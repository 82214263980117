import { useEffect, useState } from "react";
import { Button, Table, Popconfirm, message, Input, notification } from "antd";

import Acceptance from "../services/Acceptance";

import "./index.scss";

let clock = null;

enum EStatus {
  padding = 0,
  success = 1,
  timeout = 2,
  error = 3,
}

const statusObj = {
  padding: {
    value: 0,
    label: "正在执行",
    color: "gray",
  },
  success: {
    value: 1,
    label: "已完成",
    color: "#02BA5B",
  },
  timeout: {
    value: 2,
    label: "超时",
    color: "orange",
  },
  error: {
    value: 3,
    label: "失败",
    color: "red",
  },
};

function ReportHistory() {
  // 分页器
  const [pageInfo, setPageInfo] = useState({
    page: 1,
    size: 8,
  });
  const [dataSource, setDataSource] = useState([]);
  const [totalDataNum, setTotalDataNum] = useState(0);
  const [searchKey, setSearchKey] = useState("");
  const [, setTimeCount] = useState(0);

  window["reportHistorySSE"]?.addEventListener("message", (e) => {
    if (e.data) {
      const data = JSON.parse(e.data);
      if ((data.type = "createReportZip")) {
        getDataSource();
      }
    }
  });
  const getTimeConsuming = (startTime: Date) => {
    const endTime = new Date();
    const usedTime = endTime.getTime() - startTime.getTime();
    const hour = Math.floor(usedTime / (60 * 60 * 1000));
    const minute = Math.floor((usedTime - hour * 60 * 60 * 1000) / (60 * 1000));
    const second = Math.floor(
      (usedTime - hour * 60 * 60 * 1000 - minute * 60 * 1000) / 1000
    );
    return `${String(hour).padStart(2, "0")}:${String(minute).padStart(
      2,
      "0"
    )}:${String(second).padStart(2, "0")}`;
  };

  const columns = [
    {
      title: "文件名称",
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      title: "生成日期",
      dataIndex: "generateDate",
      key: "generateDate",
    },
    {
      title: "生成耗时",
      dataIndex: "timeConsume",
      key: "timeConsume",
      render: (text, record) => {
        return (
          <span
            style={{
              color: statusObj[EStatus[record.status]].color,
            }}
          >
            {record.status ? (
              text
            ) : record.generateDate ? (
              getTimeConsuming(new Date(record.generateDate))
            ) : (
              <></>
            )}
          </span>
        );
      },
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        return (
          <>
            <span
              style={{
                display: "inline-block",
                width: "10px",
                height: "10px",
                borderRadius: "999px",
                backgroundColor: statusObj[EStatus[record.status]].color,
                marginRight: "6px",
              }}
            ></span>
            <span>{statusObj[EStatus[text]].label}</span>
          </>
        );
      },
    },
    {
      title: "创建人",
      dataIndex: "createBy",
      key: "createBy",
    },
    {
      title: "操作",
      render: (_, record) => {
        return (
          <>
            {record.status === 1 ? (
              <a href={record.fileUrl} target="_blank">
                下载
              </a>
            ) : (
              <></>
            )}
            <Popconfirm
              title="确定要删除当前记录吗?"
              onConfirm={() => {
                delReportHistory(record.id);
              }}
              okText="确定"
              cancelText="取消"
              disabled={!record.status}
            >
              <Button type="link" disabled={!record.status}>
                删除
              </Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    clock = setInterval(() => {
      setTimeCount((x) => x + 1);
    }, 1000);
    return () => {
      clearInterval(clock);
    };
  }, []);

  const paginationChange = (page, size) => {
    setPageInfo({
      size,
      page,
    });
  };

  const pagination = {
    total: totalDataNum,
    current: pageInfo.page,
    pageSize: pageInfo.size,
    showQuickJumper: true,
    responsive: false,
    showSizeChanger: true,
    onChange: paginationChange,
  };

  const getDataSource = async () => {
    const res = await Acceptance.getReportHistory(
      pageInfo.page,
      pageInfo.size,
      { key: searchKey }
    );
    if (res.data.code === 200) {
      setTotalDataNum(res.data.data.total);
      setDataSource(res.data.data.list);
    }
  };

  const delReportHistory = async (id) => {
    const res = await Acceptance.delReportHistory(id);
    if (res.data.code === 200) {
      message.success("删除成功");
      getDataSource();
      return;
    }
    return message.error("删除失败");
  };

  useEffect(() => {
    getDataSource();
  }, [pageInfo.page, pageInfo.size]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <Input.Search
          placeholder="请输入信息查询"
          style={{
            width: "200px",
          }}
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
          onSearch={getDataSource}
        />
        <Button type="primary" onClick={getDataSource}>
          刷新
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={pagination}
      />
    </div>
  );
}

export default ReportHistory;
