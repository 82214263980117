import { Button, Dropdown, Menu, message, Select, Table } from 'antd';
import React, { Component } from 'react';
import './index.scss';
import Map from './map';
import AddFile from './components';
import CensusTs from '../services/census';
import PlagueWoodTs from '../services/plagueWood';
import axios from 'axios';
const { Option } = Select;
let cancel = null;
export default class Census extends Component<any, any> {
	role = localStorage.getItem('role');
	role_list = ['ROLE_3', 'ROLE_2']
	areacode = JSON.parse(localStorage.getItem("token")).areaCode
	state = {
		isOpenCensus: false, //是否开始区域划分
		uploadVisible: false, //文件上传
		data: [],
		page: 1,
		size: 10,
		total: 0,
		loading: true,
		countyList: [], //区县列表数据
		townList: [], //乡镇列表数据
		villageList: [], //
		forestList: [],
		countyCode: this.role_list.indexOf(this.role) !== -1 ? this.areacode : this.props?.locationState?.code ?? sessionStorage.getItem("cityCode"),
		townCode: '',
		villageCode: '',
		forestCode: '',
		year: sessionStorage.getItem('year'),
		levelPriority: '',
		levelDifficulty: '',
		difficultyEnum: [],
		priority: '',
		priorityEnum: [],
		yearBox: [],
		filteredInfo: {
			town: null,
			village: null,
			forest: null,
			county: null,
			levelPriority: null,
			levelDifficulty: null,
		},
		isReading: this.role_list.indexOf(this.role) !== -1 ? true : false, //只读还是可操作
	};
	componentDidMount() {
		this.getSelectList();
		this.getData();
		this.getState();
		this.getYearBox();
	}

	componentDidUpdate(
		prevProps: Readonly<any>,
		prevState: Readonly<any>,
		snapshot?: any
	): void {
		const { countyCode, townCode, villageCode, filteredInfo, forestCode, size, page, levelDifficulty, levelPriority, year } = this.state
		if (prevProps.year !== this.props.year) {
			this.setState({
				year: this.props.year
			}, () => {
				this.getSelectList();
				this.getData();
				this.getState();
				this.getYearBox();
			})
		}
		if (prevProps.locationState.code !== this.props.locationState.code) {
			this.setState({
				countyCode: this.props.locationState.code,
				countyList: [],
				isplagueWood: false,
			});
		}
		if (prevState.countyCode !== countyCode) {
			this.setState({
				townCode: "",
				villageCode: "",
				forestCode: "",
				filteredInfo: {
					...filteredInfo,
					forest: null,
					town: null,
					village: null,
				}
			}, () => {
				this.getData();
				this.getSelectList();
			})
		} else if (prevState.townCode !== townCode) {
			this.setState({
				villageCode: "",
				forestCode: "",
				filteredInfo: {
					...filteredInfo,
					forest: null,
					village: null,
				}
			}, () => {
				this.getData();
				this.getSelectList();
			})
		} else if (prevState.villageCode !== villageCode) {
			this.setState({
				forestCode: "",
				filteredInfo: {
					...filteredInfo,
					forest: null,
				}
			}, () => {
				this.getData();
				this.getSelectList();
			})
		} else if ((prevState.page !== page) || (prevState.levelDifficulty !== levelDifficulty) || (prevState.size !== size) || (prevState.forestCode !== forestCode) || (prevState.levelPriority !== levelPriority)) {
			this.getData();
		}
	}

	getState = () => {
		CensusTs.getState().then((res) => {
			if (res.data.code === 200) {
				const data = res.data.data;
				this.setState({
					difficultyEnum: data.difficultyEnum,
					priorityEnum: data.priorityEnum,
				});
			}
		});
	};

	//文件上传后获取tabel初始化数据
	upDataGetData = () => {
		this.setState(
			{
				page: 1,
				size: 10,
			},
			() => {
				this.getData();
			}
		);
	};

	getData = () => {
		const {
			page,
			size,
			countyCode,
			townCode,
			villageCode,
			forestCode,
			levelPriority,
			levelDifficulty,
			year,
		} = this.state;
		const searchData = {
			page,
			size,
			year,
			countyCode,
			townCode,
			villageCode,
			forestCode,
			levelPriority,
			levelDifficulty,
		};

		//取消请求
		const CancelToken = new axios.CancelToken(function executor(c) {
			cancel = c;
		});
		CensusTs.getList(searchData, CancelToken).then((res) => {
			if (res.data.code === 200) {
				const data = res.data.data;
				this.setState({
					page,
					data: data.list,
					total: data.total,
					loading: false,
				});
			}
		});
	};
	// 获取林班列表
	getSelectList = () => {
		const { countyCode, townCode, villageCode, forestCode, countyList } =
			this.state;
		const params = {
			countyCode: countyCode ? countyCode : '',
			townCode: townCode ? townCode : '',
			villageCode: villageCode ? villageCode : '',
			forestCode: forestCode ? forestCode : '',
		};
		CensusTs.getSelectList(params).then((res) => {
			if (res.data.code === 200) {
				if (countyList.length === 0 && this.role_list.indexOf(this.role) === -1) {
					this.setState({
						countyList: res.data.data,
					});
				} else if (!townCode && !villageCode && !forestCode) {
					this.setState({
						townList: res.data.data,
					});
				} else if (!villageCode && !forestCode) {
					this.setState({
						villageList: res.data.data,
					});
				} else if (!forestCode) {
					this.setState({
						forestList: res.data.data,
					});
				}
			}
		});
	};
	//获取年度数据
	getYearBox = () => {
		PlagueWoodTs.getYearBox().then((res) => {
			if (res.data.code === 200) {
				this.setState({
					yearBox: res.data.data,
				});
			}
		});
	};
	changeCensus = (isOpenCensus) => {
		this.setState({
			isOpenCensus: !isOpenCensus,
		});
	};

	//报表下载   天将降大任于斯人也
	ReportTreat = (value) => {
		const { countyCode, townCode, year, countyList } = this.state;
		const countyValue = countyList.find(
			(item) => countyCode && countyCode === item.areaCode
		);
		const countyName = countyValue
			? countyValue.name
			: this.props.locationState.name;

		const params = {
			countyCode: countyCode ? countyCode : this.props.locationState.code,
			townCode,
			year,
			type: value,
		};
		let today = new Date(),
			time =
				today.getFullYear() +
				'年' +
				(today.getMonth() + 1).toString().padStart(2, '0') +
				'月' +
				today.getDate().toString().padStart(2, '0') +
				'日 ';
		CensusTs.getExportExcel(params, (res) => {
			if (res.status === 200) {
				let blob = new Blob([res.data], {
					type: 'application/json;charset=utf-8',
				}); // res就是接口返回的文件流了
				let objectUrl = URL.createObjectURL(blob);
				let a = document.createElement('a');
				a.href = objectUrl;
				a.setAttribute(
					'download',
					`${countyName}普查报告${value === 1 ? '详细版' : '汇总版'
					}${time}.xlsx`
				);
				a.click();
				message.success('导出成功');
			} else {
				message.error('导出失败');
			}
		});
	};

	changeUploadVisible = (uploadVisible) => {
		this.setState({
			uploadVisible: !uploadVisible,
		});
	};
	changePagination = (pagination, filters) => {
		const { townList, villageList, forestList } = this.state;

		let town_List = [...townList];
		let village_List = [...villageList];
		let forest_List = [...forestList];

		if (!filters.county?.[0] && this.role_list.indexOf(this.role) === -1) {
			town_List = []
			village_List = []
			forest_List = []
			filters.town = null
			filters.village = null
			filters.forest = null
		}
		if (!filters.town?.[0]) {
			village_List = []
			forest_List = []
			filters.village = null
			filters.forest = null
		}
		if (!filters.village?.[0]) {
			forest_List = []
			filters.forest = null
		}
		let code;
		if (this.role_list.indexOf(this.role) !== -1) {
			code = {
				townCode: filters.town?.[0]?.substr(6, 8) ?? "",
				villageCode: filters.village?.[0]?.substr(9, 11) ?? "",
				forestCode: filters.forest?.[0]?.slice(12) ?? "",
				levelDifficulty: filters.levelDifficulty?.[0] ?? "",
				levelPriority: filters.levelPriority?.[0] ?? "",
			}
		} else {
			code = {
				countyCode: filters.county?.[0] ?? sessionStorage.getItem("cityCode"),
				townCode: filters.town?.[0]?.substr(6, 8) ?? "",
				villageCode: filters.village?.[0]?.substr(9, 11) ?? "",
				forestCode: filters.forest?.[0]?.slice(12) ?? "",
				levelDifficulty: filters.levelDifficulty?.[0] ?? "",
				levelPriority: filters.levelPriority?.[0] ?? "",
			}
		}

		this.setState({
			loading: true,
			page: pagination.current,
			size: pagination.pageSize,
			filteredInfo: filters,
			townList: town_List,
			villageList: village_List,
			forestList: forest_List,
			...code
		});

		// if (
		// 	(!filters.county && townList.length !== 0) ||
		// 	!filters.county ||
		// 	(filters.town && filters.county[0] !== this.state.countyCode)
		// ) {
		// 	this.setState(
		// 		{
		// 			loading: true,
		// 			filteredInfo: {
		// 				town: null,
		// 				village: null,
		// 				forest: null,
		// 				county: filters.county ? filters.county : null,
		// 				levelPriority: filters.levelPriority,
		// 				levelDifficulty: filters.levelDifficulty,
		// 			},
		// 			countyCode: filters.county ? filters.county[0] : '',
		// 			townCode: '',
		// 			villageCode: '',
		// 			forestCode: '',
		// 			townList: [], //乡镇列表数据
		// 			villageList: [], //
		// 			forestList: [],
		// 			levelPriority: filters.levelPriority ? filters.levelPriority[0] : '',
		// 			levelDifficulty: filters.levelDifficulty
		// 				? filters.levelDifficulty[0]
		// 				: '',
		// 		},
		// 		() => {
		// 			this.getSelectList();
		// 			this.getData();
		// 		}
		// 	);
		// } else if (
		// 	(filters.county && !filters.town && villageList.length !== 0) ||
		// 	(filters.county && !filters.town && filters.village) ||
		// 	(filters.town &&
		// 		filters.village &&
		// 		filters.town[0].substr(6, 8) !== this.state.townCode)
		// ) {
		// 	this.setState(
		// 		{
		// 			loading: true,
		// 			filteredInfo: {
		// 				town: filters.town ? filters.town : null,
		// 				village: null,
		// 				forest: null,
		// 				county: filters.county,
		// 				levelPriority: filters.levelPriority,
		// 				levelDifficulty: filters.levelDifficulty,
		// 			},
		// 			townCode: filters.town ? filters.town[0].substr(6, 8) : '',
		// 			villageCode: '',
		// 			forestCode: '',
		// 			villageList: [], //
		// 			forestList: [],
		// 			levelPriority: filters.levelPriority ? filters.levelPriority[0] : '',
		// 			levelDifficulty: filters.levelDifficulty
		// 				? filters.levelDifficulty[0]
		// 				: '',
		// 		},
		// 		() => {
		// 			this.getSelectList();
		// 			this.getData();
		// 		}
		// 	);
		// } else if (
		// 	(filters.county &&
		// 		filters.town &&
		// 		!filters.village &&
		// 		forestList.length !== 0) ||
		// 	(filters.county && filters.town && !filters.village && filters.forest) ||
		// 	(filters.town &&
		// 		filters.village &&
		// 		filters.forest &&
		// 		filters.village[0].substr(9, 11) !== this.state.villageCode)
		// ) {
		// 	this.setState(
		// 		{
		// 			loading: true,
		// 			filteredInfo: {
		// 				town: filters.town,
		// 				village: filters.village ? filters.village : null,
		// 				forest: null,
		// 				county: filters.county,
		// 				levelPriority: filters.levelPriority,
		// 				levelDifficulty: filters.levelDifficulty,
		// 			},
		// 			villageCode: filters.village ? filters.village[0].substr(9, 11) : '',
		// 			forestCode: '',
		// 			forestList: [],
		// 			levelPriority: filters.levelPriority ? filters.levelPriority[0] : '',
		// 			levelDifficulty: filters.levelDifficulty
		// 				? filters.levelDifficulty[0]
		// 				: '',
		// 		},
		// 		() => {
		// 			this.getSelectList();
		// 			this.getData();
		// 		}
		// 	);
		// } else {
		// 	this.setState({ filteredInfo: filters });
		// 	this.setState(
		// 		{
		// 			loading: true,
		// 			countyCode: filters.county ? filters.county[0] : '',
		// 			townCode: filters.town ? filters.town[0].substr(6, 8) : '',
		// 			villageCode: filters.village ? filters.village[0].substr(9, 11) : '',
		// 			forestCode: filters.forest ? filters.forest[0].slice(12) : '',
		// 			levelPriority: filters.levelPriority ? filters.levelPriority[0] : '',
		// 			levelDifficulty: filters.levelDifficulty
		// 				? filters.levelDifficulty[0]
		// 				: '',
		// 		},
		// 		() => {
		// 			this.getSelectList();
		// 			this.getData();
		// 		}
		// 	);
		// }
	};
	//年度选择
	onYearChanged = (year) => {
		cancel && cancel();
		this.setState(
			{
				year: year ? year : '',
				loading: true,
			},
			this.getData
		);
	};

	render() {
		const {
			isOpenCensus,
			uploadVisible,
			data,
			countyList,
			townList,
			villageList,
			forestList,
			yearBox,
			difficultyEnum,
			priorityEnum,
			loading,
			total,
			page,
			size,
			year,
			isReading,
		} = this.state;
		let { filteredInfo } = this.state;
		const { code, position } = this.props.locationState;
		const columns: any = [
			{
				title: '县市',
				dataIndex: 'county',
				key: 'county',
				align: 'center',
				filterMultiple: false,
				filters: this.role_list.indexOf(this.role) === -1 && countyList.map((item: any) => ({
					text: item.name,
					value: item.areaCode,
				})),
				filteredValue: filteredInfo.county || null,
			},
			{
				title: '乡镇/街道',
				dataIndex: 'town',
				key: 'town',
				align: 'center',
				filterMultiple: false,
				filters: townList.map((item: any) => ({
					text: item.name,
					value: item.areaCode,
				})),
				filteredValue: filteredInfo.town || null,
			},
			{
				title: '行政村',
				dataIndex: 'village',
				key: 'village',
				align: 'center',
				filterMultiple: false,
				filteredValue: filteredInfo.village || null,
				filters: villageList.map((item: any) => ({
					text: item.name,
					value: item.areaCode,
				})),
			},
			{
				title: '林班',
				dataIndex: 'forest',
				key: 'forest',
				align: 'center',
				filterMultiple: false,
				filteredValue: filteredInfo.forest || null,
				filters: forestList.map((item: any) => ({
					text: item.name,
					value: item.areaCode,
				})),
			},
			{
				title: '小班号',
				dataIndex: 'smallClassCode',
				key: 'smallClassCode',
				align: 'center',
			},
			{ title: '发生面积', dataIndex: 'area', key: 'area' },
			{ title: '发生株数', dataIndex: 'treeNum', key: 'treeNum' },
			{
				title: '除治优先级',
				dataIndex: 'levelPriority',
				key: 'levelPriority',
				width: 160,
				filterMultiple: false,
				filteredValue: filteredInfo.levelPriority || null,
				filters: priorityEnum.map((item: any) => ({
					text: item.value,
					value: item.key,
				})),
				render: (operation, record) => (
					<span>
						{priorityEnum.map(
							(item) => record.levelPriority === item.key && item.value
						)}
					</span>
				),
			},
			{
				title: '除治难易程度',
				dataIndex: 'levelDifficulty',
				key: 'levelDifficulty',
				width: 160,
				filterMultiple: false,
				filteredValue: filteredInfo.levelDifficulty || null,
				filters: difficultyEnum.map((item: any) => ({
					text: item.value,
					value: item.key,
				})),
				render: (operation, record) => (
					<span>
						{difficultyEnum.map(
							(item) => record.levelDifficulty === item.key && item.value
						)}
					</span>
				),
			},
			{ title: '年度', dataIndex: 'year', key: 'year' },
		];
		const pagination = {
			total,
			current: page,
			pageSize: size,
			showQuickJumper: true,
			responsive: false,
			showSizeChanger: true,
		};
		return (
			<>
				{isOpenCensus ? (
					<Map
						isOpenCensus={isOpenCensus}
						changeCensus={this.changeCensus}
						getData={this.upDataGetData}
						year={year}
						areaCode={code}
						position={position}
					/>
				) : (
					<div className={'census-box'}>
						<div className={'census-header'}>
							<div>
								<Button
									type="primary"
									style={{ marginLeft: '5px' }}
									disabled={isReading}
									onClick={() => {
										this.changeCensus(isOpenCensus);
									}}
								>
									区域划分
								</Button>
								<Button
									type="primary"
									style={{ marginLeft: '5px' }}
									onClick={() => this.changeUploadVisible(uploadVisible)}
									disabled={isReading}
								>
									文件上传
								</Button>
							</div>
							<div>
								<Select
									onChange={this.onYearChanged}
									style={{ width: 176, marginRight: '5px' }}
									placeholder="年度"
									allowClear
									defaultValue={year ? year : sessionStorage.getItem('year')}
								>
									{yearBox.map((item) => (
										<Option value={item} key={item}>
											{item}
										</Option>
									))}
								</Select>
								<Dropdown
									disabled={isReading}
									overlay={
										<Menu>
											<Menu.Item key={'xiangxi'}>
												<Button
													type="primary"
													onClick={() => {
														this.ReportTreat(1);
													}}
												>
													详细版
												</Button>
											</Menu.Item>
											<Menu.Item key={'huizong'}>
												<Button
													type="primary"
													onClick={() => {
														this.ReportTreat(2);
													}}
												>
													汇总版
												</Button>
											</Menu.Item>
										</Menu>
									}
									placement="bottom"
									arrow
								>
									<Button type="primary">报表下载</Button>
								</Dropdown>
							</div>
						</div>
						<div className={'census-table'}>
							<Table
								dataSource={data}
								columns={columns}
								rowKey={(record) => record.id}
								loading={loading}
								pagination={pagination}
								onChange={this.changePagination}
								scroll={{ y: 'calc(100vh - 23.5rem)' }}
							/>
						</div>
					</div>
				)}
				{/* 文件上传 */}
				{uploadVisible && (
					<AddFile
						visible={uploadVisible}
						onCancel={this.changeUploadVisible}
						getData={this.upDataGetData}
					/>
				)}
			</>
		);
	}
}
