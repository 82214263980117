import { PlusOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import ImageShow from "./imageShow";

interface Props {
  type: number;
  dataSource: any;
  changeDataSource: (data) => void;
}

const ModalUpload = ({ type, dataSource, changeDataSource }: Props) => {
  const props = {
    beforeUpload: (file, fileList) => {
      changeDataSource([...dataSource, ...fileList]);
      return false;
    },
    fileList: dataSource,
    showUploadList: false,
    multiple: true,
  };

  const removeImage = (file) => {
    const index = dataSource.indexOf(file);
    const newFileList = dataSource.slice();
    newFileList.splice(index, 1);
    changeDataSource(newFileList);
  };

  return (
    <>
      <Upload {...props}>
        <div className="modal_upload_box">
          <PlusOutlined rev={undefined} />
          <span>{type === 2 && "不"}合格照片</span>
        </div>
      </Upload>
      <div className="image_list">
        {dataSource.map((item, i) => {
          return (
            <ImageShow
              key={i}
              type={type}
              isNewFile={item.type ? true : false}
              url={item.type ? URL.createObjectURL(item) : item.url}
              removeClick={() => removeImage(item)}
            />
          );
        })}
      </div>
    </>
  );
};

export default ModalUpload;
