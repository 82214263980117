import React from 'react';
import { Label } from '../tools/Label';
import './index.scss';
interface BaseCardType {
  title: string;
  describe: string;
  height: number;
  backround: string;
  children: any;
}
export default function BaseCard(props: BaseCardType) {
  const { title, describe, children, height, backround } = props;
  return (
    <div className="BaseCard" style={{ height: `${height}px` }}>
      <Label title={title} describe={describe} width={395} />
      <div
        className="BaseCard_content"
        style={{ background: `url(${backround}) 100% 100%` }}
      >
        {children}
      </div>
    </div>
  );
}
