import { Component } from "react";
import CarService from "../services/Car";
import CommonService from "../services/Common";
import "./index.scss";
import {
  Button,
  Cascader,
  Form,
  Input,
  Modal,
  Popconfirm,
  Radio,
  Table,
  message,
} from "antd";
import MapModal from "./components/mapModal";
const { Search } = Input;

interface TProps {
  cityCode: number;
  year: string;
}

class Annual extends Component<TProps> {
  state = {
    page: 1,
    size: 8,
    data: [],
    loading: true,
    total: 0,
    searchValue: "",
    selectedRowKeys: [],
    record: null,
    year: this.props.year,
    yearList: JSON.parse(sessionStorage.getItem("yearList")),
    title: "",
    dialogFlag: false,
    deleteList: [],
    addModalVisible: false,
    editRecord: {
      id: null,
      responsiblePerson: "",
      carPlate: "",
      carDriver: "",
      phone: "",
      gpsCode: "",
      type: 1,
      countyCode: "",
      townCode: "",
    },
    countyAndTownList: [],
    locationData: {},
    mapVisible: false,
  };

  componentDidMount(): void {
    this.getData();
    this.getAreaData();
  }

  componentDidUpdate(prevProps: Readonly<TProps>): void {
    if (
      prevProps.cityCode !== this.props.cityCode ||
      prevProps.year !== this.props.year
    ) {
      this.getData();
      this.getAreaData();
    }
  }

  //获取数据
  getData = (msg?: boolean) => {
    const { page, size, searchValue } = this.state;
    const params = {
      page,
      size,
      key: searchValue,
      year: this.props.year,
      cityCode: this.props.cityCode,
    };

    CarService.getList(params).then((res) => {
      if (res.data.code === 200) {
        msg && message.success("获取成功");
        return this.setState({
          data: res.data.data.list,
          total: res.data.data.total,
          loading: false,
        });
      }
      msg && message.error("获取失败");
    });
  };

  getAreaData = async () => {
    const { data: countyAndTownList } = await CommonService.getListAllRegion({
      cityCode: this.props.cityCode,
      year: this.props.year,
    });

    if (countyAndTownList.code === 200) {
      this.setState({
        countyAndTownList: countyAndTownList.data,
      });
    }
  };

  openFormModal = () => {
    this.setState({
      addModalVisible: true,
    });
  };
  closeFormModal = () => {
    this.setState({
      addModalVisible: false,
    });
  };

  handleSubmitForm = (data) => {
    const areaInfo = {
      cityCode: this.props.cityCode,
      countyCode: data.region[0] ? data.region[0] : null,
      townCode: data.region.length > 1 ? data.region[1] : null,
    };

    const { carPlate, gpsCode } = data;
    if (!(!!carPlate || !!gpsCode)) {
      return message.error("请填写车牌号以及设备编号");
    }

    CarService.editCar({ ...this.state.editRecord, ...data, ...areaInfo }).then(
      (res) => {
        const { data } = res;
        if (data.code === 200) {
          this.closeFormModal();
          this.getData();
          return message.success("修改成功");
        }
        return message.error("操作失败");
      }
    );
  };

  delRecord = (id) => {
    CarService.delRecord(id).then((res) => {
      const { data } = res;
      if (data.code === 200) {
        this.getData();
        return message.success("删除成功");
      }
      message.error("删除失败");
    });
  };

  delRecords = () => {
    if (this.state.deleteList.length === 0) {
      return message.error("请选择要删除的项");
    }
    CarService.delRecords(this.state.deleteList.map((item) => item.id)).then(
      (res) => {
        const { data } = res;

        if (data.code === 200) {
          this.setState({
            deleteList: [],
          });
          this.getData();
          return message.success("删除成功");
        }
        message.error("删除失败");
      }
    );
  };

  //切换
  changePagination = (pagination) => {
    this.setState(
      {
        page: pagination.current,
        size: pagination.pageSize,
        loading: true,
      },
      this.getData
    );
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRowKeys,
      deleteList: selectedRows,
    });
  };

  upDate = () => {
    CarService.upDateRecords().then((res) => {
      if (res.data.code === 200) {
        this.getData(true);
      }
    });
  };

  render() {
    const {
      page,
      size,
      data,
      loading,
      total,
      selectedRowKeys,
      addModalVisible,
      editRecord,
      locationData,
      mapVisible,
    } = this.state;
    const pagination = {
      total,
      current: page,
      pageSize: size,
      showQuickJumper: true,
      responsive: false,
      showSizeChanger: true,
    };
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const columns = [
      {
        title: "序号",
        dataIndex: "countyName",
        key: "countyName",
        render: (_, __, index) => <span>{index + 1}</span>,
      },
      {
        title: "所属区域",
        dataIndex: "region",
        width: 200,
        key: "region",
        render: (_, record) => {
          return (
            <span>
              {record.cityName
                ? record.countyName
                  ? record.townName
                    ? [
                        record.cityName,
                        record.countyName,
                        record.townName,
                      ].join("/")
                    : [record.cityName, record.countyName].join("/")
                  : record.cityName
                : "暂无"}
            </span>
          );
        },
      },
      {
        title: "负责人",
        dataIndex: "responsiblePerson",
        key: "responsiblePerson",
        render: (text) => <span>{text || "暂无"}</span>,
      },
      {
        title: "车牌号",
        align: "center" as "center",
        dataIndex: "carPlate",
        key: "carPlate",
        render: (text) => <span>{text || "暂无"}</span>,
      },
      {
        title: "驾驶员",
        align: "center" as "center",
        dataIndex: "carDriver",
        key: "carDriver",
        render: (text) => <span>{text || "暂无"}</span>,
      },
      {
        title: "联系方式",
        align: "center" as "center",
        dataIndex: "phone",
        key: "phone",
        render: (text) => <span>{text || "暂无"}</span>,
      },
      {
        title: "设备编号",
        align: "center" as "center",
        dataIndex: "gpsCode",
        key: "gpsCode",
        render: (text) => <span>{text || "暂无"}</span>,
      },
      {
        title: "状态",
        align: "center" as "center",
        dataIndex: "state",
        key: "state",
        render: (text) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "999px",
                  backgroundColor:
                    text === "在线" || text === "停车" ? "#02BA5B" : "#FF3D57",
                  marginRight: "10px",
                }}
              ></div>
              <span>{text || "获取失败"}</span>
            </div>
          );
        },
      },

      {
        title: "设备版本",
        align: "center" as "center",
        dataIndex: "type",
        key: "type",
        render: (text) => <span>{text === 1 ? "新版" : "旧版"}</span>,
      },
      {
        title: "操作",
        className: "handle",
        dataIndex: "handle",
        width: 220,
        align: "center" as "center",
        key: "handle",
        render: (_, record) => (
          <>
            <Button
              type="link"
              onClick={() => {
                this.setState({
                  locationData: record,
                  mapVisible: true,
                });
              }}
            >
              定位
            </Button>
            <Button
              type="link"
              onClick={() =>
                this.setState(
                  {
                    editRecord: record,
                  },
                  () => this.openFormModal()
                )
              }
            >
              修改
            </Button>
            <Popconfirm
              title="确定要删除当前记录吗?"
              onConfirm={() => {
                this.delRecord(record.id);
              }}
              okText="确定"
              cancelText="取消"
            >
              <Button type="link">删除</Button>
            </Popconfirm>
          </>
        ),
      },
    ];
    return (
      <>
        <Modal
          visible={addModalVisible}
          title={this.state.editRecord.id ? "修改车辆" : "添加车辆"}
          onCancel={this.closeFormModal}
          footer={null}
          destroyOnClose
        >
          <Form labelCol={{ span: 4 }} onFinish={this.handleSubmitForm}>
            <Form.Item
              label="负责人"
              name="responsiblePerson"
              initialValue={editRecord.responsiblePerson || ""}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="车牌号"
              required
              name="carPlate"
              initialValue={editRecord.carPlate || ""}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="驾驶员"
              name="carDriver"
              initialValue={editRecord.carDriver || ""}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="联系方式"
              name="phone"
              initialValue={editRecord.phone || ""}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="设备编号"
              required
              name="gpsCode"
              initialValue={editRecord.gpsCode || ""}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="所属区域"
              name="region"
              initialValue={
                editRecord.townCode
                  ? [editRecord.countyCode, editRecord.townCode]
                  : [editRecord.countyCode]
              }
            >
              <Cascader
                options={this.state.countyAndTownList}
                placeholder="请选择"
                changeOnSelect
              />
            </Form.Item>
            <Form.Item
              label="设备版本"
              name="type"
              initialValue={editRecord.type}
            >
              <Radio.Group>
                <Radio value={0}>旧版</Radio>
                <Radio value={1}>新版</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{ margin: "0 10px" }}
              >
                确认
              </Button>
              <Button
                style={{ margin: "0 10px" }}
                onClick={this.closeFormModal}
              >
                取消
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        {mapVisible && (
          <MapModal
            visible={mapVisible}
            dataSource={locationData}
            onClose={() => {
              this.setState({
                locationData: {},
                mapVisible: false,
              });
            }}
          ></MapModal>
        )}
        <div className="score">
          <div
            className="alHeader"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Button
                style={{ marginRight: "10px" }}
                type="primary"
                onClick={() =>
                  this.setState(
                    {
                      editRecord: {
                        type: 1,
                      },
                    },
                    () => this.openFormModal()
                  )
                }
              >
                添加车辆
              </Button>
              <Search
                className="searchInput"
                placeholder="请输入信息查询"
                enterButton="搜索"
                onSearch={(value) => {
                  this.setState(
                    {
                      searchValue: value,
                    },
                    () => this.getData(true)
                  );
                }}
                style={{
                  width: "270px",
                }}
              />
            </div>
            <div>
              <Button
                style={{ marginRight: "10px" }}
                type="primary"
                onClick={this.upDate}
              >
                更新状态
              </Button>
              <Popconfirm
                title="确定要删除当前记录吗?"
                onConfirm={this.delRecords}
                okText="确定"
                cancelText="取消"
              >
                <Button style={{ marginRight: "10px" }} type="primary">
                  批量删除
                </Button>
              </Popconfirm>
            </div>
          </div>
          <Table
            dataSource={data}
            rowKey={(record) => record.id}
            columns={columns}
            loading={loading}
            rowSelection={rowSelection}
            onChange={(pagination) => this.changePagination(pagination)}
            pagination={pagination}
            scroll={{ y: "calc(100vh - 23.5rem)" }}
          />
        </div>
      </>
    );
  }
}

export default Annual;
