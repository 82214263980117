import { MinusCircleOutlined } from "@ant-design/icons";
import { Select, Space, Form, Input } from "antd";
import { useEffect, useState } from "react";

interface Props {
  form: any;
  data: any;
  situationOption: any;
  onRemove: (name) => void;
}

const SituationItem = ({
  form,
  data: { key, name, ...restField },
  situationOption,
  onRemove,
}: Props) => {
  const [selectData, setSelectData] = useState("");

  const getDefaultSelectData = () => {
    const list = form.getFieldValue("situationList");
    const newData = list[name] ? list[name].first : "";
    setSelectData(newData);
  };

  useEffect(() => {
    getDefaultSelectData();
  }, []);

  return (
    <Space key={key} className="form_list_space" align="baseline">
      <Form.Item {...restField} name={[name, "first"]}>
        <Select
          className="form_select"
          placeholder="请选择描述类别"
          options={situationOption}
          onChange={(e) => {
            setSelectData(e);
          }}
        />
      </Form.Item>

      <Form.Item {...restField} name={[name, "last"]}>
        {selectData === "其他" ? (
          <Input className="form_input" placeholder="请填写内容"></Input>
        ) : (
          <Input
            min={1}
            className="form_input"
            type={"number"}
            addonAfter={"处"}
            placeholder="请填写整数"
          ></Input>
        )}
      </Form.Item>

      <div className="clear_button">
        <MinusCircleOutlined
          style={{
            fontSize: 20,
            color: "#FF3D57",
          }}
          rev={undefined}
          onClick={() => onRemove(name)}
        />
      </div>
    </Space>
  );
};

export default SituationItem;
