// @ts-nocheck
import React, { Component, createRef } from 'react';
import {
	Modal,
	Form,
	Select,
	Input,
	Button,
	DatePicker,
	Upload,
	message,
	Checkbox,
	Pagination,
} from 'antd';
import { PlusOutlined, PlusSquareOutlined } from '@ant-design/icons';
import Common from '../../platform/services/Common';
// import { exportWord } from 'mhtml-to-word'
import { exportWord } from '../../utils/wordhtml';
import Acceptance from '../../platform/services/Acceptance';

const { Option } = Select;
const { RangePicker } = DatePicker;
const FormItem = Form.Item;

const layout = {
	labelCol: { span: 6 },
	wrapperCol: { span: 18 },
};

interface RectificaReportState {
	current: number;
	list: Array<any>;
	townList: Array<any>;
	villageList: Array<any>;
	reportData: any;
	fileList: Array<any>;
	time: string;
	townCode: string;
	pdf: boolean;
	checkVisible: boolean;
}
interface RectificaReportProps {
	visible: boolean;
	handleCancel: any;
	countyCode: any;
	countyList: any;
	isInspection: number;
	townCode: string;
	isReading: boolean;
	areaLevel: number;
}

// 导出报告
export class RectificaReport extends Component<
	RectificaReportProps,
	RectificaReportState
> {
	countyCode = this.props.countyCode;
	ref: any = createRef();
	formRef: any = createRef();
	constructor(props) {
		super(props);
		this.state = {
			pdf: false,
			current: 1,
			list: [
				{
					villageCode: '',
					forestCode: '',
					smallClassList: [],
				},
			],
			townList: [],
			villageList: [],
			time: '',
			townCode: this.props.townCode,
			fileList: [],
			reportData: null,
			checkVisible: false,
			forestVisible: this.countyCode === "331124" ? true : false,
			startTime: '',
			endTime: '',
		};
	}

	componentDidMount() {
		Common.getTownList({ countyCode: this.countyCode }).then((res) => {
			if (res.data.code === 200) {
				this.setState({
					townList: res.data.data,
				});
			}
		});
	}

	// 乡镇/街道选择
	onAreaChanged = (value) => {
		const { startTime, endTime } = this.state;
		const { isInspection } = this.props;
		const params = {
			countyCode: this.countyCode,
			townCode: value,
			startTime,
			endTime,
			isReport: true,
			isInspection: isInspection,
		};
		Common.getVillageList(params).then((res) => {
			if (res.data.code === 200) {
				this.setState({
					townCode: value,
					villageList: res.data.data,
				});
			}
		});
	};

	//获取乡镇
	getVilList = (params) => {
		Common.getVillageList(params).then((res) => {
			if (res.data.code === 200) {
				this.setState({
					villageList: res.data.data,
				});
			}
		});
	};

	//日期的变化
	rangeChange = (value) => {
		if (value) {
			const startTime = value[0].format('YYYY-MM-DD 00:00:00');
			const endTime = value[1].format('YYYY-MM-DD 23:59:59');
			this.setState({ startTime, endTime });
			const params = {
				countyCode: this.countyCode,
				townCode: this.state.townCode,
				startTime,
				endTime,
				isReport: true,
				isInspection: this.props.isInspection,
			};
			this.getVilList(params);
		}
	};

	//新增照片选择
	changeList = (index, key) => {
		const { list } = this.state;
		// 新增
		if (key === 1) {
			const newList = [
				...list,
				{
					villageCode: '',
					forestCode: '',
					smallClassList: [],
				},
			];
			this.setState({
				list: newList,
			});
		} else {
			const newList = [...list];
			newList.splice(index, 1);
			this.setState({
				list: newList,
			});
		}
	};

	// 更新验收照片选择
	updateClass = (changedValues, allValues, index) => {
		const { list } = this.state;
		const newList = [...list];
		newList[index] = {
			villageCode: allValues.villageCode ? allValues.villageCode : '',
			forestCode: allValues.forestCode ? allValues.forestCode : '',
			smallClassList: allValues.smallClassList ? allValues.smallClassList : [],
		};
		this.setState({
			list: newList,
		});
	};

	// 翻页
	pageChanged = (value) => {
		const { current } = this.state;
		// const total = Math.ceil(list.length / 2)
		if (value > current) {
			this.ref.current.style.left = '-' + (value - 1) * 752 + 'px';
			this.setState({
				current: value,
			});
		} else if (value < current) {
			this.ref.current.style.left = '-' + (value - 1) * 750 + 'px';
			this.setState({
				current: value,
			});
		}
	};

	// 生成报告
	finishReport = (value) => {
		const { list, townList, checkVisible, townCode } = this.state;
		const { time, file, frequency } = value;
		const { countyList, countyCode } = this.props;
		const reportName = countyList.find((item) => {
			return countyCode === item.code;
		});
		console.log(value)
		const params = {
			countyCode: this.countyCode,
			// picCondition: list,
			townCode: checkVisible ? '' : townCode,
			villageCode: value.villageCode,
			startTime: time[0].format('YYYY-MM-DD 00:00:00'),
			endTime: time[1].format('YYYY-MM-DD 23:59:59'),
			year: sessionStorage.getItem('year'),
			isInspection: this.props.isInspection,
		};
		message.loading({ content: '正在生成报告中...', duration: 0 });
		Acceptance.getRectReport(params).then((res) => {
			console.log(res)
			if (res.data.code === 200) {
				this.setState(
					{
						reportData: {
							countyName: reportName.name,
							townName: checkVisible
								? '全部街道'
								: townList.find((item) => {
									return item.code === townCode;
								}).name,
							frequency,
							startTime: time[0].format('YYYY年MM月DD日'),
							endTime: time[1].format('YYYY年MM月DD日'),
							...res.data.data,
						},
						pdf: true,
						fileList: file ? file.fileList : [],
					},
					() => {
						console.log(this.state.reportData)
						message.destroy();
					}
				);
			} else {
				message.destroy();
				message.error('生成报告失败，请检查后重试');
			}
		});
	};

	onChange = (value) => {
		this.setState({
			checkVisible: value.target.checked,
		});
	};

	//行政村选择
	changeSelect = (value, key) => {
		const { countyCode, townCode, time } = this.props;
		const { villageCode } = this.state;
		switch (key) {
			case 1:
				Common.getForestList({
					countyCode: countyCode,
					townCode,
					villageCode: value,
					isReport: true,
					...time
				}).then((res) => {
					if (res.data.code === 200) {
						this.setState({
							villageCode: value,
							forestList: res.data.data,
						});
					}
				});
				break;
			case 2:
				Acceptance.getClassList({
					countyCode: countyCode,
					townCode,
					villageCode,
					forestCode: value,
					isReport: true,
					...time
				}).then((res) => {
					if (res.data.code === 200) {
						this.setState({
							forestCode: value,
							smallClassList: res.data.data,
						});
					}
				});
				break;
		}
	};

	// 导出报告
	handleExport = () => {
		// const scale = 2
		exportWord({
			filename: '跟踪验收报告',
			selector: '#pdf-content',
			style: `
          .pdf-header {
            width: 595.28px;
            height: 841.89px;
          }
          .pdf-page {
            width: 599.28px;
            margin-bottom: 70px;
          }
          p{
            margin: 0;
          }
          .bodytd{
            text-align:center;
          }

          .title1 {
            font-size: 30px;
            text-align:center;
            margin-bottom: 250px;
            font-weight: 600;
          }
          .trackreport{
            width: 10px;
            font-weight: 600;
            font-size: 30px;
            text-align:center;
          }
          .trackreportlast{
            width: 10px;
            font-weight: 600;
            font-size: 30px;
            margin-bottom: 250px;
            text-align:center;
          }
          .title2 {
            font-size: 20px;
            text-align:center;
          }
          .text {
            font-size: 20px;
            margin-bottom: 25px;
          }
          .texttitle {
            font-size: 22px;
            text-align:center;
            font-weight: 700;
            margin-bottom: 25px;
          }
          .subscript {
              font-size: 12px;
              text-align:right;
              margin-bottom: 25px;
          }
          .margin {
            font-size: 0px;
            margin-bottom: 40px;
          }
          .title3 {
            font-size: 22px;
            text-align:center;
            margin-bottom: 200px;
          }
          .pdf-img {
            width: 595.28px;
            height: 350px;
          }
          #pdf-img {
            width: 595.28px;
            height: 350px;
            margin-bottom: 20px;
          }
          .pdf-imgbox {
            width: 595.28px;
            height: 350px;
            text-align:center;
          }
          .info-title {
            font-size: 20px;
          }
          .title {
            text-decoration: solid;
            color: #000000;
            font-size: 22px;
          }
          .tableForm{
            width: 100%;
            margin-bottom: 20px;
          }
        `,
		});
		message.success('导出成功');
	};

	render() {
		const {
			current,
			list,
			townList,
			townCode,
			villageList,
			pdf,
			reportData,
			fileList,
			checkVisible,
			startTime,
			endTime,
			forestVisible
		} = this.state;
		const { areaLevel } = this.props;
		const columns = [
			{
				title: '序号',
				dataIndex: '',
				key: '',
			},
			{
				title: '乡镇街道',
				dataIndex: 'townName',
				key: 'townName',
			},
			{
				title: '行政村',
				dataIndex: 'villageName',
				key: 'villageName',
			},
			{
				title: '林班',
				dataIndex: 'forestCode',
				key: 'forestCode',
			},
			{
				title: '小班号',
				dataIndex: 'smallClass',
				key: 'smallClass',
			},
			{
				title: '面积(亩)',
				dataIndex: 'area',
				key: 'area',
			},
			{
				title: '验收情况',
				dataIndex: 'situation',
				key: 'situation',
			},
			{
				title: '验收结果',
				dataIndex: 'result',
				key: 'result',
			},
			{
				title: '备注',
				dataIndex: 'situation',
				key: 'situation',
			},

		];
		// const columns2 = [
		// 	{
		// 		title: '序号',
		// 		dataIndex: '',
		// 		key: '',
		// 	},
		// 	{
		// 		title: '乡镇街道',
		// 		dataIndex: 'townName',
		// 		key: 'townName',
		// 	},
		// 	{
		// 		title: '行政村',
		// 		dataIndex: 'villageName',
		// 		key: 'villageName',
		// 	},
		// 	{
		// 		title: '林班',
		// 		dataIndex: 'forestCode',
		// 		key: 'forestCode',
		// 	},
		// 	{
		// 		title: '小班号',
		// 		dataIndex: 'smallClass',
		// 		key: 'smallClass',
		// 	},
		// 	{
		// 		title: '面积(亩)',
		// 		dataIndex: 'area',
		// 		key: 'area',
		// 	},
		// 	{
		// 		title: '验收情况',
		// 		dataIndex: 'situation',
		// 		key: 'situation',
		// 	},
		// 	{
		// 		title: '验收结果',
		// 		dataIndex: 'result',
		// 		key: 'result',
		// 	},
		// 	{
		// 		title: '备注',
		// 		dataIndex: 'remark',
		// 		key: 'remark',
		// 	},

		// ];
		const columns3 = [
			{
				title: '伐桩合格率',
				dataIndex: 'cutQualifiedRate',
				key: 'cutQualifiedRate',
			},
			{
				title: '梢头枝桠滞留率',
				dataIndex: 'twigRetentionRate',
				key: 'twigRetentionRate',
			},
		];
		// const columns4 = [
		// 	{
		// 		title: '伐桩合格率',
		// 		dataIndex: 'cutQualifiedRate',
		// 		key: 'cutQualifiedRate',
		// 	},
		// 	{
		// 		title: '梢头枝桠滞留率',
		// 		dataIndex: 'twigRetentionRate',
		// 		key: 'twigRetentionRate',
		// 	},
		// ];
		const trackreportList = ['跟', '踪', '验', '收', '报'];
		const props: any = {
			action: window['env'].REACT_APP_API_HOST + '/upload',
			listType: 'picture-card',
			beforeUpload: () => {
				return false;
			},
			accept: '.png,.jpg,.jpeg',
			showUploadList: {
				showPreviewIcon: false,
				showRemoveIcon: true,
				showDownloadIcon: false,
			},
		};
		return (
			<Modal
				title="整改报告"
				visible={this.props.visible}
				footer={null}
				onCancel={() => this.props.handleCancel(this.props.visible)}
				centered={true}
				width={800}
				className="exportReport"
			>
				{!pdf ? (
					<Form onFinish={this.finishReport}>
						<div className={'header1'}>
							<span>导出范围及验收单上传</span>
						</div>
						<FormItem
							name="townCode"
							label="乡镇/街道"
							rules={[
								{
									required: checkVisible || townCode ? false : true,
									message: '请选择乡镇/街道',
								},
							]}
							{...layout}
						>
							<Select
								onSelect={this.onAreaChanged}
								defaultValue={townCode}
								style={{ width: 400 }}
								disabled={areaLevel === 3 && true}
							>
								{townList.map((item, index) => {
									return (
										<Option key={index} value={item.code}>
											{item.name}
										</Option>
									);
								})}
							</Select>
							<Checkbox
								onChange={this.onChange}
								style={{ marginLeft: '20px' }}
								disabled={areaLevel === 3 && true}
							>
								全选
							</Checkbox>
						</FormItem>
						<FormItem
							name="villageCode"
							label="行政村"
							style={{ width: 400, marginLeft: 88 }}
							{...layout}
						>
							<Select onSelect={(value) => this.changeSelect(value, 1)}>
								{villageList.map((item, index) => {
									return (
										<Option key={index} value={item.code}>
											{item.name}
										</Option>
									);
								})}
							</Select>
						</FormItem>
						<FormItem
							label="起止时间"
							name="time"
							rules={[
								{
									required: true,
									message: '请选择导出报告日期',
								},
							]}
							{...layout}
						>
							<RangePicker style={{ width: 400 }} onChange={this.rangeChange} />
						</FormItem>
						<FormItem style={{ textAlign: 'center', justifyContent: 'center' }}>
							<Button
								type="primary"
								style={{ marginTop: '15px' }}
								htmlType="submit"
							>
								生成报告
							</Button>
						</FormItem>

					</Form>
				) : (
					<div className={'pdf-box'}>
						<div id={'pdf-content'}>
							<div className={'pdf-header'} id={'pdf-header'}>
								<div className={'title1'}>
									<span className={'trackreport1'}>
										{reportData.countyName}
										{reportData.townName}松材线虫病防治山场整改报告
									</span>
									<br />
								</div>
								{/* {trackreportList.map((item, index) => (
									<p className={'trackreport'} key={index}>
										{item}
									</p>
								))} */}
								{/* <p className={'trackreportlast'}>告</p> */}
								{/* <div className={'title2'}>
									<span>浙江点创信息科技有限公司</span>
									<div className={'title3'}>
										<span>{`${new Date().getFullYear()}`}</span>年
										<span>{`${new Date().getMonth() + 1}`}</span>月
										<span>{`${new Date().getDate()}`}</span>日
									</div>
								</div> */}
							</div>
							<div className={'text'}>
								&nbsp;&nbsp;{reportData.startTime}至{reportData.endTime}
								。项目组采用无人机航拍与人工实地核查方式对{reportData.countyName}
								{reportData.townName}松材线虫病枯死木清理除治质量、除治进度进行检查。

							</div>
							<div className={'texttitle'}>
								&nbsp;&nbsp;上月不合格小班
							</div>
							{/* <div className={'subscript'}>单位：亩、%</div> */}
							<div className={'pdf-page'}>
								<table
									border="1"
									align="center"
									cellspacing="0"
									text-align="center"
									className="tableForm"
									width="100%"
								>
									<tr>
										{columns &&
											columns.map((item) => {
												return (
													<>
														<th
															colSpan={item.title === '验收情况' ? '2' : ''}
															rowSpan={item.title === '验收情况' ? '' : '2'}
														>
															{item.title}
														</th>

													</>
												);
											})}

									</tr>
									<tr>
										{columns3 && columns3.map((item) => <th>{item.title}</th>)}
									</tr>
									<tbody className={'body'}>
										{reportData.unQualifiedClassList.map((item, index) => {
											return (
												<>
													<tr
														id={'tablefirst'}
														text-align="center"
														className={'bodytd'}
													>
														<td
															rowSpan={`${item.length}`}
															width="5%"
															style={{ height: `${item.length * 50}px` }}
														>
															{index + 1}
														</td>
														<td
															rowSpan={`${item.length}`}
															width="10%"
															style={{ height: `${item.length * 50}px` }}
														>
															{item.townName}
														</td>
														<td text-align="center" width="15%">
															{item.villageName}
														</td>
														{/* <td text-align="center" width="9%">
															{item.data[0].teamName}
														</td> */}
														<td text-align="center" width="15%">
															{item.forestCode}
														</td>
														<td text-align="center" width="15%">
															{item.smallClass}
														</td>
														<td text-align="center" width="15%">
															{item.area || 0}
														</td>
														<td text-align="center" width="15%">
															{item.cutQualifiedRate}%
														</td>
														<td text-align="center" width="10%">
															{item.twigRetentionRate}
														</td>
														<td text-align="center" width="15%">
															{item.result === 0 ? '不通过' : "通过"}
														</td>
														<td text-align="center" width="15%">
															{item.situation}
														</td>
													</tr>
													{/* {item.slice(1).map((item2, index) => {
														return (
															<tr text-align="center" className={'bodytd'}>
																<td text-align="center" width="20%">
																	{item2.villageName}
																</td>
																
																<td text-align="center" width="20%">
																	{item2.acceptArea}
																</td>
																<td text-align="center" width="20%">
																	{item2.acceptClass}
																</td>
																<td text-align="center" width="20%">
																	{item2.unqualifiedClass}
																</td>
																<td text-align="center" width="10%">
																	{item2.qualifiedClass}
																</td>
																<td text-align="center" width="10%">
																	{item2.qualifiedRate}%
																</td>
														
															</tr>
														);
													})} */}
												</>
											);
										})}
									</tbody>
								</table>
							</div>
							<div className={'margin'}>1</div>
							<div className={'texttitle'}>
								核查情况
							</div>
							<div className="subscript">单位：株、个、处</div>
							<div className={'pdf-page'}>
								<table
									border="1"
									align="center"
									cellspacing="0"
									text-align="center"
									className="tableForm"
									width="100%"
								>
									<tr>
										{columns &&
											columns.map((item) => {
												return (
													<>
														<th
															colSpan={item.title === '验收情况' ? '2' : ''}
															rowSpan={item.title === '验收情况' ? '' : '2'}
														>
															{item.title}
														</th>

													</>
												);
											})}

									</tr>
									<tr>
										{columns3 && columns3.map((item) => <th>{item.title}</th>)}
									</tr>
									<tbody className={'body'}>
										{reportData.verifyClassList.map((item, index) => {
											return (
												<>
													<tr
														id={'tablefirst'}
														text-align="center"
														className={'bodytd'}
													>
														<td
															rowSpan={`${item.length}`}
															width="5%"
															style={{ height: `${item.length * 50}px` }}
														>
															{index + 1}
														</td>
														<td
															rowSpan={`${item.length}`}
															width="10%"
															style={{ height: `${item.length * 50}px` }}
														>
															{item.townName}
														</td>
														<td text-align="center" width="15%">
															{item.villageName}
														</td>
														{/* <td text-align="center" width="9%">
															{item.data[0].teamName}
														</td> */}
														<td text-align="center" width="15%">
															{item.forestCode}
														</td>
														<td text-align="center" width="15%">
															{item.smallClass}
														</td>
														<td text-align="center" width="15%">
															{item.area || 0}
														</td>
														<td text-align="center" width="15%">
															{item.cutQualifiedRate}%
														</td>
														<td text-align="center" width="10%">
															{item.twigRetentionRate}
														</td>
														<td text-align="center" width="15%">
															{item.result === 0 ? '不通过' : "通过"}
														</td>
														<td text-align="center" width="15%">
															{item.situation}
														</td>
													</tr>

												</>
											);
										})}
									</tbody>
								</table>
								<div className={'info-title'}>核查现场照片：</div>
								{reportData.picList.map((item, index) => {
									return (
										<div className={'pdf-page'}>
											<div className={'info-title'}>{item.title}</div>
											{item.urlList.map((item2) => {
												return (
													<img
														className="pdf-img"
														id={'pdf-img'}
														src={item2}
														width={'595.28'}
														height={'350'}
														alt=""
													></img>
												);
											})}
										</div>
									);
								})}
								{/* <div className={'pdf-page'}>
									<div className={'text'}>《{reportData.countyName}{reportData.townName}松材线虫病防治第{reportData.frequency}轮跟踪验收详情表》</div>
									<table border="1" align="center" cellspacing="0" text-align="center" className='tableForm' width="100%">
										<tr>
											{
												columns2 && columns2.map(item => {
													return <th >{item.title}</th>
												})
											}
										</tr>
										<tbody className={'body'}>
											{
												reportData.classList.map((item2, index) => {
													return <>
														<tr id={'tablefirst'} text-align="center">
															<td text-align="center" width="15%">{item2.townName}</td>
															<td text-align="center" width="15%">{item2.villageName}</td>
															<td text-align="center" width="15%">{item2.forestCode}</td>
															<td text-align="center" width="10%">{item2.smallClass}</td>
															<td text-align="center" width="10%">{item2.result}</td>
															<td text-align="center" width="35%">{item2.situation}</td>
														</tr>
													</>
												})
											}
										</tbody>
									</table>
								</div> */}
								{/* <div className={'pdf-page'}>
									<div className={'text'}>验收单汇总：</div>
									{fileList.map((item) => {
										return (
											<img
												className="pdf-img"
												id={'pdf-img'}
												src={item.thumbUrl}
												width={'595.28'}
												height={'350'}
												alt=""
											></img>
										);
									})}
								</div> */}
							</div>
						</div>
						<div style={{ textAlign: 'center' }}>
							<Button
								type="primary"
								style={{ marginTop: '15px' }}
								onClick={this.handleExport}
							>
								导出报告
							</Button>
						</div>
					</div>
				)}
			</Modal>
		);
	}
}

interface PicInfoProps {
	data: any;
	index: number;
	townCode: string;
	countyCode?: string;
	villageList?: Array<any>;
	updateClass?: (changedValues, allValues, index) => void;
	changeList?: (index, key) => void;
}

interface PicInfoState {
	forestList: Array<any>;
	smallClassList: Array<any>;
	villageCode: string;
	forestCode: string;
}

// 新增验收照片选择
export class PicInfo extends Component<PicInfoProps, PicInfoState> {
	constructor(props) {
		super(props);
		this.state = {
			forestList: [],
			smallClassList: [],
			villageCode: '',
			forestCode: '',
		};
	}

	//行政村选择
	changeSelect = (value, key) => {
		const { countyCode, townCode, time } = this.props;
		const { villageCode } = this.state;
		switch (key) {
			case 1:
				Common.getForestList({
					countyCode: countyCode,
					townCode,
					villageCode: value,
					isReport: true,
					...time
				}).then((res) => {
					if (res.data.code === 200) {
						this.setState({
							villageCode: value,
							forestList: res.data.data,
						});
					}
				});
				break;
			case 2:
				Acceptance.getClassList({
					countyCode: countyCode,
					townCode,
					villageCode,
					forestCode: value,
					isReport: true,
					...time
				}).then((res) => {
					if (res.data.code === 200) {
						this.setState({
							forestCode: value,
							smallClassList: res.data.data,
						});
					}
				});
				break;
		}
	};

	render() {
		const { villageList, data, index, updateClass, changeList } = this.props;
		const { forestList, smallClassList } = this.state;
		return (
			<div className={'picInfo-box'}>
				<Form
					onValuesChange={(changedValues, allValues) =>
						updateClass(changedValues, allValues, index)
					}
					initialValues={{ ...data }}
				>
					<FormItem
						name="villageCode"
						label="行政村"
						rules={[
							{
								required: true,
								message: '请选择行政村',
							},
						]}
						{...layout}
					>
						<Select onSelect={(value) => this.changeSelect(value, 1)}>
							{villageList.map((item, index) => {
								return (
									<Option key={index} value={item.code}>
										{item.name}
									</Option>
								);
							})}
						</Select>
					</FormItem>
					<FormItem
						name="forestCode"
						label="林班"
						rules={[
							{
								required: true,
								message: '请选择林班',
							},
						]}
						{...layout}
					>
						<Select onSelect={(value) => this.changeSelect(value, 2)}>
							{forestList.map((item, index) => {
								return (
									<Option key={index} value={item.code}>
										{item.name}
									</Option>
								);
							})}
						</Select>
					</FormItem>
					<FormItem
						name="smallClassList"
						label="小班号"
						rules={[
							{
								required: true,
								message: '请选择小班号',
							},
						]}
						{...layout}
					>
						<Select mode="multiple" showArrow={true} maxTagCount={2}>
							{smallClassList.map((item, index) => {
								return (
									<Option key={index} value={item.smallClass}>
										{item.smallClass}
									</Option>
								);
							})}
						</Select>
					</FormItem>
				</Form>
				<Button
					type="primary"
					onClick={() => changeList(index, 2)}
					className={'del-btn'}
				>
					删除
				</Button>
			</div>
		);
	}
}
