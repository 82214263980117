import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
import img1 from '../../assets/icons/label/l1.svg';
import img2 from '../../assets/icons/label/r3.svg';
export function Label({
  title,
  describe,
  width,
  style,
}: {
  title: string;
  describe: string;
  data?: any;
  width?: any;
  style?: React.CSSProperties;
}) {
  const titleWidth: any = useRef();
  const [titleWid, setTitleWid] = useState(0);
  useEffect(() => {
    setTitleWid(titleWidth.current.offsetWidth);
  }, []);

  return (
    <div className="label-box" style={{ width: width, ...style }}>
      <img src={img1} className="img1" alt="" />
      <div className="title" ref={titleWidth}>
        {title}
      </div>
      <div className="describe" style={{ width: `${width - titleWid - 47}px` }}>
        {describe}
      </div>
      <img src={img2} className="img2" alt="" />
    </div>
  );
}
