interface Props {
  activeKey: number;
  changeKey: (value: number) => void;
}

const CustomTabs = ({ activeKey, changeKey }: Props) => {
  const tabs = [
    {
      key: 1,
      label: "伐桩照片",
    },
    {
      key: 2,
      label: "航拍照片",
    },
  ];

  return (
    <div className="custom_tabs">
      {tabs.map((item) => {
        return (
          <div
            key={item.key}
            className={`tabs_item ${item.key === activeKey && "active_item"}`}
            onClick={() => changeKey(item.key)}
          >
            {item.label}
          </div>
        );
      })}
    </div>
  );
};

export default CustomTabs;
