export const config = {
  service: "WMS",
  request: "GetMap",
  version: "1.1.1",
  layers: "pine-epidemic-census:ls_elevation",
  transparent: true,
  styles: "",
  format: "image/png",
  exceptions: "application/vnd.ogc.se_inimage",
  srs: "EPSG:4326",
  zIndex: 2,
  maxZoom: 22,
  width: 256,
  height: 256,
};
export const woodLayer = new window["CWMSLayer"]({
  zIndex: 1,
  url: `${window["env"].LayerUrl}/geoserver/pine-epidemic-census/wms`,
  params: {
    id: "wms-layer",
    ...config,
    width: 256,
    height: 256,
    srs: "EPSG:4549",
    layers: "pine-epidemic-census:tree_identify",
    viewparams: `areaCode:${331100};areaLevel:${1};year:2021-2022`,
  },
});
//2021-2022的感染程度
export const InfectLayer = new window["CWMSLayer"]({
  zIndex: 1,
  url: `${window["env"].LayerUrl}/geoserver/pine-epidemic-census/wms`,
  params: {
    id: "wms-layer",
    ...config,
    srs: "EPSG:4549",
    layers: "pine-epidemic-census:infect_degree",
    viewparams: `areaCode:${331100};areaLevel:${1};year:2021-2022`,
  },
});
//之后的感染程度小班
export const newInfectLayer = new window["CWMSLayer"]({
  zIndex: 1,
  url: `${window["env"].LayerUrl}/geoserver/pine-epidemic-census/wms`,
  params: {
    id: "wms-layer",
    ...config,
    srs: "EPSG:4549",
    layers: "pine-epidemic-census:view_tree_handle_infect_degree",
    viewparams: `areaCode:${331100};areaLevel:${1};year:2022-2023`,
  },
});

export const checkLayer = new window["CWMSLayer"]({
  zIndex: 1,
  url: `${window["env"].LayerUrl}/geoserver/pine-epidemic-census/wms`,
  params: {
    id: "wms-layer",
    ...config,
    srs: "EPSG:4549",
    layers: "pine-epidemic-census:view_tree_handle_class_complete",
    viewparams: `areaCode:${331100};areaLevel:${1};year:2021-2022`,
  },
});

export const analysisLayer = new window["CWMSLayer"]({
  zIndex: 1,
  url: `${window["env"].LayerUrl}/geoserver/pine-epidemic-census/wms`,
  params: {
    id: "wms-layer",
    ...config,
  },
});

export const fristFrigLayer = new window["CWMSLayer"]({
  zIndex: 1,
  url: `${window["env"].LayerUrl}/geoserver/pine-epidemic-census/wms`,
  params: {
    id: "wms-layer",
    ...config,
    layers: "pine-epidemic-census:grid",
    viewparams: "level:1",
  },
});

export const secondFrigLayer = new window["CWMSLayer"]({
  zIndex: 1,
  url: `${window["env"].LayerUrl}/geoserver/pine-epidemic-census/wms`,
  params: {
    id: "wms-layer",
    ...config,
    layers: "pine-epidemic-census:grid",
    viewparams: "level:2",
  },
});

export const completeFrigLayer = new window["CWMSLayer"]({
  //已排查完成网格
  zIndex: 1,
  url: `${window["env"].LayerUrl}/geoserver/pine-epidemic-census/wms`,
  params: {
    id: "wms-layer",
    ...config,
    layers: "pine-epidemic-census:identify_grid_complete",
    timestamp: Date.now(),
  },
});

export const textLayer = new window["CWMSLayer"]({
  //已排查完成网格
  zIndex: 1,
  url: `http://47.96.112.120:9000/geoserver/pine-epidemic-census/wms`,
  params: {
    id: "wms-layer",
    layers: "pine-epidemic-census:tree_identify_liandu_20221109",
  },
});

export const lianduRedPointLayer = new window["CWMSLayer"]({
  //新增莲都红色点图层
  zIndex: 2,
  url: `http://192.168.1.80:8080/geoserver/test/wms`,
  params: {
    id: "wms-layer",
    layers: "test:temp_class_view",
  },
});
