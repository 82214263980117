import axios from "axios";
import exif from "exif-js";
import { message } from "antd";
import XMP from "xmp-js";
import { downloadShuiyin } from "./watermark";
import { newAxios } from "./axios";
import Common from "../platform/services/Common";

// 处理图片(传入的为list，图片文件为每个数据项的file字段)
export const handleImage = (list) => {
  return new Promise((reslove, reject) => {
    if (list.length > 0) {
      ExifImage(list).then((res1) => {
        // 筛选有经纬度的图像数据进入下一轮解析
        GetImageAddress(res1).then((res2) => {
          XmpImage(res2).then((res4: Array<any>) => {
            res4 = res4.map((item) => {
              return {
                ...item,
                userName: JSON.parse(localStorage.getItem("token"))
                  ? JSON.parse(localStorage.getItem("token")).name
                  : "",
              };
            });
            AddWaterMarker(res4).then((res5) => {
              reslove(res5);
            });
          });
          // })
        });
      });
    } else {
      reslove([]);
    }
  });
};

// 处理图片(同上，但不包括加水印过程，仅仅是解析图片信息)
export const handleImage2 = (list) => {
  return new Promise((reslove, reject) => {
    if (list.length > 0) {
      ExifImage(list).then((res1) => {
        // 筛选有经纬度的图像数据进入下一轮解析
        GetImageAddress(res1).then((res2) => {
          XmpImage(res2).then((res4: Array<any>) => {
            res4 = res4.map((item) => {
              return {
                ...item,
                userName: JSON.parse(localStorage.getItem("token"))
                  ? JSON.parse(localStorage.getItem("token")).name
                  : "",
              };
            });
            reslove(res4);
          });
        });
      });
    } else {
      reslove([]);
    }
  });
};

// 处理图片(解析图片信息,但包含解析小班信息)
export const handleImage3 = (list) => {
  return new Promise((reslove, reject) => {
    if (list.length > 0) {
      ExifImage(list).then((res1) => {
        // 筛选有经纬度的图像数据进入下一轮解析
        GetImageAddress(res1).then((res2) => {
          GetImageClassInfo(res2).then((res3) => {
            reslove(res3);
          });
        });
      });
    } else {
      reslove([]);
    }
  });
};

// 1.解析图片经纬度，高度等基本信息
export const ExifImage = (list) => {
  let arr = list;
  arr.map((item, index) => {
    if (window.location.href.indexOf("check") > 0 && !item?.point) {
      const match = item.name.match(/(-?\d+\.\d+)°N,(-?\d+\.\d+)°E/);
      if (match) {
        // match[1] 匹配纬度值
        const latitude = parseFloat(match[1]);

        // match[2] 匹配经度值
        const longitude = parseFloat(match[2]);
        list[index].point = {
          x: longitude,
          y: latitude,
        };
      }
    }
  });
  return new Promise((resolve, reject) => {
    let newList = [];
    //筛选是否有不正常照片
    const filterPromise = new Promise((resl, reject) => {
      let filterData = [];
      list.filter((item, index) => {
        !item.origin &&
          exif.getData(item.file, () => {
            filterData.push(item);
          });
        if (index === list.length - 1) {
          setTimeout(() => {
            resl(filterData);
          }, 45 * list.length); //45ms是一个临界值
        }
      });
    });
    filterPromise.then((filterlist: any) => {
      console.log(filterlist);

      list.map((item) => {
        if (item.origin) {
          newList.push(item);
          if (newList.length === list.length) {
            resolve(newList);
          }
        } else {
          let result = {
            ...item,
            altitude: null,
            point: {
              x: null,
              y: null,
            },
            time: null,
          };

          exif.getData(item.file, () => {
            const info = exif.getAllTags(item.file);
            let { GPSLongitude, GPSLatitude, GPSAltitude, DateTime } = info;
            if (GPSLongitude && GPSLatitude && GPSLatitude) {
              GPSLongitude =
                typeof GPSLongitude == "object"
                  ? GPSLongitude[0] +
                    GPSLongitude[1] / 60 +
                    GPSLongitude[2] / 3600
                  : GPSLongitude;
              GPSLatitude =
                typeof GPSLatitude == "object"
                  ? GPSLatitude[0] + GPSLatitude[1] / 60 + GPSLatitude[2] / 3600
                  : GPSLatitude;
              result.altitude = parseFloat(GPSAltitude).toFixed(1);
              result.point = {
                x: parseFloat(GPSLongitude).toFixed(6),
                y: parseFloat(GPSLatitude).toFixed(6),
              };
              if (DateTime) {
                const img_time = DateTime.split(" ");
                result.time = `${img_time[0].replace(/:/g, "-")} ${
                  img_time[1]
                }`;
              } else {
                result.time = "无拍摄时间";
              }
              newList.push(result);
              if (newList.length === list.length) {
                resolve(newList);
              }
            } else {
              if (DateTime) {
                const img_time = DateTime.split(" ");
                result.time = `${img_time[0].replace(/:/g, "-")} ${
                  img_time[1]
                }`;
              } else {
                result.time = "无拍摄时间";
              }
              result.altitude = 0;
              // message.error("图像缺少经纬度等信息，解析失败");
              newList.push(result);
              if (newList.length === list.length) {
                resolve(newList);
              }
            }
          });
        }
      });
    });
  });
};

// 2.获取图片位置信息
export const GetImageAddress = (list) => {
  return new Promise((resolve, reject) => {
    let newList = [];
    list.map((item) => {
      if (item.origin) {
        newList.push(item);
        if (newList.length === list.length) {
          resolve(newList);
        }
      } else {
        const { x, y } = item.point;
        if (x && y) {
          const location = x + "," + y;
          newAxios
            .get(
              `https://restapi.amap.com/v3/geocode/regeo?key=724c8cb3c10e74621cc7b1bd13562e33&location=${location}`
            )
            .then((res) => {
              if (res.data.status === "1") {
                const address = res.data.regeocode.formatted_address;
                newList.push({
                  ...item,
                  address: address.length === 0 ? "" : address,
                });
                if (newList.length === list.length) {
                  resolve(newList);
                }
              } else {
                newList.push({ ...item, address: "" });
                if (newList.length === list.length) {
                  resolve(newList);
                }
              }
            });
        } else {
          newList.push({ ...item, address: "" });
          if (newList.length === list.length) {
            resolve(newList);
          }
        }
      }
    });
  });
};

// 3.获取图片小班信息
export const GetImageClassInfo = (list) => {
  return new Promise((resolve, reject) => {
    let newList = [];
    list.map((item) => {
      if (item.origin) {
        newList.push(item);
        if (newList.length === list.length) {
          resolve(newList);
        }
      } else {
        const { x, y } = item.point;
        if (x && y) {
          Common.getClassLonlat({
            x,
            y,
            year: "2022-2023",
            countyCode: "332200",
          }).then((res) => {
            if (res.data.data) {
              const { townName, villageName, forestName, smallClass } =
                res.data.data;
              newList.push({
                ...item,
                classInfo: smallClass
                  ? `${townName}-${villageName}-${forestName}-${smallClass}`
                  : "",
              });
              if (newList.length === list.length) {
                resolve(newList);
              }
            } else {
              newList.push({ ...item, classInfo: "" });
              if (newList.length === list.length) {
                resolve(newList);
              }
            }
          });
        } else {
          newList.push({ ...item, classInfo: "" });
          if (newList.length === list.length) {
            resolve(newList);
          }
        }
      }
    });
  });
};

// 4.解析DJI图像朝向yaw字段
export const XmpImage = (list) => {
  return new Promise((resolve, reject) => {
    let newList = [];
    list.map((item) => {
      if (item.origin) {
        newList.push(item);
        if (newList.length === list.length) {
          resolve(newList);
        }
      } else {
        if (
          item.file.name.toUpperCase().startsWith("DJI") &&
          item.type === 2 &&
          item.point.x &&
          item.point.y
        ) {
          const reader = new FileReader();
          reader.onload = (e) => {
            let xmp = new XMP(e.target.result);
            let raw = xmp.find();
            const result = JSON.stringify(raw);
            const index1 = result.indexOf("drone-dji:GimbalYawDegree=");
            if (index1 !== -1) {
              const start: any = result.substring(
                index1 + "drone-dji:GimbalYawDegree=".length + 2
              );
              const end = start.indexOf('"') - 1;
              let yaw = start.substring(start, end);
              if (yaw.startsWith("+")) {
                yaw = yaw.substring(1);
              }
              newList.push({ ...item, yaw });
              if (newList.length === list.length) {
                resolve(newList);
              }
            } else {
              newList.push(item);
              if (newList.length === list.length) {
                resolve(newList);
              }
            }
          };
          reader.readAsDataURL(item.file);
        } else {
          newList.push(item);
          if (newList.length === list.length) {
            resolve(newList);
          }
        }
      }
    });
  });
};

// 添加水印(必须是航拍图像type=2且以DJI开头)
export const AddWaterMarker = (list) => {
  return new Promise((resolve, reject) => {
    let newList = [];
    list.map((item) => {
      if (item.origin) {
        newList.push(item);
        if (newList.length === list.length) {
          resolve(newList);
        }
      } else {
        // 航拍照片打水印
        if (
          item.file.name.toUpperCase().startsWith("DJI") &&
          item.file &&
          item.point.x &&
          item.point.y
        ) {
          downloadShuiyin(item).then((data) => {
            newList.push({ ...item, shuiYinFile: data });
            if (newList.length === list.length) {
              resolve(newList);
            }
          });
        } else {
          newList.push(item);
          if (newList.length === list.length) {
            resolve(newList);
          }
        }
      }
    });
  });
};

// 上传图片(正常调用接口)
export const uploadImage = (list) => {
  return new Promise((resolve, reject) => {
    let newList = [];
    if (list.length === 0) {
      resolve(newList);
    } else {
      list.map((item, index) => {
        if (item.origin) {
          newList.push(item);
          if (newList.length === list.length) {
            resolve(newList);
          }
        } else {
          const formData: any = new FormData();
          formData.append("type", 2);
          formData.append(
            "file",
            item.type === 1 ? item.file : item.shuiYinFile
          );
          axios.post("upload", formData).then((r) => {
            if (r.data.code === 200) {
              newList.push({
                ...item,
                uid: `pic_${index}`,
                status: "done",
                url: r.data.data,
              });
              if (newList.length === list.length) {
                resolve(newList);
              }
            } else {
              newList.push({
                ...item,
                uid: `pic_${index}`,
                status: "error",
                url: "",
              });
              if (newList.length === list.length) {
                resolve(newList);
              }
              message.error(`图片${item.name}解析成功但上传失败`);
            }
          });
        }
      });
    }
  });
};

// 上传图片(直接上传到OSS)
export const uploadImage2 = (list) => {
  const upload = window["oss-upload"];
  const url = window["env"].ACCEPTANCE_PICURL;
  // console.log(upload);
  // getOss()
  const headers = {
    // 指定该Object被下载时网页的缓存行为。
    // 'Cache-Control': 'no-cache',
    // 指定该Object被下载时的名称。
    // 'Content-Disposition': 'oss_download.txt',
    // 指定该Object被下载时的内容编码格式。
    // 'Content-Encoding': 'UTF-8',
    // 指定过期时间。
    // 'Expires': 'Wed, 08 Jul 2022 16:57:01 GMT',
    // 指定Object的存储类型。
    // 'x-oss-storage-class': 'Standard',
    // 指定Object的访问权限。
    // 'x-oss-object-acl': 'private',
    // 设置Object的标签，可同时设置多个标签。
    // 'x-oss-tagging': 'Tag1=1&Tag2=2',
    // 指定CopyObject操作时是否覆盖同名目标Object。此处设置为true，表示禁止覆盖同名Object。
    // 'x-oss-forbid-overwrite': 'true',
  };
  return new Promise((resolve, reject) => {
    let newList = [];
    if (list.length === 0) {
      resolve(newList);
    } else {
      list.map((item, index) => {
        if (item.origin) {
          newList.push(item);
          if (newList.length === list.length) {
            resolve(newList);
          }
        } else {
          const img = item.shuiYinFile ? item.shuiYinFile : item.file;
          let fileName =
            item.name.indexOf("°N") > 0
              ? `${url}${item.name}`
              : `${url}${item.name.split(".")[0]}-${Date.now()}.${
                  item.name.split(".")[1]
                }`;
          // console.log(item.name);
          // console.log(`${url}${item.name.split(".")[0]}-${Date.now()}.${item.name.split(".")[1]}`);
          upload.put(fileName, img, headers).then((r) => {
            if (r.res.status === 200) {
              newList.push({
                ...item,
                uid: `pic_${index}`,
                status: "done",
                url: r.url,
              });
              if (newList.length === list.length) {
                resolve(newList);
              }
            } else {
              newList.push({
                ...item,
                uid: `pic_${index}`,
                status: "error",
                url: "",
              });
              if (newList.length === list.length) {
                resolve(newList);
              }
              message.error(`图片${item.name}解析成功但上传失败`);
            }
          });
        }
      });
    }
  });
};

// 筛选出解析成功的照片(若含有经纬度就说明解析成功)
export const filterList = (list) => {
  return list.map((item, index) => {
    return item.point.x && item.point.y
      ? { ...item, uid: `pic_${index}`, status: "done", url: item.thumbUrl }
      : { ...item, uid: `pic_${index}`, status: "error", url: item.thumbUrl };
  });
};
