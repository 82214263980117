import { acceptance_axios as axios, kml_axios } from "../../utils/axios";

// 跟踪验收接口
export default class Acceptance {
  // 获取验收数据列表
  static getList(params) {
    return axios.get("/acceptance/list", { params });
  }

  // 获取验收数据列表
  static getClassList(params) {
    return axios.get("/acceptance/list_class", { params });
  }

  // 获取验收照片列表
  static getPicList({ id, isInspection, year }) {
    return axios.get("/acceptance/list_pic", {
      params: { id, isInspection, year },
    });
  }

  //获取全部验收照片  参数可传（按需加载）
  static getAllPicList(params, cancelToken) {
    return axios.get("/acceptance/list_pic", { params, cancelToken });
  }

  //小班编辑
  static classEidt(params) {
    return axios.post("/acceptance/integration", params);
  }

  // 添加验收数据
  static addData(params) {
    return axios.post("/acceptance/add", params);
  }

  // 删除验收数据
  static deleteData({ id }) {
    return axios.delete(`/acceptance/delete/${id}`);
  }

  // 监理检查删除验收数据
  static deleteCheckData({ id }) {
    return axios.delete(`/inspection/delete/${id}`);
  }
  //批量删除
  static batchDelData(params) {
    return axios.post(`/acceptance/batch_delete`, params);
  }

  //监理检查批量删除
  static batchCheckDelData(params) {
    return axios.post(`/inspection/batch_delete`, params);
  }

  //删除验收图片
  static deletePic({ id }) {
    return axios.delete(`/acceptance/delete_pic?id=${id}`);
  }

  // 修改列表验收数据
  static updateData(params) {
    return axios.post("/acceptance/admin_update", params);
  }

  // 修改列表验收监理检查数据
  static updateCheckData(params) {
    return axios.post("/inspection/admin_update", params);
  }

  // 修改智能上报验收数据
  static updateSmartData(params) {
    return axios.post("/acceptance/integration", params);
  }

  // 获取历史验收记录
  static getHistoryData({ classId, isInspection, year }) {
    return axios.get("/acceptance/list_history", {
      params: { classId, isInspection, year },
    });
  }

  // 获取验收数据报告
  static getReport(params) {
    return axios.post("/acceptance/get_report", params);
  }

  // 获取整改数据报告
  static getRectReport(params) {
    return axios.post("/acceptance/get_change_report", params);
  }

  // 获取验收数据报告
  static getTodayReport(params) {
    return axios.post("/acceptance/get_report_today", params);
  }

  //批量修改小班
  static ChangeAll(params) {
    return axios.put("/acceptance/batch_update", params);
  }

  // 同步验收数据
  static syncData() {
    return axios.get("/acceptance/sync");
  }

  //智能上报
  static smartAdd(params) {
    return axios.post("/acceptance/smart_add", params);
  }

  //根据小班id获取照片列表
  static getPicByClassid({ classId, year, userFlag, isInspection }) {
    return axios.get("/acceptance/list_pic_by_classId", {
      params: { classId, year, userFlag, isInspection },
    });
  }

  //根据小班id删除验收图片
  static delPicByClassid(params) {
    return axios.delete("/acceptance/del_pic_by_classId", { params });
  }

  //获取智能上报验收小班和轮次
  static getAccNum(params) {
    return axios.get("/acceptance/get_acceptance_num", { params });
  }

  static putMovePic(params) {
    return axios.put("/acceptance/move_pic", params);
  }

  //获取某人某天拍摄最早照片
  static getSomeBPic(params) {
    return axios.get("/log_info/get_earliest_pic", { params });
  }

  //获取扣分项数据/acceptance/mark_institution/query_deduct_items
  static getSubScore(params) {
    return axios.get("/mark_institution/query_deduct_items", { params });
  }
  ///导出质量验收记录表
  static getAccTable(params) {
    return kml_axios.get("/acceptance/export_quality_acceptance_table", {
      params,
    });
  }

  ///获取用户当天完成上报的小班
  static getTodayClass(params) {
    return axios.get("/acceptance/get_workload_today_user", { params });
  }

  //获取当前小班信息
  static getAcceptInfo(params) {
    return axios.get("/common/get_all_accept_info_by_point", { params });
  }

  // 获取除治情况统计表
  static exportEliminationExcel({ countyCode, year }) {
    return axios.get("/cut_report/get_elimination_report", {
      params: {
        countyCode,
        year,
      },
      responseType: "blob",
    });
  }

  // 导出砍伐区域kml
  static exportEliminationKML({
    countyCode,
    year,
    startTime,
    endTime,
    styleType,
  }) {
    return axios.get("/cut_report/get_cut_kml", {
      params: {
        countyCode,
        year,
        startTime,
        endTime,
        styleType,
      },
      responseType: "blob",
    });
  }

  // 分页查询错误的验收记录
  static getMistakeRecord({ year, page, size }) {
    return axios.get("/acceptance/query_mistake_record", {
      params: {
        year,
        page,
        size,
      },
    });
  }

  // 批量修改错误的验收记录的状态
  static UpdateMistakeRecord({ ids, status }) {
    return axios.put("/acceptance/batch_update_mistake_record_status", {
      ids,
      status,
    });
  }

  // 查询错误的验收记录数量
  static getMistakeRecordNum() {
    return axios.get("/acceptance/query_mistake_record_num");
  }

  // 获取区域类型列表
  static getKMLTypeList() {
    return axios.get("/common/list_kml_type");
  }

  // 新智能上报
  static uploadSmartAddNew({ params, data }) {
    return axios.post("/acceptance/new_smart_add", data, {
      params,
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  static modifyAcceptanceHistory({ params, data }) {
    return axios.put("/acceptance/modify_acceptance_history", data, {
      params,
    });
  }

  // 导出报告压缩包
  static exportReportZip(data) {
    return axios.post("/acceptance/create_report_zip", data);
  }

  // 根据条件分页查询历史报告
  static getReportHistory(page, size, params) {
    return axios.get("/history_report/query", {
      params: {
        page,
        size,
        ...params,
      },
    });
  }

  // 删除历史报告
  static delReportHistory(id: number) {
    return axios.delete(`/history_report/delete/${id}`);
  }
}
