import React, { Component } from "react";
import Footer from "./footer";
import Navigator from "./navigator";
import { AcceptData, LeftClass } from "./left/LeftClass";
import LeftNoClass from "./left/LeftNoClass";
import { RightClass, RightNoClass, AcceptClass } from "./right";
import Request from "../services/Manger";
import Acceptance from "../platform/services/Acceptance";
import OverlayImage from "./overlay";
import {
  config,
  woodLayer,
  InfectLayer,
  newInfectLayer,
  checkLayer,
  fristFrigLayer,
  secondFrigLayer,
  completeFrigLayer,
  lianduRedPointLayer,
} from "./navigator/data";
import icon from "../assets/icons/label/circle.svg";
import "./index.scss";
import {
  CMap,
  CMarker,
  CPolygon,
  CWMSLayer,
  CText,
  CXYZLayer,
  CMultiPolygon,
} from "../utils/CMap";
import { message } from "antd";
import ScaleContainer from "../utils/ScaleContainer";
import PlagueWoodTs from "../platform/services/plagueWood";
import "animate.css";
import Overlay from "ol/Overlay";
import ReactDOM from "react-dom/client";
import Popup from "./popup";
import axios from "axios";
import OlMap from "../utils/olmap";

import cutting_check from "../assets/images/plane/cutting_check.svg";

interface Props {}

const cityList = [
  {
    name: "丽水市",
    position: [119.63104, 28.09652],
    areaCode: "331100",
    zoom: 9,
  },
  {
    name: "温州市",
    position: [120.672111, 28.000575],
    areaCode: "330300",
    zoom: 9,
  },
  {
    name: "金华市",
    position: [120.15, 28.89652],
    areaCode: "330700",
    zoom: 11,
  },
  {
    name: "衢州市",
    position: [118.87263, 28.941708],
    areaCode: "330800",
    zoom: 9,
  },
];

const haibaList = [
  { label: "0-300", quantity: "0-300", color: "#87e78f" },
  { label: "300-600", quantity: "300-600", color: "#ffbe38" },
  { label: "600-1100", quantity: "600-1100", color: "#8959ff" },
  { label: "1100-5000", quantity: "1100-5000", color: "#50a3ff" },
];

let map;
let lay = [];
const droneList = [
  0, 22.5, 45, 67.5, 90, 112.5, 135, 157.5, 180, 202.5, 225, 247.5, 270, 292.5,
  315, 337.5, 360,
];

class IndexPage extends Component<any, any> {
  token = JSON.parse(localStorage.getItem("token"));
  Clusterer; //点聚合对象
  pointLayers; //点面对
  constructor(props: Props) {
    super(props);
    this.state = {
      username: this.props?.location?.state?.username ?? this.token.name,
      isOperat: false, //是否开始操作疫木
      mode: this.props?.location?.state?.mode, //1有小班模式，2无小班模式
      code: this.props?.location?.state?.code,
      fatherCode: this.props.location.state.propsareaCode,
      ldCode: null,
      areaName: this.props.location.state.name,
      select: {},
      level: this.props.location.state.level,
      area: 0,
      theName:
        this.props.location.state.name === "金华市"
          ? "永康市"
          : this.props.location.state.name,
      isSchedule: false, //是否开启了排查进度
      isWoodOpen: false, //疫木点位是否打开
      isDdentify: false,

      regionLayer: [], //行政区边界图层
      classLayer: null, //小班边框图层
      regionTab: [], //行政区标签图层
      regionMaker: [], //行政区标签圆点
      DiyClassLayer: [], //视角范围加载的小班图层
      ClassName: [], //小班名字

      woodLayer: woodLayer, //疫木图层
      InfectLayer: InfectLayer, //感染图层
      lianduRedPointLayer: lianduRedPointLayer,
      lianduLayerCheck: false,
      isInfectOpen: false,
      newInfectLayer: newInfectLayer,
      checkLayer: checkLayer,
      isCheckOpen: false,

      fristFrigLayer: fristFrigLayer, //一级网格图层
      secondFrigLayer: secondFrigLayer, //二级网格图层
      completeFrigLayer: completeFrigLayer, //已排查完成网格
      lableList: [],
      year: sessionStorage.getItem("year"),
      AcceptState: localStorage.getItem("AcceptState") ? false : true,
      smallClassYear: "",
      overlayList: [],
      markerList: [], //点位列表
      imageList: [], //伐桩列表
      layerPass: null, //验收通过图层
      layerUnpass: null, //验收不通过图层
      layerNoAccept: null, //未验收图层
    };
    this.initiLayer();
  }

  componentDidMount() {
    // console.log(this.state.code)
    //http://47.96.112.120:7005/geoserver/pine-epidemic-census/wms?service=WMS&version=1.1.0&request=GetMap&layers=pine-epidemic-census%3Atest_gengerPoint&bbox=119.87055%2C28.1960699999998%2C119.87057%2C28.1960899999998&width=768&height=767&srs=EPSG%3A4326&format=application/openlayers
    // const layer = new CWMSLayer({
    // 	zIndex: 1,
    // 	url: `http://47.96.112.120:7005/geoserver/pine-epidemic-census/wms`,
    // 	params: {
    // 		id: 'linshi-layer',
    // 		service: "WMS",
    // 		request: "GetMap",
    // 		version: "1.1.0",
    // 		transparent: true,
    // 		styles: "",
    // 		format: "image/png",
    // 		srs: 'EPSG:4549',
    // 		layers: 'pine-epidemic-census:test_gengerPoint',
    // 	},
    // });
    // map.addLayer(layer)
    map.addMapEvent("click", this.enterMap);
    const { code, level } = this.state;
    const tokenAccount = JSON.parse(localStorage.getItem("token"));

    let isTourist = localStorage.getItem("role") === "ROLE_2";
    let touristAreaCode = tokenAccount.areaCode;
    let touristAreaLevel = tokenAccount.areaLevel;

    if (isTourist) {
      this.setState({
        theName: tokenAccount.areaName,
        areaName: tokenAccount.areaName,
        code: code,
        level: tokenAccount.areaLevel,
        fatherCode: code,
      });
    }

    if (code === 331127) {
      this.setState({
        theName: "景宁县",
        areaName: "景宁县",
        code: "331127",
        level: 2,
        fatherCode: "331127",
      });
    }
    if (code === 330784) {
      this.setState({
        theName: "永康市",
      });
    }

    this.getAreaList(
      isTourist ? touristAreaCode : code,
      isTourist ? touristAreaLevel : level
    );
    // map.addMapEvent(CMapEvent.Zoom, this.getDiyClassLayer)
    map.addMapEvent("pointermove", this.checkMouse);
    // map.removeMapEvent("pointermove", this.checkMouse)
  }
  enterMap = (e) => {
    const { level, code, year } = this.state;
    const feature = map.map.forEachFeatureAtPixel(e.pixel, (feature: any) => {
      return feature;
    });
    if (feature && feature.values_.id.indexOf("http") >= 0) {
      const ref = document.createElement("div");
      ref.style.width = "120px";
      ref.style.height = "140px";
      const props = { url: feature.values_.id };
      const element = React.createElement(OverlayImage, props);
      const root = ReactDOM.createRoot(ref);
      root.render(element);
      var overlay = new Overlay({
        id: feature.values_.id,
        element: ref, //绑定 Overlay 对象和 DOM 对象的
        autoPan: true, // 定义弹出窗口在边缘点击时候可能不完整 设置自动平移效果
        offset: [0, 0], //偏移量，使得弹出框显示在坐标点正上方
        autoPanAnimation: {
          duration: 250, //自动平移效果的动画时间 9毫秒
        },
        insertFirst: false,
        position: e.coordinate,
        // zIndex: this.state.overlayList.length + 2
      });
      let array = this.state.imageList;
      array.push(overlay);
      this.setState({
        imageList: array,
      });
      map.map.addOverlay(overlay);
      const closeButton = overlay.getElement().querySelector(".closeImage");
      closeButton.addEventListener("click", () => {
        map.map.removeOverlay(overlay);
      });
      setTimeout(() => {
        map.map.removeOverlay(overlay);
      }, 8000);
    } else if (level === 3) {
      const params = {
        year: year,
        areaCode: code,
        x: e.coordinate[0],
        y: e.coordinate[1],
      };
      Request.getCensusClassLonlat(params, (res) => {
        if (res.code === 200) {
          const ref = document.createElement("div");
          ref.style.width = "300px";
          ref.style.height = "100px";
          // console.log(this.state.overlayList)
          // ref.style.zIndex = this.state.overlayList.length + 2
          const props = {
            treeNum: res.data.treeNum,
            smallClassName: res.data.smallClassName,
          };
          const element = React.createElement(Popup, props);
          const root = ReactDOM.createRoot(ref);
          root.render(element);

          var overlay = new Overlay({
            id: res.data.id,
            element: ref, //绑定 Overlay 对象和 DOM 对象的
            autoPan: true, // 定义弹出窗口在边缘点击时候可能不完整 设置自动平移效果
            offset: [0, 0], //偏移量，使得弹出框显示在坐标点正上方
            autoPanAnimation: {
              duration: 250, //自动平移效果的动画时间 9毫秒
            },
            insertFirst: false,
            position: e.coordinate,
            // zIndex: this.state.overlayList.length + 2
          });
          let arr = this.state.overlayList;
          arr.push(overlay);
          this.setState(
            {
              overlayList: arr,
            },
            () => {
              map.map.addOverlay(overlay);
              setTimeout(() => {
                arr.map((item, index) => {
                  if (overlay === map.map.getOverlayById(item.id)) {
                    this.setState({
                      overlayList: arr.splice(index, 1),
                    });
                    map.map.removeOverlay(overlay);
                  }
                });
              }, 5000);
            }
          );
          // console.log(arr)
          // if (arr.length > 0) { //判断是不是第一次点击
          // 	arr.map((item, index) => {
          // 		if (map.map.getOverlayById(item.id) === overlay) {
          // 			map.map.removeOverlay(item)
          // 			this.setState({
          // 				overlayList: arr.splice(index, 1)
          // 			}, () => {
          // 				arr.push(overlay)
          // 				this.setState({
          // 					overlayList: arr
          // 				}, () => {
          // 					map.map.addOverlay(overlay)
          // 					setTimeout(() => {
          // 						arr.map((item, index) => {
          // 							if (overlay === map.map.getOverlayById(item.id)) {
          // 								this.setState({
          // 									overlayList: arr.splice(index, 1)
          // 								})
          // 								map.map.removeOverlay(overlay)
          // 							}
          // 						})
          // 					}, 5000)
          // 				})
          // 			})
          // 			map.map.removeOverlay(overlay)
          // 		} else {
          // 			arr.push(overlay)
          // 			this.setState({
          // 				overlayList: arr
          // 			}, () => {
          // 				map.map.addOverlay(overlay)
          // 				setTimeout(() => {
          // 					arr.map((item, index) => {
          // 						if (overlay === map.map.getOverlayById(item.id)) {
          // 							this.setState({
          // 								overlayList: arr.splice(index, 1)
          // 							})
          // 							map.map.removeOverlay(overlay)
          // 						}
          // 					})
          // 				}, 5000)
          // 			})
          // 		}
          // 	})
          // } else {
          // 	arr.push(overlay)
          // 	this.setState({
          // 		overlayList: arr
          // 	}, () => {
          // 		map.map.addOverlay(overlay)
          // 		setTimeout(() => {
          // 			arr.map((item, index) => {
          // 				if (overlay === map.map.getOverlayById(item.id)) {
          // 					this.setState({
          // 						overlayList: arr.splice(index, 1)
          // 					})
          // 					map.map.removeOverlay(overlay)
          // 				}
          // 			})
          // 		}, 5000)
          // 	})
          // }
        }
      });

      // console.log(map.map.getOverla)
    }
  };

  componentWillUnmount(): void {
    localStorage.removeItem("AcceptState");
  }

  //获取小班年份
  getSmallClassYear = (code, year) => {
    // const {  }
    PlagueWoodTs.getYearConfig({ areaCode: code, year: year }).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          smallClassYear: res.data.data.smallClassYear,
        });
      }
    });
  };

  // 大屏鼠标移入事件
  checkMouse = (element) => {
    const { regionLayer, level, regionMaker } = this.state;
    const goal = map.map.forEachFeatureAtPixel(
      element.pixel,
      (feature: any) => {
        return feature;
      }
    );
    // 鼠标碰到Feature时变成指针
    map.map.getTargetElement().style.cursor = goal ? "pointer" : "";
    if (level === 1 || level === 2) {
      if (goal) {
        regionLayer.forEach((item) => {
          item.overlay
            .getStyle()
            .getFill()
            .setColor(
              goal.getId().indexOf(item.overlay.getId()) !== -1
                ? "#005EFF30"
                : "#32c5ff28"
            );
          item.overlay
            .getStyle()
            .getStroke()
            .setColor(
              goal.getId().indexOf(item.overlay.getId()) !== -1
                ? "#FFFFFF"
                : "#989fa6"
            );
          item.overlay
            .getStyle()
            .setZIndex(
              goal.getId().indexOf(item.overlay.getId()) !== -1 ? 90 : 1
            );
          goal.changed();
        });
        regionMaker.forEach((item) => {
          item.overlay
            .getStyle()
            .setZIndex("1" + goal.getId() === item.overlay.getId() ? 99 : 91);
          goal.changed();
        });
      } else {
        regionLayer.forEach((item) => {
          item.overlay.getStyle().getFill().setColor("#32c5ff28");
          item.overlay.getStyle().getStroke().setColor("#989fa6");
          item.overlay.getStyle().setZIndex(1);
          item.overlay.changed();
        });
        regionMaker.forEach((item) => {
          item.overlay.getStyle().setZIndex(2);
          item.overlay.changed();
        });
      }
    }
  };

  getLayer = (id: string) => {
    return map.map
      .getLayers()
      .array_.find((layer: any) => !!layer.get("id") && id === layer.get("id"));
  };

  setOperat = (isOperat) => {
    this.setState({
      isOperat,
    });
  };

  changeSchedule = (isSchedule) => {
    this.setState({
      isSchedule,
    });
  };
  changeWoodOpen = (isWoodOpen) => {
    this.setState({ isWoodOpen });
  };

  changeInfectLayer = (isInfectOpen) => {
    //改变感染程度状态
    this.setState({ isInfectOpen });
  };
  changeCheckLayer = (isCheckOpen) => {
    //改变排查进度状态
    this.setState({ isCheckOpen });
  };
  changelableList = (lableList) => {
    this.setState({
      lableList,
    });
  };
  changeIsDdentify = (isDdentify) => {
    this.setState({
      isDdentify,
    });
  };

  changeGridLayer = (completeFrigLayer) => {
    this.setState({
      completeFrigLayer,
    });
  };
  /**
   * 初始化图层
   * @returns
   */
  initiLayer = () => {
    const { propsareaCode } = this.props.location.state;
    cityList.forEach((item) => {
      if (item.areaCode === propsareaCode) {
        map = new CMap(
          "CMap",
          {
            center: item.position,
            zoom: item.zoom,
            minZoom: 2, // 地图允许的最小层级
            maxZoom: 22,
            layers: [
              new CXYZLayer({
                url: `https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}`,
              }),
              // new CXYZLayer({
              //   url: `https://t{0-7}.tianditu.gov.cn/DataServer?T=img_w&x={x}&y={y}&l={z}&tk=c733cecd345e50683180224d3bcfb104`,
              // }),
            ],
            defaultLayer: "none",
            // defaultLayer: 'zg-img',
          },
          "ClNtEkqWjXla4vYxaPn+owJLGRb8pcSZvtbS+ozKKhLVG01KB/WBCo4VPHM/lT6soEXqsQdtn39g07uT8f0fVw=="
        );

        // map.addInteraction(defaults({ "altShiftDragRotate": false, }))
      }
    });
  };

  //改变父code
  changeFatherCode = (fatherCode) => {
    // console.log(fatherCode);
    this.setState({
      fatherCode,
    });
  };

  //改变平台区域
  areaChange = (code, level, theName) => {
    this.setState({
      code,
      level,
      theName,
    });
  };

  //修改年份
  changeYear = (year) => {
    this.setState({
      year,
    });
  };

  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {
    const {
      code,
      level,
      isWoodOpen,
      woodLayer,
      isInfectOpen,
      isCheckOpen,
      isDdentify,
      mode,
      year,
      classLayer,
      theName,
      lianduLayerCheck,
      AcceptState,
    } = this.state;
    //是否为验收统计
    if (
      (prevState.level !== level && level === 1) ||
      (prevState.AcceptState !== AcceptState && AcceptState)
    ) {
      this.removeAcceptLayer();
    }
    //是否为切换地区
    if (prevState.code !== code && !AcceptState && level !== 1) {
      this.removeAcceptLayer();
      this.addAcceptLayer();
    }
    //是否为切换层级
    if (prevState.AcceptState !== AcceptState && !AcceptState && level !== 1) {
      this.addAcceptLayer();
    }
    if (
      (prevState.level !== level && level !== 3) ||
      (prevState.AcceptState !== AcceptState && AcceptState)
    ) {
      this.removeList();
    }
    if (
      prevState.code !== code &&
      !AcceptState &&
      level === 3 &&
      prevState.level === 3
    ) {
      this.removeList();
    }
    if (
      (prevState.AcceptState !== AcceptState && !AcceptState && level === 3) ||
      (prevState.level !== level && level === 3 && !AcceptState)
    ) {
      this.addPicList();
    }
    if (year !== prevState.year) {
      this.addRegion(code, level);
    }
    if (theName === "莲都区" && lianduLayerCheck) {
      map.addLayer(lianduRedPointLayer);
    } else if (theName === "莲都区" && !lianduLayerCheck) {
      map.removeLayer(lianduRedPointLayer);
    }

    if (prevState.code !== this.state.code) {
      this.changeFatherCode(this.state.fatherCode);
      this.getAreaList(code, level);
    }

    //监听年份的变化
    if (prevState.year !== year) {
      //小班岁年份变化
      if (classLayer) {
        classLayer.setParams({
          viewparams: `areaCode:${code};areaLevel:${level};year:${year}`,
        });
      }
      //疫木点位随年度变化
      if (isWoodOpen) {
        woodLayer.setParams({
          viewparams: `areaCode:${code};areaLevel:${level};year:${year}`,
        });
      }
      //感染程度随年度变换
      if (isInfectOpen) {
        if (year === "2021-2022") {
          map.removeLayer(newInfectLayer);
          map.removeLayer(InfectLayer);
          InfectLayer.setParams({
            viewparams: `areaCode:${code};areaLevel:${level};year:${year}`,
          });
          map.addLayer(InfectLayer);
        } else {
          map.removeLayer(InfectLayer);
          map.removeLayer(newInfectLayer);
          newInfectLayer.setParams({
            viewparams: `areaCode:${code};areaLevel:${level};year:${year}`,
          });
          map.addLayer(newInfectLayer);
        }
      }
      //排查进度随年度变换
      if (isCheckOpen) {
        if (mode === 1) {
          checkLayer.setParams({
            viewparams: `areaCode:${code};areaLevel:${level};year:${year}`,
          });
        }
      }
    }

    //监听疫木点位的是否开启
    if (isWoodOpen !== prevState.isWoodOpen) {
      if (isWoodOpen && !isDdentify) {
        woodLayer.setParams({
          viewparams: `areaCode:${code};areaLevel:${level};year:${year}`,
        });

        map.addLayer(woodLayer);
      } else {
        map.removeLayer(woodLayer);
      }
    }

    //监听感染程度的是否开启
    if (isInfectOpen !== prevState.isInfectOpen) {
      if (isInfectOpen) {
        if (year === "2021-2022") {
          InfectLayer.setParams({
            viewparams: `areaCode:${code};areaLevel:${level};year:${year}`,
          });
          map.addLayer(InfectLayer);
        } else {
          newInfectLayer.setParams({
            viewparams: `areaCode:${code};areaLevel:${level};year:${year}`,
          });
          map.addLayer(newInfectLayer);
        }
      } else {
        map.removeLayer(InfectLayer);
        map.removeLayer(newInfectLayer);
      }
    }

    if (isCheckOpen !== prevState.isCheckOpen) {
      if (isCheckOpen) {
        //排查进度
        if (mode === 1) {
          checkLayer.setParams({
            viewparams: `areaCode:${code};areaLevel:${level};year:${year}`,
          });
          map.addLayer(checkLayer);
        } else {
          map.addLayer(secondFrigLayer);
          map.addLayer(fristFrigLayer);
          map.addLayer(completeFrigLayer);
        }
      } else {
        if (mode === 1) {
          map.removeLayer(checkLayer);
        } else {
          map.removeLayer(secondFrigLayer);
          map.removeLayer(fristFrigLayer);
          map.removeLayer(completeFrigLayer);
        }
      }
    }
  }

  addPicList = () => {
    // console.log(123)
    const { areaName, theName, markerList } = this.state;
    let cancel = null;
    const CancelToken = new axios.CancelToken(function executor(c) {
      cancel = c;
      //window.cancel = c
    });
    let newParams = {
      town: theName,
      year: sessionStorage.getItem("year"),
      isInspection: 0,
      photoType: 1,
    };

    let key;
    Acceptance.getAllPicList(newParams, CancelToken).then((res) => {
      if (res.data.code == 200) {
        // console.log(res)
        let arr = markerList;
        res.data.data.map((item) => {
          const marker = new CMarker({
            position: [item.point.x, item.point.y],
            marker: {
              id: item.url,
            },
            style: {
              iconUrl: cutting_check, //采用平台默认自带的定位图标,
              textOffsetY: -20,
              fontSize: "17px",
            },
          });
          arr.push(marker);
          map.addMarker(marker);
        });
        this.setState({
          markerList: arr,
        });
      } else {
        message.destroy();
        message.error(res.data.msg);
      }
    });
  };

  removeList = () => {
    // console.log(234)
    const { markerList, AcceptState, level } = this.state;
    markerList &&
      markerList.map((e) => {
        map.removeMarker(e);
      });
    this.setState(
      {
        markerList: [],
      },
      () => {
        if (!AcceptState && level === 3) {
          this.addPicList();
        }
      }
    );
  };

  //18层展示可视区域小班图层
  getDiyClassLayer = (e) => {
    const { DiyClassLayer, ClassName } = this.state;
    const [minX, minY, maxX, maxY] = map.getExtent();
    if (map.getZoom() >= 18) {
      DiyClassLayer.length === 0 &&
        Request.getDiyClassLayer(
          {
            minX,
            minY,
            maxX,
            maxY,
          },
          (res) => {
            if (res.code === 200) {
              res.data.forEach((item) => {
                const list = JSON.parse(item.json);
                let quLayers = []; //可视图层
                if (list) {
                  if (list.type === "MultiPolygon") {
                    list.coordinates[0][0].forEach((item) => {
                      quLayers.push(item);
                    });
                  }
                }
                const p = new CPolygon({
                  position: [quLayers],
                  marker: {
                    id: item.name,
                  },
                  style: {
                    fillColor: "#32c5ff28",
                    width: 1.5,
                    borderColor: "#989fa6",
                  },
                });
                lay[item.name] = p;
                DiyClassLayer.push(p);
                map.addMarker(p);

                // //在可视化区域中心加lab
                const bounds = p.overlay.geometryChangeKey_.target.extent_;
                const [minX, minY, maxX, maxY] = [
                  bounds[0],
                  bounds[1],
                  bounds[2],
                  bounds[3],
                ];
                const centerX = (minX + maxX) / 2;
                const centerY = (minY + maxY) / 2;
                const marker = new CText({
                  position: [centerX, centerY],
                  marker: {
                    id: "1" + item.name,
                    name: item.name,
                  },
                  style: {
                    textOffsetY: -20,
                    fontSize: "17px",
                  },
                });
                map.addMarker(marker);
                ClassName.push(marker);
              });
            }
            this.setState({
              DiyClassLayer,
              ClassName,
            });
          }
        );
    } else if (map.getZoom() < 18) {
      DiyClassLayer &&
        DiyClassLayer.forEach((e) => {
          map.removeMarker(e);
        });
      ClassName &&
        ClassName.forEach((e) => {
          map.removeMarker(e);
        });
      this.setState({
        DiyClassLayer: [],
        ClassName: [],
      });
    }
  };

  //获取区域列表-有小班
  getAreaList = (code, level) => {
    const {
      classLayer,
      woodLayer,
      InfectLayer,
      newInfectLayer,
      checkLayer,
      mode,
      year,
    } = this.state;
    woodLayer.setParams({
      viewparams: `areaCode:${code};areaLevel:${level};year:${year}`,
    });
    checkLayer.setParams({
      viewparams: `areaCode:${code};areaLevel:${level};year:${year}`,
    });
    if (year === "2021-2022") {
      InfectLayer.setParams({
        viewparams: `areaCode:${code};areaLevel:${level};year:${year}`,
      });
    } else {
      newInfectLayer.setParams({
        viewparams: `areaCode:${code};areaLevel:${level};year:${year}`,
      });
    }

    this.setState({
      woodLayer,
      InfectLayer,
      newInfectLayer,
    });
    Request.TownList(
      {
        areaCode: code,
        areaLevel: level,
        year: sessionStorage.getItem("year"),
      },
      (res) => {
        if (res.code === 200) {
          this.addTag(res.data, code, level); //添加地名标签
          classLayer && map.removeLayer(classLayer);
          if (mode === 1) {
            this.addRegion(code, level);
          } else if (mode === 2) {
            this.addNoClassRegion(code, level);
          } else if (mode === 3) {
            this.addRegion(code, level);
          }
        }
      }
    );
  };

  /**
   * 添加行政区标签图层
   * @returns
   */
  addTag = (list, code, level) => {
    const { regionMaker } = this.state;
    let makerList = [];
    regionMaker.forEach((item) => {
      map.removeMarker(item);
    });
    list.forEach((item) => {
      const marker = new CMarker({
        position: [item.lon, item.lat],
        marker: {
          id: "1" + item.name,
          name: item.name,
        },
        style: {
          iconUrl: icon, //采用平台默认自带的定位图标,
          textOffsetY: -20,
          fontSize: "17px",
        },
      });
      if (level === 3) {
        //如果点击街道只加街道的标签
        if (item.areaCode === code) {
          makerList.push(marker);
          map.addMarker(marker);
        }
      } else {
        makerList.push(marker);
        map.addMarker(marker);
      }
    });

    this.setState({
      regionMaker: makerList,
    });
  };

  /**
   *  添加行政区边界图层,小班边框图层
   * @returns
   */
  addRegion = (code, level) => {
    const { classLayer, regionLayer, ldCode, year, fatherCode } = this.state;
    regionLayer.forEach((item) => {
      map.removeMarker(item);
    });
    let regionList = [];
    if (level === 1) {
      //市级
      Request.CountryBorderlist(code, (res) => {
        if (res.code === 200) {
          res.data.forEach((item) => {
            const list = JSON.parse(item.json);
            let pointList = [];
            if (list) {
              if (list.type === "Polygon") {
                list.coordinates[0].forEach((item1) => {
                  pointList.push([item1[0], item1[1]]);
                });
                // 添加多边形
                const p = new CPolygon({
                  position: [pointList],
                  marker: {
                    id: item.name,
                  },
                  style: {
                    fillColor: "#32c5ff28",
                    width: 1.5,
                    borderColor: "#989fa6",
                  },
                });
                regionList.push(p);
                map.addMarker(p);
              } else {
                list.coordinates.forEach((item1) => {
                  item1.forEach((item2, index) => {
                    pointList.push(item2);
                  });
                });
                //添加多个多边形
                const p = new CMultiPolygon({
                  position: [pointList],
                  marker: {
                    id: item.name,
                  },
                  style: {
                    fillColor: "#32c5ff28",
                    width: 1.5,
                    borderColor: "#989fa6",
                  },
                });
                regionList.push(p);
                map.addMarker(p);
              }
            } else {
              message.error("暂无边界");
            }
          });
        }
      });
      this.setState({
        regionLayer: regionList,
      });
    } else if (level === 2) {
      //县级
      Request.StreetBorderlist(code, (res) => {
        if (res.code === 200) {
          res.data.forEach((item) => {
            const list = JSON.parse(item.json);
            let pointList = [];
            if (list) {
              if (list.type === "Polygon") {
                list.coordinates[0].forEach((item1) => {
                  pointList.push([item1[0], item1[1]]);
                });
                // 添加多边形
                const p = new CPolygon({
                  position: [pointList],
                  marker: {
                    id: item.name,
                  },
                  style: {
                    fillColor: "#32c5ff28",
                    width: 1.5,
                    borderColor: "#989fa6",
                  },
                });
                regionList.push(p);
                map.addMarker(p);
              } else {
                list.coordinates.forEach((item1) => {
                  item1.forEach((item2, index) => {
                    pointList.push(item2);
                  });
                });
                //添加多个多边形
                const p = new CMultiPolygon({
                  position: [pointList],
                  marker: {
                    id: item.name,
                  },
                  style: {
                    fillColor: "#32c5ff28",
                    width: 1.5,
                    borderColor: "#989fa6",
                  },
                });
                regionList.push(p);
                map.addMarker(p);
              }
            }
          });
        }
      });
      this.setState({
        regionLayer: regionList,
        ldCode: code,
      });
    } else {
      //乡镇
      classLayer && map.removeLayer(classLayer);
      let viewparams = null;
      // 调用小班获取该小班有数据年份
      PlagueWoodTs.getYearConfig({ areaCode: code, year: year })
        .then((respon) => {
          if (respon.data.code === 200) {
            this.setState(
              {
                smallClassYear: respon.data.data.smallClassYear,
              },
              () => {
                viewparams = `areaCode:${code};areaLevel:${level};year:${this.state.smallClassYear}`;
              }
            );
          }
        })
        .finally(() => {
          const layer = new CWMSLayer({
            zIndex: 1,
            url: `${window["env"].LayerUrl}/geoserver/pine-epidemic-census/wms`,
            params: {
              id: "wms-layer",
              ...config,
              srs: "EPSG:4549",
              layers: "pine-epidemic-census:small_class_base_data_filter",
              viewparams: viewparams,
            },
          });

          // console.log(viewparams)
          map.addLayer(layer);
          this.setState({
            classLayer: layer,
          });
        });
      // console.log(newYear)
      // if (year === '2021-2022' && fatherCode === '331127') {
      // 	viewparams = `areaCode:${code};areaLevel:${level};year:2021-2022`;
      // } else if (year === '2021-2022' && fatherCode !== '331127') {
      // 	//21年除丽水市外  其余市小班都为22年数据    隔岸无旧情，姑苏有钟声。 --地尽头
      // 	if (fatherCode !== '331100') {
      // 		viewparams = `areaCode:${code};areaLevel:${level};year:2022-2023`;
      // 	} else {
      // 		viewparams = `areaCode:${code};areaLevel:${level};year:2021-2022`;
      // 	}
      // } else {
      // 	if (year === '2022-2023') {
      // 		if (
      // 			ldCode === '331102' ||
      // 			fatherCode === '330300' ||
      // 			fatherCode === '330800'
      // 		) {
      // 			viewparams = `areaCode:${code};areaLevel:${level};year:2022-2023`;
      // 		} else {
      // 			viewparams = `areaCode:${code};areaLevel:${level};year:2021-2022`;
      // 		}
      // 	}
      // 	if (year === '2023-2024') {
      // 		// console.log(fatherCode)
      // 		if (fatherCode === '331102') {
      // 			viewparams = `areaCode:${code};areaLevel:${level};year:2021-2022`;
      // 		} else if (
      // 			fatherCode === '330300' ||
      // 			fatherCode === '330800'
      // 		) {
      // 			viewparams = `areaCode:${code};areaLevel:${level};year:2022-2023`;
      // 		} else {
      // 			viewparams = `areaCode:${code};areaLevel:${level};year:2021-2022`;
      // 		}
      // 	}
      // }
      // const layer = new CWMSLayer({
      // 	zIndex: 1,
      // 	url: `${window['env'].LayerUrl}/geoserver/pine-epidemic-census/wms`,
      // 	params: {
      // 		id: 'wms-layer',
      // 		...config,
      // 		srs: 'EPSG:4549',
      // 		layers: 'pine-epidemic-census:small_class_base_data_filter',
      // 		viewparams: viewparams,
      // 	},
      // });

      // map.addLayer(layer);
      // this.setState({
      // 	classLayer: layer,
      // });
    }
  };

  addAcceptLayer = () => {
    const env = window["env"];
    const { code, ldCode, year, level } = this.state;
    const layerPass = new CWMSLayer({
      zIndex: 1,
      url: env.REACT_APP_WMS_URL,
      params: {
        id: "wms-layer",
        ...config,
        srs: "EPSG:4549",
        layers: "mountain-field-acceptance:acceptance",
        styles: "accept_pass",
        viewparams: `areaCode:${level === 2 ? code : ldCode};townCode:${
          level === 2 ? "" : code.slice(-3)
        };result:1;year:${year};isInspection:0`,
      },
    });
    const layerUnpass = new CWMSLayer({
      zIndex: 1,
      url: env.REACT_APP_WMS_URL,
      params: {
        id: "wms-layer",
        ...config,
        srs: "EPSG:4549",
        layers: "mountain-field-acceptance:acceptance",
        styles: "accept_unpass",
        viewparams: `areaCode:${level === 2 ? code : ldCode};townCode:${
          level === 2 ? "" : code.slice(-3)
        };result:0;year:${year};isInspection:0`,
      },
    });
    const layerNoAccept = new CWMSLayer({
      zIndex: 1,
      url: env.REACT_APP_WMS_URL,
      params: {
        id: "wms-layer",
        ...config,
        srs: "EPSG:4549",
        layers: "mountain-field-acceptance:acceptance",
        styles: "accept_no",
        viewparams: `areaCode:${level === 2 ? code : ldCode};townCode:${
          level === 2 ? "" : code.slice(-3)
        };result:3;year:${year};isInspection:0`,
      },
    });
    this.setState(
      {
        layerPass,
        layerUnpass,
        layerNoAccept,
      },
      () => {
        map.addLayer(layerPass);
        map.addLayer(layerUnpass);
        map.addLayer(layerNoAccept);
      }
    );
  };

  removeAcceptLayer = () => {
    const { layerPass, layerUnpass, layerNoAccept } = this.state;
    layerPass && map.removeLayer(layerPass);
    layerUnpass && map.removeLayer(layerUnpass);
    layerNoAccept && map.removeLayer(layerNoAccept);
    this.setState({
      layerPass: null,
      layerUnpass: null,
      layerNoAccept: null,
    });
  };

  /**
   *  添加行政区边界图层
   * @returns
   */
  addNoClassRegion = (code, level) => {
    const { regionLayer, theName } = this.state;
    regionLayer.forEach((item) => {
      map.removeMarker(item);
    });
    let regionList = [];
    Request.StreetBorderlist(code, (res) => {
      if (res.code === 200) {
        if (theName === "永康市" || theName === "泰顺县") {
          res.data.forEach((item) => {
            if (item.name !== "永康市" && item.name !== "泰顺县") {
              const list = JSON.parse(item.json);
              let pointList = [];
              if (list.type === "Polygon") {
                list.coordinates[0].forEach((item) => {
                  pointList.push([item[0], item[1]]);
                });
              } else {
                list.coordinates[0][0].forEach((item) => {
                  pointList.push([item[0], item[1]]);
                });
              }
              const p = new CPolygon({
                position: [pointList],
                marker: {
                  id: item.name,
                },
                style: {
                  fillColor: "#32c5ff28",
                  width: 1.5,
                  borderColor: "#989fa6",
                },
              });
              regionList.push(p);
              map.addMarker(p);
            }
          });
          this.setState({
            regionLayer: regionList,
          });
        } else {
          res.data.forEach((item1) => {
            if (item1.name === theName) {
              const list = JSON.parse(item1.json);
              let pointList = [];
              if (list.type === "Polygon") {
                list.coordinates[0].forEach((item) => {
                  pointList.push([item[0], item[1]]);
                });
              } else {
                list.coordinates[0][0].forEach((item) => {
                  pointList.push([item[0], item[1]]);
                });
              }
              const p = new CPolygon({
                position: [pointList],
                marker: {
                  id: item1.name,
                },
                style: {
                  fillColor: "#32c5ff28",
                  width: 1.5,
                  borderColor: "#989fa6",
                },
              });
              regionList.push(p);
              map.addMarker(p);
            }
          });
          this.setState({
            regionLayer: regionList,
          });
        }
      }
    });
  };
  changeAcceptState = () => {
    const { AcceptState } = this.state;
    if (!AcceptState) {
      localStorage.removeItem("AcceptState");
    } else {
      localStorage.setItem("AcceptState", "true");
    }
    this.setState({
      AcceptState: !this.state.AcceptState,
    });
  };
  changeLiandu = () => {
    this.setState({ lianduLayerCheck: !this.state.lianduLayerCheck });
  };

  render() {
    const {
      isOperat,
      mode,
      code,
      areaName,
      level,
      username,
      isWoodOpen,
      lableList,
      theName,
      fatherCode,
      isCheckOpen,
      year,
      AcceptState,
      lianduLayerCheck,
    } = this.state;
    const { propsareaCode } = this.props.location.state;

    return (
      <ScaleContainer resolution="16:9" isPointerEve="none" adapt="two-way">
        <div className="main-page" id="main-page">
          <Navigator
            username={username}
            map={map}
            lay={lay}
            code={code}
            level={level}
            areaName={areaName}
            mode={mode}
            propsareaCode={propsareaCode}
            lableList={lableList}
            isOperat={isOperat}
            year={year}
            changelableList={this.changelableList}
            areaChange={this.areaChange}
            changeInfectLayer={this.changeInfectLayer}
            theName={theName}
            changeCheckLayer={this.changeCheckLayer}
            changeSchedule={this.changeSchedule}
            changeWoodOpen={this.changeWoodOpen}
            changeFatherCode={this.changeFatherCode}
            isWoodOpen={isWoodOpen}
            AcceptStateOnclick={this.changeAcceptState}
            AcceptState={AcceptState}
          />
          {mode === 1 ? (
            <>
              {AcceptState ? (
                <LeftClass
                  map={map}
                  lay={lay}
                  areaName={areaName}
                  isOperat={isOperat}
                  lableList={lableList}
                  changelableList={this.changelableList}
                  code={code}
                  level={level}
                  areaChange={this.areaChange}
                  changeFatherCode={this.changeFatherCode}
                  fatherCode={fatherCode}
                  isWoodOpen={isWoodOpen}
                  theName={theName}
                  year={year}
                  AcceptState={AcceptState}
                />
              ) : (
                <AcceptData
                  map={map}
                  lay={lay}
                  areaName={areaName}
                  isOperat={isOperat}
                  lableList={lableList}
                  changelableList={this.changelableList}
                  code={code}
                  level={level}
                  areaChange={this.areaChange}
                  changeFatherCode={this.changeFatherCode}
                  fatherCode={fatherCode}
                  isWoodOpen={isWoodOpen}
                  theName={theName}
                  year={year}
                  AcceptState={AcceptState}
                />
              )}
              {/* <LeftClass
								map={map}
								lay={lay}
								areaName={areaName}
								isOperat={isOperat}
								lableList={lableList}
								changelableList={this.changelableList}
								code={code}
								level={level}
								areaChange={this.areaChange}
								changeFatherCode={this.changeFatherCode}
								fatherCode={fatherCode}
								isWoodOpen={isWoodOpen}
								theName={theName}
								year={year}
								AcceptState={AcceptState}
							/> */}
              {AcceptState ? (
                <RightClass
                  isOperat={isOperat}
                  code={code}
                  level={level}
                  theName={theName}
                  areaName={areaName}
                  mode={mode}
                  year={year}
                  AcceptState={AcceptState}
                />
              ) : (
                <AcceptClass
                  isOperat={isOperat}
                  code={code}
                  level={level}
                  theName={theName}
                  areaName={areaName}
                  mode={mode}
                  year={year}
                />
              )}
            </>
          ) : (
            <>
              <LeftNoClass
                map={map}
                lay={lay}
                areaName={areaName}
                code={code}
                level={level}
                theName={theName}
                changeFatherCode={this.changeFatherCode}
                fatherCode={fatherCode}
                isOperat={isOperat}
                areaChange={this.areaChange}
                isCheckOpen={isCheckOpen}
                year={year}
                AcceptState={AcceptState}
              />
              <RightNoClass
                isOperat={isOperat}
                code={code}
                level={level}
                theName={theName}
                year={year}
                AcceptState={AcceptState}
              />
            </>
          )}
          <Footer
            lianduLayerCheck={lianduLayerCheck}
            isOperat={isOperat}
            setOperat={this.setOperat}
            changeYear={this.changeYear}
            changeLiandu={this.changeLiandu}
          />
          <div className="elevation-tip" id={"elevation-tip"}>
            {haibaList.map((item, index) => {
              return (
                <div key={index}>
                  <span
                    style={{
                      border: `1px solid ${item.color}`,
                      background: `${item.color}`,
                    }}
                  ></span>
                  <span>{item.quantity}</span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="mapPopup" id="mapPopup"></div>
      </ScaleContainer>
    );
  }
}

export default IndexPage;
