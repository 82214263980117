import { useEffect, useState } from 'react';
import Request from '../../../services/Manger';
import BaseCard from '../../../component/Card/BaseCard';
import img8 from '../../../assets/icons/icon/svg/fristicon.svg';
import img9 from '../../../assets/icons/icon/svg/secondicon.svg';
import back from '../../../assets/images/CheckWork.png';
import NumCard from '../../../component/Card/NumCard';
import Progress from '../../../component/tools/Progress';

//永康市·排查工作进展
export default function CheckWork({ props }) {
  const { theName, year } = props;
  const [list, setList] = useState([
    {
      img: img8,
      title: '一级网格',
      gridOne: 0,
      datatitle: 'gridOne',
    },
    {
      img: img9,
      title: '二级网格',
      gridTwo: 0,
      datatitle: 'gridTwo',
    },
  ]);
  const [progress, setProgress] = useState(0); //排查进度

  useEffect(() => {
    Request.NoclassCheck({ year }, (res) => {
      if (res.code === 200) {
        setList((list) => {
          list.map((item) => {
            item[item.datatitle] = res.data[item.datatitle];
          });
          return list;
        });
        setProgress(res.data.progress);
      }
    });
  }, [year]);
  return (
    <BaseCard
      title={`${theName}·排查工作进展`}
      describe={'CHECK THE WORK'}
      height={202}
      backround={back}
    >
      <div className="CheckWork">
        <div className="CheckWork_grid">
          {list.map((item, index) => (
            <div key={index}>
              <img src={item.img} alt="" />
              <NumCard
                title={item.title}
                total={item[item.datatitle]}
                color="#02D68A"
                bold={true}
              />
            </div>
          ))}
        </div>
        <div className="CheckWork_progress">
          <NumCard
            title="验收通过率："
            unit="%"
            total={progress < 0 ? 0 : progress > 100 ? 100 : progress}
            color="#02D68A"
            bold={true}
            position={true}
          />
          <Progress progress={progress < 0 ? 0 : progress > 100 ? 100 : progress} />
        </div>
      </div>
      {/* <Label
        title={`${theName}·排查工作进展`}
        describe={'CHECK THE WORK'}
        width={398}
      />
      <div className="statistics">
        <div>
          <div className="statistics-box">
            <div className="check-work">
              <div className="grid-list">
                {list.map((item, index) => {
                  return (
                    <div className={`item${index}`}>
                      <img src={item.img} className={`img${index}`} alt="" />
                      <div className={`title${index}`}>
                        <p>{item.title}</p>
                        <span>{item.num}</span>
                        <span>{item.unit}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="schedule">
                <div className="Paicha">
                  <span>排查进度：</span>
                  <span>{progress.toFixed(2)}</span>
                  <span>%</span>
                </div>
                <div className="Jindutiao">
                  <div style={{ width: `${progress}%` }}></div>
                  <div
                    style={{
                      width: `${100 - progress}%`,
                      marginLeft: progress ? '1%' : 0,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </BaseCard>
  );
}
