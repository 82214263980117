import { Button, Cascader, DatePicker, message } from "antd";
import { useEffect, useState } from "react";
import MapBox from "./mapBox";
import Common from "../../services/Common";
import eiminationReports from "../../services/EiminationReports";

const { RangePicker } = DatePicker;

interface Props {
  reportData: any;
  onClose: () => void;
}

const ProblemMap = ({ reportData, onClose }: Props) => {
  //数据年度
  const year = sessionStorage.getItem("year");
  //当前城市
  const cityCode = sessionStorage.getItem("cityCode");
  //上报时间
  const [time, setTime] = useState([]);

  const [options, setOptions] = useState([]);
  //选中的区域
  const [activeData, setActiveData] = useState([]);

  const [position, setPosition] = useState([]);

  const [activeClass, setActiveClass] = useState(null);

  /**
   * @description 获取级联选择选择项
   */

  const getCascaderOptions = () => {
    Common.getListAllRegion({ cityCode, year }).then((res) => {
      const data = res.data;
      if (data.code === 200) {
        setOptions(data.data);
      }
    });
  };

  /**
   * @description 改变所选区域
   */

  const changeActiveData = (value, selectedOptions) => {
    setActiveData(selectedOptions);
  };

  /**
   * @description 改变上报时间
   */

  const changeTime = (date, dateStrings) => {
    setTime(dateStrings);
  };

  const getActiveClass = (value) => {
    setPosition(value);
  };

  const onSave = () => {
    const data = {
      classIds: [activeClass.classId],
      countyCode: activeData[0].value,
      cutExcelReportFailId: reportData.id,
      isVisualizedReport: false,
      year,
    };
    eiminationReports.batchReport(data).then((res) => {
      const resData = res.data;
      if (resData.code === 200) {
        message.success("上报进度成功");
        onClose();
      }
    });
  };

  useEffect(() => {
    getCascaderOptions();
  }, []);

  useEffect(() => {
    if (position.length > 0) {
      Common.getClassLonlat({
        x: position[0],
        y: position[1],
        year,
        countyCode: activeData[0].value,
      }).then((res) => {
        const data = res.data;
        if (data.code === 200) {
          setActiveClass(data.data);
        }
      });
    }
  }, [position]);

  const filter = (inputValue: string, path: any[]) =>
    path.some(
      (option) =>
        (option.label as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1
    );

  return (
    <div className="problem_map">
      <div className="problem_map_left">
        <MapBox
          reportData={reportData}
          areaData={activeData}
          reportTime={time}
          mapClick={getActiveClass}
        />
      </div>
      <div className="problem_map_right">
        <div className="right_item">
          <div className="item_title">区域选择：</div>
          <Cascader
            allowClear={false}
            options={options}
            showSearch={{ filter }}
            onChange={changeActiveData}
          />
        </div>
        <div className="right_item">
          <div className="item_title">上报时间：</div>
          <RangePicker format={"YYYY-MM-DD"} onChange={changeTime} />
        </div>
        <div className="right_item">
          <div className="item_title">选中小班：</div>
          <div>{activeClass ? activeClass.className : ""}</div>
        </div>
        <div className="right_button">
          <Button
            style={{ marginRight: "10px" }}
            type="primary"
            onClick={onSave}
          >
            确认
          </Button>
          <Button onClick={onClose}>返回</Button>
        </div>
      </div>
    </div>
  );
};

export default ProblemMap;
