// @ts-nocheck
import React, { Component, createRef } from 'react';
import {
	Modal,
	Form,
	Select,
	Input,
	Radio,
	Button,
	DatePicker,
	Upload,
	message,
	Checkbox,
	Pagination,
} from 'antd';
import { PlusOutlined, PlusSquareOutlined } from '@ant-design/icons';
import Common from '../../platform/services/Common';
// import { exportWord } from 'mhtml-to-word'
import { exportWord } from '../../utils/wordhtml';
import { PicInfo } from './exportReport';
import Acceptance from '../../platform/services/Acceptance';

const { Option } = Select;
const { RangePicker } = DatePicker;
const FormItem = Form.Item;

const layout = {
	labelCol: { span: 6 },
	wrapperCol: { span: 18 },
};

interface TodayReportState {
	current: number;
	list: Array<any>;
	townList: Array<any>;
	villageList: Array<any>;
	reportData: any;
	fileList: Array<any>;
	time: string;
	townCode: string;
	pdf: boolean;
	checkVisible: boolean;
}
interface TodayReportProps {
	visible: boolean;
	handleCancel: any;
	countyCode: any;
	countyList: any;
	isInspection: number;
	townCode: string;
	isReading: boolean;
	areaLevel: number;
}

// 导出当日报告
export class TodayReport extends Component<
	TodayReportProps,
	TodayReportState
> {
	countyCode = this.props.countyCode;
	ref: any = createRef();
	formRef: any = createRef();
	constructor(props) {
		super(props);
		this.state = {
			pdf: false,
			current: 1,
			list: [
				{
					villageCode: '',
					forestCode: '',
					smallClassList: [],
				},
			],
			townList: [],
			villageList: [],
			time: '',
			townCode: this.props.townCode,
			fileList: [],
			reportData: null,
			checkVisible: false,
			startTime: '',
			endTime: '',
		};
	}

	componentDidMount() {
		Common.getTownList({ countyCode: this.countyCode }).then((res) => {
			if (res.data.code === 200) {
				this.setState({
					townList: res.data.data,
				});
			}
		});
	}

	// 乡镇/街道选择
	onAreaChanged = (value) => {
		const { startTime, endTime } = this.state;
		const { isInspection } = this.props;
		const params = {
			countyCode: this.countyCode,
			townCode: value,
			startTime,
			endTime,
			isReport: true,
			isInspection: isInspection,
		};
		Common.getVillageList(params).then((res) => {
			if (res.data.code === 200) {
				this.setState({
					townCode: value,
					villageList: res.data.data,
				});
			}
		});
	};

	//获取乡镇
	getVilList = (params) => {
		Common.getVillageList(params).then((res) => {
			if (res.data.code === 200) {
				this.setState({
					villageList: res.data.data,
				});
			}
		});
	};

	//日期的变化
	rangeChange = (value) => {
		if (value) {
			const startTime = value.format('YYYY-MM-DD 00:00:00');
			const endTime = value.format('YYYY-MM-DD 23:59:59');
			this.setState({ startTime, endTime });
			const params = {
				countyCode: this.countyCode,
				townCode: this.state.townCode,
				startTime,
				endTime,
				isReport: true,
				isInspection: this.props.isInspection,
			};
			this.getVilList(params);
		}
	};

	//新增照片选择
	changeList = (index, key) => {
		const { list } = this.state;
		// 新增
		if (key === 1) {
			const newList = [
				...list,
				{
					villageCode: '',
					forestCode: '',
					smallClassList: [],
				},
			];
			this.setState({
				list: newList,
			});
		} else {
			const newList = [...list];
			newList.splice(index, 1);
			this.setState({
				list: newList,
			});
		}
	};

	// 更新验收照片选择
	updateClass = (changedValues, allValues, index) => {
		const { list } = this.state;
		const newList = [...list];
		newList[index] = {
			villageCode: allValues.villageCode ? allValues.villageCode : '',
			forestCode: allValues.forestCode ? allValues.forestCode : '',
			smallClassList: allValues.smallClassList ? allValues.smallClassList : [],
		};
		this.setState({
			list: newList,
		});
	};

	// 翻页
	pageChanged = (value) => {
		const { current } = this.state;
		// const total = Math.ceil(list.length / 2)
		if (value > current) {
			this.ref.current.style.left = '-' + (value - 1) * 752 + 'px';
			this.setState({
				current: value,
			});
		} else if (value < current) {
			this.ref.current.style.left = '-' + (value - 1) * 750 + 'px';
			this.setState({
				current: value,
			});
		}
	};

	// 生成报告
	finishReport = (value) => {
		const { list, townList, checkVisible, townCode } = this.state;
		const { time, exportAll, file } = value;
		const { countyList, countyCode } = this.props;
		const reportName = countyList.find((item) => {
			return countyCode === item.code;
		});
		const params = {
			countyCode: this.countyCode,
			picCondition: list,
			exportAll,
			townCode: checkVisible ? '' : townCode,
			startTime: time.format('YYYY-MM-DD 00:00:00'),
			endTime: time.format('YYYY-MM-DD 23:59:59'),
			year: sessionStorage.getItem('year'),
			isInspection: this.props.isInspection,
		};
		message.loading({ content: '正在生成报告中...', duration: 0 });
		Acceptance.getTodayReport(params).then((res) => {
			if (res.data.code === 200) {
				console.log(res)
				message.destroy()
				this.setState({
					reportData: {
						list: res.data.data,
						countyName: reportName.name,
						townName: checkVisible
							? '全部街道'
							: townList.find((item) => {
								return item.code === townCode;
							}).name,
					},
					pdf: true,
					fileList: file ? file.fileList : [],
				});
			} else {
				message.destroy();
				message.error('生成报告失败，请检查后重试');
			}
		});
	};

	onChange = (value) => {
		this.setState({
			checkVisible: value.target.checked,
		});
	};

	// url-->base64
	ImgtoBase64 = (url, imgTab) => {
		return new Promise((resolve, reject) => {
			const image = new Image();
			image.crossOrigin = 'Anonymous'; // 添加crossOrigin属性
			// image.src = url + "?" + Math.random();
			image.src = `https://lmg.jj20.com/up/allimg/4k/s/02/2109250006343S5-0-lp.jpg`;
			image.onload = () => {
				const canvas = document.createElement('canvas');
				const context = canvas.getContext('2d');
				canvas.width = image.width;
				canvas.height = image.height;
				context.drawImage(image, 0, 0);
				const base64 = canvas.toDataURL('image/png');
				console.log(base64);
				resolve(base64);
			};
			image.onerror = () => {
				reject(new Error('Failed to load image'));
			};
		});
	}


	// 导出报告
	handleExport = () => {
		// const imgList = document.getElementById("pdf-content").querySelectorAll("img")
		// imgList.forEach((item) => {
		// 	var imgURL = item.currentSrc;
		// 	this.ImgtoBase64(imgURL, item);
		// })

		// console.log(document.getElementById("pdf-content").querySelectorAll("img"));


		exportWord({
			filename: '跟踪验收报告',
			selector: '#pdf-content',
			style: `
			.pdf-header {
				width: 595.28px;
				height: 841.89px;
			}
			.pdf-page {
				width: 599.28px;
				margin-bottom: 40px;
			}
			.title1 {
				font-size: 30px;
				text-align:center;
				margin-bottom: 700px;
			}
			.title2 {
				font-size: 25px;
				text-align:center;
			}
			.title3 {
				font-size: 25px;
				text-align:center;
				margin-bottom: 200px;
			}
			.pdf-img {
				width: 595.28px;
				height: 350px;
			}
			#pdf-img {
				width: 595.28px;
				height: 350px;
			}
			.pdf-imgbox {
				width: 595.28px;
				height: 350px;
				text-align:center;
			}
			.info-title {
				font-size: 20px;
			}
			.title {
				text-decoration: solid;
				color: #000000;
				font-size: 22px;
			}
			.tableForm{
				width: 100%;
			}
		`,
		});
		message.success('导出成功');
	};

	render() {
		const {
			current,
			list,
			townList,
			townCode,
			villageList,
			pdf,
			reportData,
			startTime,
			endTime,
			fileList,
			checkVisible,
		} = this.state;
		const { areaLevel } = this.props;
		const acceptanceResult = {
			'1': '验收通过',
			'0': '验收不通过',
			'-1': '待审核',
			'3': '不纳入考核',
		};
		const columns = [
			{
				title: '乡镇街道',
				dataIndex: 'town',
				key: 'town',
			},
			{
				title: '行政村',
				dataIndex: 'village',
				key: 'village',
			},
			{
				title: '林班',
				dataIndex: 'forest',
				key: 'forest',
			},
			{
				title: '小班号',
				dataIndex: 'smallClass',
				key: 'smallClass',
			},
			{
				title: '验收结果',
				dataIndex: 'result',
				key: 'result',
			},
			{
				title: '备注',
				dataIndex: 'remark',
				key: 'remark',
			}
		];
		const props: any = {
			action: window['env'].REACT_APP_API_HOST + '/upload',
			listType: 'picture-card',
			beforeUpload: () => {
				return false;
			},
			accept: '.png,.jpg,.jpeg',
			showUploadList: {
				showPreviewIcon: false,
				showRemoveIcon: true,
				showDownloadIcon: false,
			},
		};
		return (
			<Modal
				title="导出当日报告"
				visible={this.props.visible}
				footer={null}
				onCancel={() => this.props.handleCancel(this.props.visible)}
				centered={true}
				width={800}
				className="exportReport-today"
			>
				{!pdf
					?
					<>
						<Form onFinish={this.finishReport}>
							<div className={'header1'}>
								<span>导出范围及验收单上传</span>
							</div>
							<FormItem
								name="townCode"
								label="乡镇/街道"
								rules={[
									{
										required: checkVisible || townCode ? false : true,
										message: '请选择乡镇/街道',
									},
								]}
								{...layout}
							>
								<Select
									onSelect={this.onAreaChanged}
									defaultValue={townCode}
									style={{ width: 400 }}
									disabled={areaLevel === 3 && true}
								>
									{townList.map((item, index) => {
										return (
											<Option key={index} value={item.code}>
												{item.name}
											</Option>
										);
									})}
								</Select>
								<Checkbox
									onChange={this.onChange}
									style={{ marginLeft: '20px' }}
									disabled={areaLevel === 3 && true}
								>
									全选
								</Checkbox>
							</FormItem>
							<FormItem
								label="验收时间"
								name="time"
								rules={[
									{
										required: true,
										message: '请选择导出报告日期',
									},
								]}
								{...layout}
							>
								<DatePicker style={{ width: 400 }} onChange={this.rangeChange} />
							</FormItem>
							<FormItem
								name="exportAll"
								label="是否导出全量数据"
								rules={[
									{
										required: true,
										message: '请先选择导出数据范围',
									},
								]}
								initialValue={true}
								{...layout}
							>
								<Radio.Group  >
									<Radio value={true} >是</Radio>
									<Radio value={false}>否</Radio>
								</Radio.Group>
							</FormItem>
							<FormItem
								label="验收单照片"
								name="file"
								rules={[
									{
										required: false,
										message: '请选择验收单照片',
									},
								]}
								{...layout}
							>
								<Upload {...props}>
									<div>
										<PlusOutlined />
										<div style={{ marginTop: 8 }}>上传</div>
									</div>
								</Upload>
							</FormItem>
							<div className={'acceptance-list'}>
								<div className={'header'}>
									<span>验收照片选择</span>
									<span>
										<PlusSquareOutlined
											onClick={() => this.changeList(null, 1)}
										/>
									</span>
								</div>
								<div className={'body'}>
									<div ref={this.ref}>
										{list.map((item, index) => {
											return (
												<PicInfo
													data={item}
													index={index}
													countyCode={this.countyCode}
													townCode={townCode}
													time={{ startTime, endTime }}
													villageList={villageList}
													updateClass={this.updateClass}
													changeList={this.changeList}
												/>
											);
										})}
									</div>
								</div>
								<div className={'footer'}>
									<Pagination
										current={current}
										total={list.length}
										defaultPageSize={2}
										onChange={this.pageChanged}
									/>
									<Button
										type="primary"
										style={{ marginTop: '15px' }}
										htmlType="submit"
									>
										生成报告
									</Button>
								</div>
							</div>
						</Form>
					</>
					:
					<div className={'pdf-box'}>
						<div id={'pdf-content'}>
							<div className={'pdf-header'} id={'pdf-header'}>
								<div className={'title1'}>
									<span>{reportData.countyName + reportData.townName}松材线虫病防治</span><br />
									<span>跟踪验收报告</span>
								</div>
								<div className={'title2'}>
									<span>浙江点创信息科技有限公司</span>
									<div className={'title3'}>
										<span>{`${new Date().getFullYear()}`}</span>年
										<span>{`${new Date().getMonth() + 1}`}</span>月
										<span>{`${new Date().getDate()}`}</span>日
									</div>
								</div>
							</div>
							{
								reportData.list.length !== 0 && reportData.list.map((item, index) => {
									return <div className={'pdf-page'} id={`pdf-page-${index}`}>
										<div className={'title'}>{item.title}</div>
										<div className={'info-title'}>验收统计表：</div>
										<table border="1" align="center" cellspacing="0" text-align="center" className='tableForm' width="100%">
											<tr >
												{
													this.countyCode === '331124' ?
														[...columns, {
															title: '分数',
															dataIndex: 'score',
															key: 'score',
														},].map(item => {
															return <th >{item.title}</th>
														})
														:
														columns.map(item => {
															return <th >{item.title}</th>
														})
												}
											</tr>
											<tbody className={'body'}>
												{
													item.classList.map((item2, index) => {
														return <>
															<tr id={'tablefirst'} text-align="center">
																<td text-align="center" width="15%">{item2.townName}</td>
																<td text-align="center" width="15%">{item2.villageName}</td>
																<td text-align="center" width="15%">{item2.forestName}</td>
																<td text-align="center" width="10%">{item2.smallClass}</td>
																<td text-align="center" width="15%">{acceptanceResult[item2.result]}</td>
																<td text-align="center" width="30%">{item2.situation}</td>
																{
																	this.countyCode == '331124' &&
																	<td text-align="center" width="25%">
																		{item2.score || 0}
																	</td>
																}
															</tr>
														</>
													})

												}
											</tbody>
										</table>
										<div className={'info-title'}>现场图片：</div>
										{
											item.picList.map((r2, i2) => {
												return <div>
													<img className='pdf-img' id={'pdf-img'} src={r2.url} width={'595.28'} height={'350'}></img>
													<div style={{ padding: '10px 0' }}>{r2.classInfo?.town}-{r2.classInfo?.village}-{r2.classInfo?.forest}-{r2.classInfo?.smallClass}</div>
												</div>
											})
										}
									</div>
								})
							}
							<div className={'info-title'}>验收单照片：</div>
							{
								fileList.map((r3, i3) => {
									return <img className='pdf-img' id={'pdf-img'} src={r3.thumbUrl} width={'595.28'} height={'350'}></img>
								})
							}
						</div>
						<div style={{ textAlign: 'center' }}>
							<Button type="primary" style={{ marginTop: '15px' }} onClick={this.handleExport}>导出报告</Button>
						</div>
					</div>
				}
			</Modal>
		);
	}
}