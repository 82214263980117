import {
  Button,
  Form,
  Input,
  List,
  message,
  Modal,
  Select,
  Tooltip,
  Upload,
  Image,
  Switch,
  Descriptions,
  Space,
  Typography,
} from "antd";
import React, { Component } from "react";
import JSZip from "jszip";
import FileSaver from "file-saver";
import "./map.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  PlusOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  EditOutlined,
  SearchOutlined,
  UserOutlined,
  DeleteOutlined,
  DragOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import OlMap from "../../utils/olmap";
import { UploadImg } from "./uploadImg";
import { ClassList, ClassSearchByLnglat } from "./smallClass";
import Acceptance from "../../platform/services/Acceptance";
import Common from "../../platform/services/Common";
import { handleImage, uploadImage2 } from "../../utils/handlePic";
import selectPoint from "../../assets/images/select.svg";
import { MapLayers } from "../../platform/acceptance/source";
import axios from "axios";
import { log } from "console";
import PlagueWoodTs from "../../platform/services/plagueWood";
import UploadImgNew from "./UploadImgNew";

const { Search, TextArea } = Input;
const FormItem = Form.Item;
const { Option } = Select;
let cancel = null;

const env = window["env"];
const droneList = [
  0, 22.5, 45, 67.5, 90, 112.5, 135, 157.5, 180, 202.5, 225, 247.5, 270, 292.5,
  315, 337.5, 360,
];
let timming = null;
const url = window["env"].LayerUrl + "/geoserver/pine-epidemic-census/wms";
export default class Map extends Component<any, any> {
  role = localStorage.getItem("role");
  ref;
  maxZoom = 18;
  layer = {};
  element;
  mapClickInfoWindow;
  pageSize = 10;
  expandRecord = null;
  markerObject = [];
  clustererObject = null;
  Clusterer; //点聚合对象
  pointLayers; //点面对
  iconTranslate;
  constructor(props) {
    super(props);
    this.state = {
      uploadImgVisible: false,
      key: "",
      page: 1,
      dataList: [],
      total: 0,
      isShowLayer: false,
      updateAllVisible: false,
      allRemark: "",
      allResult: -2,
      classEdit: false, // 小班编辑
      editKey: 0,
      addClassInfo: null,
      addClassPicList: [],
      droneEdit: false, // 航拍关联
      classSearch: false, // 小班搜索
      downloadImg: [], //下载的照片
      downloadBackup: [],
      choosePicId: null, //选择照片的id
      pointVisiable: false, //是否改变已选点位的样式
      searchKey: 0,
      searchKeyValue: "",
      showAll: this.role === "ROLE_2" ? true : false,
      imageVisible: false, //智能上报列表照片预览
      previewImage: null, //智能上报照片列表
      userFlag: true, //是否选择当日照片
      classResult: "all", //当日照片选择
      allFlag: false, //图片展示当前区县的   true为数据库所有图片
      classDelPic: false, //删除小班图片
      allCutFlag: false, //展示所有的砍伐图片
      classDelName: null, //删除的小班
      repeatPicLook: false, //重复照片预览
      classNum: 0, //小班总数
      acceptanceNum: 0, //验收总轮次
      moveVisible: false,
      newOrOverFlag: false,
      hourData24: null,
      afterControl: 1,
      smallClassYear: "",
      accKey: -1,
      isOther: false,
      isWater: false,
      situationOption: [],
      situationOptionFlag: false,
      todaySmallClass: 0, //今日完成小班数
      historyModelFlag: false,
      historyList: [],
      thisClassName: "",
      flyPoint: null,
      oldUploadImgVisible: false,
    };
    this.ref = React.createRef();
  }

  componentDidMount = () => {
    const { countyCode, townCode, townList, countyList, isInspection } =
      this.props;
    this.getSunScoreData();
    this.getTodaySmallClass();
    const year = sessionStorage.getItem("year");
    const new_townCode = townCode ?? "";
    this.getSmallLayerYear();
    //地图初始化
    OlMap.init("olmap");
    OlMap.addAcceptanceLayer(
      {
        ...MapLayers.passLayer,
        viewparams: `areaCode:${countyCode};townCode:${new_townCode};result:1;year:${year};isInspection:${isInspection}`,
      },
      "pass"
    );
    OlMap.addAcceptanceLayer(
      {
        ...MapLayers.unPassLayer,
        viewparams: `areaCode:${countyCode};townCode:${new_townCode};result:0;year:${year};isInspection:${isInspection}`,
      },
      "unpass"
    );
    OlMap.addAcceptanceLayer(
      {
        ...MapLayers.noAccLayer,
        viewparams: `areaCode:${countyCode};townCode:${new_townCode};result:3;year:${year};isInspection:${isInspection}`,
      },
      "noacc"
    );

    if (townCode && this.role === "ROLE_2") {
      message.loading("正在加载图片数据，请稍后...");
      townList.forEach((item) => {
        return (
          item.code === townCode && OlMap.ToLngLat([item.lon, item.lat], 12)
        );
      });
    } else {
      countyList.forEach((item) => {
        return (
          item.code === countyCode && OlMap.ToLngLat([item.lon, item.lat], 11)
        );
      });
    }
    this.load();
    this.getData();
    this.getAcceptanceNum();
    setTimeout(() => {
      OlMap.map.on("singleclick", this.MapClick);
      OlMap.map.on("moveend", this.MapChange);
    });
    // const { newOrOverFlag, addClassInfo } = this.state
    // if (newOrOverFlag) {
    // 	localStorage.setItem('测试', '123')
    // 	let ids = []
    // 	addClassInfo.picCutList.forEach(item => {
    // 		ids.push(item.id)
    // 	})
    // 	addClassInfo.picDroneList.forEach(item => {
    // 		ids.push(item.id)
    // 	})
    // 	const params = {
    // 		classId: addClassInfo.classId,
    // 		picIdList: ids,
    // 		isInspection: this.props.isInspection,
    // 		requestIdentifying: 3
    // 	}
    // 	Acceptance.classEidt(params).then(res => {
    // 		if (res.data.code == 200) {
    // 			this.setState({
    // 				newOrOverFlag: false,
    // 				hourData24: null
    // 			})
    // 			this.setState({
    // 				page: 1,
    // 				dataList: [],
    // 				classEdit: false,
    // 				editKey: 0,
    // 				addClassInfo: null
    // 			}, this.getData)
    // 		} else {
    // 			this.setState({
    // 				newOrOverFlag: false,
    // 				hourData24: null
    // 			})
    // 			message.error(res.data.msg)
    // 		}
    // 	})
    // }
    window.addEventListener("beforeunload", () => {
      const { newOrOverFlag, addClassInfo } = this.state;
      if (newOrOverFlag) {
        let ids = [];
        addClassInfo.picCutList.forEach((item) => {
          ids.push(item.id);
        });
        addClassInfo.picDroneList.forEach((item) => {
          ids.push(item.id);
        });
        const params = {
          classId: addClassInfo.classId,
          picIdList: ids,
          isInspection: this.props.isInspection,
          requestIdentifying: 3,
        };
        Acceptance.classEidt(params).then((res) => {
          if (res.data.code == 200) {
            this.setState({
              newOrOverFlag: false,
              hourData24: null,
            });
            this.setState(
              {
                page: 1,
                dataList: [],
                classEdit: false,
                editKey: 0,
                addClassInfo: null,
              },
              this.getData
            );
          } else {
            this.setState({
              newOrOverFlag: false,
              hourData24: null,
            });
            message.error(res.data.msg);
          }
        });
      }
    });
  };

  componentWillUnmount = () => {};

  getAcceptanceNum = () => {
    const { countyCode, isInspection } = this.props;
    const params = {
      year: sessionStorage.getItem("year"),
      countyCode,
      isInspection,
    };
    Acceptance.getAccNum(params).then((res) => {
      if (res.data.code === 200) {
        const { classNum, acceptanceNum } = res.data.data;
        this.setState({ classNum, acceptanceNum });
      } else {
        message.error(res.data.msg);
      }
    });
  };

  getSmallLayerYear = () => {
    const { countyCode, townCode, townList, countyList, isInspection } =
      this.props;
    const new_townCode = townCode ?? "";
    PlagueWoodTs.getYearConfig({
      areaCode: countyCode,
      year: sessionStorage.getItem("year"),
    }).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          smallClassYear: res.data.data.smallClassYear,
        });
        OlMap.addSmallClassLayer({
          ...MapLayers.AllSmallClassLayer,
          viewparams: `areaCode:${countyCode};townCode:${new_townCode};year:${res.data.data.smallClassYear}`,
        });
        OlMap.addBorderLayer({
          ...MapLayers.countryLayer,
          viewparams: `areaCode:${countyCode};townCode:${new_townCode};year:${res.data.data.regionYear};`,
        });
      }
    });
  };

  getTodaySmallClass = () => {
    const { countyCode, isInspection } = this.props;
    let params = {
      isInspection,
      countyCode,
      year: window.sessionStorage.getItem("year"),
    };
    Acceptance.getTodayClass(params).then((res) => {
      // console.log(res)
      if (res.data.code === 200) {
        this.setState({
          todaySmallClass: res.data.data.classNum,
        });
      }
    });
  };

  //标注点击事件
  onMarkerClick = (feature) => {
    //只有地图标注存在点击事件
    const { classEdit, downloadImg } = this.state;
    if (feature) {
      const data =
        feature.values_?.features?.length === 1
          ? feature.values_.features[0].values_
          : feature.values_;
      const lnglat = [data?.point?.x, data?.point?.y];
      const refresh = () => {
        if (OlMap.map.getView().getZoom() >= 17) {
          const view = OlMap.map.getView().calculateExtent(OlMap.map.getSize());
          const params = {
            maxLongitude: view[2],
            maxLatitude: view[3],
            minLongitude: view[0],
            minLatitude: view[1],
          };
          OlMap.map.removeLayer(this.pointLayers);
          this.pointLayers = null;
          this.load(params);
        }
        OlMap.map.removeLayer(this.Clusterer);
        this.Clusterer = null;
        this.load();
      };
      if (classEdit && downloadImg) {
        this.setState({
          choosePicId: data.id,
        });
      }
      lnglat[0] && OlMap.addInfoOverlay({ data, lnglat, refresh });
    }
  };

  //地图缩放
  MapChange = () => {
    const view = OlMap.map.getView().calculateExtent(OlMap.map.getSize()); //获取视角范围区域
    const params = {
      maxLongitude: view[2],
      maxLatitude: view[3],
      minLongitude: view[0],
      minLatitude: view[1],
    };
    //视角范围加载
    OlMap.map.removeLayer(this.pointLayers);
    this.pointLayers = null;

    if (OlMap.map.getView().getZoom() >= 17) {
      this.load(params);
    }
  };

  // 点位选择
  MovePointChoose = (feature) => {
    // OlMap.setStyle(feature, selectPoint)
    OlMap.map.removeInteraction(this.iconTranslate);
    this.iconTranslate = null;
    this.iconTranslate = OlMap.addPointerMove({ feature });
    this.iconTranslate.on("translateend", () => {
      let clickPoint = feature.getGeometry().flatCoordinates;
      const params = {
        id: feature.values_.id,
        longitude: clickPoint[0],
        latitude: clickPoint[1],
      };
      Acceptance.putMovePic(params).then((res) => {
        if (res.data.code === 200) {
          message.success("点位更改成功");
          OlMap.removePoints();
          //需要传个参数不然会有问题NND，这代码给我写的祝后来的人成功吧，加油！
          this.load({ remarkPoint: 1 });
        } else {
          message.error("点位更改失败");
        }
      });
    });
  };

  //点位移动拖拽
  MovePoint = () => {
    const { moveVisible } = this.state;
    if (moveVisible) {
      message.success("已关闭点位更改功能");
      OlMap.map.removeInteraction(this.iconTranslate);
      this.iconTranslate = null;
      this.setState({
        moveVisible: false,
      });
    } else {
      if (OlMap.map.getView().getZoom() >= 17) {
        message.destroy();
        message.info("请点击你要选择的更改的点位,然后开始进行拖拽...");
        this.setState({ moveVisible: true });
      } else {
        message.info("请放大到最大层级进行图片点位更改");
      }
    }
  };

  //地图点击事件
  MapClick = (e) => {
    const {
      moveVisible,
      classEdit,
      editKey,
      classDelPic,
      addClassInfo,
      dataList,
      droneEdit,
      userFlag,
    } = this.state;
    const { countyCode } = this.props;
    const { isInspection } = this.props;
    const year = sessionStorage.getItem("year");
    const [lng, lat] = [
      e.coordinate[0].toFixed(4) * 1,
      e.coordinate[1].toFixed(4) * 1,
    ];
    //点击的标注物
    const feature = OlMap.map.forEachFeatureAtPixel(e.pixel, (feature: any) => {
      return feature;
    });
    const featureList = OlMap.map.getFeaturesAtPixel(e.pixel);
    this.setState({ repeatPicLook: false });
    if (featureList.length === 1) {
      //只有地图标注存在点击事件
      !moveVisible && this.onMarkerClick(feature);
      moveVisible && this.MovePointChoose(feature);
    } else if (featureList.length > 1) {
      this.repeatPoint(featureList);
    }
    // console.log(countyCode)
    // 处于小班编辑模式
    if (classEdit && !feature) {
      Common.getClassLonlat({
        x: lng,
        y: lat,
        year,
        countyCode: countyCode,
      }).then((res) => {
        if (res.data.code === 200 && res.data.data) {
          const { classId, smallClass } = res.data.data;
          if (smallClass) {
            if (addClassInfo && addClassInfo.smallClass === smallClass) {
              // message.info('当前小班已选择，请勿重复点击')
            } else {
              if (addClassInfo && addClassInfo.smallClass !== smallClass) {
                const filterDataList = dataList.filter((item) => {
                  return item.classId !== addClassInfo.classId;
                });
                this.setState(
                  {
                    dataList: filterDataList,
                  },
                  () => {
                    this.setState({
                      addClassInfo: {
                        picCutList: [],
                        picDroneList: [],
                        frequency: 1,
                        showExpand: true,
                        situation: "",
                        ...res.data.data,
                      },
                      editKey: 2,
                      classResult: "all",
                      historyModelFlag: true,
                    });
                    Acceptance.getAcceptInfo({
                      x: lng,
                      y: lat,
                      year,
                      countyCode,
                      isInspection,
                    }).then((res) => {
                      if (res.data.code === 200) {
                        this.setState(
                          {
                            historyList: res.data.data.acceptRecords,
                            thisClassName: res.data.data.className,
                          },
                          () => {
                            console.log(this.state.historyList);
                          }
                        );
                        if (res.data.data.isMistake === 1) {
                          message.error("小班验收数据有问题");
                        }
                      }
                    });

                    //傻逼完成超
                    message.success("选择小班成功");
                  }
                );
              } else {
                Acceptance.getAcceptInfo({
                  x: lng,
                  y: lat,
                  year,
                  countyCode,
                  isInspection,
                }).then((res) => {
                  if (res.data.code === 200) {
                    this.setState(
                      {
                        historyList: res.data.data.acceptRecords,
                        thisClassName: res.data.data.className,
                      },
                      () => {
                        console.log(this.state.historyList);
                      }
                    );
                    if (res.data.data.isMistake === 1) {
                      message.error("小班验收数据有问题");
                    }
                  }
                });
                message.success("选择小班成功");
                // 所选小班为待验收小班
                this.setState({
                  addClassInfo: {
                    picCutList: [],
                    picDroneList: [],
                    frequency: 1,
                    showExpand: true,
                    situation: "",
                    ...res.data.data,
                  },
                  editKey: 2,
                  historyModelFlag: true,
                });
              }
            }
            this.getClassPicById(classId, year, userFlag);
          } else {
            message.error("小班数据不存在，无法选择");
          }
        } else {
          message.error("获取数据失败，请联系管理员");
        }
      });
    } else if (classDelPic) {
      Common.getClassLonlat({
        x: lng,
        y: lat,
        year,
        countyCode: countyCode,
      }).then((res) => {
        if (res.data.code === 200 && res.data.data) {
          const { classId, smallClass, villageName, forestCode } =
            res.data.data;
          const classDelName = {
            name: villageName + "-" + forestCode + "-" + smallClass,
            classId,
          };
          this.setState({
            classDelName,
          });
        }
      });
    } else {
      // 查看验收记录
      if (!feature && OlMap.map.getView().getZoom() >= 16) {
        Common.getAcceptInfo({
          x: lng,
          y: lat,
          year,
          isInspection: isInspection,
          countyCode: countyCode,
        }).then((res) => {
          if (res.data.code === 200) {
            const lnglat = [lng, lat];
            const data = res.data.data;
            OlMap.addAcceptInfo({ data, lnglat });
          } else if (res.data.code === 500) {
            message.error(res.data.msg);
          }
        });
      } else {
        !feature && message.info("请放大层级再查看验收记录");
      }
    }

    // console.log('航拍照片关联', feature)
    //航拍照片关联
    this.associated(feature);
  };

  //获取指定小班照片
  getClassPicById = (classId, year, userFlag) => {
    const { isInspection } = this.props;
    Acceptance.getPicByClassid({
      classId,
      year,
      userFlag,
      isInspection: isInspection,
    }).then((obj) => {
      if (obj.data.code === 200) {
        this.setState({
          downloadImg: obj.data.data,
          downloadBackup: obj.data.data,
          choosePicId: null,
          userFlag: userFlag,
        });
      } else {
        message.error("获取小班照片数据失败");
      }
    });
  };

  //重复点位点击处理
  repeatPoint = (featureList) => {
    const { classEdit } = this.state;
    const feaValueList = [];
    if (!classEdit) {
      featureList.map((item, index) => {
        feaValueList.push(item.values_);
        if (index === featureList.length - 1) {
          this.setState({
            downloadImg: feaValueList,
            repeatPicLook: true,
          });
        }
      });
    } else {
      featureList.map((item, index) => {
        feaValueList.push(item.values_);
        if (index === featureList.length - 1) {
          this.setState({
            downloadImg: feaValueList,
          });
        }
      });
    }
  };

  //照片关联
  associated = (feature) => {
    const { classEdit, editKey, addClassInfo, dataList, droneEdit } =
      this.state;
    // 小班编辑模式
    if (classEdit && editKey == 2) {
      console.log("傻逼完成超");

      const { id, type, url, address, altitude, point, result } =
        feature.values_
          ? feature.values_.features
            ? feature.values_.features[0].values_
            : feature.values_ || feature
          : feature;
      let oldList =
        type == 1 ? addClassInfo.picCutList : addClassInfo.picDroneList;
      let list;
      let obj = oldList.find((item) => {
        return id == item.id;
      });
      if (obj) {
        console.log("傻逼完成超");
        list = oldList;
      } else {
        console.log("傻逼完成超111");
        this.setState({
          historyModelFlag: false,
        });
        list = [
          ...oldList,
          {
            address,
            altitude,
            point,
            url,
            uid: id,
            id,
            result,
            status: "done",
            origin: true,
          },
        ];
      }
      // console.log(type)
      if (type == 1) {
        // console.log('伐')
        addClassInfo.picCutList = list;
      } else {
        // console.log('航')
        addClassInfo.picDroneList = list;
      }
      this.setState(
        {
          addClassInfo,
        },
        () => {
          // 增加一条临时数据
          let has = dataList.find((item) => {
            return item.id == addClassInfo.id;
          });
          if (has) {
          } else {
            const tmp = [this.state.addClassInfo, ...dataList];
            this.setState({
              dataList: tmp,
            });
          }
        }
      );
    }

    if (droneEdit) {
      if (feature) {
        const { id, type, url, address, altitude, point, time, yaw, result } =
          feature.values_
            ? feature.values_.features
              ? feature.values_.features[0].values_
              : feature.values_
            : feature;
        if (type == 2) {
          dataList.forEach((item) => {
            if (item.id == this.expandRecord.id) {
              if (
                item.picDroneList.length == 0 ||
                (item.picDroneList.length > 0 &&
                  !item.picDroneList.find((r) => {
                    return r.id == id;
                  }))
              ) {
                item.picDroneList.push({
                  address,
                  altitude,
                  point,
                  url,
                  time,
                  yaw,
                  uid: id,
                  id,
                  result,
                  origin: true,
                  status: "done",
                  from: "relation",
                });
                message.success("图片选择成功！");
              } else {
                message.error("图片已被关联！");
              }
            }
          });
          this.setState({
            dataList,
          });
        } else {
          message.error("仅支持与航拍照片进行关联");
        }
      }
    }
  };

  //搜索
  onSearch = (value) => {
    this.setState(
      {
        key: value,
        page: 1,
        dataList: [],
      },
      this.getData
    );
  };

  // 获取待验收小班数据
  getData = () => {
    const { page, key, dataList, total, hasMore } = this.state;
    const { countyCode, isInspection } = this.props;
    const param = {
      key: key,
      countyCode,
      page,
      year: sessionStorage.getItem("year"),
      size: this.pageSize,
      result: -1,
      isInspection: isInspection,
      isSmartUpload: true,
    };
    Acceptance.getList(param).then((res) => {
      if (res.data.code == 200) {
        res.data.data.list.forEach((item) => {
          item.showExpand = false;
        });
        const list = [...dataList, ...res.data.data.list];
        const newPage = page + 1;
        this.setState({
          dataList: list,
          total: res.data.data.total,
          page: newPage,
        });
      }
    });
  };

  //修改数据项
  update = (value, data, i) => {
    const { countyCode } = this.props;
    console.log(value);
    console.log(data);
    let situation = {};
    value.situationList &&
      value.situationList.map((e) => {
        situation[e.first] = e.last;
      });
    value.demeritPoints = situation;
    value.countyCode = countyCode;
    // console.log(value)
    // let data = JSON.parse(JSON.stringify(r))
    const cityName = sessionStorage.getItem("cityName");
    const cutList = data.picCutList.length !== 0 ? data.picCutList : [];
    const droneList = data.picDroneList.length !== 0 ? data.picDroneList : [];
    const list1 = cutList.map((item) => {
      return item.origin
        ? { ...item, type: 1, thumbUrl: item.url, cityName: cityName }
        : {
            ...item,
            type: 1,
            file: item.originFileObj,
            thumbUrl: item.thumbUrl,
            cityName: cityName,
          };
    });
    const list2 = droneList.map((item) => {
      return item.origin
        ? { ...item, type: 2, thumbUrl: item.url, cityName: cityName }
        : {
            ...item,
            type: 2,
            file: item.originFileObj,
            thumbUrl: item.thumbUrl,
            cityName: cityName,
          };
    });
    message.info({
      content: "图片正在解析中,请稍等一会",
      key: "img",
      duration: 0,
    });
    handleImage(list1).then((res1) => {
      handleImage(
        list2.filter((item) => {
          return !item.from;
        })
      ).then((res2) => {
        message.loading({
          content: "图片解析成功，正在进行上传...",
          duration: 3,
          key: "img",
        });
        uploadImage2(res1).then((res3: any) => {
          uploadImage2(res2).then((res4: any) => {
            message.success({
              content: "图片上传成功",
              key: "img",
              duration: 1,
            });
            data.demeritPoints = value.demeritPoints;
            data.result = value.result;
            data.picCutList = res3;
            data.picDroneList = [
              ...data.picDroneList.filter((item) => {
                return item.from && item.from == "relation";
              }),
              ...res4,
            ];
            data.isInspection = this.props.isInspection;
            data.countyCode = countyCode;
            data.year = sessionStorage.getItem("year");
            //判断是不是监理检查页面
            if (window.location.href.indexOf("check") > 0) {
              data.surveyPileNum = value.surveyPileNum;
              data.failedPileNum = value.failedPileNum;
              data.twigRetentionNum = value.twigRetentionNum;
            }
            if (value.cityCode != "331102") {
              data.situation = value.situation;
            }
            console.log(sessionStorage.getItem("year"));
            Acceptance.updateSmartData(data).then((res) => {
              if (res.data.code == 200) {
                message.success("修改成功");
                this.setState(
                  {
                    dataList: [],
                    page: 1,
                  },
                  this.getData
                );
              }
            });
          });
        });
      });
    });
  };
  //数据项展开
  expand = (value, index) => {
    console.log(value);
    const { dataList } = this.state;
    let record = value;
    record.showExpand = !record.showExpand;
    dataList[index] = record;
    this.setState(
      {
        dataList,
      },
      () => {
        if (record.showExpand) {
          message.loading("正在获取详细数据，请稍后...", 0);
          Acceptance.getPicList({
            id: value.id,
            isInspection: this.props.isInspection,
            year: sessionStorage.getItem("year"),
          }).then((res) => {
            if (res.data.data) {
              // record.picInfoList = res.data.data
              let list1 = [];
              let list2 = [];
              if (res.data.data.length == 0) {
                record.picCutList = list1;
                record.picDroneList = list2;
                dataList[index] = record;
                this.setState(
                  {
                    dataList,
                  },
                  () => {
                    message.destroy();
                  }
                );
              } else {
                res.data.data.forEach((item, key) => {
                  const content = {
                    id: item.id,
                    uid: "1_" + key.toString(),
                    status: "done",
                    origin: true,
                    from: "relation",
                    url: item.url,
                    address: item.address,
                    altitude: item.altitude,
                    point: item.point,
                    time: item.time,
                    yaw: item.yaw,
                    result: item.result,
                  };
                  if (item.type == 1) {
                    list1.push(content);
                  } else {
                    list2.push(content);
                  }
                  if (key == res.data.data.length - 1) {
                    record.picCutList = list1;
                    record.picDroneList = list2;
                    dataList[index] = record;
                    this.setState(
                      {
                        dataList,
                      },
                      () => {
                        message.destroy();
                      }
                    );
                  }
                });
              }
            } else {
              message.destroy();
            }
          });
          //跳转
          this.locationClass(value.classId).then((classMes: any) => {
            const { x, y } = classMes.point;
            const LonLat = [x, y];
            OlMap.ToLngLat(LonLat, 17);
          });
        }
      }
    );
  };

  //智能上报修改伐桩照片
  onFileChanged1 = (file, data, index) => {
    const { dataList } = this.state;
    data.picCutList = file.fileList;
    dataList[index] = data;
    this.setState({
      dataList,
    });
  };

  //智能上报修改航拍照片
  onFileChanged2 = (file, data, index) => {
    const { dataList } = this.state;
    data.picDroneList = file.fileList;
    dataList[index] = data;
    this.setState({
      dataList,
    });
  };

  //航拍图片关联
  editDrone = (value) => {
    const { droneEdit, dataList, countyCode } = this.state;
    console.log(value);
    if (!droneEdit) {
      this.expandRecord = value;
      this.setState(
        {
          droneEdit: true,
        },
        () => {
          message.success("航拍照片关联已开启，请在地图上选择图片");
        }
      );
    } else {
      const data = dataList.find((item) => {
        return item.id == this.expandRecord.id;
      });
      //筛选是否存在从本地上传的照片，将上传的解析打水印，关联的照片跳过，直接进行关联
      // const ListPromise = new Promise((resolve, reject) => {
      // 	const newdata = data.picDroneList.filter(item => { return (!item.from && !item.origin) })
      // 	resolve(newdata)
      // })
      // ListPromise.then((list: any) => {
      // 	const newData = list.map(item => {
      // 		return { name: item.name, file: item.originFileObj, type: 2, thumbUrl: item.thumbUrl }
      // 	})
      message.info({
        content: "图片正在解析中,请稍等一会",
        key: "img",
        duration: 0,
      });
      handleImage(
        data.picDroneList.filter((item) => {
          return !item.from;
        })
      ).then((res1) => {
        message.success({
          content: "图片解析成功，正在进行上传...",
          duration: 3,
          key: "img",
        });
        uploadImage2(res1).then((res2: any) => {
          message.success({ content: "图片上传成功", key: "img", duration: 1 });
          data.picDroneList = [
            ...data.picDroneList.filter((item) => {
              return item.from && item.from == "relation";
            }),
            ...res2,
          ];
          data.isInspection = this.props.isInspection;
          // this.expandRecord = null
          // message.destroy()
          // this.setState({
          // 	page: 1,
          // 	dataList: [],
          // 	droneEdit: false
          // }, this.getData)
          // data.isTransmitPhoto = 0
          Acceptance.updateSmartData(data).then((res) => {
            if (res.data.code == 200) {
              message.destroy();
              message.success("关联成功");
              this.expandRecord = null;
              this.setState({
                // page: 1,
                // dataList: [],
                droneEdit: false,
              });
            } else {
              message.error(res.data.msg);
              this.setState({
                droneEdit: false,
              });
            }
          });
        });
      });
      // })
    }
  };

  changeUploadImg = (value) => {
    this.setState({
      uploadImgVisible: !value,
    });
  };
  changeOldUploadImg = (value) => {
    this.setState({
      oldUploadImgVisible: !value,
    });
  };

  openUploadImgModal = () => {
    this.setState({
      uploadImgVisible: true,
    });
  };
  closeUploadImgModal = () => {
    const { flyPoint } = this.state;

    if (flyPoint) {
      OlMap.ToLngLat(flyPoint, 18);
      this.load();
    }
    this.setState({
      uploadImgVisible: false,
      flyPoint: null,
    });
  };

  changeSearchKey = (key) => {
    this.setState({
      searchKey: key,
    });
  };

  //上报图片
  point = (list, result, type) => {
    const { oldUploadImgVisible, isWater } = this.state;
    // console.log(123)
    const newList = list.filter((item) => {
      return item.point.x && item.point.y;
    });
    message.loading({
      content: "图片正在上传中,请稍等一会",
      key: "upload",
      duration: 0,
    });
    uploadImage2(newList).then((res) => {
      console.log(res);
      message.success({ content: "图片上传成功", key: "upload", duration: 1 });
      const params = {
        result,
        dataList: res,
        picType: type,
        year: sessionStorage.getItem("year"),
        isInspection: this.props.isInspection,
      };
      const lnglat = [newList[0].point.x, newList[0].point.y];
      OlMap.ToLngLat(lnglat, 17);
      console.log(params);
      Acceptance.smartAdd(params).then((res) => {
        if (res.data.code == 200) {
          message.success("上报成功");
          this.setState(
            {
              page: 1,
              dataList: [],
            },
            () => {
              OlMap.map.removeLayer(this.Clusterer);
              this.Clusterer = null;
              this.load();
              this.getData();
              this.changeOldUploadImg(oldUploadImgVisible);
            }
          );
        }
      });
    });
  };

  //批量备注内容
  InputValue = (value) => {
    this.setState({
      allRemark: value.target.value,
    });
  };
  //批量修改验收结果
  changeAllResult = (value) => {
    this.setState({
      allResult: value,
    });
  };
  // 批量修改
  updateAll = (val) => {
    const { allResult, dataList, allRemark } = this.state;
    const { isInspection, countyCode } = this.props;
    // console.log(val)
    let situation = {};
    val.situationList &&
      val.situationList.map((e) => {
        situation[e.first] = e.last;
      });
    val.demeritPoints = situation;
    const param = {
      key: "",
      page: 1,
      size: 10000,
      result: -1,
      isInspection: isInspection,
      isSmartUpload: true,
    };
    // console.log(param)
    Acceptance.getList(param).then((res) => {
      if (res.data.code == 200) {
        const value = {
          idList: res.data.data.list.map((item) => {
            return item.id;
          }),
          picCutList: [],
          picDroneList: [],
          demeritPoints: val.demeritPoints,
          result: allResult,
          isInspection: isInspection,
          isSmartUpload: true,
          countyCode,
          situation: val.situation,
          year: sessionStorage.getItem("year"),
        };
        const noRemarkValue = {
          idList: res.data.data.list.map((item) => {
            return item.id;
          }),
          picCutList: [],
          picDroneList: [],
          result: allResult,
          isInspection: isInspection,
          isSmartUpload: true,
          countyCode,
          situation: val.situation,
        };
        console.log(value, noRemarkValue);

        Acceptance.ChangeAll(val.demeritPoints ? value : noRemarkValue).then(
          (res) => {
            if (res.data.code == 200) {
              message.success("修改成功");
              this.setState(
                {
                  page: 1,
                  total: 0,
                  updateAllVisible: false,
                  dataList: [],
                },
                this.getData
              );
            } else {
              message.error("修改失败");
            }
          }
        );
      }
    });
  };

  // 加载图层
  renderLayer = () => {
    const { isShowLayer } = this.state;
    this.setState(
      {
        isShowLayer: !isShowLayer,
      },
      () => {
        if (!isShowLayer) {
          OlMap.layer.passLayer.setVisible(false);
          OlMap.layer.unPassLayer.setVisible(false);
          OlMap.layer.noAccLayer.setVisible(false);
        } else {
          OlMap.layer.passLayer.setVisible(true);
          OlMap.layer.unPassLayer.setVisible(true);
          OlMap.layer.noAccLayer.setVisible(true);
        }
      }
    );
  };

  // 小班操作提示确认
  classEditConfrim = () => {
    const { editKey, addClassInfo } = this.state;

    if (editKey == 1) {
      this.setState({
        editKey: 2,
      });
    } else if (editKey == 2) {
      let ids = [];
      addClassInfo.picCutList.forEach((item) => {
        ids.push(item.id);
      });
      addClassInfo.picDroneList.forEach((item) => {
        ids.push(item.id);
      });
      const params = {
        classId: addClassInfo.classId,
        picIdList: ids,
        isInspection: this.props.isInspection,
        requestIdentifying: 0,
      };
      // 新增一条记录
      Acceptance.classEidt(params).then((res) => {
        if (res.data.code == 200 && !res.data.data) {
          message.success("关联成功");
          this.setState(
            {
              page: 1,
              dataList: [],
              classEdit: false,
              editKey: 0,
              addClassInfo: null,
            },
            this.getData
          );
        } else if (res.data.code == 200 && res.data.data) {
          this.setState({
            newOrOverFlag: true,
            hourData24: res.data.data,
          });
        } else {
          this.setState({
            newOrOverFlag: false,
            hourData24: null,
          });
          message.error(res.data.msg);
        }
      });
    }
  };

  //对小班24小时内有操作的调整确认或取消
  handleOk = (val: any) => {
    const { editKey, addClassInfo, afterControl } = this.state;
    let ids = [];
    addClassInfo.picCutList.forEach((item) => {
      ids.push(item.id);
    });
    addClassInfo.picDroneList.forEach((item) => {
      ids.push(item.id);
    });
    if (val) {
      const params = {
        classId: addClassInfo.classId,
        picIdList: ids,
        isInspection: this.props.isInspection,
        requestIdentifying: afterControl,
      };
      Acceptance.classEidt(params).then((res) => {
        // console.log(res)
        if (res.data.code == 200) {
          message.success("关联成功");
          this.setState({
            newOrOverFlag: false,
            hourData24: null,
          });
          this.setState(
            {
              page: 1,
              dataList: [],
              classEdit: false,
              editKey: 0,
              addClassInfo: null,
            },
            this.getData
          );
        } else {
          this.setState({
            newOrOverFlag: false,
            hourData24: null,
          });
          message.error(res.data.msg);
        }
      });
    } else {
      const params = {
        classId: addClassInfo.classId,
        picIdList: ids,
        isInspection: this.props.isInspection,
        requestIdentifying: 3,
      };
      Acceptance.classEidt(params).then((res) => {
        if (res.data.code == 200) {
          message.success("关联成功");
          this.setState({
            newOrOverFlag: false,
            hourData24: null,
          });
          this.setState(
            {
              page: 1,
              dataList: [],
              classEdit: false,
              editKey: 0,
              addClassInfo: null,
            },
            this.getData
          );
        } else {
          this.setState({
            newOrOverFlag: false,
            hourData24: null,
          });
          message.error(res.data.msg);
        }
      });
    }
  };

  // 小班操作提示取消
  classEditCancel = () => {
    const { editKey, addClassInfo, dataList } = this.state;
    // console.log(editKey)
    this.setState({
      historyModelFlag: false,
    });
    if (editKey == 1) {
      this.setState({
        classEdit: false,
        editKey: 0,
      });
    } else {
      if (
        addClassInfo.picCutList.length > 0 ||
        addClassInfo.picDroneList.length > 0
      ) {
        let str = addClassInfo;
        str.picCutList = [];
        str.picDroneList = [];
        this.setState(
          {
            addClassInfo: str,
          },
          () => {
            const list = dataList.filter((r, i) => {
              return i !== 0;
            });
            // console.log(list)
            this.setState({
              dataList: list,
              choosePicId: null,
            });
          }
        );
      }
    }
  };

  // 加载初始点位图片数据
  load = (params?: any) => {
    // OlMap.map.removeLayer(OlMap.getLayer("clusterLayers"))
    const { showAll, allFlag, allCutFlag } = this.state;
    const { countyCode, countyList, isInspection, townCode, townList } =
      this.props;
    let town;
    countyList.map((item) => {
      if (item.code === countyCode) {
        return (town = item.name);
      }
    });
    if (townCode && this.role === "ROLE_2") {
      townList.forEach((item) => {
        if (item.code === townCode) {
          return (town = item.name);
        }
      });
    }
    let key;

    let newParams = {
      ...params,
      town: town === "景宁县" ? "景宁" : town,
      year: sessionStorage.getItem("year"),
      showAll: showAll,
      isInspection: isInspection,
      allFlag,
      allCutFlag,
    };
    //取消请求
    const CancelToken = new axios.CancelToken(function executor(c) {
      cancel = c;
      //window.cancel = c
    });
    Acceptance.getAllPicList(newParams, CancelToken).then((res) => {
      if (res.data.code == 200) {
        if (res.data.data.length !== 0) {
          const newPromise = new Promise((resolve, reject) => {
            res.data.data.map((item, index) => {
              item.yaw = parseFloat(item.yaw);
              while (item.yaw < 0) {
                item.yaw += 360;
              }
              const resultPromise = new Promise((resolve, reject) => {
                if (item.yaw % 22.5 == 0) {
                  key = item.yaw;
                  resolve(key);
                } else {
                  if (!item.yaw) {
                    resolve(1000);
                  } else {
                    while (item.yaw > 360) {
                      item.yaw -= 360;
                    }
                    droneList.forEach((r, i) => {
                      if (
                        r <= item.yaw &&
                        item.yaw <=
                          droneList[i + 1 == droneList.length + 1 ? 16 : i + 1]
                      ) {
                        key =
                          item.yaw - r >
                          droneList[
                            i + 1 == droneList.length + 1 ? 16 : i + 1
                          ] -
                            item.yaw
                            ? droneList[
                                i + 1 == droneList.length + 1 ? 16 : i + 1
                              ]
                            : r;
                        resolve(key);
                      }
                    });
                  }
                }
              });
              resultPromise.then((data: any) => {
                item.yaw = data;
                if (index == res.data.data.length - 1) {
                  resolve(res.data.data);
                }
              });
            });
          });
          newPromise.then((res) => {
            console.log(params);
            params
              ? (this.pointLayers = OlMap.addPoints({ data: res }))
              : (this.Clusterer = OlMap.addMarkerClusterer({ data: res }));
            message.destroy();
            this.setState({
              pointVisiable: params ? true : false,
            });
          });
        } else {
          message.destroy();
        }
      } else {
        message.destroy();
        message.error(res.data.msg);
      }
    });
  };

  // 小班搜索
  classSearch = () => {
    this.setState(
      {
        classSearch: !this.state.classSearch,
        searchKey: this.state.classSearch ? 0 : 1,
      },
      () => {
        if (this.state.classSearch) {
          message.success("小班搜索功能已开启");
        } else {
          this.setState({
            searchKey: 0,
            searchKeyValue: "",
          });
          message.info("小班搜索功能已关闭");
        }
      }
    );
  };

  // 小班编辑
  classEdit = () => {
    const { classEdit, editKey, dataList, addClassInfo } = this.state;
    if (OlMap.map.getView().getZoom() >= 17) {
      this.setState(
        {
          classEdit: !classEdit,
          editKey: classEdit ? 0 : editKey,
        },
        () => {
          if (this.state.classEdit) {
            message.success(
              "小班编辑功能已开启，请在地图上使用左键点击选择想要编辑的小班"
            );
          } else {
            const filterDataList = dataList.filter((item) => {
              return item?.classId !== addClassInfo?.classId;
            });
            this.setState({
              addClassInfo: null,
              editKey: 0,
              dataList: filterDataList,
              downloadImg: [],
              downloadBackup: [],
              userFlag: true,
              classResult: "all",
              historyModelFlag: false,
            });
            message.info("小班编辑功能已关闭");
          }
        }
      );
    } else {
      message.info("请出现小班图层后再使用该功能");
    }
  };

  //点击照片列表的回调
  picChoose = (picData) => {
    const zoom = OlMap.map.getView().getZoom();
    const lonlat = [picData.point.x, picData.point.y];
    OlMap.ToLngLat(lonlat, zoom);
    this.setPointStyle(picData);
    this.setState({
      choosePicId: picData.id,
    });
    console.log(picData);
    //航拍照片关联
    this.associated(picData);
  };

  //选中后修改图标样式
  setPointStyle = (picData) => {
    if (!timming) {
      timming = setInterval(() => {
        const { pointVisiable, choosePicId } = this.state;
        if (choosePicId && pointVisiable) {
          const FeaturesList = this.pointLayers.getSource().getFeatures();
          FeaturesList.map((item, index) => {
            if (item.values_.id === picData.id) {
              OlMap.setStyle(item, selectPoint);
              this.setState({
                pointVisiable: false,
              });
              clearInterval(timming);
              timming = null;
            }
          });
        }
      }, 500);
    }
  };

  // 我的
  myClick = () => {
    if (OlMap.map.getView().getZoom() < 17) {
      OlMap.map.removeLayer(OlMap.getLayer("clusterLayers"));
      message.loading({
        content: this.state.showAll
          ? "正在切换至个人数据"
          : "正在切换至全量数据,数据量较多，请耐心等待...",
        key: "myMessage",
        duration: 0,
      });
      cancel && cancel();
      this.setState(
        {
          showAll: !this.state.showAll,
          allCutFlag: false,
        },
        () => {
          this.load();
        }
      );
    } else {
      message.info("请缩小地图层级再开启我的功能", 1);
    }
  };

  classDelPic = () => {
    const { classDelPic, classDelName } = this.state;
    if (classDelPic) {
      this.setState({
        classDelPic: !classDelPic,
        classDelName: null,
      });
    } else {
      if (OlMap.map.getView().getZoom() >= 16) {
        this.setState({
          classDelPic: !classDelPic,
          classDelName: null,
        });
      } else {
        message.info("请放大到小班层级再进行删除");
      }
    }
  };

  // 删除小班图片
  deletePic_class = () => {
    const { classDelName } = this.state;
    const { isInspection } = this.props;
    const year = sessionStorage.getItem("year");
    const params = {
      year,
      classId: classDelName?.classId,
      isInspection,
    };
    classDelName &&
      Modal.confirm({
        icon: <DeleteOutlined rev={undefined} />,
        content: (
          <div id={"sync-data-text"}>
            确认删除{classDelName.name}小班的所有图片？
          </div>
        ),
        onOk: () => {
          Acceptance.delPicByClassid(params).then((res) => {
            if (res.data.code === 200) {
              message.success(`成功${res.data.data}`);
              OlMap.map.removeLayer(OlMap.getLayer("clusterLayers"));
              const view = OlMap.map
                .getView()
                .calculateExtent(OlMap.map.getSize());
              const params = {
                maxLongitude: view[2],
                maxLatitude: view[3],
                minLongitude: view[0],
                minLatitude: view[1],
              };
              OlMap.map.removeLayer(this.pointLayers);
              this.pointLayers = null;
              this.load(params);
              this.load();
              this.setState({
                classDelName: null,
              });
            } else {
              message.error("删除失败");
            }
          });
        },
        onCancel() {},
      });
  };

  //下载图片
  downLoadImg = () => {
    const { addClassInfo, downloadImg } = this.state;
    const { villageName, villageCode, forestCode } = addClassInfo;
    const className = villageName + "-" + forestCode + "-" + villageCode;
    const zipList = [];
    downloadImg.map((item, index) => {
      this.getBase64(item.url)
        .then((res) => {
          zipList.push({
            ...item,
            base64: res,
          });
          if (index === downloadImg.length - 1) {
            this.toZip(zipList, className);
          }
        })
        .catch((rej) => {
          if (rej === "error") message.error("图片无法访问");
        });
    });
    message.loading({
      content: "图片正在压缩导出，请稍等...",
      key: "export",
      duration: 0,
    });
  };

  //将图片url转换为base64
  getBase64 = (imgUrl) => {
    return new Promise((resolve, reject) => {
      window.URL = window.URL || window.webkitURL;
      var xhr = new XMLHttpRequest();
      xhr.open("get", imgUrl, true);
      // 至关重要
      xhr.responseType = "blob";
      xhr.onload = function () {
        if (this.status == 200) {
          //得到一个blob对象
          var blob = this.response;
          // 至关重要
          let oFileReader = new FileReader();
          oFileReader.onloadend = function (e) {
            let base64 = e.target.result;
            resolve(base64);
          };
          oFileReader.readAsDataURL(blob);
        } else {
          reject("error");
        }
      };
      xhr.send();
    });
  };

  //导出图片
  toZip = (zipList, className) => {
    let zip = new JSZip();
    let imgFolder = zip.folder(`${className}`);
    zipList.map((item, index) => {
      const url =
        "http://mountain-field-acceptance.oss-cn-hangzhou.aliyuncs.com/1/";
      const name = this.getCaption(item.url, url);
      const base = item.base64.replace(
        /^data:image\/(png|jpg|jpeg);base64,/,
        ""
      );
      imgFolder.file(name, base, { base64: true });
    });
    zip
      .generateAsync({
        type: "blob",
      })
      .then(function (content) {
        message.destroy("export");
        message.success({ content: "压缩包导出成功", duration: 3 });
        FileSaver.saveAs(content, `${className}.zip`);
      });
  };

  getCaption = (obj, text) => {
    let index = obj.lastIndexOf(text) + text.length - 1;
    obj = obj.substring(index + 1, obj.length);
    return obj;
  };

  // 定位到小班位置
  locationClass = (id) => {
    return new Promise((resolve, reject) => {
      Common.getClassCenter({ classId: id }).then((res) => {
        if (res.data.code == 200) {
          const { x, y } = res.data.data;
          resolve(res.data.data);
        }
      });
    });
  };

  //照片列表选择
  classPicSelect = (value) => {
    const year = sessionStorage.getItem("year");
    const { addClassInfo, downloadBackup } = this.state;
    const resultGood = downloadBackup.filter((item) => item.result === 0);
    const resultBad = downloadBackup.filter((item) => item.result === 1);
    switch (value) {
      case true:
        this.getClassPicById(addClassInfo.classId, year, true);
        break;
      case false:
        this.getClassPicById(addClassInfo.classId, year, false);
        break;
      case "1":
        this.setState({
          downloadImg: resultBad,
          classResult: value,
        });
        break;
      case "2":
        this.setState({
          downloadImg: resultGood,
          classResult: value,
        });
        break;
      case "all":
        this.setState({
          downloadImg: downloadBackup,
          classResult: value,
        });
        break;
      default:
        break;
    }
  };

  getSunScoreData = () => {
    const { countyCode } = this.props;
    let param = {
      year: sessionStorage.getItem("year"),
      countyCode,
    };
    Acceptance.getSubScore(param).then((res) => {
      if (res.data.code === 200) {
        if (res.data.data.length > 0) {
          let arr = [];
          res.data.data.forEach((e) => {
            arr.push({
              value: e,
              label: e.substring(0, 10),
            });
          });
          this.setState({
            situationOption: arr,
            situationOptionFlag: true,
          });
        }
      }
    });
  };

  // 图片预览
  previewImage = async (file, fileList) => {
    this.setState({
      imageVisible: true,
      previewImage: fileList,
    });
    // let src = file.url;
    // if (!src) {
    //     src = await new Promise(resolve => {
    //         const reader = new FileReader();
    //         reader.readAsDataURL(file.originFileObj);
    //         reader.onload = () => resolve(reader.result);
    //     });
    // }
    // let str = `<!DOCTYPE html>
    //     <html lang="en">
    //     <head>
    //       <meta charset="UTF-8">
    //       <meta http-equiv="X-UA-Compatible" content="IE=edge">
    //       <meta name="viewport" content="width=device-width, initial-scale=1.0">
    //       <title>验收图片${file.name || ''}预览</title>
    //       <style>
    //         * {
    //           margin: 0;
    //           padding: 0;
    //         }
    //         body {
    //           width: 100vw;
    //           height: 100vh;
    //           background-color: #000000CC;
    //           text-align: center;
    //         }
    //         img {
    //           max-width: 100vw;
    //           max-height: 100vh;
    //         }
    //       </style>
    //     </head>
    //     <script>
    //     </script>
    //     <body>
    //       <img src=${src} />
    //     </body>
    //     </html>`;
    // var a = window.open("")
    // a.document.write(str);
  };

  render() {
    const props: any = {
      listType: "picture-card",
      data: (file) => {
        return {
          file,
          type: 2,
        };
      },
      beforeUpload: () => {
        return false;
      },
      multiple: true,
      accept: ".jpg,.png,jpeg",
      showUploadList: {
        showPreviewIcon: true,
        showRemoveIcon: true,
        showDownloadIcon: false,
      },
    };
    const promptList = [
      {
        title: "验收不通过",
        key: "red",
        class: "red",
      },
      {
        title: "验收通过",
        key: "green",
        class: "green",
      },
      {
        title: "未纳入考核",
        key: "gray",
        class: "gray",
      },
    ];

    const {
      situationOption,
      thisClassName,
      historyList,
      historyModelFlag,
      todaySmallClass,
      situationOptionFlag,
      moveVisible,
      newOrOverFlag,
      hourData24,
      classNum,
      acceptanceNum,
      repeatPicLook,
      uploadImgVisible,
      dataList,
      choosePicId,
      isWater,
      downloadImg,
      classDelPic,
      classDelName,
      total,
      isShowLayer,
      updateAllVisible,
      allResult,
      classEdit,
      editKey,
      addClassInfo,
      downloadBackup,
      droneEdit,
      classSearch,
      searchKey,
      searchKeyValue,
      showAll,
      allRemark,
      imageVisible,
      previewImage,
      oldUploadImgVisible,
    } = this.state;
    const {
      changeIntelligentVisible,
      intelligentVisible,
      isReading,
      countyCode,
      isInspection,
    } = this.props;

    return (
      <div className="Map-box">
        {/* 左侧地图 */}
        <div className="left">
          <Button
            type="primary"
            className={"backButton"}
            onClick={() => changeIntelligentVisible(intelligentVisible)}
            disabled={classEdit}
          >
            返回
          </Button>
          <Descriptions bordered className="class-descript">
            <Descriptions.Item label="验收总小班数(个)">
              {classNum}
            </Descriptions.Item>
            <Descriptions.Item label="总验收次数(次)">
              {acceptanceNum}
            </Descriptions.Item>
            <Descriptions.Item label="用户今日完成验收小班数">
              {todaySmallClass}
            </Descriptions.Item>
          </Descriptions>
          {classEdit && (
            <List
              className="showImgList"
              header={
                <div className="title">
                  <span className="title-index">序号</span>
                  <div className="title-select">
                    <Select
                      style={{ width: "103px" }}
                      defaultValue={true}
                      onSelect={(value) => {
                        this.classPicSelect(value);
                      }}
                      disabled={
                        addClassInfo && addClassInfo.classId ? false : true
                      }
                    >
                      <Option key="1" value={true}>
                        当日照片
                      </Option>
                      <Option key="2" value={false}>
                        所有照片
                      </Option>
                    </Select>
                  </div>
                </div>
              }
              footer={
                <>
                  <Select
                    style={{ width: "90px", marginRight: "8px" }}
                    defaultValue={"all"}
                    value={this.state.classResult}
                    placement="topLeft"
                    onSelect={(value) => {
                      this.classPicSelect(value);
                    }}
                    disabled={downloadBackup.length !== 0 ? false : true}
                  >
                    <Option key="1" value={"1"}>
                      合格
                    </Option>
                    <Option key="2" value={"2"}>
                      不合格
                    </Option>
                    <Option key="3" value={"all"}>
                      全部
                    </Option>
                  </Select>
                  <Button type="primary" onClick={this.downLoadImg}>
                    下载图片
                  </Button>
                </>
              }
              dataSource={downloadImg}
              renderItem={(r: any, index) => (
                <List.Item
                  key={r.id}
                  onClick={() => {
                    this.picChoose(r);
                  }}
                  style={{
                    background:
                      choosePicId && choosePicId === r.id && "#dcebf9",
                  }}
                >
                  <div className="source" key={r.id}>
                    <div>{index + 1}</div>
                    <div>{r.address}</div>
                  </div>
                </List.Item>
              )}
            />
          )}
          <div className="map" id="olmap"></div>
          <div className={"lengend"}>
            {promptList.map((item) => {
              return (
                <div>
                  <div className={`${item.class} color-box`} key={item.key} />
                  {item.title}
                </div>
              );
            })}
            {isShowLayer ? (
              <EyeInvisibleOutlined
                rev={undefined}
                className={"eyes"}
                onClick={this.renderLayer}
              />
            ) : (
              <EyeOutlined
                rev={undefined}
                className={"eyes"}
                onClick={this.renderLayer}
              />
            )}
          </div>
          <div style={{ position: "absolute", bottom: "20px", left: "30px" }}>
            <Tooltip placement="top" title="是否展示全域图片">
              <Switch
                onChange={(value) => {
                  OlMap.map.removeLayer(OlMap.getLayer("clusterLayers"));
                  message.loading({
                    content: value
                      ? "正在切换至全域数据"
                      : "正在切换至当前区县数据",
                    key: "myMessage",
                    duration: 0,
                  });
                  this.setState({ allFlag: value }, () => {
                    this.load();
                  });
                }}
              />
            </Tooltip>
          </div>
          {countyCode === "331121" && (
            <div style={{ position: "absolute", bottom: "50px", left: "30px" }}>
              <Tooltip placement="top" title="是否展示所有砍伐照片">
                <Switch
                  onChange={(value) => {
                    OlMap.map.removeLayer(OlMap.getLayer("clusterLayers"));
                    message.loading({
                      content: value
                        ? "正在切换至所有伐桩图片"
                        : "正在切换回个人信息数据",
                      key: "myMessage",
                      duration: 0,
                    });
                    this.setState({ allCutFlag: value }, () => {
                      this.load();
                    });
                  }}
                  disabled={showAll}
                />
              </Tooltip>
            </div>
          )}
          {editKey !== 0 && (
            <div
              className={"map-classEditInfo"}
              id={"map-classEditInfo"}
              ref={this.ref}
            >
              <div className={"title"}>操作提示</div>
              <div className={"body"}>
                {editKey == 1 ? (
                  <span>当前所选小班暂未除治，是否修改小班信息？</span>
                ) : (
                  <span>请在地图上单击选择照片图标关联至当前小班</span>
                )}
              </div>
              <div className={"btn"}>
                <Button
                  type="primary"
                  onClick={this.classEditConfrim}
                  disabled={
                    (editKey == 2 &&
                      addClassInfo &&
                      addClassInfo.picCutList.length == 0 &&
                      addClassInfo.picDroneList.length == 0) ||
                    (editKey == 2 && !addClassInfo)
                  }
                >
                  {editKey == 1 ? "是" : "选择完毕"}
                </Button>
                <Button onClick={this.classEditCancel}>
                  {editKey == 1 ? "否，重新选择" : "重选照片"}
                </Button>
              </div>
            </div>
          )}
          <Tooltip placement="top" title={"疫木点位开关"}>
            <div
              className={"my-box map-toolbox"}
              style={{ marginRight: "50px" }}
            >
              <Switch
                onChange={(value) => {
                  const year = sessionStorage.getItem("year");
                  value
                    ? OlMap.addWoodLayer(
                        {
                          ...MapLayers.WoodLayer,
                          viewparams: `areaCode:${countyCode};areaLevel:${1};year:${year}`,
                        },
                        url
                      )
                    : OlMap.removeWoodLayer();
                }}
              />
            </div>
          </Tooltip>
          <Tooltip placement="top" title={"我的"}>
            <div
              className={"my-box map-toolbox"}
              style={{ backgroundColor: `${!showAll ? "#1890ff" : "#FFFFFF"}` }}
              onClick={this.myClick}
            >
              <UserOutlined
                rev={undefined}
                style={{
                  color: `${!showAll ? "#FFFFFF" : "#000000"}`,
                  fontSize: "22px",
                }}
              />
            </div>
          </Tooltip>
          <Tooltip placement="top" title={"小班搜索"}>
            {
              <div
                className={"search-box  map-toolbox"}
                style={{
                  backgroundColor: `${classSearch ? "#1890ff" : "#FFFFFF"}`,
                }}
                onClick={this.classSearch}
              >
                <SearchOutlined
                  rev={undefined}
                  style={{
                    color: `${classSearch ? "#FFFFFF" : "#000000"}`,
                    fontSize: "22px",
                  }}
                />
              </div>
            }
          </Tooltip>
          {!isReading && (
            <Tooltip placement="top" title={"小班编辑"}>
              {!droneEdit && (
                <div
                  className={"edit-box map-toolbox"}
                  style={{
                    backgroundColor: `${classEdit ? "#1890ff" : "#FFFFFF"}`,
                  }}
                  onClick={this.classEdit}
                >
                  <EditOutlined
                    rev={undefined}
                    style={{
                      color: `${classEdit ? "#FFFFFF" : "#000000"}`,
                      fontSize: "22px",
                    }}
                  />
                </div>
              )}
            </Tooltip>
          )}
          {localStorage.getItem("role").indexOf("0") > 0 && (
            <Tooltip placement="top" title={"删除小班图片"}>
              {
                <div
                  className={"delete-box  map-toolbox"}
                  style={{
                    backgroundColor: `${classDelPic ? "#ed2424" : "#FFFFFF"}`,
                  }}
                  onClick={this.classDelPic}
                >
                  <DeleteOutlined
                    rev={undefined}
                    style={{
                      color: `${classDelPic ? "#FFFFFF" : "#000000"}`,
                      fontSize: "22px",
                    }}
                  />
                </div>
              }
            </Tooltip>
          )}
          {!isReading &&
            (localStorage.getItem("role").indexOf("0") > 0 ||
              window.location.href.indexOf("check") > 0) && (
              <Tooltip placement="top" title={"更改点位图标"}>
                {
                  <div
                    className={"move-box  map-toolbox"}
                    style={{
                      backgroundColor: `${moveVisible ? "#1890ff" : "#FFFFFF"}`,
                    }}
                    onClick={this.MovePoint}
                  >
                    <DragOutlined
                      rev={undefined}
                      style={{
                        color: `${moveVisible ? "#FFFFFF" : "#000000"}`,
                        fontSize: "22px",
                      }}
                    />
                  </div>
                }
              </Tooltip>
            )}
          {classDelPic && (
            <div
              className={"map-classEditInfo"}
              id={"map-classEditInfo"}
              ref={this.ref}
            >
              <div className={"title"}>操作提示</div>
              <div className={"body"}>
                <span>{classDelName?.name ?? "请选择需要删除图片的小班"}</span>
              </div>
              <div className={"btn"}>
                <Button type="primary" danger onClick={this.deletePic_class}>
                  删除
                </Button>
              </div>
            </div>
          )}
          {searchKey == 1 && (
            <div className="search-menu">
              <Button type="primary" onClick={() => this.changeSearchKey(2)}>
                关键字
              </Button>
              <Button
                type="primary"
                style={{ marginLeft: "10px" }}
                onClick={() => this.changeSearchKey(3)}
              >
                经纬度
              </Button>
            </div>
          )}
          {searchKey == 2 && (
            <div className="search-key">
              <Input
                placeholder="请输入小班关键词搜索"
                style={{ width: "200px" }}
                prefix={<SearchOutlined rev={undefined} />}
                allowClear={true}
                onChange={(e) => {
                  this.setState({
                    searchKeyValue: e.target.value,
                  });
                }}
              />
            </div>
          )}
          {/* 小班搜索框 */}
          {searchKeyValue !== "" && (
            <ClassList
              keyValue={searchKeyValue}
              locationClass={(id) => {
                this.locationClass(id).then((res: any) => {
                  const { x, y } = res.point;
                  const LonLat = [x, y];
                  OlMap.ToLngLat(LonLat, 17);
                });
              }}
              countyCode={countyCode}
            />
          )}
          {searchKey == 3 && (
            <ClassSearchByLnglat
              locationClass={(id) => {
                this.locationClass(id).then((res: any) => {
                  const { x, y } = res.point;
                  const LonLat = [x, y];
                  OlMap.ToLngLat(LonLat, 17);
                });
              }}
              countyCode={countyCode}
            />
          )}
        </div>
        {/* 右侧数据 */}
        <div className="right">
          <>
            <div className={"title"}>
              {/* {repeatPicLook ? "重叠照片预览" : "智能上报"}
               */}
              {historyModelFlag
                ? "当前选中小班"
                : repeatPicLook
                ? "重叠照片预览"
                : "智能上报"}
            </div>
            {historyModelFlag ? (
              <div>
                <div
                  style={{ display: "flex", flexWrap: "wrap", width: "100%" }}
                >
                  <List
                    style={{ width: "100%" }}
                    header={<div>{thisClassName}</div>}
                    bordered
                    dataSource={historyList}
                    renderItem={(item: any) => (
                      <List.Item>
                        <div className={"list-item"}>
                          <div>验收次数:{item.frequency || ""}</div>
                          <div>验收结果:{item.result || ""}</div>
                          <div>验收时间:{item.acceptTime || ""}</div>
                          <div>验收人员:{item.userName || ""}</div>
                        </div>
                      </List.Item>
                    )}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    type="primary"
                    onClick={() => this.setState({ repeatPicLook: false })}
                  >
                    返回
                  </Button>
                </div>
              </div>
            ) : !repeatPicLook ? (
              <>
                <div className={"list"} id={"ClassList"}>
                  <Search
                    placeholder="请输入关键词搜索"
                    allowClear
                    onSearch={this.onSearch}
                    enterButton
                    disabled={classEdit}
                  />
                  <InfiniteScroll
                    dataLength={dataList.length}
                    next={() => {
                      this.getData();
                    }}
                    hasMore={dataList.length < total}
                    loader={null}
                    endMessage={null}
                    scrollableTarget="ClassList"
                  >
                    <List
                      dataSource={dataList}
                      renderItem={(r: any, i) => {
                        return (
                          <List.Item key={r.id}>
                            <Form
                              onFinish={(value) => this.update(value, r, i)}
                              initialValues={{
                                ...r,
                                surveyPileNum: r.surveyPileNum ?? 0,
                                failedPileNum: r.failedPileNum ?? 0,
                                twigRetentionNum: r.twigRetentionNum ?? 0,
                              }}
                            >
                              <div key={r.id} className={"list-item"}>
                                <div
                                  onClick={() => {
                                    !classEdit && this.expand(r, i);
                                  }}
                                  className={"expandClick"}
                                >
                                  <span>
                                    小班名称：
                                    {r.className
                                      ? r.className
                                      : `${r.villageName}-${r.forestName}-${r.smallClass}`}
                                  </span>
                                  <span>乡镇街道：{r.townName}</span>
                                  <span>验收次数：{r.frequency}</span>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      height: "32px",
                                    }}
                                  >
                                    验收结果：
                                    <FormItem name="result">
                                      <Select
                                        style={{ width: 180, marginTop: "5px" }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                        onChange={(e) => {
                                          this.setState({
                                            accKey: e,
                                          });
                                        }}
                                        disabled={classEdit}
                                      >
                                        <Option value={-1} key="待审核">
                                          待审核(系统算分)
                                        </Option>
                                        <Option value={1} key="验收通过">
                                          验收通过
                                        </Option>
                                        <Option value={0} key="验收不通过">
                                          验收不通过
                                        </Option>
                                        <Option value={3} key="不纳入考核">
                                          不纳入考核
                                        </Option>
                                      </Select>
                                    </FormItem>
                                  </div>
                                </div>
                                {r.showExpand &&
                                  !classEdit &&
                                  window.location.href.indexOf("check") >=
                                    0 && (
                                    <div>
                                      <FormItem
                                        style={{
                                          height: "32px",
                                          marginTop: "5px",
                                          marginBottom: "5px",
                                        }}
                                        name="surveyPileNum"
                                        label="调查伐桩数"
                                        rules={[
                                          {
                                            required: true,
                                            message: "请填写数量",
                                          },
                                        ]}
                                      >
                                        <Input
                                          placeholder="请输入调查伐桩数"
                                          type="number"
                                          addonAfter="个"
                                          min={0}
                                        ></Input>
                                      </FormItem>
                                      <FormItem
                                        style={{
                                          height: "32px",
                                          marginTop: "5px",
                                          marginBottom: "5px",
                                        }}
                                        name="failedPileNum"
                                        label="不合格伐桩数"
                                        rules={[
                                          {
                                            required: true,
                                            message: "请填写数量",
                                          },
                                        ]}
                                      >
                                        <Input
                                          placeholder="请输入不合格伐桩数"
                                          type="number"
                                          addonAfter="个"
                                          min={0}
                                        ></Input>
                                      </FormItem>
                                      <FormItem
                                        style={{
                                          height: "32px",
                                          marginTop: "5px",
                                          marginBottom: "5px",
                                        }}
                                        name="twigRetentionNum"
                                        label="枝桠滞留数"
                                        rules={[
                                          {
                                            required: true,
                                            message: "请填写数量",
                                          },
                                        ]}
                                      >
                                        <Input
                                          placeholder="请输入枝桠滞留数"
                                          type="number"
                                          addonAfter="个"
                                          min={0}
                                        ></Input>
                                      </FormItem>
                                    </div>
                                  )}
                                {r.showExpand && (
                                  <div className={"expand-box"}>
                                    <div>伐桩照片</div>
                                    <FormItem name="picCutList">
                                      <Upload
                                        fileList={r.picCutList}
                                        onChange={(file) =>
                                          this.onFileChanged1(file, r, i)
                                        }
                                        onPreview={(file) => {
                                          this.previewImage(file, r.picCutList);
                                        }}
                                        disabled={r.picCutList?.length === 0}
                                        {...props}
                                      >
                                        {r.picCutList?.length === 0 && (
                                          <div>
                                            <PlusOutlined rev={undefined} />
                                            <div style={{ marginTop: 8 }}>
                                              待关联
                                            </div>
                                          </div>
                                        )}
                                      </Upload>
                                    </FormItem>
                                    <div>航拍照片</div>
                                    <FormItem name="picDroneList">
                                      <Upload
                                        fileList={r.picDroneList}
                                        onChange={(file) =>
                                          this.onFileChanged2(file, r, i)
                                        }
                                        onPreview={(file) => {
                                          this.previewImage(
                                            file,
                                            r.picDroneList
                                          );
                                        }}
                                        disabled={r.picDroneList?.length === 0}
                                        {...props}
                                      >
                                        {r.picDroneList?.length === 0 && (
                                          <div
                                            onClick={(e) => e.preventDefault()}
                                          >
                                            <PlusOutlined rev={undefined} />
                                            <div style={{ marginTop: 8 }}>
                                              待关联
                                            </div>
                                          </div>
                                        )}
                                      </Upload>
                                    </FormItem>
                                    <Button
                                      type="primary"
                                      onClick={() => this.editDrone(r)}
                                    >
                                      {droneEdit ? "选择完毕" : "关联照片"}
                                    </Button>
                                    <div>上传时间：{r.time}</div>
                                    <div>验收人员：{r.username}</div>
                                    <div
                                      style={{
                                        display: `${
                                          classEdit || countyCode !== "331102"
                                            ? "none"
                                            : ""
                                        }`,
                                      }}
                                    >
                                      备注信息
                                      {countyCode === "331102" && (
                                        <span style={{ color: "red" }}>
                                          (
                                          <MinusCircleOutlined
                                            rev={undefined}
                                          />
                                          可点击移除内容)
                                        </span>
                                      )}
                                    </div>
                                    {situationOption.length > 0 && (
                                      <Form.List
                                        name="situationList"
                                        initialValue={
                                          classEdit
                                            ? []
                                            : [{ first: null, last: null }]
                                        }
                                      >
                                        {(fields, { add, remove }) => (
                                          <>
                                            {fields.map(
                                              ({ key, name, ...restField }) => (
                                                <Space
                                                  key={key}
                                                  style={{
                                                    display: "flex",
                                                    marginBottom: "8px",
                                                    height: "40px",
                                                  }}
                                                  align="baseline"
                                                >
                                                  <Form.Item
                                                    {...restField}
                                                    name={[name, "last"]}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: "请填写内容",
                                                      },
                                                    ]}
                                                  >
                                                    <Input
                                                      min="1"
                                                      type={
                                                        this.state.isOther
                                                          ? "string"
                                                          : "number"
                                                      }
                                                      style={{
                                                        position: "relative",
                                                        top: `${
                                                          this.state.isOther
                                                            ? "0"
                                                            : "5px"
                                                        }`,
                                                      }}
                                                      addonAfter={
                                                        this.state.isOther
                                                          ? ""
                                                          : "处"
                                                      }
                                                      placeholder="请填写内容"
                                                    />
                                                  </Form.Item>
                                                  <Form.Item
                                                    {...restField}
                                                    name={[name, "first"]}
                                                    rules={[
                                                      {
                                                        required: true,
                                                        message: "请选择描述",
                                                      },
                                                    ]}
                                                  >
                                                    {/* {this.state.accKey} */}
                                                    <Select
                                                      onChange={(e) => {
                                                        e === "其他"
                                                          ? this.setState({
                                                              isOther: true,
                                                            })
                                                          : this.setState({
                                                              isOther: false,
                                                            });
                                                      }}
                                                      placeholder="请选择描述"
                                                    >
                                                      {this.state.accKey === -1
                                                        ? situationOption.map(
                                                            (option) => (
                                                              <Option
                                                                key={
                                                                  option.value
                                                                }
                                                                value={
                                                                  option.value
                                                                }
                                                                title={
                                                                  option.value
                                                                }
                                                              >
                                                                {option.label}
                                                              </Option>
                                                            )
                                                          )
                                                        : [
                                                            ...situationOption,
                                                            {
                                                              value: "其他",
                                                              label: "其他",
                                                            },
                                                          ].map((option) => (
                                                            <Option
                                                              key={option.value}
                                                              value={
                                                                option.value
                                                              }
                                                              title={
                                                                option.value
                                                              }
                                                            >
                                                              {option.label}
                                                            </Option>
                                                          ))}
                                                    </Select>
                                                  </Form.Item>

                                                  <MinusCircleOutlined
                                                    rev={undefined}
                                                    onClick={() => remove(name)}
                                                  />
                                                </Space>
                                              )
                                            )}
                                            <Form.Item
                                              style={{
                                                marginTop: "30px",
                                                display: `${
                                                  classEdit ? "none" : ""
                                                }`,
                                              }}
                                            >
                                              <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                block
                                                icon={
                                                  <PlusOutlined
                                                    rev={undefined}
                                                  />
                                                }
                                              >
                                                添加描述
                                              </Button>
                                            </Form.Item>
                                          </>
                                        )}
                                      </Form.List>
                                    )}
                                    {
                                      <FormItem name="situation">
                                        <TextArea rows={2} />
                                      </FormItem>
                                    }

                                    <div style={{ textAlign: "center" }}>
                                      <FormItem>
                                        <Button
                                          type="primary"
                                          htmlType="submit"
                                        >
                                          确认
                                        </Button>
                                      </FormItem>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </Form>
                          </List.Item>
                        );
                      }}
                    />
                  </InfiniteScroll>
                </div>
                <div className={"footer"}>
                  {/* 今日水印相机 */}
                  {/* <Button type="primary" onClick={() => { this.changeUploadImg(uploadImgVisible); this.setState({ isWater: true }) }} disabled={(classEdit || isReading)}>今日水印相机</Button> */}
                  <Button
                    type="primary"
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      this.changeOldUploadImg(oldUploadImgVisible);
                      this.setState({ isWater: false });
                    }}
                    disabled={classEdit || isReading}
                  >
                    上传图片
                  </Button>
                  <Button
                    type="primary"
                    style={{ marginLeft: "10px" }}
                    onClick={() => {
                      this.changeUploadImg(uploadImgVisible);
                      this.setState({ isWater: false });
                    }}
                    disabled={classEdit || isReading}
                  >
                    新上传图片
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      this.setState({ updateAllVisible: true });
                    }}
                    style={{ marginLeft: "10px" }}
                    disabled={classEdit || isReading}
                  >
                    批量修改
                  </Button>
                </div>
              </>
            ) : (
              <div>
                <div
                  style={{ display: "flex", flexWrap: "wrap", width: "100%" }}
                >
                  <Image.PreviewGroup>
                    {downloadImg.map((item, index) => {
                      // console.log(downloadImg)
                      return (
                        <div
                          style={{
                            width: "100px",
                            height: "120px",
                            marginLeft: "10px",
                            marginTop: "10px",
                          }}
                        >
                          <Image
                            style={{ width: "100px", height: "100px" }}
                            src={item.url}
                          />
                          {droneEdit && (
                            <>
                              <span
                                onClick={() => {
                                  // console.log(downloadImg)
                                  this.associated(downloadImg[index]);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                选择图片
                              </span>
                            </>
                          )}
                        </div>
                      );
                    })}
                  </Image.PreviewGroup>
                </div>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    type="primary"
                    onClick={() => this.setState({ repeatPicLook: false })}
                  >
                    返回
                  </Button>
                </div>
              </div>
            )}
          </>
        </div>
        {/* 批量修改弹窗 */}
        <Modal
          title="批量修改"
          visible={updateAllVisible}
          footer={null}
          onCancel={() => {
            this.setState({ updateAllVisible: false, allResult: "" });
          }}
          className={"updateAll_modal"}
        >
          <div className={"updateAll-box"}>
            <span>将当前待审核小班列表批量审批为</span>
            <Form onFinish={this.updateAll}>
              <div>
                <span>统一备注：</span>
                {situationOption.length > 0 ? (
                  <Form.List name="situationList">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space
                            key={key}
                            style={{
                              display: "flex",
                              marginBottom: "8px",
                              height: "40px",
                            }}
                            align="baseline"
                          >
                            <Form.Item
                              {...restField}
                              name={[name, "first"]}
                              rules={[
                                { required: true, message: "请选择描述" },
                              ]}
                            >
                              <Select placeholder="请选择描述">
                                {[
                                  ...situationOption,
                                  { value: "其他", label: "其他" },
                                ].map((option) => (
                                  <Option
                                    key={option.value}
                                    value={option.value}
                                    title={option.value}
                                  >
                                    {option.label}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "last"]}
                              rules={[
                                { required: true, message: "请填写内容" },
                              ]}
                            >
                              <Input min="1" placeholder="请填写内容" />
                            </Form.Item>
                            <MinusCircleOutlined
                              rev={undefined}
                              onClick={() => remove(name)}
                            />
                          </Space>
                        ))}
                        <Form.Item style={{ marginTop: "30px" }}>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined rev={undefined} />}
                          >
                            添加描述
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                ) : (
                  <FormItem name="situation">
                    <TextArea rows={2} />
                  </FormItem>
                )}
              </div>
              <FormItem name="result">
                <Button
                  style={{ marginRight: "10px" }}
                  type={allResult == 1 ? "primary" : "default"}
                  onClick={() => this.changeAllResult(1)}
                >
                  验收通过
                </Button>
                <Button
                  type={allResult == 0 ? "primary" : "default"}
                  onClick={() => this.changeAllResult(0)}
                  danger={allResult == 0 ? true : false}
                >
                  验收不通过
                </Button>
              </FormItem>
              <FormItem style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  disabled={allResult == -2 ? true : false}
                >
                  提交
                </Button>
              </FormItem>
            </Form>
            {/* <div>
						<span>统一备注：</span>
						<Input
							style={{ width: "166px" }}
							placeholder="请输入备注信息"
							onChange={(value) => this.InputValue(value)}
						/>
					</div>
					<div>
						<Button type={allResult == 1 ? "primary" : 'default'} onClick={() => this.changeAllResult(1)}>验收通过</Button>
						<Button type={allResult == 0 ? "primary" : 'default'} onClick={() => this.changeAllResult(0)} danger={allResult == 0 ? true : false}>验收不通过</Button>
					</div>
					<Button type="primary" onClick={this.updateAll} style={{ marginTop: '20px' }} disabled={allResult == -2 ? true : false}>提交</Button> */}
          </div>
        </Modal>
        {/** 判断是否要覆盖之前验收记录 */}
        <Modal
          title="编辑小班(24小时内存在验收数据)"
          visible={newOrOverFlag}
          onOk={() => this.handleOk(true)}
          onCancel={() => this.handleOk(false)}
        >
          <div style={{ height: "40px", width: "100%" }}>
            验 收 者：&nbsp;&nbsp;&nbsp;
            {(hourData24 && hourData24.createBy) || ""}
          </div>
          <div style={{ height: "40px", width: "100%" }}>
            验收结果：&nbsp;&nbsp;&nbsp;
            {(hourData24 && hourData24.result) || ""}
          </div>
          <div style={{ height: "40px", width: "100%" }}>
            验收时间：&nbsp;&nbsp;&nbsp;{(hourData24 && hourData24.time) || ""}
          </div>
          <div style={{ height: "40px", width: "100%" }}>
            描述信息：&nbsp;&nbsp;&nbsp;
            {(hourData24 && hourData24.situation) || ""}
          </div>
          <span>后续操作：</span>
          <Select
            defaultValue="新增验收数据(轮次加一)"
            style={{ width: 200 }}
            onChange={(value) =>
              this.setState({
                afterControl: value,
              })
            }
            options={[
              { value: 1, label: "新增验收数据(轮次加一)" },
              { value: 2, label: "覆盖上述验收数据" },
              { value: 4, label: "更新上述验收数据" },
            ]}
          />
        </Modal>
        {/* 上传图片弹窗 */}
        <Modal
          visible={uploadImgVisible}
          footer={null}
          onCancel={this.closeUploadImgModal}
          destroyOnClose
          maskClosable={false}
        >
          <UploadImgNew
            isInspection={isInspection}
            closeModal={this.closeUploadImgModal}
            flayTo={(lnglat, zoom) => {
              OlMap.ToLngLat(lnglat, zoom);
              this.load();
            }}
            setFlyPoint={(data) => {
              this.setState({
                flyPoint: data,
              });
            }}
          />
        </Modal>
        {oldUploadImgVisible && (
          <UploadImg
            visible={oldUploadImgVisible}
            changeUploadImg={this.changeOldUploadImg}
            point={this.point}
            isWater={isWater}
            countyCode={countyCode}
          />
        )}
        {/**照片预览 */}
        {imageVisible && (
          <Image.PreviewGroup
            preview={{
              visible: imageVisible,
              onVisibleChange: (value) => {
                this.setState({
                  imageVisible: value,
                });
              },
            }}
          >
            {previewImage.map((item) => {
              return <Image style={{ display: "none" }} src={item.url} />;
            })}
          </Image.PreviewGroup>
        )}
      </div>
    );
  }
}
