import React, { Component } from 'react';
import { Tabs, Form, Input, Button, DatePicker, Row, Col, message } from 'antd';
import './index.scss';
import ToolTs from '../services/tool';
import type { FormInstance } from 'antd/es/form';

const FormItem = Form.Item;
const style: any = {
	color: '#80808099',
	height: '0px',
	whiteSpace: 'nowrap',
};
const dateFormat = 'YYYY-MM-DD HH:mm:ss';

class Orthographic extends Component<any, any> {
	formRef = React.createRef<FormInstance>();

	//上传正射
	onFinish = (value) => {
		const { propsareaCode } = this.props.locationState;
		console.log(value)
		const acquisitionDate = value.acquisitionDate?.format(dateFormat);
		const params = {
			name: value.name,
			area: value.area,
			baseJson: value.baseJson,
			lat: value.lat,
			lon: value.lon,
			capacity: value.capacity,
			acquisitionDate,
			areaCode: propsareaCode,
		};
		ToolTs.orthoEdit(params, (res) => {
			if (res.code === 200) {
				message.success('新增正射成功');
				this.formRef.current!.resetFields();
			} else {
				message.error(res.msg);
			}
		});
	};

	//取消
	changeVisible = () => {
		this.formRef.current!.resetFields();
	};

	render() {
		return (
			<Tabs className="tabsPage">
				<Tabs.TabPane tab="正射上传" key="ogUpload">
					<Form onFinish={this.onFinish} ref={this.formRef}>
						<FormItem
							name="name"
							label="名称"
							rules={[
								{
									required: true,
									message: '请填写正射名称',
								},
							]}
							labelCol={{ span: 8 }}
							wrapperCol={{ span: 8 }}
						>
							<Input />
						</FormItem>
						<FormItem
							label="数据源"
							name="baseJson"
							rules={[
								{
									required: true,
									message: '请填写正射地址',
								},
							]}
							labelCol={{ span: 8 }}
							wrapperCol={{ span: 8 }}
						>
							<FormItem name="baseJson" noStyle>
								<Input key={'数据源'} placeholder="请输入数据存储的完整路径" />
							</FormItem>
							<p style={style}>如：http://www.3dsite.com/data/xiangmu</p>
						</FormItem>
						<FormItem
							label="数据容量"
							labelCol={{ span: 8 }}
							wrapperCol={{ span: 2 }}
						>
							<FormItem name="capacity" noStyle>
								<Input addonAfter={'GB'} />
							</FormItem>
							<p style={style}>正射的存储大小</p>
						</FormItem>
						<FormItem
							label="面积"
							labelCol={{ span: 8 }}
							wrapperCol={{ span: 2 }}
						>
							<FormItem name="area" noStyle>
								<Input addonAfter={'km²'} />
							</FormItem>
							<p style={style}>正射的占地面积</p>
						</FormItem>
						<FormItem
							label="经纬度"
							labelCol={{ span: 8 }}
							wrapperCol={{ span: 7 }}
						>
							<Input.Group>
								<Row gutter={5}>
									<Col span={7}>
										<FormItem name="lon" noStyle>
											<Input placeholder="经度" />
										</FormItem>
									</Col>
									<Col span={7}>
										<FormItem name="lat" noStyle>
											<Input placeholder="纬度" />
										</FormItem>
									</Col>
								</Row>
							</Input.Group>
							<p style={style}>正射中心点的经纬度</p>
						</FormItem>
						<FormItem
							name="acquisitionDate"
							label="采集时间"
							labelCol={{ span: 8 }}
							wrapperCol={{ span: 8 }}
						>
							<DatePicker />
						</FormItem>
						<div className="form-button">
							<FormItem>
								<Button type="primary" htmlType="submit">
									提交
								</Button>
								<Button
									onClick={() => this.changeVisible()}
									style={{ marginLeft: '10px' }}
								>
									取消
								</Button>
							</FormItem>
						</div>
					</Form>
				</Tabs.TabPane>
			</Tabs>
		);
	}
}

export default Orthographic;
