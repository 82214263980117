import { acceptance_axios as axios } from "../../utils/axios";

export default class HistoryService {
  /**
   * @description 获取验收历史数据
   */

  static getHistoryData = (params: {
    countyCode: string;
    className: string;
    year: string;
    isInspection: number;
  }) => axios.get("/acceptance/query_acceptance_history", { params });
}
