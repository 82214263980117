import React, { Component } from 'react';
// import 'animate.css';
import Lottie from 'react-lottie';
import middle from '../../assets/icons/middle.json';
import {
	InStatistics,
	DivisStatistics,
	Occurrdegree,
	Nostatistics,
	CheckWork,
	ResourInvest,
	UnAcceptance,
	CurrentAccept,
} from './components';
import './right.scss';

interface State { }
export class RightClass extends Component<any, State> {
	constructor(props: any) {
		super(props);
		this.state = {};
	}
	render() {
		const { isOperat } = this.props;
		return (
			<div
				className={`h-right animate__animated ${isOperat ? 'animate__fadeOutRight' : 'animate__fadeInRight'
					}`}
			>
				<div className="right-list">
					<InStatistics props={{ ...this.props }} />
					<DivisStatistics props={{ ...this.props }} />
					<Occurrdegree props={{ ...this.props }} />
				</div>

				<Lottie
					options={{
						loop: true,
						autoplay: true,
						animationData: middle,
					}}
					width={100}
					height={'100%'}
				/>
			</div>
		);
	}
}

//永康疫情统计
export class RightNoClass extends Component<any, State> {
	acceptState = localStorage.getItem('acceptState');
	constructor(props: any) {
		super(props);
		this.state = {};
	}
	componentDidMount(): void {
		console.log(this.acceptState);
	}
	render() {
		const { isOperat } = this.props;
		return (
			<div
				className="h-right"
				style={isOperat ? { right: '-40vw ' } : { right: '0' }}
			>
				<div className="right-list">
					<Nostatistics props={{ ...this.props }} />
					<CheckWork props={{ ...this.props }} />
					<ResourInvest props={{ ...this.props }} />
				</div>
				<Lottie
					options={{
						loop: true,
						autoplay: true,
						animationData: middle,
					}}
					width={100}
					height={'76.4%'}
				/>
			</div>
		);
	}
}

// 验收统计
export class AcceptClass extends Component<any, State> {
	constructor(props: any) {
		super(props);
		this.state = {};
	}
	render() {
		const { isOperat } = this.props;

		const MoblieType = sessionStorage.getItem('type');
		return (
			<div
				className={`h-right animate__animated ${isOperat ? 'animate__fadeOutRight' : 'animate__fadeInRight'
					}`}
			>
				<div className="right-list">
					<CurrentAccept props={{ ...this.props }} />
					<UnAcceptance props={{ ...this.props }} />
				</div>

				<Lottie
					options={{
						loop: true,
						autoplay: true,
						animationData: middle,
					}}
					width={100}
					height={'100%'}
				/>
			</div>
		);
	}
}
