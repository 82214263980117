import React from 'react';
interface SplitLineType {
  height: number;
  width: number;
  children: any;
}
export default function SplitLine(props: SplitLineType) {
  const { height, width, children } = props;
  const percentLineData = [0, 25, 50, 75, 100];
  return (
    <div className="SplitLine">
      <div
        className="SplitLine_back"
        style={{ width: `${width}px`, height: `${height}px` }}
      >
        {percentLineData.map((item, index) => (
          <div key={index}>
            <div className="percent-line"></div> {item}
          </div>
        ))}
      </div>
      <div className="SplitLine_content">{children}</div>
    </div>
  );
}
