import { acceptance_axios } from "./axios";
import { message } from "antd";

const OSS = window["OSS"];

// 获取oss临时凭证
export const getOss = () => {
  acceptance_axios.get("/common/get_security_token").then(res => {
    if (res.data.code === 200) {
      const { accessKeyId, accessKeySecret, securityToken, expiration } = res.data.data;
      const client = new OSS({
        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
        region: "oss-cn-hangzhou",
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId,
        accessKeySecret,
        // 从STS服务获取的安全令牌（SecurityToken）。
        stsToken: securityToken,
        // 填写Bucket名称。
        bucket: window["env"].OSS_BUCKET,
        // 默认超时时间7.5分钟，大约支持300张图片
        timeout: window["env"].OSS_TIMEOUT,
      });
      // console.log(client);
      window["oss-upload"] = client;
      // 获取到临时凭证后8小时过期
      localStorage.setItem(
        "ossOverdueTime",
        JSON.stringify(new Date(expiration).getTime() + 8 * 60 * 60 * 1000)
      );
    } else {
      message.error("获取OSS临时凭证失败");
    }
  });
};

// 检查oss是否过期
export const checkOss = () => {
  if (new Date().getTime() > parseInt(localStorage.getItem("ossOverdueTime"))) {
    getOss();
  }
};
