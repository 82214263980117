import { useEffect } from "react";
import OlMap from "../../utils/olmap";

interface Props {
  dataSource: Array<{
    distance: number;
    duration: number;
    roadStepList: string;
  }>;
}

const TrajectoryMap = ({ dataSource }: Props) => {
  const initMap = () => {
    OlMap.init("trajectory-map");
    dataSource.forEach((item, index) => {
      const newList = item.roadStepList.split(";").map((it) => it.split(","));
      OlMap.addCarTrajectory(newList);
      if (index === dataSource.length / 2 - 1) OlMap.ToLngLat(newList[0], 12);
    });
  };

  useEffect(() => {
    initMap();
  }, []);

  return <div id="trajectory-map"></div>;
};

export default TrajectoryMap;
