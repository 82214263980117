import React, { Component } from 'react';
import NoticeRequest from '../services/notice';
import ReactMarkdown from 'react-markdown';
import './index.scss';
export interface Props {}

export interface State {
  versionData: object;
}

class VersionLog extends Component<Props, any> {
  constructor(props) {
    super(props);
    this.state = {
      versionData: [],
    };
  }
  componentDidMount() {
    this.Viewversion();
  }
  Viewversion = () => {
    NoticeRequest.getAllDataList().then((res) => {
      if (res.data.code === 200) {
        this.setState({
          versionData: res.data.data,
        });
      }
    });
  };

  render() {
    const { versionData } = this.state;
    console.log(versionData);

    return (
      <div className="versionlog-scroll">
        <div className="versionlog">
          <h1>更新日志</h1>
          {versionData.map((item) => (
            <div className="versionlog-content">
              <h2>{item.noticeTitle}</h2>
              <h1>更新时间：{item.createDate}</h1>
              <div className="versionlog-list">
                <ReactMarkdown>{item.noticeContent}</ReactMarkdown>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default VersionLog;
