import ReactDOM from 'react-dom';
import Lottie from 'react-lottie';
import React, { Component } from 'react';
import './index.scss';
import { calculateScreenDistance, calculateCityRadius } from './handle';
import map from './map';
import { Overlay } from './mapSource';
import bigCircle from './data/呼吸渐隐目标大圆0(1).json';
import smallCircle from './data/呼吸渐隐目标小圆0(1).json';
import middleCircle from './data/目标中圆.json';
import Header from './components/header';
import Left from './components/left';
import Right from './components/right';
import Request from '../services/Manger';
import marker_bg from '../assets/images/point.svg';
import project from '../assets/images/project.png';
import area from '../assets/images/area.png';
import wood from '../assets/images/wood.png';
import plane from '../assets/images/plane.png';
import picture from '../assets/images/picture.png';
import passrate from '../assets/images/passrate.png';
import { CaretRightOutlined } from '@ant-design/icons';
import ScaleContainer from '../utils/ScaleContainer';

const bigCircleOptions = {
	loop: true,
	autoplay: true,
	animationData: bigCircle,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice',
	},
};

const smallCircleOptions = {
	loop: true,
	autoplay: true,
	animationData: smallCircle,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice',
	},
};

const middleCircleOptions = {
	loop: true,
	autoplay: true,
	animationData: middleCircle,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice',
	},
};

// const bigMoveCircleOptions = {
// 	loop: true,
// 	autoplay: true,
// 	animationData: bigMoveCircle,
// 	rendererSettings: {
// 		preserveAspectRatio: 'xMidYMid slice'
// 	}
// }

// const smallMoveCircleOptions = {
// 	loop: true,
// 	autoplay: true,
// 	animationData: smallMoveCircle,
// 	rendererSettings: {
// 		preserveAspectRatio: 'xMidYMid slice'
// 	}
// }

interface Props { }

interface State {
	provinceBorder: Array<any>;
	cityBorder: Array<any>;
	cityCenter: Array<any>;
	projectList: Array<any>;
	username: any;
	year: any;
	pageLeftData: any;
	cityCode: any;
	pageRightData: any;
}

const openCity = ["331100", "330800", "330300", "330700"]

// 省边界图层 province-border
// 省动效光圈 province-circle
class Navigation extends Component<any, State> {
	map: any;
	isCalculate: boolean = false;
	interval: any = undefined;
	province_radius: number = 0;
	origin_radius: number = 0;
	constructor(props: Props) {
		super(props);
		this.state = {
			provinceBorder: [],
			cityBorder: [],
			cityCenter: [],
			username: '',
			projectList: [
				{
					name: '莲都区',
					position: { x: 119.837994011224, y: 28.4435234024548 },
					areaCode: '331102',
					fatherCode: '331100',
				},
				{
					name: '青田县',
					position: { x: 120.1353744374, y: 28.2060269550344 },
					areaCode: '331121',
					fatherCode: '331100',
				},
				{
					name: '缙云县',
					position: { x: 120.180535125734, y: 28.6620094446283 },
					areaCode: '331122',
					fatherCode: '331100',
				},
				{
					name: '遂昌县',
					position: { x: 119.077604507338, y: 28.521935814412 },
					areaCode: '331123',
					fatherCode: '331100',
				},
				{
					name: '松阳县',
					position: { x: 119.420101799734, y: 28.3884380064619 },
					areaCode: '331124',
					fatherCode: '331100',
				},
				{
					name: '龙泉市',
					position: { x: 119.070641546808, y: 28.0483625009841 },
					areaCode: '331181',
					fatherCode: '331100',
				},
				{
					name: '庆元县',
					position: { x: 119.145646408514, y: 27.6267106149919 },
					areaCode: '331126',
					fatherCode: '331100',
				},
				{
					name: '景宁县',
					position: { x: 119.608409334384, y: 27.8941810861464 },
					areaCode: '331127',
					fatherCode: '331100',
				},
				{
					name: '永康市',
					position: { x: 120.109405, y: 28.944168 },
					areaCode: '330784',
					fatherCode: '330700',
				},
				{
					name: '常山县',
					position: { x: 118.50507094318665, y: 28.903026199266836 },
					areaCode: '330822',
					fatherCode: '330800',
				},
				// { name: '泰顺县', position: { x: 119.717643, y: 27.556578 }, areaCode: '330329', fatherCode: '330300' },
				{
					name: '瓯海区',
					position: { x: 120.63751, y: 28.00714 },
					areaCode: '330304',
					fatherCode: '330300',
				},
			],
			year: sessionStorage.getItem('year'),
			cityCode: '331100',
			pageLeftData: null, //首页左侧数据
			pageRightData: null, //首页右侧数据
		};
	}

	componentDidMount() {
		this.getYear()
		this.getPageData();
		this.props.location.state &&
			this.setState({
				username: this.props.location.state.username,
			});
		// 地图初始化
		map.init();
		fetch('/border/浙江省边界.geojson', { mode: 'cors' })
			.then((r) => r.json())
			.then((r) => {
				this.setState(
					{
						provinceBorder: r.features[0].geometry.coordinates,
					},
					() => {
						this.addProvinceBorder();
						this.addProvinceCircle();
					}
				);
			});
		fetch('/border/浙江省市界.geojson', { mode: 'cors' })
			.then((r) => r.json())
			.then((r) => {
				// r.features.map(item => {
				//     const link = projectList.find(e => {
				//         return e.fatherCode == item.properties.Code
				//     })
				//     return {
				//         ...item,
				//         hasProject: link ? true : false
				//     }
				// })
				this.setState(
					{
						cityBorder: r.features,
					},
					() => {
						fetch('/border/浙江省市中心点.geojson', { mode: 'cors' })
							.then((r) => r.json())
							.then((r) => {
								this.setState(
									{
										cityCenter: r.features,
									},
									() => {
										this.addCityName();
										this.addCityBorder();
									}
								);
							});
					}
				);
			});
		// 获取项目数据
		this.getProjectData();
		setTimeout(() => {
			map.map.on('singleclick', this.mapClick);
		});
	}

	getYear = () => {
		let list = [];
		Request.getAllYear((res) => {
			if (res.code === 200) {
				res.data.forEach((e, index) => {
					list.push({ key: e, value: e });
					if (index === list.length - 1) {
						!sessionStorage.getItem('year') &&
							sessionStorage.setItem('year', list[0].value);
						sessionStorage.setItem('yearList', JSON.stringify(list));
					}
				});
			}
		});
	}

	componentWillMount = () => {
		const mapid = document.getElementById('CMap');
		mapid && mapid.remove();
	};

	//切换年份的回调
	changeYear = (year) => {
		this.setState(
			{
				year,
			},
			() => {
				this.getPageData();
			}
		);
	};

	//选择城市
	changeCityCode = (cityCode) => {
		this.setState(
			{
				cityCode,
			},
			() => {
				this.getPageData();
			}
		);
	};

	//首页数据
	getPageData = () => {

		Request.getAllPageData((res) => {
			//获取展示的所有数据
			if (res.code === 200) {
				const {
					countyCensusAreaRDTOS,
					censusArea,
					treeNum,
					infectArea,
					projectNum,
					acceptanceArea,
					picNum,
					passRate
				} = res.data;

				const data = [
					{
						key: 'project',
						icon: project,
						name: '项目',
						title: '数量',
						value: projectNum,
						unit: '个',
					},
					{
						key: 'survey',
						icon: area,
						name: '普查',
						title: '总数',
						value: censusArea,
						unit: '亩',
					},
					{
						key: 'wood',
						icon: wood,
						name: '疫木',
						title: '株树',
						value: treeNum,
						unit: '株',
					},
					{
						key: 'area',
						icon: area,
						name: '感染',
						title: '面积',
						value: infectArea,
						unit: '亩',
					},
					{
						key: 'acceptance',
						icon: area,
						name: '验收',
						title: '面积',
						value: acceptanceArea,
						unit: '亩',
					},
					{
						key: 'pic',
						icon: picture,
						name: '验收',
						title: '照片数量',
						value: picNum,
						unit: '张',
					},
					{
						key: 'pass',
						icon: passrate,
						name: '验收',
						title: '通过率',
						value: passRate,
						unit: '%',
					},
				];

				//左侧数据
				data[0].value = projectNum;
				const list = data.slice(1, 5).map((item) => {
					return { ...item, value: this.NumFormat(item.value, 3, item.unit).number, unit: this.NumFormat(item.value, 3, item.unit).unit }
				})
				data.splice(1, 4, ...list)

				this.setState({
					pageLeftData: data,
				});

				//右侧数据
				let newdata = [];
				if (countyCensusAreaRDTOS.length !== 0) {
					countyCensusAreaRDTOS.forEach((item, index) => {
						newdata.push({
							key: item.name,
							name: item.name,
							title: '普查',
							value: this.NumFormat(item.censusArea, 2, '亩').number,
							unit: this.NumFormat(item.censusArea, 2, '亩').unit,
							title_1: '验收',
							value_1: this.NumFormat(item.acceptanceArea, 2, '亩').number,
							unit_1: this.NumFormat(item.acceptanceArea, 2, '亩').unit,
						});
						if (index === countyCensusAreaRDTOS.length - 1) {
							this.setState({
								pageRightData: newdata,
							});
						}
					});
				} else {
					this.setState({
						pageRightData: newdata,
					});
				}
			}
		});
	};


	NumFormat = (number, digit, unit) => {
		return number > 10000 ? { number: (number / 10000).toFixed(digit), unit: "万" + unit } : { number, unit: unit }
	}


	// 地图点击事件
	mapClick = (e: any) => {
		// 判断点击的地方是否是feature元素
		const feature = map.map.forEachFeatureAtPixel(e.pixel, (feature: any) => {
			return feature;
		});
		// 点击空白处时feature为undefined，需要进行判断
		if (feature) {
			const { Code } = feature.getProperties();
			if (
				openCity.indexOf(Code) !== -1
			) {
				this.lockCity(Code);
			}
		}
	};

	// 增加地名标注
	addCityName = () => {
		const { cityCenter } = this.state;
		const style = {
			fontSize: '16px',
			offsetY: 0,
			offsetX: 0,
		};
		const cityNameList = cityCenter.map((item: any, index: number) => {
			return map.createMarker(
				item.geometry.coordinates,
				{ id: `cityname-${item.properties.Code}`, name: item.properties.Name },
				{
					...style,
					textColor:
						openCity.indexOf(item.properties.Code) !== -1
							? '#FFFFFFCC'
							: '#FFFFFF59',
				}
			);
		});
		map.addMarker({ data: cityNameList, id: 'city-name' });
	};

	// 获取项目数据
	getProjectData = () => {
		// axios.get('/project/list_projects').then(res => {
		//     if (res.data.code === 200) {
		//         this.setState({
		//             projectList: res.data.data
		//         }, () => {
		//             this.addProjectMarker()
		//         })
		//     } else {
		//         message.error('获取项目信息失败')
		//     }
		// })
		this.addProjectMarker();
	};

	// 添加项目标注
	addProjectMarker = () => {
		const { projectList } = this.state;
		const style = {
			iconUrl: marker_bg,
			iconScale: 1.2,
			fontSize: '18px',
			fontFamily: 'TV Nord EF Cond Light',
			textColor: '#FF3232',
			offsetY: -22,
			offsetX: 25,
		};
		const markerList = projectList.map((item: any, index: number) => {
			return map.createMarker(
				[item.position.x, item.position.y],
				{
					...item,
					name: index < 9 ? '0' + (index + 1) : (index + 1).toString(),
				},
				style
			);
		});
		const layer = map.addMarker({ data: markerList, id: 'project' });
		layer.setZIndex(1);
	};

	// 加载浙江省边界
	addProvinceBorder = () => {
		const { provinceBorder } = this.state;
		map.addBorder(
			provinceBorder,
			{ id: 'province-border' },
			{ width: 2, color: '#484B50' }
		);
	};

	// 加载浙江省动态光圈
	addProvinceCircle = () => {
		const { provinceBorder } = this.state;
		// 传入浙江省中心点和浙江省边界
		calculateScreenDistance(map.defaultCenter, provinceBorder).then(
			(res: any) => {
				this.province_radius = res * 2;
				let dom = document.createElement('div');
				document.body.appendChild(dom);
				dom.style.pointerEvents = 'none';
				dom.id = 'province-circle';
				let point_overlay = new Overlay({
					id: 'province-circle',
					element: dom,
					positioning: 'center-center',
					stopEvent: true,
				});
				map.addOverlay(point_overlay);
				point_overlay.setPosition(map.defaultCenter);
				ReactDOM.render(
					<Lottie
						options={bigCircleOptions}
						width={res * 2}
						height={res * 2}
					></Lottie>,
					dom
				);
			}
		);
	};

	// 加载市边界线
	addCityBorder = () => {
		const { cityBorder } = this.state;
		cityBorder.map((item: any, index: number) => {
			const polygon = {
				...item.properties,
				id: `cityborder-${item.properties.Code}`,
				name: item.properties.Name,
			};
			const style = {
				width: 2,
				borderColor: '#484B50',
				visible: true,
				fillColor:
					openCity.indexOf(item.properties.Code) !== -1
						? 'rgba(108, 129, 149, 0.15)'
						: 'rgba(108, 129, 149, 0)',
				lineDash:
					openCity.indexOf(item.properties.Code) !== -1
						? undefined
						: [1, 6],
			};
			const layer =
				item.geometry.coordinates.length > 1
					? map.addMultiPolygon({
						position: item.geometry.coordinates,
						polygon,
						style,
					})
					: map.addPolygon({
						position: item.geometry.coordinates[0],
						polygon,
						style,
					});
			layer.setZIndex(
				openCity.indexOf(item.properties.Code) !== -1
					? 4
					: 3
			);
		});
		setTimeout(() => {
			map.map.on('pointermove', this.checkMouse);
		});
	};

	// 加载市动态光圈
	addCityCircle = () => {
		let dom = document.createElement('div');
		document.body.appendChild(dom);
		dom.id = `city-circle`;
		dom.style.pointerEvents = 'none';
		let point_overlay = new Overlay({
			id: `city-circle`,
			element: dom,
			positioning: 'center-center',
			stopEvent: true,
		});
		map.addOverlay(point_overlay);
	};

	// 鼠标移动事件
	checkMouse = (e: any) => {
		const { cityBorder } = this.state;
		if (!this.isCalculate) {
			this.isCalculate = true;
			// 获取当前鼠标位置的屏幕坐标

			// const scale: any = document.getElementById("navigation").style.transform.slice(6, -1).split(",")
			// const newPixel = [e.pixel[0] / scale[0] * 1, e.pixel[1] / scale[1] * 1]

			// var pixel = map.map.getEventPixel(e.originalEvent);
			const goal = map.map.forEachFeatureAtPixel(e.pixel, (feature: any) => {
				return feature;
			});
			// 鼠标碰到Feature时变成指针
			map.map.getTargetElement().style.cursor = goal ? 'pointer' : '';
			// var hit = map.map.hasFeatureAtPixel(pixel);
			goal &&
				map
					.getLayer('city-name')
					.getSource()
					.forEachFeature((e) => {
						e.getStyle()
							.getText()
							.setFont(
								e.getId() === `cityname-${goal.values_.Code}`
									? 'bolder 16px 微软雅黑'
									: 'normal 16px 微软雅黑'
							);
						e.changed();
					});
			cityBorder.map((item: any, index: number) => {
				const result = goal
					? goal.values_.Code === item.properties.Code
					: false;
				const feature = map
					.getLayer(`cityborder-${item.properties.Code}`)
					.getSource()
					.getFeatureById(`cityborder-${item.properties.Code}`);
				feature
					.getStyle()
					.getStroke()
					.setColor(result ? '#B8BDC3' : '#484B50');
				feature
					.getStyle()
					.getStroke()
					.setLineDash(
						result
							? undefined
							: openCity.indexOf(item.properties.Code) !== -1
								? undefined
								: [1, 6]
					);
				map
					.getLayer(`cityborder-${item.properties.Code}`)
					.setZIndex(result ? 5 : 4);
				feature.changed();
			});
			setTimeout(() => {
				this.isCalculate = false;
			}, 70);
		}
	};

	checkCity = (feature: any) => {
		const { cityBorder, cityCenter } = this.state;
		// 鼠标落在了市里面
		if (feature && feature.getId()) {
			const city_border = cityBorder.find((item) => {
				return feature.getId().split('-')[1] === item.properties.Code;
			}).geometry.coordinates[0][0];
			const city_center = cityCenter.find((item) => {
				return feature.getId().split('-')[1] === item.properties.Code;
			}).geometry.coordinates;
			const originPosition = map.getOverlayById(`city-circle`).getPosition();
			// 显示市光圈
			// 原来市光圈已经存在
			if (originPosition) {
				// 隐藏省光圈
				map.getOverlayById('province-circle').getPosition() &&
					map.getOverlayById('province-circle').setPosition(undefined);
				let num = 1;
				if (city_center[0] !== originPosition[0]) {
					if (!this.interval) {
						const lng = city_center[0] - originPosition[0];
						const lat = city_center[1] - originPosition[1];
						calculateCityRadius(city_center, city_border).then((res: any) => {
							const radius = res * 2 + 70 - this.origin_radius;
							this.interval = setInterval(() => {
								if (
									map.getOverlayById(`city-circle`).getPosition()[0] ===
									city_center[0]
								) {
									map.getOverlayById(`city-circle`).setPosition(city_center);
									clearInterval(this.interval);
									this.interval = undefined;
									ReactDOM.render(
										<Lottie
											options={smallCircleOptions}
											width={res * 2 + 70}
											height={res * 2 + 70}
										></Lottie>,
										document.getElementById(`city-circle`)
									);
									setTimeout(() => {
										this.isCalculate = false;
									}, 30);
								} else {
									map
										.getOverlayById(`city-circle`)
										.setPosition([
											originPosition[0] + (lng / 30) * num,
											originPosition[1] + (lat / 30) * num,
										]);
									ReactDOM.render(
										<Lottie
											options={smallCircleOptions}
											width={this.origin_radius + (radius / 30) * num}
											height={this.origin_radius + (radius / 30) * num}
										></Lottie>,
										document.getElementById(`city-circle`)
									);
								}
								num = num + 1;
							}, 20);
						});
					}
				} else {
					setTimeout(() => {
						this.isCalculate = false;
					}, 30);
				}
			} else {
				let num = 1;
				// 正常显现
				// calculateCityRadius(city_center, city_border).then((res: any) => {
				//     ReactDOM.render(<Lottie
				//         options={smallCircleOptions}
				//         width={res * 2 + 70}
				//         height={res * 2 + 70}
				//     >
				//     </Lottie>, document.getElementById(`city-circle`))
				//     map.getOverlayById(`city-circle`).setPosition(city_center)
				// })
				const lng = city_center[0] - map.defaultCenter[0];
				const lat = city_center[1] - map.defaultCenter[1];
				calculateCityRadius(city_center, city_border).then((res: any) => {
					this.origin_radius = res * 2 + 70;
					const radius = this.origin_radius - this.province_radius;
					this.interval = setInterval(() => {
						// 省光圈缩小，移动
						if (
							map.getOverlayById(`province-circle`).getPosition()[0] ===
							city_center[0]
						) {
							map.getOverlayById(`province-circle`).setPosition(undefined);
							ReactDOM.render(
								<Lottie
									options={bigCircleOptions}
									width={this.province_radius}
									height={this.province_radius}
								></Lottie>,
								document.getElementById(`province-circle`)
							);
							map.getOverlayById(`city-circle`).setPosition(city_center);
							clearInterval(this.interval);
							this.interval = undefined;
							ReactDOM.render(
								<Lottie
									options={smallCircleOptions}
									width={res * 2 + 70}
									height={res * 2 + 70}
								></Lottie>,
								document.getElementById(`city-circle`)
							);
							setTimeout(() => {
								this.isCalculate = false;
							}, 30);
						} else {
							map
								.getOverlayById(`province-circle`)
								.setPosition([
									map.defaultCenter[0] + (lng / 30) * num,
									map.defaultCenter[1] + (lat / 30) * num,
								]);
							ReactDOM.render(
								<Lottie
									options={bigCircleOptions}
									width={this.province_radius + (radius / 30) * num}
									height={this.province_radius + (radius / 30) * num}
								></Lottie>,
								document.getElementById(`province-circle`)
							);
						}
						num = num + 1;
					}, 20);
				});
			}
		} else {
			!map.getOverlayById('province-circle').getPosition() &&
				map.getOverlayById('province-circle').setPosition(map.defaultCenter);
			map.getOverlayById('city-circle').getPosition() &&
				map.getOverlayById('city-circle').setPosition(undefined);
			setTimeout(() => {
				this.isCalculate = false;
			}, 30);
		}
	};

	lockCity = (Code: string) => {
		const { cityCenter, cityBorder } = this.state;
		const center = cityCenter.find((item) => {
			return item.properties.Code == Code;
		});
		const border = cityBorder.find((item) => {
			return item.properties.Code === Code;
		});
		const city_center = center.geometry.coordinates;
		const city_border = border.geometry.coordinates[0][0];
		let num = 1;
		// 计算新半径
		calculateCityRadius(city_center, city_border).then((res: any) => {
			const newRadius = res * 2 + 70;
			const radius = newRadius - this.province_radius;
			const lng = city_center[0] - map.defaultCenter[0];
			const lat = city_center[1] - map.defaultCenter[1];
			this.interval = setInterval(() => {
				// 省光圈缩小，移动
				if (
					map.getOverlayById(`province-circle`).getPosition()[0] ===
					city_center[0]
				) {
					clearInterval(this.interval);
					this.interval = undefined;
					// 进行页面跳转
					map.map.un('singleclick', this.mapClick);
					setTimeout(() => {
						this.gotoPage(center);
					});
				} else {
					map
						.getOverlayById(`province-circle`)
						.setPosition([
							map.defaultCenter[0] + (lng / 30) * num,
							map.defaultCenter[1] + (lat / 30) * num,
						]);
					ReactDOM.render(
						<Lottie
							options={middleCircleOptions}
							width={this.province_radius + (radius / 30) * num}
							height={this.province_radius + (radius / 30) * num}
						></Lottie>,
						document.getElementById(`province-circle`)
					);
				}
				num = num + 1;
			}, 30);
		});
	};

	gotoPage = (center: any) => {
		const { Code, Name } = center.properties;
		const { coordinates } = center.geometry;
		if (
			openCity.indexOf(Code) !== -1
		) {
			new Promise((resolve, reject) => {
				let countyData = {
					code: null,
					level: null,
					mode: null,
				};
				switch (Code) {
					case '331100': //丽水市
						const tokenAccount = JSON.parse(localStorage.getItem('token'))
						const role = localStorage.getItem('role')
						if (tokenAccount.account === 'ls_jn_tourist') {
							countyData.code = '331127';
							countyData.level = 2;
							countyData.mode = 1;
							resolve(countyData);
						} else if (role === 'ROLE_2') {
							countyData.code = tokenAccount.areaCode;
							countyData.level = tokenAccount.areaLevel;
							countyData.mode = 1;
							resolve(countyData);
						} else {
							countyData.code = '331100';
							countyData.level = 1;
							countyData.mode = 1;
							resolve(countyData);
						}

						break;
					case '330300': //温州市
						countyData.code = '330300';
						countyData.level = 1;
						countyData.mode = 1;
						resolve(countyData);
						break;
					case '330800': //衢州市
						countyData.code = '330800';
						countyData.level = 1;
						countyData.mode = 1;
						resolve(countyData);
						break;
					case '330700': //金华市
						countyData.code = '330784';
						countyData.level = 2;
						countyData.mode = 2;
						resolve(countyData);
						break;
				}
			}).then((resData: any) => {
				setTimeout(() => {
					this.props.history.push({
						pathname: '/editPage',
						state: {
							name: Name,
							code: resData.code,
							propsareaCode: Code,
							mode: resData.mode,
							loalat: coordinates,
							level: resData.level,
							username: this.state.username,
						},
					});
				}, 700);
			});
		}
	};

	//播放视频
	videoPlay = () => {
		window.open(
			'https://highdata.oss-cn-hangzhou.aliyuncs.com/%E6%BC%94%E7%A4%BA%E8%A7%86%E9%A2%91/%E6%9D%BE%E6%9D%90%E7%BA%BF%E8%99%AB%E7%97%85%E6%97%A0%E4%BA%BA%E6%9C%BA%E6%99%AE%E6%9F%A5%E8%A7%86%E9%A2%9120220825.mp4'
		);
	};

	render() {
		const { pageLeftData, pageRightData } = this.state;
		return (
			<>
				<div className={'map-box'} id={'map-box'}></div>
				<ScaleContainer resolution="16:9" isPointerEve="none" adapt="two-way">
					<div className={'navigation-box'}>
						<div className={'grid-bg'} />
						<Header
							username={this.state.username}
							changeYear={this.changeYear}
							changeCityCode={this.changeCityCode}
						/>
						<Left pageLeftData={pageLeftData} />
						<Right pageRightData={pageRightData} />
						{/* <div className={'video-box'} onClick={() => this.videoPlay()}>
							<div className={'circle'}>
								<CaretRightOutlined />
							</div>
							<span>观看普查影片</span>
						</div> */}
					</div>
				</ScaleContainer>
			</>
		);
	}
}

export default Navigation;
