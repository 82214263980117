import React from 'react';
import ReactDOM from 'react-dom';
import Router from './router';
import './utils/axios';
import './utils/refresh';
import moment from 'moment';
import 'moment/locale/zh-cn';
import { Provider } from 'react-redux';
import store from './utils/store';
import zhCN from 'antd/es/locale/zh_CN';
import 'antd/dist/antd.less';
import { ConfigProvider } from 'antd';
// moment.locale('zh-cn')

ReactDOM.render(
	<Provider store={store} >
		<ConfigProvider locale={zhCN}>
			<Router />
		</ConfigProvider>
	</Provider>,
	document.getElementById('toolbar')
)