import { Component } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import {
  AppstoreOutlined,
  FileTextOutlined,
  ToolOutlined,
  TeamOutlined,
  createFromIconfontCN,
} from "@ant-design/icons";

import "./index.scss";

const MyIcon = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/c/font_3786243_t3tp6xhfp.js", // 在 iconfont.cn 上生成
});

export default class MenuList extends Component<any, any> {
  items = [
    {
      label: (
        <Link
          to={{
            pathname: "/platform/plagueWood",
            state: this.props.locationState,
          }}
        >
          疫木管理
        </Link>
      ),
      key: "plagueWood",
      authority: ["ROLE_0", "ROLE_1", "ROLE_3", "ROLE_4"],
      title: "疫木管理",
      icon: <AppstoreOutlined rev={undefined} style={{ color: "white" }} />,
    },
    {
      label: (
        <Link
          to={{
            pathname: "/platform/census",
            state: this.props.locationState,
          }}
        >
          普查管理
        </Link>
      ),
      key: "census",
      authority: ["ROLE_0", "ROLE_1", "ROLE_3", "ROLE_4"],
      title: "普查管理",
      icon: <FileTextOutlined rev={undefined} style={{ color: "white" }} />,
    },
    {
      label: (
        <Link
          to={{
            pathname: "/platform/acceptance",
            state: this.props.locationState,
          }}
        >
          跟踪验收
        </Link>
      ),
      title: "跟踪验收",
      key: "acceptance",
      authority: ["ROLE_0", "ROLE_1", "ROLE_2", "ROLE_3", "ROLE_4"],
      icon: <MyIcon type="icon-jspjiancha" />,
    },
    {
      label: (
        <Link
          to={{
            pathname: "/platform/check",
            state: this.props.locationState,
          }}
        >
          监理检查
        </Link>
      ),
      title: "监理检查",
      authority: ["ROLE_0", "ROLE_1", "ROLE_2", "ROLE_3", "ROLE_4"],
      key: "check",
      icon: <MyIcon type="icon-jianlirizhi" />,
    },
    {
      label: (
        <Link
          to={{ pathname: "/platform/work", state: this.props.locationState }}
        >
          工作统计
        </Link>
      ),
      title: "工作统计",
      key: "work",
      authority: ["ROLE_0", "ROLE_1", "ROLE_4"],
      icon: <MyIcon type="icon-gl-chartBarUpward" />,
    },
    {
      label: (
        <Link
          to={{ pathname: "/platform/notice", state: this.props.locationState }}
        >
          公告管理
        </Link>
      ),
      key: "notice",
      authority: ["ROLE_0"],
      title: "公告管理",
      icon: <MyIcon type="icon-gonggao" />,
    },
    {
      label: (
        <Link
          to={{ pathname: "/platform/user", state: this.props.locationState }}
        >
          用户管理
        </Link>
      ),
      key: "user",
      authority: ["ROLE_0"],
      title: "用户管理",
      icon: <TeamOutlined rev={undefined} style={{ color: "white" }} />,
    },
    {
      label: "数据管理",
      key: "tools",
      authority: ["ROLE_0", "ROLE_1", "ROLE_4"],
      title: "数据管理",
      icon: <ToolOutlined rev={undefined} />,
      children: [
        {
          label: (
            <Link
              to={{
                pathname: "/platform/orthographic",
                state: this.props.locationState,
              }}
            >
              正射上传
            </Link>
          ),
          authority: ["ROLE_0", "ROLE_1", "ROLE_4"],
          key: "orthographic",
          title: "正射上传",
        },
        {
          label: (
            <Link
              to={{
                pathname: "/platform/orthoList",
                state: this.props.locationState,
              }}
            >
              正射列表
            </Link>
          ),
          authority: ["ROLE_0", "ROLE_1", "ROLE_4"],
          key: "orthoList",
          title: "正射列表",
        },
        {
          label: (
            <Link
              to={{
                pathname: "/platform/platdataList",
                state: this.props.locationState,
              }}
            >
              疫木文件
            </Link>
          ),
          authority: ["ROLE_0", "ROLE_1", "ROLE_4"],
          key: "platdataList",
          title: "疫木文件",
        },
        {
          label: (
            <Link
              to={{
                pathname: "/platform/adjust",
                state: this.props.locationState,
              }}
            >
              数据调整
            </Link>
          ),
          authority: ["ROLE_0", "ROLE_1", "ROLE_4"],
          key: "adjust",
          title: "数据调整",
        },
        {
          label: (
            <Link
              to={{
                pathname: "/platform/annual",
                state: this.props.locationState,
              }}
            >
              年度配置
            </Link>
          ),
          authority: ["ROLE_0"],
          key: "annual",
          title: "年度配置",
        },
        {
          label: (
            <Link
              to={{
                pathname: "/platform/score",
                state: this.props.locationState,
              }}
            >
              算分管理
            </Link>
          ),
          authority: ["ROLE_0"],
          key: "score",
          title: "算分管理",
        },
        {
          label: (
            <Link
              to={{
                pathname: "/platform/car",
                state: this.props.locationState,
              }}
            >
              车辆管理
            </Link>
          ),
          authority: ["ROLE_0"],
          key: "car",
          title: "车辆管理",
        },
        {
          label: (
            <Link
              to={{
                pathname: "/platform/editHistory",
                state: this.props.locationState,
              }}
            >
              验收历史修改
            </Link>
          ),
          authority: ["ROLE_0"],
          key: "editHistory",
          title: "验收历史修改",
        },
        {
          label: (
            <Link
              to={{
                pathname: "/platform/reporthistory",
                state: this.props.locationState,
              }}
            >
              报告历史
            </Link>
          ),
          authority: ["ROLE_0", "ROLE_1"],
          key: "reporthistory",
          title: "报告历史",
        },
      ],
    },
    {
      label: "疫木除治",
      key: "epidWood",
      authority: ["ROLE_0", "ROLE_1"],
      title: "疫木除治",
      icon: <ToolOutlined rev={undefined} />,
      children: [
        {
          label: (
            <Link
              to={{
                pathname: "/platform/houseAb",
                state: this.props.locationState,
              }}
            >
              房前屋后
            </Link>
          ),
          authority: ["ROLE_0", "ROLE_1", "ROLE_2", "ROLE_3", "ROLE_4"],
          key: "houseAb",
          title: "房前屋后",
        },
        {
          label: (
            <Link
              to={{
                pathname: "/platform/eliminationreport",
                state: this.props.locationState,
              }}
            >
              除治上报
            </Link>
          ),
          authority: ["ROLE_0", "ROLE_1"],
          key: "eliminationreport",
          title: "除治上报",
        },
      ],
    },
    {
      label: "辅助工具",
      title: "辅助工具",
      key: "auxiliary",
      authority: ["ROLE_0", "ROLE_1", "ROLE_4"],
      icon: <MyIcon type="icon-fuzhugongju" />,
      children: [
        {
          label: (
            <Link
              to={{
                pathname: "/platform/assist",
                state: this.props.locationState,
              }}
            >
              面积补充
            </Link>
          ),
          authority: ["ROLE_0", "ROLE_1", "ROLE_4"],
          key: "assist",
          title: "面积补充",
        },
        {
          label: (
            <Link
              to={{
                pathname: "/platform/parse",
                state: this.props.locationState,
              }}
            >
              图片解析
            </Link>
          ),
          authority: ["ROLE_0", "ROLE_1", "ROLE_4"],
          key: "parse",
          title: "图片解析",
        },
        {
          label: (
            <Link
              to={{
                pathname: "/platform/excel_kml",
                state: this.props.locationState,
              }}
            >
              Excel转换KML
            </Link>
          ),
          authority: ["ROLE_0", "ROLE_1", "ROLE_4"],
          key: "excel_kml",
          title: "Excel转换kml",
        },
        {
          label: (
            <Link
              to={{
                pathname: "/platform/reimbursement",
                state: this.props.locationState,
              }}
            >
              报销单上传
            </Link>
          ),
          authority: ["ROLE_0", "ROLE_1", "ROLE_4"],
          key: "reimbursement",
          title: "报销单上传",
        },
        {
          label: (
            <Link
              to={{
                pathname: "/platform/travelReport",
                state: this.props.locationState,
              }}
            >
              出差报告上传
            </Link>
          ),
          authority: ["ROLE_0", "ROLE_1", "ROLE_4"],
          key: "travelReport",
          title: "出差报告上传",
        },
        {
          label: (
            <Link
              to={{
                pathname: "/platform/errorHandle",
                state: this.props.locationState,
              }}
            >
              异常处理
            </Link>
          ),
          authority: ["ROLE_0", "ROLE_1"],
          key: "errorHandle",
          title: "异常处理",
        },
      ],
    },
  ];
  role = localStorage.getItem("role");
  rootSubmenuKeys = ["tools", "auxiliary"];
  constructor(props) {
    super(props);
    this.state = {
      OpenKeys: this.getOpenKeys(),
      items: this.getItems(),
    };
  }

  getOpenKeys = () => {
    let key = "";
    this.items.map((item: any) => {
      item.children?.map((e) => {
        const name = e.label.props?.to?.pathname;
        if (name === window.location.pathname) {
          key = item.key;
        }
      });
    });
    return [key];
  };
  getItems = () => {
    const data = [];
    this.items.map((item) => {
      if (item.authority.indexOf(this.role) >= 0) {
        const admin = JSON.parse(localStorage.getItem("token")).account;
        if (
          (item.title === "公告管理" || item.title === "用户管理") &&
          admin === "dckj_admin"
        ) {
          data.push(item);
        } else if (item.title !== "公告管理" && item.title !== "用户管理") {
          if (item.children && item.children.length > 0) {
            let arr = [];
            item.children.map((e) => {
              e.authority.indexOf(this.role) >= 0 && arr.push(e);
            });

            item.children = arr;
            data.push(item);
          } else {
            data.push(item);
          }
        }
      }
    });
    return data;
  };
  onOpenChange = (keys) => {
    this.setState({
      OpenKeys: keys[0],
    });
    const latestOpenKey = keys.find(
      (key) => this.state.OpenKeys.indexOf(key) === -1
    );
    if (this.rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      this.setState({
        OpenKeys: keys,
      });
    } else {
      this.setState({
        OpenKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  render() {
    const { pathname } = window.location;
    const { OpenKeys, items } = this.state;

    return (
      <Menu
        theme="dark"
        items={items}
        defaultSelectedKeys={[pathname.substring(10)]}
        // defaultOpenKeys={[OpenKeys]}
        openKeys={OpenKeys}
        onOpenChange={this.onOpenChange}
        mode="inline"
      />
    );
  }
}
