import { useEffect, useState } from 'react';
import Request from '../../../services/Manger';
import img1 from '../../../assets/icons/icon/svg/areaicon.svg';
import back from '../../../assets/images/line.png';
import NumCard from '../../../component/Card/NumCard';
import img2 from '../../../assets/icons/icon/svg/treenumicon.svg';
import BaseCard from '../../../component/Card/BaseCard';
import Progress from '../../../component/tools/Progress';

// 丽水市·疫情发生统计
export default function InStatistics({ props }) {
  const { code, level, theName, year } = props;
  const [countryPercent, setCountry] = useState(0);
  const [classPercent, setClass] = useState(0);
  const [schedule, setSchedule] = useState(0);
  const [data, setDate] = useState({
    classNum: 0,
    countyNum: 0,
    hasClassNum: 0,
    hasCountyNum: 0,
    area: 0,
    treeNum: 0,
  });
  useEffect(() => {
    Request.InStatisticData(
      { areaCode: code, areaLevel: level, year },
      (res) => {
        if (res.code === 200) {
          setDate({ ...res.data });
          setCountry(
            parseInt(res.data.hasCountyNum) / parseInt(res.data.countyNum)
          );
          setClass(
            parseInt(res.data.hasClassNum) / parseInt(res.data.classNum)
          );
        }
      }
    );
    Request.Screenschedule(
      { areaCode: code, areaLevel: level, year },
      (res) => {
        if (res.code === 200) {
          setSchedule(res.data);
        }
      }
    );
  }, [code, level, year]);
  return (
    <BaseCard
      title={`${theName}·疫情发生统计`}
      describe={'EPIDEMIC STATISTICS'}
      height={302}
      backround={back}
    >
      <div className="InStatistics">
        <div className="InStatistics_total">
          <div>
            <img src={img1} alt="" />
            <NumCard
              title="发生面积"
              unit="万亩"
              bold={true}
              color="#02D68A"
              total={Number((data.area / 10000).toFixed(2))}
            />
          </div>
          <div>
            <img src={img2} alt="" />
            <NumCard
              title="疫木株数"
              unit="万株"
              color="#02D68A"
              bold={true}
              total={Number((data.treeNum / 10000).toFixed(2))}
            />
          </div>
        </div>
        <div className="InStatistics_progress">
          <div className="progress_content">
            <div className="progres_title">
              <NumCard
                title="疫点个数："
                color="#02D68A"
                bold={true}
                position={true}
                total={data.hasCountyNum}
              />
              <NumCard
                title="乡镇总数："
                position={true}
                total={data.countyNum}
              />
            </div>
            <Progress progress={countryPercent * 100} colorRight="white" />
          </div>
          <div className="progress_content">
            <div className="progres_title">
              <NumCard
                title="发生个数："
                color="#02D68A"
                bold={true}
                position={true}
                total={data.hasClassNum}
              />
              <NumCard
                title="松林小班："
                position={true}
                total={data.classNum}
              />
            </div>
            <Progress progress={classPercent * 100} colorRight="white" />
          </div>
        </div>
        <div className="InStatistics_schedule">
          <NumCard
            title="验收通过率："
            unit="%"
            total={schedule < 0 ? 0 : schedule > 100 ? 100 : schedule}
            color="#02D68A"
            bold={true}
            position={true}
          />
          <Progress progress={schedule < 0 ? 0 : schedule > 100 ? 100 : schedule} />
        </div>
      </div>
    </BaseCard>
  );
}
