import axios from "axios";
export default class AnnualService {
  //查询年度配置列表
  static getList(params) {
    return axios.get("/configration_year/query", { params });
  }

  //全量新增接口
  static putAddOffFull() {
    return axios.put("/configration_year/add_of_full_volume");
  }

  //根据id查询年度配置
  static getListById(params) {
    return axios.get("/configration_year/query_by_id/" + params);
  }

  //删除年度配置
  static delYearById(params) {
    return axios.delete("/configration_year/delete/" + params);
  }

  //批量删除年度配置
  static delYearByIds(params) {
    return axios.post("/configration_year/batch_delete", params);
  }

  //新增或者修改年度配置
  static putYearEdit(params) {
    return axios.put("/configration_year/edit", params);
  }
}
