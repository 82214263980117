import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Select,
  Input,
  Button,
  message,
  Checkbox,
  Radio,
} from "antd";
import Common from "../../platform/services/Common";
import Acceptance from "../../platform/services/Acceptance";
import { kml_axios } from "../../utils/axios";

const { Option } = Select;
const FormItem = Form.Item;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

// 导出区域组件
export const ExportArea = ({
  visible,
  handleCancel,
  countyCode,
  isInspection,
  townCode,
  isReading,
  areaLevel,
}) => {
  const [form] = Form.useForm();
  // const resultObj = {
  //   "99": "全部",
  //   "1": "验收通过",
  //   "0": "验收不通过",
  //   "-1": "待验收",
  //   "2": "全量未处理",
  //   "3": "不纳入考核",
  //   "6": "松林未处理",
  //   "4": "未验收发生小班",
  //   "5": "松林小班",
  //   "8": "已验收发生小班",
  //   "7": "省区未验收小班",
  //   "9": "全量小班",
  //   "10": "23-24未验收小班",
  // };
  const [townList, setTownList] = useState([]);
  const [town, setTown] = useState(townCode);
  const [village, setVillage] = useState("");
  const [forest, setForest] = useState("");
  const [villageList, setVillageList] = useState([]);
  const [forestList, setForestList] = useState([]);
  const [smallList, setSmallList] = useState([]);
  const [result, setResult] = useState(null);
  const [allSelect, setAllSelect] = useState(false);
  const [exportStyle, setExportStyle] = useState(1);
  const [resultObj, setResultObj] = useState<
    Array<{ value: number; label: string }>
  >([]);

  const getKMLList = () => {
    Acceptance.getKMLTypeList().then((res) => {
      const data = res.data.data;
      // console.log(data);
      setResultObj(data);
    });
  };

  useEffect(() => {
    Common.getTownList({ countyCode }).then((res) => {
      const data = res.data.data;
      // data.shift()
      setTownList(data);
    });
    getKMLList();
    townCode && getVillageList(townCode);
  }, []);

  //导出确认
  const exportArea = (values) => {
    // console.log(values, result);
    const value = {
      ...values,
      classIdList: values.classIdList ? values.classIdList + "" : "",
      countyCode,
      result: allSelect ? 99 : result,
      year: sessionStorage.getItem("year"),
      isInspection: isInspection,
      style: exportStyle,
    };
    // const list = [99, 1, 0, -1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const list = [...resultObj.map((item) => item.value), 99];
    console.log(list);

    if (list.indexOf(value.result) !== -1) {
      message.loading({
        content: "kml正在导出,请稍等一会",
        key: "kml",
        duration: 0,
      });
      kml_axios.defaults.headers.common["Authorization"] =
        "Bearer " + JSON.parse(localStorage.getItem("token")).access_token;
      kml_axios.get("/acceptance/list_kml", { params: value }).then((res) => {
        if (res.status === 200) {
          const date = new Date();
          const year = date.getFullYear();
          const month =
            date.getMonth() + 1 < 10
              ? `0${date.getMonth() + 1}`
              : `${date.getMonth() + 1}`;
          const day = date.getDate();
          const townName = townList.find((item) => {
            return item.code === town;
          }).name;
          let str = `${year}${month}${day}-${townName}`;
          if (value.villageCode) {
            const villageName = villageList.find((item) => {
              return item.code === village;
            }).name;
            str = str + "-" + villageName;
          }
          if (value.forestCode) {
            const forestName = forestList.find((item) => {
              return item.code === values.forestCode;
            }).name;
            str = str + "-" + forestName;
          }
          let blob = new Blob([res.data], {
            type: "application/vnd.google-earth.kml+xml",
          });
          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = function (e) {
            let a: any = document.createElement("a");
            a.download =
              str +
              `-${resultObj.find((item) => item.value === result).label}.kml`;
            a.href = e.target.result;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            message.destroy("kml");
            message.success("导出成功");
            setVillageList([]);
            setForestList([]);
            setSmallList([]);
            setResult(null);
            setAllSelect(false);
            handleCancel(visible);
            form.resetFields([
              "villageCode",
              "forestCode",
              "classIdList",
              "frequency",
            ]);
            getVillageList(town);
          };
        } else {
          message.error("未选择验收状态");
        }
      });
    }
  };

  //选择
  const changeSelect = (value, key) => {
    switch (key) {
      case 1:
        setTown(value);
        getVillageList(value);
        break;
      case 2:
        setVillage(value);
        getForestList(town, value);
        break;
      case 3:
        setForest(value);
        getSmallClass(town, village, value);
        break;
    }
  };
  //行政村
  const getVillageList = (townCode) => {
    Common.getVillageList({ countyCode, townCode }).then((res) => {
      setVillageList(res.data.data);
    });
  };
  //林班
  const getForestList = (townCode, villageCode) => {
    Common.getForestList({ countyCode, townCode, villageCode }).then((res) => {
      setForestList(res.data.data);
    });
  };

  //小班
  const getSmallClass = (townCode, villageCode, forestCode) => {
    Common.getClassList({ countyCode, townCode, villageCode, forestCode }).then(
      (res) => {
        setSmallList(res.data.data);
      }
    );
  };

  const onResultChanged = (value) => {
    setResult(value);
  };
  const onChange = (e) => {
    setAllSelect(e.target.checked);
  };

  return (
    <Modal
      title="导出小班区域"
      visible={visible}
      footer={null}
      onCancel={() => handleCancel(visible)}
      centered={true}
      width={600}
      className="updateAll_modal"
    >
      <Form
        onFinish={exportArea}
        form={form}
        initialValues={{
          townCode: townCode,
        }}
      >
        <FormItem
          name="townCode"
          label="乡镇/街道"
          rules={[
            {
              required: true,
              message: "请选择乡镇/街道",
            },
          ]}
          {...layout}
        >
          <Select
            onSelect={(value) => changeSelect(value, 1)}
            disabled={areaLevel === 3 && true}
          >
            {townList.map((item, index) => {
              return (
                <Option key={item.code} value={item.code}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </FormItem>
        <FormItem name="villageCode" label="行政村" {...layout}>
          <Select onSelect={(value) => changeSelect(value, 2)}>
            {villageList.map((item, index) => {
              return (
                <Option key={index} value={item.code}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </FormItem>
        <FormItem name="forestCode" label="林班" {...layout}>
          <Select onSelect={(value) => changeSelect(value, 3)}>
            {forestList.map((item, index) => {
              return (
                <Option key={index} value={item.code}>
                  {item.code + "(" + item.name + ")"}
                </Option>
              );
            })}
          </Select>
        </FormItem>
        <FormItem name="classIdList" label="小班号" {...layout}>
          <Select mode="multiple" showArrow={true} maxTagCount={4}>
            {smallList.map((item, index) => {
              return (
                <Option key={index} value={item.classId}>
                  {item.smallClass}
                </Option>
              );
            })}
          </Select>
        </FormItem>
        {/* <FormItem
					name="totalFrequency"
					label={isInspection === 0 ? '总验收次数' : '总监理次数'}
					{...layout}
				>
					<Input placeholder={isInspection === 0 ? '验收总数' : '监理总数'} />
				</FormItem> */}
        <FormItem
          name="frequency"
          label={isInspection === 0 ? "验收次数" : "监理次数"}
          {...layout}
        >
          <Input
            placeholder={
              isInspection === 0 ? "填入第几次验收次数" : "填入第几次监理次数"
            }
          />
        </FormItem>
        <FormItem
          name="result"
          label={isInspection === 0 ? "验收结果" : "监理结果"}
          required={true}
          {...layout}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Select
              allowClear
              placeholder="仅支持选择单个状态或全选"
              onSelect={onResultChanged}
              style={{ width: 300 }}
            >
              {resultObj.map((item) => (
                <Option key={item.value} title={item.label} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
            <Checkbox onChange={onChange}>全选</Checkbox>
          </div>
        </FormItem>
        <FormItem name="style" label="样式" {...layout} initialValue={1}>
          <Radio.Group
            value={exportStyle}
            onChange={() => setExportStyle((x) => (x === 1 ? 2 : 1))}
          >
            <Radio value={1}>奥维样式</Radio>
            <Radio value={2}>司空样式</Radio>
          </Radio.Group>
        </FormItem>
        <div className="form-button">
          <FormItem>
            <Button type="primary" htmlType="submit">
              确认
            </Button>
            <Button onClick={() => handleCancel(visible)}>取消</Button>
          </FormItem>
        </div>
      </Form>
    </Modal>
  );
};
