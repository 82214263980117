import React, { Component } from 'react';
import triangle from '../../assets/images/triangle.png';
import { message } from 'antd';
import { Link } from 'react-router-dom';
import axios from 'axios';

interface Props {
	username: any;
}

interface State {
	yearList: any;
	cityData: any;
}

class Header extends Component<any, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			yearList: [],
			cityData: [
				{ Name: '丽水市', Layer: '市', Code: '331100' },
				{ Name: '金华市', Layer: '市', Code: '330700' },
				{ Name: '温州市', Layer: '市', Code: '330300' },
				{ Name: '衢州市', Layer: '市', Code: '330800' },
				{ Name: '台州市', Layer: '市', Code: '331000' },
				{ Name: '宁波市', Layer: '市', Code: '330200' },
				{ Name: '杭州市', Layer: '市', Code: '330100' },
				{ Name: '湖州市', Layer: '市', Code: '330500' },
				{ Name: '舟山市', Layer: '市', Code: '330900' },
				{ Name: '嘉兴市', Layer: '市', Code: '330400' },
				{ Name: '绍兴市', Layer: '市', Code: '330600' },
			],
		};
	}

	componentDidMount() {
		const list = JSON.parse(sessionStorage.getItem('yearList'));
		this.setState({
			yearList: list,
		});
	}

	exitLogin = () => {
		axios.get('/user/revoke_token').then((res) => {
			if (res.data.code === 200) {
				message.success('已退出登录');
				localStorage.removeItem('token');
				localStorage.removeItem("admin")
				window.location.href = localStorage.getItem('isFromAdmin') ? "/admin" : '/'
			} else {
				message.error('退出账号存在异常，请联系管理员');
				localStorage.removeItem('token');
				localStorage.removeItem("admin")
				window.location.href = localStorage.getItem('isFromAdmin') ? "/admin" : '/'
			}
		});
	};

	Year = (value) => {
		const { changeYear } = this.props;
		sessionStorage.setItem('year', value);
		changeYear(value);
	};

	changeCode = (code) => {
		const { changeCityCode } = this.props;
		changeCityCode(code);
	};

	render() {
		const name = this.props.username ? this.props.username : JSON.parse(localStorage.getItem("token")).name
		return (
			<div className={'navigation-header'}>
				<div className={'left'}>
					<img src={triangle} alt="" />
					<div className={'title'}>
						<div>
							<span>点创科技</span>
							·云森防平台
						</div>
						<div>DINNOVATE · FOREST  PROTECTION  CLOUD  PLATFORM</div>
					</div>
					{/* <div className={'select'}>
                        <div className={'select-title'}>年度</div>
                        <Select style={{ width: 120 }} defaultValue={sessionStorage.getItem("year")} onChange={(value) => { this.Year(value) }}>
                            {
                                yearList && yearList.map((item: any) => {
                                    return <Option key={item.key} value={item.value}>{item.value}</Option>
                                })
                            }
                        </Select>
                    </div>
                    <div className={'select-city'}>
                        <div className={'select-title'}>市</div>
                        <Select style={{ width: 90 }} defaultValue={"331100"} onChange={(code) => { this.changeCode(code) }}>
                            {
                                cityData && cityData.map((item: any) => {
                                    return <Option key={item.Code} value={item.Code}>{item.Name}</Option>
                                })
                            }
                        </Select>
                    </div> */}
				</div>
				<div className={'right'}>{name}，
					<span onClick={() => this.exitLogin()}>退出</span>
				</div>
			</div>
		);
	}
}

export default Header;
