import React, { Component } from "react";
import Lottie from "react-lottie";
import bottom from "../../assets/icons/bottom.json";
import exit from "../../assets/icons/icon/svg/退出登录.svg";
import {
  FullscreenOutlined,
  PieChartFilled,
  ReloadOutlined,
  FullscreenExitOutlined,
  createFromIconfontCN,
} from "@ant-design/icons";
import "./footer.scss";
import { Input, Space } from "antd";
import { Button, Modal, Select } from "antd";
import Common from "../../platform/services/Common";

const { Search } = Input;
interface Props {
  setOperat: any;
  isOperat: any;
  changeYear: any;
  changeLiandu: any;
  lianduLayerCheck: any;
}

interface State {
  time: any;
  isExitHidden: any;
  yearList: any;
  isFullScreen: any;
  equipmentType: string;
}
const { Option } = Select;

const MyIcon = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/c/font_3786243_t3tp6xhfp.js", // 在 iconfont.cn 上生成
});

export default class Footer extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      time: null,
      isExitHidden: false,
      yearList: [],
      isFullScreen: false,
      equipmentType: sessionStorage.getItem("type"),
    };
  }
  componentDidMount() {
    setInterval(() => this.getTime(), 1000);
    const yearList = JSON.parse(sessionStorage.getItem("yearList"));
    this.setState(
      {
        yearList,
      },
      () => {
        !sessionStorage.getItem("year") &&
          sessionStorage.setItem("year", this.state.yearList[0].value);
      }
    );
  }

  //当前时间
  getTime = () => {
    let today = new Date(),
      time =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        today.getDate().toString().padStart(2, "0") +
        " " +
        today.getHours().toString().padStart(2, "0") +
        ":" +
        today.getMinutes().toString().padStart(2, "0") +
        ":" +
        today.getSeconds().toString().padStart(2, "0");
    this.setState({
      time,
    });
  };

  //修改年份
  yearChange = (value) => {
    const { changeYear } = this.props;
    sessionStorage.setItem("year", value);
    changeYear(value);
  };

  //莲都红点图层是否展示
  lianduChange = () => {
    const { changeLiandu } = this.props;
    console.log("foot");
    changeLiandu();
  };

  //充满屏幕
  FullScreen = () => {
    const { isFullScreen, equipmentType } = this.state;
    if (equipmentType === "PC") {
      const FullScreen = document.fullscreenElement;
      FullScreen
        ? document.exitFullscreen()
        : document.documentElement.requestFullscreen();
      this.setState({
        isFullScreen: !isFullScreen,
      });
    }
  };
  //显示两边页面
  showPage = () => {
    this.props.isOperat
      ? this.props.setOperat(false)
      : this.props.setOperat(true);
  };
  //重新加载
  reload = () => {
    window.location.reload();
  };
  hidden = () => {
    Modal.confirm({
      title: "是否退出当前登录",
      okText: "是",
      cancelText: "否",
      style: {
        marginTop: "5%",
      },
      onOk() {
        localStorage.removeItem("token");
        window.location.href = "/";
      },
    });
  };

  render() {
    const { time, yearList, isFullScreen, equipmentType } = this.state;
    return (
      <div className="h-bottom">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: bottom,
          }}
          width={"100%"}
          height={"22px"}
        />
        <div className="bottom-tools">
          <div className="tools-left">
            <div className="exitedImg">
              <img
                src={exit}
                style={{ marginBottom: "5px", cursor: "pointer" }}
                onClick={() => {
                  this.hidden();
                }}
                alt=""
              />
            </div>
            <span>年度:</span>
            <Select
              disabled={
                window.localStorage.getItem("role").indexOf("2") > 0
                  ? true
                  : false
              }
              className="footer-select"
              placement="topRight"
              onChange={(value) => this.yearChange(value)}
              defaultValue={sessionStorage.getItem("year")}
            >
              {yearList &&
                yearList.map((item: any) => {
                  return (
                    <Option value={item.value} key={item.key}>
                      {item.value}
                    </Option>
                  );
                })}
            </Select>
          </div>

          <div className="tools-right">
            {/* <Search placeholder="搜索小班" onSearch={this.onSearch} style={{ width: 200, right: '130px', position: 'absolute' }} /> */}
            {this.props.lianduLayerCheck ? (
              <Button
                className="layer-check"
                type="primary"
                onClick={() => {
                  this.lianduChange();
                }}
                style={{ display: "none" }}
              >
                莲都退化林
              </Button>
            ) : (
              <Button
                className="layer-check"
                ghost
                onClick={() => {
                  this.lianduChange();
                }}
                style={{ display: "none" }}
              >
                莲都退化林
              </Button>
            )}

            <span style={{ marginRight: "10px" }}>{time}</span>

            {equipmentType === "PC" && (
              <>
                {isFullScreen ? (
                  <MyIcon
                    className="FullScreen"
                    type="icon-quxiaoquanping"
                    onClick={() => {
                      this.FullScreen();
                    }}
                  />
                ) : (
                  <MyIcon
                    className="FullScreen"
                    type="icon-quanping"
                    onClick={() => {
                      this.FullScreen();
                    }}
                  />
                )}
              </>
            )}
            <MyIcon
              className="PieChart"
              type="icon-tongji"
              onClick={() => {
                this.showPage();
              }}
            />
            <ReloadOutlined
              rev={undefined}
              className="reload"
              onClick={() => {
                this.reload();
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
