export const MapLayers = {
  //全量小班
  classBaseMap: {
    layers: "pine-epidemic-census:cut_report_class_base_map",
    srs: "EPSG:4549",
    format: "image/png",
  },
  //疑似小班
  suspectedClass: {
    layers: "pine-epidemic-census:cut_report_suspected_class",
    srs: "EPSG:4549",
    format: "image/png",
  },
  //已上报小班
  reportedClass: {
    layers: "pine-epidemic-census:already_report_time_interval",
    srs: "EPSG:4549",
    format: "image/png",
  },
  reportLayer: {
    layers: "pine-epidemic-census:cut_report",
    srs: "EPSG:4549",
    format: "image/png",
  },
  newAddClass: {
    layers: "pine-epidemic-census:cut_report_new_add",
    srs: "EPSG:4549",
    format: "image/png",
  },
  boundaries: {
    layers: "pine-epidemic-census:cut_report_town_border",
    srs: "EPSG:4549",
    format: "image/png",
  },
};
