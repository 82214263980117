import { acceptance_axios as axios, kml_axios } from "../../utils/axios";

export default class eiminationReports {
  static getList(params) {
    return axios.get("/cut_report/query", { params });
  }

  static updateData(data) {
    return axios.put("/cut_report/edit", data);
  }

  static delRecord(id) {
    return axios.delete("/cut_report/delete/" + id);
  }

  static delRecords(idArray) {
    return axios.delete("/cut_report/batch_delete", { data: idArray });
  }

  /**
   * @description 除治上报上传excel
   */

  static uploadExcel(data) {
    return axios.post("/cut_report/upload_excel", data);
  }

  /**
   * @description 根据条件分页查询表格上报失败
   */

  static queryExcelReportFail(params) {
    return axios.get("/cut_report/query_excel_report_fail", { params });
  }

  /**
   * @description 可视化批量上报进度
   */

  static batchReport(data) {
    return axios.post("/cut_report/batch_report", data);
  }

  /**
   * @description 可视化批量删除进度
   */

  static batchReportDelete(data) {
    return axios.delete("/cut_report/batch_report_delete", { data });
  }
}
