import React, { Component } from 'react';
import OlMap from '../../utils/olmap';
import { MapLayers } from '../acceptance/source';
import { DatePicker } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

const url = window['env'].LayerUrl + '/geoserver/pine-epidemic-census/wms';

const classLayer = {
	service: "WMS",
	request: "GetMap",
	version: "1.1.1",
	layers: "pine-epidemic-census:small_class_base_data_filter",
	transparent: true,
	styles: "",
	format: "image/png",
	exceptions: "application/vnd.ogc.se_inimage",
	srs: "EPSG:4549",
};

const pointLayer = {
	service: "WMS",
	request: "GetMap",
	version: "1.1.1",
	layers: "pine-epidemic-census:migration_tree_point",
	transparent: true,
	styles: "",
	format: "image/png",
	exceptions: "application/vnd.ogc.se_inimage",
	srs: "EPSG:4326",
};


class AdjustMap extends Component<any> {
	Clusterer; //点聚合对象
	pointLayers; //点面对象
	state = {
		startDate: null,
		endDate: null,
		pointViewparams: null
	};

	componentDidMount(): void {
		this.mapInit();
		OlMap.map.on('singleclick', this.MapClick);
	}

	// 点击事件 
	MapClick = (elm) => {
		//点击的标注物
		const feature = OlMap.map.forEachFeatureAtPixel(elm.pixel, (feature: any) => {
			return feature;
		});
		if (feature) {
			// const data = feature.values_
			// const lnglat = [data.point.x, data.point.y]
			// OlMap.addInfoOverlay({ data, lnglat })
		}
	}

	//日期
	rangeChange = (value) => {
		if (value) {
			const startDate = value[0].format('YYYY-MM-DD 00:00:00');
			const endDate = value[1].format('YYYY-MM-DD 23:59:59');
			this.setState({ startDate, endDate }, () => {
				const areaCode = sessionStorage.getItem('cityCode');
				const year = sessionStorage.getItem('year');
				let areaLevel = 1
				if (this.props.areaLevel) {
					areaLevel = 2
				}
				const viewparams = `areaCode:${areaCode};areaLevel:${areaLevel};year:${year};startTime:${startDate};endTime:${endDate}`
				OlMap.removeAdjustPoint()
				OlMap.addAdjustPoint({ ...pointLayer, viewparams: viewparams }, url)
			});

		}
	};

	//地图初始化 + 图层
	mapInit = () => {
		const areaCode = sessionStorage.getItem('cityCode');
		const year = sessionStorage.getItem('year');
		const lonlat = [
			Number(sessionStorage.getItem('lon')),
			Number(sessionStorage.getItem('lat')),
		];
		let areaLevel = 1
		if (this.props.areaLevel) {
			areaLevel = 2
		}
		const viewparams = `areaCode:${areaCode};areaLevel:${areaLevel};year:${year}`
		OlMap.init('adjustmap');
		OlMap.addAdjustSmallClassLayer({ ...classLayer, viewparams: viewparams }, url)
		OlMap.ToLngLat(lonlat, 9.3);
	};




	render() {
		const { startDate, endDate } = this.state
		return (
			<>
				<RangePicker
					value={[
						startDate ? moment(startDate, 'YYYY-MM-DD') : null,
						endDate ? moment(endDate, 'YYYY-MM-DD') : null,
					]}
					onChange={(value) => this.rangeChange(value)}
					inputReadOnly={true}
					style={{ marginRight: '5px', width: '240px' }}
					placeholder={['开始日期', '结束日期']}
				/>
				<div className="adjustmap" id="adjustmap" style={{ width: '100%', height: '600px', marginTop: '20px' }}></div>
			</>
		);
	}
}

export default AdjustMap;
