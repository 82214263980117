import { Button, message, Popconfirm, Select, Table, Input } from 'antd';
import React, { Component } from 'react';
import ToolTs from '../services/tool';
import './index.scss';
const { Option } = Select;
const { Search } = Input;
class PlatdataList extends Component<any, any> {
  state = {
    page: 1,
    size: 10,
    data: [],
    loading: true,
    total: 0,
    searchValue: '',
    selectedRowKeys: [],
    record: null,
    year: sessionStorage.getItem('year'),
    yearList: JSON.parse(sessionStorage.getItem('yearList')),
  };

  componentDidMount(): void {
    this.getData();
  }

  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {
    if (prevProps.locationState.code !== this.props.locationState.code) {
      this.getData();
    }
    if (prevProps.year !== this.props.year) {
      this.setState({
        year: this.props.year
      }, () => {
        this.getData();
      })
    }
  }

  //获取数据
  getData = () => {
    const { page, size, year, searchValue } = this.state;
    const { propsareaCode } = this.props.locationState;
    const params = {
      page,
      size,
      key: searchValue,
      year,
      areaCode: propsareaCode,
    };
    ToolTs.getfileQuery(params, (res) => {
      if (res.code === 200) {
        this.setState({
          data: res.data.list,
          loading: false,
          total: res.data.total,
        });
      }
    });
  };

  //切换
  changePagination = (pagination) => {
    this.setState(
      {
        page: pagination.current,
        size: pagination.pageSize,
        loading: true,
      },
      this.getData
    );
  };

  //删除
  deleteData = (record) => {
    ToolTs.deletefileQuery({ fileName: record.fileName }, (res) => {
      if (res.code === 200) {
        message.success('删除成功');
        this.getData();
      } else {
        message.error('删除失败');
      }
    });
  };

  //修改
  changeUpdateVisible = (record) => { };

  //搜索
  handleSearch = (value) => {
    this.setState(
      {
        searchValue: value,
      },
      () => {
        this.getData();
      }
    );
  };

  //更改年份
  changeYear = (year) => {
    this.setState(
      {
        page: 1,
        size: 10,
        year,
      },
      this.getData
    );
  };

  //选择数据
  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRowKeys,
    });
  };

  render() {
    const { page, size, data, loading, total, selectedRowKeys, yearList } =
      this.state;
    const pagination = {
      total,
      current: page,
      pageSize: size,
      showQuickJumper: true,
      responsive: false,
      showSizeChanger: true,
    };
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const columns = [
      {
        title: '序号',
        width: 80,
        dataIndex: 'id',
        key: 'id',
        render: (text, record, index) => (page - 1) * 10 + (index + 1),
      },
      {
        title: '文件名',
        width: 150,
        dataIndex: 'fileName',
        key: 'fileName',
        render: (text, record) => <span>{text || '暂无'}</span>,
      },
      {
        title: '上传人',
        width: 120,
        align: 'center' as 'center',
        dataIndex: 'uploadBy',
        key: 'uploadBy',
        render: (text, record) => <span>{text || '暂无'}</span>,
      },
      {
        title: '时间',
        align: 'center' as 'center',
        dataIndex: 'createTime',
        width: 180,
        key: 'createTime',
        render: (text, record) => <span>{text || '暂无'}</span>,
      },
      {
        title: '年度',
        dataIndex: 'year',
        align: 'center' as 'center',
        width: 150,
        key: 'year',
        render: (text, record) => <span>{text || '暂无'}</span>,
      },
      {
        title: '操作',
        width: 80,
        className: 'handle',
        dataIndex: 'handle',
        key: 'handle',
        render: (text, record) => (
          <>
            {/* <Button type="link" onClick={() => { this.changeUpdateVisible(record) }}>修改</Button> */}
            <Popconfirm
              title="确定要删除当前记录吗?"
              onConfirm={() => {
                this.deleteData(record);
              }}
              okText="确定"
              cancelText="取消"
            >
              <Button type="link">删除</Button>
            </Popconfirm>
          </>
        ),
      },
    ];

    return (
      <>
        <div className="platdataList">
          <div className="PdlHeader">
            <Search
              className="searchInput"
              placeholder="请输入信息查询"
              enterButton="搜索"
              onSearch={this.handleSearch}
              style={{
                width: '270px',
              }}
            />
            <Select
              placeholder="年度"
              style={{ width: 176 }}
              onChange={this.changeYear}
              allowClear
              defaultValue={sessionStorage.getItem('year')}
            >
              {yearList.map((item) => (
                <Option value={item.key} key={item.key}>
                  {item.value}
                </Option>
              ))}
            </Select>
          </div>
          <Table
            dataSource={data}
            rowKey={(record) => record.id}
            columns={columns}
            loading={loading}
            rowSelection={rowSelection}
            onChange={(pagination) => this.changePagination(pagination)}
            pagination={pagination}
            scroll={{ y: 'calc(100vh - 23.5rem)' }}
          />
        </div>
      </>
    );
  }
}

export default PlatdataList;
