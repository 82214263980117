import React, { Component } from "react";
import { CaretRightOutlined } from "@ant-design/icons";
import { Checkbox, message, Input } from "antd";
import "./home.scss";
import logo from "../assets/images/login/logo.png";
import Request from "../services/Manger";
import axios from "axios";
import { acceptance_axios } from "../utils/axios";
interface Props {}

interface State {
  index: any;
  username: any;
  password: any;
  bgList: any;
}
export default class Home extends Component<any, State> {
  time;
  role_list = ["ROLE_3", "ROLE_2"];
  constructor(props: Props) {
    super(props);
    this.state = {
      index: 1,
      username: "",
      password: "",
      bgList: [true, false, false, false, false],
    };
  }
  componentDidMount() {
    this.play();
    // let map = new CMap('map-container', {
    //     center: [119.91028, 28.40652],
    //     zoom: 11
    // })
    window.onunload = function (event) {
      //刷新和关闭都会触发
      if (
        (event.clientX > document.body.clientWidth && event.clientY < 0) ||
        event.altKey
      ) {
        localStorage.clear();
      } else {
      }
    };
  }

  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {}
  // 背景图播放
  play = () => {
    this.time = setInterval(() => {
      let { bgList } = this.state;
      const play = bgList.indexOf(true);
      bgList = [false, false, false, false, false];
      if (play >= 4) {
        bgList[0] = true;
        this.setState({
          bgList,
        });
      } else {
        bgList[play + 1] = true;
        this.setState({
          bgList,
        });
      }
    }, 5000);
  };

  // 点击背景图播放
  handlePlay = (e, i) => {
    let { bgList } = this.state;
    bgList = [false, false, false, false, false];
    bgList[i] = true;
    this.setState({
      bgList,
      index: i,
    });
  };

  // 登录
  login = () => {
    const { username, password } = this.state;
    const version = localStorage.getItem("version");

    let list = [];
    Request.getAllYear((res) => {
      if (res.code === 200) {
        res.data.forEach((e, index) => {
          list.push({ key: e, value: e });
          if (index === list.length - 1) {
            !sessionStorage.getItem("year") &&
              sessionStorage.setItem("year", list[0].value);
            sessionStorage.setItem("yearList", JSON.stringify(list));
          }
        });
      }
    });
    Request.Login(
      {
        username: username.trim(),
        password: password.trim(),
        sysVersion: version,
      },
      (res) => {
        if (res.code === 200) {
          const {
            expires_in,
            access_token,
            versionFlag,
            role,
            cityCode,
            name,
            areaCode,
          } = res.data;
          message.success("登录成功");
          res.data.expires = Date.now() + (expires_in - 60) * 1000;
          localStorage.setItem("token", JSON.stringify(res.data));
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + access_token;
          acceptance_axios.defaults.headers.common["Authorization"] =
            "Bearer " + access_token;
          localStorage.setItem("badgeShow", versionFlag);
          localStorage.setItem("role", role);
          if (window.location.href.indexOf("admin") !== -1) {
            localStorage.setItem("isFromAdmin", "yes");
            this.props.history.push({
              pathname: "/platform/plagueWood",
              state: {
                username: name,
                propsareaCode: cityCode,
                screenVisible: "allow", // allow 不允许返回大屏
              },
            });
          } else {
            localStorage.removeItem("isFromAdmin");
            // console.log(this.role_list)
            // console.log(role)
            // if (this.role_list.indexOf(role) !== -1) {     //所有游客权限只能跳转到后台
            // 	this.props.history.push({
            // 		pathname: '/platform/plagueWood',
            // 		state: {
            // 			propsareaCode: cityCode,
            // 			username: name,
            // 		},
            // 	});
            // } else {
            this.props.history.push({
              pathname: "/navigation",
              state: {
                propsareaCode: cityCode,
                username: name,
              },
            });
            // }
          }
        } else {
          message.error(res.msg);
        }
      }
    );
  };

  inputAccunt = (e) => {
    this.setState({
      username: e.target.value,
    });
  };

  inputPassword = (e) => {
    this.setState({
      password: e.target.value,
    });
  };

  render() {
    const { username, password, bgList, index } = this.state;
    return (
      <div className="home">
        {bgList.map((item, i) => {
          return (
            <div
              className="bg"
              style={item ? { opacity: 1 } : { opacity: 0 }}
              key={i}
            ></div>
          );
        })}
        <div className="login">
          <table>
            <p>用户登录</p>
            <span>SIGN IN</span>
            <div className="account">
              <p>账号</p>
              <Input
                className="input"
                type={"text"}
                onChange={this.inputAccunt}
              />
            </div>
            <div className="password">
              <p>密码</p>
              {/* <input type={'password'} onChange={this.inputPassword} ></input> */}
              <Input.Password onChange={this.inputPassword} />
            </div>
            <div className="remember">
              <Checkbox>记住用户名</Checkbox>
            </div>
            <button
              className="loginBtn"
              style={
                username === "" || password === ""
                  ? { backgroundColor: "#9FA8A3" }
                  : { backgroundColor: "#0AA254", cursor: "pointer" }
              }
              disabled={username === "" || password === "" ? true : false}
              onClick={this.login}
              type="submit"
            >
              登 录
            </button>
            <span></span>
            <button
              className="wisdomsystem"
              // onClick={() =>
              // 	(window.location.href = 'http://acceptance.pinesos.cn/')
              // }
            >
              点创科技云森防平台
            </button>
          </table>
          <div className="subscript">
            <span>浙江点创信息科技有限公司</span>
            <span>联系电话：181 5783 7979</span>
            <span>备案号:</span>
            <a href="https://beian.miit.gov.cn/#/Integrated/index">
              浙ICP备16044182号-6
            </a>
          </div>
        </div>
        <div className="title">
          <img className="logo" src={logo} alt="" />
          <p>一站式航拍、识别、分析服务</p>
          <p>点创科技云森防平台</p>
          <button
            className="acceptanc-video"
            onClick={() =>
              window.open(
                bgList.indexOf(true) % 2 === 0
                  ? window["env"].REACT_APP_API_URL + "/video/acceptance.mp4"
                  : window["env"].REACT_APP_API_URL + "/video/census.mp4"
              )
            }
          >
            观看影片
            <CaretRightOutlined rev={undefined} />
          </button>
          <ul>
            {bgList.map((item, i) => {
              return (
                <li
                  key={i}
                  style={
                    item
                      ? { backgroundColor: "white", opacity: 1 }
                      : {
                          backgroundColor: "rgba(255, 255, 255, 0.5)",
                          opacity: 0.5,
                        }
                  }
                  onClick={(e: any) => this.handlePlay(e, i)}
                ></li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}
