import {
  Button,
  DatePicker,
  Input,
  Modal,
  Popconfirm,
  Table,
  message,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import React from "react";
import NoticeRequest from "../services/notice";

import "./index.scss";
import Add from "./component";

const { Search } = Input;
const { RangePicker } = DatePicker;
const { confirm } = Modal;

interface Props {}

interface State {
  page: number;
  size: number;
  key: string;
  startDate: string;
  endDate: string;
  dataList: object[];
  loading: boolean;
  total: number;
  selectedRowKeys: number[];
  isModalOpen: boolean;
  record: any;
}

class Notice extends React.Component<Props, State> {
  state = {
    page: 1,
    size: 10,
    key: "",
    startDate: "",
    endDate: "",
    dataList: [],
    loading: true,
    total: 0,
    selectedRowKeys: [],
    isModalOpen: false,
    record: null,
  };

  // 获取所有数据
  getAllData = () => {
    const { page, size, key, startDate, endDate } = this.state;
    const params = { page, size, key, startDate, endDate };
    NoticeRequest.getDataList(params).then((res) => {
      const data = res.data.data;
      if (res.data.code === 200) {
        this.setState({
          dataList: data.list,
        });
      }
    });
  };
  x;

  //多选数据
  rowSelection = (selectedRowKeys) => {
    this.setState({
      selectedRowKeys: selectedRowKeys,
    });
    console.log(selectedRowKeys);
  };

  // 删除数据
  deleteNotice = (id) => {
    NoticeRequest.deleteData(id).then((res) => {
      if (res.data.code === 200) {
        message.success("删除成功！");
        this.getAllData();
      }
    });
  };
  // 批量删除
  deleteBatchNotice = () => {
    const { selectedRowKeys } = this.state;
    if (selectedRowKeys.length === 0) {
      message.error("请先选择要删除得数据！");
      return;
    }
    confirm({
      title: "确定删除所选数据吗?",
      icon: <ExclamationCircleOutlined rev={undefined} />,
      okText: "确定",
      cancelText: "取消",
      onOk: () => {
        NoticeRequest.deleteBatchData(selectedRowKeys).then((res) => {
          if (res.data.code === 200) {
            message.success("批量删除成功!");
            this.setState({ selectedRowKeys: [] });
            this.getAllData();
          } else {
            message.error(res.data.msg);
          }
        });
      },
    });
  };
  // 关键字查询
  handleSearch = (key) => {
    this.setState(
      {
        key: key,
      },
      () => {
        this.getAllData();
      }
    );
    console.log(key);
  };
  // 时间刷选
  rangeChange = (value) => {
    // console.log(value);
    if (value) {
      const startDate = value[0].format("YYYY-MM-DD 00:00:00");
      const endDate = value[1].format("YYYY-MM-DD 23:59:59");
      this.setState({ startDate, endDate }, () => {
        this.getAllData();
      });
    } else {
      this.setState({ startDate: "", endDate: "" }, () => {
        this.getAllData();
      });
    }
  };

  showModal = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  changeUserVisable = (isModalOpen, record?) => {
    this.setState({
      isModalOpen: !isModalOpen,
      record: record,
      page: 1,
    });
  };
  componentDidMount(): void {
    this.getAllData();
  }
  render() {
    const { dataList, isModalOpen, record } = this.state;
    const columns = [
      {
        title: "序号",
        width: 80,
        dataIndex: "index",
        align: "center" as "center",
        key: "index",
      },
      {
        title: "版本号",
        width: 150,
        dataIndex: "noticeTitle",
        align: "center" as "center",
        key: "noticeTitle",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "创建人",
        width: 150,
        dataIndex: "createBy",
        align: "center" as "center",
        key: "createBy",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "创建时间",
        width: 120,
        align: "center" as "center",
        dataIndex: "createDate",
        key: "createDate",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },

      {
        title: "操作",
        width: 120,
        className: "handle",
        dataIndex: "handle",
        key: "handle",
        render: (text, record) => (
          <>
            <Button
              type="link"
              onClick={() => {
                this.changeUserVisable(isModalOpen, record);
              }}
            >
              修改
            </Button>
            <Popconfirm
              title="确定要删除当前记录吗?"
              onConfirm={() => {
                this.deleteNotice(record.id);
              }}
              okText="确定"
              cancelText="取消"
            >
              <Button type="link">删除</Button>
            </Popconfirm>
          </>
        ),
      },
    ];

    const reversedDataSource = dataList.map((data, index) => ({
      ...data,
      index: dataList.length - index,
    }));

    return (
      <div className="notice">
        <div className="noticeHeader">
          <div>
            <Search
              className="searchInput"
              placeholder="请输入用户名查询"
              enterButton="搜索"
              allowClear
              onSearch={this.handleSearch}
              style={{
                width: "270px",
              }}
            />
            <Button
              type="primary"
              style={{ marginLeft: "5px" }}
              onClick={this.showModal}
            >
              新增
            </Button>
          </div>
          <div>
            <RangePicker
              onChange={this.rangeChange}
              inputReadOnly={true}
              style={{ marginRight: "5px", width: 240 }}
            />

            <Button
              type="primary"
              onClick={() => this.deleteBatchNotice()}
              style={{ marginTop: "5px" }}
            >
              批量删除
            </Button>
          </div>
        </div>
        <Table
          columns={columns}
          rowSelection={{
            type: "checkbox",
            onChange: this.rowSelection,
          }}
          rowKey={(record) => record.id}
          dataSource={reversedDataSource}
          scroll={{ y: "calc(100vh - 22.5rem)" }}
        />

        {isModalOpen && (
          <Add
            addVisible={isModalOpen}
            record={record}
            changeUserVisable={this.changeUserVisable}
            getData={this.getAllData}
          />
        )}
      </div>
    );
  }
}

export default Notice;
