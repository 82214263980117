import axios from "axios";

//疫木管理接口
export default class PlagueWoodTs {
  //获取疫木管理数据列表
  static getList(params) {
    return axios.get("/file_upload_record/query", { params });
  }

  // 获取下拉框列表
  static getSelectList(params) {
    return axios.get("/common/list_region_box", {
      params: { ...params, year: sessionStorage.getItem("year") },
    });
  }

  //获取状态
  static getState() {
    return axios.get("/file_upload_record/get_select_box");
  }
  //获取年度数据
  static getYearBox() {
    return axios.get("/common/list_year");
  }
  //删除
  static DeleteState(id) {
    return axios.delete(`/file_upload_record/delete/${id}`);
  }
  //完成
  static PutState({ id, state }) {
    return axios.put("/file_upload_record/update_finsh_status", { id, state });
  }

  //获取正射图层信息
  static getOrthoImage(params, event) {
    axios.get(`/ortho_image/query_all`, { params }).then(res => {
      event(res.data);
    });
  }

  //修改小班完成状态
  static changeClassStatus(classId, year, event) {
    axios.put(`/tree_identify/update_class_status/${classId}/${year}`).then(res => {
      event(res.data);
    });
  }

  //正射信息表新增/修改
  static orthoEdit(params, event) {
    axios.put(`/ortho_image/edit`, params).then(res => {
      event(res.data);
    });
  }

  //获取正射表信息
  static getOrthoquery(params, event) {
    axios.get(`/ortho_image/query`, { params }).then(res => {
      event(res.data);
    });
  }

  //删除正射信息
  static deleteOrtho(id, event) {
    axios.delete(`/ortho_image/delete/${id}`).then(res => {
      event(res.data);
    });
  }

  //批量删除正射信息
  static batchDeleteOrtho(idList, event) {
    axios.delete(`/ortho_image/batch_delete`, idList).then(res => {
      event(res.data);
    });
  }

  //数据同步
  static synchroData(params, cancelToken) {
    return axios.get(`/tree_identify/sync_tree_identify_job`, { params, cancelToken });
  }

  // 手动调整上传数据
  static postBatchReportData(params) {
    return axios.post(`/tree_handle_record_info/batch_deal_report_data`, params);
  }

  //获取疫木数量列表
  static getTreePoint(params) {
    return axios.get(`/tree_handle_record_info/get_tree_point_num`, { params });
  }

  //调整数据疫木迁移
  static postMigrationTree(params) {
    return axios.post(`/tree_handle_record_info/migration_tree`, params);
  }

  //根据数据获取小班年份
  static getYearConfig(params) {
    return axios.get(`/configration_year/query_year_config`, { params });
  }
}
