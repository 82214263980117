import React, { Component } from "react";
import { message, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { kml_axios } from "../../utils/axios";
import "./excel.scss";

interface AssistProps {
  locationState: any;
}

interface AssistState {}

class ExcelKml extends Component<AssistProps, AssistState> {
  constructor(props: AssistProps) {
    super(props);
    this.state = {};
  }

  // 导出小班面积
  upload = (file) => {
    const data = new FormData();
    data.append("file", file);
    kml_axios.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(localStorage.getItem("token")).access_token;
    kml_axios.post("/common/from_excel_to_kml", data).then((res) => {
      if (res.status === 200) {
        if (res.data.size !== 0) {
          message.success("导出成功");
          let blob = new Blob([res.data], {
            type: "application/vnd.google-earth.kml+xml",
          }); // res就是接口返回的文件流了
          let objectUrl = URL.createObjectURL(blob);
          let a: any = document.createElement("a");
          a.download = `class.kml`;
          a.href = objectUrl;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(objectUrl);
        } else {
          message.info(
            "该文件导出的KML大小为0，请确认文件上传是否正确，若还有问题，请联系管理员"
          );
        }
      } else {
        message.error("文件上传失败，请联系管理员");
      }
    });
  };

  render() {
    const props: any = {
      listType: "picture-card",
      // data: (file) => {
      //   return {
      //     type: 2
      //   }
      // },
      beforeUpload: (file, fileList) => {
        this.upload(file);
        return false;
      },
      fileList: [],
      accept: ".xlsx,.xls",
      showUploadList: {
        showPreviewIcon: false,
        showRemoveIcon: true,
        showDownloadIcon: false,
      },
    };
    return (
      <div className={"excel-box"}>
        <div className="upload-box">
          <Upload {...props}>
            <PlusOutlined rev={undefined} />
          </Upload>
        </div>
        <div className="download-box">
          处理省平台Excel数据导出小班KML数据，请上传excel
        </div>
      </div>
    );
  }
}

export default ExcelKml;
