import { useEffect } from "react";
import OlMap from "../../../utils/olmap";
import { MapLayers } from "./mapLayer";
import PlagueWoodTs from "../../services/plagueWood";

interface Props {
  reportData: any;
  areaData: any[];
  reportTime: any[];
  mapClick: (data) => void;
}

const MapBox = ({ reportData, areaData, reportTime, mapClick }: Props) => {
  //数据年度
  const year = sessionStorage.getItem("year");

  /**
   * @description 初始化地图
   */

  const initMap = () => {
    OlMap.init("ol_map");
  };

  const flyToAddLayer = () => {
    OlMap.ToLngLat([areaData[1].lon, areaData[1].lat], 12);
    PlagueWoodTs.getYearConfig({
      areaCode: areaData[1].value,
      year,
    }).then((res) => {
      const data = res.data;
      if (data.code === 200) {
        OlMap.addClassBaseMap({
          ...MapLayers.classBaseMap,
          viewparams: `countyCode:${
            areaData[0].value
          };townCode:${areaData[1].value.slice(6)};year:${
            data.data.smallClassYear
          }`,
        });
        OlMap.addBoundariesLeary({
          ...MapLayers.boundaries,
          viewparams: `areaCode:${
            areaData[0].value
          };townCode:${areaData[1].value.slice(6)};`,
        });
        OlMap.addSuspectedClass({
          ...MapLayers.suspectedClass,
          viewparams: `town:${reportData.town};village:${reportData.village};forest:${reportData.forest};smallClass:${reportData.smallClass};year:${data.data.smallClassYear}`,
        });
      }
    });
  };

  const addClick = () => {
    OlMap.map.on("click", (e) => {
      const position = [
        e.coordinate[0].toFixed(6) * 1,
        e.coordinate[1].toFixed(6) * 1,
      ];
      mapClick(position);
    });
  };

  const addReportedClass = () => {
    OlMap.addReportedClass({
      ...MapLayers.reportedClass,
      viewparams: `startTime:${reportTime[0]} 00:00:00;endTime:${reportTime[1]} 23:59:59`,
    });
  };

  useEffect(() => {
    initMap();
    addClick();
  }, []);

  useEffect(() => {
    if (reportTime.length > 1) {
      addReportedClass();
    }
  }, [reportTime]);

  useEffect(() => {
    if (areaData.length > 1) {
      flyToAddLayer();
    }
  }, [areaData]);

  return (
    <>
      <div className="map_box" id="ol_map"></div>
      <div className="tip_box">
        <div className="tip_item">
          <div className="item_icon blue_icon"></div>
          <div className="item_title">全量小班</div>
        </div>
        <div className="tip_item">
          <div className="item_icon red_icon"></div>
          <div className="item_title">疑似小班</div>
        </div>
        <div className="tip_item">
          <div className="item_icon green_icon"></div>
          <div className="item_title">已上报小班</div>
        </div>
      </div>
    </>
  );
};

export default MapBox;
