import { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";
import EditPage from "./page";
import Home from "./home";
import Navigation from "./navigation";
import Platform from "./platform";
import VersionLog from "./platform/versionlog";
import { message } from "antd";
// RequireAuth 组件相当于一个拦截器，是否返回被拦截的组件要听他的
function RequireAuth({ children }) {
  const location = useLocation();
  const authed = localStorage.getItem("token") ? true : false;
  const tokenAccount = JSON.parse(localStorage.getItem("token")).account;
  const roleCode = JSON.parse(localStorage.getItem("token")).areaCode;
  const role = localStorage.getItem("role");
  // console.log(location);
  if (
    tokenAccount === "ls_qt_visitor" ||
    (role === "ROLE_2" &&
      roleCode !== "331102" &&
      roleCode !== "331121" &&
      roleCode !== "331125" &&
      !roleCode.includes("331127"))
  ) {
    if (authed && location.pathname.indexOf("/platform") >= 0) {
      return <Redirect to="/navigation" />;
    } else if (authed && location.pathname.indexOf("/platform") < 0) {
      return children;
    } else {
      return <Redirect to="/" />;
    }
  } else {
    return authed ? ( // 判断 localstorage 中登录状态是否为 true
      children
    ) : (
      <Redirect to="/" /> // 跳转到登录
    );
  }
}
class router extends Component {
  componentDidMount(): void {
    const judgeDeviceType =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|OperaMini/i.test(
        navigator.userAgent
      )
        ? "Mobile"
        : "PC";
    sessionStorage.setItem("type", judgeDeviceType);
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route path={"/"} exact component={Home} />
          <Route path={"/admin"} exact component={Home} />
          <RequireAuth>
            <Route path={"/editPage"} exact component={EditPage} />
            <Route path={"/navigation"} component={Navigation} />
            <Route path={"/platform"} component={Platform} />
            <Route path={"/versionlog"} component={VersionLog} />
          </RequireAuth>
        </Switch>
      </Router>
    );
  }
}

export default router;
