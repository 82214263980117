const currentAcceptData = [
  {
    title: '验收小班总数',
    total: 1600,
    totalNumber: 0,
    datatitle: 'totalNumber',
  },
  {
    title: '验收通过',
    bold: false,
    acceptNumber: 0,
    color: '#02D68A',
    total: 1200,
    datatitle: 'acceptNumber',
  },
  {
    title: '验收不合格',
    bold: false,
    color: '#FD4444',
    unAcceptNumber: 0,
    total: 400,
    datatitle: 'unAcceptNumber',
  },
];
const chineseBigNum = [
  '零',
  '一',
  '二',
  '三',
  '四',
  '五',
  '六',
  '七',
  '八',
  '九',
];
export { currentAcceptData, chineseBigNum };
