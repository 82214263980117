import axios from "axios";
import { message } from "antd";

//高德定位请求
const newAxios = axios.create({});

let token = JSON.parse(localStorage.getItem("token"));
let tokenIsRefresh = false;
const validTime = window["env"].REFRESH_TOKEN_INTERVAL * 60 * 1000;
axios.defaults.baseURL = window["env"].LoginUrl;
axios.defaults.headers.common["Authorization"] =
  localStorage.getItem("token") &&
  "Bearer " + JSON.parse(localStorage.getItem("token")).access_token;
axios.interceptors.request.use((res) => {
  beforeAxios();
  return res;
});

axios.interceptors.response.use(
  (res) => {
    // if (res.data.code !== 200) {
    // message.error(res.data.message);
    // }
    return res;
  },
  // 处理axios错误
  (err) => {
    errorHandler(err);
  }
);

const errorHandler = (err) => {
  // err && process && window["env"].NODE_ENV == 'development' && console.error(err);
  const newErr = err.toString();
  if (newErr.substring(newErr.length - 3) === "401") {
    newErr.substring(newErr.length - 3) === "401"
      ? message.error({
          content: "登陆信息已失效，请重新登陆！",
          key: "401",
          duration: 1,
        })
      : message.error({
          content: "无法连接服务器，请稍后再试",
          key: "error",
          duration: 1,
        });
    setTimeout(() => {
      localStorage.removeItem("token");
      window.location.href = "/";
    }, 1000);
  } else if (newErr.substring(newErr.length - 3) === "403") {
    message.error({ content: "权限不足！", duration: 1 });
  } else {
    // message.error('请重新登录')
    // localStorage.removeItem("token");
    // setTimeout(() => (window.location.href = "/"), 500);
  }
};

const getToken = () => {
  let token = localStorage.getItem("token");
  try {
    token = JSON.parse(token);
  } catch (e) {
  } finally {
    return eval(token);
  }
};

const beforeAxios = () => {
  if (token && !tokenIsRefresh) {
    const time = token.expires - Date.now();
    if (time < validTime) {
      tokenIsRefresh = true;
      refresh(token.refresh_token);
    }
  }
};

const refresh = (refreshToken) => {
  return axios.post("/user/refresh_token", { refreshToken }).then((r) => {
    if (r.data.data) {
      let data = r.data.data;
      data.expires = Date.now() + data.expires_in * 1000;
      const newData = JSON.parse(localStorage.getItem("token"));
      newData.access_token = data.access_token;
      newData.refresh_token = data.refresh_token;
      newData.scope = data.scope;
      newData.token_type = data.token_type;
      newData.expires_in = data.expires_in;
      newData.expires = data.expires;
      localStorage.setItem("token", JSON.stringify(newData));
      token = data;
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + data.access_token;
      acceptance_axios.defaults.headers.common["Authorization"] =
        "Bearer " + data.access_token;
      tokenIsRefresh = false;
      return Promise.resolve();
    } else {
      setTimeout(() => {
        localStorage.removeItem("token");
        window.location.href = "/";
      }, 1000);
    }
    return Promise.reject(r);
  });
};

//导出小班面积请求
const file_axios = axios.create({
  responseType: "blob",
  baseURL: window["env"].REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  },
});

//导出kml请求
const kml_axios = axios.create({
  responseType: "blob",
  baseURL: window["env"].REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/octet-stream;charset=UTF-8 ",
  },
});
//报销单
const reimbursement_axios = axios.create({
  responseType: "blob",
  baseURL: window["env"].LoginUrl,
  headers: {
    "Content-Type": "application/octet-stream;charset=UTF-8 ",
  },
});

//图片上传
const pic_axios = axios.create({
  responseType: "blob",
  baseURL: window["env"].REACT_APP_API_URL,
});

//验收
const acceptance_axios = axios.create({
  baseURL: window["env"].REACT_APP_API_URL,
});

export {
  axios,
  file_axios,
  reimbursement_axios,
  newAxios,
  kml_axios,
  acceptance_axios,
  pic_axios,
};
