import React, { Component } from "react";
import { Modal, Form, Select, Button, Upload, message, Table } from "antd";
import { InboxOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { handleImage2 } from "../../utils/handlePic";
import { downloadShuiyin } from "../../utils/watermark";

const { Option } = Select;
const { Dragger } = Upload;
const { confirm } = Modal;
const FormItem = Form.Item;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

//上传图片
export class UploadImg extends Component<any, any> {
  state = {
    uploading: false,
    fileList: [],
    parseList: [],
    // 1:采伐 2:航拍
    type: 0,
    XfileList: [],
    isUploading: false,
    result: "",
    secondVisible: false,
  };

  componentDidMount() {}

  secondOnFinish = (value) => {
    const { fileList } = this.state;
    const cityName = sessionStorage.getItem("cityName");
    const list = [...fileList];
    message.info({
      content: "图片正在解析中,请稍等一会",
      key: "img",
      duration: 0,
    });
    this.setState({
      fileList: [],
    });

    const { type, result } = value;
    handleImage2(
      list.map((item) => {
        return {
          name: item.name,
          file: item.originFileObj,
          type,
          thumbUrl: item.thumbUrl,
          cityName: cityName,
        };
      })
    ).then((res: any) => {
      console.log(res);
      let arr = res;
      arr.map((item, index) => {
        if (
          window.location.href.indexOf("check") > 0 &&
          !item.point.x &&
          !item.point.y
        ) {
          const match = item.name.match(/(-?\d+\.\d+)°N,(-?\d+\.\d+)°E/);
          item.address = "景宁";
          if (match) {
            // match[1] 匹配纬度值
            const latitude = parseFloat(match[1]);

            // match[2] 匹配经度值
            const longitude = parseFloat(match[2]);
            res[index].point.x = longitude;
            res[index].point.y = latitude;
            // 打印经纬度值
            console.log("纬度:", latitude);
            console.log("经度:", longitude);
          }
        }
      });
      const value = res.filter((item) => {
        return item.point.x && item.point.y;
      });
      res = res.map((item, index) => {
        return item.point.x && item.point.y
          ? { ...item, uid: `pic_${index}`, status: "done", url: item.thumbUrl }
          : {
              ...item,
              uid: `pic_${index}`,
              status: "error",
              url: item.thumbUrl,
            };
      });
      const promise = new Promise((resolve, reject) => {
        let newList = [];
        res.map((item) => {
          if (
            item.file.name.toUpperCase().startsWith("DJI") &&
            item.file &&
            item.point.x &&
            item.point.y
            // && !this.props.isWater
          ) {
            downloadShuiyin(item).then((data) => {
              // console.log(data)
              newList.push({ ...item, shuiYinFile: data });
              this.setState(
                {
                  fileList: newList,
                },
                () => {
                  if (newList.length === res.length) {
                    resolve(newList);
                  }
                }
              );
            });
          } else {
            newList.push({ ...item });
            this.setState(
              {
                fileList: newList,
              },
              () => {
                if (newList.length === res.length) {
                  resolve(newList);
                }
              }
            );
          }
        });
      });
      promise.then((r: any) => {
        const list = r.filter((item) => {
          return item.point.x && item.point.y;
        });
        this.setState(
          {
            result: result,
            uploading: true,
            parseList: list,
            fileList: list,
          },
          () => {
            message.success({
              content: `图片全部解析完成，共${res.length}张图片，成功${
                value.length
              }张，失败${res.length - value.length}张`,
              key: "img",
              duration: 3,
            });
          }
        );
      });
    });
  };

  onFinish = (value) => {
    confirm({
      title: "请核对信息，是否确认提交",
      icon: <ExclamationCircleOutlined rev={undefined} />,
      style: {
        position: "relative",
        marginTop: "10%",
      },
      onOk: () => {
        this.secondOnFinish(value);
      },
      onCancel() {},
    });
  };

  // 上报图片
  report = () => {
    const { parseList, result, type } = this.state;
    const { point } = this.props;
    point(parseList, result, type);
  };

  onTypeChanged = (value) => {
    this.setState({
      type: value,
    });
  };

  render() {
    const { visible, changeUploadImg } = this.props;
    const { uploading, parseList, fileList } = this.state;
    const props: any = {
      listType: "picture-card",
      // data: (file) => {
      // 	return {
      // 		type: 2
      // 	}
      // },
      fileList: fileList,
      onChange: (value) => {
        // const list = value.fileList.length > 50 ? value.fileList.slice(0, 50) : value.fileList
        this.setState({
          fileList: value.fileList,
        });
      },
      // maxCount: 50,
      multiple: true,
      beforeUpload: () => {
        return false;
      },
      accept: ".png,.jpg,.jpeg",
      showUploadList: {
        showPreviewIcon: false,
        showRemoveIcon: true,
        showDownloadIcon: false,
      },
    };

    const column = [
      {
        title: "序号",
        dataIndex: "index",
        key: "index",
        render: (text, record, index) => index + 1,
      },
      {
        title: "文件名称",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "经纬度",
        dataIndex: "lnglat",
        key: "lnglat",
        width: 200,
        render: (text, record) => (
          <span>
            {record.point.x
              ? `${record.point.x},${record.point.y}`
              : "照片无定位信息"}
          </span>
        ),
      },
      {
        title: "位置信息",
        dataIndex: "address",
        key: "address",
        width: 200,
        render: (text, record) => (
          <span>{record.point.x ? record.address : "照片无定位信息"}</span>
        ),
      },
    ];
    return (
      <Modal
        title={uploading ? "解析结果" : "上传照片"}
        visible={visible}
        footer={null}
        onCancel={() => changeUploadImg(visible)}
        className="upload_img_modal"
        width={600}
      >
        {uploading ? (
          <div>
            <Table
              dataSource={parseList}
              columns={column}
              scroll={{ y: 300 }}
              pagination={false}
            />
            <div
              style={{
                width: "60%",
                margin: "0 auto",
                display: "flex",
                justifyContent: "space-evenly",
                marginTop: "10px",
              }}
            >
              <Button type="primary" onClick={this.report}>
                上报
              </Button>
              <Button
                onClick={() => {
                  this.setState({
                    uploading: false,
                    parseList: [],
                  });
                }}
              >
                取消
              </Button>
            </div>
          </div>
        ) : (
          <Form onFinish={this.onFinish}>
            <FormItem
              name="type"
              label="照片类型"
              tooltip={
                <>
                  <div>
                    伐桩图片：1.将会解析照片的定位信息，与小班进行关联，并以图标形式展示在地图上。
                  </div>
                  <div>
                    航拍图片：1.将会解析其朝向信息，并以图标形式展示在地图上。2.会根据解析出来的相关信息为照片添加水印。3.当前已适配的无人机机型有：精灵、FPV、御二、御三、M300。
                  </div>
                </>
              }
              rules={[
                {
                  required: true,
                  message: "请选择照片类型",
                },
              ]}
              {...layout}
            >
              <Select onChange={this.onTypeChanged}>
                <Option value={1}>伐桩照片</Option>
                <Option value={2}>航拍照片</Option>
              </Select>
            </FormItem>
            <FormItem
              name="result"
              label="照片结果"
              rules={[
                {
                  required: true,
                  message: "请上传现场照片",
                },
              ]}
              {...layout}
            >
              <Select>
                <Option value={1}>合格</Option>
                <Option value={0}>不合格</Option>
              </Select>
            </FormItem>
            <FormItem
              name="file"
              label="现场照片"
              rules={[
                {
                  required: true,
                  message: "请上传现场照片",
                },
              ]}
              {...layout}
            >
              <Dragger {...props}>
                {true && (
                  <>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined rev={undefined} />
                    </p>
                    <p className="ant-upload-text">上传现场照片</p>
                    {/* <p className="ant-upload-hint">
                                                单次最多50张
                                            </p> */}
                  </>
                )}
              </Dragger>
            </FormItem>
            {/* {
                    isUploading && <div className={'progress'}>
                      <div>{fileList.length == XfileList.length ? '图片全部解析完成': `${fileList.length == 0 ? '正在准备上传' : `正在解析第${fileList.length+1}张图片` }`}</div><Progress percent={parseFloat((fileList.length / XfileList.length * 100).toFixed(2))} />
                    </div>
                  } */}
            <div className="form-button">
              <FormItem>
                <Button type="primary" htmlType="submit">
                  确认
                </Button>
                <Button onClick={() => changeUploadImg(visible)}>取消</Button>
              </FormItem>
            </div>
          </Form>
        )}
      </Modal>
    );
  }
}
