import React from 'react';
interface ProgressType {
  progress: number;
  colorLeft?: string;
  colorRight?: string;
  distance?: boolean;
  style?: React.CSSProperties;
}
export default function Progress(props: ProgressType) {
  const { progress, colorLeft, colorRight, distance, style } = props;
  return (
    <div className="Progress" style={{ ...style }}>
      <div
        className="Progress_left"
        style={{ width: `${progress}%`, background: colorLeft }}
      ></div>
      <div
        className="Progress_right"
        style={{
          width: `${100 - progress}%`,
          marginLeft:
            distance || progress === 0 || progress === 100 ? '0' : '2px',
          background: colorRight,
        }}
      ></div>
    </div>
  );
}
