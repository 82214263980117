import React, { Component } from 'react';
import { Modal, Form, Input, Button, message } from 'antd';
import MarkdownIt from 'markdown-it';
import 'react-markdown-editor-lite/lib/index.css';
import MdEditor from 'react-markdown-editor-lite';
import NoticeRequest from '../services/notice';
const mdParser = new MarkdownIt(/* Markdown-it options */);
const FormItem = Form.Item;

class Add extends Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			html: props.record ? props.record.noticeContent : '',
		};
	}
	//提交
	onFinish = (value) => {
		const { changeUserVisable, addVisible, record, getData } = this.props;
		const recordVisible = record ? true : false;
		const { noticeTitle } = value;
		const params = {
			id: recordVisible ? record.id : null,
			noticeTitle: noticeTitle,
			noticeContent: this.state.html,
		};
		NoticeRequest.putNoticeEdit(params).then((res) => {
			if (res.data.code === 200) {
				message.success(recordVisible ? '公告修改成功' : '公告新增成功');
				changeUserVisable(addVisible);
				getData();
			}
			console.log(res);
		});
	};
	handleEditorChange = ({ html, text }) => {
		// console.log(html);
		this.setState({
			html: text,
		});
		console.log(text);
	};
	render() {
		const { changeUserVisable, addVisible, record } = this.props;
		return (
			<Modal
				title={!record ? '添加公告' : '修改公告'}
				getContainer={false}
				width={800}
				visible={addVisible}
				footer={null}
				onCancel={() => {
					changeUserVisable(addVisible);
				}}
				centered={true}
				className={'user-add-box'}
			>
				<Form onFinish={this.onFinish} initialValues={record && record}>
					<FormItem
						name="noticeTitle"
						label="版本号"
						rules={[
							{
								required: true,
							},
						]}
					>
						<Input placeholder="请输入版本号" allowClear />
					</FormItem>
					<FormItem label="内容">
						<MdEditor
							style={{ height: '500px' }}
							renderHTML={(text) => mdParser.render(text)}
							onChange={this.handleEditorChange}
							defaultValue={this.state.html}
							placeholder="开始编辑......."
						/>
					</FormItem>
					<div className="form-button">
						<FormItem>
							<Button type="primary" htmlType="submit">
								完成
							</Button>
							<Button
								style={{ marginLeft: '20px' }}
								onClick={() => {
									changeUserVisable(addVisible);
								}}
							>
								取消
							</Button>
						</FormItem>
					</div>
				</Form>
			</Modal>
		);
	}
}

export default Add;
