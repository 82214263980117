import { useEffect, useState } from 'react';
import { Progress } from 'antd';
import Request from '../../../services/Manger';
import BaseCard from '../../../component/Card/BaseCard';
import back from '../../../assets/images/Nostatistics.png';

//永康市·疫情发生统计
export default function Nostatistics({ props }) {
  const { code, level, theName, year } = props;
  const [townNum, setTownNum] = useState(0); //乡镇数量
  const [hasTownNum, setHasTownNum] = useState(0); //疫点个数
  useEffect(() => {
    Request.NoclassStatic({ areaCode: code, areaLevel: level, year }, (res) => {
      if (res.code === 200) {
        setTownNum(res.data.townNum);
        setHasTownNum(res.data.hasTownNum);
      }
    });
  }, [code, level, year]);

  return (
    <BaseCard
      title={`${theName}·疫情发生统计`}
      describe={'EPIDEMIC STATISTICS'}
      height={232}
      backround={back}
    >
      {/* <Button>按钮</Button> */}
      <div className="statistics">
        <div className="chart-box">
          <Progress
            type="circle"
            className="circleProgerss"
            percent={75}
            showInfo={false}
            strokeLinecap="square"
            strokeColor="#02d281"
            strokeWidth={3}
            width={125}
          />
          <div className="townnumber">
            <p>乡镇总数</p>
            <p>{townNum}</p>
            <p>个</p>
          </div>
        </div>
        <div className="chart-describe">
          <div className="item">
            <span></span>
            <div>
              <span>疫点个数</span>
              <span>{hasTownNum}</span>
              <span>个</span>
            </div>
          </div>
          <div className="item">
            <span></span>
            <div>
              <span>未感染个数</span>
              <span>{townNum - hasTownNum}</span>
              <span>个</span>
            </div>
          </div>
        </div>
      </div>
    </BaseCard>
  );
}
