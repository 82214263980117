import axios from "axios";

//疫木管理接口
export default class CensusTs {
  //获取疫木管理数据列表
  static getList(params, cancelToken) {
    return axios.get("/tree_handle_record_info/query", { params, cancelToken });
  }

  // 获取小班列表
  static getSelectList(params) {
    return axios.get("/common/list_region_box", {
      params: { ...params, year: sessionStorage.getItem("year") },
    });
  }

  //获取状态
  static getState() {
    return axios.get("/tree_handle_record_info/get_select_box");
  }

  //视角范围加载小班数据
  static postCensusViewClass(param, event) {
    axios.post(`/decision/plague_tree_centroid_by_range`, param).then(res => {
      event(res.data);
    });
  }

  //划分小班优先级和难易程度
  static editDecison(params, event) {
    axios.put(`/decision/edit_decision`, params).then(res => {
      event(res.data);
    });
  }

  static getExportExcel(params, event) {
    axios
      .get(`/tree_handle_record_info/exportExcel`, { params, responseType: "blob" })
      .then(res => {
        event(res);
      });
  }
}
