import { Component } from "react";
import ScoreService from "../services/Score";
import "./index.scss";
import {
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Table,
  message,
} from "antd";
import FormItem from "antd/es/form/FormItem";
const { Search } = Input;
const { Option } = Select;

class Annual extends Component {
  state = {
    page: 1,
    size: 10,
    data: [],
    loading: true,
    total: 0,
    searchValue: "",
    selectedRowKeys: [],
    record: null,
    year: sessionStorage.getItem("year"),
    yearList: JSON.parse(sessionStorage.getItem("yearList")),
    title: "",
    dialogFlag: false,
    deleteList: [],
    demeritPoints: [],
    currentDemeritPointsIndex: 0,
    currentEditScoreItem: null,
  };

  componentDidMount(): void {
    this.getData();
  }

  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {}

  //获取数据
  getData = () => {
    const { page, size, searchValue } = this.state;
    const params = {
      page,
      size,
      key: searchValue,
    };
    ScoreService.getList(params).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          data: res.data.data.list,
          total: res.data.data.total,
          loading: false,
        });
      }
    });
  };

  //切换
  changePagination = (pagination) => {
    this.setState(
      {
        page: pagination.current,
        size: pagination.pageSize,
        loading: true,
      },
      this.getData
    );
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    let chooseTreeList = [];
    const { year } = this.state;
    this.setState({
      selectedRowKeys,
      deleteList: selectedRows,
    });
  };

  //搜索
  handleSearch = (value) => {
    this.setState(
      {
        searchValue: value,
      },
      () => {
        this.getData();
      }
    );
  };

  //全量新增
  allAdd = () => {
    ScoreService.putAddOffFull().then((res) => {
      res.data.code === 200
        ? message.success(res.data.data)
        : message.error(res.data.data);
    });
  };

  //新增数据
  addNewYear = () => {
    this.setState({
      title: "新增数据",
      dialogFlag: true,
      record: null,
      demeritPoints: [],
      currentDemeritPointsIndex: 0,
    });
  };

  //新增数据
  editYear = (ids) => {
    ScoreService.getListById(ids).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          title: "修改数据",
          dialogFlag: true,
          record: res.data.data,
        });
      }
    });
  };

  //删除单个
  deleteById = (ids) => {
    ScoreService.delYearById(ids).then((res) => {
      if (res.data.code === 200) {
        this.getData();
        message.success("删除成功！");
      } else {
        message.error("删除失败！");
      }
    });
  };
  //批量删除
  deleteMany = () => {
    const { selectedRowKeys } = this.state;
    ScoreService.delYearByIds(selectedRowKeys).then((res) => {
      if (res.data.code === 200) {
        this.getData();
        this.setState({
          selectedRowKeys: [],
        });
        message.success("删除成功！");
      } else {
        message.error("删除失败！");
      }
    });
  };

  //提交表单
  onFinish = (value) => {
    const { title, record } = this.state;

    const demeritPoints = {};

    for (let i = 0; i < this.state.demeritPoints.length; i++) {
      demeritPoints[this.state.demeritPoints[i].label] =
        this.state.demeritPoints[i].score;
    }
    if (title === "新增数据") {
      ScoreService.addScoreItem({ ...value, demeritPoints }).then((res) => {
        if (res.data.code === 200) {
          message.success("新增成功！");
          this.setState({
            dialogFlag: false,
          });
          this.getData();
        } else {
          message.error("新增失败！");
        }
      });
    } else {
      let params = {
        ...value,
        id: record.id,
        demeritPoints,
      };

      ScoreService.editScoreItem(params).then((res) => {
        if (res.data.code === 200) {
          message.success("修改成功！");
          this.setState({
            dialogFlag: false,
          });
          this.getData();
        } else {
          message.error("修改失败！");
        }
      });
    }
  };

  // 新增扣分标准
  addNewDemeritPoints = () => {
    this.setState(
      {
        demeritPoints: [
          ...this.state.demeritPoints,
          { id: this.state.currentDemeritPointsIndex, label: "", score: "" },
        ],
      },
      () => {
        this.setState({
          currentDemeritPointsIndex: this.state.currentDemeritPointsIndex + 1,
        });
      }
    );
  };

  // 编辑扣分标准
  editDemeritPoints = (id, flag, value) => {
    const index = this.state.demeritPoints.findIndex((item) => item.id === id);
    const tmp = [...this.state.demeritPoints];
    if (flag === "label") {
      tmp[index].label = value;
    } else {
      tmp[index].score = value;
    }
    this.setState({
      demeritPoints: [...tmp],
    });
  };

  // 删除扣分项
  delDemeritPoints = (id) => {
    const index = this.state.demeritPoints.findIndex((item) => item.id === id);
    const tmp = [...this.state.demeritPoints];
    tmp.splice(index, 1);
    this.setState({
      demeritPoints: [...tmp],
    });
  };

  render() {
    const {
      page,
      size,
      data,
      loading,
      total,
      selectedRowKeys,
      title,
      dialogFlag,
      record,
      yearList,
    } = this.state;
    const pagination = {
      total,
      current: page,
      pageSize: size,
      showQuickJumper: true,
      responsive: false,
      showSizeChanger: true,
    };
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const columns = [
      {
        title: "地区名称",
        width: 80,
        dataIndex: "countyName",
        key: "countyName",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "地区编码",
        width: 80,
        dataIndex: "countyCode",
        key: "countyCode",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "年度",
        width: 120,
        align: "center" as "center",
        dataIndex: "year",
        key: "year",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "总分",
        align: "center" as "center",
        dataIndex: "fullMark",
        width: 180,
        key: "fullMark",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "合格分数",
        align: "center" as "center",
        dataIndex: "passScore",
        width: 180,
        key: "passScore",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "运算符",
        align: "center" as "center",
        dataIndex: "operator",
        width: 180,
        key: "operator",
        render: (text, record) => <span>{text === 1 ? ">" : "≥"}</span>,
      },
      {
        title: "扣分标准",
        align: "center" as "center",
        dataIndex: "demeritPoints",
        width: 180,
        key: "demeritPoints",
        render: (text, record) => (
          <span>
            {Object.keys(text)
              .map((key) => `${key}扣${text[key]}分`)
              .join("，") || "暂无"}
          </span>
        ),
      },
      {
        title: "操作",
        width: 80,
        className: "handle",
        dataIndex: "handle",
        key: "handle",
        render: (_, record) => (
          <>
            <Button
              type="link"
              onClick={() => {
                this.setState({
                  record,
                  dialogFlag: true,
                  title: "修改数据",
                  demeritPoints: Object.keys(record.demeritPoints).map(
                    (dp, index) => {
                      return {
                        id: index,
                        label: dp,
                        score: record.demeritPoints[dp],
                      };
                    }
                  ),
                  currentDemeritPointsIndex: Object.keys(record.demeritPoints)
                    .length,
                });
              }}
            >
              修改
            </Button>
            <Popconfirm
              title="确定要删除当前记录吗?"
              onConfirm={() => {
                this.deleteById(record.id);
              }}
              okText="确定"
              cancelText="取消"
            >
              <Button type="link">删除</Button>
            </Popconfirm>
          </>
        ),
      },
    ];
    return (
      <div className="score">
        <div className="alHeader">
          <Button
            style={{ marginRight: "10px" }}
            type="primary"
            onClick={this.addNewYear}
          >
            新增数据
          </Button>
          <Popconfirm
            title="确定要删除当前记录吗?"
            onConfirm={this.deleteMany}
            okText="确定"
            cancelText="取消"
          >
            <Button style={{ marginRight: "10px" }} type="primary">
              批量删除
            </Button>
          </Popconfirm>
          {/* <Popconfirm
            title="确定要全量新增吗?"
            onConfirm={this.allAdd}
            okText="确定"
            cancelText="取消"
          >
            <Button style={{ marginRight: "10px" }} type="primary">
              全量新增
            </Button>
          </Popconfirm> */}
          <Search
            className="searchInput"
            placeholder="请输入信息查询"
            enterButton="搜索"
            onSearch={this.handleSearch}
            style={{
              width: "270px",
            }}
          />
          <Modal
            title={title}
            visible={dialogFlag}
            footer={null}
            destroyOnClose={true}
            onCancel={() => this.setState({ dialogFlag: false })}
            width={400}
          >
            <Form onFinish={this.onFinish} initialValues={record}>
              <FormItem name="countyName" label="地区名称">
                <Input disabled={title === "修改数据" ? true : false} />
              </FormItem>
              <FormItem name="countyCode" label="地区编码">
                <Input disabled={title === "修改数据" ? true : false} />
              </FormItem>
              <FormItem name="year" label="年度">
                <Select
                  placeholder="年度"
                  style={{ width: 176, marginLeft: "30px" }}
                  allowClear
                  disabled={title === "修改数据" ? true : false}
                >
                  {yearList.map((item) => (
                    <Option value={item.key} key={item.key}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem name="fullMark" label="总分">
                <Input style={{ width: 176, marginLeft: "30px" }} />
              </FormItem>
              <FormItem name="passScore" label="合格分数">
                <Input style={{ width: 176 }} />
              </FormItem>
              <FormItem name="operator" label="运算符">
                <Select style={{ width: 176, marginLeft: "13px" }}>
                  {["≥", ">"].map((op, index) => (
                    <Option value={index} key={index}>
                      {op}
                    </Option>
                  ))}
                </Select>
              </FormItem>
              <div className="score-demerit-points">
                <p>扣分标准</p>
                <div className="score-dp-header">
                  <div>
                    <span>扣分项</span>
                    <span>分数</span>
                  </div>
                  <Button type="primary" onClick={this.addNewDemeritPoints}>
                    +
                  </Button>
                </div>
                {this.state.demeritPoints.map((dp) => (
                  <div key={dp.id} className="score-dp-item">
                    <div>
                      <Input
                        type="text"
                        value={dp.label}
                        onChange={(e) =>
                          this.editDemeritPoints(dp.id, "label", e.target.value)
                        }
                      />
                      <Input
                        type="text"
                        value={dp.score}
                        onChange={(e) =>
                          this.editDemeritPoints(dp.id, "value", e.target.value)
                        }
                      />
                    </div>
                    <Button
                      type="primary"
                      onClick={() => this.delDemeritPoints(dp.id)}
                    >
                      -
                    </Button>
                  </div>
                ))}
              </div>

              <div className="form-button">
                <FormItem>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginRight: "10px" }}
                  >
                    确定
                  </Button>
                  <Button
                    onClick={() =>
                      this.setState({ dialogFlag: false, record: null })
                    }
                  >
                    取消
                  </Button>
                </FormItem>
              </div>
            </Form>
          </Modal>
        </div>
        <Table
          dataSource={data}
          rowKey={(record) => record.id}
          columns={columns}
          loading={loading}
          rowSelection={rowSelection}
          onChange={(pagination) => this.changePagination(pagination)}
          pagination={pagination}
          scroll={{ y: "calc(100vh - 23.5rem)" }}
        />
      </div>
    );
  }
}

export default Annual;
