import { acceptance_axios as axios } from "../../utils/axios";

//风萧萧兮易水寒  壮士一去兮不复还！
export default class HouseAbTs {
  // 根据条件分页查询房前屋后疫木堆放情况检测表
  static getHouseTree(params) {
    return axios.get("/house_tree_stack_situation/query", { params });
  }

  //房前屋后疫木堆放情况检测表
  static putHouseTree(params) {
    return axios.put("/house_tree_stack_situation/edit", params);
  }

  static postPic(params) {
    return axios.post("/house_tree_stack_situation/upload_image", params);
  }

  //删除记录
  static delHouseById(id) {
    return axios.delete(`/house_tree_stack_situation/delete/${id}`);
  }

  //批量删除
  static delHouseByIds(params) {
    return axios.post("/house_tree_stack_situation/batch_delete", params);
  }

  //生成报告
  static postReport(params) {
    return axios.post("/house_tree_stack_situation/get_report", params);
  }
}
