import {
  Button,
  Form,
  InputNumber,
  Select,
  DatePicker,
  Upload,
  Switch,
  TreeSelect,
  Result,
} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";

import "./exportReportFormNew.scss";

interface TProps {
  onFinish: (values: any) => void;
  defaultTown: string;
  townList: Array<{ code: string; name: string }>;
  townChange: (value: string) => void;
  onCancel: () => void;
  changeAnnexList: (id: number | null, key: "add" | "del") => void;
  isChooseAllAnnex: boolean;
  isChooseAllAnnexChange: (value: boolean) => void;
  annexList: Array<{
    id: number;
    dataTree: Array<any>;
    villageCode: string;
    picCondition: Array<string>;
  }>;
  annexInfo: {
    villageList: Array<{ code: string; name: string }>;
    forestListTree: Array<any>;
  };
  onVillageOrForestChange: (value, key) => void;
  dateChange: (value) => void;
  clearAllAnnex: () => void;
  reportDate: {
    startTime: string;
    endTime: string;
  };
  villageUniqueChange: (villageCode: string, annexId: number) => void;
  updateAnnex: (
    id: number,
    flag: "changeVillage" | "changePicCondition",
    data: {
      villageCode?: string;
      picCondition?: Array<string>;
    }
  ) => void;
  handleExportFormSubmit: () => void;
  fileList: Array<any>;
  fileListChange: (file) => void;
}

function ExportReportFormNew({
  onFinish,
  defaultTown,
  townList,
  townChange,
  onCancel,
  changeAnnexList,
  isChooseAllAnnex,
  isChooseAllAnnexChange,
  annexList,
  annexInfo,
  onVillageOrForestChange,
  dateChange,
  clearAllAnnex,
  reportDate,
  villageUniqueChange,
  updateAnnex,
  handleExportFormSubmit,
  fileList,
  fileListChange,
}: TProps) {
  return (
    <div className="ysfaerf-new">
      <div className="ysfaerfn-header">
        <h2>导出报告</h2>
      </div>
      <Form onFinish={handleExportFormSubmit}>
        <div className="ysfaerfn-form">
          <div className="ysfaerfn-form-left">
            <h3>导出范围及验收单上传</h3>
            <Form.Item
              label="乡镇/街道"
              required
              labelCol={{ span: 24 }}
              name="townCode"
              initialValue={!!defaultTown ? defaultTown : "0"}
            >
              <Select
                onChange={(value) => {
                  clearAllAnnex();
                  townChange(value);
                }}
              >
                <Select.Option value={"0"}>全部</Select.Option>
                {townList.map((t) => {
                  return (
                    <Select.Option key={t.code} value={t.code}>
                      {t.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label="验收轮次"
              required
              labelCol={{ span: 24 }}
              name="frequency"
            >
              <InputNumber
                placeholder="请填写整数"
                required
                min={1}
                style={{
                  width: "50%",
                }}
              />
            </Form.Item>
            <Form.Item
              label="起止时间"
              required
              labelCol={{ span: 24 }}
              name="time"
            >
              <DatePicker.RangePicker
                onChange={(value) => {
                  clearAllAnnex();
                  dateChange(value);
                }}
              />
            </Form.Item>
            <Form.Item name="file">
              <div
                style={{
                  marginTop: "10px",
                }}
              >
                <p>验收单照片</p>
                <Upload
                  listType="picture-card"
                  action={window["env"].REACT_APP_API_HOST + "/upload"}
                  accept=".png,.jpg,.jpeg"
                  showUploadList={{
                    showPreviewIcon: false,
                    showRemoveIcon: true,
                    showDownloadIcon: false,
                  }}
                  beforeUpload={() => false}
                  fileList={fileList}
                  onChange={fileListChange}
                >
                  <div className="ysfaerfnfl-upload">
                    <PlusOutlined rev={undefined} />
                    <div>上传</div>
                  </div>
                </Upload>
              </div>
            </Form.Item>
          </div>
          <div className="ysfaerfn-form-right">
            <div className="ysfaerfnfr-header">
              <h3>附件/验收照片</h3>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  type="primary"
                  style={{
                    marginRight: "20px",
                  }}
                  onClick={() => changeAnnexList(null, "add")}
                  disabled={
                    isChooseAllAnnex ||
                    !(reportDate.startTime && reportDate.endTime)
                  }
                >
                  添加
                </Button>
                <span>选择全部：</span>
                <Switch
                  defaultChecked={isChooseAllAnnex}
                  onChange={(value) => isChooseAllAnnexChange(value)}
                  disabled={annexList.length !== 0}
                ></Switch>
              </div>
            </div>
            <div className="ysfaerfnfr-body">
              {isChooseAllAnnex ? (
                <div className="ysfaerfnfrb-select-all">
                  <Result
                    status="success"
                    title="您已全选（乡镇/街道）全部验收照片"
                  />
                </div>
              ) : annexList.length === 0 ? (
                <div className="ysfaerfnfrb-select-none">
                  <span>请</span>
                  <span
                    style={{
                      fontWeight: 700,
                      color: "#20AD84",
                    }}
                  >
                    选择起始时间后
                  </span>
                  <span>点击右上角添加按钮选择需要导出的报告数据</span>
                </div>
              ) : (
                annexList.map((item) => (
                  <div className="ysfaerfnfrb-item" key={item.id}>
                    <div className="ysfaerfnfrbi-header">
                      <Select
                        placeholder="请选择行政村"
                        style={{ width: "30%" }}
                        onChange={(value) => {
                          updateAnnex(item.id, "changeVillage", {
                            villageCode: value,
                          });
                          villageUniqueChange(value, item.id);
                          onVillageOrForestChange(value, 1);
                        }}
                        value={item.villageCode}
                      >
                        {annexInfo.villageList.map((o) => (
                          <Select.Option key={o.code} value={o.code}>
                            {o.name}
                          </Select.Option>
                        ))}
                      </Select>
                      <Button
                        danger
                        onClick={() => changeAnnexList(item.id, "del")}
                      >
                        <DeleteOutlined rev={undefined} />
                        删除
                      </Button>
                    </div>
                    <TreeSelect
                      placeholder="请添加小班号"
                      treeData={item.dataTree}
                      treeCheckable
                      showCheckedStrategy={TreeSelect.SHOW_CHILD}
                      onChange={(value) =>
                        updateAnnex(item.id, "changePicCondition", {
                          picCondition: value,
                        })
                      }
                      value={item.picCondition}
                      placement="topLeft"
                    ></TreeSelect>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
        <div className="ysfaerfn-footer">
          <Button type="primary" htmlType="submit">
            确认
          </Button>
          <Button onClick={onCancel}>取消</Button>
        </div>
      </Form>
    </div>
  );
}

export default ExportReportFormNew;
