import { useState, useEffect } from "react";
import { Form, Select, Upload, message, Button, Progress } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import Acceptance from "../../platform/services/Acceptance";
import axios from "axios";

type TProps = {
  isInspection: number;
  closeModal: Function;
  flayTo: Function;
  setFlyPoint: (data) => void;
};

let socket = undefined;

function UploadImgNew({
  isInspection,
  closeModal,
  flayTo,
  setFlyPoint,
}: TProps) {
  const [uploadInfo, setUploadInfo] = useState({
    picType: 2,
    result: 1,
    year: sessionStorage.getItem("year"),
    isInspection: isInspection,
  });
  const userId = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token")).id
    : undefined;
  const [fileList, setFileList] = useState([]);
  const [uploadState, setUploadState] = useState<boolean>(false);

  const [uploadNum, setUploadNum] = useState(0);
  const [handInfo, setHandInfo] = useState({
    total: -1,
    current: 0,
  });
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);

  const [errMessage, setErrMessage] = useState(false);
  const [errList, setErrList] = useState([]);

  const handleTypeOrResChange = (flag: string, vlaue: number) => {
    setUploadInfo({ ...uploadInfo, [flag]: vlaue });
  };

  const handleUpload = () => {
    const formData = new FormData();

    fileList.forEach((file) => {
      formData.append("files", file);
    });
    setIsRequestLoading(true);
    message.info("文件正在上传中", 5);

    Acceptance.uploadSmartAddNew({
      params: uploadInfo,
      data: formData,
    }).then((res) => {
      setIsRequestLoading(false);
      if (res.data.code === 200) {
        setUploadState(true);
        return message.success("上传成功");
      }
      message.error("上传失败");
    });
  };

  const getUploadInfo = () => {
    // socket = new WebSocket(
    //   window["env"].REACT_APP_WS_SMART_ADD +
    //     JSON.parse(localStorage.getItem("token")).id
    // );

    socket = new EventSource(
      `${window["env"].REACT_APP_WS_SMART_ADD}?userId=${
        JSON.parse(localStorage.getItem("token")).id
      }`,
      {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).access_token,
        },
      } as any
    );

    // 连接一旦建立，就会触发open事件
    socket.addEventListener(
      "open",
      function (e) {
        console.log("建立连接");
      },
      false
    );

    // 客户端收到服务器发来的数据
    socket.addEventListener("message", (e) => {
      let msg = JSON.parse(e.data);
      setHandInfo({
        total: msg.uploadFileNum,
        current: msg.completeFileNum,
      });

      if (msg.errorPicList) {
        message.success(
          `处理完成,共处理${msg.successFileNum}张,成功${msg.successFileNum}张,失败${msg.errorPicList.length}张`
        );
        if (msg.errorPicList.length === 0) {
          closeModal();
          flayTo([msg.point.x, msg.point.y], 18);
        } else {
          setErrMessage(true);
          setErrList(msg.errorPicList);
          setFlyPoint([msg.point.x, msg.point.y]);
        }

        return socket.close();
      }
    });
  };

  const closeSSE = () => {
    let newAxios = axios.create({
      baseURL: window["env"].REACT_APP_API_URL,
      headers: {
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("token")).access_token,
      },
    });

    newAxios.delete(
      `/sse/break_connect?userId=${
        JSON.parse(localStorage.getItem("token")).id
      }`
    );
  };

  useEffect(() => {
    setUploadNum(fileList.length);
  }, [fileList.length]);

  useEffect(() => {
    uploadState && getUploadInfo();
  }, [uploadState]);

  useEffect(() => {
    return () => {
      closeSSE();
      socket && socket.close();
    };
  }, []);

  const uploadProps = {
    beforeUpload: (file, fileList) => {
      setFileList(fileList);

      return false;
    },
  };

  return (
    <div style={{ padding: "36px", paddingBottom: 0 }}>
      {uploadState ? (
        <div
          style={{
            paddingBottom: "20px",
          }}
        >
          {errMessage ? (
            <>
              <h3>错误图片:{errList.length}张</h3>
              <div
                style={{
                  width: "100%",
                  maxHeight: "300px",
                  overflow: "hidden auto",
                }}
              >
                {errList.map((item) => {
                  return <div key={item}>{item}</div>;
                })}
              </div>
            </>
          ) : (
            <>
              <h3>处理进度：</h3>
              <p>
                {handInfo.total === -1
                  ? "正在获取处理进度..."
                  : `共 ${handInfo.total} 张，已处理 ${handInfo.current} 张`}
              </p>
              <Progress
                percent={Math.round((handInfo.current / handInfo.total) * 100)}
              />
            </>
          )}
        </div>
      ) : (
        <Form onFinish={handleUpload} disabled={isRequestLoading}>
          <Form.Item label="照片类型">
            <Select
              value={uploadInfo.picType}
              onChange={(vlaue: number) =>
                handleTypeOrResChange("picType", vlaue)
              }
            >
              {[
                { key: 1, value: "伐桩照片" },
                { key: 2, value: "航拍照片" },
              ].map((item) => (
                <Select.Option key={item.key} value={item.key}>
                  {item.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="照片结果">
            <Select
              value={uploadInfo.result}
              onChange={(vlaue: number) =>
                handleTypeOrResChange("result", vlaue)
              }
            >
              {[
                { key: 0, value: "不合格" },
                { key: 1, value: "合格" },
              ].map((item) => (
                <Select.Option key={item.key} value={item.key}>
                  {item.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="现场照片">
            <Upload.Dragger
              disabled={
                uploadInfo.isInspection === undefined || userId === undefined
              }
              multiple
              maxCount={200}
              accept=".png,.jpg,.jpeg"
              showUploadList={false}
              fileList={fileList}
              {...uploadProps}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined rev={undefined} />
              </p>
              <p className="ant-upload-text">点击或拖动以添加现场照片</p>
              <p className="ant-upload-text">
                单次添加上限200张, 当前已选择
                <>
                  <span style={{ fontWeight: "bold" }}>
                    &nbsp;{uploadNum}&nbsp;
                  </span>
                </>
                张
              </p>
            </Upload.Dragger>
          </Form.Item>
          <Form.Item style={{ textAlign: "center" }}>
            <Button
              style={{
                width: "40%",
              }}
              type="primary"
              htmlType="submit"
            >
              上传
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
}

export default UploadImgNew;
