import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Select,
  Space,
} from "antd";
import CustomTabs from "./customTabs";
import { useEffect, useState } from "react";
import { resultList } from "../data";
import moment from "moment";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import Acceptance from "../../services/Acceptance";
import ModalUpload from "./modalUpload";
import SituationItem from "./situationItem";

interface Props {
  editData: any;
  onClose: () => void;
}

const EditModal = ({ editData, onClose }: Props) => {
  const [form] = Form.useForm();

  const [tabsActiveKey, setTabsActiveKey] = useState(1);

  const [dataSource, setDataSource] = useState(null);

  const [situationOption, setSituationOption] = useState([]);
  //合格伐桩图片信息
  const [passPicCutList, setPassPicCutList] = useState([]);
  //合格航拍图片信息
  const [passPicDroneList, setPassPicDroneList] = useState([]);
  //不合格伐桩图片信息
  const [unPassPicCutList, setUnPassPicCutList] = useState([]);
  //不合格航拍图片信息
  const [unPassPicDroneList, setUnPassPicDroneList] = useState([]);

  const [loading, setLoading] = useState(false);

  const Qualified = {
    1: passPicCutList,
    2: passPicDroneList,
  };

  const Unqualified = {
    1: unPassPicCutList,
    2: unPassPicDroneList,
  };

  const changeTabsActiveKey = (value: number) => {
    setTabsActiveKey(value);
  };

  const getSituationOption = () => {
    Acceptance.getSubScore({
      year: editData.year,
      countyCode: editData.countyCode,
    }).then((res) => {
      if (res.data.code === 200) {
        setSituationOption(
          res.data.data
            ? res.data.data.map((item) => {
                return {
                  value: item,
                  label: item,
                };
              })
            : []
        );
      }
    });
  };

  const getNewData = (data) => {
    return data.map((item, i) => {
      return {
        uid: `-${i}`,
        status: "done",
        url: item,
      };
    });
  };

  const getImageList = () => {
    const newPassPicCut = getNewData(editData.passPicCutList);
    const newPassPicDrone = getNewData(editData.passPicDroneList);
    const newUnPassPicCut = getNewData(editData.unPassPicCutList);
    const newUnPassPicDrone = getNewData(editData.unPassPicDroneList);
    setPassPicCutList(newPassPicCut);
    setPassPicDroneList(newPassPicDrone);
    setUnPassPicCutList(newUnPassPicCut);
    setUnPassPicDroneList(newUnPassPicDrone);
  };

  const onFinish = (value) => {
    let situation = {};
    value.situationList &&
      value.situationList.map((e) => {
        return (situation[e.first] = e.last);
      });
    const newParams = {
      id: editData.id,
      result: value.result,
      isInspection: editData.isInspection,
      countyCode: editData.countyCode,
      year: editData.year,
      frequency: editData.frequency,
      editDate: moment(value.createDate).format("YYYY-MM-DD HH:mm:ss"),
      demeritPoints: JSON.stringify(situation),
    };
    const formData = new FormData();

    passPicCutList.forEach((file) => {
      if (file.type) {
        formData.append("passPicCutList", file);
      }
    });
    passPicDroneList.forEach((file) => {
      if (file.type) {
        formData.append("passPicDroneList", file);
      }
    });
    unPassPicCutList.forEach((file) => {
      if (file.type) {
        formData.append("unPassPicCutList", file);
      }
    });
    unPassPicDroneList.forEach((file) => {
      if (file.type) {
        formData.append("unPassPicDroneList", file);
      }
    });
    setLoading(true);
    Acceptance.modifyAcceptanceHistory({
      params: newParams,
      data: formData,
    }).then((res) => {
      setLoading(false);
      if (res.data.code === 200) {
        message.success("修改成功");
        onClose();
      } else {
        message.warning(res.data.msg);
      }
    });
  };

  const changeImageList = (data, type) => {
    if (tabsActiveKey === 1) {
      if (type === 1) {
        setPassPicCutList(data);
      } else {
        setUnPassPicCutList(data);
      }
    } else {
      if (type === 1) {
        setPassPicDroneList(data);
      } else {
        setUnPassPicDroneList(data);
      }
    }
  };

  useEffect(() => {
    getSituationOption();
    getImageList();
    setDataSource({
      situationList: editData.demeritPoints
        ? Object.entries(editData.demeritPoints).map(([key, value]) => ({
            first: key,
            last: value,
          }))
        : [],
      ...editData,
      createDate: moment(editData.createDate, "YYYY-MM-DD HH:mm:ss"),
    });
  }, []);

  return (
    <Modal
      className="edit_history_modal"
      title="修改"
      visible={true}
      centered={true}
      width={1440}
      footer={
        <div className="edit_history_modal_foot">
          <Button
            type="primary"
            loading={loading}
            onClick={() => {
              form.submit();
            }}
          >
            确认
          </Button>
          <Button onClick={onClose}>取消</Button>
        </div>
      }
      onCancel={onClose}
    >
      <div className="edit_history_modal_body">
        <div className="modal_body_l">
          {dataSource && (
            <Form
              form={form}
              initialValues={dataSource}
              layout="vertical"
              onFinish={onFinish}
            >
              <Form.Item name="result" label="验收结果">
                <Select options={resultList}></Select>
              </Form.Item>
              <Form.Item name="createDate" label="验收时间">
                <DatePicker
                  style={{ width: "100%" }}
                  format="YYYY-MM-DD HH:mm:ss"
                  allowClear={false}
                  showTime={true}
                />
              </Form.Item>
              <div style={{ marginBottom: "8px" }}>描述</div>
              <Form.List name="situationList">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      {fields.map((data) => {
                        return (
                          <SituationItem
                            form={form}
                            data={data}
                            situationOption={[
                              ...situationOption,
                              { value: "其他", label: "其他" },
                            ]}
                            onRemove={remove}
                          />
                        );
                      })}

                      <div className="add_button" onClick={() => add()}>
                        <PlusOutlined rev={undefined} />
                        <span>新增描述</span>
                      </div>
                    </>
                  );
                }}
              </Form.List>
            </Form>
          )}
        </div>
        <div className="modal_body_r">
          <div className="right_tabs_box">
            <CustomTabs
              activeKey={tabsActiveKey}
              changeKey={changeTabsActiveKey}
            />
          </div>
          <div className="right_image_box">
            <div className="image_box_l">
              <ModalUpload
                type={1}
                dataSource={Qualified[tabsActiveKey]}
                changeDataSource={(data) => changeImageList(data, 1)}
              />
            </div>
            <div className="image_box_r">
              <ModalUpload
                type={2}
                dataSource={Unqualified[tabsActiveKey]}
                changeDataSource={(data) => changeImageList(data, 2)}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditModal;
