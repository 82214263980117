import React, { Component } from 'react';
import './index.scss';
import { Button, Popconfirm, Table, Input, notification, Modal, Form, Tooltip } from 'antd';
import ToolTs from '../services/tool';
import Add from './component';
import FormItem from 'antd/es/form/FormItem';
const { Search } = Input;

const areaLevel = {
	1: '市级',
	2: '区县',
	3: '乡镇',
};

const role = {
	ROLE_0: '超级管理员',
	ROLE_1: '平台使用人员',
	ROLE_2: '游客账号',
	ROLE_3: '日志监管账号',
	ROLE_4: '删除验收图片专用账号',
};

class User extends Component<any> {
	state = {
		page: 1,
		size: 10,
		dataList: [],
		loading: true,
		total: 0,
		searchValue: '',
		record: null,
		addVisible: false,
		year: sessionStorage.getItem('year'),
		yearList: JSON.parse(sessionStorage.getItem('yearList')),
		resetPasswordFlag: false,
		resetId: null,
	};

	componentDidMount(): void {
		this.getData();
	}

	getData = () => {
		const { page, size, searchValue } = this.state;
		const params = {
			page,
			size,
			key: searchValue,
		};

		ToolTs.getUserList(params, (res) => {
			if (res.code === 200) {
				this.setState({
					dataList: res.data.list,
					loading: false,
					total: res.data.total,
				});
			} else {
				notification.error({
					message: `获取失败`,
					description: res.data.msg,
					duration: 2.5,
				});
			}
		});
	};

	//删除
	deleteData = (record) => {
		ToolTs.deleteUser(record.id, (res) => {
			if (res.code === 200) {
				notification.success({
					message: `删除成功`,
					description: '',
					duration: 2.5,
				});
				this.getData();
			} else {
				notification.error({
					message: `删除失败`,
					description: '',
					duration: 2.5,
				});
			}
		});
	};

	//切换
	changePagination = (pagination) => {
		this.setState(
			{
				page: pagination.current,
				size: pagination.pageSize,
				loading: true,
			},
			this.getData
		);
	};

	//搜索
	handleSearch = (value) => {
		this.setState(
			{
				searchValue: value,
			},
			() => {
				this.getData();
			}
		);
	};

	changeUserVisable = (addVisible, record?) => {
		this.setState({
			addVisible: !addVisible,
			record: record,
			page: 1,
		});
	};

	// 重置密码
	resetPW = (record) => {
		this.setState({
			resetId: record.id,
			resetPasswordFlag: true
		})
		// Modal.confirm({
		// 	content: `确认要重置${record.account}账号的密码吗？`,
		// 	onOk: () => {
		// 		ToolTs.resetUser({ id: record.id, password: "Lsysf123456@" }, res => {
		// 			res.code === 200
		// 				?
		// 				notification.success({
		// 					message: `重置密码成功`,
		// 					description: '',
		// 					duration: 2,
		// 				})
		// 				:
		// 				notification.error({
		// 					message: `重置失败`,
		// 					description: '',
		// 					duration: 1,
		// 				})
		// 		})
		// 	}
		// })
	}

	resetPWNow = (value) => {
		const { resetId } = this.state

		ToolTs.resetUser({ id: resetId, password: value.password }, res => {
			res.code === 200
				?
				(notification.success({
					message: `重置密码成功`,
					description: '',
					duration: 2,
				})
				)
				:
				notification.error({
					message: `重置失败`,
					description: '',
					duration: 1,
				})
			this.setState({
				resetPasswordFlag: false
			})
		})
	}

	hideModal = () => {
		this.setState({
			resetPasswordFlag: false,
			resetId: null,
		});
	};

	render() {
		const { page, size, dataList, loading, total, record, addVisible, resetPasswordFlag } =
			this.state;
		const columns = [
			{
				title: '序号',
				width: 80,
				dataIndex: 'id',
				align: 'center' as 'center',
				key: 'id',
				render: (text, record, index) => (page - 1) * 10 + (index + 1),
			},
			{
				title: '用户账号',
				width: 150,
				dataIndex: 'account',
				align: 'center' as 'center',
				key: 'account',
				render: (text, record) => <span>{text || '暂无'}</span>,
			},
			{
				title: '用户名',
				width: 120,
				align: 'center' as 'center',
				dataIndex: 'name',
				key: 'name',
				render: (text, record) => <span>{text || '暂无'}</span>,
			},
			{
				title: '联系方式',
				align: 'center' as 'center',
				dataIndex: 'phone',
				width: 150,
				key: 'phone',
				render: (text, record) => <span>{text || '暂无'}</span>,
			},
			{
				title: '身份权限',
				dataIndex: 'role',
				align: 'center' as 'center',
				width: 100,
				key: 'role',
				ellipsis: true,
				render: (text, record) => <span>{role[text] || '暂无'}</span>,
			},
			{
				title: '所属区域',
				dataIndex: 'areaName',
				align: 'center' as 'center',
				width: 120,
				key: 'areaCode',
				render: (text, record) => <span>{text || '暂无'}</span>,
			},
			{
				title: '区域级别',
				dataIndex: 'areaLevel',
				align: 'center' as 'center',
				width: 100,
				key: 'areaLevel',
				render: (text, record) => <span>{areaLevel[text] || '暂无'}</span>,
			},
			{
				title: '操作',
				width: 130,
				className: 'handle',
				dataIndex: 'handle',
				key: 'handle',
				align: 'center' as 'center',
				render: (text, record) => (
					<>
						<Button
							type="link"
							onClick={() => {
								this.changeUserVisable(addVisible, record);
							}}
						>
							修改
						</Button>
						<Popconfirm
							title="确定要删除当前记录吗?"
							onConfirm={() => {
								this.deleteData(record);
							}}
							okText="确定"
							cancelText="取消"
						>
							<Button type="link">删除</Button>
						</Popconfirm>
						<Tooltip title="重置密码">
							<Button type="link" onClick={() => this.resetPW(record)} >重置</Button>
						</Tooltip>

					</>
				),
			},
		];
		const pagination = {
			total,
			current: page,
			pageSize: size,
			showQuickJumper: true,
			responsive: false,
			showSizeChanger: true,
		};

		return (
			<>
				<div className="userData">
					<div className="userHeader">
						<Search
							className="searchInput"
							placeholder="请输入用户名查询"
							enterButton="搜索"
							allowClear
							onSearch={this.handleSearch}
							style={{
								width: '270px',
							}}
						/>
						<Button
							type="primary"
							onClick={() => {
								this.changeUserVisable(addVisible, null);
							}}
						>
							添加用户
						</Button>
					</div>
					<Table
						dataSource={dataList}
						rowKey={(record) => record.id}
						columns={columns}
						loading={loading}
						onChange={(pagination) => this.changePagination(pagination)}
						pagination={pagination}
						scroll={{ y: 'calc(100vh - 22.5rem)' }}
					/>
					{addVisible && (
						<Add
							addVisible={addVisible}
							record={record}
							changeUserVisable={this.changeUserVisable}
							getData={this.getData}
						/>
					)}
				</div>
				<Modal
					title="重置密码"
					visible={resetPasswordFlag}
					footer={null}
					destroyOnClose={true}
					onCancel={this.hideModal}
				>
					<Form
						onFinish={this.resetPWNow}
					>
						<FormItem
							label="重新输入密码"
							name="password"
							rules={[{ required: true, message: '请输入密码！' }]}
						>
							<Input />
						</FormItem>
						<div className="form-button">
							<FormItem>
								<Button type="primary" htmlType="submit" style={{ marginRight: '10px' }}>
									确认
								</Button>
								<Button onClick={this.hideModal}>取消</Button>
							</FormItem>
						</div>
					</Form>
				</Modal>
			</>
		);
	}
}

export default User;
