import React, { Component, useEffect, useState } from "react";
import {
  Alert,
  Button,
  DatePicker,
  Modal,
  Radio,
  Image,
  Select,
  Spin,
  Table,
  message,
} from "antd";
import LogRequest from "../services/Logrecord";
import userSvg from "../../assets/icons/icon/svg/user.svg";
import WaySvg from "../../assets/icons/icon/svg/way.svg";
import Acceptance from "../services/Acceptance";
import Workmap from "./map";
import charts from "../../utils/echarts";
import moment from "moment";
import ToolTs from "../services/tool";
import TrajectoryMap from "./trajectoryMap";

const { Option } = Select;
const { RangePicker } = DatePicker;

// 报销单核对
export const PayPage = ({ visible, onClose, record }) => {
  const [classCheck, setCheck] = useState(false);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [list_real, setListReal] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [totalHours, setTotalHour] = useState(0);
  const [hoursList, setHourList] = useState([]);
  const [wayData, setWayData] = useState({
    kil: null,
    minutes: null,
    startAddress: null,
  });
  const [kilCheck, setKilCheck] = useState(false);
  const [roadGuidModel, setRoadGuidModel] = useState({
    roadStepList: null,
    totalDistance: 0,
    totalDuration: 0,
  });

  useEffect(() => {
    getPayData();
  }, []);

  const getPayData = () => {
    const { classNum, userName, startDate, endDate } = record;
    const params = {
      classNum,
      userName,
      startDate,
      endDate,
      year: sessionStorage.getItem("year"),
    };
    LogRequest.getReimburse(params).then((res) => {
      if (res.data.code === 200) {
        const {
          kil,
          minutes,
          startAddress,
          reimburseDTOS,
          picRealTimeDTOS,
          classCheck,
          validWorkTimeInfoRDTOS,
          totalApplicationOverTimeHours,
          staffAttendanceRDTOS,
          kilCheck,
          roadGuidModel,
        } = res.data.data;
        setList(reimburseDTOS);
        setAttendanceData(staffAttendanceRDTOS);
        setListReal(picRealTimeDTOS);
        setCheck(classCheck);
        setTotalHour(totalApplicationOverTimeHours);
        setHourList(validWorkTimeInfoRDTOS);
        setWayData({ kil, minutes, startAddress });
        setLoading(false);
        setKilCheck(kilCheck);
        setRoadGuidModel(roadGuidModel);
      } else {
        message.error(res.data.msg);
      }
    });
  };

  const columns_1 = [
    {
      title: "起止地点",
      dataIndex: "location",
      key: "location",
      align: "center" as "center",
      width: "120px",
    },
    {
      title: "交通工具",
      dataIndex: "vehicle",
      key: "vehicle",
      align: "center" as "center",
      width: "120px",
    },
    {
      title: "小班数/采集面积",
      dataIndex: "smallClasses",
      key: "smallClasses",
      align: "center" as "center",
      width: "120px",
      render: (text) => (
        <span style={{ color: classCheck ? "green" : "red" }}>{text}</span>
      ),
    },
    {
      title: "公里（km）",
      dataIndex: "kil",
      key: "kil",
      align: "center" as "center",
      width: "120px",
      render: (text) => (
        <span style={{ color: kilCheck ? "green" : "red" }}>{text}</span>
      ),
    },
  ];
  const columns_2 = [
    {
      title: "拍摄地点",
      dataIndex: "newAddress",
      key: "newAddress",
      align: "center" as "center",
      width: "150px",
      ellipsis: true,
    },
    {
      title: "开始时间",
      dataIndex: "startTime",
      key: "startTime",
      align: "center" as "center",
      width: "120px",
    },
    {
      title: "结束时间",
      dataIndex: "endTime",
      key: "endTime",
      align: "center" as "center",
      width: "120px",
    },
    {
      title: "航拍照片数(张)",
      dataIndex: "picNum",
      key: "picNum",
      align: "center" as "center",
      width: "120px",
    },
  ];
  const columns_3 = [
    {
      title: "加班次数",
      dataIndex: "index",
      key: "index",
      align: "center" as "center",
      width: "80px",
      render: (text, record, index) => index + 1,
    },
    {
      title: "开始时间",
      dataIndex: "startDate",
      key: "startDate",
      align: "center" as "center",
      width: "150px",
      ellipsis: true,
    },
    {
      title: "结束时间",
      dataIndex: "endDate",
      key: "endDate",
      align: "center" as "center",
      width: "150px",
      ellipsis: true,
    },
  ];
  const columns_4 = [
    {
      title: "考勤类型",
      dataIndex: "attendanceType",
      key: "attendanceType",
      align: "center" as "center",
    },
    {
      title: "打卡结果",
      dataIndex: "clockResult",
      key: "clockResult",
      align: "center" as "center",
    },
    {
      title: "打卡时间",
      dataIndex: "clockTime",
      key: "clockTime",
      align: "center" as "center",
    },
    {
      title: "打卡类型",
      dataIndex: "clockType",
      key: "clockType",
      align: "center" as "center",
    },
    {
      title: "照片",
      dataIndex: "clockPhoto",
      key: "clockPhoto",
      align: "center" as "center",
      render: (src) =>
        src ? (
          <Image
            src={src}
            width={120}
            height={120}
            alt=""
            style={{
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        ) : (
          <></>
        ),
    },
  ];

  return (
    <Modal
      footer={null}
      title={
        <div>
          <span>报销单核对：</span>
          <span>{record?.userName}</span>
          <span style={{ marginLeft: "12px" }}>
            {record.startDate.slice(0, 4)}年{record.startDate.slice(5, 7)}月
            {record.startDate.slice(8, 10)}日
          </span>
          <Alert
            style={{
              width: "auto",
              position: "absolute",
              right: "61px",
              top: "13px",
              height: "27px",
              borderRadius: "3px",
              cursor: "default",
              userSelect: "none",
            }}
            message={
              Boolean(totalHours) ? `加班时长：${totalHours}小时` : "未加班"
            }
            type={Boolean(totalHours) ? "error" : "success"}
          />
        </div>
      }
      visible={visible}
      width={800}
      destroyOnClose
      className="paypage-modal"
      onCancel={() => onClose(visible)}
      getContainer={false}
    >
      <div className="modal_content">
        {Boolean(totalHours) && (
          <div className="pay-table">
            <div className="title">钉钉申请加班详情：</div>
            <Table
              dataSource={hoursList}
              columns={columns_3}
              style={{ height: "150px" }}
              scroll={{ y: "100px" }}
              pagination={false}
              // rowKey={(record: any) => record.id}
            />
          </div>
        )}
        <div className="pay-table">
          <div className="title">
            报销单中数据：{list.length === 0 && "暂无数据"}
          </div>
          {list.length !== 0 && (
            <Table
              dataSource={list}
              columns={columns_1}
              scroll={{ y: "300px" }}
              pagination={false}
              // rowKey={(record: any) => record.id}
            />
          )}
        </div>
        {roadGuidModel.roadStepList && (
          <div className="pay-table">
            <div className="title">
              总里程:{roadGuidModel.totalDistance.toFixed(2)}千米
            </div>
            <TrajectoryMap dataSource={roadGuidModel.roadStepList} />
          </div>
        )}
        {Boolean(wayData.kil) && (
          <div className="way_box">
            <div className="way_title">里程和耗时:</div>
            <div className="way_text" style={{ marginLeft: "5px" }}>
              {wayData.startAddress}
            </div>
            <div className="way_gc">
              <p>路程（{wayData.kil}km）</p>
              <img src={WaySvg} draggable="false" />
              <p>耗时（{wayData.minutes}h）</p>
            </div>
            <div className="way_text">第一张照片拍摄点</div>
          </div>
        )}
        <div className="pay-table">
          <div className="title">当日考勤数据：</div>
          <Table
            dataSource={attendanceData}
            columns={columns_4}
            loading={loading}
            scroll={{ y: "300px" }}
            pagination={false}
            // rowKey={(record: any) => record.id}
          />
        </div>
        <div className="pay-table">
          <div className="title">平台实际数据：</div>
          <Table
            dataSource={list_real}
            columns={columns_2}
            loading={loading}
            scroll={{ y: "300px" }}
            pagination={false}
            // rowKey={(record: any) => record.id}
          />
        </div>
      </div>
    </Modal>
  );
};

// 工作详情、柱状图表--查看数据
export const ModalTable = ({ visible, onClose, record }) => {
  const result = {
    "0": "验收不通过",
    "1": "验收通过",
    "-1": "待审核",
    "2": "未处理",
    "3": "未纳入考核",
  };
  const resultColor = {
    "1": "green",
    "0": "red",
    "-1": "#000000",
  };
  const [load, setLoad] = useState(true);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [year, setYear] = useState(sessionStorage.getItem("year"));

  useEffect(() => {
    getData_work();
  }, [page, year]);

  const getData_work = () => {
    const { userName, cityCode, startDate, endDate } = record;
    const params = {
      key: userName,
      cityCode,
      // countyCode,
      // townCode,
      startTime: startDate,
      endTime: endDate,
      page,
      size: 10,
      year,
    };
    Acceptance.getList(params).then((res) => {
      if (res.data.code === 200) {
        setList(res.data.data.list);
        setLoad(false);
        setTotal(res.data.data.total);
      } else {
        message.error(res.data.msg);
      }
    });
  };

  const changePagination = (pagination) => {
    setPage(pagination.current);
    setLoad(true);
  };

  const pagination = {
    total,
    current: page,
    pageSize: 10,
    showSizeChanger: false,
  };
  const columns = [
    {
      title: "区县",
      dataIndex: "countyName",
      key: "countyName",
      align: "center" as "center",
      width: "120px",
    },
    {
      title: "行政村",
      dataIndex: "villageName",
      key: "villageName",
      align: "center" as "center",
      width: "120px",
    },
    {
      title: "林班",
      dataIndex: "forestName",
      key: "forestName",
      align: "center" as "center",
      width: "120px",
    },
    {
      title: "小班号",
      dataIndex: "smallClass",
      key: "smallClass",
      align: "center" as "center",
      width: "100px",
    },
    {
      title: "验收次数",
      dataIndex: "frequency",
      key: "frequency",
      align: "center" as "center",
      width: "100px",
    },
    {
      title: "上传时间",
      dataIndex: "updateDate",
      key: "updateDate",
      align: "center" as "center",
      width: "150px",
      render: (text) => <div>{text ?? "----"}</div>,
    },
    {
      title: "验收结果",
      dataIndex: "result",
      key: "result",
      align: "center" as "center",
      width: "100px",
      render: (text) => (
        <span style={{ color: resultColor[text] }}>{result[text]}</span>
      ),
    },
    {
      title: "验收情况",
      dataIndex: "situation",
      key: "situation",
      align: "center" as "center",
      ellipsis: true,
      width: "200px",
      render: (text) => <div>{text ?? "----"}</div>,
    },
  ];
  return (
    <Modal
      footer={null}
      title={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ fontWeight: "bolder" }}>操作数据</div>
            <div
              style={{
                marginLeft: "10px",
                display: "flex",
                height: "20px",
                alignItems: "center",
              }}
            >
              <img src={userSvg} />
              <div style={{ fontSize: "14px", color: "rgba(0,0,0,0.3)" }}>
                {record.userName}
              </div>
            </div>
          </div>
          <div style={{ marginRight: "80px" }}>
            <Select
              value={year}
              onChange={(year) => {
                setYear(year);
              }}
            >
              <Option key="2023-2024" vlaue="2023-2024">
                2023-2024
              </Option>
              <Option key="2022-2023" vlaue="2022-2023">
                2022-2023
              </Option>
              <Option key="2021-2022" vlaue="2021-2022">
                2021-2022
              </Option>
            </Select>
          </div>
        </div>
      }
      visible={visible}
      width={1400}
      destroyOnClose
      className="work-modal"
      onCancel={() => onClose(visible)}
      getContainer={false}
    >
      <Table
        dataSource={list}
        columns={columns}
        rowKey={(record: any) => record.id}
        loading={load}
        onChange={(pagination) => changePagination(pagination)}
        pagination={pagination}
      />
    </Modal>
  );
};

// 工作详情模块
export class Work_details extends Component<any> {
  pageSize = 10;
  state = {
    countyList: [],
    loading: true,
    page: 1,
    total: 0,
    dataList_work: [],
    payVisible: false,
    modalVivible: false,
    recordData: null,
    workson_list: [],
    son_loading: true,
    son_show: true,
    expendName: "",
    totalBusinessTripTime: 0,
    totalOutWorkTime: 0,
  };

  changePagination = (pagination, filters?) => {
    this.setState({
      page: pagination.current,
    });
  };

  componentDidMount(): void {
    this.getData_work();
  }

  componentDidUpdate(prevProps, prevState, snapshot): void {
    const { startDate, endDate, keyWord } = this.props;
    const isUpate =
      prevProps.startDate !== startDate ||
      prevProps.endDate !== endDate ||
      prevProps.keyWord !== keyWord;
    if (isUpate) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.getData_work();
        }
      );
    }
  }

  // 工作详情数据
  getData_work = () => {
    const { cityCode, startDate, endDate, keyWord } = this.props;
    const params = {
      startDate,
      endDate,
      key: keyWord,
      cityCode,
      cityName: sessionStorage.getItem("cityName"),
    };
    startDate &&
      LogRequest.getWorkStati(params).then((res) => {
        if (res.data.code === 200) {
          const new_list = res.data.data.map((item, index) => {
            return { key: index, ...item };
          });
          this.setState({
            dataList_work: new_list,
            loading: false,
          });
        } else {
          message.error(res.data.msg);
          this.setState({
            loading: false,
          });
        }
      });
  };

  // 获取子表数据
  getWorkSonList = (expanded, record) => {
    expanded &&
      this.setState({
        expendName: record.userName,
        workson_list: [],
        son_loading: true,
        son_show: true,
      });
    const { cityCode, startDate, endDate, millisecondNum } = this.props;
    if (millisecondNum / 3600 / 24 > 30) {
      expanded && message.info("查看工作详情的时间选择范围不可超过三十天");
      this.setState({ son_loading: false, son_show: false });
      return;
    }
    const params = {
      key: record.userName,
      startDate,
      endDate,
      cityCode,
      cityName: sessionStorage.getItem("cityName"),
    };
    expanded &&
      LogRequest.getWorkSonList(params).then((res) => {
        if (res.data.code === 200) {
          this.setState({
            son_loading: false,
            workson_list: res.data.data.workloadDataInfoRDTOS,
            totalBusinessTripTime: res.data.data.totalBusinessTripTime,
            totalOutWorkTime: res.data.data.totalOutWorkTime,
          });
        } else {
          message.error(res.data.msg);
        }
      });

    !expanded &&
      this.setState({ workson_list: [], son_loading: true, expendName: "" });
  };

  // 子表数据展开项
  expandedRowRender = (fatherrecord, index, indent, expanded) => {
    const { workson_list, son_loading, modalVivible, payVisible } = this.state;
    const columns = [
      {
        title: "已完成小班数",
        dataIndex: "classNum",
        key: "classNum",
        align: "center" as "center",
        width: "120px",
      },
      {
        title: "航拍小班数",
        dataIndex: "droneClassNum",
        key: "droneClassNum",
        align: "center" as "center",
        width: "120px",
      },
      {
        title: "照片数量",
        dataIndex: "picNum",
        key: "picNum",
        align: "center" as "center",
        width: "120px",
      },
      {
        title: "砍伐照片数量",
        dataIndex: "cutPicNum",
        key: "cutPicNum",
        align: "center" as "center",
        width: "120px",
      },
      {
        title: "工作日期",
        dataIndex: "workDate",
        key: "workDate",
        align: "center" as "center",
        width: "150px",
      },
      {
        title: "是否加班",
        dataIndex: "isOverTimeWork",
        key: "isOverTimeWork",
        align: "center" as "center",
        width: "100px",
        render: (text) => (
          <Alert
            style={{
              width: "80px",
              position: "absolute",
              right: "26px",
              top: "20px",
              height: "27px",
              borderRadius: "3px",
              cursor: "default",
              userSelect: "none",
            }}
            message={Boolean(text) ? `加班` : "未加班"}
            type={Boolean(text) ? "error" : "success"}
          />
        ),
      },
      {
        title: "出差时间：\n" + this.state.totalBusinessTripTime + "天",
        dataIndex: "businessTripTime",
        key: "businessTripTime",
        align: "center" as "center",
        width: "100px",
        render: (text) => <div>{text ? text : "暂无"}</div>,
      },
      {
        title: "外出时间：\n" + this.state.totalOutWorkTime + "天",
        dataIndex: "outWorkTime",
        key: "outworkTime",
        align: "center" as "center",
        width: "100px",
        render: (text) => <div>{text ? text : "暂无"}</div>,
      },
      {
        title: "详情数据",
        dataIndex: "operation",
        key: "operation",
        align: "center" as "center",
        width: "100px",
        render: (text, record) => (
          <Button
            type="link"
            onClick={() => this.checkWork(modalVivible, record)}
          >
            查看
          </Button>
        ),
      },
      {
        title: "报销凭据",
        dataIndex: "operation",
        key: "operation",
        align: "center" as "center",
        width: "150px",
        render: (text, record) => (
          <Button
            type="link"
            onClick={() => this.checkPaypage(payVisible, record)}
          >
            查看
          </Button>
        ),
      },
    ];
    return (
      <>
        {this.state.son_show && (
          <Table
            loading={son_loading}
            columns={columns}
            dataSource={workson_list}
            pagination={false}
            sticky={true}
            rowKey={(record: any) => record.userName}
          />
        )}
      </>
    );
  };

  //详情验收数据
  checkWork = (visible, record?) => {
    const one_day = Boolean(record?.workDate);
    const { cityCode, startDate, endDate } = this.props;
    const { modalVivible } = this.state;
    const newData = {
      ...record,
      cityCode,
      startDate,
      endDate,
    };

    const one_day_data = {
      ...record,
      cityCode,
      startDate: record?.workDate + " 00:00:00",
      endDate: record?.workDate + " 23:59:59",
    };
    this.changeModalVisible(modalVivible);
    this.setState({ recordData: one_day ? one_day_data : newData });
  };

  // 报销单数据
  checkPaypage = (visible, record?) => {
    message.destroy();
    const { cityCode, startDate, endDate, millisecondNum } = this.props;
    const { payVisible } = this.state;
    const one_day = Boolean(record?.workDate);

    const newData = {
      ...record,
      cityCode,
      startDate,
      endDate,
    };

    const one_day_data = {
      ...record,
      cityCode,
      startDate: record?.workDate + " 00:00:00",
      endDate: record?.workDate + " 23:59:59",
    };

    if (one_day) {
      this.changePayVisible(payVisible);
      this.setState({ recordData: one_day_data });
    } else {
      if (!startDate || !endDate) {
        message.info("请先选择日期再进行查看");
        return;
      }
      if (millisecondNum === 0) {
        this.changePayVisible(payVisible);
        this.setState({ recordData: newData });
      } else {
        message.info("报销凭证只限查看一天内容，请您重新选择日期");
      }
    }
  };

  changeModalVisible = (visible) => {
    this.setState({ modalVivible: !visible });
  };

  changePayVisible = (visible) => {
    this.setState({ payVisible: !visible });
  };

  render() {
    const {
      expendName,
      total,
      page,
      modalVivible,
      payVisible,
      loading,
      dataList_work,
      recordData,
    } = this.state;
    const { startDate, endDate } = this.props;

    const columns_work = [
      {
        title: "姓名",
        dataIndex: "userName",
        key: "userName",
        align: "center" as "center",
        width: "100px",
        render: (text) => <span style={{ color: "#1665b7" }}>{text}</span>,
      },
      {
        title: "已完成小班数",
        dataIndex: "classNum",
        key: "classNum",
        align: "center" as "center",
        width: "140px",
      },
      {
        title: "航拍小班数",
        dataIndex: "droneClassNum",
        key: "droneClassNum",
        align: "center" as "center",
        width: "100px",
      },
      {
        title: "照片总数",
        dataIndex: "picNum",
        key: "picNum",
        align: "center" as "center",
        width: "150px",
      },
      {
        title: "砍伐照片总数",
        dataIndex: "cutPicNum",
        key: "cutPicNum",
        align: "center" as "center",
        width: "150px",
      },
      {
        title: "平均每日操作小班数",
        dataIndex: "avgClassNum",
        key: "avgClassNum",
        align: "center" as "center",
        width: "150px",
      },
      {
        title: "平均每日砍伐照片数",
        dataIndex: "avgCutPicNum",
        key: "avgCutPicNum",
        align: "center" as "center",
        width: "150px",
      },
      {
        title: "平均每日上传照片数",
        dataIndex: "avgPicNum",
        key: "avgPicNum",
        align: "center" as "center",
        width: "150px",
      },
      {
        title: "详情数据",
        dataIndex: "operation",
        key: "operation",
        align: "center" as "center",
        width: "100px",
        render: (text, record) => (
          <Button
            type="link"
            onClick={() => this.checkWork(modalVivible, record)}
          >
            查看
          </Button>
        ),
      },
    ];
    const pagination = {
      total,
      current: page,
      pageSize: this.pageSize,
      showSizeChanger: false,
    };

    return (
      <div className="work-table">
        <Table
          dataSource={dataList_work}
          columns={columns_work}
          expandable={{
            onExpand: this.getWorkSonList,
            expandedRowRender: this.expandedRowRender,
            rowExpandable: () => startDate && endDate,
            expandedRowKeys: [expendName],
          }}
          rowKey={(record: any) => record.userName}
          loading={loading}
          onChange={(pagination) => this.changePagination(pagination)}
          pagination={pagination}
          scroll={{ y: "calc(100vh - 23.5rem)" }}
        />
        {modalVivible && (
          <ModalTable
            visible={modalVivible}
            onClose={this.changeModalVisible}
            record={recordData}
          />
        )}
        {payVisible && (
          <PayPage
            visible={payVisible}
            onClose={this.changePayVisible}
            record={recordData}
          />
        )}
      </div>
    );
  }
}

// 柱状图表
export class Work_barCharts extends Component<any> {
  state = {
    recordData: null,
    mapVisible: false,
  };

  componentDidUpdate(prevProps, prevState, snapshot): void {
    const { startDate, endDate } = this.props;
    const isUpate =
      prevProps.startDate !== startDate || prevProps.endDate !== endDate;
    if (isUpate) {
      const bar_0 = charts.getChart("barchart")?.dom;
      bar_0 && bar_0.off("click");
      bar_0 && charts.clear("barchart");
      setTimeout(() => {
        charts.init("barchart", "bar");
        this.getBarData();
        const bar_1 = charts.getChart("barchart").dom;
        bar_1.on("click", this.barClick);
      });
    }
  }

  componentDidMount(): void {
    setTimeout(() => {
      charts.init("barchart", "bar");
      this.getBarData();
      const bar_1 = charts.getChart("barchart").dom;
      bar_1.on("click", this.barClick);
    });
  }

  //柱状图点击事件
  barClick = (params) => {
    const { seriesName, name } = params;
    const { cityCode, startDate, endDate } = this.props;
    const record = {
      userName: name,
      cityCode,
      startDate,
      endDate,
    };
    if (seriesName === "小班总数") {
      this.setState({ mapVisible: true, recordData: record });
    }
  };

  // 柱状图数据
  getBarData = () => {
    message.loading({ duration: 0, content: "正在获取图表数据，请稍等..." });
    const { cityCode, startDate, endDate } = this.props;
    const params = {
      cityCode,
      cityName: sessionStorage.getItem("cityName"),
      startDate,
      endDate,
    };
    let unpass_class = [];
    let pass_class = [];
    let all_class = [];
    let series_pic = [];
    LogRequest.getWorkbar(params).then((res) => {
      if (res.data.code === 200) {
        const data = res.data.data;
        if (data?.nameList.length !== 0) {
          data.logInfoStatisticBarRDTOS.forEach((item) => {
            all_class.push(item.failClassNum + item.passClassNum);
            pass_class.push(item.passClassNum);
            unpass_class.push(item.failClassNum);
            series_pic.push(item.picNum);
          });
          const option = {
            ...charts.baroption,
            xAxis: {
              ...charts.baroption.xAxis,
              data: data.nameList,
            },
            series: [
              {
                ...charts.baroption.series[0],
                data: pass_class,
              },
              {
                ...charts.baroption.series[1],
                data: unpass_class,
              },
              {
                ...charts.baroption.series[2],
                data: all_class,
              },
              {
                ...charts.baroption.series[3],
                data: series_pic,
              },
            ],
          };
          message.destroy();
          charts.setOption("barchart", option);
        } else {
          message.destroy();
          message.info("当前时间暂无图表数据");
          charts.setOption("barchart", charts.baroption);
        }
      } else {
        message.info(res.data.msg);
      }
    });
  };

  render() {
    const { recordData, mapVisible } = this.state;
    return (
      <>
        <div id="barchart" className="barchart"></div>
        {mapVisible && (
          <Modal
            footer={null}
            title={
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ fontWeight: "bolder" }}>操作数据</div>
                <div
                  style={{
                    marginLeft: "10px",
                    display: "flex",
                    height: "20px",
                    alignItems: "center",
                  }}
                >
                  <img src={userSvg} />
                  <div style={{ fontSize: "14px", color: "rgba(0,0,0,0.3)" }}>
                    {recordData.userName}
                  </div>
                </div>
              </div>
            }
            visible={mapVisible}
            width={1400}
            destroyOnClose
            className="work-modal"
            onCancel={() => this.setState({ mapVisible: false })}
            getContainer={false}
          >
            <Workmap record={recordData} />
          </Modal>
        )}
      </>
    );
  }
}

// 操作历史数据
type resultCode =
  | 200
  | 201
  | 202
  | 204
  | 400
  | 401
  | 403
  | 404
  | 406
  | 410
  | 422
  | 500
  | 502
  | 503
  | 504;
const resultColor = {
  200: "green",
  300: "orange",
  500: "red",
  401: "red",
  403: "red",
  404: "red",
};

export class Work_historyData extends Component<any> {
  pageSize = 10;
  state = {
    dataList_history: [],
    page: 1,
    loading: true,
    total: 0,
  };

  componentDidMount(): void {
    this.getData_history();
  }

  componentDidUpdate(prevProps, prevState, snapshot): void {
    const { startDate, endDate, keyWord } = this.props;

    const isUpate =
      prevProps.startDate !== startDate ||
      prevProps.endDate !== endDate ||
      prevProps.keyWord !== keyWord;

    if (isUpate) {
      this.setState(
        {
          loading: true,
        },
        () => {
          this.getData_history();
        }
      );
    }
  }

  //分页
  changePagination = (pagination) => {
    this.setState(
      {
        page: pagination.current,
        loading: true,
      },
      () => {
        this.getData_history();
      }
    );
  };

  // 操作历史数据
  getData_history = () => {
    const { page } = this.state;
    const { endDate, startDate, keyWord } = this.props;
    const param = {
      page,
      size: this.pageSize,
      startDate,
      endDate,
      key: keyWord,
    };
    LogRequest.getLogList(param).then((res) => {
      if (res.data.code === 200) {
        if (res.data.data) {
          const { list, total } = res.data.data;
          this.setState({
            total,
            dataList_history: list,
            loading: false,
          });
        }
      } else {
        message.error(res.data.msg);
        this.setState({ loading: false });
      }
    });
  };

  render() {
    const { dataList_history, loading, total, page } = this.state;
    const columns_history = [
      {
        title: "功能模块",
        dataIndex: "module",
        key: "module",
        align: "center" as "center",
        width: "150px",
        // filterMultiple: true,
        // filters: this.loginModuleEnum
      },
      {
        title: "操作描述",
        dataIndex: "message",
        key: "message",
        align: "center" as "center",
        width: "350px",
      },
      {
        title: "用户ID",
        dataIndex: "userName",
        key: "userName",
        align: "center" as "center",
        width: "150px",
        render: (text) => <span style={{ color: "#1665b7" }}>{text}</span>,
      },
      {
        title: "请求IP",
        dataIndex: "ip",
        key: "ip",
        align: "center" as "center",
        width: "150px",
      },
      {
        title: "创建时间",
        dataIndex: "createTime",
        key: "createTime",
        align: "center" as "center",
        width: "200px",
      },
      {
        title: "返回结果",
        dataIndex: "code",
        key: "code",
        align: "center" as "center",
        width: "100px",
        render: (text) => (
          <span style={{ color: resultColor[text as resultCode] }}>{text}</span>
        ),
        // filterMultiple: true,
        // filters: this.loginCodeEnum
      },
    ];
    const pagination = {
      total,
      current: page,
      pageSize: this.pageSize,
      showSizeChanger: false,
    };

    return (
      <div className="log-table">
        <Table
          dataSource={dataList_history}
          columns={columns_history}
          rowKey={(record: any) => record.id}
          loading={loading}
          onChange={(pagination) => this.changePagination(pagination)}
          pagination={pagination}
        />
      </div>
    );
  }
}

//操作比列模块
const markPoint = {
  data: [
    {
      type: "max", //最大值
    },
    {
      type: "min", //最小值
    },
  ],
};
const seriesData = {
  name: "",
  type: "line",
  data: [0, 5, 7, 4, 6, 10, 20],
  markPoint: markPoint,
};
const pieSeries = {
  name: "使用比例",
  type: "pie",
  radius: "50%",
  data: [
    { value: 0, name: "跟踪验收" },
    { value: 0, name: "监理检查" },
  ],
  // stillShowZeroSum: true,
  // showEmptyCircle: true,
  emphasis: {
    itemStyle: {
      shadowBlur: 10,
      shadowOffsetX: 0,
      shadowColor: "rgba(0, 0, 0, 0.5)",
    },
  },
};
const pieLegend = {
  orient: "vertical",
  left: "right",
};
export class Work_scale extends Component<any> {
  state = {
    nameList: [],
    seriesList: [],
    lineOption: {},
  };

  componentDidMount(): void {
    setTimeout(() => {
      charts.init("line", "line");
      charts.init("fan", "fan");
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot?): void {
    const { dateValue, userValue } = this.props;
    if (prevProps.dateValue !== dateValue) {
      this.getPicDataByTime();
    }
    if (prevProps.userValue !== userValue) {
      this.userChange();
    }
  }

  // 操作比例__折线图
  getLineData = (params) => {
    LogRequest.getLineList(params).then((res) => {
      if (res.data.code === 200) {
        const data = res.data.data;
        if (data?.length !== 0) {
          const { logInfoStatisticRDTOS, hourList } = data;
          const newhourList = hourList.map((item) => item + ":00");
          let seriesList = [];
          let nameList = [];
          logInfoStatisticRDTOS.map((item, index) => {
            const data = {
              ...seriesData,
              name: item.userName,
              data: item.numberList,
            };
            seriesList.push(data);
            nameList.push({ name: item.userName });
            if (logInfoStatisticRDTOS.length === index + 1) {
              const lineOption = {
                ...charts.lineoption,
                xAxis: {
                  ...charts.lineoption.xAxis,
                  data: newhourList,
                },
                series: seriesList,
              };
              charts.setOption("line", lineOption);
              this.setState({
                nameList,
                seriesList,
                lineOption,
              });
            }
          });
        } else {
          message.info("暂无数据");
          charts.setOption("line", charts.lineoption);
        }
      } else {
        this.setState({
          nameList: [],
          seriesList: [],
          lineOption: charts.lineoption,
        });
        charts.setOption("line", charts.lineoption);
        message.destroy();
        message.error(res.data.msg);
      }
    });
  };

  //根据时间获取图表数据
  getPicDataByTime = () => {
    const { dateValue } = this.props;
    const startDate = dateValue.format("YYYY-MM-DD 00:00:00");
    const endDate = dateValue.format("YYYY-MM-DD 23:59:59");
    const params = {
      startDate,
      endDate,
    };
    this.getLineData(params);
    this.getPieData(params);
  };

  //用户切换
  userChange = () => {
    const { userValue } = this.props;
    const { seriesList, lineOption } = this.state;
    if (userValue !== "all") {
      const newSeries = seriesList.filter((item) => {
        return userValue === item.name;
      });
      const optionDate = {
        ...lineOption,
        series: newSeries,
      };
      charts.setOption("line", optionDate);
    } else {
      charts.setOption("line", lineOption);
    }
  };

  //操作比例__饼图数据获取
  getPieData = (params) => {
    LogRequest.getPieList(params).then((res) => {
      if (res.data.code === 200) {
        const data = res.data.data;
        if (data) {
          const series = [
            {
              ...pieSeries,
              data,
            },
          ];
          const pieOption = {
            ...charts.fanoption,
            legend: pieLegend,
            series,
          };
          charts.setOption("fan", pieOption);
        } else {
          charts.setOption("fan", charts.fanoption);
        }
      } else {
        message.error(res.data.msg);
      }
    });
  };

  render() {
    return (
      <div className="log-echarts">
        <div className="left">
          <div className="table" id="line"></div>
        </div>
        <div className="right">
          <div className="table" id="fan"></div>
        </div>
      </div>
    );
  }
}

// 工时图标
export class Work_overtime extends Component<any> {
  options = [
    { label: "全部", value: "all" },
    { label: "个人", value: "self" },
  ];
  state = {
    dateValue: null,
    radioValue: "all",
    startDate: "",
    endDate: "",
    userName: "",
    // millisecondNum: 0,
    userList: [],
    cutPicList: [],
    dronePicList: [],
    isModalOpen: false,
    isNew: false,
    loading: true,
  };

  componentDidMount(): void {
    this.getTodayDate();
    setTimeout(() => {
      charts.init("custom_chart", "custom");
      const bar_1 = charts.getChart("custom_chart").dom;
      bar_1.on("click", this.customClick);
    });
  }

  //柱状图点击
  customClick = (params) => {
    // console.log("11", params);
    if (params.seriesName === "外业工作") {
      const obj = {
        date: params.value[4],
        key: params.name,
      };
      Acceptance.getSomeBPic(obj).then((res) => {
        res.data.data.cutPicList.length > 0 &&
          res.data.data.cutPicList.forEach((e, i) => {
            res.data.data.cutPicList[i].time = e.time.split(" ")[1];
          });
        res.data.data.dronePicList.length > 0 &&
          res.data.data.dronePicList.forEach((e, i) => {
            res.data.data.dronePicList[i].time = e.time.split(" ")[1];
          });
        if (res.data.code === 200) {
          this.setState({
            isModalOpen: true,
            cutPicList: res.data.data.cutPicList || [],
            dronePicList: res.data.data.dronePicList || [],
          });
        } else {
          message.error("图片获取失败，请联系管理员！");
        }
      });
    }
  };

  getTodayDate = () => {
    const yesterday = new Date();
    yesterday.setDate(new Date().getDate() - 1);
    const date = moment(yesterday, "YYYY-MM-DD");
    this.setState({ dateValue: date }, () => {
      this.getAllWork();
    });
  };

  // 所有人工作时间
  getAllWork = () => {
    charts.setOption("custom_chart", charts.customoption);
    const { cityCode } = this.props;
    const { dateValue, isNew } = this.state;
    const nowDay = dateValue.format("YYYY-MM-DD");
    const params = {
      startDate: dateValue.format("YYYY-MM-DD 00:00:00"),
      endDate: dateValue.format("YYYY-MM-DD 23:59:59"),
      cityCode,
      isNew,
      cityName: sessionStorage.getItem("cityName"),
    };
    LogRequest.getAllWork_date(params).then((res) => {
      if (res.data.code === 200) {
        const { innerTime, outerTime, nameList, overTime } = res.data.data;
        if (nameList.length === 0) {
          message.destroy();
          message.info("当前时间没有工作数据");
          this.setState({ loading: false });
          return;
        }
        (window as any).echarts_allNum = nameList.length;
        const innerData = innerTime.map((item, index) => {
          return {
            name: item.userName,
            type: "inner",
            value: [
              item.index,
              item.startTime,
              item.endTime,
              {
                classNum: item.classNum,
                title: "验收完成小班：",
                unit: "个",
              },

              {
                censusClassNum: item.censusClassNum,
                title: "普查完成小班：",
                unit: "个",
              },
              nowDay,
            ],
          };
        });
        const outerData = outerTime.map((item, index) => {
          return {
            name: item.userName,
            type: "outer",
            itemStyle: { color: item.color },
            value: [
              item.index,
              item.startTime,
              item.endTime,
              {
                title: "照片数量：",
                unit: "张",
                picNum: item.picNum ?? 0,
                newAddress: item.newAddress && item.newAddress.slice(6),
                duration: item.duration,
                kil: item.kil,
              },
              nowDay,
            ],
          };
        });
        const overData = overTime.map((item) => {
          return {
            name: item.userName,
            type: "over",
            value: [
              item.index,
              item.startTime,
              item.endTime,
              {
                title: item.isApplication ? "申请时间" : "打卡时间",
              },
              nowDay,
            ],
          };
        });
        const option = {
          ...charts.customoption,
          yAxis: {
            ...charts.customoption.yAxis,
            data: nameList,
            name: "员工（小班数）",
          },
          xAxis: {
            ...charts.customoption.xAxis,
            axisPointer: {
              value: dateValue.format("YYYY-MM-DD 09:00:00"),
              ...charts.customoption.xAxis.axisPointer,
            },
          },
          series: [
            {
              ...charts.customoption.series[0],
              data: innerData,
            },
            {
              ...charts.customoption.series[1],
              data: outerData,
            },
            {
              ...charts.customoption.series[2],
              data: overData,
            },
          ],
        };
        // console.log(innerData, outerData, overData);

        this.setState({ loading: false });
        charts.setOption("custom_chart", option);
      } else {
        message.error(res.data.msg);
        this.setState({ loading: false });
      }
    });
  };

  // 个人工作时间 self
  getSelfWork = () => {
    charts.setOption("custom_chart", charts.customoption);
    const { userName, startDate, endDate } = this.state;
    const { cityCode } = this.props;
    const params = {
      key: userName,
      cityCode,
      startDate,
      endDate,
      cityName: sessionStorage.getItem("cityName"),
    };
    const selfVisible =
      Boolean(userName) && Boolean(startDate) && Boolean(endDate);
    if (selfVisible) {
      LogRequest.getSelfWork_date(params).then((res) => {
        if (res.data.code === 200) {
          const { date, innerTime, outerTime, overTime } = res.data.data;
          if (date.length === 0) {
            message.destroy();
            message.info(`当前时间范围内没有${userName}的工作数据`);
            this.setState({ loading: false });
            return;
          }
          (window as any).echarts_allNum = date.length;
          const innerData = innerTime.map((item) => {
            // console.log(item);
            return {
              name: userName,
              type: "inner",
              value: [
                item.index,
                "2000-01-01 " + item.startTime,
                "2000-01-01 " + item.endTime,
                {
                  classNum: item.classNum,
                  title: "验收完成小班：",
                  unit: "个",
                },
                {
                  censusClassNum: item.censusClassNum,
                  title: "普查完成小班：",
                  unit: "个",
                },
                item.date,
              ],
            };
          });
          const outerData = outerTime.map((item, index) => {
            // console.log(item);
            return {
              name: userName,
              type: "outer",
              itemStyle: { color: item.color },
              value: [
                item.index,
                "2000-01-01 " + item.startTime,
                "2000-01-01 " + item.endTime,
                {
                  title: "照片数量：",
                  unit: "张",
                  picNum: item.picNum,
                  newAddress: item.newAddress,
                  duration: item.duration,
                  kil: item.kil,
                },
                item.date,
              ],
            };
          });

          const overData = overTime.map((item) => {
            return {
              name: userName,
              type: "over",
              value: [
                item.index,
                "2000-01-01 " + item.startTime,
                "2000-01-01 " + item.endTime,
                {
                  title: item.isApplication ? "申请时间" : "打卡时间",
                },
                item.date,
              ],
            };
          });

          const option = {
            ...charts.customoption,
            yAxis: {
              ...charts.customoption.yAxis,
              name: "日期（小班数）",
              data: date,
            },
            xAxis: {
              ...charts.customoption.xAxis,
              axisPointer: {
                value: "2000-01-01 09:00:00",
                ...charts.customoption.xAxis.axisPointer,
              },
            },
            series: [
              {
                ...charts.customoption.series[0],
                data: innerData,
              },
              {
                ...charts.customoption.series[1],
                data: outerData,
              },
              {
                ...charts.customoption.series[2],
                data: overData,
              },
            ],
          };
          this.setState({ loading: false });
          // console.log(option);
          charts.setOption("custom_chart", option);
        } else {
          message.error(res.data.msg);
          this.setState({ loading: false });
        }
      });
    }
  };

  userChange = (name) => {
    this.setState({ userName: name }, () => {
      this.getSelfWork();
    });
  };

  rangeChange = (value, type) => {
    this.setState({ loading: true });
    if (value) {
      if (type === "one") {
        // 获取当前日期的字符串形式，格式为 "yyyy-mm-dd"
        const currentDate = new Date().toISOString().split("T")[0];
        // 获取给定时间的字符串形式，格式为 "yyyy-mm-dd"
        const givenDate = new Date(value._d).toISOString().split("T")[0];
        const isSameDay = currentDate === givenDate;
        this.setState({
          isNew: isSameDay,
        });
        // console.log(isSameDay);
        this.setState({ dateValue: value }, () => {
          this.getAllWork();
        });
      } else {
        const millisecondNum = value[1].unix() - value[0].unix();
        const startDate = value[0].format("YYYY-MM-DD 00:00:00");
        const endDate = value[1].format("YYYY-MM-DD 23:59:59");
        this.setState({ startDate, endDate }, () => {
          this.getSelfWork();
        });
      }
    } else {
      if (type === "range") {
        this.setState({ startDate: "", endDate: "", loading: false });
        charts.setOption("custom_chart", charts.customoption);
      }
    }
  };

  // 所有用户
  getUserList = () => {
    ToolTs.getAll_User().then((res) => {
      if (res.data.code === 200) {
        this.setState({
          userName: res.data.data[0],
          userList: res.data.data,
        });
      } else {
        message.destroy();
        message.error("获取用户列表失败");
      }
    });
  };

  // all or self切换
  modalChange = (value) => {
    this.setState({
      radioValue: value.target.value,
      loading: true,
    });
    const { startDate, endDate } = this.state;
    if (value.target.value === "self") {
      message.destroy();
      !startDate && message.info("请选择时间范围再进行查看");
      this.setState({ loading: false });
      charts.setOption("custom_chart", charts.customoption);
      this.getUserList();
      startDate && this.getSelfWork();
    } else {
      this.getAllWork();
    }
  };

  showModal = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  handleOk = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  handleCancel = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  render() {
    const {
      loading,
      dateValue,
      radioValue,
      startDate,
      endDate,
      userList,
      userName,
      isModalOpen,
      cutPicList,
      dronePicList,
    } = this.state;
    return (
      <>
        <Modal
          width={800}
          title="工作图片展示"
          visible={isModalOpen}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          {cutPicList && dronePicList ? (
            <>
              <div>砍伐图片：</div>
              <div className="cutPicList">
                {cutPicList.length > 0 ? (
                  cutPicList.map((e, i) => {
                    return (
                      <>
                        <div className="cutData">
                          <Image width={100} height={100} src={e.url} />
                          <div>{e.time}</div>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <div>无</div>
                )}
              </div>

              <div className="droneTitle">航拍图片：</div>
              <div className="dronePicList">
                {dronePicList.length > 0 ? (
                  dronePicList.map((e, i) => {
                    return (
                      <>
                        <div className="cutData">
                          <Image width={100} height={100} src={e.url} />
                          <div>{e.time}</div>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <div>无</div>
                )}
              </div>
            </>
          ) : (
            <span>无图片数据</span>
          )}
        </Modal>
        <div
          style={{ position: "absolute", right: "47px", marginTop: "-50px" }}
        >
          <Radio.Group
            options={this.options}
            onChange={this.modalChange}
            value={radioValue}
            optionType="button"
            buttonStyle="solid"
          />
          {radioValue === "all" ? (
            <DatePicker
              value={dateValue}
              onChange={(value) => this.rangeChange(value, "one")}
              inputReadOnly={true}
              style={{ marginLeft: "25px", marginRight: "5px", width: 120 }}
            />
          ) : (
            <>
              <RangePicker
                value={[
                  startDate ? moment(startDate, "YYYY-MM-DD") : null,
                  endDate ? moment(endDate, "YYYY-MM-DD") : null,
                ]}
                onChange={(value) => this.rangeChange(value, "range")}
                inputReadOnly={true}
                style={{ marginLeft: "25px", marginRight: "5px", width: 240 }}
                placeholder={["开始日期", "结束日期"]}
              />
              <Select
                onChange={this.userChange}
                style={{ width: 120, marginRight: "17px" }}
                value={userName}
              >
                {userList.map((item, index) => {
                  return (
                    <Option value={item} key={index + 1}>
                      {item}
                    </Option>
                  );
                })}
              </Select>
            </>
          )}
        </div>
        <Spin
          spinning={loading}
          size="large"
          style={{ position: "absolute", left: "57%", marginTop: "16%" }}
        />
        <div id="custom_chart" className="custom_chart"></div>
      </>
    );
  }
}
