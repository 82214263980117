import React, { Component } from 'react';
import { OverviewBox } from './common';
import project from '../../assets/images/project.png';
import area from '../../assets/images/area.png';
import wood from '../../assets/images/wood.png';
import picture from '../../assets/images/picture.png';
import passrate from '../../assets/images/passrate.png';

interface Props {
	pageLeftData: any;
}

interface State {
	data: Array<any>;
}

class Left extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			data: [
				{
					key: 'project',
					icon: project,
					name: '项目',
					title: '数量',
					value: 9,
					unit: '个',
				},
				{
					key: 'survey',
					icon: area,
					name: '普查',
					title: '总数',
					value: 0,
					unit: '万亩',
				},
				{
					key: 'wood',
					icon: wood,
					name: '疫木',
					title: '株树',
					value: 0,
					unit: '万株',
				},
				{
					key: 'area',
					icon: area,
					name: '感染',
					title: '面积',
					value: 0,
					unit: '万亩',
				},
				{
					key: 'user',
					icon: area,
					name: '验收',
					title: '面积',
					value: 2604.11,
					unit: '万亩',
				},
				{
					key: 'system',
					icon: picture,
					name: '验收',
					title: '照片数量',
					value: 8722547,
					unit: '张',
				},
				{
					key: 'pass',
					icon: passrate,
					name: '验收',
					title: '通过率',
					value: 95.78,
					unit: '%',
				},
			],
		};
	}

	render() {
		const { pageLeftData } = this.props;
		const { data } = this.state;
		return (
			<div className={'navigation-left'}>
				<div className={'top'}>
					{pageLeftData &&
						pageLeftData.slice(0, 4).map((item: any) => {
							return <OverviewBox data={item} key={item.key} />;
						})}
				</div>
				<div className={'bottom'}>
					{pageLeftData &&
						pageLeftData.slice(4, 7).map((item: any) => {
							return <OverviewBox data={item} key={item.key} />;
						})}
				</div>
			</div>
		);
	}
}

export default Left;
