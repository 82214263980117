import React, { Component } from "react";
import {
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Spin,
  Statistic,
  Table,
  message,
  notification,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ToolTs from "../services/tool";
import PlagueWoodTs from "../services/plagueWood";
import "./index.scss";
import Intell from "./component";
import numUp from "../../assets/images/numUp.svg";
import numDown from "../../assets/images/numDown.svg";
import FormItem from "antd/es/form/FormItem";
import AdjustMap from "./map";

const { Option } = Select;
const { confirm } = Modal;

class Adjust extends Component<any> {
  private interval: NodeJS.Timeout | null = null;
  state = {
    page: 1,
    size: 10,
    pageTree: 1,
    sizeTree: 10,
    data: [],
    dataTree: [],
    cacheData: [],
    loading: false,
    loadingTree: true,
    countyCode: this.props.locationState.code,
    townCode: "",
    countyCodeTree: this.props.locationState.code,
    townCodeTree: "",
    filterCondition: null,
    filterConditionTree: null,
    countyList: [], //区县列表数据
    townList: [], //乡镇列表数据
    countyListTree: [], //区县列表数据疫木弹窗
    townListTree: [], //乡镇列表数据疫木弹窗
    treePointList: [], //疫木点位列表数据
    total: 0,
    totalTree: 0,
    totalAreaNum: 0,
    totalTreeCountNum: 0,
    saveAreaNum: 0, //保存的原始面积总数
    saveTreeNum: 0, //保存的原始疫木总数
    searchValue: "",
    selectedRowKeys: [],
    selectedRowKeysTree: [], //选中的疫木弹窗内数据
    record: null,
    year: sessionStorage.getItem("year"),
    selectArea: 0, //选择的总面积
    selectTree: 0, //选择的总株数
    yearList: JSON.parse(sessionStorage.getItem("yearList")),
    filteredInfo: { countyCode: null, townCode: null, treePoint: null },
    filteredInfoTree: { countyCode: null, townCode: null, treePoint: null },
    deleteList: [], //批量删除列表
    intellVisiable: false, //智能调整Boolean
    adjustDataFlag: false,
    treeFlag: false, //疫木弹窗
    chooseTreeList: [], //疫木弹窗内部选择的转移位置
    pointMapFlag: false, //迁移点位地图弹窗
    timer: 0, //迁移疫木弹窗定时器
    spinFlag: true,
  };

  componentDidMount(): void {
    this.getSelectList();
    this.getData();
    this.getTreePointNum();
    // window.addEventListener('keydown', this.handleUserActivity);
  }

  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {
    if (prevProps.year !== this.props.year) {
      this.setState(
        {
          year: this.props.year,
        },
        () => {
          this.getSelectList();
          this.getData();
        }
      );
    }
    if (prevProps.locationState.code !== this.props.locationState.code) {
      this.setState(
        {
          countyCode: this.props.locationState.code,
          countyList: [],
        },
        () => {
          this.getData();
          this.getSelectList();
        }
      );
    }
    if (prevState.countyCode !== this.state.countyCode) {
      this.setState(
        {
          townList: [],
        },
        () => {
          this.getSelectList();
          this.getTreePointNum();
        }
      );
    }
    if (prevState.countyCodeTree !== this.state.countyCodeTree) {
      this.setState(
        {
          townListTree: [],
        },
        () => {
          this.getSelectListTree();
        }
      );
    }
    if (prevState.size !== this.state.size) {
      this.setState({
        sizeTree: this.state.size,
      });
    }
  }

  componentWillUnmount() {
    // 在组件卸载时清除计时器和事件监听器
  }

  getData = () => {
    const {
      page,
      size,
      year,
      searchValue,
      countyCode,
      townCode,
      cacheData,
      filterCondition,
    } = this.state;
    const params = {
      page,
      size,
      key: searchValue,
      year,
      countyCode,
      filterCondition: filterCondition ? filterCondition.join(",") : null,
      townCode: townCode ? townCode.slice(townCode.length - 3) : "",
    };
    ToolTs.getTreeHandle(params, (res) => {
      if (res.code === 200) {
        let arr = res.data.list;
        res.data.list &&
          res.data.list.map((e, i) => {
            cacheData.map((obj) => {
              if (
                obj.county === e.countyName &&
                obj.town === e.townName &&
                obj.treeCount === e.treeCount
              ) {
                arr[i].totalTree = e.totalTree + obj.diffTreeNum;
              }
            });
          });
        this.setState({
          data: arr,
          loading: false,
          total: res.data.total,
          totalTreeCountNum: res.data.totalTreeCountNum,
          totalAreaNum: res.data.totalAreaNum,
          saveAreaNum: res.data.totalAreaNum,
          saveTreeNum: res.data.totalTreeCountNum,
        });
      }
    });
  };

  // 重置计时器的方法
  resetTimer = () => {
    this.setState({ timer: 0 });
  };

  // 每次用户活动时，调用resetTimer方法来重置计时器
  handleUserActivity = () => {
    this.resetTimer();
  };

  //获取列表
  getSelectList = () => {
    const { countyCode, townCode, countyList } = this.state;
    const { code } = this.props.locationState;
    const params = {
      countyCode: countyCode ? countyCode : code,
      townCode: townCode ? townCode.slice(townCode.length - 3) : "",
    };
    PlagueWoodTs.getSelectList(params).then((res) => {
      if (res.data.code === 200) {
        if (countyList.length === 0) {
          this.setState({
            countyList: res.data.data,
          });
        } else if (!townCode) {
          this.setState({
            townList: res.data.data,
          });
        }
      }
    });
  };

  //获取列表疫木弹窗
  getSelectListTree = () => {
    const { countyCodeTree, countyListTree, townCodeTree } = this.state;
    const { code } = this.props.locationState;
    const params = {
      countyCode: countyCodeTree ? countyCodeTree : code,
      townCode: townCodeTree ? townCodeTree.slice(townCodeTree.length - 3) : "",
    };
    PlagueWoodTs.getSelectList(params).then((res) => {
      if (res.data.code === 200) {
        if (countyListTree.length === 0) {
          this.setState({
            countyListTree: res.data.data,
          });
        } else if (!townCodeTree) {
          this.setState({
            townListTree: res.data.data,
          });
        }
      }
    });
  };

  //更改年份
  changeYear = (year) => {
    this.setState(
      {
        page: 1,
        size: 10,
        year,
      },
      this.getData
    );
  };

  //获取疫木数量
  getTreePointNum = () => {
    const { countyCode, townCode, year } = this.state;
    const params = {
      countyCode,
      townCode,
      year,
    };
    PlagueWoodTs.getTreePoint(params).then((res) => {
      // console.log(res)
      if (res.data.code === 200) {
        this.setState({
          treePointList: res.data.data,
        });
      }
    });
  };

  //切换
  changePagination = (pagination, filter) => {
    const { code } = this.props.locationState;
    this.setState(
      {
        filteredInfo: {
          countyCode: filter.countyName || null,
          townCode: filter.countyName ? filter.townName : null,
          treePoint: filter.treeCount ? filter.treeCount : null,
        },
        countyCode: filter.countyName ? filter.countyName[0] : code,
        townCode: filter.townName ? filter.townName[0] : "",
        filterCondition: filter.treeCount ? filter.treeCount : null,
        page: pagination.current,
        size: pagination.pageSize,
        loading: true,
      },
      () => {
        this.getData();
      }
    );
  };

  //切换
  changePaginationTree = (pagination, filter) => {
    const { countyCode } = this.state;
    this.setState(
      {
        filteredInfoTree: {
          countyCode: filter.countyName,
          townCode: filter.countyName ? filter.townName : null,
          treePoint: filter.treeCount ? filter.treeCount : null,
        },
        countyCodeTree: filter.countyName ? filter.countyName[0] : countyCode,
        townCodeTree: filter.townName ? filter.townName[0] : "",
        filterConditionTree: filter.treeCount ? filter.treeCount : null,
        pageTree: pagination.current,
        sizeTree: pagination.pageSize,
        loadingTree: true,
      },
      () => {
        this.getDataTree();
      }
    );
  };

  //删除
  deleteData = (record) => {
    const { year } = this.state;
    const params = {
      county: record.countyName,
      town: record.townName,
      treeCount: record.treeCount,
      year,
    };
    ToolTs.postTreeHandle(params, (res) => {
      if (res.code === 200) {
        notification.success({
          message: `删除成功`,
          description: "",
          duration: 2.5,
        });
        this.setState({
          selectedRowKeys: [],
        });
        this.getData();
      } else {
        notification.error({
          message: `删除失败`,
          description: "",
          duration: 2.5,
        });
      }
    });
  };

  //批量删除
  deleteAll = () => {
    const { deleteList } = this.state;
    if (deleteList.length === 0) {
      message.error("请先选择要删除得数据！");
      return;
    }
    confirm({
      title: "确定删除所选数据吗?",
      icon: <ExclamationCircleOutlined rev={undefined} />,
      okText: "确定",
      cancelText: "取消",
      onOk: () => {
        ToolTs.postBatchTreeHandle(deleteList, (res) => {
          if (res.code === 200) {
            message.success("批量删除成功!");
            this.setState({ selectedRowKeys: [] });
            this.getData();
          } else {
            message.error(res.data.msg);
          }
        });
      },
    });
  };

  //智能调整
  intellMethod = (intellVisiable) => {
    this.setState({
      intellVisiable: !intellVisiable,
    });
  };

  //手动调整数据
  adjustmentData = () => {
    const { cacheData } = this.state;
    PlagueWoodTs.postBatchReportData(cacheData).then((res) => {
      if (res.data.code === 200) {
        message.success("调整成功");
        this.setState({
          cacheData: [],
          adjustDataFlag: false,
        });
        this.getData();
      }
    });
  };

  hideModal = () => {
    this.setState({
      adjustDataFlag: false,
    });
  };
  //疫木弹窗关闭
  hideModalTree = () => {
    // clearInterval(this.interval);
    // window.removeEventListener('click', this.handleUserActivity);
    const {
      deleteList,
      pageTree,
      sizeTree,
      year,
      countyCodeTree,
      townCodeTree,
      filterConditionTree,
    } = this.state;
    let params = {
      page: pageTree,
      size: sizeTree,
      year,
      requestIndex: "3",
      countyCode: countyCodeTree,
      townCode: townCodeTree,
      needMigrationData: deleteList,
    };
    PlagueWoodTs.postMigrationTree(params).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          treeFlag: false,
          selectedRowKeysTree: [],
          dataTree: [],
          chooseTreeList: [],
          loadingTree: false,
        });
      } else {
        this.setState({
          treeFlag: false,
        });
        message.info(res.data.msg);
      }
    });
  };

  //点击疫木转移按钮
  clickTree = () => {
    this.resetTimer();
    const {
      deleteList,
      pageTree,
      size,
      sizeTree,
      year,
      countyCodeTree,
      townCodeTree,
      countyList,
      filterCondition,
    } = this.state;
    console.log(size);
    let params = {
      page: pageTree,
      size: size,
      year,
      requestIndex: "1",
      countyCode: countyCodeTree,
      townCode: townCodeTree ? townCodeTree.slice(townCodeTree.length - 3) : "",
      filterCondition: filterCondition,
      needMigrationData: deleteList,
    };

    if (deleteList.length > 0) {
      PlagueWoodTs.postMigrationTree(params).then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          this.setState({
            dataTree: res.data.data.list,
            loadingTree: false,
            countyCodeTree: countyCodeTree,
            townCodeTree: townCodeTree,
            countyListTree: countyList,
            totalTree: res.data.data.total,
          });
          this.setState({
            treeFlag: true,
          });
          // 启动计时器
          // this.interval = setInterval(() => {
          // 	this.setState(
          // 		{ timer: this.state.timer + 1 },
          // 		() => {
          // 			// 在setState回调中检查计时器是否达到3分钟
          // 			if (this.state.timer >= 180) {
          // 				message.info('3分钟未操作关闭迁移弹窗！')
          // 				this.hideModalTree()
          // 			}
          // 		}
          // 	);
          // }, 1000);

          // window.addEventListener('click', this.handleUserActivity);
        } else {
          message.info(res.data.msg);
          this.setState({
            treeFlag: true,
          });
        }
      });
    } else {
      message.error("请选择迁移数据！");
    }
  };

  //迁移点位查看
  clickMap = () => {
    this.setState({
      pointMapFlag: true,
    });
  };

  //获取疫木数据
  getDataTree = () => {
    const {
      deleteList,
      pageTree,
      sizeTree,
      year,
      countyCodeTree,
      townCodeTree,
      filterConditionTree,
    } = this.state;
    let params = {
      page: pageTree,
      size: sizeTree,
      year,
      requestIndex: "1",
      countyCode: countyCodeTree,
      townCode: townCodeTree ? townCodeTree.slice(townCodeTree.length - 3) : "",
      filterCondition: filterConditionTree,
      needMigrationData: deleteList,
    };
    PlagueWoodTs.postMigrationTree(params).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          dataTree: res.data.data.list,
          loadingTree: false,
          totalTree: res.data.data.total,
        });
      } else {
        message.info(res.data.msg);
      }
    });
  };

  //感染株数上升
  numberUp = (record) => {
    const { data, cacheData, year } = this.state;
    let arr = cacheData;
    let newData = this.dataManage(data, record, true);
    this.setState({ data: newData }, () => {
      if (arr.length === 0) {
        arr.push({
          treeCount: record.treeCount,
          diffTreeNum: 1,
          year,
          town: record.townName,
          county: record.countyName,
          treeCountT: record.treeCount + 1,
        });
        this.setState({
          cacheData: arr,
        });
      } else {
        const index = this.cacheData(arr, record);
        if (index >= 0) {
          arr[index].diffTreeNum = arr[index].diffTreeNum + 1;
          arr[index].treeCountT = arr[index].treeCountT + 1;
          let newArr = arr.filter((obj) => {
            return obj.diffTreeNum !== 0;
          });
          console.log(newArr);
          this.setState({
            cacheData: newArr,
          });
        } else {
          arr.push({
            treeCount: record.treeCount,
            diffTreeNum: 1,
            year,
            town: record.townName,
            county: record.countyName,
            treeCountT: record.treeCount + 1,
          });
          this.setState({
            cacheData: arr,
          });
        }
      }
    });
  };
  //感染株数下降
  numberDown = (record) => {
    const { data, cacheData, year } = this.state;
    let arr = cacheData;
    const isTotalTree = record.totalTree;
    let newData = this.dataManage(data, record, false);
    this.setState({ data: newData });
    if (arr.length === 0 && isTotalTree > 0) {
      arr.push({
        treeCount: record.treeCount,
        diffTreeNum: -1,
        year,
        town: record.townName,
        county: record.countyName,
        treeCountT: record.treeCount - 1,
      });
      this.setState({
        cacheData: arr,
      });
    } else if (isTotalTree > 0) {
      const index = this.cacheData(arr, record);
      if (index >= 0) {
        arr[index].diffTreeNum = arr[index].diffTreeNum - 1;
        arr[index].treeCountT = arr[index].treeCountT - 1;
        let newArr = arr.filter((obj) => {
          return obj.diffTreeNum !== 0;
        });
        console.log(newArr);
        this.setState({
          cacheData: newArr,
        });
      } else {
        arr.push({
          treeCount: record.treeCount,
          diffTreeNum: -1,
          year,
          town: record.townName,
          county: record.countyName,
          treeCountT: record.treeCount - 1,
        });
        this.setState({
          cacheData: arr,
        });
      }
    }
  };

  //匹配缓存中是否有此数据
  cacheData = (array, targetObject) => {
    let flag = -1;
    array.forEach((obj, i) => {
      if (
        obj.county === targetObject.countyName &&
        obj.town === targetObject.townName &&
        obj.treeCount === targetObject.treeCount
      ) {
        flag = i;
      }
    });
    return flag;
  };

  //匹配数据
  dataManage = (array, targetObject, isUp) => {
    const { totalTreeCountNum } = this.state;
    array.forEach((obj) => {
      if (
        obj.countyName === targetObject.countyName &&
        obj.townName === targetObject.townName &&
        obj.treeCount === targetObject.treeCount
      ) {
        if (obj.totalTree === 0 && !isUp) {
          message.warning("不能减小了，已经为0！");
        } else if (obj.totalTree !== 0 && !isUp) {
          obj.totalTree = Math.max(0, obj.totalTree - targetObject.classNum);
          this.setState({
            totalTreeCountNum: totalTreeCountNum - targetObject.classNum,
          });
        } else {
          obj.totalTree = Math.max(0, obj.totalTree + targetObject.classNum);
          this.setState({
            totalTreeCountNum: totalTreeCountNum + targetObject.classNum,
          });
        }
      }
    });
    return array;
  };

  //疫木弹窗选择数据
  onSelectTreeChange = (selectedRowKeys, selectedRows) => {
    let chooseTreeList = [];
    const { year } = this.state;
    selectedRows.map((item, index) => {
      chooseTreeList.push({
        county: item.countyName,
        town: item.townName,
        treeCount: item.treeCount,
        year,
      });
      if (selectedRows.length === index + 1) {
        this.setState({
          chooseTreeList,
        });
      }
    });
  };
  //选择数据
  onSelectChange = (selectedRowKeys, selectedRows) => {
    const { year } = this.state;
    let deleteList = [];
    selectedRows.map((item, index) => {
      deleteList.push({
        county: item.countyName,
        town: item.townName,
        treeCount: item.treeCount,
        year,
      });
      if (selectedRows.length === index + 1) {
        this.setState({
          deleteList,
        });
      }
    });

    const { saveAreaNum, saveTreeNum } = this.state;
    let newselectArea = 0;
    let newselectTree = 0;
    let newSaveArea = saveAreaNum;
    let newSaveTree = saveTreeNum;
    //选择的数据
    selectedRows.map((item) => {
      newselectArea = newselectArea + item.totalArea;
      newselectTree = newselectTree + item.totalTree;
    });

    newSaveArea = newSaveArea - newselectArea;
    newSaveTree = newSaveTree - newselectTree;

    this.setState({
      selectedRowKeys,
      totalAreaNum: newSaveArea,
      totalTreeCountNum: newSaveTree,
      selectTree: newselectTree,
      selectArea: newselectArea,
    });
  };

  //点击确认
  onOkTree = () => {
    this.resetTimer();
    const {
      deleteList,
      pageTree,
      sizeTree,
      year,
      countyCodeTree,
      townCodeTree,
      chooseTreeList,
      filterConditionTree,
    } = this.state;
    let params = {
      page: pageTree,
      size: sizeTree,
      year,
      migrationLocation: chooseTreeList,
      requestIndex: "2",
      countyCode: countyCodeTree,
      townCode: townCodeTree,
      filterCondition: filterConditionTree,
      needMigrationData: deleteList,
    };
    this.setState({
      loadingTree: true,
    });
    if (chooseTreeList.length > 0) {
      PlagueWoodTs.postMigrationTree(params).then((res) => {
        if (res.data.code === 200) {
          this.setState({
            treeFlag: false,
            selectedRowKeysTree: [],
            dataTree: [],
            chooseTreeList: [],
            loadingTree: false,
          });
          message.info(res.data.msg);
          this.getData();
        } else {
          message.info(res.data.msg);
        }
      });
    } else {
      message.error("请选择转移目标！");
    }
  };

  render() {
    const {
      page,
      size,
      data,
      loading,
      total,
      selectedRowKeys,
      filteredInfo,
      filteredInfoTree,
      townList,
      yearList,
      totalAreaNum,
      totalTreeCountNum,
      totalTree,
      countyList,
      intellVisiable,
      adjustDataFlag,
      spinFlag,
      cacheData,
      saveTreeNum,
      treePointList,
      treeFlag,
      dataTree,
      loadingTree,
      pageTree,
      sizeTree,
      countyListTree,
      townListTree,
      pointMapFlag,
      townCode,
      selectedRowKeysTree,
    } = this.state;
    const pagination = {
      total,
      current: page,
      pageSize: size,
      showQuickJumper: true,
      responsive: false,
      showSizeChanger: true,
    };
    const paginationTree = {
      total: totalTree,
      current: pageTree,
      pageSize: sizeTree,
      showQuickJumper: true,
      responsive: false,
      showSizeChanger: true,
    };
    const paginationCache = {
      total: cacheData.length,
      current: 1,
    };
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const columns: any = [
      {
        title: "区县",
        width: "120px",
        dataIndex: "countyName",
        key: "countyName",
        filteredValue: filteredInfo.countyCode || null,
        filterMultiple: false,
        filters: countyList.map((item: any) => ({
          text: item.name,
          value: item.areaCode,
        })),
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "乡镇/街道",
        width: "150px",
        align: "center" as "center",
        dataIndex: "townName",
        key: "townName",
        filteredValue: filteredInfo.townCode || null,
        filterMultiple: false,
        filters: townList.map((item: any) => ({
          text: item.name,
          value: item.areaCode,
        })),
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "疫木点位数",
        dataIndex: "treeCount",
        key: "treeCount",
        align: "center" as "center",
        width: "150px",
        filteredValue: filteredInfo.treePoint || null,
        filterMultiple: true,
        filters: treePointList.map((item: any) => ({
          text: item,
          value: item,
        })),
        render: (text, record) => {
          const data = this.state.cacheData.filter((item) => {
            return (
              item.county == record.countyName &&
              item.town == record.townName &&
              item.treeCount == record.treeCount
            );
          });
          return (
            <>
              <span>{text || "暂无"}</span>
              {data.length > 0 && (
                <span>
                  (
                  {data[0].diffTreeNum > 0
                    ? "+" + data[0].diffTreeNum
                    : data[0].diffTreeNum}
                  )
                </span>
              )}
            </>
          );
        },
      },
      {
        title: "小班数量",
        align: "center" as "center",
        dataIndex: "classNum",
        key: "classNum",
        width: "150px",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "感染总数(株)",
        dataIndex: "totalTree",
        key: "totalTree",
        align: "center" as "center",
        width: "150px",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "感染总面积(亩)",
        dataIndex: "totalArea",
        key: "totalArea",
        align: "center" as "center",
        width: "150px",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "操作",
        width: "150px",
        className: "handle",
        dataIndex: "handle",
        key: "handle",
        render: (text, record) => (
          <>
            {/* <Button type="link" onClick={() => { this.changeUpdateVisible(record) }}>修改</Button> */}
            <Popconfirm
              title="确定要删除当前记录吗?"
              onConfirm={() => {
                this.deleteData(record);
              }}
              okText="确定"
              cancelText="取消"
            >
              <Button type="link">删除</Button>
            </Popconfirm>
            <img
              style={{ width: "20px", height: "20px", cursor: "pointer" }}
              onClick={() => this.numberUp(record)}
              src={numUp}
              alt=""
            />
            <img
              style={{ width: "20px", height: "20px", cursor: "pointer" }}
              onClick={() => this.numberDown(record)}
              src={numDown}
              alt=""
            />
          </>
        ),
      },
    ];
    const rowSelectionTree = {
      selectedRowKeysTree,
      onChange: this.onSelectTreeChange,
    };
    const columnsTree: any = [
      {
        title: "区县",
        width: "120px",
        dataIndex: "countyName",
        key: "countyName",
        filteredValue: filteredInfoTree.countyCode || null,
        filterMultiple: false,
        filters: countyListTree.map((item: any) => ({
          text: item.name,
          value: item.areaCode,
        })),
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "乡镇/街道",
        width: "150px",
        align: "center" as "center",
        dataIndex: "townName",
        key: "townName",
        filteredValue: filteredInfoTree.townCode || null,
        filterMultiple: false,
        filters: townListTree.map((item: any) => ({
          text: item.name,
          value: item.areaCode,
        })),
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "疫木点位数",
        dataIndex: "treeCount",
        key: "treeCount",
        align: "center" as "center",
        width: "150px",
        filteredValue: filteredInfoTree.treePoint || null,
        filterMultiple: true,
        filters: treePointList.map((item: any) => ({
          text: item,
          value: item,
        })),
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "小班数量",
        align: "center" as "center",
        dataIndex: "classNum",
        key: "classNum",
        width: "150px",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "感染总数(株)",
        dataIndex: "totalTree",
        key: "totalTree",
        align: "center" as "center",
        width: "150px",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "感染总面积(亩)",
        dataIndex: "totalArea",
        key: "totalArea",
        align: "center" as "center",
        width: "150px",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
    ];

    const adjustColumns: any = [
      {
        title: "区县",
        dataIndex: "county",
        key: "county",
        align: "center" as "center",
        width: "50px",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "乡镇/街道",
        dataIndex: "town",
        key: "town",
        align: "center" as "center",
        width: "50px",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "疫木点位数",
        dataIndex: "treeCount",
        key: "treeCount",
        align: "center" as "center",
        width: "50px",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },

      {
        title: "数据变化量",
        dataIndex: "diffTreeNum",
        key: "diffTreeNum",
        align: "center" as "center",
        width: "50px",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "数据目标值",
        dataIndex: "treeCountT",
        key: "treeCountT",
        align: "center" as "center",
        width: "50px",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
    ];
    return (
      <div className="adjust">
        <div className="adjust-Header">
          <div className="totalArea">
            <div>总感染面积：</div>
            <Statistic
              value={totalAreaNum}
              valueStyle={{ fontSize: "18px" }}
              suffix="亩"
            />
            <div style={{ marginLeft: "30px" }}>总感染株数：</div>
            <Statistic
              value={totalTreeCountNum}
              valueStyle={{ fontSize: "18px" }}
              suffix="株"
            />
          </div>
          {/* <Search
						className='searchInput'
						placeholder='请输入信息查询'
						enterButton="搜索"
						onSearch={this.handleSearch}
						style={{
							width: '270px'
						}}
					/> */}
          <div className="function">
            <Button
              style={{ marginRight: "10px" }}
              type="primary"
              onClick={() => {
                this.clickMap();
              }}
            >
              迁移查看
            </Button>
            <Button
              style={{ marginRight: "10px" }}
              type="primary"
              onClick={() => {
                this.clickTree();
              }}
            >
              疫木转移
            </Button>
            <Button
              style={{ marginRight: "10px" }}
              type="primary"
              onClick={() => {
                this.setState({
                  adjustDataFlag: true,
                });
                console.log(cacheData);
              }}
            >
              调整数据
            </Button>
            <Button
              style={{ marginRight: "10px" }}
              type="primary"
              onClick={() => this.intellMethod(intellVisiable)}
            >
              智能调整
            </Button>
            <Button
              type="primary"
              className="deleteAll"
              onClick={() => {
                this.deleteAll();
              }}
            >
              批量删除
            </Button>
            <Select
              className="yearSelect"
              placeholder="年度"
              onChange={this.changeYear}
              style={{ width: 176 }}
              allowClear
              defaultValue={sessionStorage.getItem("year")}
            >
              {yearList.map((item) => (
                <Option value={item.key} key={item.key}>
                  {item.value}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <Table
          dataSource={data}
          rowKey={(record) => record.id}
          columns={columns}
          loading={loading}
          rowSelection={rowSelection}
          onChange={this.changePagination}
          pagination={pagination}
          scroll={{ y: "calc(100vh - 23.5rem)" }}
        />
        <Spin spinning={false}>
          <Modal
            title="调整数据"
            visible={adjustDataFlag}
            footer={null}
            destroyOnClose={true}
            onCancel={this.hideModal}
            width={800}
          >
            <Form onFinish={this.adjustmentData}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <FormItem label="感染株树" style={{ width: "45%" }}>
                  <Input defaultValue={saveTreeNum} disabled />
                </FormItem>
                <FormItem label="改变后株树" style={{ width: "45%" }}>
                  <Input defaultValue={totalTreeCountNum} disabled />
                </FormItem>
              </div>

              <Table
                dataSource={cacheData}
                columns={adjustColumns}
                loading={loading}
                scroll={{ y: "calc(100vh - 23.5rem)" }}
              />
              <div className="form-button">
                <FormItem style={{ marginTop: "20px" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginRight: "10px" }}
                  >
                    确认
                  </Button>
                  {/* <Spin spinning={false} /> */}
                  <Popconfirm
                    title="是否要保存之前修改的数据?"
                    onConfirm={() => {
                      this.setState({
                        adjustDataFlag: false,
                      });
                    }}
                    onCancel={() => {
                      this.setState(
                        {
                          adjustDataFlag: false,
                          cacheData: [],
                        },
                        () => {
                          this.getData();
                          message.success("调整数据复原成功");
                        }
                      );
                    }}
                    okText="保存"
                    cancelText="不保存"
                  >
                    <Button>取消</Button>
                  </Popconfirm>
                </FormItem>
              </div>
            </Form>
          </Modal>
        </Spin>

        <Modal
          title="疫木转移"
          visible={treeFlag}
          destroyOnClose={true}
          onCancel={this.hideModalTree}
          width={1200}
          onOk={this.onOkTree}
        >
          <Table
            dataSource={dataTree}
            rowKey={(record) => record.id}
            columns={columnsTree}
            loading={loadingTree}
            rowSelection={rowSelectionTree}
            onChange={this.changePaginationTree}
            pagination={paginationTree}
            scroll={{ y: "calc(100vh - 23.5rem)" }}
          />
        </Modal>
        <Modal
          title="迁移点位数据"
          visible={pointMapFlag}
          destroyOnClose={true}
          footer={null}
          onCancel={() => {
            this.setState({
              pointMapFlag: false,
            });
          }}
          width={1200}
        >
          <AdjustMap areaLevel={townCode}></AdjustMap>
        </Modal>
        {intellVisiable && (
          <Intell
            intellVisiable={intellVisiable}
            intellMethod={this.intellMethod}
            getListData={this.getData}
            countyCode={this.props.locationState.code}
          />
        )}
      </div>
    );
  }
}

export default Adjust;
