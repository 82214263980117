import React, { Component } from "react";
import { CMap } from "@dinnovate/cloudmap_npm";
import { Button, List, message, Popconfirm, Tabs } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import Request from "../../services/Manger";
import CensusTs from "../services/census";
import WmsData from "./wmsServer.json";
import icon from "../../assets/icons/label/circle.svg";
import TileLayer from "ol/layer/Tile";
import { XYZ } from "ol/source";
import PlagueWoodTs from "../services/plagueWood";
interface Props {
  isOpenCensus: boolean;
  changeCensus: (e: any) => void;
  getData: () => void;
  year: any;
  areaCode: any;
  position: any;
}

let regionParam = {
  id: "regionlayer",
  geometryList: [],
};
const url = window["env"].LayerUrl + "/geoserver/wms";

//疫木点位图层
const woodLayerLoding = {
  id: "woodLayer",
  url,
  zIndex: 5,
  params: {
    SERVICE: "WMS",
    VERSION: "1.1.1",
    REQUEST: "GetMap",
    LAYERS: "pine-epidemic-census:tree_identify",
    TRANSPARENT: true,
    STYLES: "",
    format: "image/png",
    exceptions: "application/vnd.ogc.se_inimage",
    srs: "EPSG:4549",
    viewparams: `areaCode:${331100};areaLevel:${1};year:2021-2022`,
  },
};
//优先级小班
let levelLayer: any;
const maplevelLayer = {
  id: "maplevelLayer",
  url,
  params: {
    ...WmsData.maplevel,
    time: new Date().getTime(),
    viewparams: `countyCode:${331102};townCode:005,xian:${331102};xiang:005`,
  },
};
//难易度小班
let hardLayer: any;
const hardlevelLayer = {
  id: "hardlevelLayer",
  url,
  params: {
    ...WmsData.hardlevel,
    time: new Date().getTime(),
    viewparams: `countyCode:${331102};townCode:005,xian:${331102};xiang:005`,
  },
};

let temporaryLayer = {
  id: "temporaryLayer",
  url: window["env"].LayerUrl + "/geoserver/pine-epidemic-census/wms",
  params: {
    VERSION: "1.1.0",
    layers: "pine-epidemic-census:pine_report_county_data",
    FORMAT: "image/png",
    SRS: "EPSG:4549",
  },
};

// const BMap = window["BMap"]

export default class Map extends Component<Props, any> {
  state = {
    altInnerlist: {
      level: [["Ⅰ级", "Ⅱ级", "Ⅲ级"], "alt-inner"],
      hard: [["A级", "B级", "C级"], "alt-hard-inner"],
      data: [["Ⅰ级", "Ⅱ级", "Ⅲ级"], "alt-inner"],
    },
    moveMap: null, //移动的实例对象
    optOnIs: ["Ⅲ级", "C级"],
    classSelect: [false, false],
    TabPanelistIs: false, //切换优先级  难易度
    listData1: [], //优先级小班数据
    areaCode: this.props.areaCode, //选中的区县的code
    townCode: "", //乡镇code
    areaLevel: "1", //选中的县级/乡镇的level，
    areaLonlat: [], //选中的县级/乡镇的经纬度
    levelTwoAreaLonlat: [], //二级区域lonlat存放
    listData2: [], //难易度小班数据
    textList: [],
    labelList: [], //已选中列表的小班id
    classTabList: [], //18级小班标签的列表
    ragionTabs: [], //行政区标签
    Year: "2022-2023",
  };

  componentDidMount = () => {
    const { moveMap } = this.state;
    const { areaCode } = this.props;

    CMap.addEvent("moveTab", "moveend", (e) => {
      !moveMap && this.setState({ moveMap: e });
      this.addClassTabs(e);
    });
    //添加行政边界
    this.getCountryData(areaCode);
    CMap.addEvent("region", "click", (e: any) => {
      //添加地图鼠标点击事件
      const f = e.map.forEachFeatureAtPixel(e.pixel, (feature: any) => {
        //如果点击了地图上的标注物返回标注物的value
        return feature.values_;
      });
      if (f) {
        switch (f.data.areaLevel) {
          case "2":
            this.setState({
              areaCode: f.data.areaCode,
              areaLevel: f.data.areaLevel,
              areaLonlat: [parseFloat(f.data.lon), parseFloat(f.data.lat)],
              levelTwoAreaLonlat: [
                parseFloat(f.data.lon),
                parseFloat(f.data.lat),
              ],
            });
            break;
          case "3":
            let townCode = f.data.areaCode.slice(6);
            this.setState({
              townCode: townCode,
              areaLevel: f.data.areaLevel,
              areaLonlat: [parseFloat(f.data.lon), parseFloat(f.data.lat)],
            });
            break;
        }
      }
    });
  };

  //监听状态热更新
  componentDidUpdate = (
    prevProps: Readonly<Props>,
    prevState: Readonly<any>,
    snapshot?: any
  ) => {
    const {
      areaCode,
      areaLevel,
      areaLonlat,
      ragionTabs,
      townCode,
      TabPanelistIs,
      levelTwoAreaLonlat,
    } = this.state;
    if (prevState.areaCode != areaCode || prevState.townCode !== townCode) {
      //监听areaCode变化
      CMap.removeLayer("regionlayer");
      if (areaLevel == "2") {
        this.getTownData(areaCode);
        CMap.mapMove(
          levelTwoAreaLonlat.length !== 0 ? levelTwoAreaLonlat : areaLonlat,
          11
        ); //地图移动 [119.99073218249146, 28.432226986911104]
      } else if (areaLevel == "3") {
        this.addClassLayer(areaCode, townCode, TabPanelistIs);
        // this.addWoodLayer(`${areaCode}${townCode}`)
        CMap.removeLayer("tabs"); //清除行行政区标签
        ragionTabs.forEach((e) => {
          if (e.LonLat[0] == areaLonlat[0]) {
            this.addRegionTabs([e]);
          }
        });
        CMap.mapMove(areaLonlat, 13); //地图移动 [119.99073218249146, 28.432226986911104]
      }
    }
  };

  componentWillUnmount(): void {
    this.setState({
      moveMap: null,
    });
  }

  //疫木geoserver图层
  addWoodLayer = (areaCode) => {
    const { year } = this.props;
    woodLayerLoding.params.viewparams = `areaCode:${areaCode};areaLevel:${3};year:${year}`;
    CMap.addWMSLayer(woodLayerLoding);
  };

  //加载小班图层
  addClassLayer = (countyCode: any, townCode: any, TabPanelistIs) => {
    const year = sessionStorage.getItem("year");
    if (!TabPanelistIs) {
      PlagueWoodTs.getYearConfig({
        areaCode: countyCode,
        year,
      }).then((res) => {
        if (res.data.code === 200) {
          maplevelLayer.params.viewparams = `countyCode:${countyCode};townCode:${townCode};year:${res.data.data.smallClassYear};currentYear:${year}`;
          levelLayer = CMap.addWMSLayer(maplevelLayer);
        }
      });
    } else {
      PlagueWoodTs.getYearConfig({
        areaCode: countyCode,
        year,
      }).then((res) => {
        if (res.data.code === 200) {
          hardlevelLayer.params.viewparams = `countyCode:${countyCode};townCode:${townCode};year:${res.data.data.smallClassYear};currentYear:${year}`;
          hardLayer = CMap.addWMSLayer(hardlevelLayer);
        }
      });
    }
    setTimeout(() => {
      CMap.addWMSLayer(temporaryLayer);
    }, 500);
  };

  //获取县级边界数据
  getCountryData = (code: any) => {
    Request.CountryBorderlist(code, (res) => {
      if (res.code == 200) {
        this.addRegionlayer(res.data);
      }
    });
  };

  //获取乡镇边界数据
  getTownData = (code: any) => {
    Request.StreetBorderlist(code, (res) => {
      if (res.code == 200) {
        this.addRegionlayer(res.data);
      }
    });
  };

  //返回上一级
  backNextLevel = () => {
    const { areaLevel, levelTwoAreaLonlat } = this.state;
    CMap.removeLayer("regionlayer");
    switch (areaLevel) {
      case "2":
        this.getCountryData(this.props.areaCode);
        CMap.mapMove(this.props.position, 9); //地图移动
        this.setState({
          areaLevel: "1",
          areaCode: this.props.areaCode,
          levelTwoAreaLonlat: [],
        });
        break;
      case "3":
        CMap.mapMove(levelTwoAreaLonlat, 11); //地图移动
        CMap.removeLayer("maplevelLayer");
        CMap.removeLayer("hardlevelLayer");
        CMap.removeLayer("temporaryLayer");
        this.setState({
          areaLevel: "2",
          townCode: "",
        });
        break;
    }
  };

  //加载行政边界图层
  addRegionlayer = (data: any) => {
    let list: any[] = [];
    let tabList: any[] = [];
    CMap.removeLayer("tabs"); //清除行行政区标签
    data.forEach((item: any, i: any) => {
      if (item.json) {
        let obj = { ...item, ...JSON.parse(item.json) };
        delete obj.json;
        list.push(obj);
        tabList.push({
          LonLat: [parseFloat(item.lon), parseFloat(item.lat)],
          tab: item.name,
          ...obj,
        });
      }
    });
    const style = {
      fillColor: "#32c5ff28",
      strokeColor: "#989fa6",
      width: 1.5,
    };
    const param = {
      ...regionParam,
      geometryList: list,
      style,
    };
    CMap.addVectorLayer(param);
    this.addRegionTabs(tabList);
    this.setState({
      ragionTabs: tabList,
    });
  };

  //加载行政区标签
  addRegionTabs = (list: any[]) => {
    CMap.addTabs({
      id: "tabs",
      type: "iconTab",
      src: icon,
      data: list,
      fontSzie: 17,
      offsetY: -25,
      offsetX: 0,
      iconOffset: [-15, -10],
    });
  };

  //添加小班标签
  addClassTabs = (e: any) => {
    const { labelList, Year } = this.state;
    CMap.removeLayer("classTabs");
    if (e.map.getView().getZoom() > 17) {
      const view = e.map.getView().calculateExtent(e.map.getSize()); //获取视角范围区域
      const params = {
        maxX: view[2].toFixed(4),
        maxY: view[3].toFixed(4),
        minX: view[0].toFixed(4),
        minY: view[1].toFixed(4),
        year: Year,
      };
      CensusTs.postCensusViewClass(params, (res) => {
        if (res.code === 200) {
          new Promise((resolve, err) => {
            let list = [];
            res.data.map((item, index) => {
              list.push({
                tab: item.className,
                LonLat: [item.lon, item.lat],
                classId: item.classId,
              });
              if (index === list.length - 1) {
                resolve(list);
              }
            });
          }).then((list: any) => {
            list.map((item) => {
              if (labelList.indexOf(item.classId) !== -1) {
                return (item.color = "#ff0");
              } else {
                return (item.color = "#fff");
              }
            });
            CMap.removeLayer("classTabs");
            CMap.addTabs({
              id: "classTabs",
              data: list,
              type: "Tab",
              fontSzie: 15,
            });
          });
        }
      });
    }
  };

  // 切换TabPane
  TabPanelist = (value) => {
    let { altInnerlist, TabPanelistIs, classSelect, areaCode, townCode } =
      this.state;
    if (Boolean(parseInt(value)) === TabPanelistIs) return;

    !TabPanelistIs
      ? (altInnerlist.data = altInnerlist.hard)
      : (altInnerlist.data = altInnerlist.level);

    classSelect = [false, false];
    this.setState(
      {
        TabPanelistIs: Boolean(parseInt(value)),
        altInnerlist,
        classSelect,
        textList: [],
        labelList: [],
      },
      () => {
        let newValue = parseInt(value) === 0 ? 1 : 0;
        this.clearlist(newValue);
        CMap.removeEvent("selectclass");
        //切换小班图层
        newValue
          ? CMap.removeLayer("hardlevelLayer")
          : CMap.removeLayer("maplevelLayer");
        this.state.townCode &&
          this.addClassLayer(areaCode, townCode, this.state.TabPanelistIs);
      }
    );
  };

  // 更改优先级或难易度小班等级
  optOn = (s) => {
    let { optOnIs } = this.state;
    s.id_ >= 4 ? (optOnIs[1] = s.name) : (optOnIs[0] = s.name);
    this.setState({
      optOnIs,
    });
  };

  // 清除单个
  clearsingle = (key) => {
    const { listData1, listData2, TabPanelistIs, labelList, moveMap } =
      this.state;
    if (
      labelList.indexOf(
        !TabPanelistIs ? listData1[key].id : listData2[key].id
      ) !== -1
    ) {
      labelList.splice(
        labelList.indexOf(
          !TabPanelistIs ? listData1[key].id : listData2[key].id
        ),
        1
      );
    }
    !TabPanelistIs ? listData1.splice(key, 1) : listData2.splice(key, 1);
    // 更新列表
    for (let i = 0; i < listData2.length; i++) {
      listData2[i] = { key: i, name: listData2[i].name, id: listData2[i].id };
    }
    for (let i = 0; i < listData1.length; i++) {
      listData1[i] = { key: i, name: listData1[i].name, id: listData1[i].id };
    }
    // 更新状态
    this.setState({
      listData1,
      listData2,
      labelList,
    });
    // 更新标签函数
    this.addClassTabs(moveMap);
  };

  // 返回小班
  blackClass = () => {
    const { classSelect, TabPanelistIs } = this.state;
    !TabPanelistIs
      ? (classSelect[0] = !classSelect[0])
      : (classSelect[1] = !classSelect[1]);
    this.setState({
      classSelect,
    });
    CMap.removeEvent("selectclass");
  };

  // 选择小班开关
  selectClass = () => {
    const { classSelect, TabPanelistIs, areaLevel } = this.state;
    if (areaLevel === "3") {
      !TabPanelistIs
        ? (classSelect[0] = !classSelect[0])
        : (classSelect[1] = !classSelect[1]);
      this.setState({
        classSelect,
      });

      this.MapSelectClass();
    } else {
      message.info("进入小班页面后开启该功能");
    }
  };

  //地图点击选择小班
  MapSelectClass = () => {
    CMap.addEvent("selectclass", "singleclick", (e) => {
      const {
        listData1,
        listData2,
        TabPanelistIs,
        labelList,
        Year,
        moveMap,
        areaCode,
      } = this.state;
      const lonlat = [
        e.coordinate[0].toFixed(4) * 1,
        e.coordinate[1].toFixed(4) * 1,
      ];
      console.log(areaCode);
      const params = {
        x: lonlat[0],
        y: lonlat[1],
        year: Year,
        areaCode,
      };
      if (e.map.getView().getZoom() > 17) {
        Request.getCensusClassLonlat(params, (res) => {
          if (res.code === 200) {
            const data = res.data;
            if (labelList.indexOf(data.classId) === -1) {
              labelList.push(data.classId);
            }
            let count = 0;
            if (!TabPanelistIs) {
              listData1.length > 0
                ? listData1.forEach((item) => {
                    if (data.classId === item.id) {
                      this.clearsingle(item.key);
                      count = 78;
                    }
                  })
                : (count = 0);
              count === 0 &&
                listData1.push({
                  key: listData1.length,
                  name: data.smallClassName,
                  id: data.classId,
                });
            } else {
              listData2.length > 0
                ? listData2.forEach((item) => {
                    if (data.classId === item.id) {
                      this.clearsingle(item.key);
                      count = 90;
                    }
                  })
                : (count = 0);
              count === 0 &&
                listData2.push({
                  key: listData2.length,
                  name: data.smallClassName,
                  id: data.classId,
                });
            }
            this.setState({
              listData1,
              listData2,
              labelList,
            });
            // 更新标签函数
            CMap.removeLayer("classTabs");
            this.addClassTabs(moveMap);
          }
        });
      } else {
        message.info("请放大到17级后再选择小班");
      }
    });
  };

  // 情况列表确认
  confirm(value) {
    message.success(
      (value === 0 ? "优先级" : "难易度") + "划分小班，已经全部清除."
    );
    this.clearlist(value);
  }

  //划分完成
  onFinished = (value) => {
    const { listData1, listData2, optOnIs, Year, TabPanelistIs, moveMap } =
      this.state;
    let ForList = value === 0 ? listData1 : listData2;
    new Promise((resolve, err) => {
      let newList = [];
      ForList.forEach((item, index) => {
        newList.push({
          classId: item.id,
          levelDifficulty: value === 0 ? "" : optOnIs[1],
          levelPriority: value === 0 ? optOnIs[0] : "",
          year: Year,
        });
        if (index === newList.length - 1) {
          resolve(newList);
        }
      });
    }).then((params) => {
      CensusTs.editDecison(params, (res) => {
        if (res.code === 200) {
          message.success("小班划分完毕");
          this.setState(
            {
              listData1: [],
              listData2: [],
              labelList: [],
            },
            () => {
              CMap.removeLayer("classTabs");
              if (!TabPanelistIs) {
                levelLayer
                  .getSource()
                  .updateParams({ time: new Date().getTime() });
              } else {
                hardLayer
                  .getSource()
                  .updateParams({ time: new Date().getTime() });
              }
              this.addClassTabs(moveMap); //添加小班标签

              // !TabPanelistIs ? CMap.removeLayer("maplevelLayer") : CMap.removeLayer("hardlevelLayer")
              // this.addClassLayer(areaCode, townCode, TabPanelistIs)
              // setTimeout(() => {
              //     CMap.removeLayer("classTabs")
              //     moveMap.map.getView().setZoom(15)
              // }, 1500)
            }
          );
        }
      });
    });
  };

  //返回上页
  backHistory = () => {
    const { changeCensus, isOpenCensus, getData } = this.props;
    changeCensus(isOpenCensus);
    getData();
  };

  // 清楚列表
  clearlist = (value) => {
    let { listData1, listData2, moveMap } = this.state;
    value === 0 ? (listData1 = []) : (listData2 = []);
    this.setState(
      {
        listData1,
        listData2,
        labelList: [],
      },
      () => {
        this.addClassTabs(moveMap);
      }
    ); // 更新标签函数
  };

  get hardSelect() {
    const { classSelect } = this.state;
    return classSelect[0];
  }

  get PriorSelect() {
    const { classSelect } = this.state;
    return classSelect[1];
  }

  get operateUI() {
    const { listData1, listData2, TabPanelistIs } = this.state;
    let value = !TabPanelistIs ? 0 : 1;
    let listData = !TabPanelistIs ? listData1 : listData2;
    return (value === 0 ? this.hardSelect : this.PriorSelect) ? (
      listData.length === 0 ? (
        <>
          <Button disabled>划分完毕</Button>
          <Button onClick={() => this.blackClass()}>返回</Button>
        </>
      ) : (
        <>
          <Button onClick={() => this.onFinished(value)} type="primary">
            划分完毕
          </Button>
          <Popconfirm
            placement="top"
            title={"确认清楚全部小班！"}
            onConfirm={() => this.confirm(value)}
            okText="是"
            cancelText="否"
          >
            <Button>清空所有</Button>
          </Popconfirm>
        </>
      )
    ) : (
      <Button type="primary" onClick={() => this.selectClass()}>
        选择小班
      </Button>
    );
  }

  get showbtn() {
    const { optOnIs, TabPanelistIs } = this.state;
    // UI级别选择按钮数据
    const ranklist = [
      [
        { id_: 1, name: "Ⅰ级", bgcolor: "#cc211f", color: "#ffffff" },
        { id_: 2, name: "Ⅱ级", bgcolor: "#e25c01", color: "#ffffff" },
        { id_: 3, name: "Ⅲ级", bgcolor: "#e0a501", color: "#ffffff" },
      ],
      [
        { id_: 4, name: "A级", bgcolor: "#0285e7", color: "#ffffff" },
        { id_: 5, name: "B级", bgcolor: "#2db3e6", color: "#ffffff" },
        { id_: 6, name: "C级", bgcolor: "#41c7a8", color: "#ffffff" },
      ],
    ];
    let s = !TabPanelistIs ? 0 : 1;
    return ranklist[s].map((item, index) => {
      return (
        <Button
          key={index}
          className={"level-btn"}
          // shape="circle"
          style={
            optOnIs[s] === item.name
              ? { background: item.bgcolor, color: item.color }
              : {}
          }
          onClick={() => this.optOn(item)}
        >
          {item.name}
        </Button>
      );
    });
  }

  get listShow() {
    const { listData1, listData2, TabPanelistIs } = this.state;
    return (
      <List
        size="large"
        bordered
        dataSource={!TabPanelistIs ? listData1 : listData2}
        renderItem={(item) => (
          <List.Item>
            {item.name}
            <DeleteOutlined
              rev={undefined}
              className={"icon yellow"}
              onClick={() => this.clearsingle(item.key)}
            />
          </List.Item>
        )}
      />
    );
  }

  // TabPane标签
  get tabTabPane() {
    const { TabPane } = Tabs;
    return ["优先级", "难易度"].map((item, index) => {
      return (
        <TabPane tab={item} key={index}>
          <div className={"census-map-list-list-level"}>{this.showbtn}</div>
          <div className={"census-map-list-list-processed"}>
            {this.operateUI}
          </div>
          <div className={"census-map-list-list-item"}>{this.listShow}</div>
        </TabPane>
      );
    });
  }

  render() {
    const { altInnerlist, areaLevel } = this.state;
    const { position } = this.props;
    return (
      <div className={"census-map"}>
        <div className={"census-map-container"}>
          <CMap
            id="census-map"
            layer={
              new TileLayer({
                source: new XYZ({
                  url: `https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}`,
                }),
                // source: new XYZ({
                //   url: `https://gac-geo.googlecnapps.cn/maps/vt?lyrs=s&x={x}&y={y}&z={z}`,
                // }),
              })
            }
            layerType={"TDTYXMap"}
            Zoom={9}
            CenterLonLat={position}
          ></CMap>
          <Button className={"back-btn"} onClick={() => this.backHistory()}>
            返回上页
          </Button>
          {areaLevel !== "1" && (
            <Button
              className={"backLevel-btn"}
              onClick={() => this.backNextLevel()}
            >
              返回上级
            </Button>
          )}
          <div className={"mapDivUi"}>
            {/* @ts-ignore */}
            {altInnerlist.data[0].map((item, index) => {
              return (
                <div key={index}>
                  {/* @ts-ignore */}
                  <span className={altInnerlist.data[1]}></span>
                  <span>{item}</span>
                </div>
              );
            })}
          </div>
        </div>
        <div className={"census-map-list"}>
          <Tabs
            defaultActiveKey="0"
            onChange={(value) => this.TabPanelist(value)}
          >
            {this.tabTabPane}
          </Tabs>
        </div>
      </div>
    );
  }
}
