import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Popconfirm,
  Popover,
  Select,
  Table,
  Upload,
  message,
  Image,
} from "antd";
import React, { Component, createRef } from "react";
import HouseAbTs from "../services/houseAb";
import FormItem from "antd/es/form/FormItem";
import Common from "../services/Common";
import { LeftOutlined, PlusOutlined, RightOutlined } from "@ant-design/icons";
import { pic_axios } from "../../utils/axios";
import moment from "moment";
import { HouseReport } from "../../component/acceptance/houseReport";

const { RangePicker } = DatePicker;
const { Option } = Select;
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
const dateFormat = "YYYY-MM-DD HH:mm:ss";

export default class HouseAb extends Component {
  ref: any = createRef();
  state = {
    dataList: [],
    loading: true,
    total: 0,
    page: 1,
    size: 10,
    title: "新增数据",
    dataFlag: false,
    cityCode: sessionStorage.getItem("cityCode"),
    countyList: [], //区县列表
    townList: [], //乡镇列表
    villageList: [], //行政村列表
    countyCode: "",
    townCode: "",
    villageCode: "",
    record: null, //修改时暂存数据
    fileList: [],
    selectedRowKeys: [],
    houseFlag: false,
    imgpage: 1,
    imgtotal: 0,
  };

  componentDidMount(): void {
    this.getData();
    this.getCountyList();
  }

  //获取数据
  getData = () => {
    const { page, size } = this.state;
    let params = {
      page,
      size,
    };
    HouseAbTs.getHouseTree(params).then((res) => {
      console.log(res);
      if (res.data.code === 200) {
        this.setState({
          loading: false,
          total: res.data.data.total,
          dataList: res.data.data.list,
        });
      }
    });
  };

  //删除单个数据
  deleteData = (record) => {
    console.log(record);
    HouseAbTs.delHouseById(record.id).then((res) => {
      if (res.data.code === 200) {
        message.success("删除成功！");
        this.getData();
      } else {
        message.error("删除失败！");
      }
    });
  };

  // 获取区县列表
  getCountyList = () => {
    const { cityCode } = this.state;
    Common.getCountyList({ cityCode }).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          countyList: res.data.data,
        });
      }
    });
  };

  // 获取乡镇列表
  getTownList = () => {
    const { countyCode } = this.state;
    // console.log(countyCode)
    Common.getTownList({ countyCode }).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          townList: res.data.data,
        });
      }
    });
  };

  // 获取行政村列表
  getVillageList = () => {
    const { countyCode, townCode } = this.state;
    Common.getVillageList({ countyCode, townCode }).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          villageList: res.data.data,
        });
      }
    });
  };

  //切换
  changePagination = (pagination) => {
    this.setState(
      {
        page: pagination.current,
        size: pagination.pageSize,
        loading: true,
      },
      this.getData
    );
  };

  //提交
  onFinish = (value) => {
    const { title, fileList, record } = this.state;
    console.log(value.checkDate);
    if (title === "新增数据") {
      const data = new FormData();
      value.file.fileList.forEach((file) => {
        data.append("file", file.originFileObj);
      });
      value.checkDate =
        value.checkDate && value.checkDate.format("YYYY-MM-DD HH:mm:ss");
      HouseAbTs.postPic(data).then((res) => {
        if (res.data.code === 200) {
          value.images = res.data.data;
          HouseAbTs.putHouseTree(value).then((res) => {
            if (res.data.code === 200) {
              message.success("新增成功！");
              this.getData();
              this.hideModal();
            } else {
              message.error("新增失败！");
            }
          });
        }
      });
    } else {
      const data = new FormData();
      let arr = [];
      fileList.forEach((file) => {
        if (file.originFileObj) {
          data.append("file", file.originFileObj);
        } else {
          arr.push(file.url);
        }
      });
      HouseAbTs.postPic(data).then((res) => {
        if (res.data.code === 200) {
          arr = [...arr, ...res.data.data];
          console.log(arr);
          value.images = arr;
          value.id = record.id;
          HouseAbTs.putHouseTree(value).then((res) => {
            if (res.data.code === 200) {
              message.success("修改成功！");
              this.getData();
              this.hideModal();
            } else {
              message.error("修改失败！");
            }
          });
        }
      });
    }
  };

  //取消
  hideModal = () => {
    this.setState({
      countyCode: "",
      townCode: "",
      villageCode: "",
      townList: [], //乡镇列表
      villageList: [], //行政村列表
      dataFlag: false,
      record: null,
    });
  };

  // 区县选择
  onCountyChanged = (value) => {
    let code = this.state.countyList.filter((item) => item.name === value)[0]
      .code;
    this.setState(
      {
        countyCode: code,
        townCode: null,
        villageCode: null,
      },
      () => {
        this.getTownList();
      }
    );
  };

  //关闭导出报告
  handleCancel = () => {
    this.setState({
      houseFlag: false,
    });
  };

  // 乡镇选择
  onTownChanged = (value) => {
    let code = this.state.townList.filter((item) => item.name === value)[0]
      .code;
    this.setState(
      {
        townCode: code,
        villageCode: null,
      },
      () => {
        this.getVillageList();
      }
    );
  };

  //批量删除 删删删！！！！！
  deleteMany = () => {
    const { selectedRowKeys } = this.state;
    HouseAbTs.delHouseByIds(selectedRowKeys).then((res) => {
      if (res.data.code === 200) {
        this.getData();
        this.setState({
          selectedRowKeys: [],
        });
        message.success("删除成功！");
      } else {
        message.error("删除失败！");
      }
    });
  };

  // 小班选择
  onVillageChanged = (value) => {
    this.setState({
      villageCode: value ? value : "",
    });
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRowKeys,
      deleteList: selectedRows,
    });
  };

  left = () => {
    const { imgpage } = this.state;
    if (imgpage - 1 > 1) {
      this.ref.current.style.left = "-" + (imgpage - 2) * 170 + "px";
      this.setState({
        imgpage: imgpage - 1,
      });
    } else {
      this.ref.current.style.left = "0px";
      this.setState({
        imgpage: 1,
      });
    }
  };

  right = () => {
    const { imgpage, imgtotal } = this.state;
    if (imgpage + 1 <= imgtotal) {
      this.ref.current.style.left = "-" + imgpage * 170 + "px";
      this.setState({
        imgpage: imgpage + 1,
      });
    }
  };

  render() {
    const {
      dataList,
      loading,
      total,
      page,
      size,
      title,
      dataFlag,
      countyList,
      townList,
      villageList,
      record,
      fileList,
      selectedRowKeys,
      houseFlag,
    } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const columns = [
      {
        title: "区县",
        width: 150,
        dataIndex: "countyName",
        align: "center" as "center",
        key: "countyName",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "乡镇",
        width: 120,
        align: "center" as "center",
        dataIndex: "townName",
        key: "townName",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "行政村",
        align: "center" as "center",
        dataIndex: "villageName",
        width: 150,
        key: "villageName",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "抽查户数",
        dataIndex: "spotCheckNum",
        align: "center" as "center",
        width: 100,
        key: "spotCheckNum",
        ellipsis: true,
        render: (text, record) => <span>{text || 0}</span>,
      },
      {
        title: "堆放户数",
        dataIndex: "stackNum",
        align: "center" as "center",
        width: 120,
        key: "stackNum",
        render: (text, record) => <span>{text || 0}</span>,
      },
      {
        title: "现场照片",
        dataIndex: "picUrl",
        key: "picUrl",
        width: 80,
        align: "center" as "center",
        render: (text, record) => (
          <Popover
            content={
              <div className={"pic-popover"}>
                <div onClick={this.left} className={"left-btn btn"}>
                  <LeftOutlined rev={undefined} />
                </div>
                <div className={"pic-content"}>
                  {record.images !== 0 ? (
                    <div ref={this.ref} className={"center"}>
                      <Image.PreviewGroup>
                        {record.images.map((item) => {
                          return (
                            <div className={"pic-box"}>
                              <Image width={80} height={80} src={item} />
                            </div>
                          );
                        })}
                      </Image.PreviewGroup>
                    </div>
                  ) : (
                    <div className={"none-text"}>暂无现场照片</div>
                  )}
                </div>
                <div onClick={this.right} className={"right-btn btn"}>
                  <RightOutlined rev={undefined} />
                </div>
              </div>
            }
            title="现场照片"
            trigger="click"
          >
            <Button
              type="link"
              onClick={() => {
                this.setState({
                  imgtotal: record.images.length,
                });
              }}
            >
              查看
            </Button>
          </Popover>
        ),
      },
      {
        title: "疫木流失情况",
        dataIndex: "lossSituation",
        align: "center" as "center",
        width: 100,
        key: "lossSituation",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "检查时间",
        dataIndex: "checkDate",
        align: "center" as "center",
        width: 100,
        key: "checkDate",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "操作",
        width: 130,
        className: "handle",
        dataIndex: "handle",
        key: "handle",
        align: "center" as "center",
        render: (text, record) => (
          <>
            <Button
              type="link"
              onClick={() => {
                console.log(record);
                let fileList = [];
                record.images.length >= 1 &&
                  record.images.map((item, index) => {
                    fileList.push({ url: item });
                  });
                this.setState({
                  dataFlag: true,
                  title: "修改数据",
                  record,
                  fileList,
                });
              }}
            >
              修改
            </Button>
            <Popconfirm
              title="确定要删除当前记录吗?"
              onConfirm={() => {
                this.deleteData(record);
              }}
              okText="确定"
              cancelText="取消"
            >
              <Button type="link">删除</Button>
            </Popconfirm>
          </>
        ),
      },
    ];
    const pagination = {
      total,
      current: page,
      pageSize: size,
      showQuickJumper: true,
      responsive: false,
      showSizeChanger: true,
    };
    const props: any = {
      listType: "picture-card",
      beforeUpload: () => {
        return false;
      },
      onChange: (file) => {
        // let fileListNew = this.state.fileList
        // fileListNew.push(file.file)
        // console.log(fileListNew)
        this.setState({
          fileList: file.fileList,
        });
      },
      accept: ".png,.jpg,.jpeg",
      showUploadList: {
        showPreviewIcon: false,
        showRemoveIcon: true,
        showDownloadIcon: false,
      },
    };
    const rangeConfig = {
      // rules: [{ type: 'object' as const, required: true, message: 'Please select time!' }],
    };
    return (
      <>
        <div className="houseData">
          <div className="userHeader">
            <div>
              <Button
                type="primary"
                onClick={() => {
                  this.setState({
                    dataFlag: true,
                    title: "新增数据",
                  });
                }}
              >
                添加数据
              </Button>

              <Button
                type="primary"
                style={{ marginLeft: "20px" }}
                onClick={() => {
                  this.setState({
                    houseFlag: true,
                  });
                }}
              >
                导出报告
              </Button>
              <Popconfirm
                title="确定要删除当前记录吗?"
                onConfirm={this.deleteMany}
                okText="确定"
                cancelText="取消"
              >
                <Button type="primary" style={{ marginLeft: "20px" }}>
                  批量删除
                </Button>
              </Popconfirm>
            </div>
          </div>
          <Table
            dataSource={dataList}
            rowKey={(record) => record.id}
            columns={columns}
            loading={loading}
            onChange={(pagination) => this.changePagination(pagination)}
            pagination={pagination}
            rowSelection={rowSelection}
            scroll={{ y: "calc(100vh - 22.5rem)" }}
          />
        </div>
        {houseFlag && (
          <HouseReport
            visible={houseFlag}
            handleCancel={this.handleCancel}
            areaLevel={1}
          ></HouseReport>
        )}
        <Modal
          title={title}
          visible={dataFlag}
          footer={null}
          destroyOnClose={true}
          onCancel={this.hideModal}
        >
          <Form
            onFinish={this.onFinish}
            initialValues={{
              ...record,
              checkDate: record && moment(record.checkDate, dateFormat),
            }}
          >
            <Form.Item
              name="countyName"
              label="区县"
              hasFeedback
              rules={[{ required: true, message: "请选择区县！" }]}
            >
              <Select placeholder="请选择区县" onChange={this.onCountyChanged}>
                {countyList.map((item, index) => {
                  return (
                    <Option value={item.name} key={index}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="townName"
              label="乡镇"
              hasFeedback
              rules={[{ required: true, message: "请选择乡镇！" }]}
            >
              <Select placeholder="请选择乡镇" onChange={this.onTownChanged}>
                {townList.map((item, index) => {
                  return (
                    <Option value={item.name} key={index}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="villageName"
              label="行政村"
              hasFeedback
              rules={[{ required: true, message: "请选择行政村！" }]}
            >
              <Select
                placeholder="请选择行政村"
                onChange={this.onVillageChanged}
              >
                {villageList.map((item, index) => {
                  return (
                    <Option value={item.name} key={index}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name="checkDate" label="检查时间" {...rangeConfig}>
              <DatePicker
                showTime
                disabled={title == "修改数据" ? true : false}
              />
            </Form.Item>
            <FormItem
              label="抽查户数"
              name="spotCheckNum"
              rules={[{ required: true, message: "请输入户数！" }]}
            >
              <Input type="number" addonAfter="户" />
            </FormItem>
            <FormItem
              label="堆放户数"
              name="stackNum"
              rules={[{ required: true, message: "请输入户数！" }]}
            >
              <Input type="number" addonAfter="户" />
            </FormItem>
            <FormItem
              label="图片上传"
              name="file"
              rules={[
                {
                  required: false,
                  message: "请选择验收单照片",
                },
              ]}
              {...layout}
            >
              {title == "修改数据" ? (
                <>
                  <Upload {...props} fileList={record ? fileList : []}>
                    <div>
                      <PlusOutlined rev={undefined} />
                      <div style={{ marginTop: 8 }}>上传</div>
                    </div>
                  </Upload>
                </>
              ) : (
                <Upload {...props}>
                  <div>
                    <PlusOutlined rev={undefined} />
                    <div style={{ marginTop: 8 }}>上传</div>
                  </div>
                </Upload>
              )}
            </FormItem>
            <div className="form-button">
              <FormItem>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginRight: "10px" }}
                >
                  确认
                </Button>
                <Button onClick={this.hideModal}>取消</Button>
              </FormItem>
            </div>
          </Form>
        </Modal>
      </>
    );
  }
}
