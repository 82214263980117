import { Input, message, Select } from "antd";
import "./index.scss";
import { useEffect, useState } from "react";
import Common from "../services/Common";
import { InspectionList } from "./data";
import EditHistoryTable from "./components/editHistoryTable";
import HistoryService from "../services/History";
import Acceptance from "../services/Acceptance";
import EditModal from "./components/editModal";

const { Search } = Input;

interface Props {
  year: any;
  cityCode: string;
  locationState: any;
}

const EditHistory = ({ year, cityCode }: Props) => {
  const [countyList, setCountList] = useState([]);

  const [countyCode, setCountyCode] = useState(undefined);

  const [isInspection, setIsInspection] = useState(0);

  const [className, setClassName] = useState("");

  const [dataSource, setDataSource] = useState([]);

  const [editData, setEditData] = useState({
    id: 0,
  });

  const [modalVisible, setModalVisible] = useState(false);

  /**
   * @description 获取区县列表
   */

  const getCountyList = () => {
    Common.getCountyList({ cityCode }).then((res) => {
      if (res.data.code === 200) {
        setCountList(
          res.data.data.map((item) => {
            return {
              value: item.code,
              label: item.name,
            };
          })
        );
      }
    });
  };

  /**
   * @description 改变区县code
   */

  const changeCountyCode = (value) => {
    setCountyCode(value);
  };

  const changeIsInspection = (value) => {
    setIsInspection(value);
  };

  /**
   * @description 输入框内容改变
   */

  const searchChange = (value) => {
    if (value.target.value !== className) {
      setClassName(value.target.value);
    }
  };

  /**
   * @description 点击搜索
   */

  const handleSearch = (value) => {
    setClassName(value);
  };

  /**
   * @description 获取列表数据
   */

  const getDataSource = () => {
    HistoryService.getHistoryData({
      countyCode,
      className,
      isInspection,
      year,
    }).then((res) => {
      if (res.data.code === 200) {
        setDataSource(res.data.data);
      }
    });
  };

  const onEdit = (data) => {
    setEditData(data);
    setModalVisible(true);
  };

  /**
   * @description 删除数据
   */

  const onDelete = (data) => {
    Acceptance.deleteData({ id: data.id }).then((res) => {
      if (res.data.code === 200) {
        message.success("删除成功");
        getDataSource();
      }
    });
  };

  const modalClose = () => {
    setEditData({ id: 0 });
    setModalVisible(false);
    getDataSource();
  };

  useEffect(() => {
    getDataSource();
  }, [countyCode, className, isInspection]);

  useEffect(() => {
    getCountyList();
  }, [cityCode]);

  return (
    <>
      <div className="edit_history">
        <div className="edit_history_head">
          <Select
            className="county_select"
            placeholder="区县"
            allowClear={true}
            options={countyList}
            value={countyCode}
            onChange={changeCountyCode}
          />
          <Select
            className="type_select"
            options={InspectionList}
            value={isInspection}
            onChange={changeIsInspection}
          />
          <Search
            className="search_box"
            placeholder="请输入小班名称"
            enterButton="搜索"
            // onChange={searchChange}
            onSearch={handleSearch}
          />
        </div>
        <div className="edit_history_body">
          <EditHistoryTable
            dataSource={dataSource}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </div>
      </div>
      {modalVisible && <EditModal editData={editData} onClose={modalClose} />}
    </>
  );
};

export default EditHistory;
