import { Button, Table, Tooltip, Popconfirm } from "antd";

interface Props {
  dataSource: any[];
  onEdit: (data: any) => void;
  onDelete: (data: any) => void;
}

const EditHistoryTable = ({ dataSource, onEdit, onDelete }: Props) => {
  const acceptanceResult = {
    "1": "验收通过",
    "0": "验收不通过",
    "-1": "待审核",
    "3": "不纳入考核",
  };

  const acceptanceResultColor = {
    "1": "green",
    "0": "red",
    "-1": "#000000",
    "3": "#000000",
  };
  const columns = [
    {
      title: "区县",
      dataIndex: "countyName",
      key: "countyName",
    },
    {
      title: "乡镇/街道",
      dataIndex: "townName",
      key: "townName",
    },
    {
      title: "小班名称",
      dataIndex: "className",
      key: "className",
    },
    {
      title: "验收次数",
      dataIndex: "frequency",
      key: "frequency",
    },
    {
      title: "验收结果",
      dataIndex: "result",
      key: "result",
      width: "105px",
      render: (text, record, index) => (
        <span
          style={{ color: acceptanceResultColor[record.result.toString()] }}
        >
          {acceptanceResult[record.result.toString()]}
        </span>
      ),
    },
    {
      title: "验收情况",
      dataIndex: "situation",
      key: "situation",
      width: "332px",
      render: (text, record) => (
        <Tooltip placement="top" title={text}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "300px",
            }}
          >
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "验收时间",
      dataIndex: "createDate",
      key: "createDate",
    },
    {
      title: "操作",
      dataIndex: "",
      key: "",
      width: "160px",
      render: (_, record) => {
        return (
          <>
            <Button type="link" onClick={() => onEdit(record)}>
              修改
            </Button>
            <Popconfirm
              title="是否删除当前数据"
              onConfirm={() => onDelete(record)}
            >
              <Button type="link">删除</Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={dataSource}
        rowKey={(recode) => recode.id}
        pagination={false}
      />
    </>
  );
};

export default EditHistoryTable;
