import { useState, useEffect } from "react";

import { Button, Table, message, Popconfirm } from "antd";

import Acceptance from "../services/Acceptance";

function ErrorHandle() {
  const [dataSource, setDataSource] = useState([]);
  const [paging, setPaging] = useState({
    page: 1,
    size: 9,
  });
  const [reloadFlag, setReloadFlag] = useState(true);
  const reload = () => setReloadFlag((x) => !x);
  const [dataSourceTotal, setDataSourceTotal] = useState(0);
  const [selectedRows, setSelectedRow] = useState([]);

  const setProcessedStatus = async (records) => {
    const ids = records.length ? records.map((item) => item.id) : [];
    if (ids.length === 0) {
      return message.info("请选择要处理的数据");
    } else {
      console.log(ids);
      const { data: res } = await Acceptance.UpdateMistakeRecord({
        ids,
        status: 1,
      });
      console.log(res);
      if (res.code !== 200) {
        message.error("处理失败");
      } else {
        message.success("处理成功");
        reload();
      }
    }
  };

  const columns = [
    {
      title: "区县名称",
      dataIndex: "countyName",
      key: "countyName",
    },
    {
      title: "乡镇名称",
      dataIndex: "townName",
      key: "townName",
    },
    {
      title: "小班名称",
      dataIndex: "className",
      key: "className",
    },
    {
      title: "小班id",
      dataIndex: "classId",
      key: "classId",
    },
    {
      title: "类型",
      dataIndex: "isInspection",
      key: "isInspection",
      render: (text) => <>{text === 0 ? "跟踪验收" : "监理检查"}</>,
    },
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      render: (text) => <>{text === 0 ? "未处理" : "已处理"}</>,
    },
    {
      title: "年度",
      dataIndex: "year",
      key: "year",
    },
    {
      title: "操作",
      key: "handle",
      render: (_, record) => (
        <Popconfirm
          title="确认修改状态为已处理？"
          onConfirm={() => {
            setProcessedStatus([record]);
          }}
          okText="确定"
          cancelText="取消"
        >
          <Button type="link">处理</Button>
        </Popconfirm>
      ),
    },
  ];

  const getMistackRecord = async () => {
    const { data: res } = await Acceptance.getMistakeRecord({
      year: sessionStorage.getItem("year"),
      page: paging.page,
      size: paging.size,
    });
    if (res.code === 200) {
      setDataSource(res.data.list);
      setDataSourceTotal(res.data.total);
    } else {
      message.error("获取失败");
    }
  };

  const pagination = {
    total: dataSourceTotal,
    current: paging.page,
    pageSize: paging.size,
    showQuickJumper: true,
    responsive: false,
    showSizeChanger: true,
  };

  const changePagination = (pagination) => {
    setPaging({
      page: pagination.current,
      size: pagination.pageSize,
    });
  };

  const onSelectChange = (_, selectedRows) => {
    setSelectedRow(selectedRows);
  };
  const rowSelection = {
    selectedRowKeys:
      selectedRows.length > 0 ? selectedRows.map((item) => item.id) : [],
    onChange: onSelectChange,
  };

  useEffect(() => {
    getMistackRecord();
  }, [sessionStorage.getItem("year"), paging.page, paging.size, reloadFlag]);

  return (
    <div>
      <div>
        <Popconfirm
          title="确认批量处理？"
          onConfirm={() => {
            setProcessedStatus(selectedRows);
          }}
          okText="确定"
          cancelText="取消"
        >
          <Button type="primary">批量处理</Button>
        </Popconfirm>
      </div>
      <Table
        style={{ marginTop: "16px" }}
        dataSource={dataSource}
        columns={columns}
        pagination={pagination}
        rowKey={(record) => record.id}
        onChange={changePagination}
        rowSelection={rowSelection}
      />
    </div>
  );
}

export default ErrorHandle;
