import React, { Component } from "react";
import "./index.scss";
import { CMap } from "@dinnovate/cloudmap_npm";
import { Button, Empty, message, Pagination, Switch, Tooltip } from "antd";
import infoImg from "../../assets/icons/icon/png/info-circle.png";
import error from "../../assets/icons/icon/svg/error.svg";
import success from "../../assets/icons/icon/svg/success.svg";
import Request from "../../services/Manger";
import PlagueWoodTs from "../services/plagueWood";
import {
  classBaseLayer,
  completeClass,
  woodLayer,
  afterAcceptLayer,
} from "./layer";
import { FullscreenOutlined } from "@ant-design/icons";
import { InfoWindow } from "./components";
import icon from "../../assets/icons/label/circle.svg";
import { log } from "console";
import TileLayer from "ol/layer/Tile";
import { XYZ } from "ol/source";

interface Props {
  isplagueWood: boolean;
  changeWood: (e: any) => void;
  changeRecord: () => void;
  getData: () => void;
  record: any;
  areaCode: any;
  year: any;
  position: any;
}
const url = window["env"].LayerUrl + "/geoserver/pine-epidemic-census/wms";

const tipObj = {
  1: {
    text: "新增成功",
    icon: success,
  },
  2: {
    text: "清除成功",
    icon: success,
  },
  3: {
    text: "新增失败",
    icon: error,
  },
  4: {
    text: "清除失败",
    icon: error,
  },
  5: {
    text: "已完成",
    icon: success,
  },
};

//疫木点位图层
const woodLayerLoding = {
  id: "woodLayer",
  url,
  params: {
    ...woodLayer,
    viewparams: `areaCode:${331100};areaLevel:${1};year:2021-2022`,
  },
};

const classLayer = {
  id: "classLayer",
  url,
  params: {
    ...classBaseLayer,
    viewparams: `areaCode:${331124015};areaLevel:${3};year:2021-2022`,
  },
};
const completeLayer = {
  id: "completeLayer",
  url,
  opacity: 0.8,
  params: {
    ...completeClass,
    time: new Date().getTime(),
    viewparams: `areaCode:${331124015};areaLevel:${3};year:2021-2022`,
  },
};

const afterSmallClassLayer = {
  id: "afterAcceptLayer",
  url,
  params: {
    ...afterAcceptLayer,
    viewparams: `areaCode:${331102001};areaLevel:${3};year:2022-2023`,
  },
};
let wmslayer: any;
let regionParam = {
  id: "regionlayer",
  geometryList: [],
};
export default class Map extends Component<Props, any> {
  state = {
    openModalIs_: 0,
    tipIs_: 0, //弹框提示
    add: [],
    box: [],
    isDoubleClick: false, //只允许点击一次
    moveMap: null, //map实例对象
    areaCode: this.props.areaCode, //选中的县级/乡镇的code
    areaLevel: "1", //选中的县级/乡镇的level，   "4" 小班跳转赋予的值，用来判断跳转还是点击进入的小班
    areaLonlat: [], //选中的县级/乡镇的经纬度
    levelTwoAreaLonlat: [], //二级区域经纬度
    undealData: [], //未处理小班数据列表
    theClassId: "", //选中小班id
    TipVisable: false,
    Tipkey: 1,
    theWoodPoints: [], //视角范围中的所有点位
    theWoodYellowPoints: [], //视角范围中的黄色点位
    theWoodBluePoints: [], //视角范围中的蓝色点位
    viewparams: "", //选中小班wms图层参数
    ragionTabs: [], //行政区标签
    page: 1, //待处理小班查询页数
    total: 0, //待处理小班页码总数
    acceptSmallFlag: false, //已验收小班图层是否打开
    completeLayerSwitch: false, //疫木完成开关按钮
  };

  componentDidMount(): void {
    const { record, areaCode } = this.props;
    const { moveMap } = this.state;
    this.addOrthoLayer(); //加载正射图层
    CMap.addEvent("movegetView", "moveend", (e) => {
      //视角范围加载小班疫木点位
      !moveMap && this.setState({ moveMap: e });
      this.getWoodPoints(e);
    });
    this.areaMapClick(); //小班点击弹窗
    if (!record) {
      this.getCountryData(areaCode);
      this.countryAreaClick();
    }
    this.getUndealClass(record);
  }

  //地图模块点击事件
  countryAreaClick = () => {
    CMap.addEvent("region", "click", (e: any) => {
      //添加地图鼠标点击事件
      const f = e.map.forEachFeatureAtPixel(e.pixel, (feature: any) => {
        //如果点击了地图上的标注物返回标注物的value
        return feature.values_;
      });
      if (f) {
        // if (sessionStorage.getItem('year') !== '2023-2024' || f.data.areaLevel !== '3') {

        // }
        this.setState({
          areaCode: f.data.areaCode,
          areaLevel: f.data.areaLevel,
          areaLonlat: [parseFloat(f.data.lon), parseFloat(f.data.lat)],
        });
        if (f.data.areaLevel === "2") {
          this.setState({
            levelTwoAreaLonlat: [
              parseFloat(f.data.lon),
              parseFloat(f.data.lat),
            ],
          });
        }
      }
    });
  };

  //修改小班疫木处理状态
  onCompleteClass = (classId) => {
    const year = sessionStorage.getItem("year");
    PlagueWoodTs.changeClassStatus(classId, year, (res) => {
      if (res.code === 200) {
        message.success("小班状态修改成功");
        wmslayer.getSource().updateParams({ time: new Date().getTime() });
      } else {
        message.error("修改失败");
      }
    });
  };

  //处理小班信息点击事件
  areaMapClick = () => {
    const { year } = this.props;
    const { areaCode } = this.state;
    console.log(123);
    CMap.addEvent("classClick", "singleclick", (e) => {
      if (e.map.getView().getZoom() > 17) {
        const lonlat = [
          e.coordinate[0].toFixed(4) * 1,
          e.coordinate[1].toFixed(4) * 1,
        ];
        const params = {
          x: lonlat[0],
          y: lonlat[1],
          year: year,
          areaCode: areaCode,
        };
        Request.getCensusClassLonlat(params, (res) => {
          if (res.code === 200) {
            const overlayData = {
              id: res.data.classId,
              lonlat: [lonlat[0], lonlat[1]],
              stopEvent: true,
              InfoWin: (
                <InfoWindow
                  data={res.data}
                  onCompleteClass={this.onCompleteClass}
                />
              ),
              offset: [0, -20],
              time: 3000,
            };
            CMap.addOverlay(overlayData);
          } else {
            message.error("获取小班信息失败");
          }
        });
      }
    });
  };

  //获取待处理小班
  getUndealClass = (record) => {
    const { page } = this.state;
    const { year, areaCode, changeRecord, changeWood, isplagueWood } =
      this.props;
    const param = {
      classId: record && record.classId,
      page,
      size: 10,
      year,
      countyCode: areaCode,
    };
    Request.undealClassList(param, (res: any) => {
      if (res.code == 200) {
        this.setState({
          undealData: res.data.list,
          total: res.data.total,
          page: res.data.total === 0 ? 0 : 1,
        });
        if (record) {
          if (res.data.list.length !== 0) {
            const [lon, lat] = [res.data.list[0].lon, res.data.list[0].lat];
            CMap.mapMove([lon, lat], 18);
            this.addClassLayer(record.countyCode + record.townCode);
            this.setState({
              theClassId: record.classId,
              undealData: res.data.list,
              areaLevel: "4",
            });
            changeRecord();
          } else {
            message.info("当前待处理小班在地图上不存在数据");
            changeWood(isplagueWood);
            changeRecord();
          }
        }
      }
    });
  };

  //修改页码
  PagChange = (page) => {
    this.setState(
      {
        page: page,
      },
      () => {
        const { page } = this.state;
        this.getClassList(page);
      }
    );
  };

  //疫木geoserver图层
  addWoodLayer = (areaCode) => {
    const { year } = this.props;
    woodLayerLoding.params.viewparams = `areaCode:${areaCode};areaLevel:${3};year:${year}`;
    CMap.addWMSLayer(woodLayerLoding);
  };

  //加载正射图层
  addOrthoLayer = () => {
    const { areaCode } = this.props;
    const year = sessionStorage.getItem("year");
    PlagueWoodTs.getOrthoImage({ year: year, areaCode: areaCode }, (res) => {
      if (res.code === 200) {
        res.data.forEach((item, index) => {
          fetch(item.baseJson + "?" + `token=${window["env"].CLOUD_TOKEN}`)
            .then((res) => res.json())
            .then((data) => {
              if (data) {
                const { bounds, maxzoom } = data;
                const { east, north, south, west } = bounds;
                const param = {
                  id: `zhengshe_${item.id}`,
                  zIndex: 1,
                  minZoom: 11,
                  maxZoom: maxzoom,
                  extent: [west, south, east, north],
                  url:
                    item.baseUrl + "?" + `token=${window["env"].CLOUD_TOKEN}`,
                };
                CMap.addXYZLayer(param);
              }
            });
        });
      } else {
        message.error("正射图层获取失败");
      }
    });
  };

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {
    const {
      theWoodPoints,
      areaCode,
      areaLevel,
      areaLonlat,
      ragionTabs,
      theWoodBluePoints,
      theWoodYellowPoints,
      levelTwoAreaLonlat,
      acceptSmallFlag,
      completeLayerSwitch,
    } = this.state;

    if (prevState.theWoodPoints != theWoodPoints) {
      //监听疫木点位数据变化
      let paramYellow: any = {
        id: "classWoodLayerYellow",
        geometryList: theWoodYellowPoints,
        style: {
          fillColor: "yellow",
          strokeColor: "yellow",
          width: 1.5,
        },
      };
      let paramBlue: any = {
        id: "classWoodLayerBlue",
        geometryList: theWoodBluePoints,
        style: {
          fillColor: "blue",
          strokeColor: "blue",
          width: 1.5,
        },
      };
      CMap.removeLayer("classWoodLayerYellow");
      CMap.removeLayer("classWoodLayerBlue");
      CMap.addVectorLayer(paramYellow);
      CMap.addVectorLayer(paramBlue);
    }
    if (prevState.areaCode != areaCode) {
      //监听areaCode变化
      CMap.removeLayer("regionlayer");
      if (areaLevel == "2") {
        this.getTownData(areaCode);
        CMap.removeLayer("classLayer");
        // console.log(sessionStorage.getItem('year') === '2023-2024');
        if (sessionStorage.getItem("year") === "2023-2024") {
          CMap.removeLayer("afterSmallClassLayer");
          // this.addClassLayer(areaCode);
          acceptSmallFlag && this.addAfterAccept(areaCode);
          // this.addFinishedClass(areaCode); //完成处理的小班
        } else {
          this.addClassLayer(areaCode);
        }
        CMap.mapMove(
          levelTwoAreaLonlat.length !== 0 ? levelTwoAreaLonlat : areaLonlat,
          11
        ); //地图移动 [119.99073218249146, 28.432226986911104]
      } else if (areaLevel == "3") {
        // console.log(123)

        CMap.removeLayer("afterSmallClassLayer");
        acceptSmallFlag && this.addAfterAccept(areaCode);
        this.addClassLayer(areaCode);
        this.addFinishedClass(areaCode); //完成处理的小班
        this.addWoodLayer(areaCode);
        CMap.removeLayer("tabs"); //清除行行政区标签
        ragionTabs.forEach((e) => {
          if (e.LonLat[0] == areaLonlat[0]) {
            this.addRegionTabs([e]);
          }
        });
        CMap.mapMove(areaLonlat, 13); //地图移动 [119.99073218249146, 28.432226986911104]
      }
    }
    if (
      acceptSmallFlag != prevState.acceptSmallFlag &&
      acceptSmallFlag &&
      areaLevel === "3" &&
      sessionStorage.getItem("year") !== "2023-2024"
    ) {
      this.addAfterAccept(areaCode);
    }
    if (
      acceptSmallFlag != prevState.acceptSmallFlag &&
      acceptSmallFlag &&
      areaLevel !== "1" &&
      sessionStorage.getItem("year") === "2023-2024"
    ) {
      this.addAfterAccept(areaCode);
    }
    if (
      ((acceptSmallFlag != prevState.acceptSmallFlag && !acceptSmallFlag) ||
        areaLevel !== "3") &&
      sessionStorage.getItem("year") !== "2023-2024"
    ) {
      CMap.removeLayer("afterAcceptLayer");
    }
    if (
      acceptSmallFlag != prevState.acceptSmallFlag &&
      (!acceptSmallFlag || areaLevel === "1") &&
      sessionStorage.getItem("year") === "2023-2024"
    ) {
      CMap.removeLayer("afterAcceptLayer");
      areaLevel == "2" && this.getTownData(areaCode);
    }
  }

  //获取小班列表
  getClassList = (page) => {
    const { year } = this.props;
    const param = {
      page,
      size: 10,
      year,
    };
    Request.undealClassList(param, (res: any) => {
      if (res.code == 200) {
        this.setState({
          undealData: res.data.list,
          total: res.data.total,
        });
      }
    });
  };

  //获取县级边界数据
  getCountryData = (code: any) => {
    Request.CountryBorderlist(code, (res) => {
      if (res.code == 200) {
        this.addRegionlayer(res.data);
      }
    });
  };

  //获取乡镇边界数据
  getTownData = (code: any) => {
    Request.StreetBorderlist(code, (res) => {
      if (res.code == 200) {
        this.addRegionlayer(res.data);
      }
    });
  };

  //返回上一级
  backNextLevel = () => {
    const { areaCode, areaLevel, levelTwoAreaLonlat } = this.state;
    CMap.removeLayer("regionlayer");
    CMap.removeLayer("woodLayer"); //疫木点位图层
    switch (areaLevel) {
      case "2":
        this.getCountryData(this.props.areaCode);

        CMap.mapMove(this.props.position, 9); //地图移动 [119.99073218249146, 28.432226986911104]
        this.setState({
          areaLevel: "1",
          areaCode: this.props.areaCode,
          levelTwoAreaLonlat: [],
        });
        break;
      case "3":
        const newAreaCode = areaCode.slice(0, 6);
        CMap.mapMove(levelTwoAreaLonlat, 11); //地图移动 [119.99073218249146, 28.432226986911104]
        CMap.removeLayer("classLayer"); //清除小班图层
        CMap.removeLayer("completeLayer"); //清除小班状态的图层
        CMap.removeLayer("woodLayer"); //疫木点位图层
        this.setState({
          areaLevel: "2",
          areaCode: newAreaCode,
        });
        break;
      case "4":
        this.getCountryData(this.props.areaCode);
        CMap.removeLayer("classLayer"); //清除小班图层
        CMap.removeLayer("completeLayer"); //清除小班状态的图层
        CMap.mapMove(this.props.position, 9); //地图移动 [119.99073218249146, 28.432226986911104]
        this.countryAreaClick();
        this.setState({
          areaLevel: "1",
        });
        break;
    }
  };

  //加载行政边界图层
  addRegionlayer = (data: any) => {
    let list: any[] = [];
    let tabList: any[] = [];
    // console.log(data);
    CMap.removeLayer("tabs"); //清除行行政区标签
    data.forEach((item: any, i: any) => {
      if (item.json) {
        let obj = { ...item, ...JSON.parse(item.json) };
        delete obj.json;
        list.push(obj);
        tabList.push({
          LonLat: [parseFloat(item.lon), parseFloat(item.lat)],
          tab: item.name,
          ...obj,
        });
      }
    });
    const style = {
      fillColor: "#32c5ff28",
      strokeColor: "#989fa6",
      width: 1.5,
    };
    const param = {
      ...regionParam,
      geometryList: list,
      style,
    };

    CMap.addVectorLayer(param);
    this.addRegionTabs(tabList);
    this.setState({
      ragionTabs: tabList,
    });
  };

  //加载小班图层
  addClassLayer = (code: any) => {
    const { areaLevel } = this.state;
    const { areaCode } = this.props;
    const newAreaCode = this.state.areaCode.slice(0, 6);
    let years = sessionStorage.getItem("year");
    this.setState({
      areaCode: code,
    });
    //石可破也，而不可夺坚；丹可磨也，而不可夺赤。 --吕氏春秋
    if (years === "2023-2024" && newAreaCode === "331102") {
      classLayer.params.viewparams = `areaCode:${code};areaLevel:${areaLevel};year:2022-2023`;
    } else {
      if (
        newAreaCode === "331102" ||
        areaCode === "330300" ||
        areaCode === "330800"
      ) {
        classLayer.params.viewparams = `areaCode:${code};areaLevel:${3};year:2022-2023`;
      } else {
        classLayer.params.viewparams = `areaCode:${code};areaLevel:${3};year:2021-2022`;
      }
    }

    CMap.addWMSLayer(classLayer);
  };

  //加载已验收小班图层
  addAfterAccept = (code: any) => {
    const { areaLevel } = this.state;
    if (sessionStorage.getItem("year") === "2023-2024") {
      afterSmallClassLayer.params.viewparams = `areaCode:${331102};areaLevel:${2};year:2022-2023`;
      CMap.removeLayer("tabs");
      CMap.addWMSLayer(afterSmallClassLayer);
      CMap.removeLayer("regionlayer");
    } else {
      afterSmallClassLayer.params.viewparams = `areaCode:${code};areaLevel:${3};year:2022-2023`;

      CMap.addWMSLayer(afterSmallClassLayer);
    }
  };

  //加载已完成疫木处理的小班图层
  addFinishedClass = (code: any) => {
    const { areaCode } = this.props;
    const { areaLevel, completeLayerSwitch } = this.state;
    let years = sessionStorage.getItem("year");
    let xian = code.slice(0, 6);
    let str = completeLayerSwitch ? ";state:2" : "";
    if (years === "2023-2024") {
      completeLayer.params.viewparams =
        `areaCode:${code};areaLevel:${areaLevel};year:2023-2024` + str;
    } else {
      if (xian === "331102" || areaCode === "330300" || areaCode === "330800") {
        completeLayer.params.viewparams =
          `areaCode:${code};areaLevel:${3};year:2022-2023` + str;
      } else {
        completeLayer.params.viewparams =
          `areaCode:${code};areaLevel:${3};year:2021-2022` + str;
      }
    }

    wmslayer = CMap.addWMSLayer(completeLayer);
  };

  //完成疫木处理的小班图层的开关
  onChangeState = (checked: boolean) => {
    const { areaLevel, areaCode } = this.state;
    this.setState(
      {
        completeLayerSwitch: checked,
      },
      () => {
        //2023-2024年份的疫木图层添加方式
        if (
          sessionStorage.getItem("year") === "2023-2024" &&
          areaLevel !== "1"
        ) {
          CMap.removeLayer("completeLayer");
          this.addFinishedClass(areaCode);
        }
        //非2023-2024年份的疫木图层添加方式
        if (
          sessionStorage.getItem("year") !== "2023-2024" &&
          areaLevel === "3"
        ) {
          CMap.removeLayer("completeLayer");
          this.addFinishedClass(areaCode);
        }
      }
    );
  };

  //加载行政区标签
  addRegionTabs = (list: any[]) => {
    CMap.addTabs({
      id: "tabs",
      type: "iconTab",
      src: icon,
      data: list,
      fontSzie: 17,
      offsetY: -25,
      offsetX: 0,
      iconOffset: [-15, -10],
    });
  };

  //定位小班的位置，加载小班和疫木图层
  localClass = (item: any) => {
    this.setState({
      theClassId: item.classId,
      viewparams: `xian:${item.countyCode};xiang:${item.townCode}`,
      areaLevel: "4",
    });
    CMap.removeEvent("region");
    CMap.mapMove([item.lon, item.lat], 18); //地图移动
    CMap.removeLayer("regionlayer"); //清除行政边界图层
    CMap.removeLayer("classLayer"); //清除小班图层
    CMap.removeLayer("tabs"); //清除行行政区标签
    !wmslayer && this.addFinishedClass(item.countyCode + item.townCode);
    this.addClassLayer(item.countyCode + item.townCode);
  };

  //处理完成小班
  completeClass = (e: any, item: any) => {
    e.stopPropagation(); //阻止事件冒泡
    Request.changeClassState({ id: item.id, state: 1 }, (res) => {
      if (res.code === 200) {
        const isFullScreen = document.fullscreenElement;
        if (isFullScreen) {
          this.setState({
            TipVisable: true,
            Tipkey: 5,
          });
          setTimeout(() => {
            this.setState({
              TipVisable: false,
            });
          }, 2000);
        } else {
          message.success("已完成");
        }
        this.getClassList(1);
      } else {
        message.error(res.msg);
      }
    });
  };

  //获取点位
  getWoodPoints = (e) => {
    const { year } = this.props;
    const { areaLevel } = this.state;
    if (e.map.getView().getZoom() > 17) {
      CMap.removeLayer("woodLayer");
      const view = e.map.getView().calculateExtent(e.map.getSize()); //获取视角范围区域
      const params = {
        maxX: view[2].toFixed(4),
        maxY: view[3].toFixed(4),
        minX: view[0].toFixed(4),
        minY: view[1].toFixed(4),
        year,
      };
      Request.getWoodByView(params, (res: any) => {
        //视角范围加载疫木点位
        if (res.code == 200) {
          let yellowList: any = [];
          let blueList: any = [];
          res.data.forEach((e) => {
            if (e.type === 1) {
              yellowList.push({
                coordinates: [e.x, e.y],
                id: e.id,
              });
            } else {
              blueList.push({
                coordinates: [e.x, e.y],
                id: e.id,
              });
            }
          });
          this.setState({
            theWoodPoints: [...yellowList, ...blueList],
            theWoodYellowPoints: yellowList,
            theWoodBluePoints: blueList,
          });
        } else {
          message.error("获取点位异常");
        }
      });
    } else {
      const { areaCode } = this.state;
      areaLevel === "3" &&
        !CMap.getLayer("woodLayer") &&
        this.addWoodLayer(areaCode);
      CMap.removeLayer("classWoodLayerYellow"); //小于17层不展示点位
      CMap.removeLayer("classWoodLayerBlue");
    }
  };

  //新增点位
  addWood = (e: any) => {
    let { add, isDoubleClick } = this.state;
    this.setState({
      tipIs_: 1,
      isDoubleClick: true,
    });
    CMap.removeEvent("region");
    CMap.removeEvent("classClick");
    if (!isDoubleClick) {
      CMap.addDraw("Point", (e) => {
        //地图交互事件  Point打点
        add.push(e.target.sketchCoords_);
        this.setState({
          openModalIs_: 1,
          add,
        });
      });
    }
  };

  //框选清除
  boxClean = (e: any) => {
    let { isDoubleClick } = this.state;
    this.setState({
      tipIs_: 2,
      isDoubleClick: true,
    });
    CMap.removeEvent("region");
    CMap.removeEvent("classClick");
    if (!isDoubleClick) {
      CMap.addDraw("Box", (e) => {
        //地图交互事件 Box画框
        this.setState({
          openModalIs_: 2,
          box: e.target.sketchCoords_,
        });
      });
    }
  };

  //取消新增或者框选操作
  cancelModal = () => {
    CMap.removeDraw();
    this.countryAreaClick();
    this.areaMapClick();
    this.setState({
      openModalIs_: 0,
      tipIs_: 0,
      add: [],
      box: [],
      isDoubleClick: false,
    });
  };

  //确认新增或者框选操作  风萧萧兮易水寒
  confirmModal = () => {
    const { year } = this.props;
    const { openModalIs_, add, box, moveMap, areaCode } = this.state;
    let pointList = [];
    add.forEach((element) => {
      pointList.push({ x: element[0], y: element[1] });
    });
    CMap.removeDraw();
    this.countryAreaClick();
    this.areaMapClick();
    if (openModalIs_ === 1) {
      //新增操作
      Request.AddPoints(
        { pointList, hasClass: true, areaCode: areaCode },
        (res: any) => {
          if (res.code === 200) {
            const isFullScreen = document.fullscreenElement;
            if (isFullScreen) {
              this.setState({
                TipVisable: true,
                Tipkey: 1,
              });
              setTimeout(() => {
                this.setState({
                  TipVisable: false,
                });
              }, 2000);
            } else {
              message.success(res.data);
            }
            this.getWoodPoints(moveMap);
          } else {
            const isFullScreen = document.fullscreenElement;
            if (isFullScreen) {
              this.setState({
                TipVisable: true,
                Tipkey: 3,
              });
              setTimeout(() => {
                this.setState({
                  TipVisable: false,
                });
              }, 2000);
            } else {
              message.error("新增失败");
            }
          }
        }
      );
    } else if (openModalIs_ === 2) {
      //框选清除操作
      let boxParams = {};
      boxParams["maxX"] = box[1][0];
      boxParams["minX"] = box[0][0];
      boxParams["maxY"] = box[0][1];
      boxParams["minY"] = box[1][1];
      boxParams["year"] = year;
      Request.CleanBox({ ...boxParams }, (res) => {
        if (res.code === 200) {
          const isFullScreen = document.fullscreenElement;
          if (isFullScreen) {
            this.setState({
              TipVisable: true,
              Tipkey: 2,
            });
            setTimeout(() => {
              this.setState({
                TipVisable: false,
              });
            }, 2000);
          } else {
            message.success("清除成功");
          }
          this.getWoodPoints(moveMap);
        } else {
          const isFullScreen = document.fullscreenElement;
          if (isFullScreen) {
            this.setState({
              TipVisable: true,
              Tipkey: 4,
            });
            setTimeout(() => {
              this.setState({
                TipVisable: false,
              });
            }, 2000);
          } else {
            message.error("清除失败");
          }
        }
      });
    }
    this.setState({
      openModalIs_: 0,
      tipIs_: 0,
      add: [],
      box: [],
      isDoubleClick: false,
    });
  };

  //全屏fullScreen
  fullScreen = () => {
    const screen = document.getElementById("platform-map-container");
    const isFullScreen = document.fullscreenElement;
    isFullScreen ? document.exitFullscreen() : screen.requestFullscreen();
  };

  render() {
    const { changeWood, isplagueWood, getData, position } = this.props;
    const {
      openModalIs_,
      tipIs_,
      undealData,
      total,
      page,
      TipVisable,
      Tipkey,
      areaLevel,
      completeLayerSwitch,
    } = this.state;

    return (
      <div className={"platform-map"}>
        <div className={"platform-map-container"} id="platform-map-container">
          <CMap
            id="plagueWood-map"
            layer={
              new TileLayer({
                source: new XYZ({
                  url: `https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}`,
                }),
                // source: new XYZ({
                // 	url: `https://gac-geo.googlecnapps.cn/maps/vt?lyrs=s&x={x}&y={y}&z={z}`,
                // }),
              })
            }
            layerType={"TDTYXMap"}
            CenterLonLat={position}
            Zoom={9}
            maxZoom={20}
          ></CMap>
          <Button
            className={"back-btn"}
            onClick={() => {
              changeWood(isplagueWood);
              getData();
            }}
          >
            返回上页
          </Button>

          {areaLevel !== "1" && (
            <Button
              className={"backlevel-btn"}
              onClick={() => {
                this.backNextLevel();
              }}
            >
              返回上级
            </Button>
          )}
          <Tooltip placement="top" title={"全屏"}>
            <div className={"fullScreen"}>
              <FullscreenOutlined
                rev={undefined}
                className="fullScreen-img"
                onClick={() => {
                  this.fullScreen();
                }}
              />
            </div>
          </Tooltip>
          <div className={"plagueWood-btn"}>
            <span
              style={{ whiteSpace: "nowrap", color: "white", marginTop: "5px" }}
            >
              普查完成度开关：
            </span>
            <Switch
              defaultChecked={completeLayerSwitch}
              onChange={this.onChangeState}
              style={{ marginTop: "5px", marginRight: "50px" }}
            />
            <Button
              className={
                !this.state.acceptSmallFlag ? "over-accept" : "over-accept-act"
              }
              onClick={() => {
                // console.log(123)
                // CMap.addWMSLayer(afterSmallClassLayer)
                this.setState({
                  acceptSmallFlag: !this.state.acceptSmallFlag,
                });
              }}
            >
              已验收小班
            </Button>

            {tipIs_ === 2 ? (
              <Button className={"add"} onClick={this.cancelModal} danger>
                {" "}
                取消清除
              </Button>
            ) : (
              <Button className={"add"} onClick={this.addWood}>
                {" "}
                疫木新增
              </Button>
            )}
            {tipIs_ === 1 ? (
              <Button className={"box"} onClick={this.cancelModal} danger>
                取消新增
              </Button>
            ) : (
              <Button className={"box"} onClick={this.boxClean}>
                框选清除
              </Button>
            )}
          </div>
          <div
            className={"plagueWood-pop"}
            style={
              openModalIs_ == 0 ? { display: "none" } : { display: "block" }
            }
          >
            <p>
              {openModalIs_ == 1
                ? "是否新增以上疫木点位"
                : "是否清除以上疫木点位"}
            </p>
            <Button
              className={"cancel"}
              onClick={this.cancelModal}
              size="small"
            >
              否
            </Button>
            <Button
              className={"confirm"}
              onClick={this.confirmModal}
              size="small"
              type="primary"
            >
              是
            </Button>
          </div>
          <div
            className={"message-pop"}
            style={tipIs_ != 0 ? { display: "block" } : { display: "none" }}
          >
            <img src={infoImg} />{" "}
            <span>当前操作 : {tipIs_ == 1 ? "疫木新增" : "框选清除"}</span>
          </div>
          {/* 成功弹窗 */}
          {TipVisable && (
            <div className={"message-fullMessage"}>
              <img src={tipObj[Tipkey].icon} style={{ width: "20px" }} />{" "}
              <span>{tipObj[Tipkey].text}</span>
            </div>
          )}
        </div>
        <div className={"plagueWood-map-list"}>
          <div className={"list-title"}>小班列表</div>
          <div className={"list"}>
            {undealData.length !== 0 ? (
              undealData.map((item: any, i: any) => {
                return (
                  <div
                    className={"list-item"}
                    key={i}
                    onClick={(e: any) => this.localClass(item)}
                  >
                    <div>
                      <span>小班名称：</span>
                      <span>{item.className}</span>
                    </div>
                    <div>
                      <span>乡镇街道：</span>
                      <span>{item.town}</span>
                    </div>
                    <div>
                      <span style={{ wordSpacing: "20px" }}>区 县：</span>
                      <span>{item.county}</span>
                    </div>
                    <div>
                      <span style={{ wordSpacing: "20px" }}>状 态：</span>
                      <span>{item.state === "-1" ? "待处理" : "完成"}</span>
                    </div>
                    <Button
                      size="small"
                      type="primary"
                      onClick={(e: any) => this.completeClass(e, item)}
                    >
                      完成
                    </Button>
                  </div>
                );
              })
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
          <Pagination
            current={page}
            total={total}
            simple
            style={{
              marginLeft: "47px",
              marginTop: "7px",
            }}
            onChange={this.PagChange}
          />
        </div>
      </div>
    );
  }
}
