import React, { useState, useEffect } from 'react';
import { Input, Button, List } from 'antd';
import Common from '../../platform/services/Common';
import InfiniteScroll from 'react-infinite-scroll-component';

// const layout = {
// 	labelCol: { span: 6 },
// 	wrapperCol: { span: 18 }
// }

//小班搜索列表
export const ClassList = ({ keyValue, locationClass, countyCode }) => {
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    total: 0,
  });
  useEffect(() => {
    if (keyValue !== '') {
      setData([]);
      setTimeout(() => {
        data.length === 0 && loadClassList(1, keyValue);
      });
    }
  }, [keyValue]);
  // 获取小班列表数据
  const loadClassList = (page, keyValue) => {
    const params = {
      key: keyValue,
      page: page,
      size: 10,
      countyCode: countyCode,
      year: sessionStorage.getItem('year')
    };
    Common.getGlobalClass(params).then((res) => {
      if (res.data.code === 200) {
        setData(
          res.data.data.list.length === 0
            ? []
            : [...data, ...res.data.data.list]
        );
        setPagination({
          page: page + 1,
          total: res.data.data.total,
        });
      }
    });
  };
  return (
    <div id={'ClassList'} className={'searchClass-list'}>
      <InfiniteScroll
        dataLength={data.length}
        next={() => {
          loadClassList(pagination.page, keyValue);
        }}
        hasMore={data.length < pagination.total}
        loader={null}
        endMessage={null}
        scrollableTarget="ClassList"
      >
        <List
          dataSource={data}
          renderItem={(item, index) => (
            <List.Item
              key={item.classId}
              onClick={() => {
                locationClass(item.classId);
              }}
            >
              <div>{item.className}</div>
              <span>{index + 1}</span>
            </List.Item>
          )}
        />
      </InfiniteScroll>
    </div>
  );
};

//经纬度查询小班
export const ClassSearchByLnglat = ({ locationClass, countyCode }) => {
  const [lng, setLng] = useState('');
  const [lat, setLat] = useState('');
  return (
    <div className="search-lnglat">
      <div>
        <span>经度</span>
        <Input
          placeholder="请输入经度"
          style={{ width: '120px', marginLeft: '10px' }}
          allowClear={true}
          suffix={null}
          onChange={(e) => {
            setLng(e.target.value);
          }}
        />
      </div>
      <div>
        <span>纬度</span>
        <Input
          placeholder="请输入纬度"
          style={{ width: '120px', marginLeft: '10px' }}
          allowClear={true}
          onChange={(e) => {
            setLat(e.target.value);
          }}
        />
      </div>
      <Button
        type="primary"
        onClick={() => {
          Common.getClassLonlat({
            x: lng,
            y: lat,
            year: sessionStorage.getItem('year'),
            countyCode: countyCode
          }).then((res) => {
            if (res.data.code === 200) {
              locationClass(res.data.data.classId);
            }
          });
        }}
      >
        搜索
      </Button>
    </div>
  );
};
