import { Component } from "react";
import AnnualService from "../services/Annual";
import "./index.scss";
import {
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Table,
  message,
} from "antd";
import FormItem from "antd/es/form/FormItem";
const { Search } = Input;
const { Option } = Select;

class Annual extends Component {
  state = {
    page: 1,
    size: 10,
    data: [],
    loading: true,
    total: 0,
    searchValue: "",
    selectedRowKeys: [],
    record: null,
    year: sessionStorage.getItem("year"),
    yearList: JSON.parse(sessionStorage.getItem("yearList")),
    title: "",
    dialogFlag: false,
    deleteList: [],
  };

  componentDidMount(): void {
    this.getData();
  }

  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {}

  //获取数据
  getData = () => {
    const { page, size, year, searchValue } = this.state;
    const params = {
      page,
      size,
      key: searchValue,
    };
    AnnualService.getList(params).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          data: res.data.data.list,
          total: res.data.data.total,
          loading: false,
        });
      }
    });
  };

  //切换
  changePagination = (pagination) => {
    this.setState(
      {
        page: pagination.current,
        size: pagination.pageSize,
        loading: true,
      },
      this.getData
    );
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    let chooseTreeList = [];
    const { year } = this.state;
    this.setState({
      selectedRowKeys,
      deleteList: selectedRows,
    });
  };

  //搜索
  handleSearch = (value) => {
    this.setState(
      {
        searchValue: value,
      },
      () => {
        this.getData();
      }
    );
  };

  //全量新增
  allAdd = () => {
    AnnualService.putAddOffFull().then((res) => {
      res.data.code === 200
        ? message.success(res.data.data)
        : message.error(res.data.data);
    });
  };

  //新增数据
  addNewYear = () => {
    this.setState({
      title: "新增数据",
      dialogFlag: true,
      record: null,
    });
  };

  //新增数据
  editYear = (ids) => {
    AnnualService.getListById(ids).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          title: "修改数据",
          dialogFlag: true,
          record: res.data.data,
        });
      }
    });
  };

  //删除单个
  deleteById = (ids) => {
    AnnualService.delYearById(ids).then((res) => {
      if (res.data.code === 200) {
        this.getData();
        message.success("删除成功！");
      } else {
        message.error("删除失败！");
      }
    });
  };
  //批量删除
  deleteMany = () => {
    const { selectedRowKeys } = this.state;
    AnnualService.delYearByIds(selectedRowKeys).then((res) => {
      if (res.data.code === 200) {
        this.getData();
        this.setState({
          selectedRowKeys: [],
        });
        message.success("删除成功！");
      } else {
        message.error("删除失败！");
      }
    });
  };

  //提交表单
  onFinish = (value) => {
    // console.log(value)
    const { title, record } = this.state;
    if (title === "新增数据") {
      AnnualService.putYearEdit(value).then((res) => {
        if (res.data.code === 200) {
          message.success("新增成功！");
          this.setState({
            dialogFlag: false,
          });
          this.getData();
        } else {
          message.error("新增失败！");
        }
      });
    } else {
      let params = {
        ...value,
        id: record.id,
      };
      AnnualService.putYearEdit(params).then((res) => {
        if (res.data.code === 200) {
          message.success("修改成功！");
          this.setState({
            dialogFlag: false,
          });
          this.getData();
        } else {
          message.error("修改失败！");
        }
      });
    }
  };
  render() {
    const {
      page,
      size,
      data,
      loading,
      total,
      selectedRowKeys,
      title,
      dialogFlag,
      record,
      yearList,
    } = this.state;
    const pagination = {
      total,
      current: page,
      pageSize: size,
      showQuickJumper: true,
      responsive: false,
      showSizeChanger: true,
    };
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const columns = [
      {
        title: "地区名称",
        width: 80,
        dataIndex: "areaName",
        key: "areaName",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "地区编码",
        width: 80,
        dataIndex: "areaCode",
        key: "areaCode",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "年度",
        width: 120,
        align: "center" as "center",
        dataIndex: "year",
        key: "year",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "小班的年度",
        align: "center" as "center",
        dataIndex: "smallClassYear",
        width: 180,
        key: "smallClassYear",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "区域的年度",
        align: "center" as "center",
        dataIndex: "regionYear",
        width: 180,
        key: "regionYear",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "操作",
        width: 80,
        className: "handle",
        dataIndex: "handle",
        key: "handle",
        render: (text, record) => (
          <>
            <Button
              type="link"
              onClick={() => {
                this.editYear(record.id);
              }}
            >
              修改
            </Button>
            <Popconfirm
              title="确定要删除当前记录吗?"
              onConfirm={() => {
                this.deleteById(record.id);
              }}
              okText="确定"
              cancelText="取消"
            >
              <Button type="link">删除</Button>
            </Popconfirm>
          </>
        ),
      },
    ];
    return (
      <div className="annual">
        <div className="alHeader">
          <Button
            style={{ marginRight: "10px" }}
            type="primary"
            onClick={this.addNewYear}
          >
            新增数据
          </Button>
          <Popconfirm
            title="确定要删除当前记录吗?"
            onConfirm={this.deleteMany}
            okText="确定"
            cancelText="取消"
          >
            <Button style={{ marginRight: "10px" }} type="primary">
              批量删除
            </Button>
          </Popconfirm>
          <Popconfirm
            title="确定要全量新增吗?"
            onConfirm={this.allAdd}
            okText="确定"
            cancelText="取消"
          >
            <Button style={{ marginRight: "10px" }} type="primary">
              全量新增
            </Button>
          </Popconfirm>
          <Search
            className="searchInput"
            placeholder="请输入信息查询"
            enterButton="搜索"
            onSearch={this.handleSearch}
            style={{
              width: "270px",
            }}
          />
          <Modal
            title={title}
            visible={dialogFlag}
            footer={null}
            destroyOnClose={true}
            onCancel={() => this.setState({ dialogFlag: false })}
            width={400}
          >
            <Form onFinish={this.onFinish} initialValues={record}>
              <FormItem name="areaName" label="地区名称">
                <Input disabled={title === "修改数据" ? true : false} />
              </FormItem>
              <FormItem name="areaCode" label="地区编码">
                <Input disabled={title === "修改数据" ? true : false} />
              </FormItem>
              <FormItem name="year" label="年度">
                <Select
                  placeholder="年度"
                  style={{ width: 176, marginLeft: "30px" }}
                  allowClear
                  disabled={title === "修改数据" ? true : false}
                >
                  {yearList.map((item) => (
                    <Option value={item.key} key={item.key}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem name="regionYear" label="区域年度">
                <Select placeholder="年度" style={{ width: 176 }} allowClear>
                  {yearList.map((item) => (
                    <Option value={item.key} key={item.key}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </FormItem>
              <FormItem name="smallClassYear" label="小班年度">
                <Select placeholder="年度" style={{ width: 176 }} allowClear>
                  {yearList.map((item) => (
                    <Option value={item.key} key={item.key}>
                      {item.value}
                    </Option>
                  ))}
                </Select>
              </FormItem>

              <div className="form-button">
                <FormItem>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginRight: "10px" }}
                  >
                    确定
                  </Button>
                  <Button
                    onClick={() =>
                      this.setState({ dialogFlag: false, record: null })
                    }
                  >
                    取消
                  </Button>
                </FormItem>
              </div>
            </Form>
          </Modal>
        </div>
        <Table
          dataSource={data}
          rowKey={(record) => record.id}
          columns={columns}
          loading={loading}
          rowSelection={rowSelection}
          onChange={(pagination) => this.changePagination(pagination)}
          pagination={pagination}
          scroll={{ y: "calc(100vh - 23.5rem)" }}
        />
      </div>
    );
  }
}

export default Annual;
