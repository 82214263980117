// @ts-nocheck
import React, { Component } from 'react';
import Lottie from 'react-lottie';
import navigator from '../../assets/icons/navigator.json';
import './navigator.scss';
import Left from './leftBtns';
import Right from './rightBtns';
export default class Navigator extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isSelectArea: false,
      selectValue: '丽水市',
      list: [
        { btnName: '正摄影像', isClick: false },
        { btnName: '疫木点位', isClick: false },
        // { btnName: '感染程度', isClick: false },
      ],
      list2: [
        { btnName: '排查进度', isClick: false },
        { btnName: '海拔分析', isClick: false },
        { btnName: '....', isClick: false },
      ],
      areaList: [],
      villageList: [],
    };
  }
  componentDidMount() { }

  // 选择地区
  isSelect = () => {
    const { isSelectArea } = this.state;
    this.setState({
      isSelectArea: !isSelectArea,
    });
  };
  selectArea = (e, item) => {
    this.setState({
      villageList: item.children,
      selectValue: item.area,
      isSelectArea: false,
    });
  };
  render() {
    const {
      map,
      lay,
      code,
      areaName,
      changeSchedule,
      mode,
      username,
      level,
      changeWoodOpen,
      areaChange,
      changeInfectLayer,
      changeCheckLayer,
      theName,
      changeFatherCode,
      isWoodOpen,
      year,
      propsareaCode,
      AcceptStateOnclick,
      AcceptState,
    } = this.props;
    return (
      <>
        <div className="h-head">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: navigator,
            }}
          // width={'97.5 %'}
          />

          <Left
            map={map}
            lay={lay}
            code={code}
            areaName={areaName}
            mode={mode}
            areaChange={areaChange}
            level={level}
            changeWoodOpen={changeWoodOpen}
            changeInfectLayer={changeInfectLayer}
            theName={theName}
            year={year}
            propsareaCode={propsareaCode}
            changeFatherCode={changeFatherCode}
            isWoodOpen={isWoodOpen}
          // AcceptState={AcceptState}
          />
          <div className="title">
            <div className="title-chinese">
              <span>点创科技</span>
              <span>·</span>
              <span>云森防平台</span>
            </div>
            <div className="title-engh">
              DINNOVATE · FOREST PROTECTION CLOUD PLATFORM
            </div>
          </div>
          <Right
            map={map}
            lay={lay}
            changeSchedule={changeSchedule}
            username={username}
            changeCheckLayer={changeCheckLayer}
            level={level}
            theName={theName}
            areaName={areaName}
            mode={mode}
            code={code}
            propsareaCode={propsareaCode}
            AcceptState={AcceptState}
            AcceptStateOnclick={AcceptStateOnclick}
          />
        </div>
        <div className="a" style={this.props.isOperat ? { display: 'none' } : {}}></div>
        <div className="b"></div>
      </>
    );
  }
}
