import { Button, Form, Modal, Popconfirm, Select, Table, message } from "antd";
import React, { Component, createRef } from "react";
import EiminationReports from "../services/EiminationReports";
import FormItem from "antd/es/form/FormItem";
import Common from "../services/Common";
import moment from "moment";
import EliReportModal from "../../component/acceptance/eliReport";
import ExcelUpload from "./components/excelUpload";
import eiminationReports from "../services/EiminationReports";
import ProblemModal from "./components/problemModal";

import "./index.scss";
import VisualizationMap from "./components/visualizationMap";

const { Option } = Select;
const dateFormat = "YYYY-MM-DD HH:mm:ss";

export default class EiminationReport extends Component<any, any> {
  ref: any = createRef();
  state = {
    dataList: [],
    loading: true,
    total: 0,
    page: 1,
    size: 10,
    title: "新增数据",
    dataFlag: false,
    cityCode: sessionStorage.getItem("cityCode"),
    countyList: [], //区县列表
    townList: [], //乡镇列表
    villageList: [], //行政村列表
    countyCode: "",
    townCode: "",
    villageCode: "",
    record: null, //修改时暂存数据
    fileList: [],
    selectedRowKeys: [],
    houseFlag: false,
    imgpage: 1,
    imgtotal: 0,
    exportDateVisible: false,
    exportStyleVisible: false,
    excelUploadVisible: false,
    buttonDisabled: true,
    problemVisible: false,
    mapVisible: false,
    activeCounty: null,
  };

  componentDidMount(): void {
    this.getData();
    this.getCountyList();
    this.getProblemList();
  }

  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {
    if (prevProps.year !== this.props.year) {
      this.getData();
      this.getCountyList();
      this.getProblemList();
    }
  }

  //获取数据
  getData = () => {
    const { year } = this.props;
    const { page, size, countyCode } = this.state;
    let params = {
      countyCode,
      page,
      size,
      year,
    };
    EiminationReports.getList(params).then((res) => {
      // console.log("res", res);
      if (res.data.code === 200) {
        this.setState({
          loading: false,
          total: res.data.data.total,
          dataList: res.data.data.list,
        });
      }
    });
  };

  //删除单个数据
  deleteData = (record) => {
    // console.log(record);
    EiminationReports.delRecord(record.id).then((res) => {
      // console.log(res);
      if (res.data.code === 200) {
        message.success("删除成功！");
        this.getData();
      } else {
        message.error("删除失败！");
      }
    });
  };

  // 获取区县列表
  getCountyList = () => {
    const { cityCode } = this.state;
    Common.getCountyList({ cityCode }).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          countyList: res.data.data.map((item) => {
            return {
              value: item.code,
              label: item.name,
              lat: item.lat,
              lon: item.lon,
            };
          }),
        });
      }
    });
  };

  // 获取乡镇列表
  getTownList = () => {
    const { countyCode } = this.state;
    // console.log(countyCode)
    Common.getTownList({ countyCode }).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          townList: res.data.data,
        });
      }
    });
  };

  // 获取行政村列表
  getVillageList = () => {
    const { countyCode, townCode } = this.state;
    Common.getVillageList({ countyCode, townCode }).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          villageList: res.data.data,
        });
      }
    });
  };

  //切换
  changePagination = (pagination) => {
    this.setState(
      {
        page: pagination.current,
        size: pagination.pageSize,
        loading: true,
      },
      this.getData
    );
  };

  //提交
  onFinish = (value) => {
    const { title, fileList, record } = this.state;
    // console.log(value.checkDate);
    if (title === "新增数据") {
      // const data = new FormData();
      // value.file.fileList.forEach((file) => {
      //   data.append("file", file.originFileObj);
      // });
      // value.checkDate =
      //   value.checkDate && value.checkDate.format("YYYY-MM-DD HH:mm:ss");
      // HouseAbTs.postPic(data).then((res) => {
      //   if (res.data.code === 200) {
      //     value.images = res.data.data;
      //     HouseAbTs.putHouseTree(value).then((res) => {
      //       if (res.data.code === 200) {
      //         message.success("新增成功！");
      //         this.getData();
      //         this.hideModal();
      //       } else {
      //         message.error("新增失败！");
      //       }
      //     });
      //   }
      // });
    } else {
      // const data = new FormData();
      // let arr = [];
      // fileList.forEach((file) => {
      //   if (file.originFileObj) {
      //     data.append("file", file.originFileObj);
      //   } else {
      //     arr.push(file.url);
      //   }
      // });
      // HouseAbTs.postPic(data).then((res) => {
      //   if (res.data.code === 200) {
      //     arr = [...arr, ...res.data.data];
      //     console.log(arr);
      //     value.images = arr;
      //     value.id = record.id;
      //     HouseAbTs.putHouseTree(value).then((res) => {
      //       if (res.data.code === 200) {
      //         message.success("修改成功！");
      //         this.getData();
      //         this.hideModal();
      //       } else {
      //         message.error("修改失败！");
      //       }
      //     });
      //   }
      // });
      const dataParams = {
        ...value,
        id: record.id,
        isNewAdd: value.isNewAdd === "新发" ? true : false,
        status: value.status === "已除治" ? 1 : 0,
      };
      EiminationReports.updateData(dataParams).then((res) => {
        if (res.data.code === 200) {
          message.success("修改成功！");
          this.getData();
          this.hideModal();
        } else {
          message.error("修改失败！");
        }
      });
    }
  };

  //取消
  hideModal = () => {
    this.setState({
      countyCode: "",
      townCode: "",
      villageCode: "",
      townList: [], //乡镇列表
      villageList: [], //行政村列表
      dataFlag: false,
      record: null,
    });
  };

  // 区县选择
  onCountyChanged = (value, option) => {
    this.setState(
      {
        countyCode: value,
        activeCounty: option,
      },
      () => {
        this.getData();
      }
    );
    // let code = this.state.countyList.filter((item) => item.name === value)[0]
    //   .code;
    // this.setState(
    //   {
    //     countyCode: code,
    //     townCode: null,
    //     villageCode: null,
    //   },
    //   () => {
    //     this.getTownList();
    //   }
    // );
  };

  //关闭导出报告
  handleCancel = () => {
    this.setState({
      houseFlag: false,
    });
  };

  // 乡镇选择
  onTownChanged = (value) => {
    let code = this.state.townList.filter((item) => item.name === value)[0]
      .code;
    this.setState(
      {
        townCode: code,
        villageCode: null,
      },
      () => {
        this.getVillageList();
      }
    );
  };

  //批量删除 删删删！！！！！
  deleteMany = () => {
    const { selectedRowKeys } = this.state;
    EiminationReports.delRecords(selectedRowKeys).then((res) => {
      if (res.data.code === 200) {
        this.getData();
        this.setState({
          selectedRowKeys: [],
        });
        message.success("删除成功！");
      } else {
        message.error("删除失败！");
      }
    });
  };

  // 小班选择
  onVillageChanged = (value) => {
    this.setState({
      villageCode: value ? value : "",
    });
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRowKeys,
      deleteList: selectedRows,
    });
  };

  left = () => {
    const { imgpage } = this.state;
    if (imgpage - 1 > 1) {
      this.ref.current.style.left = "-" + (imgpage - 2) * 170 + "px";
      this.setState({
        imgpage: imgpage - 1,
      });
    } else {
      this.ref.current.style.left = "0px";
      this.setState({
        imgpage: 1,
      });
    }
  };

  right = () => {
    const { imgpage, imgtotal } = this.state;
    if (imgpage + 1 <= imgtotal) {
      this.ref.current.style.left = "-" + imgpage * 170 + "px";
      this.setState({
        imgpage: imgpage + 1,
      });
    }
  };

  /**
   * @description 获取表格上报失败列表
   */

  getProblemList = () => {
    const { year } = this.props;
    const params = {
      year,
      page: 1,
      size: 10,
    };
    eiminationReports.queryExcelReportFail(params).then((res) => {
      const data = res.data;
      if (data.code === 200 && data.data.total > 0) {
        this.setState({
          buttonDisabled: false,
        });
      } else {
        this.setState({
          buttonDisabled: true,
        });
      }
    });
  };

  changeExcelUploadVisible = () => {
    const { excelUploadVisible } = this.state;
    if (excelUploadVisible) {
      this.getProblemList();
    }
    this.setState({
      excelUploadVisible: !excelUploadVisible,
    });
  };

  changeProblemVisible = () => {
    const { problemVisible } = this.state;
    this.setState({
      problemVisible: !problemVisible,
    });
  };

  openMap = () => {
    const { countyCode } = this.state;
    if (countyCode) {
      this.setState({
        mapVisible: true,
      });
    } else {
      message.warning("请先选择区县");
    }
  };

  render() {
    const {
      dataList,
      loading,
      total,
      page,
      size,
      title,
      dataFlag,
      countyList,
      townList,
      villageList,
      record,
      fileList,
      selectedRowKeys,
      houseFlag,
      excelUploadVisible,
      buttonDisabled,
      problemVisible,
      mapVisible,
      countyCode,
      activeCounty,
    } = this.state;
    const { year } = this.props;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const columns = [
      {
        title: "区县",
        width: 150,
        dataIndex: "countyName",
        align: "center" as "center",
        key: "countyName",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "乡镇/街道",
        width: 120,
        align: "center" as "center",
        dataIndex: "townName",
        key: "townName",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "行政村",
        align: "center" as "center",
        dataIndex: "villageName",
        width: 150,
        key: "villageName",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "小班名",
        dataIndex: "className",
        align: "center" as "center",
        width: 100,
        key: "className",
        ellipsis: true,
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "除治进度",
        dataIndex: "status",
        align: "center" as "center",
        width: 120,
        key: "status",
        render: (text, record) => (
          <span>{text === 1 ? "已除治" : "未除治"}</span>
        ),
      },
      {
        title: "新发小班",
        dataIndex: "isNewAdd",
        align: "center" as "center",
        width: 100,
        key: "isNewAdd",
        render: (text, record) => <span>{text ? "新发" : "非新发"}</span>,
      },
      {
        title: "上报时间",
        dataIndex: "reportTime",
        align: "center" as "center",
        width: 100,
        key: "reportTime",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "操作",
        width: 130,
        className: "handle",
        dataIndex: "handle",
        key: "handle",
        align: "center" as "center",
        render: (text, record) => (
          <>
            <Button
              type="link"
              onClick={() => {
                // console.log(record);
                this.setState({
                  dataFlag: true,
                  title: "修改数据",
                  record,
                  fileList,
                });
              }}
            >
              修改
            </Button>
            <Popconfirm
              title="确定要删除当前记录吗?"
              onConfirm={() => {
                this.deleteData(record);
              }}
              okText="确定"
              cancelText="取消"
            >
              <Button type="link">删除</Button>
            </Popconfirm>
          </>
        ),
      },
    ];
    const pagination = {
      total,
      current: page,
      pageSize: size,
      showQuickJumper: true,
      responsive: false,
      showSizeChanger: true,
    };
    return (
      <>
        {mapVisible ? (
          <VisualizationMap
            countyData={activeCounty}
            onBack={() => {
              this.setState({
                mapVisible: false,
              });
            }}
          />
        ) : (
          <div className="houseData">
            <div className="userHeader">
              <div className="header_l">
                <Button type="primary" onClick={this.openMap}>
                  可视化上报
                </Button>
                <Button
                  type="primary"
                  style={{ marginLeft: "20px" }}
                  onClick={this.changeExcelUploadVisible}
                >
                  表格上报
                </Button>
                <Button
                  type="primary"
                  style={{ marginLeft: "20px" }}
                  disabled={buttonDisabled}
                  onClick={this.changeProblemVisible}
                >
                  问题处理
                </Button>
                <Button
                  type="primary"
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    this.setState({
                      houseFlag: true,
                      exportDateVisible: false,
                      exportStyleVisible: false,
                    });
                  }}
                >
                  导出报告
                </Button>

                <Button
                  type="primary"
                  style={{ marginLeft: "20px" }}
                  onClick={() => {
                    this.setState({
                      houseFlag: true,
                      exportDateVisible: true,
                      exportStyleVisible: true,
                    });
                  }}
                >
                  导出区域
                </Button>
                <Popconfirm
                  title="确定要删除当前记录吗?"
                  onConfirm={this.deleteMany}
                  okText="确定"
                  cancelText="取消"
                >
                  <Button type="primary" style={{ marginLeft: "20px" }}>
                    批量删除
                  </Button>
                </Popconfirm>
              </div>
              <div className="header_r">
                <Select
                  allowClear={true}
                  style={{ width: "100px" }}
                  placeholder="区县"
                  options={countyList}
                  onChange={this.onCountyChanged}
                ></Select>
              </div>
            </div>
            <Table
              dataSource={dataList}
              rowKey={(record) => record.id}
              columns={columns}
              loading={loading}
              onChange={(pagination) => this.changePagination(pagination)}
              pagination={pagination}
              rowSelection={rowSelection}
              scroll={{ y: "calc(100vh - 22.5rem)" }}
              style={{ marginTop: "16px" }}
            />
          </div>
        )}

        {houseFlag && (
          <EliReportModal
            visible={houseFlag}
            handleCancel={this.handleCancel}
            dateVisible={this.state.exportDateVisible}
            styleVisible={this.state.exportStyleVisible}
          ></EliReportModal>
        )}
        {excelUploadVisible && (
          <ExcelUpload changeVisible={this.changeExcelUploadVisible} />
        )}
        {problemVisible && (
          <ProblemModal year={year} changeVisible={this.changeProblemVisible} />
        )}
        <Modal
          title={title}
          visible={dataFlag}
          footer={null}
          destroyOnClose={true}
          onCancel={this.hideModal}
        >
          <Form
            onFinish={this.onFinish}
            initialValues={{
              ...record,
              isNewAdd: record?.isNewAdd ? "新发" : "非新发",
              status: record?.status ? "已除治" : "未除治",
              checkDate: record && moment(record.checkDate, dateFormat),
            }}
          >
            <Form.Item
              name="countyName"
              label="区县"
              hasFeedback
              rules={[{ required: true, message: "请选择区县！" }]}
            >
              <Select
                placeholder="请选择区县"
                disabled
                onChange={this.onCountyChanged}
              >
                {countyList.map((item, index) => {
                  return (
                    <Option value={item.name} key={index}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="townName"
              label="乡镇"
              hasFeedback
              rules={[{ required: true, message: "请选择乡镇！" }]}
            >
              <Select
                placeholder="请选择乡镇"
                disabled
                onChange={this.onTownChanged}
              >
                {townList.map((item, index) => {
                  return (
                    <Option value={item.name} key={index}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="villageName"
              label="行政村"
              hasFeedback
              rules={[{ required: true, message: "请选择行政村！" }]}
            >
              <Select
                placeholder="请选择行政村"
                disabled
                onChange={this.onVillageChanged}
              >
                {villageList.map((item, index) => {
                  return (
                    <Option value={item.name} key={index}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="status"
              label="除治进度"
              // hasFeedback
              rules={[{ required: true, message: "请选择除治进度！" }]}
            >
              <Select placeholder="是否除治">
                <Option key="已除治">已除治</Option>
                <Option key="未除治">未除治</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="isNewAdd"
              label="新发小班"
              // hasFeedback
              rules={[{ required: true, message: "请选择是否新发！" }]}
            >
              <Select placeholder="是否新发">
                <Option key="新发">新发</Option>
                <Option key="非新发">非新发</Option>
              </Select>
            </Form.Item>
            <div className="form-button">
              <FormItem>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginRight: "10px" }}
                >
                  确认
                </Button>
                <Button onClick={this.hideModal}>取消</Button>
              </FormItem>
            </div>
          </Form>
        </Modal>
      </>
    );
  }
}
