import { useEffect, useState } from "react";
import { Modal, Form, Select, DatePicker, Radio, message } from "antd";

import Common from "../../platform/services/Common";
import Acceptance from "../../platform/services/Acceptance";

interface Props {
  visible: boolean;
  handleCancel: Function;
  dateVisible: boolean;
  styleVisible: boolean;
}

const { RangePicker } = DatePicker;

function EliReportModal({
  visible,
  handleCancel,
  dateVisible,
  styleVisible,
}: Props) {
  const [countyList, setCountyList] = useState([]);
  const [countyCode, setCountyCode] = useState();
  const [time, setTime] = useState({ start: "", end: "" });
  const [styleCode, setStyleCode] = useState(1);

  const getCountyList = () => {
    const cityCode = sessionStorage.getItem("cityCode");
    Common.getCountyList({ cityCode }).then((res) => {
      if (res.data.code === 200) {
        setCountyList(res.data.data);
      }
    });
  };

  const onCountyChanged = (code) => {
    setCountyCode(code);
  };

  const onRangeChange = (value) => {
    if (value) {
      const startTime = value[0].format("YYYY-MM-DD 00:00:00");
      const endTime = value[1].format("YYYY-MM-DD 23:59:59");
      setTime({ start: startTime, end: endTime });
    }
  };

  const handleExportReport = async () => {
    // console.log(sessionStorage.getItem("year"));
    // console.log(countyCode);
    // console.log(time);
    // console.log(styleCode);

    if (dateVisible && styleVisible) {
      // console.log("导出区域");
      const res = await Acceptance.exportEliminationKML({
        countyCode,
        year: sessionStorage.getItem("year"),
        startTime: time.start,
        endTime: time.end,
        styleType: styleCode,
      });
      const countryName = countyList.filter(
        (item) => item.code === countyCode
      )[0].name;
      // console.log(res);
      const { data, headers } = res;
      // console.log(data);

      if (res.status !== 200) {
        return message.error("导出失败，请稍后再试！");
      }
      // console.log(headers["content-disposition"]);

      const fileName = headers["content-disposition"].replace(
        /\w+; filename=(.*)/,
        "$1"
      );

      let url = window.URL.createObjectURL(new Blob([data]));
      let a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.setAttribute("download", decodeURIComponent(fileName));
      document.body.appendChild(a);
      a.click(); //执行下载
      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);
    } else {
      // console.log("导出报告");
      const res = await Acceptance.exportEliminationExcel({
        countyCode,
        year: sessionStorage.getItem("year"),
      });
      // 除治情况统计表.xlsx
      // console.log(res);
      const { data, headers } = res;
      // console.log(data);

      if (res.status !== 200) {
        return message.error("导出失败，请稍后再试！");
      }
      const fileName = headers["content-disposition"].replace(
        /\w+;filename\*=utf-8''(.*)/,
        "$1"
      );

      // console.log(decodeURIComponent(fileName));

      let url = window.URL.createObjectURL(new Blob([data]));
      let a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.setAttribute("download", decodeURIComponent(fileName));
      document.body.appendChild(a);
      a.click(); //执行下载
      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);
    }
  };

  const init = async () => {
    getCountyList();
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Modal
      visible={visible}
      onCancel={() => handleCancel(visible)}
      closable={false}
      centered={true}
      onOk={handleExportReport}
      width={800}
    >
      <Form style={{ marginTop: "20px" }}>
        <Form.Item
          name="townName"
          label="区县"
          rules={[
            {
              required: true,
              message: "请选择区县",
            },
          ]}
          labelAlign="left"
          labelCol={{ span: 3 }}
        >
          <Select placeholder="请选择区县" onChange={onCountyChanged}>
            {countyList.map((item, index) => {
              return (
                <Select.Option value={item.code} key={index}>
                  {item.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        {dateVisible && (
          <Form.Item
            label="起止时间"
            name="time"
            rules={[
              {
                required: true,
                message: "请选择导出报告日期",
              },
            ]}
            labelAlign="left"
            labelCol={{ span: 3 }}
          >
            <RangePicker style={{ width: 400 }} onChange={onRangeChange} />
          </Form.Item>
        )}
        {styleVisible && (
          <Form.Item
            label="样式"
            name="style"
            rules={[
              {
                required: true,
                message: "请选择样式",
              },
            ]}
            labelAlign="left"
            labelCol={{ span: 3 }}
            initialValue={styleCode}
          >
            <Radio.Group
              value={styleCode}
              onChange={(e) => {
                setStyleCode(e.target.value);
              }}
            >
              <Radio value={1}>奥维</Radio>
              <Radio value={2}>司空</Radio>
            </Radio.Group>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
}

export default EliReportModal;
