import { acceptance_axios as axios } from "../../utils/axios";

export default class CarService {
  // 分页查询车辆管理列表
  static getList(params) {
    return axios.get("/car/query", { params });
  }

  static editCar(data) {
    return axios.put("/car/edit", data);
  }

  static delRecord(id) {
    return axios.delete("/car/delete/" + id);
  }

  static delRecords(ids) {
    return axios.delete("/car/batch_delete", { data: ids });
  }

  static getCarTrajectory(params) {
    return axios.get("/car/trajectory", { params });
  }

  static upDateRecords() {
    return axios.put("car/update_state");
  }
}
