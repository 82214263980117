export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

const imgToCanvas = (img) => {
  let canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  let ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0);
  return canvas;
};

const addWaterMarker = (canvas, values, status) => {
  return new Promise((resolve, reject) => {
    let ctx = canvas.getContext("2d");
    let fontSize = canvas.width * (status ? 0.032 : 0.016);
    values.forEach((item, index) => {
      // 设置填充字号和字体，样式
      ctx.font = `${fontSize}px 宋体`;
      ctx.fillStyle = "#FFFFFF";
      // 设置右对齐
      ctx.textAlign = "left";
      // 在指定位置绘制文字，这里指定距离右下角20坐标的地方
      ctx.fillText(
        `${item.title}${item.value}`,
        canvas.width * 0.02,
        canvas.height * 0.98 - (values.length - index) * fontSize
      );
    });
    ctx.fillText(
      "点创科技",
      canvas.width * 0.98 - fontSize * 4,
      canvas.height * 0.98 - fontSize
    );
    resolve(canvas);
  });
};

export const downloadShuiyin = (data) => {
  const cityName = data.cityName;
  const status = data.isCheck ? data.isCheck : false;
  const read = new FileReader();
  read.readAsDataURL(data.file);
  return new Promise((resolve, reject) => {
    read.onload = (e) => {
      var bg: any = new Image();
      bg.src = e.target.result;
      bg.onload = () => {
        let canvas = imgToCanvas(bg);
        const keyList = [
          {
            title: status
              ? `${cityName}松材线虫病防治监理工作`
              : `${cityName}松材线虫病防治验收工作`,
            key: "title",
            position: "left",
          },
          { title: "时间", key: "time", position: "left" },
          { title: "地点", key: "address", position: "left" },
          { title: "海拔", key: "altitude", position: "left" },
          { title: "经纬度", key: "lnglat", position: "left" },
          // { title: '拍摄人', key: 'userName', position: 'left' },
        ];
        const userNamekeyList = [
          {
            title: status
              ? `${cityName}松材线虫病防治监理工作`
              : `${cityName}松材线虫病防治验收工作`,
            key: "title",
            position: "left",
          },
          { title: "时间", key: "time", position: "left" },
          { title: "地点", key: "address", position: "left" },
          { title: "海拔", key: "altitude", position: "left" },
          { title: "经纬度", key: "lnglat", position: "left" },
          { title: "拍摄人", key: "userName", position: "left" },
        ];
        (data.userName ? userNamekeyList : keyList).forEach((item: any, i) => {
          if (item.key == "title") {
            item.value = "";
          } else {
            item.value =
              item.key == "lnglat"
                ? (item.value = `：${data.point.x ? data.point.x : ""} ${
                    data.point.y ? data.point.y : ""
                  }`)
                : `: ${data[item.key] || ""}`;
          }
          if (i == (data.userName ? userNamekeyList : keyList).length - 1) {
            addWaterMarker(
              canvas,
              data.userName ? userNamekeyList : keyList,
              status
            ).then((res) => {
              let img = canvas.toDataURL("image/jpeg", 0.5);
              let File = dataURLtoFile(img, data.name);
              resolve(File);
            });
          }
        });
      };
    };
  });
};

// 压缩图片
export const CompressImage = (file) => {
  let files;
  const fileSize = parseFloat(
    (parseInt(file.file["size"]) / 1024 / 1024).toFixed(2)
  );
  const read = new FileReader();
  read.readAsDataURL(file.file);
  return new Promise(function (resolve, reject) {
    read.onload = function (e) {
      var img: any = new Image();
      img.src = e.target.result;
      img.onload = function () {
        var w = this.width,
          h = this.height;
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        var base64;
        canvas.setAttribute("width", w);
        canvas.setAttribute("height", h);
        ctx.drawImage(this, 0, 0, w, h);
        if (fileSize < 1) {
          base64 = canvas.toDataURL(file.file["type"], 1);
        } else if (fileSize > 1 && fileSize < 2) {
          base64 = canvas.toDataURL(file.file["type"], 0.5);
        } else {
          base64 = canvas.toDataURL(file.file["type"], 0.2);
        }
        files = dataURLtoFile(base64, file.file["name"]); //如果后台接收类型为base64的话这一步可以省
        file.file = files;
        resolve(file);
      };
    };
  });
};
