import { useEffect, useState } from 'react';
import Request from '../../../services/Manger';
import back from '../../../assets/images/CurrentAccept.png';
import BaseCard from '../../../component/Card/BaseCard';
import NumCard from '../../../component/Card/NumCard';
import accepttotal from '../../../assets/icons/icon/svg/accepttotal.svg';
import { currentAcceptData } from '../data';
import Progress from '../../../component/tools/Progress';
// 验收不合格小班
export default function CurrentAccept({ props }) {
  const { code, level, theName, year } = props;
  const [data, setData] = useState([] as any);
  useEffect(() => {
    Request.getCurrentAcceptData(
      { areaCode: code, year, isInspection: 0 },
      (res) => {
        if (res.code === 200) {
          setData(res.data);
        }
      }
    );
  }, [code, level, year]);

  return (
    <BaseCard
      title={`${theName}·当前验收统计`}
      describe={'CURRENT ACCEPTANCE'}
      height={223}
      backround={back}
    >
      <div className="currentAccept">
        <div className="accepttop">
          <img src={accepttotal} alt="" />
          <div className="accepttop_number">
            {currentAcceptData.map((item, index) => (
              <NumCard
                title={item.title}
                total={data[item.datatitle]}
                key={index + 1}
                bold={item.bold}
                color={item.color}
              />
            ))}
          </div>
        </div>
        <div className="acceptbottom">
          <NumCard
            title="验收通过率："
            unit="%"
            total={data?.perOfAccept}
            color="#02D68A"
            position={true}
          />
          <Progress progress={75} />
        </div>
      </div>
    </BaseCard>
  );
}
