import { Modal, DatePicker, message } from "antd";
import { useEffect, useState } from "react";
import OlMap from "../../../utils/olmap";
import { MapLayers } from "../../acceptance/source";
import CarService from "../../services/Car";
import moment from "moment";
import PlagueWoodTs from "../../services/plagueWood";

interface Props {
  visible: boolean;
  dataSource: any;
  onClose: () => void;
}

const { RangePicker } = DatePicker;

let lines = null;

const MapModal = ({ visible, dataSource, onClose }: Props) => {
  const [startTime, setStartTime] = useState(moment().format("YYYYMMDD"));
  const [endTime, setEndTime] = useState(moment().format("YYYYMMDD"));

  const addLines = () => {
    CarService.getCarTrajectory({
      carId: dataSource.id,
      startTime: `${startTime}000000`,
      endTime: `${endTime}235959`,
    }).then((res: any) => {
      if (res.data.code === 200) {
        if (res.data.data.length === 0) {
          message.warning("当前时间不存在轨迹信息");
        }
        if (res.data.data.length > 0) {
          lines = OlMap.addLines(res.data.data);
        } else {
          removeLines();
        }
      }
    });
  };

  const removeLines = () => {
    if (lines) {
      OlMap.map.removeLayer(lines);
      OlMap.removeCarPoint();
      lines = null;
    }
  };

  const init = () => {
    OlMap.init("ol_map", [119.905586, 28.464861]);
    PlagueWoodTs.getYearConfig({
      areaCode: dataSource.countyCode,
      year: sessionStorage.getItem("year"),
    }).then((res) => {
      if (res.data.code === 200) {
        OlMap.addBorderLayer({
          ...MapLayers.countryLayer,
          viewparams: `areaCode:${dataSource.countyCode};year:${res.data.data.regionYear};`,
        });
        OlMap.addFactoryPoint();
      }
    });
  };

  const changeTime = (date, dateString) => {
    if (date) {
      setStartTime(moment(dateString[0]).format("YYYYMMDD"));
      setEndTime(moment(dateString[1]).format("YYYYMMDD"));
    } else {
      setStartTime(moment().format("YYYYMMDD"));
      setEndTime(moment().format("YYYYMMDD"));
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    removeLines();
    addLines();
  }, [startTime, endTime]);

  return (
    <Modal
      title={`车辆轨迹 - ${dataSource.carPlate}`}
      visible={visible}
      footer={false}
      width={"95%"}
      onCancel={onClose}
      getContainer={false}
      centered={true}
    >
      <div className="map_modal">
        <div id="ol_map" className="map_box"></div>
        <div className="time_box">
          <RangePicker onChange={changeTime} />
        </div>
      </div>
    </Modal>
  );
};

export default MapModal;
