import ReactDOM from 'react-dom';
import { message } from 'antd';
import map from './map';
import { getDistance } from './mapSource';

export const keepDecimal = (value: any, num: number) => {
  return parseFloat(value.toFixed(num));
};

export const copyValue = (value: any) => {
  let oInput = document.createElement('input');
  oInput.value = value;
  document.body.appendChild(oInput);
  oInput.select();
  document.execCommand('Copy');
  oInput.style.display = 'none';
  message.success('已复制当前信息到剪贴板');
  setTimeout(() => {
    document.body.removeChild(oInput);
  }, 1000);
};

export const getRandomLayerId = (id: string) => {
  let newId = id;
  for (let i = 0; i < 100; i++) {
    newId = `${id}_${i + 1}`;
    if (!map.getLayer(newId)) {
      return newId;
    }
  }
};

/**
 * 判断某个点是否在指定范围内
 * @param {*} testLat 目标点的纬度
 * @param {*} testLon 目标点的经度
 * @param {*} polygonPoints 指定范围的经纬度数组
 * @returns
 */
export const IsPointInPolygon = (
  testLat: number,
  testLon: number,
  polygonPoints: any
) => {
  if (polygonPoints.length < 3) return false;
  let iSum = 0,
    iCount = polygonPoints.length;
  let dLon1, dLon2, dLat1, dLat2, dLon; //点1，点2的经度纬度；需要判断的点与多边形边的交点经度
  let bool = false;
  /**判断是否是顶点，如果是顶点则直接返回**/
  for (let i = 0; i < iCount; i++) {
    dLon1 = polygonPoints[i][0];
    dLat1 = polygonPoints[i][1];
    if (testLon == dLon1 && testLat == dLat1) {
      bool = true;
    }
  }
  /**判断顶点结束**/
  if (bool) {
    return bool;
  } else {
    for (let i = 0; i < iCount; i++) {
      if (i == iCount - 1) {
        dLon1 = polygonPoints[i][0];
        dLat1 = polygonPoints[i][1];
        dLon2 = polygonPoints[0][0];
        dLat2 = polygonPoints[0][1];
      } else {
        dLon1 = polygonPoints[i][0];
        dLat1 = polygonPoints[i][1];
        dLon2 = polygonPoints[i + 1][0];
        dLat2 = polygonPoints[i + 1][1];
      }
      //以下语句判断A点是否在边的两端点的水平平行线之间，在则可能有交点，开始判断交点是否在左射线上
      if (
        (testLat >= dLat1 && testLat < dLat2) ||
        (testLat >= dLat2 && testLat < dLat1)
      ) {
        if (Math.abs(dLat1 - dLat2) > 0) {
          //得到 A点向左射线与边的交点的x坐标：
          dLon =
            dLon1 - ((dLon1 - dLon2) * (dLat1 - testLat)) / (dLat1 - dLat2);
          if (dLon < testLon) iSum++;
        }
      }
    }

    if (iSum % 2 != 0) {
      bool = true;
    }
    return bool;
  }
};

export const render = (element: any, selector: string) => {
  const interval = setInterval(() => {
    const el = document.querySelector(selector);
    if (el) {
      ReactDOM.render(element, el);
      clearInterval(interval);
      // setTimeout(callback);
    }
  }, 10);
};

// render(<InfoWin data={e.target.options} refresh={() => {
//     this.map.clearOverLays()
//     this.load()
// }} />, "#" + selector);

export const calculateDistance = (
  center: Array<number>,
  extent: Array<Array<number>>
) => {
  return new Promise((resolve, reject) => {
    let max = 0;
    let distance = 0;
    // const screenCenter = map.map.getPixelFromCoordinate(transform(center,'EPSG:3857', 'EPSG:4326'))
    extent.forEach((item: any, index: number) => {
      // border = map.map.getPixelFromCoordinate(transform(item,'EPSG:3857', 'EPSG:4326'))
      // distance = Math.sqrt(Math.pow(border[0] - screenCenter[0], 2) + Math.pow(border[1] - screenCenter[1], 2))
      distance = getDistance(item, center);
      if (distance > max) {
        max = distance;
      }
      if (index == extent.length - 1) {
        resolve(max);
      }
    });
  });
};

export const calculateScreenDistance = (
  center: Array<number>,
  extent: Array<Array<number>>
) => {
  return new Promise((resolve, reject) => {
    let max = 0;
    let border = [];
    let distance = 0;
    const screenCenter = map.map.getPixelFromCoordinate(center);
    extent.forEach((item: any, index: number) => {
      item[0].forEach((item2: any, index2: number) => {
        border = map.map.getPixelFromCoordinate(item2);
        distance = Math.sqrt(
          Math.pow(border[0] - screenCenter[0], 2) +
            Math.pow(border[1] - screenCenter[1], 2)
        );
        if (distance > max) {
          max = distance;
        }
      });
      if (index == extent.length - 1) {
        resolve(max);
      }
    });
  });
};

export const calculateCityRadius = (
  center: Array<number>,
  extent: Array<Array<number>>
) => {
  return new Promise((resolve, reject) => {
    let max = 0;
    let border = [];
    let distance = 0;
    const screenCenter = map.map.getPixelFromCoordinate(center);
    extent.forEach((item: any, index: number) => {
      border = map.map.getPixelFromCoordinate(item);
      distance = Math.sqrt(
        Math.pow(border[0] - screenCenter[0], 2) +
          Math.pow(border[1] - screenCenter[1], 2)
      );
      if (distance > max) {
        max = distance;
      }
      if (index == extent.length - 1) {
        resolve(max);
      }
    });
  });
};
