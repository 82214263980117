import React, { Component } from 'react';
import './popup.scss'

class Popup extends Component<any> {
    componentDidMount(): void {
    }
    render() {
        return (
            <div className='popup' style={{ color: 'black' }}>
                {/* {this.props} */}
                <div>小班名字:{this.props.smallClassName}</div>
                <div>疫木数量:{this.props.treeNum || 0}颗</div>
            </div>
        );
    }
}

export default Popup;