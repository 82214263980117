import React, { Component, useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Input,
  Modal,
  Select,
  Table,
  Tabs,
  message,
} from "antd";
import LogRequest from "../services/Logrecord";
import Acceptance from "../services/Acceptance";
import charts from "../../utils/echarts";
import userSvg from "../../assets/icons/icon/svg/user.svg";
import Workmap from "./map";
import "./index.scss";
import {
  PayPage,
  ModalTable,
  Work_barCharts,
  Work_historyData,
  Work_details,
  Work_scale,
  Work_overtime,
} from "./component";
import moment from "moment";

const { RangePicker } = DatePicker;
const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

interface workProps {
  year: string;
  cityCode: string;
}
interface workState<T = []> {
  startDate: any;
  endDate: any;
  keyWord: string;
  activeKey: string;
  countyList: any;
  nameList: any;
  dateValue: any;
  millisecondNum: number;
  userValue: string;
}

class WorkSta extends Component<workProps, workState> {
  pageSize = 10;
  constructor(props) {
    super(props);
    this.state = {
      countyList: [],
      startDate: "",
      endDate: "",
      keyWord: "",
      activeKey: "0",
      nameList: [],
      dateValue: null,
      millisecondNum: 0,
      userValue: "",
    };
  }

  componentDidMount(): void {
    const currentMonth = moment().startOf("month");
    const startOfMonth = currentMonth.format("YYYY-MM-DD  00:00:00");
    const endOfMonth = currentMonth
      .endOf("month")
      .format("YYYY-MM-DD 23:59:59");

    // 设置状态，将起始日期和结束日期作为默认值
    this.setState({
      startDate: startOfMonth,
      endDate: endOfMonth,
    });
    window.addEventListener("resize", () => {
      const dom = charts.getChart("barchart");
      const dom_line = charts.getChart("line");
      const dom_pie = charts.getChart("fan");
      const custom_chart = charts.getChart("custom_chart");
      dom && dom.dom.resize();
      dom_line && dom_line.dom.resize();
      dom_pie && dom_pie.dom.resize();
      custom_chart && custom_chart.dom.resize();
    });
  }

  componentWillUnmount(): void {
    const bar = charts.getChart("barchart")?.dom;
    bar && bar.off("click");
    charts.clear("barchart");
    charts.clear("line");
    charts.clear("fan");
    charts.clear("custom_chart");
  }

  componentDidUpdate(
    prevProps: Readonly<workProps>,
    prevState: Readonly<workState<[]>>,
    snapshot?: any
  ): void {
    if (prevProps.cityCode !== this.props.cityCode) {
      this.setState({
        activeKey: "0",
        keyWord: "",
      });
    }
    if (prevProps.year !== this.props.year) {
      const bar = charts.getChart("barchart")?.dom;
      bar && bar.off("click");
      charts.clear("barchart");
      charts.clear("line");
      charts.clear("fan");
      charts.clear("custom_chart");
      window.addEventListener("resize", () => {
        const dom = charts.getChart("barchart");
        const dom_line = charts.getChart("line");
        const dom_pie = charts.getChart("fan");
        const custom_chart = charts.getChart("custom_chart");
        dom && dom.dom.resize();
        dom_line && dom_line.dom.resize();
        dom_pie && dom_pie.dom.resize();
        custom_chart && custom_chart.dom.resize();
      });
    }
  }

  // 操作比例__name
  getLineDataName = (params) => {
    LogRequest.getLineList(params).then((res) => {
      if (res.data.code === 200) {
        const data = res.data.data;
        if (data?.length !== 0) {
          const { logInfoStatisticRDTOS } = data;
          let nameList = [];
          logInfoStatisticRDTOS.map((item, index) => {
            nameList.push({ name: item.userName });
            if (logInfoStatisticRDTOS.length === index + 1) {
              this.setState({ nameList });
            }
          });
        }
      }
    });
  };

  //用户切换
  userChange = (value) => {
    this.setState({ userValue: value });
  };

  //日期
  rangeChange = (value, type?) => {
    console.log(value, type);
    const { activeKey } = this.state;
    if (value) {
      if (type === "date") {
        this.setState({ dateValue: value });
        const startDate = value.format("YYYY-MM-DD 00:00:00");
        const endDate = value.format("YYYY-MM-DD 23:59:59");
        const params = {
          startDate,
          endDate,
        };
        this.getLineDataName(params);
      } else {
        const millisecondNum = value[1].unix() - value[0].unix();
        const startDate = value[0].format("YYYY-MM-DD 00:00:00");
        const endDate = value[1].format("YYYY-MM-DD 23:59:59");
        this.setState({ startDate, endDate, millisecondNum }, () => {
          this.acitceKey_change(activeKey);
        });
      }
    } else {
      if (type === "range") {
        this.setState({ startDate: "", endDate: "" }, () => {
          this.acitceKey_change(activeKey);
        });
      }
    }
  };

  //搜索
  handleSearch = (key) => {
    this.setState({ keyWord: key });
  };

  // 切换模块
  changeCharts = (activeKey) => {
    this.setState(
      {
        activeKey: activeKey,
      },
      () => {
        this.acitceKey_change(activeKey);
      }
    );
  };

  // 对应模块
  acitceKey_change = (activeKey) => {
    const bar_0 = charts.getChart("barchart")?.dom;
    bar_0 && bar_0.off("click");
    bar_0 && charts.clear("barchart");
    charts.clear("line");
    charts.clear("fan");
    charts.clear("custom_chart");
    switch (activeKey) {
      case "3":
        const date = moment(new Date(), "YYYY-MM-DD");
        this.setState({ dateValue: date });
        const startDate = date.format("YYYY-MM-DD 00:00:00");
        const endDate = date.format("YYYY-MM-DD 23:59:59");
        const params = {
          startDate,
          endDate,
        };
        this.getLineDataName(params);
        break;
      default:
        break;
    }
  };

  // 乡镇切换
  onCountyChanged = (value) => {};

  // 获取区县列表
  getCountyList = () => {
    // const cityCode = sessionStorage.getItem("cityCode")
    // Common.getCountyList({ cityCode }).then(res => {
    // 	if (res.data.code == 200) {
    // 		this.setState({
    // 			countyList: res.data.data
    // 		})
    // 	}
    // })
  };

  render() {
    const {
      startDate,
      endDate,
      activeKey,
      dateValue,
      nameList,
      millisecondNum,
      keyWord,
      userValue,
    } = this.state;
    const { cityCode } = this.props;
    return (
      <div className="work-page">
        <div className="work-header">
          <div>
            {/* <Select
						onChange={this.onCountyChanged}
						style={{ width: 90, marginRight: '5px' }} placeholder="区县"
						allowClear
					>
						{
							countyList.map((item, index) => {
								return (
									<Option value={item.code} key={index}>{item.name}</Option>
								)
							})
						}
					</Select> */}
            {activeKey === "3" && (
              <>
                <DatePicker
                  value={dateValue}
                  onChange={(value) => this.rangeChange(value, "date")}
                  inputReadOnly={true}
                  style={{ marginLeft: "25px", marginRight: "5px", width: 120 }}
                />
                <Select
                  onChange={this.userChange}
                  style={{ width: 150, marginRight: "17px" }}
                  defaultValue={"all"}
                >
                  <Option value={"all"} key={0}>
                    全部
                  </Option>
                  {nameList.map((item, index) => {
                    return (
                      <Option value={item.name} key={index + 1}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </>
            )}
            {activeKey !== "3" && activeKey !== "4" && (
              <>
                <RangePicker
                  value={[
                    startDate ? moment(startDate, "YYYY-MM-DD") : null,
                    endDate ? moment(endDate, "YYYY-MM-DD") : null,
                  ]}
                  onChange={(value) => this.rangeChange(value, "range")}
                  inputReadOnly={true}
                  style={{ marginRight: "5px", width: 240 }}
                  placeholder={["开始日期", "结束日期"]}
                />
                <Search
                  placeholder="请输入关键字查询"
                  allowClear
                  enterButton="搜索"
                  disabled={activeKey === "1"}
                  onSearch={this.handleSearch}
                  style={{
                    width: "240px",
                    marginRight: "5px",
                  }}
                />
              </>
            )}
          </div>
        </div>
        <div className="work-table">
          <Tabs
            defaultActiveKey="0"
            activeKey={activeKey}
            onChange={this.changeCharts}
            tabPosition={"left"}
            destroyInactiveTabPane
          >
            <TabPane tab="工作详情" key="0">
              <Work_details
                cityCode={cityCode}
                startDate={startDate}
                endDate={endDate}
                millisecondNum={millisecondNum}
                keyWord={keyWord}
              />
            </TabPane>
            <TabPane tab="柱状图表" key="1">
              <Work_barCharts
                cityCode={cityCode}
                startDate={startDate}
                endDate={endDate}
              />
            </TabPane>
            <TabPane tab="操作历史" key="2">
              <Work_historyData
                cityCode={cityCode}
                startDate={startDate}
                endDate={endDate}
                keyWord={keyWord}
              />
            </TabPane>
            <TabPane tab="操作比例" key="3">
              <Work_scale dateValue={dateValue} userValue={userValue} />
            </TabPane>
            <TabPane tab="工时图表" key="4">
              <Work_overtime cityCode={cityCode} />
              {/* <Work_scale
								dateValue={dateValue}
								userValue={userValue}
							/> */}
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default WorkSta;
