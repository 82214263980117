import { useEffect, useState } from "react";
import OlMap from "../../../utils/olmap";
import { Button, Switch, message } from "antd";
import PlagueWoodTs from "../../services/plagueWood";
import { MapLayers } from "./mapLayer";
import Common from "../../services/Common";
import { DeleteOutlined } from "@ant-design/icons";
import eiminationReports from "../../services/EiminationReports";

interface Props {
  countyData: any;
  onBack: () => void;
}

const typeList = [
  {
    value: 0,
    label: "上报进度",
  },
  {
    value: 1,
    label: "删除进度",
  },
];

const VisualizationMap = ({ countyData, onBack }: Props) => {
  //数据年度
  const year = sessionStorage.getItem("year");
  //0:上报进度 1:删除进度
  const [type, setType] = useState(0);
  //当前选中经纬度
  const [position, setPosition] = useState([]);
  //上报进度 小班列表
  const [reportClassList, setReportClassList] = useState([]);
  //删除进度 小班列表
  const [removeClassList, setRemoveClassList] = useState([]);

  const [smallClassYear, setSmallClassYear] = useState("");

  const [reportLayerVisible, setReportLayerVisible] = useState(false);

  const [newAddClassVisible, setNewAddClassVisible] = useState(false);

  /**
   * @description 初始化地图
   */

  const initMap = () => {
    OlMap.init("ol_map");
    OlMap.ToLngLat([countyData.lon, countyData.lat], 11);
    OlMap.addBoundariesLeary({
      ...MapLayers.boundaries,
      viewparams: `areaCode:${countyData.value};`,
    });
    PlagueWoodTs.getYearConfig({
      areaCode: countyData.value,
      year,
    }).then((res) => {
      const data = res.data;
      if (data.code === 200) {
        setSmallClassYear(data.data.smallClassYear);
        OlMap.addClassBaseMap({
          ...MapLayers.classBaseMap,
          viewparams: `countyCode:${countyData.value};year:${data.data.smallClassYear}`,
        });
      }
    });

    OlMap.map.on("click", (e) => {
      const newPosition = [
        e.coordinate[0].toFixed(6) * 1,
        e.coordinate[1].toFixed(6) * 1,
      ];
      setPosition(newPosition);
    });
  };

  const changeType = (value) => {
    setType(value);
  };

  const changeReportLayerVisible = (checked) => {
    setReportLayerVisible(checked);
    if (checked) {
      OlMap.addReportLayer({
        ...MapLayers.reportLayer,
        viewparams: `countyCode:${countyData.value};year:${year};smallClassYear:${smallClassYear}`,
      });
    } else {
      OlMap.removeReportLayer();
    }
  };

  const changeNewAddClassVisible = (checked) => {
    setNewAddClassVisible(checked);
    if (checked) {
      OlMap.addNewAddClass({
        ...MapLayers.newAddClass,
        viewparams: `countyCode:${countyData.value};year:${year};smallClassYear:${smallClassYear}`,
      });
    } else {
      OlMap.removeNewAddClass();
    }
  };

  /**
   * @description 保存
   */

  const onSave = () => {
    const data = {
      year,
      isVisualizedReport: true,
      countyCode: countyData.value,
      classIds: (type === 0 ? reportClassList : removeClassList).map((item) => {
        return item.classId;
      }),
    };
    if (type === 0) {
      eiminationReports.batchReport(data).then((res) => {
        const resData = res.data;
        if (resData.code === 200) {
          message.success(resData.data);
          onClear();
        }
      });
    } else {
      eiminationReports.batchReportDelete(data).then((res) => {
        const resData = res.data;
        if (resData.code === 200) {
          message.success(resData.data);
          onClear();
        }
      });
    }
  };

  /**
   * @description 清空
   */

  const onClear = () => {
    if (type === 0) {
      setReportClassList([]);
    } else {
      setRemoveClassList([]);
    }
  };

  /**
   * @description 删除列表中对应的内容
   * @param index
   */

  const deleteItem = (index) => {
    const newList = type === 0 ? [...reportClassList] : [...removeClassList];
    newList.splice(index, 1);
    if (type === 0) {
      setReportClassList(newList);
    } else {
      setRemoveClassList(newList);
    }
  };

  useEffect(() => {
    initMap();
  }, []);

  useEffect(() => {
    if (position.length > 0) {
      const newList = type === 0 ? [...reportClassList] : [...removeClassList];
      Common.getClassLonlat({
        x: position[0],
        y: position[1],
        year,
        countyCode: countyData.value,
      }).then((res) => {
        const data = res.data;
        if (data.code === 200) {
          newList.push(data.data);
          if (type === 0) {
            setReportClassList(newList);
          } else {
            setRemoveClassList(newList);
          }
        }
      });
    }
  }, [position]);
  return (
    <div className="visualization_map">
      <div className="map_box" id="ol_map"></div>
      <div className="list_box">
        <div className="list_head">
          {typeList.map((item) => (
            <div
              key={item.value}
              className={`head_item ${item.value === type && "active_item"}`}
              onClick={() => changeType(item.value)}
            >
              {item.label}
            </div>
          ))}
        </div>
        <div className="list_body">
          <div className="body_head">小班列表</div>
          <div className="body_content">
            {(type === 0 ? reportClassList : removeClassList).map(
              (item, index) => (
                <div key={index} className="content_item">
                  <div className="item_label">{item.className}</div>
                  <DeleteOutlined
                    rev={null}
                    onClick={() => deleteItem(index)}
                  />
                </div>
              )
            )}
          </div>
        </div>
        <div className="list_foot">
          <Button type="primary" onClick={onSave}>
            选择完毕
          </Button>
          <Button type="primary" onClick={onClear}>
            清空所有
          </Button>
        </div>
      </div>
      <div className="back_button">
        <Button type="primary" onClick={onBack}>
          返回
        </Button>
        <div className="switch_box">
          <div className="switch_label">上报图层</div>
          <Switch
            checked={reportLayerVisible}
            onChange={changeReportLayerVisible}
          />
        </div>
        <div className="switch_box">
          <div className="switch_label">新发小班</div>
          <Switch
            checked={newAddClassVisible}
            onChange={changeNewAddClassVisible}
          />
        </div>
      </div>
    </div>
  );
};

export default VisualizationMap;
