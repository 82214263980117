import { useEffect, useState } from 'react';
import Request from '../../../services/Manger';
import SplitLine from '../../../component/tools/SplitLine';
import back from '../../../assets/images/CurrentAccept.png';
import BaseCard from '../../../component/Card/BaseCard';
import Progress from '../../../component/tools/Progress';
// 丽水市·疫情发生程度
export default function Occurrdegree({ props }) {
  const { code, level, theName, year } = props;
  const [data, setData] = useState([]);
  useEffect(() => {
    Request.OutbreakLevel({ areaCode: code, areaLevel: level, year }, (res) => {
      if (res.code === 200) {
        res.data[0] ? setData(res.data) : setData([]);
      }
    });
  }, [code, level, year]);

  return (
    <BaseCard
      title={`${theName}·疫情发生程度`}
      describe={'DEGREE OF EPIDEMIC'}
      height={220}
      backround={back}
    >
      <div className="UnAcceptance">
        {data.length === 0 ? (
          <div className="UnAcceptance_nodata">暂无数据</div>
        ) : (
          <>
            <div className="UnAcceptance_unit">单位：‰</div>
            <SplitLine height={117} width={232}>
              {data.map((item, index) => (
                <div className="UnAcceptance_content" key={index}>
                  <span className="county" title={item.name}>
                    {item.name}
                  </span>
                  <Progress
                    progress={item.treeRate * 10}
                    distance={true}
                    colorLeft="#02D68A"
                    style={{ width: '327px' }}
                  />
                  <span className="number">
                    {(item.treeRate * 10).toFixed(2)}
                  </span>
                </div>
              ))}
            </SplitLine>
          </>
        )}
      </div>
    </BaseCard>
  );
}
