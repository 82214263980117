interface Props {
	data: any;
}

export const OverviewBox = ({ data }: Props) => {
	return (
		<div className={'overview-box'}>
			<img src={data.icon} alt=""></img>
			<div className={'right'}>
				<div className={'title'}>
					<span>{data.name}</span>
					<span>{data.title}</span>
				</div>
				<div className={'value'}>
					{data.value}
					<span>{data.unit}</span>
				</div>
			</div>
		</div>
	);
};

export const AreaDataBox = ({ data }: Props) => {
	return (
		<div className={'area-box'}>
			<div className="area_title">
				<div className={'index'}>{data.index}</div>
				<div className={'line'}></div>
				<div className={'area'}>{data.name}</div>
			</div>
			<div className="area_data">
				<div className={'value first'}>
					{data.title}
					<span>{data.value}</span>
					<span>{data.unit + "，"}</span>
				</div>
				<div className={'value'}>
					{data.title_1}
					<span style={{ color: "#00BAFF" }}>{data.value_1}</span>
					<span>{data.unit_1}</span>
				</div>
			</div>
		</div>
	);
};
