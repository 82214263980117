import { Component } from "react";
import "./index.scss";
import MenuList from "./menu";
import {
  Button,
  Layout,
  Avatar,
  Modal,
  message,
  Popover,
  Select,
  Tooltip,
  Badge,
  Form,
  Input,
  notification,
} from "antd";
import { Link, Route, Switch } from "react-router-dom";
import { EnvironmentOutlined, UserOutlined } from "@ant-design/icons";
import Census from "./census";
import PlagueWood from "./plagueWood";
import Orthographic from "./orthographic";
import OrthoList from "./orthoList";
import PlatdataList from "./platdataList";
import Adjust from "./adjustment";
import Annual from "./annual";
import Score from "./score";
import Car from "./car";
import Acceptance from "./acceptance/index";
import Check from "./check/index";
import Assist from "./assist/index";
import Parse from "./parse/index";
import ExcelKml from "./excel_kml/index";
import WorkSta from "./work";
import User from "./userManagement";
import Notice from "./notice";
import Reimbursement from "./reimbursement";
import TravelReport from "./travelReport";
import HouseAb from "./houseAb";
import EiminationReport from "./eliminationreport";
import ErrorHandle from "./errorHandle";

import { acceptance_axios } from "../utils/axios";
import versionUpdate from "../assets/images/versionUpdate.svg";
import FormItem from "antd/es/form/FormItem";
import ToolTs from "./services/tool";
import AcceptanceServer from "./services/Acceptance";
import EditHistory from "./editHistory";
import ReportHistory from "./reportHistory";

interface Props {}

const { Header, Sider, Content } = Layout;
const { Option } = Select;

let allCountyCode = [
  {
    name: "丽水市",
    code: "331100",
    mode: 1,
    position: [119.63104, 28.09652],
    propsareaCode: "331100",
    level: 1,
  },
  {
    name: "金华市",
    code: "330784",
    mode: 2,
    position: [120.15, 28.89652],
    propsareaCode: "330700",
    level: 2,
  },
  {
    name: "温州市",
    code: "330300",
    mode: 1,
    position: [120.672111, 28.000575],
    propsareaCode: "330300",
    level: 1,
  },
  {
    name: "衢州市",
    code: "330800",
    mode: 1,
    position: [118.87263, 28.941708],
    propsareaCode: "330800",
    level: 1,
  },
];

const router_all = [
  { key: "/platform/plagueWood", name: "疫木信息", element: PlagueWood },
  { key: "/platform/census", name: "普查管理", element: Census },
  { key: "/platform/orthographic", name: "正射上传", element: Orthographic },
  { key: "/platform/orthoList", name: "正射列表", element: OrthoList },
  { key: "/platform/platdataList", name: "疫木数据", element: PlatdataList },
  { key: "/platform/adjust", name: "数据调整", element: Adjust },
  { key: "/platform/annual", name: "年度配置", element: Annual },
  { key: "/platform/score", name: "算分管理", element: Score },
  { key: "/platform/car", name: "车辆管理", element: Car },
  { key: "/platform/user", name: "用户管理", element: User },
  { key: "/platform/acceptance", name: "跟踪验收", element: Acceptance },
  { key: "/platform/check", name: "监理检查", element: Check },
  { key: "/platform/work", name: "工作统计", element: WorkSta },
  { key: "/platform/assist", name: "面积补充", element: Assist },
  { key: "/platform/parse", name: "图片解析", element: Parse },
  { key: "/platform/excel_kml", name: "Excel转换KML", element: ExcelKml },
  { key: "/platform/notice", name: "公告管理", element: Notice },
  { key: "/platform/houseAb", name: "房前屋后", element: HouseAb },
  {
    key: "/platform/eliminationreport",
    name: "除治上报",
    element: EiminationReport,
  },
  {
    key: "/platform/reimbursement",
    name: "报销单上传",
    element: Reimbursement,
  },
  {
    key: "/platform/travelReport",
    name: "出差报告上传",
    element: TravelReport,
  },
  {
    key: "/platform/errorHandle",
    name: "异常处理",
    element: ErrorHandle,
  },
  {
    key: "/platform/editHistory",
    name: "验收历史修改",
    element: EditHistory,
  },
  {
    key: "/platform/reporthistory",
    name: "报告历史",
    element: ReportHistory,
  },
];

const router_sourist = [
  { key: "/platform/plagueWood", name: "疫木信息", element: PlagueWood },
  { key: "/platform/census", name: "普查管理", element: Census },
  { key: "/platform/acceptance", name: "跟踪验收", element: Acceptance },
  { key: "/platform/check", name: "监理检查", element: Check },
];

// 	ROLE_0: '超级管理员', dckj_admin
// 	ROLE_1: '平台使用人员', 全部
// 	ROLE_2: '游客账号', qt_tourist
// 	ROLE_3: '日志监管账号',dckj_rzjg
// 	ROLE_4: '删除验收图片专用账号',

const role_power = {
  ROLE_0: router_all,
  ROLE_1: router_all,
  ROLE_2: router_sourist,
  ROLE_3: router_sourist,
  ROLE_4: router_all,
};

export default class Platform extends Component<any, any> {
  sessiondata = JSON.parse(sessionStorage.getItem("state"));
  role = localStorage.getItem("role");
  role_list = ["ROLE_3", "ROLE_2"];
  sse = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      countyCode: this.sessiondata
        ? this.sessiondata.code
        : this.props.location.state.code,
      name: this.sessiondata
        ? this.sessiondata.name
        : this.props.location.state.name,
      mode: this.sessiondata
        ? this.sessiondata.mode
        : this.props.location.state.mode,
      propsareaCode: this.sessiondata
        ? this.sessiondata.propsareaCode
        : this.props.location.state.propsareaCode,
      position: this.sessiondata
        ? this.sessiondata.position
        : this.props.location.state.position,
      level: this.sessiondata
        ? this.sessiondata.level
        : this.props.location.state.level,
      badgeShow: JSON.parse(localStorage.getItem("badgeShow")),
      userData: JSON.parse(localStorage.getItem("token")),
      yearList: JSON.parse(sessionStorage.getItem("yearList")),
      year: sessionStorage.getItem("year"),
      resetPasswordFlag: false,
      resetId: JSON.parse(localStorage.getItem("token")).id,
      newPassword: "",
      confirmPassword: "",
      oldPassword: "",
      oldWrong: false,
      isExitAbnormalRecord: false,
    };
  }

  componentDidMount = () => {
    // sessionStorage.setItem("city_dictionary", JSON.stringify(allCountyCode))
    if (this.props.location.state?.screenVisible === "allow") {
      this.cityChange("331100");
    }
    this.addSessionDate(allCountyCode[0]);

    this.sse = new EventSource(
      `${window["env"].REACT_APP_WS_SMART_ADD}?userId=${
        JSON.parse(localStorage.getItem("token")).id
      }`,
      {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).access_token,
        },
      } as any
    );
    window["reportHistorySSE"] = this.sse;

    // this.sse.addEventListener("open", function () {
    //   console.log("open");
    // });

    this.sse.addEventListener("message", (e) => {
      if (e.data) {
        const data = JSON.parse(e.data);
        if ((data.type = "createReportZip")) {
          switch (data.code) {
            case 200:
              return notification.open({
                message: "报告压缩包生成成功",
              });
            case 504:
              return notification.open({
                message: "报告压缩包生成超时",
              });
            default:
              return notification.open({
                message: "报告压缩包生成失败",
              });
          }
        }
      }
    });
  };

  addSessionDate = (data) => {
    sessionStorage.setItem("cityCode", data.propsareaCode);
    sessionStorage.setItem("cityName", data.name);
    sessionStorage.setItem("lon", JSON.stringify(data.position[0]));
    sessionStorage.setItem("lat", JSON.stringify(data.position[1]));
  };

  componentWillMount = () => {
    // const mapid = document.getElementById('CMap')
    // var allchild = document.getElementById("CMap").children;
    // allchild.length !== 0 && document.body.removeChild(mapid)
    const mapid = document.getElementById("CMap");
    mapid && mapid.remove();
    this.getMistackeRecordNum();
  };

  componentWillUnmount(): void {
    sessionStorage.removeItem("state");
    this.sse.close();
  }

  // 获取错误验收的数量
  getMistackeRecordNum = async () => {
    const { data: res } = await AcceptanceServer.getMistakeRecordNum();

    if (res.code === 200) {
      res.data > 0 && this.setState({ isExitAbnormalRecord: true });
    }
  };

  //修改年份
  yearChange = (value) => {
    sessionStorage.setItem("year", value);
    this.setState({
      year: value,
    });
  };
  // 查看版本的迭代
  Viewversion = () => {
    const { badgeShow } = this.state;
    if (badgeShow) {
      localStorage.setItem("badgeShow", "false");
      const version = localStorage.getItem("version");
      acceptance_axios
        .get(`/common/update_version`, { params: { version } })
        .then((res) => {
          if (res.data.code === 200) {
            this.setState({
              badgeShow: false,
            });
          } else {
            message.error({
              content: `您在更新版本信息时出现未知错误，但这不影响您使用，请向管理员及时反馈`,
              duration: 2,
            });
          }
        });
    }
    window.open("/versionlog");
  };

  //退出登录
  logOut = () => {
    localStorage.removeItem("token");
    if (
      localStorage.getItem("isFromAdmin") ||
      localStorage.getItem("isFromAdmin") === "yes"
    ) {
      window.location.href = "/admin";
      localStorage.removeItem("admin");
    } else {
      window.location.href = "/";
    }
  };

  //后台城市选择参数对应
  cityChange = (value) => {
    allCountyCode.forEach((item) => {
      if (value === item.propsareaCode) {
        this.setState({
          countyCode: item.code,
          mode: item.mode,
          propsareaCode: item.propsareaCode,
          name: item.name,
          position: item.position,
          level: item.level,
        });
        const state = {
          code: item.code,
          mode: item.mode,
          propsareaCode: item.propsareaCode,
          name: item.name,
          position: item.position,
          level: item.level,
        };
        sessionStorage.setItem("state", JSON.stringify(state));
        this.addSessionDate(item);
      }
    });
  };

  hideModal = () => {
    this.setState({
      resetPasswordFlag: false,
      oldWrong: false,
    });
  };

  resetPWNow = (value) => {
    const { resetId, oldPassword, newPassword, confirmPassword } = this.state;
    ToolTs.resetUserPassword(
      {
        id: resetId,
        newPassword: value.newPassword,
        newPasswordSecond: value.password,
        oldPassword: value.oldPassword,
      },
      (res) => {
        if (res.code === 200) {
          notification.success({
            message: `重置密码成功`,
            description: "",
            duration: 2,
          });
          setTimeout(() => {
            localStorage.removeItem("token");
            window.location.href = "/";
          }, 500);
          this.setState({
            resetPasswordFlag: false,
            oldWrong: false,
          });
        } else {
          this.setState({
            oldWrong: true,
          });
          notification.error({
            message: res.msg,
            description: "",
            duration: 1,
          });
        }
      }
    );
  };

  //新密码
  handleNewPasswordChange = (e) => {
    const newPassword = e.target.value;
    this.setState({ newPassword });
  };

  //确认密码
  handleConfirmPasswordChange = (e) => {
    const confirmPassword = e.target.value;
    this.setState({ confirmPassword });
  };

  //旧密码
  handleOldPasswordChange = (e) => {
    const oldPassword = e.target.value;
    this.setState({ oldPassword });
  };

  //确认密码
  compareToFirstPassword = (_, value) => {
    const { newPassword } = this.state;
    if (value && value !== newPassword) {
      return Promise.reject("请输入正确新密码！");
    }
    return Promise.resolve();
  };

  //比较新旧密码
  compareOldAndNewPassword = (_, value) => {
    const { oldPassword } = this.state;
    if (value && value === oldPassword) {
      return Promise.reject("新密码和旧密码不能相同！");
    }
    return Promise.resolve();
  };

  render() {
    // const { username } = this.props.location.state;
    const token = window.localStorage.getItem("token");
    const username = token ? JSON.parse(token).account : "获取失败";
    const {
      countyCode,
      name,
      mode,
      propsareaCode,
      position,
      level,
      userData,
      badgeShow,
      yearList,
      resetPasswordFlag,
      oldWrong,
    } = this.state;
    const locationState = {
      code: countyCode,
      mode,
      name,
      username,
      propsareaCode,
      position,
      level,
    };
    const menu = (
      <div className={"user-info"}>
        <div style={{ marginBottom: "10px" }}>姓名：{userData?.name}</div>
        <div style={{ marginBottom: "10px" }}>账号：{userData?.account}</div>
        <div style={{ marginBottom: "10px" }}>
          电话：{userData?.phone ?? "-"}
        </div>
        <div className={""}>
          <Button
            type="primary"
            size="small"
            style={{ margin: "0 5px" }}
            onClick={this.logOut}
          >
            退出登录
          </Button>
          <Button
            type="primary"
            size="small"
            style={{ margin: "0 5px" }}
            onClick={() =>
              this.setState({
                resetPasswordFlag: true,
              })
            }
          >
            修改密码
          </Button>
        </div>
      </div>
    );

    return (
      <>
        <Modal
          title="提醒"
          visible={this.state.isExitAbnormalRecord}
          onCancel={() => this.setState({ isExitAbnormalRecord: false })}
          footer={null}
        >
          <div
            style={{
              textAlign: "center",
              color: "red",
              margin: "86px 0 86px 0",
              fontSize: "20px",
            }}
          >
            验收记录存在异常，请前往“辅助工具-异常处理”中处理
          </div>
        </Modal>
        <Layout
          style={{ width: "100vw", height: "100vh" }}
          className="Platform"
        >
          <Sider width={256} trigger={null} className={"Platform-sider"}>
            <div className="sider-header">
              <span>点创科技云森防平台</span>
            </div>
            <MenuList locationState={locationState} />
          </Sider>
          <Layout className="site-layout">
            <Header className="layout-header" style={{ padding: 0 }}>
              <div className="layout-header-left">
                <EnvironmentOutlined rev={undefined} />
                <span
                  style={{
                    fontSize: "15px",
                  }}
                >
                  当前城市：
                </span>
                <Select
                  onChange={(value) => this.cityChange(value)}
                  style={{ width: 100 }}
                  defaultValue={propsareaCode}
                  disabled={Boolean(this.role_list.indexOf(this.role) !== -1)}
                >
                  {allCountyCode.map((item, index) => {
                    return (
                      <Option value={item.propsareaCode} key={index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </div>
              <div className={"header-right"}>
                <Link
                  to={{
                    pathname: "/navigation",
                    state: {
                      username: username,
                    },
                  }}
                >
                  <Button
                    className={
                      "navigator-btn-color"
                      // this.role_list.indexOf(this.role) !== -1
                      //   ? "navigator-btn"
                      //   : "navigator-btn-color"
                    }
                    // disabled={Boolean(this.role_list.indexOf(this.role) !== -1)}
                  >
                    返回导航页
                  </Button>
                </Link>
                <Link
                  to={{
                    pathname: "/editPage",
                    state: {
                      name: name,
                      code: countyCode,
                      mode: mode,
                      level: 1,
                      propsareaCode: propsareaCode,
                      username: username,
                    },
                  }}
                >
                  <Button
                    className={
                      "navigator-btn-color"
                      // this.role_list.indexOf(this.role) !== -1
                      //   ? "navigator-btn"
                      //   : "navigator-btn-color"
                    }
                    // disabled={Boolean(this.role_list.indexOf(this.role) !== -1)}
                  >
                    返回展示端
                  </Button>
                </Link>
                <Tooltip title="版本更新日志" placement="bottom">
                  <div className={"btn-box"} onClick={() => this.Viewversion()}>
                    <Badge dot={badgeShow}>
                      <img
                        src={versionUpdate}
                        style={{ width: "18px", height: "18px" }}
                        alt=""
                      />
                      {/* <BellOutlined style={{ color: "#FFFFFF", fontSize: "18px" }} /> */}
                    </Badge>
                  </div>
                </Tooltip>
                <Popover
                  content={menu}
                  title="个人中心"
                  placement="bottomRight"
                >
                  <div className="header-menu" onClick={() => {}}>
                    <Avatar
                      className="avatar"
                      size={"default"}
                      icon={<UserOutlined rev={undefined} />}
                    />
                  </div>
                </Popover>
              </div>
            </Header>
            {role_power[this.role].map(
              (item, index) =>
                this.props.location.pathname === item.key && (
                  <div className="platform_header" key={index}>
                    {item.name}
                    <span className="platform_year">
                      数据年度：
                      <Select
                        disabled={
                          window.localStorage.getItem("role").indexOf("2") > 0
                            ? true
                            : false
                        }
                        className="footer-select"
                        placement="topRight"
                        onChange={(value) => this.yearChange(value)}
                        defaultValue={sessionStorage.getItem("year")}
                      >
                        {yearList &&
                          yearList.map((item: any) => {
                            return (
                              <Option value={item.value} key={item.key}>
                                {item.value}
                              </Option>
                            );
                          })}
                      </Select>
                    </span>
                  </div>
                )
            )}
            <Content style={{ margin: "20px 24px ", padding: "20px 24px" }}>
              <Switch>
                {role_power[this.role].map((item, index) => (
                  <Route path={item.key} exact key={index}>
                    <item.element
                      locationState={locationState}
                      cityCode={countyCode}
                      year={this.state.year}
                    />
                  </Route>
                ))}
              </Switch>
            </Content>
          </Layout>
        </Layout>
        <Modal
          title="重置密码"
          visible={resetPasswordFlag}
          footer={null}
          destroyOnClose={true}
          onCancel={this.hideModal}
        >
          <Form onFinish={this.resetPWNow}>
            <FormItem
              label="请输入旧密码"
              name="oldPassword"
              rules={[{ required: true, message: "请输入旧密码！" }]}
            >
              <Input.Password onChange={this.handleOldPasswordChange} />
            </FormItem>
            <FormItem
              label="输入新密码"
              name="newPassword"
              rules={[
                { required: true, message: "请输入新密码！" },
                { validator: this.compareOldAndNewPassword },
              ]}
            >
              <Input.Password
                type="password"
                onChange={this.handleNewPasswordChange}
              />
            </FormItem>
            <FormItem
              label="确认新密码"
              name="password"
              rules={[
                { required: true, message: "请确认密码！" },
                { validator: this.compareToFirstPassword },
              ]}
            >
              <Input.Password
                type="password"
                onChange={this.handleConfirmPasswordChange}
              />
            </FormItem>
            {oldWrong && (
              <>
                <div
                  className="oldpassword-wrong"
                  style={{
                    textAlign: "center",
                    color: "red",
                    marginBottom: "20px",
                  }}
                >
                  旧密码输入错误,请重新输入
                </div>
              </>
            )}
            <div className="form-button">
              <FormItem>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginRight: "10px" }}
                >
                  确认
                </Button>
                <Button onClick={this.hideModal}>取消</Button>
              </FormItem>
            </div>
          </Form>
        </Modal>
      </>
    );
  }
}
