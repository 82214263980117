import React, { Component } from "react";
import { Button, message, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { file_axios } from "../../utils/axios";
import "./assist.scss";

interface AssistProps {
  locationState: any;
}

interface AssistState {}

class Assist extends Component<AssistProps, AssistState> {
  constructor(props: AssistProps) {
    super(props);
    this.state = {};
  }

  // 模板下载
  download = () => {
    const a = document.createElement("a");
    a.href =
      "https://lsysf.oss-cn-hangzhou.aliyuncs.com/template/%E5%B0%8F%E7%8F%AD%E9%9D%A2%E7%A7%AF%E5%AF%BC%E5%87%BA%E6%A8%A1%E6%9D%BF.xlsx";
    a.click();
  };

  // 导出小班面积
  upload = (file) => {
    const data = new FormData();
    data.append("file", file);
    file_axios.defaults.headers.common["Authorization"] =
      "Bearer " + JSON.parse(localStorage.getItem("token")).access_token;
    file_axios.post("/common/export_class_area", data).then((res) => {
      if (res.status === 200) {
        message.success("导出成功");
        let blob = new Blob([res.data], { type: "application/vnd.ms-excel" }); // res就是接口返回的文件流了
        let objectUrl = URL.createObjectURL(blob);
        window.location.href = objectUrl;
      }
    });
  };

  render() {
    const props: any = {
      action: window["env"].REACT_APP_API_URL + "/common/export_class_area",
      listType: "picture-card",
      headers: {
        responseType: "blob",
        // authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')).access_token
      },
      // data: (file) => {
      //   return {
      //     type: 2
      //   }
      // },
      beforeUpload: (file, fileList) => {
        this.upload(file);
        return false;
      },
      // maxCount: 50,
      // multiple: true,
      accept: ".xlsx",
      maxCount: 1,
      showUploadList: {
        showPreviewIcon: false,
        showRemoveIcon: true,
        showDownloadIcon: false,
      },
    };
    return (
      <div className={"assist-box"}>
        <div className="upload-box">
          <Upload {...props}>
            <PlusOutlined rev={undefined} />
          </Upload>
        </div>
        <div className="download-box">
          <Button type="primary" onClick={this.download}>
            下载模板
          </Button>
        </div>
      </div>
    );
  }
}

export default Assist;
