export const MapLayers = {
  //乡镇边界图层
  countryLayer: {
    id: "qt_town_boundary",
    layers: "mountain-field-acceptance:qt_town_boundary",
    format: "image/png",
    srs: "EPSG:4549",
    style: "",
    transparent: true,
    exceptions: "application/vnd.ogc.se_inimage",
  },
  //验收通过图层
  passLayer: {
    layers: "mountain-field-acceptance:acceptance",
    tited: true,
    format: "image/png",
    styles: "accept_pass",
    srs: "EPSG:4549",
    viewparams: "result:1;year:2021-2022",
  },
  //验收不通过图层
  unPassLayer: {
    layers: "mountain-field-acceptance:acceptance",
    tited: true,
    format: "image/png",
    styles: "accept_unpass",
    srs: "EPSG:4549",
    viewparams: "result:0;year:2021-2022",
  },
  //不纳入验收图层
  noAccLayer: {
    layers: "mountain-field-acceptance:acceptance",
    tited: true,
    format: "image/png",
    styles: "accept_no",
    srs: "EPSG:4549",
    viewparams: "result:3;year:2021-2022",
  },
  //全量小班图层
  AllSmallClassLayer: {
    id: "qt_small_class_all",
    layers: "mountain-field-acceptance:qt_small_class_all",
    format: "image/png",
    styles: "",
    srs: "EPSG:4549",
  },

  WoodLayer: {
    id: "wms-layer",
    format: "image/png",
    styles: "",
    srs: "EPSG:4549",
    layers: "pine-epidemic-census:tree_identify",
  },
};
