import { Button, Modal, Table } from "antd";
import { useEffect, useState } from "react";
import eiminationReports from "../../services/EiminationReports";
import ProblemMap from "./problemMap";

interface Props {
  year: string;
  changeVisible: () => void;
}

const ProblemModal = ({ year, changeVisible }: Props) => {
  //总数
  const [total, setTotal] = useState(0);
  //列表
  const [dataSource, setDataSource] = useState([]);
  //当前页码
  const [current, setCurrent] = useState(1);
  const [activeData, setActiveData] = useState(null);
  //获取table列表
  const getDataSource = () => {
    const params = {
      year,
      page: current,
      size: 7,
    };
    eiminationReports.queryExcelReportFail(params).then((res) => {
      const data = res.data;
      if (data.code === 200) {
        setDataSource(data.data.list);
        setTotal(data.data.total);
      }
    });
  };
  //页码改变
  const paginationChange = (page) => {
    setCurrent(page);
  };
  //选中的上报小班
  const report = (value) => {
    setActiveData(value);
    if (!value) {
      getDataSource();
    }
  };

  useEffect(() => {
    getDataSource();
  }, [current]);

  const columns = [
    {
      title: "乡镇(街道)",
      dataIndex: "town",
    },
    {
      title: "行政村",
      dataIndex: "village",
    },
    {
      title: "林班",
      dataIndex: "forest",
    },
    {
      title: "小班",
      dataIndex: "smallClass",
    },
    {
      title: "操作",
      dataIndex: "",
      render: (value) => {
        return (
          <Button type="link" onClick={() => report(value)}>
            手动上报
          </Button>
        );
      },
    },
  ];

  const pagination = {
    total,
    current,
    showSizeChanger: false,
    onChange: paginationChange,
  };

  return (
    <Modal
      title="上报失败列表"
      visible={true}
      footer={false}
      onCancel={changeVisible}
      width={950}
      bodyStyle={activeData ? { padding: "24px 10px" } : null}
    >
      {activeData ? (
        <ProblemMap reportData={activeData} onClose={() => report(null)} />
      ) : (
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={pagination}
        />
      )}
    </Modal>
  );
};

export default ProblemModal;
