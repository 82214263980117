// @ts-nocheck
import React, { Component, createRef } from "react";
import {
  Modal,
  Form,
  Select,
  Input,
  Button,
  DatePicker,
  Upload,
  message,
  Checkbox,
  Pagination,
  Switch,
} from "antd";
import { PlusOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import Common from "../../platform/services/Common";
// import { exportWord } from 'mhtml-to-word'
import { exportWord } from "../../utils/wordhtml";
import Acceptance from "../../platform/services/Acceptance";
import ExportReportFormNew from "./exportReportFormNew";

const { Option } = Select;
const { RangePicker } = DatePicker;
const FormItem = Form.Item;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

interface ExportReportState {
  current: number;
  list: Array<any>;
  townList: Array<any>;
  villageList: Array<any>;
  reportData: any;
  fileList: Array<any>;
  time: string;
  townCode: string;
  pdf: boolean;
  checkVisible: boolean;
}
interface ExportReportProps {
  visible: boolean;
  handleCancel: any;
  countyCode: any;
  countyList: any;
  isInspection: number;
  townCode: string;
  isReading: boolean;
  areaLevel: number;
}

// 导出报告
class ExportReport extends Component<ExportReportProps, ExportReportState> {
  countyCode = this.props.countyCode;
  ref: any = createRef();
  formRef: any = createRef();
  constructor(props) {
    super(props);
    this.state = {
      pdf: false,
      current: 1,
      list: [],
      townList: [],
      villageList: [],
      villageListUnique: [],
      time: "",
      townCode: this.props.townCode,
      fileList: [],
      reportData: null,
      checkVisible: false,
      forestVisible: this.countyCode === "331124" ? true : false,
      startTime: "",
      endTime: "",
      picAllChoose: false,
      forestListTree: [],
      annexList: [],
    };
  }

  componentDidMount() {
    Common.getTownList({ countyCode: this.countyCode }).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          townList: res.data.data,
        });
      }
    });
    this.onAreaChanged(this.props.townCode ? this.props.townCode : "0");
  }

  // 乡镇/街道选择
  onAreaChanged = (value) => {
    const { startTime, endTime } = this.state;
    const { isInspection } = this.props;
    const params = {
      countyCode: this.countyCode,
      townCode: value,
      startTime,
      endTime,
      isReport: true,
      isInspection: isInspection,
    };

    Common.getVillageList(params).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          townCode: value,
          villageList: res.data.data,
        });
      }
    });

    this.getVillageListUnique(params);
  };

  //获取乡镇
  getVilList = (params) => {
    Common.getVillageList(params).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          villageList: res.data.data,
        });
      }
    });
  };

  getVillageListUnique = (params) =>
    Common.getListVillageUnique(params).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          villageListUnique: res.data.data,
        });
      }
    });

  villageUniqueChange = (villageCodeUnique, annexId) => {
    const { startTime, endTime } = this.state;
    const { isInspection } = this.props;
    const params = {
      villageCode: villageCodeUnique,
      startTime,
      endTime,
      isInspection,
    };
    Common.getForestListTree(params).then((res) => {
      // console.log(res);
      if (res.data.code === 200) {
        const tmp = [...this.state.annexList];
        tmp.forEach((item) => {
          if (item.id === annexId) {
            item.dataTree = res.data.data;
          }
        });
        this.setState({
          forestListTree: res.data.data,
          annexList: [...tmp],
        });
      }
    });
  };

  //日期的变化
  rangeChange = (value) => {
    const params = {
      countyCode: this.countyCode,
      townCode: this.state.townCode,
      startTime: "",
      endTime: "",
      isReport: true,
      isInspection: this.props.isInspection,
    };

    if (value) {
      const startTime = value[0].format("YYYY-MM-DD 00:00:00");
      const endTime = value[1].format("YYYY-MM-DD 23:59:59");
      this.setState({ startTime, endTime });
      params.startTime = startTime;
      params.endTime = endTime;
    } else {
      this.setState({
        startTime: "",
        endTime: "",
      });
    }

    this.getVilList(params);
    this.getVillageListUnique(params);
  };

  //新增照片选择
  changeList = (index, key) => {
    const { list } = this.state;
    // 新增
    if (key === 1) {
      let tmpList = [
        {
          id: 1,
          villageCode: "",
          forestCode: "",
          smallClassList: [],
        },
      ];
      if (list.length !== 0) {
        tmpList = [
          ...list,
          {
            id: list[list.length - 1].id + 1,
            villageCode: "",
            forestCode: "",
            smallClassList: [],
          },
        ];
      }

      this.setState({
        list: tmpList,
      });
    } else {
      const newList = [...list];
      newList.splice(index, 1);
      this.setState({
        list: newList,
      });
    }
  };

  addOrDelAnnex = (id, flag) => {
    const { annexList } = this.state;
    let tmpList = [...annexList];
    switch (flag) {
      case "del":
        const index = tmpList.findIndex((item) => item.id === id);
        if (index > -1) {
          tmpList.splice(index, 1);
          this.setState({
            annexList: [...tmpList],
          });
        }
        return;
      case "add":
        if (!tmpList.length) {
          tmpList.push({
            id: 1,
            dataTree: [],
            villageCode: null,
            picCondition: [],
          });
        } else {
          const newAnnex = {
            id: tmpList[tmpList.length - 1].id + 1,
            dataTree: [],
            villageCode: null,
            picCondition: [],
          };
          tmpList.push(newAnnex);
        }
        this.setState({ annexList: [...tmpList] });
        return;
      default:
        return;
    }
  };

  updateAnnex = (
    id: number,
    flag: "changeVillage" | "changePicCondition",
    data: { villageCode?: string; picCondition?: Array<string> }
  ) => {
    const tmpList = [...this.state.annexList];
    switch (flag) {
      case "changeVillage":
        tmpList.forEach((item) => {
          if (item.id === id) {
            item.villageCode = data.villageCode;
            item.picCondition = [];
          }
        });

        this.setState({ annexList: [...tmpList] });
        return;
      case "changePicCondition":
        tmpList.forEach((item) => {
          if (item.id === id) {
            item.picCondition = data.picCondition;
          }
        });

        this.setState({ annexList: [...tmpList] });
        return;
      default:
        return;
    }
  };

  // 更新验收照片选择
  updateClass = (changedValues, allValues, index) => {
    const { list } = this.state;
    const newList = [...list];
    newList[index] = {
      villageCode: allValues.villageCode ? allValues.villageCode : "",
      forestCode: allValues.forestCode ? allValues.forestCode : "",
      smallClassList: allValues.smallClassList ? allValues.smallClassList : [],
    };
    this.setState({
      list: newList,
    });
  };

  // 翻页
  pageChanged = (value) => {
    const { current } = this.state;
    // const total = Math.ceil(list.length / 2)
    if (value > current) {
      this.ref.current.style.left = "-" + (value - 1) * 752 + "px";
      this.setState({
        current: value,
      });
    } else if (value < current) {
      this.ref.current.style.left = "-" + (value - 1) * 750 + "px";
      this.setState({
        current: value,
      });
    }
  };

  exportReportFormSubmit = (e) => {
    const { frequency, time } = e;
    const { countyList, countyCode } = this.props;
    const {
      townCode,
      annexList,
      picAllChoose,
      checkVisible,
      townList,
      fileList,
    } = this.state;
    const picCondition = [].concat(
      ...annexList.map((item) => item.picCondition)
    );

    const reportName = countyList.find((item) => {
      return countyCode === item.code;
    });

    const params = {
      countyCode: this.countyCode,
      picCondition,
      townCode: checkVisible ? "" : townCode,
      startTime: time[0].format("YYYY-MM-DD 00:00:00"),
      endTime: time[1].format("YYYY-MM-DD 23:59:59"),
      year: sessionStorage.getItem("year"),
      isInspection: this.props.isInspection,
      picAllChoose,
    };

    message.loading({ content: "正在生成报告中...", duration: 0 });
    Acceptance.getReport(params).then((res) => {
      if (res.data.code === 200) {
        this.setState(
          {
            reportData: {
              countyName: reportName.name,
              townName: checkVisible
                ? "全部街道"
                : [{ code: "0", name: "全部街道" }, ...townList].find(
                    (item) => {
                      return item.code === townCode;
                    }
                  ).name,
              frequency,
              startTime: time[0].format("YYYY年MM月DD日"),
              endTime: time[1].format("YYYY年MM月DD日"),
              ...res.data.data,
            },
            pdf: true,
          },
          () => {
            message.destroy();
          }
        );
      } else {
        message.destroy();
        message.error("生成报告失败，请检查后重试");
      }
    });
  };

  // 生成报告
  finishReport = (value) => {
    const { list, townList, checkVisible, townCode, picAllChoose } = this.state;
    const { time, file, frequency } = value;
    const { countyList, countyCode } = this.props;
    const reportName = countyList.find((item) => {
      return countyCode === item.code;
    });
    const params = {
      countyCode: this.countyCode,
      picCondition: list,
      townCode: checkVisible ? "" : townCode,
      startTime: time[0].format("YYYY-MM-DD 00:00:00"),
      endTime: time[1].format("YYYY-MM-DD 23:59:59"),
      year: sessionStorage.getItem("year"),
      isInspection: this.props.isInspection,
      picAllChoose,
    };
    message.loading({ content: "正在生成报告中...", duration: 0 });
    Acceptance.getReport(params).then((res) => {
      if (res.data.code === 200) {
        this.setState(
          {
            reportData: {
              countyName: reportName.name,
              townName: checkVisible
                ? "全部街道"
                : townList.find((item) => {
                    return item.code === townCode;
                  }).name,
              frequency,
              startTime: time[0].format("YYYY年MM月DD日"),
              endTime: time[1].format("YYYY年MM月DD日"),
              ...res.data.data,
            },
            pdf: true,
            fileList: file ? file.fileList : [],
          },
          () => {
            message.destroy();
          }
        );
      } else {
        message.destroy();
        message.error("生成报告失败，请检查后重试");
      }
    });
  };

  onChange = (value) => {
    this.setState({
      checkVisible: value.target.checked,
    });
  };

  // 导出报告
  handleExport = () => {
    // const scale = 2

    exportWord({
      filename: "跟踪验收报告",
      selector: "#pdf-content",
      style: `
          .pdf-header {
            width: 595.28px;
            height: 841.89px;
          }
          .pdf-page {
            width: 599.28px;
            margin-bottom: 70px;
          }
          p{
            margin: 0;
          }
          .bodytd{
            text-align:center;
          }

          .title1 {
            font-size: 30px;
            text-align:center;
            margin-bottom: 250px;
            font-weight: 600;
          }
          .trackreport{
            width: 10px;
            font-weight: 600;
            font-size: 30px;
            text-align:center;
          }
          .trackreportlast{
            width: 10px;
            font-weight: 600;
            font-size: 30px;
            margin-bottom: 250px;
            text-align:center;
          }
          .title2 {
            font-size: 20px;
            text-align:center;
          }
          .text {
            font-size: 20px;
            margin-bottom: 25px;
          }
          .texttitle {
            font-size: 22px;
            text-align:center;
            font-weight: 700;
            margin-bottom: 25px;
          }
          .subscript {
              font-size: 12px;
              text-align:right;
              margin-bottom: 25px;
          }
          .margin {
            font-size: 0px;
            margin-bottom: 40px;
          }
          .title3 {
            font-size: 22px;
            text-align:center;
            margin-bottom: 200px;
          }
          .pdf-img {
            width: 595.28px;
            height: 350px;
          }
          #pdf-img {
            width: 595.28px;
            height: 350px;
            margin-bottom: 20px;
          }
          .pdf-imgbox {
            width: 595.28px;
            height: 350px;
            text-align:center;
          }
          .info-title {
            font-size: 20px;
          }
          .title {
            text-decoration: solid;
            color: #000000;
            font-size: 22px;
          }
          .tableForm{
            width: 100%;
            margin-bottom: 20px;
          }
        `,
    });
    message.success("导出成功");
  };

  onVillageOrForestChange = (value, key) => {
    const { countyCode, townCode, time } = this.props;
    const { villageCode } = this.state;
    switch (key) {
      case 1:
        Common.getForestList({
          countyCode: countyCode,
          townCode,
          villageCode: value,
          isReport: true,
          ...time,
        }).then((res) => {
          if (res.data.code === 200) {
            this.setState({
              villageCode: value,
              forestList: res.data.data,
            });
          }
        });
        break;
      case 2:
        Acceptance.getClassList({
          countyCode: countyCode,
          townCode,
          villageCode,
          forestCode: value,
          isReport: true,
          ...time,
        }).then((res) => {
          if (res.data.code === 200) {
            this.setState({
              forestCode: value,
              smallClassList: res.data.data,
            });
          }
        });
        break;
    }
  };

  fileListChange = (e) => {
    this.setState({
      fileList: e.fileList,
    });
  };

  exportWordFile = () => {
    const { countyList, countyCode } = this.props;
    const {
      townCode,
      annexList,
      picAllChoose,
      checkVisible,
      townList,
      fileList,
      startTime,
      endTime,
    } = this.state;
    const { frequency } = this.state.reportData;
    const picCondition = [].concat(
      ...annexList.map((item) => item.picCondition)
    );

    const reportName = countyList.find((item) => {
      return countyCode === item.code;
    });

    const formData = new FormData();

    fileList.forEach((item) => {
      formData.append("files", item.originFileObj);
    });
    formData.append(
      "jsonParams",
      JSON.stringify({
        countyCode: this.countyCode,
        picCondition,
        townCode: checkVisible ? "" : townCode,
        startTime,
        endTime,
        year: sessionStorage.getItem("year"),
        isInspection: this.props.isInspection,
        picAllChoose,
        round: frequency,
      })
    );

    Acceptance.exportReportZip(formData).then((res) => {
      if (res.data.code === 200) {
        const history = this.props.history;
        message.success("导出成功,请前往 数据管理-报告历史 查看");
        this.props.handleCancel(this.props.visible);
        history.push("/platform/reporthistory");
      }
    });
  };

  render() {
    const {
      picAllChoose,
      current,
      list,
      townList,
      townCode,
      villageList,
      pdf,
      reportData,
      fileList,
      checkVisible,
      startTime,
      endTime,
      forestVisible,
      forestList,
    } = this.state;
    const { areaLevel, isInspection, countyCode } = this.props;
    const columns = [
      {
        title: "序号",
        dataIndex: "",
        key: "",
      },
      {
        title: "乡镇街道",
        dataIndex: "townName",
        key: "townName",
      },
      {
        title: "行政村",
        dataIndex: "villageName",
        key: "villageName",
      },
      // {
      // 	title: '施工企业名称',
      // 	dataIndex: 'teamName',
      // 	key: 'teamName',
      // },
      {
        title: "验收面积(亩)",
        dataIndex: "acceptArea",
        key: "acceptArea",
      },
      {
        title: "验收小班数",
        dataIndex: "acceptClass",
        key: "acceptClass",
      },
      {
        title: "不合格小班数",
        dataIndex: "unqualifiedClass",
        key: "unqualifiedClass",
      },
      {
        title: "合格小班数",
        dataIndex: "qualifiedClass",
        key: "qualifiedClass",
      },
      {
        title: "合格率",
        dataIndex: "qualifiedRate",
        key: "qualifiedRate",
      },
      // {
      // 	title: '标段合格率',
      // 	dataIndex: '',
      // 	key: '',
      // },
    ];
    let columns2 = [
      {
        title: "序号",
        dataIndex: "",
        key: "",
      },
      {
        title: "乡镇街道",
        dataIndex: "townName",
        key: "townName",
      },
      {
        title: "行政村",
        dataIndex: "villageName",
        key: "villageName",
      },
      {
        title: "林班",
        dataIndex: "forestCode",
        key: "forestCode",
      },
      {
        title: "小班号",
        dataIndex: "smallClass",
        key: "smallClass",
      },
      {
        title: "面积",
        dataIndex: "area",
        key: "area",
      },
      {
        title: "验收情况",
        dataIndex: "acceptance",
        key: "acceptance",
      },
      {
        title: "验收结果",
        dataIndex: "result",
        key: "result",
      },
      {
        title: "备注",
        dataIndex: "situation",
        key: "situation",
      },
    ];
    const columns5 = [
      {
        title: "序号",
        dataIndex: "",
        key: "",
      },
      {
        title: "乡镇街道",
        dataIndex: "townName",
        key: "townName",
      },
      {
        title: "行政村",
        dataIndex: "villageName",
        key: "villageName",
      },
      {
        title: "林班",
        dataIndex: "forestCode",
        key: "forestCode",
      },
      {
        title: "小班号",
        dataIndex: "smallClass",
        key: "smallClass",
      },
      {
        title: "面积",
        dataIndex: "area",
        key: "area",
      },
      {
        title: "检查数",
        dataIndex: "situation",
        key: "situation",
      },
      {
        title: "不合格伐桩",
        dataIndex: "unqualifiedcutting",
        key: "unqualifiedcutting",
      },
      {
        title: "枝干遗留",
        dataIndex: "branches",
        key: "branches",
      },
      {
        title: "伐桩合格率",
        dataIndex: "cutQualifiedRate",
        key: "cutQualifiedRate",
      },
      {
        title: "梢头枝桠滞留率",
        dataIndex: "twigRetentionRate",
        key: "twigRetentionRate",
      },
      {
        title: "验收结果",
        dataIndex: "result",
        key: "result",
      },
      {
        title: "备注",
        dataIndex: "situation",
        key: "situation",
      },
    ];
    const columns3 = [
      {
        title: "检查数",
        dataIndex: "situation",
        key: "situation",
      },
      {
        title: "不合格伐桩",
        dataIndex: "unqualifiedcutting",
        key: "unqualifiedcutting",
      },
      {
        title: "枝干遗留",
        dataIndex: "branches",
        key: "branches",
      },
      {
        title: "未砍伐枯死木",
        dataIndex: "deadwood",
        key: "deadwood",
      },
    ];
    const columns4 = [
      {
        title: "检查数",
        dataIndex: "situation",
        key: "situation",
      },
      {
        title: "不合格伐桩",
        dataIndex: "unqualifiedcutting",
        key: "unqualifiedcutting",
      },
      {
        title: "枝干遗留",
        dataIndex: "branches",
        key: "branches",
      },
      {
        title: "伐桩合格率",
        dataIndex: "cutQualifiedRate",
        key: "cutQualifiedRate",
      },
      {
        title: "梢头枝桠滞留率",
        dataIndex: "twigRetentionRate",
        key: "twigRetentionRate",
      },
    ];
    isInspection == 1 ? (columns2 = columns5) : (columns2 = columns2);
    const trackreportList =
      isInspection === 1 ? ["监", "理", "报"] : ["跟", "踪", "验", "收", "报"];
    const props: any = {
      action: window["env"].REACT_APP_API_HOST + "/upload",
      listType: "picture-card",
      beforeUpload: () => {
        return false;
      },
      accept: ".png,.jpg,.jpeg",
      showUploadList: {
        showPreviewIcon: false,
        showRemoveIcon: true,
        showDownloadIcon: false,
      },
    };

    return (
      <Modal
        // title="导出报告"
        visible={this.props.visible}
        footer={null}
        onCancel={() => this.props.handleCancel(this.props.visible)}
        maskClosable={false}
        centered={true}
        width={1200}
        className="exportReport"
      >
        {!pdf ? (
          // <Form onFinish={this.finishReport}>
          //   <div className={"header1"}>
          //     <span>导出范围及验收单上传</span>
          //   </div>
          //   <FormItem
          //     name="townCode"
          //     label="乡镇/街道"
          //     rules={[
          //       {
          //         required: checkVisible || townCode ? false : true,
          //         message: "请选择乡镇/街道",
          //       },
          //     ]}
          //     {...layout}
          //   >
          //     <Select
          //       onSelect={this.onAreaChanged}
          //       defaultValue={townCode}
          //       style={{ width: 400 }}
          //       disabled={areaLevel === 3 && true}
          //     >
          //       {townList.map((item, index) => {
          //         return (
          //           <Option key={index} value={item.code}>
          //             {item.name}
          //           </Option>
          //         );
          //       })}
          //     </Select>
          //     <Checkbox
          //       onChange={this.onChange}
          //       style={{ marginLeft: "20px" }}
          //       disabled={areaLevel === 3 && true}
          //     >
          //       全选
          //     </Checkbox>
          //   </FormItem>
          //   <FormItem
          //     name="frequency"
          //     label="验收轮次"
          //     rules={[
          //       {
          //         required: true,
          //         message: "请输入验收轮次",
          //       },
          //     ]}
          //     {...layout}
          //   >
          //     <Input placeholder="请输入验收轮次" style={{ width: "400px" }} />
          //   </FormItem>
          //   <FormItem
          //     label="起止时间"
          //     name="time"
          //     rules={[
          //       {
          //         required: true,
          //         message: "请选择导出报告日期",
          //       },
          //     ]}
          //     {...layout}
          //   >
          //     <RangePicker style={{ width: 400 }} onChange={this.rangeChange} />
          //   </FormItem>
          //   <FormItem
          //     label="验收单照片"
          //     name="file"
          //     rules={[
          //       {
          //         required: false,
          //         message: "请选择验收单照片",
          //       },
          //     ]}
          //     {...layout}
          //   >
          //     <Upload {...props}>
          //       <div>
          //         <PlusOutlined />
          //         <div style={{ marginTop: 8 }}>上传</div>
          //       </div>
          //     </Upload>
          //   </FormItem>
          //   <div className={"acceptance-list"}>
          //     <div className={"header"}>
          //       <span>验收照片选择</span>
          //       <span>
          //         全选：
          //         <Switch
          //           defaultChecked={picAllChoose}
          //           onChange={(value) => {
          //             this.setState({
          //               picAllChoose: value,
          //               list: [],
          //             });
          //           }}
          //           style={{ marginRight: "10px", marginTop: "-10px" }}
          //         />
          //         <PlusSquareOutlined
          //           onClick={() => this.changeList(null, 1)}
          //         />
          //       </span>
          //     </div>
          //     <div className={"body"}>
          //       <div ref={this.ref}>
          //         {list.map((item, index) => {
          //           return (
          //             <PicInfo
          //               data={item}
          //               index={index}
          //               countyCode={this.countyCode}
          //               townCode={townCode}
          //               villageList={villageList}
          //               time={{ startTime, endTime }}
          //               updateClass={this.updateClass}
          //               changeList={this.changeList}
          //             />
          //           );
          //         })}
          //       </div>
          //     </div>
          //     <div className={"footer"}>
          //       <Pagination
          //         current={current}
          //         total={list.length}
          //         defaultPageSize={2}
          //         onChange={this.pageChanged}
          //       />
          //       <Button
          //         type="primary"
          //         style={{ marginTop: "15px" }}
          //         htmlType="submit"
          //       >
          //         生成报告
          //       </Button>
          //     </div>
          //   </div>
          // </Form>
          <ExportReportFormNew
            onFinish={this.finishReport}
            defaultTown={townCode}
            townList={townList}
            townChange={this.onAreaChanged}
            onCancel={() => this.props.handleCancel(this.props.visible)}
            changeAnnexList={(id, flag) => this.addOrDelAnnex(id, flag)}
            isChooseAllAnnex={picAllChoose}
            isChooseAllAnnexChange={(value) => {
              this.setState({
                picAllChoose: value,
                annexList: [],
              });
            }}
            annexList={this.state.annexList}
            onVillageOrForestChange={this.onVillageOrForestChange}
            annexInfo={{
              villageList: this.state.villageListUnique,
              forestListTree: this.state.forestListTree,
            }}
            dateChange={this.rangeChange}
            clearAllAnnex={() =>
              this.setState({
                annexList: [],
              })
            }
            reportDate={{ startTime, endTime }}
            villageUniqueChange={this.villageUniqueChange}
            updateAnnex={this.updateAnnex}
            handleExportFormSubmit={this.exportReportFormSubmit}
            fileList={this.state.fileList}
            fileListChange={this.fileListChange}
          ></ExportReportFormNew>
        ) : (
          <div className={"pdf-box"}>
            <div id={"pdf-content"}>
              <div className={"pdf-header"} id={"pdf-header"}>
                <div className={"title1"}>
                  <span className={"trackreport1"}>
                    {reportData.countyName}
                    {reportData.townName}松材线虫病防治
                  </span>
                  <br />
                </div>
                {trackreportList.map((item, index) => (
                  <p className={"trackreport"} key={index}>
                    {item}
                  </p>
                ))}
                <p className={"trackreportlast"}>告</p>
                <div className={"title2"}>
                  <span>浙江点创信息科技有限公司</span>
                  <div className={"title3"}>
                    <span>{`${new Date().getFullYear()}`}</span>年
                    <span>{`${new Date().getMonth() + 1}`}</span>月
                    <span>{`${new Date().getDate()}`}</span>日
                  </div>
                </div>
              </div>
              <div className={"text"}>
                &nbsp;&nbsp;{reportData.startTime}至{reportData.endTime}
                。项目组采用无人机航拍与人工踏查方式对{reportData.countyName}
                {reportData.townName}松材线虫病防治清理质量，进度进行第
                {reportData.frequency}轮
                {isInspection === 1 ? "监理" : "跟踪验收"}。 此次共验收
                {reportData.townName}，{reportData.villageCount}个行政村，
                {reportData.forestCount}个林班，{reportData.classCount}
                个小班，其中验收合格小班{reportData.acceptPassClassCount}
                个小班， 合格率为{reportData.passRate}%。总计验收面积
                {reportData.acceptArea}亩，验收合格小班面积
                {reportData.acceptPassArea}亩。
              </div>
              <div className={"texttitle"}>
                &nbsp;&nbsp;{reportData.countyName}
                {reportData.townName}松材线虫病防治
                {isInspection === 1 ? "监理" : "跟踪验收"}情况汇总表
              </div>
              <div className={"subscript"}>单位：亩、%</div>
              <div className={"pdf-page"}>
                <table
                  border="1"
                  align="center"
                  cellspacing="0"
                  text-align="center"
                  className="tableForm"
                  width="100%"
                >
                  <tr>
                    {columns.map((item) => {
                      return <th>{item.title}</th>;
                    })}
                  </tr>
                  <tbody className={"body"}>
                    {reportData.tableData.map((item, index) => {
                      return (
                        <>
                          <tr
                            id={"tablefirst"}
                            text-align="center"
                            className={"bodytd"}
                          >
                            <td
                              rowSpan={`${item.data.length}`}
                              width="5%"
                              style={{ height: `${item.data.length * 50}px` }}
                            >
                              {index + 1}
                            </td>
                            <td
                              rowSpan={`${item.data.length}`}
                              width="10%"
                              style={{ height: `${item.data.length * 50}px` }}
                            >
                              {item.title}
                            </td>
                            <td text-align="center" width="15%">
                              {item.data[0].villageName}
                            </td>
                            {/* <td text-align="center" width="9%">
															{item.data[0].teamName}
														</td> */}
                            <td text-align="center" width="15%">
                              {item.data[0].acceptArea}
                            </td>
                            <td text-align="center" width="15%">
                              {item.data[0].acceptClass}
                            </td>
                            <td text-align="center" width="15%">
                              {item.data[0].unqualifiedClass}
                            </td>
                            <td text-align="center" width="15%">
                              {item.data[0].qualifiedClass}
                            </td>
                            <td text-align="center" width="10%">
                              {item.data[0].qualifiedRate}%
                            </td>
                            {/* <td text-align="center" width="15%">
															%
														</td> */}
                          </tr>
                          {item.data.slice(1).map((item2, index) => {
                            return (
                              <tr text-align="center" className={"bodytd"}>
                                <td text-align="center" width="20%">
                                  {item2.villageName}
                                </td>
                                {/* <td text-align="center" width="10%">
																	{item2.teamName}
																</td> */}
                                <td text-align="center" width="20%">
                                  {item2.acceptArea}
                                </td>
                                <td text-align="center" width="20%">
                                  {item2.acceptClass}
                                </td>
                                <td text-align="center" width="20%">
                                  {item2.unqualifiedClass}
                                </td>
                                <td text-align="center" width="10%">
                                  {item2.qualifiedClass}
                                </td>
                                <td text-align="center" width="10%">
                                  {item2.qualifiedRate}%
                                </td>
                                {/* <td text-align="center" width="20%">
																	%
																</td> */}
                              </tr>
                            );
                          })}
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className={"margin"}>1</div>
              <div className={"texttitle"}>
                {reportData.countyName}
                {reportData.townName}松材线虫病除治项目
                {isInspection === 1 ? "监理" : "跟踪验收"}情况小班一览表
              </div>
              <div className="subscript">单位：株、个、处</div>
              <div className={"pdf-page"}>
                <table
                  border="1"
                  align="center"
                  cellspacing="0"
                  text-align="center"
                  className="tableForm"
                  width="100%"
                >
                  <tr>
                    {columns2 &&
                      (countyCode == "331124"
                        ? [
                            ...columns2,
                            {
                              title: "分数",
                              dataIndex: "score",
                              key: "score",
                            },
                          ].map((item) => {
                            return (
                              <>
                                <th
                                  colSpan={
                                    item.title === "验收情况"
                                      ? isInspection == 1
                                        ? "5"
                                        : "4"
                                      : ""
                                  }
                                  rowSpan={item.title === "验收情况" ? "" : "2"}
                                >
                                  {item.title}
                                </th>
                              </>
                            );
                          })
                        : columns2.map((item) => {
                            return (
                              <>
                                <th
                                  colSpan={
                                    item.title === "验收情况"
                                      ? isInspection == 1
                                        ? "5"
                                        : "4"
                                      : ""
                                  }
                                  rowSpan={item.title === "验收情况" ? "" : "2"}
                                >
                                  {item.title}
                                </th>
                              </>
                            );
                          }))}
                  </tr>
                  <tr>
                    {isInspection === 1
                      ? ""
                      : columns3 &&
                        columns3.map((item) => <th>{item.title}</th>)}
                  </tr>
                  <tbody className={"body"}>
                    {reportData.classList.map((item2, index) => {
                      return (
                        <>
                          <tr
                            id={"tablefirst"}
                            text-align="center"
                            className={"bodytd"}
                          >
                            <td text-align="center" width="5%">
                              {index + 1}
                            </td>
                            <td text-align="center" width="9%">
                              {item2.townName}
                            </td>
                            <td text-align="center" width="9%">
                              {item2.villageName}
                            </td>
                            <td text-align="center" width="5%">
                              {forestVisible
                                ? item2.forestName
                                : item2.forestCode}
                            </td>
                            <td text-align="center" width="9%">
                              {item2.smallClass}
                            </td>
                            <td text-align="center" width="5%">
                              {item2.area}
                            </td>
                            <td text-align="center" width="5%">
                              {isInspection === 1
                                ? item2.surveyPileNum
                                : item2.result === 1
                                ? "0"
                                : ""}
                            </td>
                            <td text-align="center" width="5%">
                              {isInspection === 1
                                ? item2.failedPileNum
                                : item2.result === 1
                                ? "0"
                                : ""}
                            </td>
                            <td text-align="center" width="5%">
                              {isInspection === 1
                                ? item2.twigRetentionNum
                                : item2.result === 1
                                ? "0"
                                : ""}
                            </td>
                            <td text-align="center" width="10%">
                              {isInspection === 1
                                ? item2.cutQualifiedRate + "%"
                                : item2.result === 1
                                ? "0"
                                : ""}
                            </td>
                            {isInspection === 1 && (
                              <td text-align="center" width="5%">
                                {isInspection === 1
                                  ? item2.twigRetentionRate
                                    ? item2.twigRetentionRate
                                    : 0
                                  : item2.result === 1
                                  ? "0"
                                  : ""}
                              </td>
                            )}

                            <td text-align="center" width="9%">
                              {item2.result === 0 ? "不合格" : "合格"}
                            </td>
                            <td text-align="center" width="25%">
                              {item2.situation}
                            </td>
                            {countyCode == "331124" && (
                              <td text-align="center" width="25%">
                                {item2.score}
                              </td>
                            )}
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
                <div
                  className={"info-title"}
                  style={{ marginTop: isInspection == 1 ? "170px" : "20px" }}
                >
                  现场照片：请下载后到压缩包中查看。
                </div>
                {/* {reportData.townPicList.map((item, index) => {
                  return (
                    <>
                      <div
                        style={{
                          fontSize: "24px",
                          fontFamily: "微软雅黑",
                          fontWeight: "bold",
                        }}
                      >
                        乡镇：{item.townName}
                      </div>
                      {item.acceptanceReportPicRDTO.map((e, i) => {
                        return (
                          <div className={"pdf-page"}>
                            <div className={"info-title"}>{e.title}</div>
                            {e.urlList.map((item2) => {
                              return (
                                <img
                                  className="pdf-img"
                                  id={"pdf-img"}
                                  src={item2}
                                  width={"595.28"}
                                  height={"350"}
                                  alt=""
                                ></img>
                              );
                            })}
                          </div>
                        );
                      })}
                    </>
                  );
                })} */}
                {/* {reportData.picList.map((item, index) => {
									return (
										<div className={'pdf-page'}>
											<div className={'info-title'}>{item.title}</div>
											{item.urlList.map((item2) => {
												return (
													<img
														className="pdf-img"
														id={'pdf-img'}
														src={item2}
														width={'595.28'}
														height={'350'}
														alt=""
													></img>
												);
											})}
										</div>
									);
								})} */}
                {/* <div className={'pdf-page'}>
									<div className={'text'}>《{reportData.countyName}{reportData.townName}松材线虫病防治第{reportData.frequency}轮跟踪验收详情表》</div>
									<table border="1" align="center" cellspacing="0" text-align="center" className='tableForm' width="100%">
										<tr>
											{
												columns2 && columns2.map(item => {
													return <th >{item.title}</th>
												})
											}
										</tr>
										<tbody className={'body'}>
											{
												reportData.classList.map((item2, index) => {
													return <>
														<tr id={'tablefirst'} text-align="center">
															<td text-align="center" width="15%">{item2.townName}</td>
															<td text-align="center" width="15%">{item2.villageName}</td>
															<td text-align="center" width="15%">{item2.forestCode}</td>
															<td text-align="center" width="10%">{item2.smallClass}</td>
															<td text-align="center" width="10%">{item2.result}</td>
															<td text-align="center" width="35%">{item2.situation}</td>
														</tr>
													</>
												})
											}
										</tbody>
									</table>
								</div> */}
                <div className={"pdf-page"}>
                  <div className={"text"}>验收单汇总：</div>
                  {fileList.map((item) => {
                    return (
                      <img
                        className="pdf-img"
                        id={"pdf-img"}
                        src={item.thumbUrl}
                        width={"595.28"}
                        height={"350"}
                        alt=""
                      ></img>
                    );
                  })}
                </div>
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <Button
                type="primary"
                style={{ marginTop: "15px" }}
                // onClick={this.handleExport}
                onClick={this.exportWordFile}
              >
                导出报告
              </Button>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

export default withRouter(ExportReport);

interface PicInfoProps {
  data: any;
  index: number;
  townCode: string;
  countyCode?: string;
  villageList?: Array<any>;
  updateClass?: (changedValues, allValues, index) => void;
  changeList?: (index, key) => void;
}

interface PicInfoState {
  forestList: Array<any>;
  smallClassList: Array<any>;
  villageCode: string;
  forestCode: string;
}

// 新增验收照片选择
export class PicInfo extends Component<PicInfoProps, PicInfoState> {
  constructor(props) {
    super(props);
    this.state = {
      forestList: [],
      smallClassList: [],
      villageCode: "",
      forestCode: "",
    };
  }

  //行政村选择
  changeSelect = (value, key) => {
    const { countyCode, townCode, time } = this.props;
    const { villageCode } = this.state;
    switch (key) {
      case 1:
        Common.getForestList({
          countyCode: countyCode,
          townCode,
          villageCode: value,
          isReport: true,
          ...time,
        }).then((res) => {
          if (res.data.code === 200) {
            this.setState({
              villageCode: value,
              forestList: res.data.data,
            });
          }
        });
        break;
      case 2:
        Acceptance.getClassList({
          countyCode: countyCode,
          townCode,
          villageCode,
          forestCode: value,
          isReport: true,
          ...time,
        }).then((res) => {
          if (res.data.code === 200) {
            this.setState({
              forestCode: value,
              smallClassList: res.data.data,
            });
          }
        });
        break;
    }
  };

  render() {
    const { villageList, data, index, updateClass, changeList } = this.props;
    const { forestList, smallClassList } = this.state;
    return (
      <div className={"picInfo-box"}>
        <Form
          onValuesChange={(changedValues, allValues) =>
            updateClass(changedValues, allValues, index)
          }
          initialValues={{ ...data }}
        >
          <FormItem
            name="villageCode"
            label="行政村"
            rules={[
              {
                required: true,
                message: "请选择行政村",
              },
            ]}
            {...layout}
          >
            <Select onSelect={(value) => this.changeSelect(value, 1)}>
              {villageList.map((item, index) => {
                return (
                  <Option key={index} value={item.code}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </FormItem>
          <FormItem
            name="forestCode"
            label="林班"
            rules={[
              {
                required: true,
                message: "请选择林班",
              },
            ]}
            {...layout}
          >
            <Select onSelect={(value) => this.changeSelect(value, 2)}>
              {forestList.map((item, index) => {
                return (
                  <Option key={index} value={item.code}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </FormItem>
          <FormItem
            name="smallClassList"
            label="小班号"
            rules={[
              {
                required: true,
                message: "请选择小班号",
              },
            ]}
            {...layout}
          >
            <Select mode="multiple" showArrow={true} maxTagCount={2}>
              {smallClassList.map((item, index) => {
                return (
                  <Option key={index} value={item.smallClass}>
                    {item.smallClass}
                  </Option>
                );
              })}
            </Select>
          </FormItem>
        </Form>
        <Button
          type="primary"
          onClick={() => changeList(index, 2)}
          className={"del-btn"}
        >
          删除
        </Button>
      </div>
    );
  }
}
