import { axios } from '../../utils/axios';

export default class NoticeRequest {
  // 获取公告管理表格数据
  static getDataList(params) {
    return axios.get('/system_notice/query', { params });
  }
  // 获取公告管理所有数据
  static getAllDataList() {
    return axios.get('/system_notice/query_all');
  }
  // 删除数据
  static deleteData(id) {
    return axios.delete(`/system_notice/delete/${id}`);
  }
  // 批量删除数据
  static deleteBatchData(params) {
    return axios.post(`/system_notice/batch_delete`, params);
  }
  // 新增
  static putNoticeEdit(params) {
    return axios.put(`/system_notice/edit`, params);
  }
}
