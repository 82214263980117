import React from "react";
import { Button, Image, Popconfirm, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import OlMap from "../../utils/olmap";
import Acceptance from "../../platform/services/Acceptance";

//地图图片弹窗
export const InfoWin = ({ data, refresh }) => {
  // const [visible, setVisible] = useState(false)
  const delAccount =
    localStorage.getItem("role").indexOf("0") > 0 ? true : false;

  //关闭按钮
  const close = () => {
    OlMap.map.removeOverlay(OlMap.map.getOverlayById(data.id));
    // setVisible(true)
  };

  const deletePic = (id) => {
    Acceptance.deletePic({ id }).then((res) => {
      if (res.data.code === 200) {
        message.success("删除成功");
        OlMap.map.removeOverlay(OlMap.map.getOverlayById(data.id));
        refresh();
      } else {
        message.error(res.data.msg);
      }
    });
  };

  // const hidePic = (id) => {
  //     axios.post(`/acceptance/hide_pic/${id}`).then((res) => {
  //         if (res.data.code === 200) {
  //             message.success('隐藏成功')
  //             refresh()
  //         }
  //     })
  // }
  // const onVisibleChanged = () => {
  //     setVisible(!visible)
  // }
  // const handle = (values) => {
  //     axios.post('/acceptance/update_pic', {id: data.id, result: values.result }).then((res) => {
  //         if (res.data.code === 200) {
  //             message.success('修改成功')
  //             onVisibleChanged()
  //             refresh()
  //         } else {
  //             message.error('修改失败')
  //             onVisibleChanged()
  //         }
  //     })
  // }
  return (
    <div className={"popupBox"}>
      <CloseOutlined
        rev={undefined}
        className="closeButton"
        onClick={() => {
          close();
        }}
      />
      <Image src={data.url} width={110} height={110} />
      {delAccount && (
        <div>
          {/* <span onClick={onVisibleChanged}>修改</span> */}
          <Popconfirm
            title="确定要删除当前验收图片吗?"
            onConfirm={() => deletePic(data.id)}
            okText="确认"
            cancelText="取消"
          >
            <Button type="link">删除</Button>
          </Popconfirm>
          {/* <Popconfirm
						title="确定要隐藏当前验收图片吗?"
						onConfirm={() => hidePic(data.id)}
						okText="确认"
						cancelText="取消"
					>
						<span>隐藏</span>
					</Popconfirm> */}
        </div>
      )}
      {/* <Modal title="修改照片" visible={visible} footer={null} onCancel={onVisibleChanged}>
                <Form
                    onFinish={handle}
                    initialValues={{ ...data }}
                >
                    <FormItem
                        name='result'
                        label='验收结果'
                        required={true}
                        {...layout}
                    >
                        <Select style={{ marginBottom: '50px' }}>
                            <Option key={'验收通过'} value={'验收通过'}>验收通过</Option>
                            <Option key={'验收不通过'} value={'验收不通过'}>验收不通过</Option>
                        </Select>
                    </FormItem>
                    <div className="infowindow-updatePic-button">
                        <FormItem>
                            <Button type="primary" htmlType="submit">
                                确认
                            </Button>
                            <Button onClick={onVisibleChanged}>
                                取消
                            </Button>
                        </FormItem>
                    </div>
                </Form>
            </Modal> */}
    </div>
  );
};

//验收信息弹窗
export const AcceptInfo = ({ data }) => {
  //关闭按钮
  const close = () => {
    OlMap.map.removeOverlay(OlMap.map.getOverlayById(data.className));
  };

  return (
    <div className="acceptInfo">
      <CloseOutlined
        rev={undefined}
        className="closeButton"
        onClick={() => {
          close();
        }}
      />
      <div className="bottom"></div>
      <div className="acceptdata">
        <div>小班名称：{data.className}</div>
        <div>采伐队：{data.teamName}</div>
        <div>验收次数：{data.frequency}</div>
        <div>验收人：{data.uploadUser}</div>
        <div>验收时间：{data.time}</div>
        <div>备注：{data.remark}</div>
      </div>
    </div>
  );
};
