import axios from "axios";

export default class ToolTs {
  //正射信息记录新增
  static addOrthoImage(json, event) {
    axios.put(`/ortho_image/add`, json).then(res => {
      event(res.data);
    });
  }

  //正射信息表新增/修改
  static orthoEdit(params, event) {
    axios.put(`/ortho_image/edit`, params).then(res => {
      event(res.data);
    });
  }

  //获取正射表信息
  static getOrthoquery(params, event) {
    axios.get(`/ortho_image/query`, { params }).then(res => {
      event(res.data);
    });
  }

  //删除正射信息
  static deleteOrtho(id, event) {
    axios.delete(`/ortho_image/delete/${id}`).then(res => {
      event(res.data);
    });
  }

  //批量删除正射信息
  static batchDeleteOrtho(idList, event) {
    axios.delete(`/ortho_image/batch_delete`, idList).then(res => {
      event(res.data);
    });
  }

  //文件流水表
  static getfileQuery(params, event) {
    axios.get(`/file_info/query`, { params }).then(res => {
      event(res.data);
    });
  }

  //批量删除文件上传流水表
  static deletefileQuery(params, event) {
    axios.delete(`/file_info/delete_by_file_name`, { params }).then(res => {
      event(res.data);
    });
  }

  //调整数据接口
  static getTreeHandle(params, event) {
    axios.get(`/tree_handle_record_info/get_report_data`, { params }).then(res => {
      event(res.data);
    });
  }

  //删除待调整数据
  static postTreeHandle(params, event) {
    axios.post(`/tree_handle_record_info/del_report_data`, params).then(res => {
      event(res.data);
    });
  }

  //批量删除删除待调整数据
  static postBatchTreeHandle(params, event) {
    axios.post(`/tree_handle_record_info/batch_del_report_data`, params).then(res => {
      event(res.data);
    });
  }

  //获取用户列表
  static getUserList(params, event) {
    axios.get(`/user/query`, { params }).then(res => {
      event(res.data);
    });
  }

  //删除用户信息
  static deleteUser(id, event) {
    axios
      .delete(`/user/delete/${id}`)
      .then(res => {
        event(res.data);
      })
      .catch(res => {
        console.log(res);
      });
  }

  // 重置用户密码
  static resetUser(params, event) {
    axios.post(`/user/update_password`, params).then(res => {
      event(res.data);
    });
  }

  // 修改用户密码
  static resetUserPassword(params, event) {
    axios.post(`/user/user_update_password`, params).then(res => {
      event(res.data);
    });
  }

  //用户数据新增/修改
  static putUserEdit(params, event) {
    axios.put(`/user/edit`, params).then(res => {
      event(res.data);
    });
  }

  //  所有人工作时长
  static getAll_User() {
    return axios.get("/user/findUserList");
  }

  //处理待调整数据
  static postDealData(params) {
    return axios.post(`/tree_handle_record_info/deal_report_data`, params);
  }
}
