import { Form, Input, Modal, Button, Upload, message } from "antd";
import React, { Component } from "react";
import { InboxOutlined, InfoCircleFilled } from "@ant-design/icons";
import Request from "../../services/Manger";
import PlagueWoodTs from "../services/plagueWood";
import { CMap } from "@dinnovate/cloudmap_npm";
import { saveAs } from "file-saver";

const FormItem = Form.Item;
const { Dragger } = Upload;
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};

//文件上传
export class AddUpload extends Component<any, any> {
  onSubmit = (value) => {
    const { cancleUpload, getData, areaCode } = this.props;
    message.loading({
      content: "正在导入数据...",
      key: "loading",
      duration: 0,
    });
    const file = new FormData();
    file.append("file", value.file.file);
    const param = {
      file: file,
      areaCode,
      // year: "2022-2023"
    };
    Request.upLoadFile(param, (res) => {
      if (res.code === 200) {
        message.destroy("loading");
        message.success("导入成功");
        cancleUpload(false);
        getData();
      } else {
        message.destroy("loading");
        message.error(res.msg);
      }
    });
  };

  downLoad = () => {
    // window.location.href = window["env"].LoginUrl + `/common/get_txt_demo`
    Request.DownloadTemplate((res) => {
      // let blob = new Blob([res], { type: "application/vnd.ms-excel;charset=utf-8" });  // res就是接口返回的blob文件
      let objectUrl = URL.createObjectURL(res);
      window.location.href = objectUrl;

      // const a = document.createElement('a')
      // document.body.appendChild(a) // 此处增加了将创建的添加到body当中
      // a.href = objectUrl
      // a.download = '文件名称名称.xlsx'
      // a.click()
      // a.remove() // 将a标签移除
    });
  };

  render() {
    const { isuploadData, cancleUpload } = this.props;
    const props: any = {
      headers: {
        responseType: "blob",
        // authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')).access_token
      },
      beforeUpload: (file) => {
        return false;
      },
      // maxCount: 1,
      // multiple: true,
      accept: ".xlsx,.xls",
      showUploadList: {
        showPreviewIcon: false,
        showRemoveIcon: true,
        showDownloadIcon: false,
      },
    };

    return (
      <Modal
        title="文件上传"
        visible={isuploadData}
        onCancel={() => {
          cancleUpload(false);
        }}
        getContainer={false}
        footer={null}
        className="uploadDataModal"
      >
        <Form onFinish={this.onSubmit} className="uploadDataForm">
          <FormItem
            name="file"
            // label="导入文件"
            rules={[
              {
                required: true,
                message: "请上传你需要导入的数据文件",
              },
            ]}
            {...layout}
          >
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined rev={undefined} />
              </p>
              <p className="ant-upload-text">点击或将文件拖拽到此处</p>
              <p className="ant-upload-hint">支持扩展名：.xlsx...</p>
            </Dragger>
          </FormItem>
          <Button
            type="primary"
            onClick={() => {
              this.downLoad();
            }}
          >
            下载模板
          </Button>
          <div className="form-button">
            <FormItem>
              <Button type="primary" htmlType="submit">
                确认
              </Button>
              <Button onClick={() => cancleUpload(false)}>取消</Button>
            </FormItem>
          </div>
        </Form>
      </Modal>
    );
  }
}

//信息弹窗
export class InfoWindow extends Component<any, any> {
  state = {
    smallClassName: null, //小班名称,
    classId: null,
  };

  componentDidMount(): void {
    const { data } = this.props;
    this.setState({
      smallClassName: data.smallClassName,
      classId: data.classId,
    });
  }

  //关闭函数
  close = () => {
    const { classId } = this.state;
    CMap.removeOverlay(classId);
  };

  complete = () => {
    const { classId } = this.state;
    const { onCompleteClass } = this.props;
    onCompleteClass(classId);
    CMap.removeOverlay(classId);
  };

  render() {
    const { smallClassName } = this.state;
    return (
      <div className="InfoWindow">
        <div className="title">
          <InfoCircleFilled rev={undefined} />
          <span>是否更改小班完成状态？</span>
        </div>
        <div className="bottom"></div>
        <span className="name">处理小班：{smallClassName}</span>
        <div className="complete">
          <Button onClick={this.close}>否</Button>
          <Button type="primary" className="dispose" onClick={this.complete}>
            是
          </Button>
        </div>
      </div>
    );
  }
}
