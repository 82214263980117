import React, { Component, createRef } from "react";
import {
  Table,
  Input,
  Button,
  DatePicker,
  message,
  Select,
  Modal,
  Popconfirm,
  Popover,
  Image,
  Tooltip,
  notification,
} from "antd";
import {
  LeftOutlined,
  RightOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Add } from "../../component/acceptance/addReport";
import ExportReport from "../../component/acceptance/exportReport";
import { RectificaReport } from "../../component/acceptance/rectificaReport";
import { Update } from "../../component/acceptance/update";
import { ExportArea } from "../../component/acceptance/exportArea";
import { getOss } from "../../utils/oss";
import Common from "../services/Common";
import Acceptance from "../services/Acceptance";
import Map from "../../component/acceptance/map";
import { TodayReport } from "../../component/acceptance/todayReport";

const { Search } = Input;
const { confirm } = Modal;
const { Option } = Select;
const { RangePicker } = DatePicker;

interface AcceptanceProps {
  year: any;
  cityCode: string;
  locationState: any;
}

interface AcceptanceState {
  data: Array<any>;
  countyList: Array<any>;
  townList: Array<any>;
  villageList: Array<any>;
  page: number;
  size: number;
  key: string;
  loading: boolean;
  countyCode: string;
  townCode: string;
  villageCode: string;
  endTime: string;
  startTime: string;
  total: number;
  selectedRowKeys: Array<any>;
  reportVisible: boolean;
  exportReportVisible: boolean;
  historyList: Array<any>;
  imgpage: number;
  imgtotal: number;
  frequency: number;
  imgList: Array<any>;
  updateVisible: boolean;
  record: any;
  intelligentVisible: boolean;
  exportAreaVisible: boolean;
  rectVisible: boolean;
  result: any;
  year: any;
  isReading: any;
  cityCode: string;
  isShowTable: boolean;
  areaLevel: number;
  todayReportVisible: boolean;
}

class A extends Component<AcceptanceProps, AcceptanceState> {
  pageSize = 10;
  ref: any = createRef();
  role = localStorage.getItem("role");
  role_list = ["ROLE_3", "ROLE_2"];
  constructor(props: AcceptanceProps) {
    super(props);
    this.state = {
      data: [],
      loading: true, //true 加载   false 不加载
      countyList: [],
      townList: [],
      villageList: [],
      frequency: null, //验收次数
      result: null, //验收结果
      page: 1,
      size: 10,
      key: "",
      cityCode: this.props.cityCode ?? sessionStorage.getItem("cityCode"),
      countyCode: null,
      townCode: null,
      villageCode: "",
      areaLevel: null,
      endTime: "",
      startTime: "",
      total: 0,
      selectedRowKeys: [],
      reportVisible: false,
      exportReportVisible: false,
      todayReportVisible: false,
      intelligentVisible: false, //是否开启智能上报
      exportAreaVisible: false, //kml区域导出
      rectVisible: false, //整改报告导出
      historyList: [],
      imgpage: 1,
      imgtotal: 0,
      imgList: [],
      updateVisible: false,
      year: this.props.year,
      record: null,
      isReading: this.role_list.indexOf(this.role) !== -1 ? true : false, //只读还是可操作
      isShowTable: true, //账号是否展示数据列表  false不展示  true展示
    };
  }

  componentDidMount() {
    this.getCountyList();
    getOss();
  }

  getUserRole = () => {
    const token = JSON.parse(localStorage.getItem("token"));
    const { areaCode, areaLevel, role } = token;
    switch (role) {
      case "ROLE_2":
        this.getAccLevel(areaCode, areaLevel, true);
        break;
      case "ROLE_3":
        this.getAccLevel(areaCode, areaLevel, true);
        break;
      default:
        this.getAccLevel(areaCode, areaLevel, false);
        break;
    }
  };

  /**
   *
   * @param areaCode 	区域编号
   * @param level 	区域等级
   * @param IsUpdate 是否更新数据--展示数据
   */
  getAccLevel = (areaCode, level, IsUpdate) => {
    switch (level) {
      case 3:
        const countyCode = areaCode.slice(0, -3);
        const townCode = areaCode.slice(-3);
        this.setState(
          {
            countyCode,
            townCode,
            areaLevel: level,
            loading: IsUpdate,
          },
          () => {
            IsUpdate && this.getData();
            this.getTownList();
            this.getVillageList();
          }
        );
        break;
      default:
        this.setState(
          {
            countyCode: areaCode,
            loading: IsUpdate,
            areaLevel: level,
            isShowTable: IsUpdate,
          },
          () => {
            IsUpdate && this.getData();
            this.getTownList();
          }
        );
        break;
    }
  };

  componentDidUpdate(
    prevProps: Readonly<AcceptanceProps>,
    prevState: Readonly<AcceptanceState>,
    snapshot?: any
  ): void {
    const { countyCode } = this.state;
    const { year, cityCode } = this.props;
    if (year !== prevProps.year) {
      this.setState(
        {
          year,
          page: 1,
          intelligentVisible: false,
        },
        () => {
          this.getData();
        }
      );
    }
    if (cityCode !== prevProps.cityCode) {
      this.setState(
        {
          cityCode,
          countyCode: null,
          townCode: null,
          page: 1,
          intelligentVisible: false,
        },
        () => {
          this.getCountyList();
        }
      );
    }
    if (countyCode !== prevState.countyCode) {
      // console.log(countyCode)
      setTimeout(() => {
        this.getData();
      }, 200);
    }
  }

  // 获取全部数据
  getData = () => {
    const {
      page,
      key,
      townCode,
      year,
      countyCode,
      startTime,
      endTime,
      villageCode,
      frequency,
      result,
      cityCode,
      isShowTable,
      size,
    } = this.state;
    const searchData = {
      page,
      size,
      key,
      cityCode,
      townCode,
      countyCode,
      startTime,
      frequency,
      villageCode,
      endTime,
      year,
      result,
      isInspection: 1,
    };
    if (isShowTable) {
      Acceptance.getList(searchData).then((res) => {
        const data = res.data.data;
        if (data) {
          this.setState({
            page,
            data: data.list,
            total: data.total,
            loading: false,
          });
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  // 获取区县列表
  getCountyList = () => {
    const { cityCode, isReading } = this.state;
    Common.getCountyList({ cityCode }).then((res) => {
      if (res.data.code === 200) {
        this.setState(
          {
            countyList: res.data.data,
          },
          () => {
            if (isReading) {
              this.getUserRole();
              // this.getData();
            } else {
              this.getData();
            }
          }
        );
      }
    });
  };

  // 获取乡镇列表
  getTownList = () => {
    const { countyCode } = this.state;
    Common.getTownList({ countyCode }).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          townList: res.data.data,
        });
      }
    });
  };

  // 获取行政村列表
  getVillageList = () => {
    const { countyCode, townCode } = this.state;
    Common.getVillageList({ countyCode, townCode }).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          villageList: res.data.data,
        });
      }
    });
  };

  // 关键字搜索
  handleSearch = (value) => {
    this.setState(
      {
        key: value,
      },
      () => {
        this.getData();
      }
    );
  };

  // 区县选择
  onCountyChanged = (value) => {
    this.setState(
      {
        countyCode: value ? value : null,
        townCode: null,
        loading: true,
      },
      () => {
        this.getTownList();
        this.getData();
      }
    );
  };

  // 乡镇选择
  onTownChanged = (value) => {
    this.setState(
      {
        townCode: value ? value : null,
        loading: true,
      },
      () => {
        this.getVillageList();
        this.getData();
      }
    );
  };

  // 小班选择
  onVillageChanged = (value) => {
    this.setState(
      {
        villageCode: value ? value : "",
        loading: true,
      },
      () => {
        this.getData();
      }
    );
  };

  //验收次数
  onFrequencyChanged = (value) => {
    this.setState(
      {
        frequency: value ? value.target.value : "",
        loading: true,
      },
      () => {
        this.getData();
      }
    );
  };

  //验收结果
  onResultChanged = (value) => {
    this.setState(
      {
        result: value,
      },
      () => {
        this.getData();
      }
    );
  };

  // 日期范围改变
  rangeChange = (value) => {
    if (value) {
      const startTime = value[0].format("YYYY-MM-DD 00:00:00");
      const endTime = value[1].format("YYYY-MM-DD 23:59:59");
      this.setState(
        {
          startTime,
          endTime,
          loading: true,
        },
        this.getData
      );
    } else {
      this.setState(
        {
          startTime: "",
          endTime: "",
          loading: true,
        },
        this.getData
      );
    }
  };

  //智能上报
  changeIntelligentVisible = (intelligentVisible) => {
    this.setState({
      intelligentVisible: !intelligentVisible,
    });
    if (
      intelligentVisible === true &&
      this.role_list.indexOf(this.role) === -1
    ) {
      this.getData();
    }
  };

  // 选择
  onSelectChange = (selectedRowKeys, selectedRows) => {
    const obj = selectedRows.find((item) => {
      return item.status === "已除治";
    });
    if (obj) {
      message.error("已除治的小班无法再次进行上报");
    } else {
      this.setState({
        selectedRowKeys,
      });
    }
  };

  // 分页改变
  changePagination = (pagination) => {
    this.setState(
      {
        page: pagination.current,
        size: pagination.pageSize,
        loading: true,
      },
      this.getData
    );
  };

  changeReportVisible = () => {
    const { reportVisible } = this.state;
    this.setState({
      reportVisible: !reportVisible,
    });
  };
  //同步数据到验收
  changeSyncCheckData = () => {
    if (this.state.selectedRowKeys.length === 0) {
      message.error("请先选择要同步的数据！");
    } else {
      Common.examineAcceptance(this.state.selectedRowKeys).then((res) => {
        if (res.data.code === 200) {
          message.success("同步成功！");
        }
      });
    }
  };
  changeUpdateVisible = (value) => {
    const { updateVisible, record } = this.state;
    this.setState(
      {
        record: value || record,
      },
      () => {
        this.setState({
          updateVisible: !updateVisible,
        });
      }
    );
  };

  //导出区域
  changeExportAreaVisible = (key) => {
    this.setState({
      exportAreaVisible: !key,
    });
  };

  //导出报告
  changeRectVisible = (key) => {
    this.setState({
      rectVisible: !key,
    });
  };

  //导出整改报告
  exportReportVisible = (exportReportVisible) => {
    this.setState({
      exportReportVisible: !exportReportVisible,
    });
  };

  // 导出当日报告
  todayReportVisible = (todayReportVisible) => {
    this.setState({
      todayReportVisible: !todayReportVisible,
    });
  };

  // 获取验收记录照片
  viewImage = (id) => {
    this.setState({
      imgList: [],
      imgtotal: 0,
    });
    Acceptance.getPicList({ id, isInspection: 1, year: this.state.year }).then(
      (res) => {
        if (res.data.code === 200) {
          this.setState({
            imgList: res.data.data,
            imgtotal: Math.ceil(res.data.data.length / 2),
          });
        }
      }
    );
  };

  onPicPopoverChanged = (value) => {
    if (!value) {
      this.setState({
        imgList: [],
        imgtotal: 0,
      });
    }
  };

  //批量删除
  deleteBatch = () => {
    const { selectedRowKeys } = this.state;
    if (selectedRowKeys.length === 0) {
      message.error("请先选择要删除得数据！");
      return;
    }

    confirm({
      title: "确定删除所选数据吗?",
      icon: <ExclamationCircleOutlined rev={undefined} />,
      okText: "确定",
      cancelText: "取消",
      onOk: () => {
        Acceptance.batchCheckDelData(selectedRowKeys).then((res) => {
          if (res.data.code === 200) {
            message.success("批量删除成功!");
            this.setState({ selectedRowKeys: [] });
            this.getData();
          } else {
            message.error(res.data.msg);
          }
        });
      },
    });
  };

  left = () => {
    const { imgpage } = this.state;
    if (imgpage - 1 > 1) {
      this.ref.current.style.left = "-" + (imgpage - 2) * 170 + "px";
      this.setState({
        imgpage: imgpage - 1,
      });
    } else {
      this.ref.current.style.left = "0px";
      this.setState({
        imgpage: 1,
      });
    }
  };

  right = () => {
    const { imgpage, imgtotal } = this.state;
    if (imgpage + 1 <= imgtotal) {
      this.ref.current.style.left = "-" + imgpage * 170 + "px";
      this.setState({
        imgpage: imgpage + 1,
      });
    }
  };

  render() {
    const {
      countyCode,
      countyList,
      townList,
      villageList,
      page,
      data,
      selectedRowKeys,
      loading,
      total,
      reportVisible,
      townCode,
      areaLevel,
      exportReportVisible,
      imgList,
      updateVisible,
      record,
      intelligentVisible,
      exportAreaVisible,
      isReading,
      cityCode,
      size,
      todayReportVisible,
      rectVisible,
    } = this.state;
    const acceptanceResult = {
      "1": "验收通过",
      "0": "验收不通过",
      "-1": "待审核",
    };
    const acceptanceResultColor = {
      "1": "green",
      "0": "red",
      "-1": "#000000",
    };
    const operation = {
      title: "操作",
      dataIndex: "handle",
      key: "handle",
      align: "center",
      width: "150px",
      render: (text, record) => (
        <>
          <Button
            type="link"
            onClick={() => {
              this.changeUpdateVisible(record);
            }}
            disabled={isReading}
          >
            修改
          </Button>
          <Popconfirm
            title="确定要删除当前记录吗?"
            onConfirm={() => {
              Acceptance.deleteCheckData({ id: record.id }).then((res) => {
                if (res.data.code === 200) {
                  message.success("删除成功");
                  this.getData();
                }
              });
            }}
            okText="确定"
            cancelText="取消"
            disabled={isReading}
          >
            <Button type="link" disabled={isReading}>
              删除
            </Button>
          </Popconfirm>
        </>
      ),
    };
    // 表格数据列
    const columns: any = [
      {
        title: "序号",
        dataIndex: "id",
        key: "id",
        width: "60px",
        render: (text, record, index) => (page - 1) * 10 + (index + 1),
      },
      {
        title: "区县",
        dataIndex: "countyName",
        key: "countyName",
        width: "100px",
      },
      {
        title: "乡镇/街道",
        dataIndex: "townName",
        key: "townName",
        width: "100px",
      },
      {
        title: "小班名称",
        dataIndex: "className",
        key: "className",
        width: "180px",
      },
      {
        title: "伐桩合格率",
        dataIndex: "cutQualifiedRate",
        key: "cutQualifiedRate",
        align: "center",
        width: "120px",
        render: (text, record, index) => <span>{(text || 0) + "%"}</span>,
      },
      {
        title: "枝桠滞留率",
        dataIndex: "twigRetentionRate",
        key: "twigRetentionRate",
        align: "center",
        width: "120px",
        render: (text, record, index) => <span>{text || 0}</span>,
      },
      {
        title: "验收次数",
        dataIndex: "frequency",
        key: "frequency",
        align: "center",
        width: "100px",
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          close,
        }) => {
          return (
            <div style={{ padding: 8 }}>
              <Input
                placeholder="验收次数"
                allowClear
                onChange={this.onFrequencyChanged}
                style={{
                  width: "100px",
                  marginRight: "12px",
                }}
              />
            </div>
          );
        },
        filterIcon: (filtered: boolean) => (
          <SearchOutlined
            rev={undefined}
            style={{ color: filtered ? "#1890ff" : undefined }}
          />
        ),
      },
      {
        title: "验收结果",
        dataIndex: "result",
        key: "result",
        width: "105px",
        render: (text, record, index) => (
          <span
            style={{ color: acceptanceResultColor[record.result.toString()] }}
          >
            {acceptanceResult[record.result.toString()]}
          </span>
        ),
      },
      {
        title: "上传人",
        dataIndex: "username",
        key: "username",
        align: "center",
        width: "120px",
        render: (text, record) => (
          <Tooltip placement="top" title={text}>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "120px",
              }}
            >
              {text || "暂无"}
            </div>
          </Tooltip>
        ),
      },
      {
        title: "时间",
        dataIndex: "time",
        key: "time",
        width: "180px",
        render: (text, record) => <span>{text || "暂无"}</span>,
      },
      {
        title: "现场照片",
        dataIndex: "picUrl",
        key: "picUrl",
        width: "100px",
        render: (text, record) => (
          <Popover
            content={
              <div className={"pic-popover"}>
                <div onClick={this.left} className={"left-btn btn"}>
                  <LeftOutlined rev={undefined} />
                </div>
                <div className={"pic-content"}>
                  {imgList.length !== 0 ? (
                    <div
                      ref={this.ref}
                      className={
                        imgList.length === 1 ? "single-center" : "center"
                      }
                    >
                      <Image.PreviewGroup>
                        {imgList.map((item) => {
                          return (
                            <div className={"pic-box"}>
                              <Image width={80} height={80} src={item.url} />
                            </div>
                          );
                        })}
                      </Image.PreviewGroup>
                    </div>
                  ) : (
                    <div className={"none-text"}>暂无现场照片</div>
                  )}
                </div>
                <div onClick={this.right} className={"right-btn btn"}>
                  <RightOutlined rev={undefined} />
                </div>
              </div>
            }
            title="现场照片"
            trigger="click"
          >
            <Button type="link" onClick={() => this.viewImage(record.id)}>
              查看
            </Button>
          </Popover>
        ),
      },
      {
        title: "情况描述",
        dataIndex: "situation",
        key: "situation",
        align: "center",
        width: "200px",
        render: (text, record) => (
          <Tooltip placement="top" title={text}>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: "200px",
              }}
            >
              {text}
            </div>
          </Tooltip>
        ),
      },
    ];
    this.role == "ROLE_0" && columns.push(operation);
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const pagination = {
      total,
      current: page,
      pageSize: size,
      showQuickJumper: true,
      responsive: false,
      showSizeChanger: true,
    };
    // 表格展开项
    const expandedRowRender = (record) => {
      const { historyList } = this.state;
      const columns = [
        {
          title: "乡镇/街道",
          dataIndex: "townName",
          key: "townName",
        },
        {
          title: "行政村",
          dataIndex: "villageName",
          key: "villageName",
        },
        {
          title: "林班",
          dataIndex: "forestName",
          key: "forestName",
        },
        {
          title: "小班号",
          dataIndex: "smallClass",
          key: "smallClass",
        },
        {
          title: "验收次数",
          dataIndex: "frequency",
          key: "frequency",
        },
        {
          title: "验收结果",
          dataIndex: "result",
          key: "result",
          render: (text, record, index) => (
            <span
              style={{ color: acceptanceResultColor[record.result.toString()] }}
            >
              {acceptanceResult[record.result.toString()]}
            </span>
          ),
        },
        {
          title: "上传时间",
          dataIndex: "time",
          key: "time",
        },
        {
          title: "验收人",
          dataIndex: "username",
          key: "username",
        },
        {
          title: "现场照片",
          dataIndex: "picUrl",
          key: "picUrl",
          render: (text, record) => (
            <Popover
              content={
                <div className={"pic-popover"}>
                  <div onClick={this.left} className={"left-btn btn"}>
                    <LeftOutlined rev={undefined} />
                  </div>
                  <div className={"pic-content"}>
                    {imgList.length !== 0 ? (
                      <div ref={this.ref} className={"center"}>
                        <Image.PreviewGroup>
                          {imgList.map((item) => {
                            return (
                              <div className={"pic-box"}>
                                <Image width={80} height={80} src={item.url} />
                              </div>
                            );
                          })}
                        </Image.PreviewGroup>
                      </div>
                    ) : (
                      <div className={"none-text"}>暂无现场照片</div>
                    )}
                  </div>
                  <div onClick={this.right} className={"right-btn btn"}>
                    <RightOutlined rev={undefined} />
                  </div>
                </div>
              }
              title="现场照片"
              trigger="click"
            >
              <Button type="link" onClick={() => this.viewImage(record.id)}>
                查看
              </Button>
            </Popover>
          ),
        },
        {
          title: "备注",
          dataIndex: "situation",
          key: "situation",
        },
      ];
      return (
        <Table columns={columns} dataSource={historyList} pagination={false} />
      );
    };
    // 获取历史数据
    const getHistoryList = (value, record) => {
      if (value) {
        Acceptance.getHistoryData({
          classId: record.classId,
          isInspection: 1,
          year: this.state.year,
        }).then((res) => {
          if (res.data.code === 200) {
            this.setState({
              historyList: res.data.data,
            });
          }
        });
      } else {
        this.setState({
          historyList: [],
        });
      }
    };
    return (
      <>
        {intelligentVisible ? (
          <Map
            intelligentVisible={intelligentVisible}
            changeIntelligentVisible={this.changeIntelligentVisible}
            countyCode={countyCode}
            townCode={townCode}
            cityCode={cityCode}
            isReading={isReading}
            countyList={countyList}
            townList={townList}
            isInspection={1}
          />
        ) : (
          <div className={"acceptance-box"}>
            <div className={"acceptance-header"}>
              <div>
                <Button
                  type="primary"
                  style={{ marginRight: "5px" }}
                  onClick={() => {
                    countyCode
                      ? this.changeIntelligentVisible(intelligentVisible)
                      : message.info("请先选择区县");
                  }}
                >
                  智能上报
                </Button>
                {/* <Button
									type="primary"
									style={{ marginRight: '5px' }}
									onClick={this.changeReportVisible}
									disabled={isReading}
								>
									手动上报
								</Button> */}
                {/* <Popconfirm
									title="确定同步至验收吗？"
									// description="Are you sure to delete this task?"
									onConfirm={this.changeSyncCheckData}
								// onCancel={cancel}
								>
									<Button
										type="primary"
										style={{ marginRight: '5px' }}
										// onClick={this.changeSyncCheckData}
										disabled={isReading}
									>
										同步至验收
									</Button>
								</Popconfirm> */}
                <Search
                  placeholder="请输入信息查询"
                  enterButton="搜索"
                  onSearch={this.handleSearch}
                  style={{
                    width: "240px",
                    marginTop: "5px",
                  }}
                />
              </div>
              <div>
                <Select
                  onChange={this.onCountyChanged}
                  value={countyCode}
                  style={{ width: 90, marginRight: "5px" }}
                  placeholder="区县"
                  allowClear
                  disabled={isReading}
                >
                  {countyList.map((item, index) => {
                    return (
                      <Option value={item.code} key={index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
                <Select
                  onChange={this.onTownChanged}
                  style={{ width: 100, marginRight: "5px" }}
                  value={townCode}
                  disabled={areaLevel === 3 && true}
                  placeholder="乡镇/街道"
                  allowClear
                >
                  {townList.map((item, index) => {
                    return (
                      <Option value={item.code} key={index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
                <Select
                  onChange={this.onVillageChanged}
                  style={{ width: 100, marginRight: "5px" }}
                  placeholder="行政村"
                  allowClear
                >
                  {villageList.map((item, index) => {
                    return (
                      <Option value={item.code} key={index}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
                <Select
                  onChange={this.onResultChanged}
                  style={{ width: 100, marginRight: "5px" }}
                  placeholder="验收结果"
                  allowClear
                >
                  <Option value={1} key={"1"}>
                    验收通过
                  </Option>
                  <Option value={0} key={"0"}>
                    验收不通过
                  </Option>
                  <Option value={-1} key={"-1"}>
                    待审核
                  </Option>
                </Select>

                <RangePicker
                  onChange={this.rangeChange}
                  inputReadOnly={true}
                  style={{ marginRight: "5px", width: 240 }}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    countyCode
                      ? this.changeExportAreaVisible(exportAreaVisible)
                      : message.info("请先选择区县");
                  }}
                  style={{ marginRight: "5px" }}
                  disabled={isReading}
                >
                  导出区域
                </Button>
                <Popover
                  placement="bottom"
                  content={
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Button
                        type="primary"
                        onClick={() => {
                          countyCode
                            ? this.todayReportVisible(todayReportVisible)
                            : message.info("请先选择区县");
                        }}
                      >
                        {" "}
                        当日报告
                      </Button>
                      <Button
                        style={{ marginTop: "5px" }}
                        type="primary"
                        onClick={() =>
                          countyCode
                            ? this.exportReportVisible(exportReportVisible)
                            : message.info("请先选择区县")
                        }
                      >
                        {" "}
                        导出报告
                      </Button>
                      <Button
                        style={{ marginTop: "5px" }}
                        type="primary"
                        onClick={() =>
                          countyCode
                            ? this.changeRectVisible(rectVisible)
                            : message.info("请先选择区县")
                        }
                      >
                        {" "}
                        整改报告
                      </Button>
                    </div>
                  }
                >
                  <Button type="primary" style={{ marginRight: "5px" }}>
                    {" "}
                    导出报告
                  </Button>
                </Popover>

                {this.role == "ROLE_0" ? (
                  <Button
                    type="primary"
                    onClick={() => this.deleteBatch()}
                    style={{ marginTop: "5px" }}
                    disabled={isReading}
                  >
                    批量删除
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="acceptance-table">
              <Table
                dataSource={data}
                columns={columns}
                expandable={{ expandedRowRender, onExpand: getHistoryList }}
                rowKey={(record) => record.id}
                rowSelection={rowSelection}
                loading={loading}
                onChange={(pagination) => this.changePagination(pagination)}
                pagination={pagination}
                scroll={{ y: "calc(100vh - 23.5rem)" }}
              />
            </div>
            {reportVisible && (
              <Add
                visible={reportVisible}
                changeVisible={this.changeReportVisible}
                cityCode={cityCode}
                getData={this.getData}
                isInspection={1}
              />
            )}
            {/* 修改数据 */}
            {updateVisible && (
              <Update
                visible={updateVisible}
                record={record}
                changeVisible={() => {
                  this.changeUpdateVisible(null);
                }}
                year={this.state.year}
                getData={this.getData}
                isInspection={1}
                countyCode={countyCode}
              />
            )}
            {/*  当日报告 */}
            {todayReportVisible && (
              <TodayReport
                visible={todayReportVisible}
                countyCode={countyCode}
                isReading={isReading}
                townCode={townCode}
                areaLevel={areaLevel}
                countyList={countyList}
                handleCancel={this.todayReportVisible}
                isInspection={1}
              ></TodayReport>
            )}
            {/* 导出报告 */}
            {exportReportVisible && (
              <ExportReport
                visible={exportReportVisible}
                countyCode={countyCode}
                townCode={townCode}
                isReading={isReading}
                areaLevel={areaLevel}
                countyList={countyList}
                handleCancel={this.exportReportVisible}
                isInspection={1}
              ></ExportReport>
            )}
            {/* 区域导出 */}
            {exportAreaVisible && (
              <ExportArea
                visible={exportAreaVisible}
                townCode={townCode}
                isReading={isReading}
                areaLevel={areaLevel}
                countyCode={countyCode}
                handleCancel={this.changeExportAreaVisible}
                isInspection={1}
              />
            )}
            {rectVisible && (
              <RectificaReport
                visible={rectVisible}
                countyCode={countyCode}
                townCode={townCode}
                isReading={isReading}
                areaLevel={areaLevel}
                countyList={countyList}
                handleCancel={this.changeRectVisible}
                isInspection={1}
              ></RectificaReport>
            )}
          </div>
        )}
      </>
    );
  }
}

export default A;
