import React, { Component } from "react";
import { Link } from "react-router-dom";
import { analysisLayer } from "./data";
import Daohang from "../../assets/icons/icon/svg/导航图标.svg";
import { message } from "antd";
// const list2 = [
//   { btnName: '排查进度', isClick: false },
//   { btnName: '海拔分析', isClick: false },
//   { btnName: '....', isClick: false },
// ];
interface Props {
  username: any;
  map: any;
  lay: any;
  code: any;
  areaName: any;
  changeSelect: any;
  changeLeftData: any;
  changeAreaName: any;
  changeSchedule: any;
  AcceptState: boolean;
}

interface State {
  username: any;
  isAnalyse: any;
  schedule: any;
  isuploadData: boolean;
  downLoadType: any;
  countyCode: any;
  position: any;
  tokenAccount: any;
  roleCode: any;
}
// const T = window['T'];
// const layout = {
//   labelCol: { span: 6 },
//   wrapperCol: { span: 12 },
// };

let allCountyCode = [
  { name: "丽水市", code: "331100", position: [119.63104, 28.09652] },
  { name: "金华市", code: "330784", position: [119.917643, 27.556578] },
  { name: "温州市", code: "330300", position: [120.672111, 28.000575] },
  { name: "衢州市", code: "330800", position: [118.50507, 28.90302] },
];
export default class Right extends Component<any, State> {
  role = localStorage.getItem("role");

  constructor(props: Props) {
    super(props);
    this.state = {
      username: this.props.username,
      isAnalyse: false,
      schedule: false,
      isuploadData: false,
      downLoadType: 0,
      countyCode: null,
      position: [],
      tokenAccount: JSON.parse(localStorage.getItem("token")).account,
      roleCode: JSON.parse(localStorage.getItem("token")).areaCode,
    };
  }
  componentDidMount() {
    const { lay, areaName, AcceptState } = this.props;
    // console.log(AcceptState);

    lay["海拔分析"] = analysisLayer;
    allCountyCode.forEach((item) => {
      if (areaName === item.name) {
        this.setState({
          countyCode: item.code,
          position: item.position,
        });
      }
    });
  }

  /**
   * 海拔分析
   */
  getanalyse = () => {
    // const { lay, map, theName } = this.props;
    const { isAnalyse } = this.state;

    // const elevation = document.getElementById('elevation-tip');
    // if (theName === '泰顺县') {
    //   lay['海拔分析'].setParams({
    //     layers: 'pine-epidemic-census:taishun_elevation',
    //   });
    // } else {
    //   lay['海拔分析'].setParams({
    //     layers: 'pine-epidemic-census:ls_elevation',
    //   });
    // }
    // if (!isAnalyse) {
    //   map.addLayer(lay['海拔分析']);
    //   elevation.style.opacity = '1';
    // } else {
    //   map.removeLayer(lay['海拔分析']);
    //   elevation.style.opacity = '0';
    // }
    this.setState(
      {
        isAnalyse: !isAnalyse,
      },
      () => {
        if (!isAnalyse) {
          localStorage.setItem("acceptState", "true");
        } else {
          localStorage.removeItem("acceptState");
        }
      }
    );
  };
  /**
   * 排查进度
   * @returns
   */
  schedule = () => {
    const { changeSchedule, changeCheckLayer } = this.props;
    const { schedule } = this.state;
    this.setState({
      schedule: !schedule,
    });
    changeCheckLayer(!schedule);
    changeSchedule(!schedule);
  };
  render() {
    const {
      isAnalyse,
      schedule,
      username,
      countyCode,
      position,
      tokenAccount,
      roleCode,
    } = this.state;
    const {
      mode,
      areaName,
      propsareaCode,
      level,
      AcceptState,
      AcceptStateOnclick,
    } = this.props;

    return (
      <>
        <div className="rightBtns">
          <div className="rightBtnsList">
            <div
              className={schedule ? "active-rbtn" : "rbtn"}
              onClick={this.schedule}
            >
              <div>
                <p>排查进度</p>
              </div>
            </div>
            <div
              className={!AcceptState ? "active-rbtn" : "rbtn"}
              onClick={AcceptStateOnclick}
            >
              <div>
                <p>验收统计</p>
              </div>
            </div>
            {tokenAccount === "ls_qt_visitor" ||
            (this.role === "ROLE_2" &&
              roleCode !== "331102" &&
              roleCode !== "331121" &&
              roleCode !== "331125" &&
              !roleCode.includes("331127")) ? (
              <a onClick={() => message.info("您暂时没有权限！")}>
                <div className={"rbtn"}>
                  <div>
                    <p style={{ color: "white" }}>管理后台</p>
                  </div>
                </div>
              </a>
            ) : (
              <Link
                to={{
                  pathname:
                    "/platform/" +
                    // (this.role === 'ROLE_3' ? 'logrecord' :'plagueWood'),
                    (this.role === "ROLE_2" ? "acceptance" : "plagueWood"),
                  state: {
                    name: areaName,
                    code: countyCode,
                    mode: mode,
                    level: level,
                    propsareaCode: propsareaCode,
                    username: username,
                    position: position,
                  },
                }}
              >
                <div className={"rbtn"}>
                  <div>
                    <p style={{ color: "white" }}>管理后台</p>
                  </div>
                </div>
              </Link>
            )}
          </div>
          <div>
            <div>
              <p>
                <Link
                  to={{
                    pathname: "/navigation",
                    state: {
                      username: username,
                    },
                  }}
                >
                  <div className="toNavigation">
                    <div>导航页</div>
                    <img
                      src={Daohang}
                      style={{
                        marginLeft: "8px",
                      }}
                      alt=""
                    />
                  </div>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}
