import { acceptance_axios as axios } from "../../utils/axios";
import { file_axios } from "../../utils/axios";

// 通用模块接口
export default class Common {
  // 导出小班面积
  static exportClassArea({ params }) {
    const { data } = params;
    return file_axios.post("/common/export_class_area", data);
  }

  // 获取市级列表
  static getCityList() {
    return axios.get("/common/list_city");
  }

  // 获取区县列表
  static getCountyList({ cityCode }) {
    return axios.get("/common/list_county", { params: { cityCode } });
  }
  static examineAcceptance(ids) {
    return axios.post("/acceptance/batch_examine_acceptance", ids);
  }

  // 获取乡镇列表
  static getTownList({ countyCode }) {
    return axios.get("/common/list_town", {
      params: { countyCode, year: sessionStorage.getItem("year") },
    });
  }

  // 获取行政村列表
  static getVillageList(params) {
    return axios.get("/common/list_village", { params: params });
  }

  // 获取林班列表
  static getForestList(params) {
    return axios.get("/common/list_forest", {
      params,
    });
  }

  //全局搜索小班
  static getAllSmallClass(params) {
    return axios.get("/common/select_small_class_globe", {
      params,
    });
  }

  // 获取小班列表
  static getClassList({ countyCode, townCode, villageCode, forestCode }) {
    return axios.get("/common/list_class", {
      params: { countyCode, townCode, villageCode, forestCode },
    });
  }

  // 通过坐标获取小班状态
  static getClassLonlat({ x, y, year, countyCode }) {
    return axios.get("/common/get_class_status", {
      params: { x, y, year, countyCode },
    });
  }

  //获取小班质心位置
  static getClassCenter({ classId }) {
    return axios.get(`/common/centroid_by_id/${classId}`);
  }

  //全局小班检索-关键词
  static getGlobalClass({ key, page, size, countyCode, year }) {
    return axios.get("/common/list_global_class", {
      params: { key, page, size, countyCode, year },
    });
  }

  //根据坐标点获取小班验收信息
  static getAcceptInfo({ x, y, year, isInspection, countyCode }) {
    return axios.get("/common/get_accept_info_by_point", {
      params: { x, y, year, isInspection, countyCode },
    });
  }

  //获取市级下所有区域列表
  static getListAllRegion(params) {
    return axios.get("/common/list_all_region", { params });
  }

  // 获取唯一标识的行政村
  static getListVillageUnique(params) {
    return axios.get("/common/list_village_unique", { params });
  }

  // 获取树状结构的林班小班
  static getForestListTree(params) {
    return axios.get("/common/list_forest_class_tree", { params });
  }
}
