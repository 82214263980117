import React from 'react';
import './index.scss';
interface NumCardType {
  total: number;
  title?: string;
  style?: React.CSSProperties;
  NumCardStyle?: React.CSSProperties;
  unit?: string;
  color?: string;
  bold?: boolean;
  position?: boolean;
}
export default function NumCard(props: NumCardType) {
  const { position, bold, title, unit, total, color, style, NumCardStyle } =
    props;
  return (
    <div
      className="NumCard"
      style={{ flexDirection: position ? 'row' : 'column', ...NumCardStyle }}
    >
      <p style={{ ...style }}>{title}</p>
      <span style={{ fontWeight: bold ? 'bold' : 'normal', color: color }}>
        {total}
        <span>{unit ? unit : '个'}</span>
      </span>
    </div>
  );
}
