import { Button, message, Popconfirm, Select, Table } from 'antd';
import React, { Component } from 'react';
import ToolTs from '../services/tool';
import ViewMap from './map';
import './index.scss';
const { Option } = Select;
class OrthoList extends Component<any, any> {
  state = {
    page: 1,
    size: 10,
    data: [],
    loading: true,
    total: 0,
    selectedRowKeys: [],
    isLookView: false, //是否预览地图
    record: null,
    year: sessionStorage.getItem('year'),
    yearList: JSON.parse(sessionStorage.getItem('yearList')),
  };

  componentDidMount(): void {
    this.getData();
  }

  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {
    if (prevProps.locationState.code !== this.props.locationState.code) {
      this.getData();
    }
    if (prevProps.year !== this.props.year) {
      this.setState({
        year: this.props.year
      }, () => {
        this.getData();
      })
    }
  }

  //获取数据
  getData = () => {
    const { page, size, year } = this.state;
    const { propsareaCode } = this.props.locationState;
    const params = {
      page,
      size,
      year,
      areaCode: propsareaCode,
    };
    ToolTs.getOrthoquery(params, (res) => {
      if (res.code === 200) {
        this.setState({
          data: res.data.list,
          loading: false,
          total: res.data.total,
        });
      }
    });
  };

  //切换
  changePagination = (pagination) => {
    this.setState(
      {
        page: pagination.current,
        size: pagination.pageSize,
        loading: true,
      },
      this.getData
    );
  };

  //删除
  deleteData = (record) => {
    ToolTs.deleteOrtho(record.id, (res) => {
      if (res.code === 200) {
        message.success('删除成功');
        this.getData();
      } else {
        message.error('删除失败');
      }
    });
  };

  //修改
  changeUpdateVisible = (record) => { };

  //预览
  lookView = (record) => {
    const { isLookView } = this.state;
    this.changeIsLookView(isLookView);
    this.setState({
      record,
    });
  };

  //更改年份
  changeYear = (year) => {
    this.setState(
      {
        page: 1,
        size: 10,
        year,
      },
      this.getData
    );
  };

  changeIsLookView = (isLookView) => {
    this.setState({
      isLookView: !isLookView,
    });
  };

  //选择数据
  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRowKeys,
    });
  };

  render() {
    const {
      page,
      size,
      data,
      loading,
      total,
      selectedRowKeys,
      isLookView,
      record,
      yearList,
    } = this.state;
    const pagination = {
      total,
      current: page,
      pageSize: size,
      showQuickJumper: true,
      responsive: false,
      showSizeChanger: true,
    };
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const columns = [
      {
        title: '序号',
        width: 80,
        dataIndex: 'id',
        key: 'id',
        render: (text, record, index) => (page - 1) * 10 + (index + 1),
      },
      {
        title: '名称',
        width: 150,
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
        render: (text, record) => <span>{text || '暂无'}</span>,
      },
      {
        title: '数据容量（G）',
        width: 150,
        dataIndex: 'capacity',
        key: 'capacity',
        render: (text, record) => <span>{text || '暂无'}</span>,
      },
      {
        title: '正摄地址',
        dataIndex: 'baseJson',
        key: 'baseJson',
        ellipsis: true,
      },
      {
        title: '年度',
        dataIndex: 'year',
        align: 'center' as 'center',
        width: 150,
        key: 'year',
        render: (text, record) => <span>{text || '暂无'}</span>,
      },
      {
        title: '面积（km²）',
        dataIndex: 'area',
        align: 'center' as 'center',
        width: 150,
        key: 'area',
        render: (text, record) => <span>{text || '暂无'}</span>,
      },
      {
        title: '上传人',
        width: 120,
        align: 'center' as 'center',
        dataIndex: 'createBy',
        key: 'createBy',
        ellipsis: true,
        render: (text, record) => <span>{text || '暂无'}</span>,
      },
      {
        title: '时间',
        align: 'center' as 'center',
        dataIndex: 'acquisitionDate',
        width: 180,
        key: 'acquisitionDate',
        render: (text, record) => <span>{text || '暂无'}</span>,
      },
      {
        title: '操作',
        width: 240,
        className: 'handle',
        dataIndex: 'handle',
        key: 'handle',
        render: (text, record) => (
          <>
            {/* <Button type="link" onClick={() => { this.changeUpdateVisible(record) }}>修改</Button> */}
            <Button
              type="link"
              onClick={() => {
                this.lookView(record);
              }}
            >
              预览
            </Button>
            <Popconfirm
              title="确定要删除当前记录吗?"
              onConfirm={() => {
                this.deleteData(record);
              }}
              okText="确定"
              cancelText="取消"
            >
              <Button type="link">删除</Button>
            </Popconfirm>
          </>
        ),
      },
    ];

    return (
      <>
        {isLookView ? (
          <ViewMap
            record={record}
            isLookView={isLookView}
            changeIsLookView={this.changeIsLookView}
          />
        ) : (
          <div className="OrthoData">
            <div className="OrtHeader">
              <Select
                className="yearSelect"
                placeholder="年度"
                onChange={this.changeYear}
                style={{ width: 176 }}
                allowClear
                defaultValue={sessionStorage.getItem('year')}
              >
                {yearList.map((item) => (
                  <Option value={item.key} key={item.key}>
                    {item.value}
                  </Option>
                ))}
              </Select>
            </div>
            <Table
              dataSource={data}
              rowKey={(record) => record.id}
              columns={columns}
              loading={loading}
              rowSelection={rowSelection}
              onChange={(pagination) => this.changePagination(pagination)}
              pagination={pagination}
              scroll={{ y: 'calc(100vh - 23.5rem)' }}
            />
          </div>
        )}
      </>
    );
  }
}

export default OrthoList;
