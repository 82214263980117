import React, { Component } from 'react';
import { AreaDataBox } from './common';

interface Props {
	pageRightData: any;
}

interface State {
}

class Right extends Component<Props, State> {

	render() {
		const { pageRightData } = this.props;
		return (
			<div className={'navigation-right'}>
				<div className="right-scroll">
					{pageRightData &&
						pageRightData.map((item: any, index: number) => {
							return (
								<AreaDataBox
									data={{
										...item,
										index: index > 8 ? index + 1 : '0' + (index + 1).toString(),
									}}
									key={item.key}
								/>
							);
						})}
					{pageRightData &&
						pageRightData.map((item: any, index: number) => {
							return (
								<AreaDataBox
									data={{
										...item,
										index: index > 8 ? index + 1 : '0' + (index + 1).toString(),
									}}
									key={item.key}
								/>
							);
						})}
				</div>
			</div>
		);
	}
}

export default Right;
