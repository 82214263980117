import React, { Component } from "react";
import Lottie from "react-lottie";
import middle from "../../assets/icons/middle.json";
import { Label } from "../../component/tools/Label";
import Request from "../../services/Manger";
import { LoadingOutlined } from "@ant-design/icons";
import NumCard from "../../component/Card/NumCard";
import "./left.scss";
import { Spin, message } from "antd";

const antIcon = (
  <LoadingOutlined rev={undefined} style={{ fontSize: 24 }} spin />
);
export class LeftClass extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      dataList: [],
      selectName: "",
      showData: {},
      showList: [], //地区列表的位置信息，
      contryList: [],
      townList: [],
      isWoodOpen: false, //疫木点位是否打开
      lableList: [],
      secondCode: null,
      loading: false,
      theName: this.props.theName,
    };
  }
  componentDidMount() {
    const { map, areaName, fatherCode } = this.props;
    const { showData } = this.state;
    const role = localStorage.getItem("role");
    const tokenAccount = JSON.parse(localStorage.getItem("token"));
    if (tokenAccount.account === "ls_jn_tourist") {
      this.setState(
        {
          theName: "景宁县",
        },
        () => {
          this.areaList("景宁县", 331127, 2);
        }
      );
    } else if (role === "ROLE_2") {
      this.setState(
        {
          theName: tokenAccount.areaName,
        },
        () => {
          this.areaList(
            tokenAccount.areaName,
            tokenAccount.areaCode,
            tokenAccount.areaLevel
          );
        }
      );
    } else {
      this.areaList(areaName, fatherCode, 1);
    }
    this.getCountryList();
    map.addMapEvent("click", this.enterMap);
  }
  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {
    const { code, theName, level, fatherCode, map, year } = this.props;
    const { showData, secondCode } = this.state;

    const role = localStorage.getItem("role");
    const tokenAccount = JSON.parse(localStorage.getItem("token"));

    if (prevProps.year !== year) {
      this.areaList(theName, code, level);
    }
    //游客莲都区权限
    if (
      prevState.showData !== showData &&
      !prevState.showData?.list &&
      role == "ROLE_2" &&
      tokenAccount.areaCode === "331102"
    ) {
      console.log(showData);
      this.chooseArea({}, "0", showData.list[0], "331102");
    }

    //游客莲都区权限
    if (
      prevState.showData !== showData &&
      !prevState.showData?.list &&
      role == "ROLE_2" &&
      tokenAccount.areaCode === "331121"
    ) {
      console.log(showData);
      this.chooseArea({}, "0", showData.list[0], "331121");
    }

    if (prevProps.code !== code && prevState.showData) {
      let flag = false;
      showData.list.forEach((element) => {
        if (element.data.areaCode === code) {
          element.isShow = true;
          flag = true;
        } else {
          element.isShow = false;
        }
      });
    }
    //权限为游客时候改变左边的数据调用
    if (
      prevProps.code !== code &&
      prevState.showData !== showData &&
      localStorage.getItem("role") === "ROLE_2"
    ) {
      let flag = false;
      showData.list.forEach((element) => {
        if (element.data.areaCode === code) {
          element.isShow = true;
          flag = true;
        } else {
          element.isShow = false;
        }
      });
      if (level === 3) {
        this.areaList(
          prevProps.theName,
          secondCode ? secondCode : fatherCode,
          2
        );
        map.removeMapEvent("click", this.enterMap);
      } else {
        this.areaList(theName, code, level);
        map.addMapEvent("click", this.enterMap);
        this.setState({
          secondCode: code,
        });
      }
    }

    if (prevProps.code !== code && localStorage.getItem("role") !== "ROLE_2") {
      let flag = false;
      showData.list.forEach((element) => {
        if (element.data.areaCode === code) {
          element.isShow = true;
          flag = true;
        } else {
          element.isShow = false;
        }
      });
      if (level === 3) {
        this.areaList(
          prevProps.theName,
          secondCode ? secondCode : fatherCode,
          2
        );
        map.removeMapEvent("click", this.enterMap);
      } else {
        this.areaList(theName, code, level);
        map.addMapEvent("click", this.enterMap);
        this.setState({
          secondCode: code,
        });
      }
    }
  }

  /**
   * 点击地图进行跳转
   * @param e
   */
  enterMap = (e) => {
    // console.log(e)
    const { map, areaChange, changeFatherCode } = this.props;
    const { showData } = this.state;
    const feature = map.map.forEachFeatureAtPixel(e.pixel, (feature: any) => {
      return feature;
    });
    let theArea = feature && feature.id_;
    theArea &&
      showData.list.forEach((item) => {
        if (
          item.data.name === theArea ||
          item.data.name === theArea.replace("1", "")
        ) {
          // console.log(item.data)
          const areaLevel = item.data.areaLevel === 2 ? 1 : 2;
          Request.TownList(
            {
              areaCode: item.data.areaCode,
              areaLevel: areaLevel,
              year: sessionStorage.getItem("year"),
            },
            (res) => {
              if (res.code === 200) {
                // console.log(res)
                res.data.forEach((item1) => {
                  if (item1.name === item.data.name) {
                    // console.log(item1)
                    areaChange(item1.areaCode, item1.areaLevel, item1.name);
                    map.mapMove(
                      [item1.lon, item1.lat],
                      item1.areaLevel === 1
                        ? 9
                        : item1.areaLevel === 2
                        ? 11
                        : 13
                    ); //地图移动视角
                  }
                });
              }
            }
          );
        }
      });
    changeFatherCode(showData.fatherCode);
  };
  /**
   * 获取地区列表
   * @returns
   */
  areaList = (name, code, level) => {
    const { theName, areaName, year } = this.props;
    const codeList = ["331100", "330800", "330300", "330700"];
    this.setState({
      loading: true,
    });
    // console.log(code, codeList.indexOf(code) === -1 ? 2 : level, year)
    Request.DataList(
      {
        areaCode: code,
        areaLevel: codeList.indexOf(code) === -1 ? 2 : level,
        year,
      },
      (res) => {
        if (res.code === 200) {
          let list = [];
          // console.log(res)
          res.data.forEach((item) => {
            if (item.name === theName || item.name === areaName) {
              list.push({ isShow: true, data: item });
            } else {
              list.push({ isShow: false, data: item });
            }
          });

          this.setState({
            dataList: res.data,
            showData: { list: list, fatherCode: code },
            loading: false,
          });
        }
      }
    );
  };
  //获取市及以下数据
  getCountryList = () => {
    const { fatherCode, areaName, map } = this.props;

    // console.log(fatherCode, areaName, map)
    const lonlat = map.getCenter();
    Request.TownList(
      {
        areaCode: fatherCode,
        areaLevel: 1,
        year: sessionStorage.getItem("year"),
      },
      (res) => {
        let contryList;
        if (res.code === 200) {
          contryList = res.data;
          contryList.push({
            areaCode: fatherCode,
            areaLevel: 2,
            name: areaName,
            lat: lonlat[1],
            lon: lonlat[0],
          });
          this.setState({
            contryList,
          });
        }
      }
    );
  };
  /**
   * 地区列表选择
   * @param e
   * @param index
   * @param element
   */
  chooseArea = (e, index, element, fatherCode) => {
    console.log(e, index, element, fatherCode);
    const { map, areaChange, changeFatherCode } = this.props;
    const { showData, contryList } = this.state;
    Request.TownList(
      {
        areaCode: showData.list[0].data.areaCode,
        areaLevel: showData.list[0].data.areaLevel,
        year: sessionStorage.getItem("year"),
      },
      (res) => {
        if (res.code === 200) {
          console.log(res);
          res.data.forEach((item) => {
            if (element.data.areaCode === item.areaCode) {
              item.areaLevel !== 3 && changeFatherCode(item.areaCode);
              if (item.lon && item.lat) {
                map.mapMove(
                  [item.lon, item.lat],
                  item.areaLevel === 1 ? 9 : item.areaLevel === 2 ? 11 : 13
                ); //地图移动视角
                areaChange(
                  element.data.areaCode,
                  element.data.areaLevel,
                  element.data.name
                );
              } else {
                Request.TownList(
                  {
                    areaCode: 331100,
                    areaLevel: 1,
                    year: sessionStorage.getItem("year"),
                  },
                  (res) => {
                    //没有小班区域是返回上一级
                    res.data.forEach((item) => {
                      if (fatherCode === item.areaCode) {
                        message.info("暂无该小班区域信息");
                        map.mapMove([item.lon, item.lat], 11); //地图移动视角
                        areaChange(fatherCode, item.areaLevel, item.name);
                      }
                    });
                  }
                );
              }
            } else {
              areaChange(
                element.data.areaCode,
                element.data.areaLevel,
                element.data.name
              );
            }
          });
        }
      }
    );
    contryList.forEach((item) => {
      if (element.data.areaCode === item.areaCode) {
        map.mapMove(
          [item.lon, item.lat],
          item.areaLevel === 1 ? 9 : item.areaLevel === 2 ? 11 : 13
        ); //地图移动视角
      }
    });
    showData.list.forEach((item1) => {
      item1.isShow = false;
    });
    showData.list[index].isShow = true; //高亮显示
  };
  // 获取单位
  getUnit = (total, unit) => {
    if (total >= 10000) return `万${unit}`;
    else return unit;
  };
  // 获取上万得数据
  getUnitData = (total) => {
    if (total >= 10000) return (total / 10000).toFixed(2);
    else return total;
  };
  render() {
    const { isOperat } = this.props;
    const { showData, loading } = this.state;
    const surveyprops = {
      width: 357,
      title: "发生面积：",
      subtitle: "发生株数：",
    };

    return (
      <div
        className={`h-left animate__animated ${
          isOperat ? "animate__fadeOutLeft" : "animate__fadeInLeft"
        }`}
      >
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: middle,
          }}
          width={100}
          height={"100%"}
          style={{ margin: "0" }}
        />
        <Spin spinning={loading} indicator={antIcon}>
          <div className="left-list">
            <div
              className="class-left-list"
              onTouchMove={(event) => event.stopPropagation()}
              style={{
                width: `${surveyprops.width + 15}px`,
              }}
            >
              {showData.list &&
                showData.list.map((item, index) => {
                  return (
                    <div key={index}>
                      {item.isShow ? (
                        <>
                          <Label
                            title={item.data.name}
                            describe={"EPIDEMIC INFECTION STATISTICS"}
                            data={item}
                            width={surveyprops.width + 7}
                          />
                          <div
                            className={"list-head"}
                            style={{
                              width: `${surveyprops.width}px`,
                            }}
                            onClick={(e) =>
                              this.chooseArea(
                                e,
                                index,
                                item,
                                showData.fatherCode
                              )
                            }
                          >
                            <NumCard
                              title={surveyprops.title}
                              unit={this.getUnit(item.data.area, "亩")}
                              total={this.getUnitData(item.data.area)}
                              position={true}
                              bold={true}
                              color="#02D281"
                              style={{ opacity: "0.8" }}
                            />

                            <NumCard
                              title={surveyprops.subtitle}
                              unit={this.getUnit(item.data.treeNumber, "株")}
                              total={this.getUnitData(item.data.treeNumber)}
                              position={true}
                              bold={true}
                              color="#00BAFF"
                              style={{ opacity: "0.8" }}
                            />
                          </div>
                        </>
                      ) : (
                        <div
                          className={"list-item"}
                          style={{
                            width: `${surveyprops.width - 57}px`,
                          }}
                          onClick={(e) =>
                            this.chooseArea(e, index, item, showData.fatherCode)
                          }
                        >
                          <NumCard
                            title={`${item.data.name}：`}
                            unit={this.getUnit(item.data.area, "亩")}
                            total={this.getUnitData(item.data.area)}
                            position={true}
                            color="#02D281"
                            style={{ fontSize: "16px" }}
                          />
                          <NumCard
                            unit={this.getUnit(item.data.treeNumber, "株")}
                            total={this.getUnitData(item.data.treeNumber)}
                            position={true}
                            color="#00BAFF"
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export class AcceptData extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      dataList: [],
      selectName: "",
      showData: {},
      showList: [], //地区列表的位置信息，
      contryList: [],
      townList: [],
      isWoodOpen: false, //疫木点位是否打开
      lableList: [],
      secondCode: null,
      loading: false,
    };
  }
  componentDidMount() {
    const { showData } = this.state;
    const { map, areaName, fatherCode } = this.props;
    const tokenAccount = JSON.parse(localStorage.getItem("token"));
    const role = localStorage.getItem("role");
    if (tokenAccount.account === "ls_jn_tourist") {
      this.areaList("景宁县", 331127, 2);
    } else if (role === "ROLE_2") {
      console.log(123);
      this.setState(
        {
          theName: tokenAccount.areaName,
        },
        () => {
          this.areaList(
            tokenAccount.areaName,
            tokenAccount.areaCode,
            tokenAccount.areaLevel
          );
        }
      );
    } else {
      this.areaList(areaName, fatherCode, 1);
    }
    this.getCountryList();
    map.addMapEvent("click", this.enterMap);
  }

  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {
    const { code, theName, level, fatherCode, map, year } = this.props;
    const { showData, secondCode, loading } = this.state;
    // if (code.length > 6) {
    // 	return;
    // }
    const role = localStorage.getItem("role");
    const tokenAccount = JSON.parse(localStorage.getItem("token"));
    if (
      JSON.parse(localStorage.getItem("token")).account === "ls_jn_tourist" &&
      prevState.showData !== showData
    ) {
      let flag = false;
      showData.list.forEach((element) => {
        if (element.data.areaCode === code) {
          element.isShow = true;
          flag = true;
        } else {
          element.isShow = false;
        }
      });
    }
    //游客莲都区权限
    if (
      prevState.showData !== showData &&
      !prevState.showData?.list &&
      role == "ROLE_2" &&
      tokenAccount.areaCode === "331102"
    ) {
      console.log(showData);
      this.chooseArea({}, "0", showData.list[0], "331102");
    }
    if (
      prevProps.code !== code &&
      prevState.showData !== showData &&
      localStorage.getItem("role") === "ROLE_2"
    ) {
      let flag = false;
      showData.list.forEach((element) => {
        if (element.data.areaCode === code) {
          element.isShow = true;
          flag = true;
        } else {
          element.isShow = false;
        }
      });
      if (level === 3) {
        this.areaList(
          prevProps.theName,
          secondCode ? secondCode : fatherCode,
          2
        );
        map.removeMapEvent("click", this.enterMap);
      } else {
        this.areaList(theName, code, level);
        map.addMapEvent("click", this.enterMap);
        this.setState({
          secondCode: code,
        });
      }
    }

    if (prevProps.code !== code && localStorage.getItem("role") !== "ROLE_2") {
      let flag = false;
      showData.list.forEach((element) => {
        if (element.data.areaCode === code) {
          element.isShow = true;
          flag = true;
        } else {
          element.isShow = false;
        }
      });
      if (level === 3) {
        this.areaList(
          prevProps.theName,
          secondCode ? secondCode : fatherCode,
          2
        );
        map.removeMapEvent("click", this.enterMap);
      } else {
        this.areaList(theName, code, level);
        map.addMapEvent("click", this.enterMap);
        this.setState({
          secondCode: code,
        });
      }
    }
    // if (localStorage.getItem('role') === 'ROLE_2' && prevState.showData !== showData) {
    // 	showData.list.forEach((element) => {
    // 		if (element.data.areaCode === code) {
    // 			element.isShow = true;
    // 		} else {
    // 			element.isShow = false;
    // 		}
    // 	});
    // }
    if (prevProps.year !== year) {
      this.areaList(theName, code, level);
    }
  }

  /**
   * 点击地图进行跳转
   * @param e
   */
  enterMap = (e) => {
    const { map, areaChange, changeFatherCode } = this.props;
    const { showData } = this.state;
    const feature = map.map.forEachFeatureAtPixel(e.pixel, (feature: any) => {
      return feature;
    });

    let theArea = feature && feature.id_;
    theArea &&
      showData.list.forEach((item) => {
        if (
          item.data.name === theArea ||
          item.data.name === theArea.replace("1", "")
        ) {
          const areaLevel = item.data.areaLevel === 2 ? 1 : 2;
          Request.TownList(
            {
              areaCode: item.data.areaCode,
              areaLevel: areaLevel,
              year: sessionStorage.getItem("year"),
            },
            (res) => {
              if (res.code === 200) {
                res.data.forEach((item1) => {
                  if (item1.name === item.data.name) {
                    areaChange(item1.areaCode, item1.areaLevel, item1.name);
                    map.mapMove(
                      [item1.lon, item1.lat],
                      item1.areaLevel === 1
                        ? 9
                        : item1.areaLevel === 2
                        ? 11
                        : 13
                    ); //地图移动视角
                  }
                });
              }
            }
          );
        }
      });
    changeFatherCode(showData.fatherCode);
  };
  /**
   * 获取地区列表
   * @returns
   */
  areaList = (name, code, level) => {
    const { theName, areaName, year } = this.props;
    this.setState({
      loading: true,
    });
    Request.getAcceptCountyData(
      { areaCode: code, isInspection: 0, year },
      (res) => {
        // console.log(res);

        if (res.code === 200) {
          let list = [];
          res.data.forEach((item) => {
            if (item.name === theName || item.name === areaName) {
              list.push({ isShow: true, data: item });
            } else {
              list.push({ isShow: false, data: item });
            }
          });
          this.setState({
            dataList: res.data,
            showData: { list: list, fatherCode: code },
            loading: false,
          });
        }
      }
    );
  };
  //获取市及以下数据
  getCountryList = () => {
    const { fatherCode, areaName, map } = this.props;
    const lonlat = map.getCenter();

    Request.TownList(
      {
        areaCode: fatherCode,
        areaLevel: 1,
        year: sessionStorage.getItem("year"),
      },
      (res) => {
        let contryList;
        if (res.code === 200) {
          contryList = res.data;
          contryList.push({
            areaCode: fatherCode,
            areaLevel: 1,
            name: areaName,
            lat: lonlat[1],
            lon: lonlat[0],
          });
          this.setState({
            contryList,
          });
        }
      }
    );
  };
  /**
   * 地区列表选择
   * @param e
   * @param index
   * @param element
   */
  chooseArea = (e, index, element, fatherCode) => {
    const { map, areaChange, changeFatherCode } = this.props;
    const { showData, contryList } = this.state;

    Request.TownList(
      {
        areaCode: showData.list[0].data.areaCode,
        areaLevel: showData.list[0].data.areaLevel,
        year: sessionStorage.getItem("year"),
      },
      (res) => {
        if (res.code === 200) {
          res.data.forEach((item) => {
            if (element.data.areaCode === item.areaCode) {
              item.areaLevel !== 3 && changeFatherCode(item.areaCode);
              if (item.lon && item.lat) {
                map.mapMove(
                  [item.lon, item.lat],
                  item.areaLevel === 1 ? 9 : item.areaLevel === 2 ? 11 : 13
                ); //地图移动视角
                areaChange(
                  element.data.areaCode,
                  element.data.areaLevel,
                  element.data.name
                );
              } else {
                Request.TownList(
                  {
                    areaCode: 331100,
                    areaLevel: 1,
                    year: sessionStorage.getItem("year"),
                  },
                  (res) => {
                    //没有小班区域是返回上一级
                    res.data.forEach((item) => {
                      if (fatherCode === item.areaCode) {
                        message.info("暂无该小班区域信息");
                        map.mapMove([item.lon, item.lat], 11); //地图移动视角
                        areaChange(fatherCode, item.areaLevel, item.name);
                      }
                    });
                  }
                );
              }
            } else {
              areaChange(
                element.data.areaCode,
                element.data.areaLevel,
                element.data.name
              );
            }
          });
        }
      }
    );
    contryList.forEach((item) => {
      if (element.data.areaCode === item.areaCode) {
        map.mapMove(
          [item.lon, item.lat],
          item.areaLevel === 1 ? 9 : item.areaLevel === 2 ? 11 : 13
        ); //地图移动视角
      }
    });
    showData.list.forEach((item1) => {
      item1.isShow = false;
    });
    showData.list[index].isShow = true; //高亮显示
    // changeFatherCode(fatherCode);
  };
  // 获取单位
  getUnit = (total, unit) => {
    if (total >= 10000) return `万${unit}`;
    else return unit;
  };
  // 获取上万得数据
  getUnitData = (total) => {
    if (total >= 10000) return (total / 10000).toFixed(2);
    else return total;
  };
  render() {
    const { isOperat } = this.props;
    const { showData, loading } = this.state;

    const acceptprops = {
      width: 327,
      title: "已验收：",
      subtitle: "进度：",
      unit: "%",
    };
    return (
      <div
        className={`h-left animate__animated ${
          isOperat ? "animate__fadeOutLeft" : "animate__fadeInLeft"
        }`}
      >
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: middle,
          }}
          width={100}
          height={"100%"}
          style={{ margin: "0" }}
        />

        <Spin spinning={loading} indicator={antIcon}>
          <div className="left-list">
            <div
              className="class-left-list"
              onTouchMove={(event) => event.stopPropagation()}
              style={{
                width: `${acceptprops.width + 15}px`,
                // display: "none",
              }}
            >
              {showData.list &&
                showData.list.map((item, index) => {
                  return (
                    <div key={index}>
                      {item.isShow ? (
                        <>
                          <Label
                            title={item.data.name}
                            describe={"EPIDEMIC INFECTION STATISTICS"}
                            data={item}
                            width={acceptprops.width + 7}
                          />
                          <div
                            className={"list-head"}
                            style={{
                              width: `${acceptprops.width}px`,
                            }}
                            onClick={(e) =>
                              this.chooseArea(
                                e,
                                index,
                                item,
                                showData.fatherCode
                              )
                            }
                          >
                            <NumCard
                              title={acceptprops.title}
                              unit={this.getUnit(item.data.value, "亩")}
                              total={this.getUnitData(item.data.value)}
                              position={true}
                              bold={true}
                              color="#02D281"
                              style={{ opacity: "0.8" }}
                            />

                            <NumCard
                              title={acceptprops.subtitle}
                              unit={acceptprops.unit}
                              total={item.data.completed}
                              position={true}
                              bold={true}
                              color="#00BAFF"
                              style={{ opacity: "0.8" }}
                            />
                          </div>
                        </>
                      ) : (
                        <div
                          className={"list-item"}
                          style={{
                            width: `${acceptprops.width - 57}px`,
                          }}
                          onClick={(e) =>
                            this.chooseArea(e, index, item, showData.fatherCode)
                          }
                        >
                          <NumCard
                            title={`${item.data.name}：`}
                            unit={this.getUnit(item.data.value, "亩")}
                            total={this.getUnitData(item.data.value)}
                            position={true}
                            color="#02D281"
                            style={{ fontSize: "16px" }}
                          />
                          <NumCard
                            unit={acceptprops.unit}
                            total={item.data.completed}
                            position={true}
                            color="#00BAFF"
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
