import React from "react";
import "ol/ol.css";
import Collection from "ol/Collection";
import { Map, View, Overlay } from "./mapSource";
import { VectorSource } from "./mapSource";
import { Style, Circle, Icon, Fill, Stroke, Text } from "./mapSource";
import * as Interaction from "ol/interaction";

import Feature from "ol/Feature";

import { Point, LineString, Polygon, MultiPolygon } from "ol/geom";
import { fromLonLat } from "ol/proj";
import { Vector as VectorLayer } from "ol/layer";
// import { render } from './handle'

// const env = (window as any)['env']
// interface createMarkerProps {
//   iconUrl: string;
//   iconScale?: number;
//   iconOpacity?: number;
//   iconOffset?: string;
//   id: string;
// }

// interface lineProps {
//   width?: number;
//   color?: string;
// }

// interface PolygonProps {
//   width?: number;
//   color?: string;
// }
export default class map {
  static map: any;
  static defaultZoom = 8.3;
  static defaultCenter = [120.13906693223538, 29.185108379910591];
  static MAX_ZOOM = 18;

  static init() {
    // const layer = new TileLayer({
    //   zIndex: 1,
    //   source: new XYZ({
    //     url: 'http://t0.tianditu.com/DataServer?T=img_w&tk=262a5096a95bd3b84c7bcbd63fbbb572&x={x}&y={y}&l={z}'
    //   })
    // })
    const map = new Map({
      layers: [], //默认初始就加载的图层列表，一般即为底图
      controls: new Collection([]),
      interactions: Interaction.defaults({
        doubleClickZoom: false, // 双击缩放地图
        mouseWheelZoom: false, // 鼠标拖拽缩放
        shiftDragZoom: false, // 按住shift，拖动鼠标左键进行局部区域缩放
        altShiftDragRotate: false, // 按住shift和Alt，拖动鼠标左键进行地图旋转
        dragPan: false, // 拖拽地图
        zoomDuration: 300, // 缩放动画速度
        pinchRotate: true, // 两个手指旋转地图(针对触摸屏)
        pinchZoom: true, // 两个手指缩放地图(针对触摸屏)
        onFocusOnly: true, // 官方解释：只有当地图有焦点时才进行互动。这将影响MouseWheelZoom和DragPan的交互，并且在没有浏览器焦点的地图需要页面滚动时非常有用。
        zoomDelta: 1, //官方解释：使用键盘或双击缩放时缩放水平增量。
      }),
      target: "map-box", // 地图渲染的容器ID
      view: new View({
        center: this.defaultCenter, //地图的默认中心点
        zoom: this.defaultZoom, //地图的默认缩放层级
        projection: "EPSG:4326", // 地图的默认坐标系
      }),
    });
    this.map = map;
  }

  /**
   * 获取openLayers地图经纬度对象
   * @param x 经度
   * @param y 纬度
   * @returns 经纬度对象
   */
  static getLnglat(x: number, y: number) {
    return fromLonLat([x, y]);
  }

  /**
   * 获取地图视图View信息
   * @returns 地图视图对象
   */
  static getMapView() {
    const view = map.map.getView();
    const obj = {
      center: [view.getCenter()[0], view.getCenter()[1]],
      projection: view.getProjection().code_,
      resolution: view.getResolution(),
      zoom: view.getZoom(),
      rotate: view.getRotation(),
      left_top_position: [0, 0],
      right_bottom_position: [0, 0],
      view: view,
    };
    return obj;
  }

  static addMapEvent(key: string, fn: any) {
    map.map.on(key, fn);
  }

  static removeMapEvent(key: string, fn: any) {
    map.map.un(key, (e: any) => fn);
  }

  static createMarker(lnglat: Array<number>, MarkerObj: any, style?: any) {
    const {
      iconScale = 1,
      iconOffset = "",
      iconUrl = "",
      // iconOpacity = 1,
      textColor = "#FFFFFF",
      fontSize = "16px",
      fontFamily = "微软雅黑",
      offsetY = 0,
      offsetX = 0,
    } = style || {};
    const { id = "" } = MarkerObj;
    let feature_style = new Style();
    let iconFeature = new Feature({
      geometry: new Point(lnglat),
      ...MarkerObj,
    });
    id !== "" && iconFeature.setId(id);
    iconUrl !== "" &&
      feature_style.setImage(
        new Icon({
          scale: iconScale, //设置背景图的缩放(数值0-1，1为正常显示)
          offset: [0, 0], //背景图片的在x，y方向上的偏移，
          offsetOrigin: iconOffset, //偏移原点(经纬度)的位置
          opacity: 1, //设置背景图的透明度(数值0-1，1为正常显示)
          src: iconUrl, //设置背景图片的地址，可本地导入再传入,
        })
      );
    MarkerObj.name &&
      MarkerObj.name !== "" &&
      feature_style.setText(
        new Text({
          textAlign: "center", //设置字体的对齐方式
          textBaseline: "middle",
          font: `normal ${fontSize} ${fontFamily}`, //设置字体大小，粗细
          // rotateWithView: false,
          // rotation: 1.55,
          // padding: [20,20,20,20],
          text: MarkerObj.name || "", //设置字体的显示内容
          offsetY,
          offsetX,
          fill: new Fill({
            color: textColor, //设置字体颜色
          }),
          // stroke: new Stroke({ // 设置文字阴影颜色以及阴影宽度
          //   color: '#000',
          //   width: 1
          // })
        })
      );
    iconFeature.setStyle(feature_style);
    return iconFeature;
  }

  static addPoint(
    lnglat: Array<number>,
    PointObj: any,
    {
      pointSize = 10,
      pointColor = "#FFFFFF",
      iconUrl = "",
      textColor = "#FFFFFF",
      fontSize = "16px",
      textOffsetY = 0,
    }
  ) {
    let style = new Style();
    let pointFeature = new Feature({
      geometry: new Point(lnglat),
      ...PointObj,
    });
    style.setImage(
      new Circle({
        radius: pointSize,
        fill: new Fill({
          color: pointColor,
        }),
      })
    );
    PointObj.name !== "" &&
      style.setText(
        new Text({
          textAlign: "center",
          textBaseline: "middle",
          font: `normal ${fontSize} 微软雅黑`, //设置字体大小，粗细
          text: PointObj.name || "", //设置字体的显示内容
          offsetY: textOffsetY, // 设置字体偏移背景图的位置
          fill: new Fill({
            color: textColor, //设置字体颜色
          }),
        })
      );
    pointFeature.setStyle(style);
    //矢量标注图层
    var vectorLayer = new VectorLayer({
      source: new VectorSource({
        features: [pointFeature],
      }),
    });
    vectorLayer.set("id", PointObj.id);
    map.addLayer(vectorLayer);
    return vectorLayer;
  }

  /**
   * 画线
   * @param lnglats 经纬度数组
   * @param LineObj 线段存储的信息对象
   * @param style 线段的样式(宽度，颜色等等)
   */
  static addLine(
    lnglats: any,
    LineObj: any,
    {
      width = 5,
      color = "#FFFFFF",
      visible = true,
      fillColor = "transparent",
      textColor = "#FFFFFF",
      fontSize = "16px",
      lineDash = undefined,
    }
  ) {
    let feature = new Feature({
      type: "polygon",
      geometry: new Polygon(lnglats),
      ...LineObj,
    });
    feature.setId(LineObj.id);
    feature.setStyle(
      new Style({
        stroke: new Stroke({
          lineDash,
          width: width,
          color: color,
        }),
        fill: new Fill({
          color: fillColor,
        }),
        // text: new Text({
        //   textAlign: 'center',
        //   textBaseline: 'middle',
        //   font: `normal ${fontSize} 微软雅黑`,
        //   text: LineObj.name || '',
        //   fill: new Fill({
        //     color: textColor
        //   })
        // })
      })
    );
    let source = new VectorSource({
      features: [feature],
    });
    let vercorLayer = new VectorLayer({
      zIndex: 2,
      source,
      visible,
    });
    vercorLayer.set("id", LineObj.id || "line");
    map.addLayer(vercorLayer);
    return vercorLayer;
  }

  static createPolygon(params) {
    const { position, polygon, style } = params;
    const {
      width = 5,
      borderColor = "#FFFFFF",
      fillColor = "transparent",
      // textColor = '#FFFFFF',
      // fontSize = '16px',
      lineDash = undefined,
    } = style || {};
    let feature = new Feature({
      type: "polygon",
      geometry: new Polygon(position),
      ...polygon,
    });
    polygon.id && polygon.id !== "" && feature.setId(polygon.id);
    feature.setStyle(
      new Style({
        stroke: new Stroke({
          lineDash,
          width,
          color: borderColor,
        }),
        fill: new Fill({
          color: fillColor,
        }),
      })
    );
    return feature;
  }

  static addPolygon(params) {
    if (params.data) {
      const { data, id } = params;
      let source = new VectorSource({
        features: data,
      });
      let vercorLayer = new VectorLayer({
        source,
      });
      id && id !== "" && vercorLayer.set("id", id);
      map.addLayer(vercorLayer);
      return vercorLayer;
    } else {
      const { polygon } = params;
      const { id } = polygon;
      const feature = map.createPolygon(params);
      let source = new VectorSource({
        features: [feature],
      });
      let vercorLayer = new VectorLayer({
        source,
      });
      id && id !== "" && vercorLayer.set("id", id);
      map.addLayer(vercorLayer);
      return vercorLayer;
    }
  }

  static createMultiPolygon(params) {
    const { position, polygon, style } = params;
    const {
      width = 5,
      borderColor = "#FFFFFF",
      fillColor = "transparent",
      // textColor = '#FFFFFF',
      // fontSize = '16px',
      lineDash = undefined,
    } = style || {};
    let feature = new Feature({
      type: "polygon",
      geometry: new MultiPolygon(position),
      ...polygon,
    });
    polygon.id && polygon.id !== "" && feature.setId(polygon.id);
    feature.setStyle(
      new Style({
        stroke: new Stroke({
          lineDash,
          width,
          color: borderColor,
        }),
        fill: new Fill({
          color: fillColor,
        }),
      })
    );
    return feature;
  }

  static addMultiPolygon(params) {
    if (params.data) {
      const { data, id } = params;
      let source = new VectorSource({
        features: data,
      });
      let vercorLayer = new VectorLayer({
        source,
      });
      id && id !== "" && vercorLayer.set("id", id);
      map.addLayer(vercorLayer);
      return vercorLayer;
    } else {
      const { polygon } = params;
      const { id } = polygon;
      const feature = map.createMultiPolygon(params);
      let source = new VectorSource({
        features: [feature],
      });
      let vercorLayer = new VectorLayer({
        source,
      });
      id && id !== "" && vercorLayer.set("id", id);
      map.addLayer(vercorLayer);
      return vercorLayer;
    }
  }

  static addBorder(list: any, LineObj: any, { width = 5, color = "#FFFFFF" }) {
    let featureList: Array<any> = [];
    list.forEach((item: any, index: number) => {
      let feature = new Feature({
        geometry: new LineString(item[0]),
        ...LineObj,
      });
      featureList.push(feature);
      if (index === list.length - 1) {
        let source = new VectorSource({
          features: list.map((r: any) => {
            return new Feature({
              geometry: new LineString(r[0]),
              ...LineObj,
            });
          }),
        });
        let vercorLayer = new VectorLayer({
          zIndex: 2,
          source: source,
          visible: true,
          style: new Style({
            stroke: new Stroke({
              width: width,
              color: color,
            }),
          }),
        });
        vercorLayer.set("id", LineObj.id || "line");
        map.addLayer(vercorLayer);
        return vercorLayer;
      }
    });
  }

  static addMarker(params: any) {
    if (!params.lnglat) {
      const { data, id } = params;
      let vectorLayer = new VectorLayer({
        source: new VectorSource({
          features: data,
        }),
      });
      vectorLayer.set("id", id);
      map.addLayer(vectorLayer);
      return vectorLayer;
    } else {
      const { lnglat, marker, marker_style } = params;
      const {
        iconScale = 1,
        iconOffset = "",
        iconUrl = "",
        textColor = "#FFFFFF",
        fontSize = "16px",
        textOffsetY = 0,
      } = marker_style;
      let style = new Style();
      let iconFeature = new Feature({
        geometry: new Point(lnglat),
        ...marker,
      });
      iconUrl !== "" &&
        style.setImage(
          new Icon({
            scale: iconScale,
            offset: [0, 0],
            offsetOrigin: iconOffset,
            opacity: 1,
            src: iconUrl,
          })
        );
      marker.name !== "" &&
        style.setText(
          new Text({
            textAlign: "center",
            textBaseline: "middle",
            font: `normal ${fontSize} 微软雅黑`, //设置字体大小，粗细
            // rotateWithView: false,
            // rotation: 1.55,
            // padding: [20,20,20,20],
            text: marker.name || "", //设置字体的显示内容
            offsetY: textOffsetY, // 设置字体偏移背景图的位置
            fill: new Fill({
              color: textColor, //设置字体颜色
            }),
            // stroke: new Stroke({ // 设置文字阴影颜色以及阴影宽度
            //   color: '#000',
            //   width: 1
            // })
          })
        );
      iconFeature.setStyle(style);
      //矢量标注图层
      var vectorLayer = new VectorLayer({
        source: new VectorSource({
          features: [iconFeature],
        }),
      });
      vectorLayer.set("id", marker.id);
      map.addLayer(vectorLayer);
      return vectorLayer;
    }
  }

  /**
   * 无参数
   * @returns 当前地图所包含的全部图层以及图层数量
   */
  static getAllLayers = () => {
    const layers = {
      layerNum: map.map.getLayers().array_.length,
      layerList: map.map.getLayers().array_,
    };
    return layers;
  };

  /**
   * 获取地图已经叠加的全部图层中的某一个特定图层
   * @param {*} id 目标图层的id
   * @returns 指定的图层
   */
  static getLayer(id: string) {
    return map.map
      .getLayers()
      .array_.find((layer: any) => !!layer.get("id") && id === layer.get("id"));
  }

  static getLayerInfo(layer: any, key: string) {
    return layer.get(key);
  }

  /**
   * 添加图层
   * @param layer 图层
   */
  static addLayer(layer: any) {
    map.map.addLayer(layer);
  }

  /**
   * 移除图层
   * @param layer 图层
   */
  static removeLayer(layer: any) {
    map.map.removeLayer(layer);
  }

  static addOverlay(overlay: Overlay) {
    map.map.addOverlay(overlay);
  }

  static removeOverlay(overlay: Overlay) {
    map.map.removeOverlay(overlay);
  }

  static getOverlayById(id: string) {
    return map.map.getOverlayById(id);
  }

  /**
   * 更新图层参数
   * @param layer 目标图层对象
   * @param params 要更新的参数对象(key-value)
   */
  static updateLayer(layer: any, params: any) {
    layer.getSource().updateParams({
      ...params,
    });
  }

  /**
   * 地图移动方法
   * @param center 地图移动的中心位置
   * @param zoom 地图移动的缩放层级
   */
  static mapMove(center: any, zoom: number) {
    map.map.getView().animate({
      center: center,
      zoom: zoom,
      rotation: undefined, // 缩放完成view视图旋转弧度
      duration: 500,
    });
  }

  static CreateOverlay(
    id: string,
    element: HTMLElement,
    { position = undefined, positioning = "center-center", offset = [0, 0] }: any
  ) {
    return new Overlay({
      id: id,
      position: position,
      positioning: positioning,
      element: element,
      // 阻止滚轮冒泡事件
      stopEvent: true,
      autoPan: true, // 定义弹出窗口在边缘点击时候可能不完整 设置自动平移效果
      autoPanAnimation: {
        duration: 250, //自动平移效果的动画时间 9毫秒）
      },
      offset: offset,
    });
  }
}
