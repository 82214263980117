import React, { Component } from 'react';
import Lottie from 'react-lottie';
import middle from '../../assets/icons/middle.json';
import { Label } from '../../component/tools/Label';
import Request from '../../services/Manger';
import './left.scss';
import NumCard from '../../component/Card/NumCard';
interface State {
  dataList: any;
  selectName: string;
  showData: any;
  showList: any;
  contryList: any;
  townList: any;
}
export default class LeftNoClass extends Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      dataList: [],
      selectName: '',
      showData: [],
      showList: [],
      contryList: [],
      townList: [],
    };
  }

  componentDidMount() {
    const { map } = this.props;
    this.getShowList();
    map.addMapEvent('click', this.enterMap);
  }

  /**
   * 与区域列表进行联动
   * @param nextProps
   * @param nextState
   * @returns
   */
  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    const { code, theName, map, isCheckOpen, year } = this.props;
    const { showList } = this.state;
    if (prevProps.year !== year) {
      this.getShowList();
    }
    if (prevProps.code !== code) {
      showList.forEach((item) => {
        item.name === theName ? (item.isShow = true) : (item.isShow = false); //高亮显示
      });
    }
    if (prevProps.isCheckOpen !== isCheckOpen) {
      if (isCheckOpen) {
        map.removeMapEvent('click', this.enterMap);
        // map.addMapEvent(CMouseEvent.Click, this.modifyGrid)
      } else {
        map.addMapEvent('click', this.enterMap);
        // map.removeMapEvent(CMouseEvent.Click, this.modifyGrid)
      }
    }
  }

  //点击地图跳转
  enterMap = (e) => {
    const { map, areaChange, level, mode } = this.props;
    const { contryList } = this.state;

    const feature = map.map.forEachFeatureAtPixel(e.pixel, (feature: any) => {
      return feature;
    });
    let theArea = feature && feature.id_;
    if (level !== 3 && mode !== 1) {
      //无小班页面在跳转到镇级之后，不在触发点击地图跳转功能
      theArea &&
        contryList.forEach((item) => {
          if (item.name === theArea || item.name === theArea.replace('1', '')) {
            areaChange(item.areaCode, item.areaLevel, item.name);
            map.mapMove(
              [item.lon, item.lat],
              item.areaLevel === 1 ? 9 : item.areaLevel === 2 ? 11 : 13
            ); //地图移动视角
          }
        });
    }
  };

  /**
   * 地区列表选择
   * @param e
   * @param i
   * @param element
   */
  chooseArea = (e, i, element) => {
    const { showList, contryList } = this.state;
    const { areaChange, map } = this.props;
    showList.forEach((item) => {
      item.name === element.name ? (item.isShow = true) : (item.isShow = false); //高亮显示
    });
    contryList.forEach((item) => {
      if (item.name === element.name) {
        areaChange(item.areaCode, item.areaLevel, item.name);
        map.mapMove(
          [item.lon, item.lat],
          item.areaLevel === 1 ? 9 : item.areaLevel === 2 ? 11 : 13
        ); //地图移动视角
      }
    });
    this.setState({
      showList,
    });
  };

  /**
   * 获取地区列表数据
   * @param
   */
  getShowList = () => {
    const { code, theName, level, map, year } = this.props;
    const lonlat = map.getCenter();

    Request.NoclassAreaList(
      { areaCode: code, areaLevel: level, year },
      (res) => {
        if (res.code === 200) {
          let list = [];
          res.data.forEach((item) => {
            list.push({ ...item, isShow: false });
          });
          list[0].isShow = true;
          this.setState({ showList: list });
        }
      }
    );

    // const param = this.props.theName === '温州市' ? { areaCode: 330329, areaLevel: 2 } : { areaCode: 330784, areaLevel: 2 }
    // const item = this.props.theName === '温州市' ? { id: '', name: '泰顺县', areaCode: '330329', areaLevel: 2, lon: 119.717643, lat: 27.556578 } : { id: '', name: '永康市', areaCode: '330784', areaLevel: 2, lon: 120.1, lat: 28.89652 }

    Request.TownList({ areaCode: code, areaLevel: level, year: sessionStorage.getItem('year') }, (res) => {
      //没有小班区域是返回上一级
      let contryList;
      if (res.code === 200) {
        contryList = res.data;
        contryList.push({
          name: theName,
          areaCode: code,
          areaLevel: level,
          lat: lonlat[1],
          lon: lonlat[0],
        });
        this.setState({
          contryList,
        });
      }
    });
  };

  render() {
    const { isOperat } = this.props;
    const { showList } = this.state;
    return (
      <div
        className="h-left"
        style={isOperat ? { left: '-27vw' } : { left: '0' }}
      >
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: middle,
          }}
          width={100}
          height={'76.4%'}
          style={{ margin: '0' }}
        />
        <div className="left-list">
          <div className="noclass-left-list">
            {showList.map((item, i) => {
              return (
                <>
                  {item.isShow ? (
                    <>
                      <Label
                        title={item.name}
                        describe={'QUANTITY'}
                        width={237}
                        style={{ marginRight: '128px' }}
                      />
                      <div
                        className="list-head"
                        onClick={(e) => this.chooseArea(e, i, item)}
                        style={{ marginRight: '132px' }}
                      >
                        <NumCard
                          title={'发生株数：'}
                          unit={item.treeNumber >= 10000 ? '万株' : '株'}
                          total={
                            item.treeNumber >= 10000
                              ? (item.treeNumber / 10000).toFixed(2)
                              : item.treeNumber
                          }
                          position={true}
                          bold={true}
                          color="#00BAFF"
                          style={{
                            opacity: '0.8',
                          }}
                          NumCardStyle={{
                            justifyContent: 'space-between',
                            width: '100%',
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <div
                      className="list-item"
                      onClick={(e) => this.chooseArea(e, i, item)}
                      style={{ marginRight: '157px' }}
                    >
                      <NumCard
                        title={`${item.name}：`}
                        unit={item.treeNumber >= 10000 ? '万亩' : '亩'}
                        total={
                          item.treeNumber >= 10000
                            ? (item.treeNumber / 10000).toFixed(2)
                            : item.treeNumber
                        }
                        position={true}
                        color="#02D281"
                        style={{
                          fontSize: '16px',
                        }}
                        NumCardStyle={{
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      />
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
