import { PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Select, Upload, message } from "antd";
import { useEffect, useState } from "react";
import eiminationReports from "../../services/EiminationReports";
import Common from "../../services/Common";

const { Dragger } = Upload;

interface Props {
  changeVisible: () => void;
}

/**
 * @description    表格上传
 * @param changeVisible 关闭弹窗
 */

const ExcelUpload = ({ changeVisible }: Props) => {
  //区县列表
  const [countyList, setCountyList] = useState([]);

  const [activeCounty, setActiveCounty] = useState("");
  //上传列表
  const [fileList, setFileList] = useState([]);

  const getCountyList = () => {
    Common.getCountyList({ cityCode: 331100 }).then((res) => {
      const data = res.data;
      if (data.code === 200) {
        setCountyList(
          data.data.map((item) => {
            return {
              value: item.code,
              label: item.name,
            };
          })
        );
        setActiveCounty("331102");
      }
    });
  };

  const changeActiveCounty = (value) => {
    setActiveCounty(value);
  };

  const onUpload = () => {
    const data = new FormData();
    data.append("multipartFile", fileList[0]);
    data.append("countyCode", activeCounty);
    eiminationReports.uploadExcel(data).then((res) => {
      const resData = res.data;
      if (resData.code === 200) {
        message.success(resData.msg);
      } else {
        message.warning(resData.msg);
      }
      changeVisible();
    });
  };

  const uploadProps = {
    accept: ".xlsx,.xls",
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
  };

  useEffect(() => {
    getCountyList();
  }, []);

  return (
    <Modal
      title="表格上报"
      width={384}
      visible={true}
      footer={null}
      getContainer={false}
      onCancel={changeVisible}
      bodyStyle={{
        padding: 0,
      }}
    >
      <div className="modal_body">
        <Select
          style={{ marginBottom: "10px", width: "100px" }}
          options={countyList}
          value={activeCounty}
          onChange={changeActiveCounty}
        />
        <div className="modal_upload">
          <Dragger {...uploadProps}>
            <PlusOutlined rev={undefined} />
            <p>除治数据上传，请上传excel文件</p>
          </Dragger>
        </div>
      </div>
      <div className="modal_footer">
        <Button
          type="primary"
          style={{ marginRight: "10px" }}
          onClick={onUpload}
        >
          确认
        </Button>
        <Button onClick={changeVisible}>取消</Button>
      </div>
    </Modal>
  );
};

export default ExcelUpload;
