import React, { Component } from "react";
import { DownOutlined } from "@ant-design/icons";
import { message } from "antd";
import Request from "../../services/Manger";
import PlagueWoodTs from "../../platform/services/plagueWood";
import { CXYZLayer } from "../../utils/CMap";

interface State {
  isSelect: any;
  isOrthoimage: any;
  isWoodPoint: any;
  contryList: any;
  townList: any;
  areaName: any;
  lableList: any;
  lastTown: any;
  theTown: any;
  isOpenPollute: any;
  OrthoimageLs: any[];
  tilelay: any;
  county: any;
}

// const T = window['T']
const countyList = [
  {
    name: "丽水市",
    areaCode: "331100",
    areaLevel: 1,
    lat: 28.09652,
    lon: 119.63104,
  },
  {
    name: "衢州市",
    areaCode: "330800",
    areaLevel: 1,
    lat: 28.941708,
    lon: 118.87263,
  },
  {
    name: "温州市",
    areaCode: "330300",
    areaLevel: 1,
    lat: 28.000575,
    lon: 120.672111,
  },
];
// let tilelay: any
export default class left extends Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      isSelect: false,
      isOrthoimage: false,
      isWoodPoint: false,
      isOpenPollute: false,
      areaName: this.props.areaName,
      county: {},
      contryList: {},
      townList: {},
      lableList: [],
      lastTown: [],
      theTown: {},
      OrthoimageLs: [], //丽水市的正射
      tilelay: null, //泰顺县的正射
    };
  }
  componentDidMount() {
    this.contryList(this.props.code);
    const { propsareaCode, level } = this.props;
    const tokenAccount = JSON.parse(localStorage.getItem("token"));

    const role = localStorage.getItem("role");
    if (tokenAccount.account === "ls_jn_tourist") {
      this.selectArea(
        {},
        {
          areaCode: "331127",
          areaLevel: 2,
          id: 7,
          lat: 27.89421263154444,
          lon: 119.60867823911248,
          name: "景宁县",
        },
        "331127"
      );
    }
    if (role == "ROLE_2") {
      this.selectArea(
        {},
        {
          areaCode: tokenAccount.areaCode,
          areaLevel: tokenAccount.areaLevel,
          lat: parseFloat(tokenAccount.coordinate.split(",")[1]),
          lon: parseFloat(tokenAccount.coordinate.split(",")[0]),
          name: tokenAccount.areaName,
        },
        tokenAccount.areaCode
      );
    }
    if (level === 1) {
      countyList.map((item) => {
        if (item.areaCode === propsareaCode) {
          this.setState({
            county: item,
          });
        }
      });
    } else {
      this.setState({
        county: null,
      });
    }
  }
  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    const { isWoodOpen, year, map } = this.props;
    const { OrthoimageLs, isOrthoimage, tilelay, contryList } = this.state;
    // if (contryList !== prevState.contryList) {
    // 	const { propsareaCode, level } = this.props;
    // 	const tokenAccount = JSON.parse(localStorage.getItem('token'))

    // 	const role = localStorage.getItem('role')
    // 	if (tokenAccount.account === 'ls_jn_tourist') {
    // 		this.selectArea({}, { areaCode: "331127", areaLevel: 2, id: 7, lat: 27.89421263154444, lon: 119.60867823911248, name: "景宁县", }, '331127')
    // 	}
    // 	if (role == 'ROLE_2' && tokenAccount.areaLevel === 2) {
    // 		contryList && contryList.list.map(e => {
    // 			if (e.name === tokenAccount.areaName) {
    // 				this.selectArea({}, { areaCode: e.areaCode, areaLevel: 2, id: e.id, lat: e.lat, lon: e.lon, name: e.name, }, e.code)
    // 			}
    // 		})

    // 	}
    // 	if (level === 1) {
    // 		countyList.map((item) => {
    // 			if (item.areaCode === propsareaCode) {
    // 				this.setState({
    // 					county: item,
    // 				});
    // 			}
    // 		});
    // 	} else {
    // 		this.setState({
    // 			county: null,
    // 		});
    // 	}
    // }
    if (isOrthoimage) {
      if (prevProps.year !== year) {
        this.setState({
          OrthoimageLs: [],
        });
        OrthoimageLs.forEach((item) => {
          map.removeLayer(item);
        });
        tilelay && map.removeLayer(tilelay);
        this.upDataOrthoimage(year);
      }
    }
    if (prevProps.isWoodOpen !== isWoodOpen) {
      this.setState({
        isWoodPoint: isWoodOpen,
      });
    }
  }
  /**
   * 获取县级地区列表
   * @returns
   */
  contryList = (code) => {
    Request.ContryList(
      { areaCode: code, areaLevel: 1, year: sessionStorage.getItem("year") },
      (res) => {
        if (res.code === 200) {
          this.setState({
            contryList: { list: res.data, fatherCode: code },
          });
        } else {
          message.error("获取县级列表失败");
        }
      }
    );
  };
  /**
   * 获取乡镇列表
   * @returns
   */
  townList = (e, item, isCounty?: any) => {
    if (isCounty) {
      this.setState({
        townList: {},
        theTown: item,
      });
    } else {
      Request.TownList(
        {
          areaCode: item.areaCode,
          areaLevel: item.areaLevel,
          year: sessionStorage.getItem("year"),
        },
        (res) => {
          if (res.code === 200) {
            this.setState({
              townList: { list: res.data, fatherCode: item.areaCode },
              theTown: item,
            });
          } else {
            message.error("获取乡镇列表失败");
          }
        }
      );
    }
  };

  //更新正射影像
  upDataOrthoimage = (year) => {
    let { OrthoimageLs, tilelay } = this.state;
    const { lay, map, mode, propsareaCode } = this.props;

    if (mode === 3) {
      if (!tilelay) {
        fetch(
          `https://highdata.oss-cn-hangzhou.aliyuncs.com/2022puchapingtai/20220704taishunxiansiqianzhen/meta.json`
        )
          .then((res) => res.json())
          .then((data) => {
            if (data) {
              const { bounds } = data;
              const { east, north, south, west } = bounds;
              tilelay = new CXYZLayer({
                url: `https://highdata.oss-cn-hangzhou.aliyuncs.com/2022puchapingtai/20220704taishunxiansiqianzhen/{z}/{x}/{y}.png`,
                minZoom: 0,
                maxZoom: 22,
                extent: [west, south, east, north],
              });
              map.addLayer(tilelay);
              this.setState({ tilelay });
            }
          });
      }
    } else {
      PlagueWoodTs.getOrthoImage({ year, areaCode: propsareaCode }, (res) => {
        res.data &&
          res.data.forEach((item, index) => {
            fetch(item.baseJson + "?" + `token=${window["env"].CLOUD_TOKEN}`)
              .then((res) => res.json())
              .then((data) => {
                if (data) {
                  const { bounds } = data;
                  const { east, north, south, west } = bounds;
                  const tilelay = new CXYZLayer({
                    url:
                      item.baseUrl + "?" + `token=${window["env"].CLOUD_TOKEN}`,
                    minZoom: 0,
                    maxZoom: 22,
                    extent: [west, south, east, north],
                  });
                  lay[item] = tilelay;
                  tilelay.setVisible(true);
                  OrthoimageLs.push(tilelay);
                  map.addLayer(tilelay);
                }
              });
          });
      });
      this.setState({
        OrthoimageLs,
      });
    }
  };

  //加载正射影像图层
  orthoimage = () => {
    let { isOrthoimage, OrthoimageLs, tilelay } = this.state;
    const { lay, map, mode, year, propsareaCode } = this.props;
    if (mode === 3) {
      if (!isOrthoimage) {
        if (!tilelay) {
          fetch(
            `https://highdata.oss-cn-hangzhou.aliyuncs.com/2022puchapingtai/20220704taishunxiansiqianzhen/meta.json`
          )
            .then((res) => res.json())
            .then((data) => {
              if (data) {
                const { bounds } = data;
                const { east, north, south, west } = bounds;
                tilelay = new CXYZLayer({
                  url: `https://highdata.oss-cn-hangzhou.aliyuncs.com/2022puchapingtai/20220704taishunxiansiqianzhen/{z}/{x}/{y}.png`,
                  minZoom: 0,
                  maxZoom: 22,
                  extent: [west, south, east, north],
                });
                map.addLayer(tilelay);
                this.setState({ tilelay });
              }
            });
        } else {
          map.addLayer(tilelay);
        }
      } else {
        map.removeLayer(tilelay);
      }
    } else {
      if (!isOrthoimage) {
        // if (OrthoimageLs.length === 0) {
        const tokenAccount = JSON.parse(localStorage.getItem("token")).account;
        let areaCode = propsareaCode;
        if (tokenAccount === "ls_jn_tourist") {
          areaCode = "331127";
        }
        PlagueWoodTs.getOrthoImage({ year, areaCode: areaCode }, (res) => {
          res.data.forEach((item, index) => {
            fetch(item.baseJson + "?" + `token=${window["env"].CLOUD_TOKEN}`)
              .then((res) => res.json())
              .then((data) => {
                if (data) {
                  const { bounds } = data;
                  const { east, north, south, west } = bounds;
                  const tilelay = new CXYZLayer({
                    url:
                      item.baseUrl + "?" + `token=${window["env"].CLOUD_TOKEN}`,
                    minZoom: 0,
                    maxZoom: 22,
                    extent: [west, south, east, north],
                  });
                  lay[item] = tilelay;
                  tilelay.setVisible(true);
                  OrthoimageLs.push(tilelay);
                  map.addLayer(tilelay);
                }
              });
          });
        });
        this.setState({
          OrthoimageLs,
        });
        // } else {
        //     OrthoimageLs.forEach(e => {
        //         map.addLayer(e)
        //     })
        // }
      } else {
        OrthoimageLs.forEach((e) => {
          map.removeLayer(e);
        });
      }
    }
    this.setState({
      isOrthoimage: !isOrthoimage,
    });
  };

  //加载疫木点位
  woodPoint = () => {
    const { isWoodPoint } = this.state;
    const { changeWoodOpen } = this.props;
    this.setState({
      isWoodPoint: !isWoodPoint,
    });
    changeWoodOpen(!isWoodPoint); //更改主页面的疫木修正状态
  };

  //加载污染程度图层
  pollution = () => {
    const { changeInfectLayer } = this.props;
    const { isOpenPollute } = this.state;
    this.setState({
      isOpenPollute: !isOpenPollute,
    });
    changeInfectLayer(!isOpenPollute);
  };

  /**
   * 地区选择
   * @param e
   * @param item
   */
  selectArea = (e, item, fatherCode) => {
    const { map, areaChange, changeFatherCode } = this.props;
    const tokenAccount = JSON.parse(localStorage.getItem("token"));
    const role = localStorage.getItem("role");
    console.log([item.lon, item.lat]);
    if (
      item.areaCode.indexOf("331127") < 0 &&
      JSON.parse(localStorage.getItem("token")).account === "ls_jn_tourist"
    ) {
      message.warn("无查看此区域权限！");
    } else if (
      role === "ROLE_2" &&
      item.areaCode.indexOf(tokenAccount.areaCode) < 0
    ) {
      message.warn("无查看此区域权限！");
    } else {
      changeFatherCode(item.areaCode);
      if (item.lon && item.lat) {
        areaChange(item.areaCode, item.areaLevel, item.name);
        map.mapMove(
          [item.lon, item.lat],
          item.areaLevel === 1 ? 9 : item.areaLevel === 2 ? 11 : 13
        ); //地图移动视角
      } else {
        Request.TownList(
          {
            areaCode: 331100,
            areaLevel: 1,
            year: sessionStorage.getItem("year"),
          },
          (res) => {
            //没有小班区域是返回上一级
            res.data.forEach((obj) => {
              if (fatherCode === obj.areaCode) {
                message.info("暂无该小班区域信息");

                map.mapMove([item.lon, item.lat], 11); //地图移动视角
                areaChange(fatherCode, obj.areaLevel, obj.name);
              }
            });
          }
        );
      }
      this.setState({
        areaName: item.name, //按钮显示所选地区
        isSelect: false, //关闭地区选择
      });
    }
  };

  handleClick = () => {
    const { isSelect } = this.state;
    this.setState({
      isSelect: !isSelect,
    });
  };
  render() {
    const {
      isSelect,
      isOrthoimage,
      isWoodPoint,
      isOpenPollute,
      contryList,
      townList,
      county,
    } = this.state;
    const { theName } = this.props;
    const navButtonList = [
      { title: "正摄影像", className: isOrthoimage, onClick: this.orthoimage },
      { title: "疫木点位", className: isWoodPoint, onClick: this.woodPoint },
      { title: "感染程度", className: isOpenPollute, onClick: this.pollution },
    ];
    return (
      <div className="leftBtns">
        <div>
          <div onClick={this.handleClick}>
            <p>
              {theName} <DownOutlined rev={undefined} />{" "}
            </p>
          </div>
          <div
            className="select-list"
            style={isSelect ? { display: "block" } : { display: "none" }}
          >
            <div>
              <div
                className="county-list"
                onTouchMove={(event) => event.stopPropagation()}
              >
                {county ? (
                  <div
                    className="county-item"
                    onClick={(e) => this.selectArea(e, county, county.areaCode)}
                    onMouseOver={(e) => this.townList(e, county, true)}
                  >
                    {county.name}
                  </div>
                ) : null}
                {contryList.list &&
                  contryList.list.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className="county-item"
                        onClick={(e) =>
                          this.selectArea(e, item, contryList.fatherCode)
                        }
                        onMouseOver={(e) => this.townList(e, item)}
                      >
                        {item.name}
                      </div>
                    );
                  })}
              </div>
              <div
                className="village-list"
                onTouchMove={(event) => event.stopPropagation()}
              >
                {townList.list
                  ? townList.list.map((item) => {
                      return (
                        <div
                          className="village-item"
                          onClick={(e) =>
                            this.selectArea(e, item, townList.fatherCode)
                          }
                        >
                          {item.name}
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
        <div className="leftBtnsList">
          {navButtonList.map((item, index) => (
            <div
              className={item.className ? "active-lbtn" : "lbtn"}
              onClick={item.onClick}
              key={index}
            >
              <div>
                <p>{item.title}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
