import React, { Component } from 'react';
import { CMap } from '@dinnovate/cloudmap_npm';
import { Button } from 'antd';

interface Props {
  isLookView: boolean;
  changeIsLookView: (e: any) => void;
  record: any;
}

class ViewMap extends Component<Props, any> {
  componentDidMount(): void {
    const { record } = this.props;
    //添加正射
    fetch(record.baseJson)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          const { bounds, maxzoom } = data;
          const { east, north, south, west } = bounds;
          const param = {
            id: `zhengshe_${record.id}`,
            zIndex: 1,
            maxZoom: maxzoom,
            extent: [west, south, east, north],
            url: record.baseUrl,
          };
          CMap.addXYZLayer(param);
          if (record.lon && record.lat) {
            setTimeout(() => {
              CMap.mapMove([record.lon, record.lat], 12);
            }, 500);
          } else {
            const lon = (west + east) / 2;
            const lat = (south + north) / 2;
            setTimeout(() => {
              CMap.mapMove([lon, lat], 13);
            }, 500);
          }
        }
      });
  }

  render() {
    const { changeIsLookView, isLookView } = this.props;
    return (
      <div className="ViewMap" id="ViewMap">
        <CMap
          id="ViewMap"
          layerType={'ZjMap'}
          Zoom={9}
          CenterLonLat={[119.63104, 28.09652]}
          maxZoom={20}
        ></CMap>
        <Button
          className="backPage"
          onClick={() => changeIsLookView(isLookView)}
        >
          返回上页
        </Button>
      </div>
    );
  }
}

export default ViewMap;
