import { acceptance_axios as axios } from "../../utils/axios";

export default class LogrecordRequest {
  // 获取日志折线图数据
  static getLineList(params) {
    return axios.get("/log_info/get_line_statistics", { params });
  }

  // 获取日志饼图数据
  static getPieList(params) {
    return axios.get("/log_info/get_pie_statistics", { params });
  }

  // 获取日志数据
  static getLogList(params) {
    return axios.get("/log_info/query", { params });
  }

  // 员工工作量统计主表
  static getWorkStati(params) {
    return axios.get("/log_info/workload_statistics", { params });
  }

  // 员工工作柱状图
  static getWorkbar(params) {
    return axios.get("/log_info/workload_bar_statistics", { params });
  }

  // 员工照片数量信息
  static getWorkPic(params) {
    return axios.get("/log_info/log_info_List_pic", { params });
  }

  //获取报销单数据
  static getReimburse(params) {
    return axios.get("/log_info/reimburse_check", { params });
  }

  // 获取工作统计字表数据
  static getWorkSonList(params) {
    return axios.get("/log_info/workload_statistics_sublist", { params });
  }

  //  所有人工作时长
  static getAllWork_date(params) {
    return axios.get("/log_info/workload_one_day", { params });
  }

  //  某人工作时长
  static getSelfWork_date(params) {
    return axios.get("/log_info/workload_someone", { params });
  }
}
