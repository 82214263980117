/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, message, Modal, Select, Table, Space, Popconfirm } from "antd";
import React, { Component } from "react";
import "./index.scss";
import Map from "./map";
import { ExclamationCircleOutlined, CheckOutlined } from "@ant-design/icons";
import PlagueWoodTs from "../services/plagueWood";
import { AddUpload } from "./components";
import axios from "axios";
const { confirm } = Modal;
const { Option } = Select;
let cancel = null;
export default class PlagueWood extends Component<any, any> {
  role = localStorage.getItem("role");
  role_list = ["ROLE_3", "ROLE_2"];
  areacode = JSON.parse(localStorage.getItem("token")).areaCode;
  socket = null;

  state = {
    isplagueWood: false, //是否疫木管理
    isuploadData: false,
    downLoadType: 0,
    data: [],
    page: 1,
    size: 10,
    total: 0,
    countyList: [], //区县列表数据
    townList: [], //乡镇列表数据
    villageList: [], //
    forestList: [],
    userId: JSON.parse(localStorage.getItem("token")).id,
    isSync: false,
    countyCode:
      this.role_list.indexOf(this.role) !== -1
        ? this.areacode
        : this.props?.locationState?.code ?? sessionStorage.getItem("cityCode"),
    townCode: "",
    villageCode: "",
    forestCode: "",
    isReading: this.role_list.indexOf(this.role) !== -1 ? true : false,
    state: 0,
    loading: true,
    statusBox: [],
    yearBox: [],
    filteredInfo: {
      county: null,
      forest: null,
      state: null,
      town: null,
      village: null,
    },
    theRecord: null,
  };
  componentDidMount() {
    this.getSelectList();
    this.getData();
    this.getState();
    this.getYearBox();
  }

  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<any>,
    snapshot?: any
  ): void {
    const {
      countyCode,
      townCode,
      villageCode,
      state,
      filteredInfo,
      forestCode,
      size,
      page,
    } = this.state;
    if (prevProps.locationState.code !== this.props.locationState.code) {
      this.setState({
        countyCode: this.props.locationState.code,
        countyList: [],
        isplagueWood: false,
      });
    }
    if (prevState.countyCode !== countyCode) {
      this.setState(
        {
          townCode: "",
          villageCode: "",
          forestCode: "",
          filteredInfo: {
            ...filteredInfo,
            forest: null,
            town: null,
            village: null,
          },
        },
        () => {
          this.getData();
          this.getSelectList();
        }
      );
    } else if (prevState.townCode !== townCode) {
      this.setState(
        {
          villageCode: "",
          forestCode: "",
          filteredInfo: {
            ...filteredInfo,
            forest: null,
            village: null,
          },
        },
        () => {
          this.getData();
          this.getSelectList();
        }
      );
    } else if (prevState.villageCode !== villageCode) {
      this.setState(
        {
          forestCode: "",
          filteredInfo: {
            ...filteredInfo,
            forest: null,
          },
        },
        () => {
          this.getData();
          this.getSelectList();
        }
      );
    } else if (
      prevState.page !== page ||
      prevState.state !== state ||
      prevState.size !== size ||
      prevState.forestCode !== forestCode
    ) {
      this.getData();
    }

    if (prevProps.year !== this.props.year) {
      this.getSelectList();
      this.getData();
      this.getState();
      this.getYearBox();
    }
  }

  //获取疫木列表全部数据
  getData = () => {
    const { page, size, countyCode, townCode, villageCode, forestCode, state } =
      this.state;
    const { year } = this.props;
    const searchData = {
      page,
      size,
      countyCode,
      townCode,
      villageCode,
      forestCode,
      state,
      year,
    };
    PlagueWoodTs.getList(searchData).then((res) => {
      if (res.data.code === 200) {
        const data = res.data.data;
        this.setState({
          page,
          data: data.list,
          total: data.total,
          loading: false,
        });
      }
    });
  };

  //获取状态
  getState = () => {
    PlagueWoodTs.getState().then((res) => {
      if (res.data.code === 200) {
        const data = res.data.data;
        this.setState({
          statusBox: data.statusBox,
        });
      }
    });
  };
  //获取年度数据
  getYearBox = () => {
    PlagueWoodTs.getYearBox().then((res) => {
      if (res.data.code === 200) {
        // const data = res.data.data
        this.setState({
          yearBox: res.data.data,
        });
      }
    });
  };
  // 获取林班列表
  getSelectList = () => {
    const { countyCode, townCode, villageCode, forestCode, countyList } =
      this.state;
    const params = {
      countyCode: countyCode ? countyCode : "",
      townCode: townCode ? townCode : "",
      villageCode: villageCode ? villageCode : "",
      forestCode: forestCode ? forestCode : "",
    };
    PlagueWoodTs.getSelectList(params).then((res) => {
      if (res.data.code === 200) {
        if (
          countyList.length === 0 &&
          this.role_list.indexOf(this.role) === -1
        ) {
          const list = [];
          res.data.data.map((item) => {
            if (item) {
              list.push(item);
            }
          });
          this.setState({
            countyList: list,
          });
        } else if (!townCode && !villageCode && !forestCode) {
          this.setState({
            townList: res.data.data,
          });
        } else if (!villageCode && !forestCode) {
          this.setState({
            villageList: res.data.data,
          });
        } else if (!forestCode) {
          this.setState({
            forestList: res.data.data,
          });
        }
      }
    });
  };
  //开启疫木管理
  changeWood = (isplagueWood) => {
    this.setState({
      isplagueWood: !isplagueWood,
    });
  };
  //取消文件上传
  cancleUpload = (type: boolean) => {
    this.setState({
      isuploadData: type,
    });
  };

  //文件上传后获取tabel初始化数据
  upDataGetData = () => {
    this.setState(
      {
        page: 1,
        size: 10,
        state: "",
      },
      () => {
        this.getData();
      }
    );
  };

  //分页,筛选数据
  changePagination = (pagination, filters) => {
    const { townList, villageList, forestList } = this.state;

    let town_List = [...townList];
    let village_List = [...villageList];
    let forest_List = [...forestList];

    if (!filters.county?.[0] && this.role_list.indexOf(this.role) === -1) {
      town_List = [];
      village_List = [];
      forest_List = [];
      filters.town = null;
      filters.village = null;
      filters.forest = null;
    }
    if (!filters.town?.[0]) {
      village_List = [];
      forest_List = [];
      filters.village = null;
      filters.forest = null;
    }
    if (!filters.village?.[0]) {
      forest_List = [];
      filters.forest = null;
    }
    let code;
    if (this.role_list.indexOf(this.role) !== -1) {
      code = {
        townCode: filters.town?.[0]?.substr(6, 8) ?? "",
        villageCode: filters.village?.[0]?.substr(9, 11) ?? "",
        forestCode: filters.forest?.[0]?.slice(12) ?? "",
        state: filters.state?.[0] ?? "",
      };
    } else {
      code = {
        countyCode: filters.county?.[0] ?? sessionStorage.getItem("cityCode"),
        townCode: filters.town?.[0]?.substr(6, 8) ?? "",
        villageCode: filters.village?.[0]?.substr(9, 11) ?? "",
        forestCode: filters.forest?.[0]?.slice(12) ?? "",
        state: filters.state?.[0] ?? "",
      };
    }

    this.setState({
      loading: true,
      page: pagination.current,
      size: pagination.pageSize,
      filteredInfo: filters,
      townList: town_List,
      villageList: village_List,
      forestList: forest_List,
      ...code,
    });

    // if (
    //   (!filters.county && townList.length !== 0) ||
    //   !filters.county ||
    //   (filters.town && filters.county[0] !== this.state.countyCode)
    // ) {
    //   this.setState(
    //     {
    //       loading: true,
    //       filteredInfo: {
    //         town: null,
    //         village: null,
    //         forest: null,
    //         county: filters.county ? filters.county : null,
    //         state: filters.state,
    //       },
    //       countyCode: filters.county ? filters.county[0] : '',
    //       townCode: '',
    //       villageCode: '',
    //       forestCode: '',
    //       townList: [],
    //       villageList: [],
    //       forestList: [],
    //       state: filters.state ? filters.state[0] : '',
    //     },
    //     () => {
    //       this.getSelectList();
    //       this.getData();
    //     }
    //   );
    // } else if (
    //   (filters.county && !filters.town && villageList.length !== 0) ||
    //   (filters.county && !filters.town && filters.village) ||
    //   (filters.town &&
    //     filters.village &&
    //     filters.town[0].substr(6, 8) !== this.state.townCode)
    // ) {
    //   this.setState(
    //     {
    //       loading: true,
    //       filteredInfo: {
    //         town: filters.town ? filters.town : null,
    //         village: null,
    //         forest: null,
    //         county: filters.county,
    //         state: filters.state,
    //       },
    //       townCode: filters.town ? filters.town[0].substr(6, 8) : '',
    //       villageCode: '',
    //       forestCode: '',
    //       villageList: [],
    //       forestList: [],
    //       state: filters.state ? filters.state[0] : '',
    //     },
    //     () => {
    //       this.getSelectList();
    //       this.getData();
    //     }
    //   );
    // } else if (
    //   (filters.county &&
    //     filters.town &&
    //     !filters.village &&
    //     forestList.length !== 0) ||
    //   (filters.county && filters.town && !filters.village && filters.forest) ||
    //   (filters.town &&
    //     filters.village &&
    //     filters.forest &&
    //     filters.village[0].substr(9, 11) !== this.state.villageCode)
    // ) {
    //   this.setState(
    //     {
    //       loading: true,
    //       filteredInfo: {
    //         town: filters.town,
    //         village: filters.village ? filters.village : null,
    //         forest: null,
    //         county: filters.county,
    //         state: filters.state,
    //       },
    //       villageCode: filters.village ? filters.village[0].substr(9, 11) : '',
    //       forestCode: '',
    //       forestList: [],
    //       state: filters.state ? filters.state[0] : '',
    //     },
    //     () => {
    //       this.getSelectList();
    //       this.getData();
    //     }
    //   );
    // } else {
    //   this.setState({ filteredInfo: filters });
    //   this.setState(
    //     {
    //       loading: true,
    //       countyCode: filters.county
    //         ? filters.county[0]
    //         : this.props.locationState.code,
    //       townCode: filters.town ? filters.town[0].substr(6, 8) : '',
    //       villageCode: filters.village ? filters.village[0].substr(9, 11) : '',
    //       forestCode: filters.forest ? filters.forest[0].slice(12) : '',
    //       state: filters.state ? filters.state[0] : '',
    //     },
    //     () => {
    //       this.getSelectList();
    //       this.getData();
    //     }
    //   );
    // }
  };

  //年度选择
  onYearChanged = (year) => {
    if (year) {
      this.setState(
        {
          year: year,
          loading: true,
        },
        this.getData
      );
    } else
      this.setState(
        {
          year: "",
          loading: true,
        },
        this.getData
      );
  };

  //数据关联
  synchroData = () => {
    const { countyCode } = this.state;
    let that = this;
    //取消请求
    const cancelToken = new axios.CancelToken(function executor(c) {
      cancel = c;
    });
    confirm({
      icon: <ExclamationCircleOutlined rev={undefined} />,
      content: (
        <div id={"sync-data-text"}>
          数据同步功能将本市疫木点位数据和小班关联，请谨慎操作，是否确认同步？
        </div>
      ),
      onOk() {
        that.socket = new WebSocket(
          window["env"].REACT_APP_WS_PLAGUE + "/" + that.state.userId
        );

        // WebSocket 连接打开时的回调
        that.socket.onopen = () => {
          that.setState({
            isSync: true,
          });
        };

        that.socket.onmessage = (event) => {
          let msg = JSON.parse(event.data);

          if (msg) {
            that.setState({
              isSync: false,
            });
            // message.success(msg.pushMessage.message)
            confirm({
              icon: <CheckOutlined rev={undefined} />,
              content: (
                <div id={"sync-data-text"}>{msg.pushMessage.message}</div>
              ),
              onOk() {
                Modal.destroyAll();
              },
              cancelButtonProps: { style: { display: "none" } },
            });
            that.socket.close();
          }
        };

        Modal.destroyAll();
        return PlagueWoodTs.synchroData({ countyCode }, cancelToken).then(
          (res) => {
            if (res.data.code === 500) {
              message.error(res.data.msg);
            }
          }
        );
      },
      onCancel() {
        cancel && cancel();
        message.info("数据关联已取消");
      },
    });
  };

  //删除操作
  onStateDeleate = (id) => {
    PlagueWoodTs.DeleteState(id).then((res) => {
      if (res.data.code === 200) {
        this.setState(
          {
            loading: true,
          },
          this.getData
        );
        message.info("删除成功");
      } else {
        message.error("删除失败");
      }
    });
  };

  //完成操作
  onStateChange = (id, state) => {
    state = 1;
    PlagueWoodTs.PutState({ id, state }).then((res) => {
      if (res.data.code === 200) {
        message.success("已完成");
        this.setState(
          {
            loading: true,
          },
          this.getData
        );
      } else {
        message.error(res.data.msg);
      }
    });
  };

  //修改文件状态
  modifyFileState = (record: any) => {
    // this.onStateChange()
    PlagueWoodTs.PutState({ id: record.id, state: -1 }).then((res) => {
      if (res.data.code === 200) {
        this.setState(
          {
            loading: true,
            isplagueWood: true,
            theRecord: record,
          },
          this.getData
        );
      } else {
        message.error("修改失败");
      }
    });
  };
  changeRecord = () => {
    this.setState({
      theRecord: null,
    });
  };
  render() {
    const {
      isplagueWood,
      isuploadData,
      data,
      countyList,
      townList,
      villageList,
      forestList,
      loading,
      total,
      page,
      size,
      statusBox,
      yearBox,
      theRecord,
      filteredInfo,
      isReading,
      isSync,
    } = this.state;

    const { year } = this.props;
    const { code, position } = this.props.locationState;
    const operation = {
      title: "操作",
      dataIndex: "operation",
      key: "operation",
      width: 150,
      render: (operation, record) =>
        record.state === 1 ? (
          <Space size="middle">
            <a onClick={() => this.modifyFileState(record)}>修改</a>
            <Popconfirm
              title="确定要删除当前记录吗?"
              onConfirm={() => {
                this.onStateDeleate(record.id);
              }}
              okText="确定"
              cancelText="取消"
            >
              <a>删除</a>
            </Popconfirm>
          </Space>
        ) : (
          <>
            <Space size="middle">
              <a onClick={() => this.modifyFileState(record)}>修改</a>
              <a
                onClick={() => {
                  this.onStateChange(record.id, record.state);
                }}
              >
                完成
              </a>
              <Popconfirm
                title="确定要删除当前记录吗?"
                onConfirm={() => {
                  this.onStateDeleate(record.id);
                }}
                okText="确定"
                cancelText="取消"
              >
                <a>删除</a>
              </Popconfirm>
            </Space>
          </>
        ),
    };
    const columns: any = [
      {
        title: "区县",
        dataIndex: "county",
        key: "county",
        filterMultiple: false,
        align: "center",
        filters:
          this.role_list.indexOf(this.role) === -1 &&
          countyList.map((item: any) => ({
            text: item.name,
            value: item.areaCode,
          })),
        filteredValue: filteredInfo.county || null,
      },
      {
        title: "乡镇/街道",
        dataIndex: "town",
        key: "town",
        align: "center",
        filterMultiple: false,
        filters: townList.map((item: any) => ({
          text: item.name,
          value: item.areaCode,
        })),
        filteredValue: filteredInfo.town || null,
      },
      {
        title: "行政村",
        dataIndex: "village",
        key: "village",
        align: "center",
        filterMultiple: false,
        filteredValue: filteredInfo.village || null,
        filters: villageList.map((item: any) => ({
          text: item.name,
          value: item.areaCode,
        })),
      },
      {
        title: "林班",
        dataIndex: "forest",
        key: "forest",
        align: "center",
        filterMultiple: false,
        filteredValue: filteredInfo.forest || null,
        filters: forestList.map((item: any) => ({
          text: item.name,
          value: item.areaCode,
        })),
      },
      {
        title: "小班号",
        dataIndex: "smallClassCode",
        key: "smallClassCode",
        align: "center",
      },
      {
        title: "文件名",
        dataIndex: "fileName",
        key: "fileName",
        ellipsis: true,
      },
      { title: "上传人", dataIndex: "uploadBy", key: "uploadBy" },
      { title: "上传时间", dataIndex: "time", key: "time", width: "200px" },
      {
        title: "状态",
        dataIndex: "state",
        key: "state",
        filterMultiple: false,
        filteredValue: filteredInfo.state || null,
        filters: statusBox.map((item: any) => ({
          text: item.value,
          value: item.key,
        })),
        render: (state) =>
          state === 1 ? (
            <span style={{ color: "#3DBD28" }}>已完成</span>
          ) : (
            <span style={{ color: "rgb(255 0 0 / 65%)" }}>待处理</span>
          ),
      },
    ];
    !isReading && columns.push(operation);
    // columns = isReading ? columns : columns.push(operation);
    const pagination = {
      total,
      current: page,
      pageSize: size,
      showQuickJumper: true,
      responsive: false,
      showSizeChanger: true,
    };
    return (
      <>
        {isplagueWood ? (
          <Map
            isplagueWood={isplagueWood}
            changeWood={this.changeWood}
            record={theRecord}
            areaCode={code}
            year={year}
            position={position}
            changeRecord={this.changeRecord}
            getData={this.upDataGetData}
          />
        ) : (
          <div className={"plagueWood-box"}>
            <div className={"plagueWood-header"}>
              <div>
                <Button
                  type="primary"
                  disabled={isReading}
                  onClick={() => {
                    this.changeWood(false);
                  }}
                >
                  疫木管理
                </Button>
                <Button
                  type="primary"
                  style={{ marginLeft: "5px" }}
                  onClick={() => {
                    this.setState({ isuploadData: true });
                  }}
                  disabled={isReading}
                >
                  文件上传
                </Button>
                <Button
                  type="primary"
                  style={{ marginLeft: "5px" }}
                  disabled={isReading && isSync}
                  onClick={() => {
                    this.synchroData();
                  }}
                >
                  数据关联
                </Button>
              </div>
              <div>
                {/* <Select
									onChange={this.onYearChanged}
									style={{ width: 176 }}
									placeholder="年度"
									allowClear
									defaultValue={year ? year : sessionStorage.getItem('year')}
								>
									{yearBox.map((item) => (
										<Option value={item} key={item}>
											{item}
										</Option>
									))}
								</Select> */}
              </div>
            </div>
            <div className={"plagueWood-table"}>
              <Table
                dataSource={data}
                columns={columns}
                rowKey={(record) => record.id}
                loading={loading}
                pagination={pagination}
                onChange={this.changePagination}
                scroll={{ y: "calc(100vh - 23.5rem)" }}
              />
            </div>
          </div>
        )}
        {/* 文件上传组件 */}
        {isuploadData && (
          <AddUpload
            isuploadData={isuploadData}
            areaCode={code}
            cancleUpload={this.cancleUpload}
            getData={this.upDataGetData}
          />
        )}
      </>
    );
  }
}
