import { Modal, Upload, message } from "antd";
import React from "react";
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { reimbursement_axios } from "../../utils/axios";
const { confirm } = Modal;

interface Props {}

interface State {}

class Reimbursement extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      fileList: [],
    };
  }

  upload = (file) => {
    let that = this;
    confirm({
      title: "确认上传",
      icon: <ExclamationCircleOutlined rev={undefined} />,
      content: "您确定要上传文件吗？",
      onOk() {
        const data = new FormData();
        data.append("file", file);
        reimbursement_axios.defaults.headers.common["Authorization"] =
          "Bearer " + JSON.parse(localStorage.getItem("token")).access_token;
        reimbursement_axios.post("/common/excel_upload", data).then((res) => {
          if (res.status === 200) {
            that.setState({
              fileList: [],
            });
            message.success("文件上传成功");
            // console.log(res.data);
          } else {
            message.error("文件上传失败，请联系管理员");
          }
        });
      },
      onCancel() {
        // 用户点击取消，不执行上传操作
        // console.log('取消上传');
        let list = that.state.fileList;
        that.setState({
          fileList: list.filter((item) => item.uid !== file.uid),
        });
        // this.setState((prevState) => ({
        // 	fileList: prevState.fileList.filter((item) => item.uid !== file.uid),
        // }));
      },
    });
  };

  render() {
    const props: any = {
      fileList: this.state.fileList,
      listType: "picture-card",
      beforeUpload: (file, fileList) => {
        this.upload(file);
        return false;
      },
      maxCount: 1,
      accept: ".xlsx,.xls",
      showUploadList: {
        showPreviewIcon: false,
        showRemoveIcon: true,
        showDownloadIcon: false,
      },
    };
    return (
      <div className={"excel-box"}>
        <div className="upload-box">
          <Upload {...props}>
            <PlusOutlined rev={undefined} />
          </Upload>
        </div>
        <div className="download-box">报销单上传，请上传excel</div>
      </div>
    );
  }
}

export default Reimbursement;
