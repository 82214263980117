import { useEffect, useState } from "react";
import Request from "../../../services/Manger";
import SplitLine from "../../../component/tools/SplitLine";
import back from "../../../assets/images/UnAcceptance.png";
import BaseCard from "../../../component/Card/BaseCard";
import Progress from "../../../component/tools/Progress";
import { LoadingOutlined } from "@ant-design/icons";
import { chineseBigNum } from "../data";
import { Spin } from "antd";
// 验收不合格小班
const antIcon = (
  <LoadingOutlined rev={undefined} style={{ fontSize: 24 }} spin />
);
export default function UnAcceptance({ props }) {
  const { code, level, theName, year } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Request.UnAcceptance({ areaCode: code, year }, (res) => {
      if (res.code === 200) {
        res.data[0] ? setData(res.data) : setData([]);
        setLoading(false);
      }
    });
  }, [code, level, year]);

  return (
    <BaseCard
      title={`${theName}·验收不合格小班`}
      describe={"UNQUALIFIED"}
      height={220}
      backround={back}
    >
      <div className="UnAcceptance">
        {loading ? (
          <div className="UnAcceptance_Spin">
            <Spin indicator={antIcon} spinning={loading} />
          </div>
        ) : (
          <>
            {data.length === 0 ? (
              <div className="UnAcceptance_nodata">暂无数据</div>
            ) : (
              <>
                <div className="UnAcceptance_unit">单位：%</div>
                <SplitLine height={117} width={232}>
                  {data.map((item, index) => (
                    <div className="UnAcceptance_content" key={index}>
                      <span className="county">
                        第{chineseBigNum[item.frequency]}轮
                      </span>
                      <Progress
                        progress={item.pre}
                        distance={true}
                        colorLeft="#FD4444"
                        style={{ width: "304px" }}
                      />
                      <span className="number">{item.pre}</span>
                    </div>
                  ))}
                </SplitLine>
              </>
            )}
          </>
        )}{" "}
      </div>
    </BaseCard>
  );
}
