import { useEffect, useState } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import Request from "../../../services/Manger";
import back from "../../../assets/images/line3.png";
import BaseCard from "../../../component/Card/BaseCard";
// 丽水市·疫情划分统计
export default function DivisStatistics({ props }) {
  const { code, level, theName, year } = props;
  let [list, setList] = useState([
    {
      color: "#FEAC00",
      degree: "轻度：",
      lowLevel: 0,
      title: "lowLevel",
    },
    {
      color: "#FD8347",
      degree: "中度：",
      midLevel: 0,
      title: "midLevel",
    },
    {
      color: "#D81F1E",
      degree: "重度：",
      highLevel: 0,
      title: "highLevel",
    },
  ]);
  const [total, setTotal] = useState(6);
  useEffect(() => {
    Request.DegreeInfection(
      { areaCode: code, areaLevel: level, year },
      (res) => {
        const data = res.data;
        if (res.code === 200) {
          list.map((item) => {
            item[item.title] = data[item.title];
          });
          setTotal(data.lowLevel + data.midLevel + data.highLevel);
          setList(list);
        }
      }
    );
  }, [code, level, list, year]);
  return (
    <BaseCard
      title={`${theName}·疫情划分统计`}
      describe={"DIVISION STATISTICS"}
      height={146}
      backround={back}
    >
      <div className="DivisStatistics">
        <div className="statistics_degree">
          <span>感染程度</span>
          <span>
            划定标准
            <QuestionCircleOutlined rev={undefined} />
          </span>
          <span>单位：万亩</span>
        </div>
        <div className="statistics_progress">
          {total ? (
            <>
              {list.map((item, index) => (
                <div
                  style={{
                    width: `${(item[item.title] / total) * 100}%`,
                    background: item.color,
                  }}
                  key={index}
                ></div>
              ))}
            </>
          ) : (
            <div style={{ width: "100%", backgroundColor: " #404241" }}></div>
          )}
        </div>
        <div className="statistics_level">
          {list.map((item, i) => {
            return (
              <div key={i}>
                <div
                  style={{ backgroundColor: `${item.color}` }}
                  className="statistics_level_block"
                ></div>
                <p>{item.degree}</p>
                <span>{(item[item.title] / 10000).toFixed(2)}</span>
              </div>
            );
          })}
        </div>
      </div>
    </BaseCard>
  );
}
