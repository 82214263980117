import React, { Component } from "react";
import "./parse.scss";
import { Modal, Form, Table, Button, Upload, message } from "antd";
import { InboxOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { handleImage3 } from "../../utils/handlePic";
import { downloadShuiyin } from "../../utils/watermark";
import JSZip from "jszip";
import FileSaver from "file-saver";
const { Dragger } = Upload;
const { confirm } = Modal;
const FormItem = Form.Item;
const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 15 },
};

export default class UploadImg extends Component<any> {
  state = {
    isUploading: false, //是否上传完成
    fileList: [],
    parseList: [],
    secondVisible: false,
  };

  //导出
  export = () => {
    const { fileList } = this.state;
    message.info({
      content: "图片正在压缩导出，请稍等...",
      key: "export",
      duration: 0,
    });
    this.toZip(
      fileList,
      fileList.map((e) => e.name)
    );
  };

  toZip = (imgSrcList, fileName) => {
    //筛选要分类的文件名，由于文件名可能出现重复，所以筛选去重(这里是根据地理位置分类命名文件名)
    let ExportTitle = imgSrcList.map((item) => {
      return item.classInfo ? item.classInfo : "暂无小班定位信息";
    });
    let newExportTitle = ExportTitle.filter((item, index, arr) => {
      return arr.indexOf(item) == index;
    });
    let zip = new JSZip(); //实例化一个压缩文件对象
    let myNotesName = "导出结果";
    let imgFolder = newExportTitle.map((e) => {
      return zip.folder(e); //新建一个图片文件夹用来存放图片，参数为文件名
    });

    for (let i = 0; i < imgSrcList.length; i++) {
      let image = imgSrcList[i].shuiYinFile;
      let imageNames = `${fileName[i]}.png`;
      imgFolder.forEach((element) => {
        if (imgSrcList[i].classInfo === element.root.replace("/", "")) {
          element.file(imageNames, image, { base64: false });
        } else if (element.root.replace("/", "") === "暂无小班定位信息") {
          element.file(imageNames, image, { base64: false });
        }
      });
    }
    zip
      .generateAsync({
        type: "blob", //压缩类型
        compression: "DEFLATE", // STORE：默认不压缩 DEFLATE：需要压缩
        compressionOptions: {
          level: 1, // 压缩等级1~9    1压缩速度最快，9最优压缩方式  // [使用一张图片测试之后1和9压缩的力度不大，相差100字节左右]
        },
      })
      .then(function (content) {
        message.success({
          content: "压缩包导出成功",
          key: "export",
          duration: 3,
        });
        FileSaver.saveAs(content, `${myNotesName}.zip`);
      });
  };

  //二次确认上传回调
  secondOnFinish = (value) => {
    message.info({
      content: "图片正在解析中,请稍等一会",
      key: "img",
      duration: 0,
    });
    this.setState({
      fileList: [],
    });
    const { file } = value;
    const cityName = sessionStorage.getItem("cityName");
    handleImage3(
      file
        ? file.fileList.map((item) => {
            return {
              name: item.name,
              file: item.originFileObj,
              thumbUrl: item.thumbUrl,
              cityName: cityName,
            };
          })
        : []
    ).then((res: any) => {
      const value = res.filter((item) => {
        return item.point.x && item.point.y;
      });
      res = res.map((item, index) => {
        return item.point.x && item.point.y
          ? { ...item, uid: `pic_${index}`, status: "done", url: item.thumbUrl }
          : {
              ...item,
              uid: `pic_${index}`,
              status: "error",
              url: item.thumbUrl,
            };
      });
      const promise = new Promise((resolve, reject) => {
        let newList = [];
        res.map((item) => {
          if (
            item.file.name.toUpperCase().startsWith("DJI") &&
            item.file &&
            item.point.x &&
            item.point.y
          ) {
            downloadShuiyin(item).then((data) => {
              newList.push({ ...item, shuiYinFile: data });
              if (newList.length == res.length) {
                resolve(newList);
              }
            });
          } else {
            newList.push({ ...item });
            if (newList.length == res.length) {
              resolve(newList);
            }
          }
        });
      });
      promise.then((list: any) => {
        this.setState(
          {
            isUploading: true,
            parseList: list,
            fileList: list,
          },
          () => {
            message.success({
              content: `图片全部解析完成，共${res.length}张图片，成功${
                value.length
              }张，失败${res.length - value.length}张`,
              key: "img",
              duration: 3,
            });
          }
        );
      });
    });
  };
  onFinish = (value) => {
    confirm({
      title: "请核对图片信息，是否确认解析",
      icon: <ExclamationCircleOutlined rev={undefined} />,
      style: {
        position: "relative",
        marginTop: "10%",
        marginLeft: "45%",
      },
      onOk: () => {
        this.secondOnFinish(value);
      },
      onCancel() {},
    });
  };

  render() {
    const { parseList, fileList, isUploading } = this.state;
    const props: any = {
      // action: window["env"].REACT_APP_API_COUNTY_URL + '/upload',
      listType: "picture-card",
      // headers: {
      //     authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')).access_token
      // },
      data: (file) => {
        return {
          type: 6,
        };
      },
      fileList: fileList,
      onChange: (value) => {
        this.setState({
          fileList: value.fileList,
        });
      },
      // maxCount: 30,
      multiple: true,
      beforeUpload: () => {
        return false;
      },
      accept: ".png,.jpg,.jpeg",
      showUploadList: {
        showPreviewIcon: false,
        showRemoveIcon: true,
        showDownloadIcon: false,
      },
    };

    const column = [
      {
        title: "序号",
        dataIndex: "index",
        key: "index",
        render: (text, record, index) => index + 1,
      },
      {
        title: "文件名称",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "经纬度",
        dataIndex: "lnglat",
        key: "lnglat",
        width: 200,
        render: (text, record) => (
          <span>
            {record.point.x
              ? `${record.point.x},${record.point.y}`
              : "照片无定位信息"}
          </span>
        ),
      },
      {
        title: "位置信息",
        dataIndex: "address",
        key: "address",
        width: 200,
        render: (text, record) => (
          <span style={{ color: record.classInfo ? "black" : "red" }}>
            {record.classInfo ? record.classInfo : "照片暂无小班定位信息"}
          </span>
        ),
      },
    ];
    return (
      <>
        {/* <Form title={'上传照片'}
                    className='uploadModal'
                > */}
        <div className="uploadModal">
          {isUploading ? (
            <div>
              <Table
                dataSource={parseList}
                columns={column}
                scroll={{ y: 300 }}
                pagination={false}
                rowKey={(record) => record.uid}
              />
              <div
                style={{
                  width: "60%",
                  margin: "0 auto",
                  display: "flex",
                  justifyContent: "space-evenly",
                  marginTop: "10px",
                }}
              >
                <Button
                  type="primary"
                  onClick={() => {
                    this.export();
                  }}
                >
                  导出
                </Button>
                <Button
                  onClick={() => {
                    this.setState({
                      isUploading: false,
                      parseList: [],
                      fileList: [],
                    });
                  }}
                >
                  取消
                </Button>
              </div>
            </div>
          ) : (
            <Form onFinish={this.onFinish}>
              <FormItem
                name="file"
                label="现场照片"
                rules={[
                  {
                    required: true,
                    message: "请上传现场照片",
                  },
                ]}
                {...layout}
              >
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined rev={undefined} />
                  </p>
                  <p className="ant-upload-text">上传现场照片</p>
                  {/* <p className="ant-upload-hint">
                                        单次最多30张
                                    </p> */}
                </Dragger>
              </FormItem>
              <div className="uploadButton">
                <FormItem>
                  <Button type="primary" htmlType="submit">
                    确认
                  </Button>
                  <Button
                    onClick={() => {
                      this.setState({
                        fileList: [],
                      });
                    }}
                  >
                    取消
                  </Button>
                </FormItem>
              </div>
            </Form>
          )}
          {/* </Form> */}
        </div>
      </>
    );
  }
}
