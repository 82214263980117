import React, { Component } from "react";
import {
  Modal,
  Form,
  Select,
  Input,
  Button,
  DatePicker,
  Upload,
  message,
  Space,
  Image,
} from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import Acceptance from "../../platform/services/Acceptance";
import { handleImage, uploadImage2 } from "../../utils/handlePic";
import moment from "moment";

const { Option } = Select;
const { TextArea } = Input;
const FormItem = Form.Item;
const dateFormat = "YYYY-MM-DD HH:mm:ss";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

interface UpdateProps {
  visible: boolean;
  changeVisible: () => void;
  year: any;
  record: any;
  getData: () => void;
  isInspection: number;
  countyCode: any;
}

interface UpdateState {
  picCutList: Array<any>;
  picDroneList: Array<any>;
  recordNew: any;
  loading: Boolean;
  isOther: Boolean;
  situationOption: Array<any>;
  imageVisible: boolean;
  imageUrl: string;
}

// 验收+监理数据的修改
export class Update extends Component<UpdateProps, UpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      picCutList: [],
      picDroneList: [],
      recordNew: this.props.record,
      loading: false,
      isOther: false,
      situationOption: [],
      imageVisible: false,
      imageUrl: "",
    };
  }

  componentDidMount() {
    const { record } = this.props;
    this.getSunScoreData();
    let situationList = [];
    situationList =
      record.demeritPoints &&
      Object.entries(record.demeritPoints).map(([key, value]) => ({
        first: key,
        last: value,
      }));
    this.setState(
      {
        recordNew: {
          ...record,
          situationList,
        },
      },
      () => {
        this.setState({
          loading: true,
        });
      }
    );
    let list1 = [];
    let list2 = [];
    Acceptance.getPicList({
      id: record.id,
      isInspection: this.props.isInspection,
      year: this.props.year,
    }).then((res) => {
      if (res.data.code === 200) {
        res.data.data.map((item, index) => {
          const content = {
            uid: "1_" + index.toString(),
            status: "done",
            origin: true,
            url: item.url,
            address: item.address,
            altitude: item.altitude,
            point: item.point,
            time: item.time,
          };
          if (item.type === 1) {
            list1.push(content);
          } else {
            list2.push(content);
          }
          if (index === res.data.data.length - 1) {
            this.setState({
              picCutList: list1,
              picDroneList: list2,
            });
          }
        });
      }
    });
  }

  // 修改完成
  onFinish = (value) => {
    const { picCutList, picDroneList } = this.state;
    const { record, changeVisible, getData, countyCode } = this.props;
    value.countyCode = record.countyCode;
    let situation = {};
    value.situationList &&
      value.situationList.map((e) => {
        situation[e.first] = e.last;
      });
    value.demeritPoints = situation;
    message.info({
      content: "图片正在解析中,请稍等一会",
      key: "img",
      duration: 0,
    });
    const list1 = picCutList.map((item) => {
      return item.origin
        ? { ...item, type: 1, thumbUrl: item.url, isCheck: true }
        : {
            ...item,
            type: 1,
            file: item.originFileObj,
            thumbUrl: item.thumbUrl,
            isCheck: true,
          };
    });
    const list2 = picDroneList.map((item) => {
      return item.origin
        ? { ...item, type: 2, thumbUrl: item.url, isCheck: true }
        : {
            ...item,
            type: 2,
            file: item.originFileObj,
            thumbUrl: item.thumbUrl,
            isCheck: true,
          };
    });
    handleImage(list1).then((res1: any) => {
      handleImage(list2).then((res2: any) => {
        message.destroy();
        const total1 = res1.filter((item) => {
          return !item.origin;
        }).length;
        const total2 = res2.filter((item) => {
          return !item.origin;
        }).length;
        const success1 = res1.filter((item) => {
          return !item.origin && item.point.x && item.point.y;
        }).length;
        const success2 = res2.filter((item) => {
          return !item.origin && item.point.x && item.point.y;
        }).length;
        if (total1 !== 0 || total2 !== 0) {
          message.success({
            content: (
              <div>
                <div>图片全部解析完成！其中：</div>
                <div>
                  伐桩图片修改共{total1}张，解析成功{success1}张，失败
                  {total1 - success1}张
                </div>
                <div>
                  伐桩图片共{total2}张，解析成功{success2}张，失败
                  {total2 - success2}张
                </div>
              </div>
            ),
            duration: 3,
            key: "img",
          });
        }
        uploadImage2(res1).then((res3) => {
          uploadImage2(res2).then((res4) => {
            message.success("图片上传成功");
            value.id = record.id;
            value.picCutList = res3;
            value.picDroneList = res4;
            value.isInspection = this.props.isInspection;
            value.year = sessionStorage.getItem("year");
            // console.log(value)
            if (window.location.href.indexOf("check") > 0) {
              Acceptance.updateCheckData(value).then((res) => {
                if (res.data.code === 200) {
                  message.success("修改记录成功");
                  changeVisible();
                  getData();
                } else {
                  message.error(res.data.msg);
                }
              });
            } else {
              Acceptance.updateData(value).then((res) => {
                if (res.data.code === 200) {
                  message.success("修改记录成功");
                  changeVisible();
                  getData();
                } else {
                  message.error(res.data.msg);
                }
              });
            }
          });
        });
      });
    });
  };

  getSunScoreData = () => {
    const { year, record } = this.props;
    let param = {
      year,
      countyCode: record.countyCode,
    };
    Acceptance.getSubScore(param).then((res) => {
      console.log(res);
      if (res.data.code === 200) {
        if (res.data.data.length > 0) {
          let arr = [];
          res.data.data.forEach((e) => {
            arr.push({
              value: e,
              label: e.substring(0, 15),
            });
          });
          this.setState({
            situationOption: arr,
          });
        }
      }
    });
  };

  onPhotoChange = (value, key) => {
    if (key === 1) {
      this.setState({
        picCutList: value.fileList,
      });
    } else {
      this.setState({
        picDroneList: value.fileList,
      });
    }
  };

  render() {
    const { visible, changeVisible, record } = this.props;
    const {
      picCutList,
      picDroneList,
      recordNew,
      loading,
      situationOption,
      imageVisible,
      imageUrl,
    } = this.state;
    const props = {
      listType: "picture-card",
      beforeUpload: () => {
        return false;
      },
      maxCount: 50,
      multiple: true,
      accept: ".png,.jpg,.jpeg",
      showUploadList: {
        showPreviewIcon: true,
        showRemoveIcon: true,
        showDownloadIcon: false,
      },
      onPreview: (file) => {
        this.setState({
          imageVisible: true,
          imageUrl: file.url ? file.url : file.thumbUrl,
        });
      },
    };
    const props1: any = {
      ...props,
      fileList: picCutList,
      onChange: (value) => {
        this.onPhotoChange(value, 1);
      },
    };
    const props2: any = {
      ...props,
      fileList: picDroneList,
      onChange: (value) => this.onPhotoChange(value, 2),
    };
    const customOptionRender = (option) => (
      <div>
        <span title={option.value}>{option.label}</span>
      </div>
    );
    // const situationOption = [
    //   { value: '未清理枝条（≥1cm）', label: '未清理枝条（≥1cm）' },
    //   { value: '上传数字森防不达标', label: '上传数字森防不达标' },
    //   { value: '树桩高度超过5cm', label: '树桩高度超过5cm' },
    //   { value: '未清理树干（≥6cm）', label: '未清理树干（≥6cm）' },
    //   { value: '整株疫木未砍伐', label: '整株疫木未砍伐' },
    //   { value: '藏匿树枝树干', label: '藏匿树枝树干' },
    // ]
    return (
      <>
        {loading && (
          <Modal
            title="修改"
            visible={visible}
            footer={null}
            onCancel={changeVisible}
            centered={true}
            className={"acceptance-update-box"}
          >
            <Form
              onFinish={this.onFinish}
              initialValues={{
                ...recordNew,
                time: moment(record.time, dateFormat),
              }}
            >
              <FormItem
                name="result"
                label="验收结果"
                rules={[
                  {
                    required: true,
                    message: "请选择验收结果",
                  },
                ]}
                {...layout}
              >
                <Select>
                  <Option value={1}>验收通过</Option>
                  <Option value={0}>验收不通过</Option>
                </Select>
              </FormItem>
              <FormItem
                name="time"
                label="验收时间"
                rules={[
                  {
                    required: true,
                    message: "请选择验收时间",
                  },
                ]}
                {...layout}
              >
                <DatePicker showTime disabled />
              </FormItem>
              <FormItem
                label="伐桩图片"
                name="picCutList"
                rules={[
                  {
                    required: false,
                    message: "请选择伐桩图片",
                  },
                ]}
                {...layout}
              >
                <Upload {...props1}>
                  <div>
                    <PlusOutlined rev={undefined} />
                    <div style={{ marginTop: 8 }}>上传</div>
                  </div>
                </Upload>
              </FormItem>
              <FormItem
                label="航拍图片"
                name="picDroneList"
                rules={[
                  {
                    required: false,
                    message: "请选择航拍图片",
                  },
                ]}
                {...layout}
              >
                <Upload {...props2}>
                  <div>
                    <PlusOutlined rev={undefined} />
                    <div style={{ marginTop: 8 }}>上传</div>
                  </div>
                </Upload>
              </FormItem>
              {window.location.href.indexOf("check") > 0 && (
                <>
                  <FormItem
                    style={{
                      height: "32px",
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                    name="surveyPileNum"
                    label="调查伐桩数"
                    rules={[{ required: true, message: "请填写数量" }]}
                  >
                    <Input
                      placeholder="请输入调查伐桩数"
                      type="number"
                      addonAfter="个"
                    ></Input>
                  </FormItem>
                  <FormItem
                    style={{
                      height: "32px",
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                    name="failedPileNum"
                    label="不合格伐桩数"
                    rules={[{ required: true, message: "请填写数量" }]}
                  >
                    <Input
                      placeholder="请输入不合格伐桩数"
                      type="number"
                      addonAfter="个"
                    ></Input>
                  </FormItem>
                  <FormItem
                    style={{
                      height: "32px",
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                    name="twigRetentionNum"
                    label="枝桠滞留数"
                    rules={[{ required: true, message: "请填写数量" }]}
                  >
                    <Input
                      placeholder="请输入枝桠滞留数"
                      type="number"
                      addonAfter="个"
                    ></Input>
                  </FormItem>
                </>
              )}

              {situationOption.length > 0 ? (
                <Form.List name="situationList">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: "flex",
                            marginBottom: "8px",
                            height: "40px",
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "last"]}
                            rules={[{ required: true, message: "请填写内容" }]}
                          >
                            <Input
                              min="1"
                              type={this.state.isOther ? "string" : "number"}
                              style={{
                                position: "relative",
                                top: `${this.state.isOther ? "0" : "5px"}`,
                              }}
                              addonAfter={this.state.isOther ? "" : "处"}
                              placeholder="请填写内容"
                            />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            name={[name, "first"]}
                            rules={[{ required: true, message: "请选择描述" }]}
                          >
                            <Select
                              onChange={(e) => {
                                e === "其他"
                                  ? this.setState({
                                      isOther: true,
                                    })
                                  : this.setState({
                                      isOther: false,
                                    });
                              }}
                              placeholder="请选择描述"
                            >
                              {[
                                ...situationOption,
                                { value: "其他", label: "其他" },
                              ].map((option) => (
                                <Option
                                  key={option.value}
                                  value={option.value}
                                  title={option.value}
                                >
                                  {option.label}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>

                          <MinusCircleOutlined
                            rev={undefined}
                            onClick={() => remove(name)}
                          />
                        </Space>
                      ))}
                      <Form.Item style={{ marginTop: "30px" }}>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined rev={undefined} />}
                        >
                          添加描述
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              ) : (
                <FormItem name="situation" label="情况描述">
                  <Input
                    placeholder="请输入情况"
                    defaultValue={recordNew.situation}
                  ></Input>
                </FormItem>
              )}

              <div className="form-button">
                <FormItem>
                  <Button type="primary" htmlType="submit">
                    确定
                  </Button>
                  <Button onClick={() => changeVisible()}>取消</Button>
                </FormItem>
              </div>
            </Form>
          </Modal>
        )}
        <Image
          style={{ display: "none" }}
          preview={{
            visible: imageVisible,
            src: imageUrl,
            onVisibleChange: (value) => {
              this.setState({
                imageVisible: value,
              });
            },
          }}
        />
      </>
    );
  }
}
