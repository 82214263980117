import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Image } from "antd";
import { useState } from "react";

interface Props {
  url: string;
  type: number;
  isNewFile: boolean;
  removeClick: () => void;
}

const ImageShow = ({ url, type, isNewFile, removeClick }: Props) => {
  const [visible, setVisible] = useState(false);

  const [toolVisible, setToolVisible] = useState(false);

  const showClick = (e) => {
    e.stopPropagation();
    setVisible(true);
  };

  const onRemove = (e) => {
    e.stopPropagation();
    removeClick();
  };

  return (
    <div className="image_show_box">
      <div className="thumbnails_box">
        <img src={url} alt="" onClick={() => setToolVisible(true)} />
        {toolVisible && (
          <div
            className={`tool_box ${type === 2 && "tool_style_2"}`}
            onClick={() => setToolVisible(false)}
          >
            <EyeOutlined rev={undefined} onClick={showClick} />
            {isNewFile && <DeleteOutlined rev={undefined} onClick={onRemove} />}
          </div>
        )}
      </div>
      <Image
        style={{ display: "none" }}
        preview={{
          visible,
          src: url,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      />
    </div>
  );
};

export default ImageShow;
