import { acceptance_axios as axios } from "../../utils/axios";
export default class ScoreService {
  //查询年度配置列表
  static getList(params) {
    return axios.get("/mark_institution/query", { params });
  }

  //全量新增接口
  static putAddOffFull() {
    return axios.put("/configration_year/add_of_full_volume");
  }

  //根据id查询年度配置
  static getListById(params) {
    return axios.get("/configration_year/query_by_id/" + params);
  }

  //删除年度配置
  static delYearById(id) {
    return axios.delete(`/mark_institution/delete/${id}`);
  }

  //批量删除年度配置
  static delYearByIds(params) {
    return axios.post("/mark_institution/batch_delete", params);
  }

  //新增年度配置
  static addScoreItem(params) {
    return axios.post("/mark_institution/add", params);
  }

  // 修改年度配置
  static editScoreItem(params) {
    return axios.post("/mark_institution/update", params);
  }
}
