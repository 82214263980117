import * as echarts from "echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/pie";
import "echarts/lib/component/grid";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";

const markPoint = {
  data: [
    {
      type: "max", //最大值
    },
    {
      type: "min", //最小值
    },
  ],
};
const colors = ["#2EC7C9", "#D6737A"]; //两种状态的颜色
export default class charts {
  static myCharts = [];

  //折线图参数
  static lineoption = {
    title: {
      text: "平台操作次数",
      subtext: "单位/次",
    },
    tooltip: {
      trigger: "axis",
      order: "valueDesc", //降序
    },
    legend: {
      data: [],
    },
    grid: {
      left: "3%",
      right: "8%",
      bottom: "3%",
      containLabel: true,
    },
    toolbox: {
      feature: {
        // saveAsImage: {}
      },
    },
    xAxis: {
      name: "时间/时",
      type: "category",
      boundaryGap: false,
      data: ["00:00", "04:00", "08:00", "12:00", "16:00", "20:00", "24:00"],
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: "暂无数据",
        type: "line",
        data: [0, 0, 0, 0, 0, 0, 0],
        markPoint: markPoint,
      },
    ],
  };

  //扇形图参数
  static fanoption = {
    title: {
      text: "平台功能使用比例",
      subtext: "单位/次",
      left: "center",
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "vertical",
      left: "right",
      data: ["跟踪验收", "监理检查"],
      selected: {
        跟踪验收: false,
        监理检查: false,
      },
    },
    series: [
      {
        name: "使用比例",
        type: "pie",
        radius: "50%",
        data: [
          { value: 0, name: "跟踪验收" },
          { value: 0, name: "监理检查" },
        ],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  //工作统计柱状图
  static baroption = {
    title: {
      text: "工作统计数表(小班总数)",
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      order: "seriesDesc",
    },
    legend: {
      data: ["照片数量"],
      selected: {
        照片数量: false,
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: [],
      axisLabel: {
        interval: 0,
      },
    },

    yAxis: [
      {
        type: "value",
      },
    ],
    series: [
      {
        name: "验收通过",
        stack: "Ad",
        type: "bar",
        barGap: "-100%",
        // emphasis: {
        // 	focus: 'series'
        // },
        barWidth: "25%",
        data: [0, 0, 0, 0, 0, 0],
      },
      {
        name: "验收不通过",
        stack: "Ad",
        type: "bar",
        barGap: "-100%",
        barWidth: "25%",
        data: [0, 0, 0, 0, 0, 0],
        itemStyle: {
          normal: {
            color: "#f85013", //柱子的颜色
          },
        },
      },
      {
        name: "小班总数",
        type: "bar",
        barGap: "-100%", //实现两个数据在一个柱子上面显示
        label: {
          show: true,
          position: "top",
        },
        data: [0, 0, 0, 0, 0, 0],
        barWidth: "25%",
        itemStyle: {
          normal: {
            color: "rgba(128, 128, 128, 0)", //柱子的颜色
          },
        },
      },
      {
        name: "照片数量",
        type: "bar",
        label: {
          show: true,
          position: "top",
        },
        barWidth: "25%",
        data: [0, 0, 0, 0, 0, 0],
      },
    ],
  };

  //加班甘特图
  static customoption = {
    title: {
      text: "工作时间分布",
    },
    tooltip: {
      trigger: "item",
      order: "seriesDesc",
      formatter: (params) => {
        // console.log(params.value);
        if (params.value) {
        }
        const new_value = params.value.slice(1, 3).map((item) => {
          if (item) {
            if (item.indexOf("2000-01-01") !== -1) {
              if (item.indexOf("null") !== -1) {
                return "null";
              } else {
                return item.slice(10);
              }
            } else {
              return item;
            }
          } else {
            return item;
          }
        });
        let title = "";
        let titleCensusClassNum = "";
        let kil_duration = "";
        const data = params.value[3];
        const col_visible = Boolean(params.data?.itemStyle?.color);
        const seriesName = col_visible ? "里程耗时" : params.seriesName;
        console.log(params);
        switch (params.data.type) {
          case "inner":
            title = `(${data.title + data.classNum + data.unit})`;
            titleCensusClassNum = `(${
              params.value[4].title +
              params.value[4].censusClassNum +
              params.value[4].unit
            })`;
            break;
          case "outer":
            title = col_visible
              ? `(地点：${data.newAddress})`
              : `(地点：${data.newAddress}，${
                  data.title + data.picNum + data.unit
                })`;
            const visble = data.duration || data.kil;
            kil_duration = visble
              ? `<div>
						${params.marker + "耗时（min）："} 
						<span>${data.duration ?? 0}</span>
						 </div>
						 <div>
						${params.marker + "里程（km）："} 
						<span>${data.kil ?? 0}</span>
						 </div>
						 `
              : "";
            break;
          case "over":
            title = `（${data.title}）`;
            break;
          default:
            break;
        }

        return `<div>
				<div>
				${params.name}
				<span style="margin-left:10px">${title}</span>
        <span style="margin-left:10px">${titleCensusClassNum ?? ""}</span>
				</div>
				${kil_duration}
				<div>
				${params.marker + seriesName} 
				<span style="margin-left:10px">${new_value.join("~")}</span>
				</div>
				</div>`;
      },
    },
    legend: {
      data: ["内业工作", "外业工作", "加班时长"],
      selectedMode: false,
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    axisPointer: {
      triggerOn: "none",
    },
    xAxis: {
      type: "time",
      splitNumber: 10,
      min: "dataMin",
      // boundaryGap: ['15%', '10%'],
      axisLine: {
        show: true,
      },
      axisTick: {
        show: true,
      },
      axisLabel: {
        // showMinLabel: true,
        // formatter: function (val) {
        // 	var date = new Date(val)
        // 	var texts = [date.getHours(), date.getMinutes()]
        // 	return texts.join(":")
        // }
      },
      axisPointer: {
        triggerTooltip: false,
        lineStyle: {
          color: "#7581BD",
          width: 2,
        },
        label: {
          show: true,
          formatter: function (params: any) {
            return echarts.format.formatTime("hh:mm:ss", params.value);
          },
          // backgroundColor: '#7581BD'
        },
        handle: {
          show: true,
          color: "#7581BD",
          size: 10,
        },
      },
    },
    yAxis: {
      type: "category",
      axisTick: {
        show: false,
      },
      splitLine: {
        show: true,
      },
      data: [],
    },
    series: [
      {
        type: "custom",
        name: "内业工作",
        renderItem: function (params, api) {
          const offset_y = charts.getOffset_Y((window as any).echarts_allNum);
          //开发者自定义的图形元素渲染逻辑，是通过书写 renderItem 函数实现的
          var categoryIndex = api.value(0); //这里使用 api.value(0) 取出当前 dataItem 中第一个维度的数值。
          var start = api.coord([api.value(1), categoryIndex]); // 这里使用 api.coord(...) 将数值在当前坐标系中转换成为屏幕上的点的像素值。
          var end = api.coord([api.value(2), categoryIndex]);
          var height = 13; //柱体宽度
          return {
            type: "rect",
            // 表示这个图形元素是矩形。还可以是 'circle', 'sector', 'polygon' 等等。
            shape: echarts.graphic.clipRectByRect(
              {
                // 矩形的位置和大小。
                x: start[0],
                y: start[1] + offset_y,
                width: end[0] - start[0],
                height: height,
              },
              {
                // 当前坐标系的包围盒。
                x: params.coordSys.x,
                y: params.coordSys.y,
                width: params.coordSys.width,
                height: params.coordSys.height,
              }
            ),
            style: api.style(),
            transition: "shape",
            enterFrom: {
              style: { opacity: 0 },
            },
          };
        },
        encode: {
          x: [1, 2],
          y: 0,
        },
        itemStyle: {
          opacity: 0.6,
        },
        data: [],
      },
      {
        type: "custom",
        name: "外业工作",
        renderItem: function (params, api) {
          const offset_y = charts.getOffset_Y((window as any).echarts_allNum);
          //开发者自定义的图形元素渲染逻辑，是通过书写 renderItem 函数实现的
          var categoryIndex = api.value(0); //这里使用 api.value(0) 取出当前 dataItem 中第一个维度的数值。
          var start = api.coord([api.value(1), categoryIndex]); // 这里使用 api.coord(...) 将数值在当前坐标系中转换成为屏幕上的点的像素值。
          var end = api.coord([api.value(2), categoryIndex]);
          var height = 13; //柱体宽度
          return {
            type: "rect",
            // 表示这个图形元素是矩形。还可以是 'circle', 'sector', 'polygon' 等等。
            shape: echarts.graphic.clipRectByRect(
              {
                // 矩形的位置和大小。
                x: start[0],
                y: start[1] - offset_y * 3.1,
                width: end[0] - start[0],
                height: height,
              },
              {
                // 当前坐标系的包围盒。
                x: params.coordSys.x,
                y: params.coordSys.y,
                width: params.coordSys.width,
                height: params.coordSys.height,
              }
            ),
            style: api.style(),
            transition: "shape",
            enterFrom: {
              style: { opacity: 0 },
            },
          };
        },
        encode: {
          x: [1, 2],
          y: 0,
        },
        itemStyle: {
          opacity: 0.6,
        },
        data: [],
      },
      {
        type: "custom",
        name: "加班时长",
        renderItem: function (params, api) {
          const offset_y = charts.getOffset_Y((window as any).echarts_allNum);
          //开发者自定义的图形元素渲染逻辑，是通过书写 renderItem 函数实现的
          var categoryIndex = api.value(0); //这里使用 api.value(0) 取出当前 dataItem 中第一个维度的数值。
          var start = api.coord([api.value(1), categoryIndex]); // 这里使用 api.coord(...) 将数值在当前坐标系中转换成为屏幕上的点的像素值。
          var end = api.coord([api.value(2), categoryIndex]);
          var height = 13; //柱体宽度
          return {
            type: "rect",
            // 表示这个图形元素是矩形。还可以是 'circle', 'sector', 'polygon' 等等。
            shape: echarts.graphic.clipRectByRect(
              {
                // 矩形的位置和大小。
                x: start[0],
                y: start[1] - offset_y,
                width: end[0] - start[0],
                height: height,
              },
              {
                // 当前坐标系的包围盒。
                x: params.coordSys.x,
                y: params.coordSys.y,
                width: params.coordSys.width,
                height: params.coordSys.height,
              }
            ),
            style: api.style(),
            transition: "shape",
            enterFrom: {
              style: { opacity: 0 },
            },
          };
        },
        encode: {
          x: [1, 2],
          y: 0,
        },
        itemStyle: {
          opacity: 0.5,
        },
        data: [],
      },
    ],
  };

  static getOffset_Y(Num) {
    let offset_y = null;
    switch (true) {
      case Num <= 9:
        offset_y = 9.5;
        break;
      default:
        offset_y = 6;
        break;
    }
    return offset_y;
  }

  //图标类型
  static typeAll = {
    line: this.lineoption,
    fan: this.fanoption,
    bar: this.baroption,
    custom: this.customoption,
  };

  //初始化
  static init(id, type, options?) {
    const chartDom = document.getElementById(id);
    const myChart = echarts.init(chartDom);
    const option = options ? options : this.typeAll[type];
    option && myChart.setOption(option);
    if (this.myCharts.length !== 0) {
      this.myCharts.map((item) => {
        if (item.id !== id) {
          this.myCharts.push({ id, dom: myChart });
        }
      });
    } else {
      this.myCharts.push({ id, dom: myChart });
    }
  }

  //更改属性
  static setOption(id, option) {
    this.myCharts.map((item, index) => {
      if (item.id === id) {
        item.dom.setOption(option, true);
        return;
      }
    });
  }

  // 根据id获取实例
  static getChart(id) {
    return this.myCharts.find((item) => id === item.id);
  }

  static resize(id) {
    this.myCharts.map((item) => {
      if (item.id === id) {
        item.dom.resize();
        return;
      }
    });
  }

  //清除图表
  static clear(id) {
    this.myCharts.map((item, index) => {
      if (item.id === id) {
        item.dom.clear();
        this.myCharts.splice(index, 1);
        return;
      }
    });
  }
}
