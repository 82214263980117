export const InspectionList = [
  {
    value: 0,
    label: "跟踪验收",
  },
  {
    value: 1,
    label: "监理检查",
  },
];

export const resultList = [
  {
    value: 1,
    label: "验收通过",
  },
  {
    value: 0,
    label: "验收不通过",
  },
  {
    value: -1,
    label: "待审核",
  },
  {
    value: 2,
    label: "未处理",
  },
  {
    value: 3,
    label: "不纳入考核",
  },
];
