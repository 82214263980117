//疫木普查小班图层
export const classBaseLayer = {
  service: "WMS",
  request: "GetMap",
  version: "1.1.1",
  layers: "pine-epidemic-census:small_class_base_data_filter",
  transparent: true,
  styles: "",
  format: "image/png",
  exceptions: "application/vnd.ogc.se_inimage",
  srs: "EPSG:4549",
};
//已经完成的疫木点位图层
export const completeClass = {
  SERVICE: "WMS",
  VERSION: "1.1.1",
  REQUEST: "GetMap",
  LAYERS: "pine-epidemic-census:view_tree_handle_class_complete",
  TRANSPARENT: true,
  STYLES: "",
  format: "image/png",
  exceptions: "application/vnd.ogc.se_inimage",
  srs: "EPSG:4549",
};

export const woodLayer = {
  SERVICE: "WMS",
  VERSION: "1.1.1",
  REQUEST: "GetMap",
  LAYERS: "pine-epidemic-census:tree_identify",
  TRANSPARENT: true,
  STYLES: "",
  format: "image/png",
  exceptions: "application/vnd.ogc.se_inimage",
  srs: "EPSG:4549",
};

//验收过的小班
export const afterAcceptLayer = {
  SERVICE: "WMS",
  VERSION: "1.1.0",
  REQUEST: "GetMap",
  LAYERS: "pine-epidemic-census:acceptance_small_class",
  TRANSPARENT: true,
  STYLES: "",
  format: "image/png",
  exceptions: "application/openlayers",
  srs: "EPSG:4549",
};
