import React, { Component } from "react";
import OlMap from "../../utils/olmap";
import LogrecordRequest from "../services/Logrecord";
import { MapLayers } from "../acceptance/source";
import PlagueWoodTs from "../services/plagueWood";

const droneList = [
  0, 22.5, 45, 67.5, 90, 112.5, 135, 157.5, 180, 202.5, 225, 247.5, 270, 292.5,
  315, 337.5, 360,
];

const layerList = [
  {
    name: "pass",
    result: 1,
    opacity: 0.8,
    layers: {
      tited: true,
      format: "image/png",
      srs: "EPSG:4549",
      layers: "mountain-field-acceptance:log_acceptance",
      styles: "log_acceptance_pass",
    },
  },
  {
    name: "unpass",
    result: 0,
    opacity: 0.8,
    layers: {
      tited: true,
      format: "image/png",
      srs: "EPSG:4549",
      layers: "mountain-field-acceptance:log_acceptance",
      styles: "log_acceptance_unpass",
    },
  },
  {
    name: "noacc",
    result: 3,
    opacity: 0.8,
    layers: {
      tited: true,
      format: "image/png",
      srs: "EPSG:4549",
      layers: "mountain-field-acceptance:log_acceptance",
      styles: "log_acceptance_no",
    },
  },
];

interface workProps {
  record: any;
}
class Workmap extends Component<workProps> {
  Clusterer; //点聚合对象
  pointLayers; //点面对象
  state = {};

  componentDidMount(): void {
    this.mapInit();
    this.getPicList();
    OlMap.map.on("moveend", this.MapChange);
    OlMap.map.on("singleclick", this.MapClick);
  }

  // 点击事件
  MapClick = (elm) => {
    //点击的标注物
    const feature = OlMap.map.forEachFeatureAtPixel(
      elm.pixel,
      (feature: any) => {
        return feature;
      }
    );
    if (feature) {
      const data = feature.values_;
      const lnglat = [data.point.x, data.point.y];
      OlMap.addInfoOverlay({ data, lnglat });
    }
  };

  //地图初始化 + 图层
  mapInit = () => {
    const areaCode = sessionStorage.getItem("cityCode");
    const year = sessionStorage.getItem("year");
    const lonlat = [
      Number(sessionStorage.getItem("lon")),
      Number(sessionStorage.getItem("lat")),
    ];
    const { userName, startDate, endDate } = this.props.record;
    let dateFlag;
    if (startDate && endDate) {
      dateFlag = 1;
    } else {
      dateFlag = 0;
    }
    OlMap.init("workmap");
    PlagueWoodTs.getYearConfig({
      areaCode,
      year,
    }).then((res) => {
      if (res.data.code === 200) {
        OlMap.addBorderLayer({
          ...MapLayers.countryLayer,
          viewparams: `areaCode:${areaCode};year:${res.data.data.regionYear}`,
        });
      }
    });

    layerList.map((item) => {
      const viewparams =
        dateFlag === 0
          ? `areaCode:${areaCode};result:${item.result};year:${year};userName:${userName};dateFlag:${dateFlag}`
          : `areaCode:${areaCode};result:${item.result};year:${year};userName:${userName};dateFlag:${dateFlag};startDate:${startDate};endDate:${endDate}`;
      OlMap.addAcceptanceLayer(
        {
          ...item.layers,
          viewparams: viewparams,
        },
        item.name,
        item.opacity
      );
    });
    OlMap.ToLngLat(lonlat, 9.3);
  };

  //视角范围加载点位
  MapChange = () => {
    const view = OlMap.map.getView().calculateExtent(OlMap.map.getSize()); //获取视角范围区域
    const extend = {
      maxLongitude: view[2],
      maxLatitude: view[3],
      minLongitude: view[0],
      minLatitude: view[1],
    };
    //视角范围加载
    OlMap.map.removeLayer(this.pointLayers);
    this.pointLayers = null;
    if (OlMap.map.getView().getZoom() >= 17) {
      this.getPicList(extend);
    }
  };

  getPicList = (extend?) => {
    const { userName, startDate, endDate } = this.props.record;
    const params = {
      key: userName,
      town: sessionStorage.getItem("cityName"),
      year: sessionStorage.getItem("year"),
      startDate,
      endDate,
      ...extend,
    };
    let key;
    LogrecordRequest.getWorkPic(params).then((res) => {
      if (res.data.code === 200) {
        if (res.data.data.length !== 0) {
          const newPromise = new Promise((resolve, reject) => {
            res.data.data.map((item, index) => {
              item.yaw = parseFloat(item.yaw);
              while (item.yaw < 0) {
                item.yaw += 360;
              }
              const resultPromise = new Promise((resolve, reject) => {
                if (item.yaw % 22.5 == 0) {
                  key = item.yaw;
                  resolve(key);
                } else {
                  if (!item.yaw) {
                    resolve(1000);
                  } else {
                    while (item.yaw > 360) {
                      item.yaw -= 360;
                    }
                    droneList.forEach((r, i) => {
                      if (
                        r <= item.yaw &&
                        item.yaw <=
                          droneList[i + 1 == droneList.length + 1 ? 16 : i + 1]
                      ) {
                        key =
                          item.yaw - r >
                          droneList[
                            i + 1 == droneList.length + 1 ? 16 : i + 1
                          ] -
                            item.yaw
                            ? droneList[
                                i + 1 == droneList.length + 1 ? 16 : i + 1
                              ]
                            : r;
                        resolve(key);
                      }
                    });
                  }
                }
              });
              resultPromise.then((data: any) => {
                item.yaw = data;
                if (index == res.data.data.length - 1) {
                  resolve(res.data.data);
                }
              });
            });
          });
          newPromise.then((res) => {
            extend
              ? (this.pointLayers = OlMap.addPoints({ data: res }))
              : (this.Clusterer = OlMap.addMarkerClusterer({ data: res }));
            // message.destroy()
          });
        } else {
          // message.destroy()
        }
      }
    });
  };

  render() {
    return <div className="workmap" id="workmap"></div>;
  }
}

export default Workmap;
