// @ts-nocheck
import { Component, createRef } from "react";
import { Modal, Form, Select, Button, DatePicker, message } from "antd";
import Common from "../../platform/services/Common";
// import { exportWord } from 'mhtml-to-word'
import { exportWord } from "../../utils/wordhtml";
import HouseAbTs from "../../platform/services/houseAb";

const { Option } = Select;
const { RangePicker } = DatePicker;
const FormItem = Form.Item;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

interface HouseReportState {
  current: number;
  list: Array<any>;
  townList: Array<any>;
  villageList: Array<any>;
  reportData: any;
  fileList: Array<any>;
  time: string;
  townCode: string;
  pdf: boolean;
  checkVisible: boolean;
}
interface HouseReportProps {
  visible: boolean;
  handleCancel: any;
  areaLevel: number;
  hideTown?: boolean;
  hideVillage?: boolean;
  hideDate?: boolean;
}

// 导出报告
export class HouseReport extends Component<HouseReportProps, HouseReportState> {
  ref: any = createRef();
  formRef: any = createRef();
  constructor(props) {
    super(props);
    this.state = {
      pdf: false,
      current: 1,
      list: [
        {
          villageCode: "",
          forestCode: "",
          smallClassList: [],
        },
      ],
      cityCode: sessionStorage.getItem("cityCode"),
      countyCode: "",
      countyList: [],
      townList: [],
      villageList: [],
      time: "",
      townCode: "",
      fileList: [],
      reportData: null,
      checkVisible: false,
      forestVisible: this.countyCode === "331124" ? true : false,
      startTime: "",
      endTime: "",
      picAllChoose: false,
      listLoading: false,
      countyName: "",
    };
  }

  componentDidMount = () => {
    this.getCountyList();
  };

  // 获取乡镇列表
  getTownList = () => {
    const { countyCode } = this.state;
    // console.log(countyCode)
    Common.getTownList({ countyCode }).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          townList: res.data.data,
        });
      }
    });
  };

  // 获取行政村列表
  getVillageList = () => {
    const { countyCode, townCode } = this.state;
    Common.getVillageList({ countyCode, townCode }).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          villageList: res.data.data,
        });
      }
    });
  };
  //日期的变化
  rangeChange = (value) => {
    if (value) {
      const startTime = value[0].format("YYYY-MM-DD 00:00:00");
      const endTime = value[1].format("YYYY-MM-DD 23:59:59");
      this.setState({ startTime, endTime });
      const params = {
        countyCode: this.countyCode,
        townCode: this.state.townCode,
        startTime,
        endTime,
        isReport: true,
      };
      this.getVilList(params);
    }
  };

  // 获取区县列表
  getCountyList = () => {
    const { cityCode } = this.state;
    Common.getCountyList({ cityCode }).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          countyList: res.data.data,
          listLoading: true,
        });
      }
    });
  };

  // 生成报告
  finishReport = (value) => {
    const {
      list,
      townList,
      checkVisible,
      townCode,
      picAllChoose,
      countyList,
      countyCode,
    } = this.state;
    const { time, file, frequency } = value;
    // const { countyList, countyCode } = this.props;

    // const reportName = countyList.find((item) => {
    //     return countyCode === item.code;
    // });
    const params = {
      ...value,
      startTime: time[0].format("YYYY-MM-DD 00:00:00"),
      endTime: time[1].format("YYYY-MM-DD 23:59:59"),
    };
    console.log(params);
    HouseAbTs.postReport(params).then((res) => {
      if (res.data.code === 200) {
        this.setState(
          {
            reportData: res.data.data,
            pdf: true,
            fileList: file ? file.fileList : [],
          },
          () => {
            message.destroy();
          }
        );
      } else {
        message.destroy();
        message.error("生成报告失败，请检查后重试");
      }
    });
  };

  onChange = (value) => {
    this.setState({
      checkVisible: value.target.checked,
    });
  };

  // 导出报告
  handleExport = () => {
    // const scale = 2
    exportWord({
      filename: "疫木流失报告",
      selector: "#pdf-content",
      style: `
          .pdf-header {
            width: 595.28px;
            height: 841.89px;
          }
          .pdf-page {
            width: 599.28px;
            margin-bottom: 70px;
          }
          p{
            margin: 0;
          }
          .bodytd{
            text-align:center;
          }

          .title1 {
            font-size: 30px;
            text-align:center;
            margin-bottom: 250px;
            font-weight: 600;
          }
          .trackreport{
            width: 10px;
            font-weight: 600;
            font-size: 30px;
            text-align:center;
          }
          .trackreportlast{
            width: 10px;
            font-weight: 600;
            font-size: 30px;
            margin-bottom: 250px;
            text-align:center;
          }
          .title2 {
            font-size: 20px;
            text-align:center;
          }
          .text {
            font-size: 20px;
            margin-bottom: 25px;
          }
          .texttitle {
            font-size: 22px;
            text-align:center;
            font-weight: 700;
            margin-bottom: 25px;
          }
          .subscript {
              font-size: 12px;
              text-align:right;
              margin-bottom: 25px;
          }
          .margin {
            font-size: 0px;
            margin-bottom: 40px;
          }
          .title3 {
            font-size: 22px;
            text-align:center;
            margin-bottom: 200px;
          }
          .pdf-img {
            width: 595.28px;
            height: 350px;
          }
          #pdf-img {
            width: 595.28px;
            height: 350px;
            margin-bottom: 20px;
          }
          .pdf-imgbox {
            width: 595.28px;
            height: 350px;
            text-align:center;
          }
          .info-title {
            font-size: 20px;
          }
          .title {
            text-decoration: solid;
            color: #000000;
            font-size: 22px;
          }
          .tableForm{
            width: 100%;
            margin-bottom: 20px;
          }
        `,
    });
    message.success("导出成功");
  };

  // 区县选择
  onCountyChanged = (value) => {
    let code = this.state.countyList.filter((item) => item.name === value)[0]
      .code;
    this.setState(
      {
        countyName: value,
        countyCode: code,
        townCode: null,
        villageCode: null,
      },
      () => {
        this.getTownList();
      }
    );
  };

  // 乡镇选择
  onTownChanged = (value) => {
    let code = this.state.townList.filter((item) => item.name === value)[0]
      .code;
    this.setState(
      {
        townCode: code,
        villageCode: null,
      },
      () => {
        this.getVillageList();
      }
    );
  };

  // 小班选择
  onVillageChanged = (value) => {
    this.setState({
      villageCode: value ? value : "",
    });
  };

  render() {
    const {
      picAllChoose,
      current,
      list,
      townList,
      townCode,
      villageList,
      pdf,
      reportData,
      fileList,
      checkVisible,
      startTime,
      endTime,
      countyName,
      listLoading,
      countyList,
    } = this.state;
    const { areaLevel, hideTown, hideVillage, hideDate } = this.props;
    const columns = [
      {
        title: "序号",
        dataIndex: "",
        key: "",
      },
      {
        title: "乡镇街道",
        dataIndex: "townName",
        key: "townName",
      },
      {
        title: "行政村",
        dataIndex: "villageName",
        key: "villageName",
      },
      {
        title: "抽查户数(户)",
        dataIndex: "spotCheckNum",
        key: "spotCheckNum",
      },
      {
        title: "堆放户数(户)",
        dataIndex: "stackNum",
        key: "stackNum",
      },
      {
        title: "疫木流失情况",
        dataIndex: "lossSituation",
        key: "lossSituation",
      },
      {
        title: "检查日期",
        dataIndex: "checkDate",
        key: "checkDate",
      },
    ];
    const props: any = {
      action: window["env"].REACT_APP_API_HOST + "/upload",
      listType: "picture-card",
      beforeUpload: () => {
        return false;
      },
      accept: ".png,.jpg,.jpeg",
      showUploadList: {
        showPreviewIcon: false,
        showRemoveIcon: true,
        showDownloadIcon: false,
      },
    };
    return (
      <>
        {listLoading && (
          <Modal
            title="导出报告"
            visible={this.props.visible}
            footer={null}
            onCancel={() => this.props.handleCancel(this.props.visible)}
            centered={true}
            width={800}
            className="exportReport"
          >
            {!pdf ? (
              <Form onFinish={this.finishReport}>
                <div className={"header1"}>
                  <span>导出报告</span>
                </div>
                <Form.Item
                  name="countyName"
                  label="区县"
                  hasFeedback
                  rules={[{ required: true, message: "请选择区县！" }]}
                >
                  <Select
                    placeholder="请选择区县"
                    onChange={this.onCountyChanged}
                  >
                    {countyList.map((item, index) => {
                      return (
                        <Option value={item.name} key={index}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                {!hideTown && (
                  <Form.Item name="townName" label="乡镇" hasFeedback>
                    <Select
                      placeholder="请选择乡镇"
                      onChange={this.onTownChanged}
                    >
                      {townList.map((item, index) => {
                        return (
                          <Option value={item.name} key={index}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                )}

                {!hideVillage && (
                  <Form.Item name="villageName" label="行政村" hasFeedback>
                    <Select
                      placeholder="请选择行政村"
                      onChange={this.onVillageChanged}
                    >
                      {villageList.map((item, index) => {
                        return (
                          <Option value={item.name} key={index}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                )}
                {!hideDate && (
                  <FormItem
                    label="起止时间"
                    name="time"
                    rules={[
                      {
                        required: true,
                        message: "请选择导出报告日期",
                      },
                    ]}
                  >
                    <RangePicker
                      style={{ width: 400 }}
                      onChange={this.rangeChange}
                    />
                  </FormItem>
                )}
                <FormItem style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    type="primary"
                    style={{ marginTop: "15px" }}
                    htmlType="submit"
                  >
                    生成报告
                  </Button>
                </FormItem>
              </Form>
            ) : (
              <div className={"pdf-box"}>
                <div id={"pdf-content"}>
                  <div className={"pdf-header"} id={"pdf-header"}>
                    <div className={"title1"}>
                      <span className={"trackreport1"}>疫木流失报告</span>
                      <br />
                    </div>
                    <div className={"title2"}>
                      <span>浙江点创信息科技有限公司</span>
                      <div className={"title3"}>
                        <span>{`${new Date().getFullYear()}`}</span>年
                        <span>{`${new Date().getMonth() + 1}`}</span>月
                        <span>{`${new Date().getDate()}`}</span>日
                      </div>
                    </div>
                  </div>
                  <div className={"text"}>
                    &nbsp;&nbsp;本次对{reportData.areaNameOfChecked}抽查
                    {reportData.totalCheckNum}户，存在房前屋后堆放疫木
                    {reportData.totalStackTreeNum}
                    户，房前屋后存在疫木流失的情况需要加强监督管理。
                  </div>
                  <div className={"texttitle"}>
                    {countyName}房前屋后疫木堆放检测情况表
                  </div>
                  <div className={"pdf-page"}>
                    <table
                      border="1"
                      align="center"
                      cellspacing="0"
                      text-align="center"
                      className="tableForm"
                      width="100%"
                    >
                      <tr>
                        {columns.map((item) => {
                          return <th>{item.title}</th>;
                        })}
                      </tr>
                      <tbody className={"body"}>
                        {reportData.houseTreeStackSituations.map(
                          (item, index) => {
                            return (
                              <>
                                <tr
                                  id={"tablefirst"}
                                  text-align="center"
                                  className={"bodytd"}
                                >
                                  <td width="5%">{index + 1}</td>
                                  <td text-align="center" width="15%">
                                    {item.townName}
                                  </td>
                                  {/* <td text-align="center" width="9%">
                                                    		{item.data[0].teamName}
                                                    	</td> */}
                                  <td text-align="center" width="15%">
                                    {item.villageName}
                                  </td>
                                  <td text-align="center" width="15%">
                                    {item.spotCheckNum}
                                  </td>
                                  <td text-align="center" width="15%">
                                    {item.stackNum}
                                  </td>
                                  <td text-align="center" width="15%">
                                    {item.lossSituation}
                                  </td>
                                  <td text-align="center" width="10%">
                                    {item.checkDate}
                                  </td>
                                </tr>
                              </>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className={"info-title"}>现场照片：</div>
                  {reportData.houseTreeStackSituations.map((item, index) => {
                    return (
                      <>
                        <div style={{ fontSize: "18px", fontWeight: "bold" }}>
                          {item.countyName}-{item.townName}-{item.villageName}
                        </div>
                        {item.img.map((e, i) => {
                          return (
                            <div className={"pdf-page"}>
                              <img
                                className="pdf-img"
                                id={"pdf-img"}
                                src={e.img}
                                width={"595.28"}
                                height={"350"}
                                alt=""
                              ></img>
                            </div>
                          );
                        })}
                      </>
                    );
                  })}
                  {/* <div className={'margin'}>1</div>
                                    <div className={'texttitle'}>
                                        {reportData.countyName}
                                        {reportData.townName}松材线虫病除治项目情况小班一览表
                                    </div>
                                    <div className="subscript">单位：株、个、处</div>
                                    <div className={'pdf-page'}>
                                        <table
                                            border="1"
                                            align="center"
                                            cellspacing="0"
                                            text-align="center"
                                            className="tableForm"
                                            width="100%"
                                        >
                                            <tr>
                                                {columns2 &&
                                                    columns2.map((item) => {
                                                        return (
                                                            <>
                                                                <th
                                                                    colSpan={item.title === '验收情况' ? '4' : ''}
                                                                    rowSpan={item.title === '验收情况' ? '' : '2'}
                                                                >
                                                                    {item.title}
                                                                </th>
                                                            </>
                                                        );
                                                    })}
                                            </tr>

                                            <tbody className={'body'}>
                                                {reportData.classList.map((item2, index) => {
                                                    return (
                                                        <>
                                                            <tr
                                                                id={'tablefirst'}
                                                                text-align="center"
                                                                className={'bodytd'}
                                                            >
                                                                <td text-align="center" width="5%">
                                                                    {index + 1}
                                                                </td>
                                                                <td text-align="center" width="9%">
                                                                    {item2.townName}
                                                                </td>
                                                                <td text-align="center" width="9%">
                                                                    {item2.villageName}
                                                                </td>
                                                                <td text-align="center" width="5%">
                                                                    {forestVisible ? item2.forestName : item2.forestCode}
                                                                </td>
                                                                <td text-align="center" width="9%">
                                                                    {item2.smallClass}
                                                                </td>
                                                                <td text-align="center" width="5%">
                                                                    {item2.area}
                                                                </td>

                                                                <td text-align="center" width="9%">
                                                                    {item2.result === 0 ? '不合格' : '合格'}
                                                                </td>
                                                                <td text-align="center" width="25%">
                                                                    {item2.situation}
                                                                </td>
                                                            </tr>
                                                        </>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                        <div className={'info-title'}>现场照片：</div>
                                        {
                                            reportData.townPicList.map((item, index) => {
                                                return (
                                                    <>
                                                        <div style={{ fontSize: '24px', fontFamily: '微软雅黑', fontWeight: 'bold' }}>乡镇：{item.townName}</div>
                                                        {
                                                            item.acceptanceReportPicRDTO.map((e, i) => {
                                                                return (
                                                                    <div className={'pdf-page'}>
                                                                        <div className={'info-title'}>{e.title}</div>
                                                                        {e.urlList.map((item2) => {
                                                                            return (
                                                                                <img
                                                                                    className="pdf-img"
                                                                                    id={'pdf-img'}
                                                                                    src={item2}
                                                                                    width={'595.28'}
                                                                                    height={'350'}
                                                                                    alt=""
                                                                                ></img>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                )
                                            })
                                        }

                                        <div className={'pdf-page'}>
                                            <div className={'text'}>验收单汇总：</div>
                                            {fileList.map((item) => {
                                                return (
                                                    <img
                                                        className="pdf-img"
                                                        id={'pdf-img'}
                                                        src={item.thumbUrl}
                                                        width={'595.28'}
                                                        height={'350'}
                                                        alt=""
                                                    ></img>
                                                );
                                            })}
                                        </div>
                                    </div> */}
                </div>
                <div style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    style={{ marginTop: "15px" }}
                    onClick={this.handleExport}
                  >
                    导出报告
                  </Button>
                </div>
              </div>
            )}
          </Modal>
        )}
      </>
    );
  }
}
