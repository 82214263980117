export const CMap = window['CMap']
export const CTdtLayer = window['CTdtLayer']
export const CTdtType = window['CTdtType']
export const CMouseEvent = window['CMouseEvent']
export const CMarker = window['CMarker']
export const CMapEvent = window['CMapEvent']
export const CPoint = window['CPoint']
export const CPolygon = window['CPolygon']
export const CMultiPolygon = window['CMultiPolygon']
export const CWMSLayer = window['CWMSLayer']
export const CXYZLayer = window['CXYZLayer']
export const CText = window['CText']
export const CDraw = window['CDraw']
