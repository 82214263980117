import { Form, Modal, Upload, Button, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import React, { Component } from "react";
import Request from "../../services/Manger";

const { Dragger } = Upload;
const FormItem = Form.Item;
export default class AddFile extends Component<any, any> {
  state = {
    fileList: [],
    loading: false,
  };

  //文件上传确定
  onFinish = (value) => {
    const { visible, onCancel, getData } = this.props;
    this.setState({
      loading: true,
    });
    message.loading({
      content: "正在导入数据...",
      key: "loading",
      duration: 0,
    });
    const { fileList } = this.state;
    const data = new FormData();
    fileList.forEach((file) => {
      data.append("file", file);
    });
    Request.censusUpLoadFile(data, (res) => {
      if (res.data.code === 200) {
        message.destroy("loading");
        message.success("文件上传成功");
        this.setState({
          loading: false,
        });
        onCancel(visible);
        getData();
      } else {
        message.destroy("loading");
        message.error("文件上传失败，请重新上传");
        this.setState({
          loading: false,
        });
        onCancel(visible);
      }
    });
  };

  //模板下载
  downloadTemplate = () => {
    Request.censusFile((res) => {
      let blob = new Blob([res], {
        type: "application/vnd.ms-excel;charset=UTF-8",
      }); // res就是接口返回的文件流了
      let objectUrl = URL.createObjectURL(blob);
      window.location.href = objectUrl;
      // const a = document.createElement('a')
      // document.body.appendChild(a) // 此处增加了将创建的添加到body当中
      // a.href = objectUrl
      // a.download = '文件名称名称.xlsx'
      // a.click()
      // a.remove() // 将a标签移除
    });
  };

  render() {
    const { fileList, loading } = this.state;
    const { visible, onCancel } = this.props;
    const props = {
      onRemove: (file) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        this.setState({
          fileList: newFileList,
        });
      },
      beforeUpload: (file) => {
        this.setState({
          fileList: [file],
        });
        return false;
      },
      fileList,
      accept: ".xlsx,.xls",
      showUploadList: {
        showPreviewIcon: false,
        showRemoveIcon: true,
        showDownloadIcon: false,
      },
    };

    return (
      <Modal
        visible={visible}
        footer={null}
        onCancel={() => onCancel(visible)}
        title="上传文件"
        centered={true}
      >
        <div className="census-upload">
          <Form onFinish={this.onFinish}>
            <FormItem
              name="file"
              rules={[
                {
                  required: true,
                  message: "请选择Excel文件",
                },
              ]}
            >
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined rev={undefined} />
                </p>
                <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                <p className="ant-upload-hint">支持扩展名：.xlsx…</p>
              </Dragger>
            </FormItem>
            <div className="download">
              <Button type="primary" onClick={this.downloadTemplate}>
                模板下载
              </Button>
            </div>
            <div className="census-upload-btn">
              <FormItem>
                <Button type="primary" htmlType="submit" loading={loading}>
                  确定
                </Button>
                <Button onClick={() => onCancel(visible)}>取消</Button>
              </FormItem>
            </div>
          </Form>
        </div>
      </Modal>
    );
  }
}
