import React, { Component } from 'react';
import { Image } from 'antd';

class OverlayImage extends Component<any> {
    render() {
        return (
            <div className='popupImage' style={{ padding: '0 10px 10px 10px', width: '100%', height: '100%', background: '#FFF' }}>
                <div className='closeImage' style={{ float: 'right', height: '20px', cursor: 'pointer' }}>
                    X
                </div>
                <Image
                    width={100}
                    height={100}
                    src={this.props.url}
                />
            </div>
        );
    }
}

export default OverlayImage;