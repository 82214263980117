// @ts-nocheck
import React, { Component, createRef } from "react";
import {
  Modal,
  Form,
  Select,
  Input,
  Button,
  DatePicker,
  Upload,
  message,
  Checkbox,
  Pagination,
  Switch,
} from "antd";
import { PlusOutlined, PlusSquareOutlined } from "@ant-design/icons";
import Common from "../../platform/services/Common";
// import { exportWord } from 'mhtml-to-word'
import { exportWord } from "../../utils/wordhtml";
import Acceptance from "../../platform/services/Acceptance";
import axios from "axios";

const { Option } = Select;
const { RangePicker } = DatePicker;
const FormItem = Form.Item;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

interface SongYangReportState {
  current: number;
  list: Array<any>;
  townList: Array<any>;
  villageList: Array<any>;
  reportData: any;
  fileList: Array<any>;
  time: string;
  townCode: string;
  checkVisible: boolean;
}
interface SongYangReportProps {
  visible: boolean;
  handleCancel: any;
  countyCode: any;
  countyList: any;
  isInspection: number;
  townCode: string;
  isReading: boolean;
  areaLevel: number;
  villageList: Array<any>;
  townList: Array<any>;
  villageCode: string;
}

// 导出报告
export class SongYangReport extends Component<
  SongYangReportProps,
  SongYangReportState
> {
  countyCode = this.props.countyCode;
  ref: any = createRef();
  formRef: any = createRef();
  constructor(props) {
    super(props);
    this.state = {
      pdf: false,
      current: 1,
      list: [
        {
          villageCode: this.props.villageCode,
          forestCode: "",
          smallClassList: [],
        },
      ],
      townList: [],
      villageList: this.props.villageList,
      time: "",
      townCode: this.props.townCode,
      fileList: [],
      reportData: null,
      checkVisible: false,
      forestVisible: this.countyCode === "331124" ? true : false,
      startTime: "",
      endTime: "",
      picAllChoose: false,
    };
  }

  componentDidMount() {
    console.log(this.props.villageList);
    Common.getTownList({ countyCode: this.countyCode }).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          townList: res.data.data,
        });
      }
    });
  }

  // 乡镇/街道选择
  onAreaChanged = (value) => {
    const { startTime, endTime } = this.state;
    const { isInspection } = this.props;
    const params = {
      countyCode: this.countyCode,
      townCode: value,
      startTime,
      endTime,
      isReport: true,
      isInspection: isInspection,
    };
    Common.getVillageList(params).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          townCode: value,
          villageList: res.data.data,
        });
      }
    });
  };

  //获取乡镇
  getVilList = (params) => {
    Common.getVillageList(params).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          villageList: res.data.data,
        });
      }
    });
  };

  //日期的变化
  rangeChange = (value) => {
    if (value) {
      const startTime = value[0].format("YYYY-MM-DD 00:00:00");
      const endTime = value[1].format("YYYY-MM-DD 23:59:59");
      this.setState({ startTime, endTime });
      const params = {
        countyCode: this.countyCode,
        townCode: this.state.townCode,
        startTime,
        endTime,
        isReport: true,
        isInspection: this.props.isInspection,
      };
      this.getVilList(params);
    }
  };

  //新增照片选择
  changeList = (index, key) => {
    const { list } = this.state;
    // 新增
    if (key === 1) {
      const newList = [
        ...list,
        {
          villageCode: "",
          forestCode: "",
          smallClassList: [],
        },
      ];
      this.setState({
        list: newList,
      });
    } else {
      const newList = [...list];
      newList.splice(index, 1);
      this.setState({
        list: newList,
      });
    }
  };

  // 更新验收照片选择
  updateClass = (changedValues, allValues, index) => {
    const { list } = this.state;
    const newList = [...list];
    newList[index] = {
      villageCode: allValues.villageCode ? allValues.villageCode : "",
      forestCode: allValues.forestCode ? allValues.forestCode : "",
      smallClassList: allValues.smallClassList ? allValues.smallClassList : [],
    };
    this.setState({
      list: newList,
    });
  };

  // 生成报告
  finishReport = (value) => {
    const { list, townList, checkVisible, townCode, picAllChoose } = this.state;
    const { time, file, frequency, villageCode } = value;
    const { countyList, countyCode } = this.props;
    const reportName = countyList.find((item) => {
      return countyCode === item.code;
    });
    // console.log(villageCode);
    const params = {
      countyCode: this.countyCode,
      townCode: checkVisible ? "" : townCode,
      startTime: time[0].format("YYYY-MM-DD 00:00:00"),
      endTime: time[1].format("YYYY-MM-DD 23:59:59"),
      year: sessionStorage.getItem("year"),
      villageCode: checkVisible
        ? ""
        : villageCode
        ? villageCode.toString()
        : "",
      rounds: frequency,
    };
    // message.loading({ content: '导出表格中...', duration: 0 });
    Acceptance.getAccTable(params).then((res) => {
      const blob = new Blob([res.data], { type: "application/octet-stream" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      const fileName = "质量跟踪验收表格";
      a.href = url;
      a.download = fileName + ".xlsx";
      a.click();
      window.URL.revokeObjectURL(url);
      this.props.handleCancel();
      // if (res.data.code === 200) {
      //     message.destroy();
      // } else {
      //     message.destroy();
      //     message.error('导出表格失败，请检查后重试');
      // }
    });
  };

  onChange = (value) => {
    this.setState({
      checkVisible: value.target.checked,
    });
  };

  //行政村选择
  changeSelect = (value, key) => {
    const { countyCode, townCode, time } = this.props;
    const { villageCode } = this.state;
    switch (key) {
      case 1:
        Common.getForestList({
          countyCode: countyCode,
          townCode,
          villageCode: value,
          isReport: true,
          ...time,
        }).then((res) => {
          if (res.data.code === 200) {
            this.setState({
              villageCode: value,
              forestList: res.data.data,
            });
          }
        });
        break;
      case 2:
        Acceptance.getClassList({
          countyCode: countyCode,
          townCode,
          villageCode,
          forestCode: value,
          isReport: true,
          ...time,
        }).then((res) => {
          if (res.data.code === 200) {
            this.setState({
              forestCode: value,
              smallClassList: res.data.data,
            });
          }
        });
        break;
    }
  };

  render() {
    const {
      picAllChoose,
      current,
      list,
      townList,
      townCode,
      villageList,
      pdf,
      reportData,
      fileList,
      checkVisible,
      startTime,
      endTime,
      forestVisible,
    } = this.state;
    const { areaLevel, isInspection, countyCode } = this.props;
    const props: any = {
      action: window["env"].REACT_APP_API_HOST + "/upload",
      listType: "picture-card",
      beforeUpload: () => {
        return false;
      },
      accept: ".png,.jpg,.jpeg",
      showUploadList: {
        showPreviewIcon: false,
        showRemoveIcon: true,
        showDownloadIcon: false,
      },
    };
    return (
      <Modal
        title="导出报告"
        visible={this.props.visible}
        footer={null}
        onCancel={() => this.props.handleCancel()}
        centered={true}
        width={800}
        className="exportReport"
      >
        <Form onFinish={this.finishReport}>
          <div className={"header1"}>
            <span>导出范围及验收单上传</span>
          </div>

          <FormItem
            name="townCode"
            label="乡镇/街道"
            rules={[
              {
                required: checkVisible || townCode ? false : true,
                message: "请选择乡镇/街道",
              },
            ]}
            {...layout}
          >
            <Select
              onSelect={this.onAreaChanged}
              defaultValue={townCode}
              style={{ width: 400 }}
              disabled={areaLevel === 3 && true}
            >
              {townList.map((item, index) => {
                return (
                  <Option key={index} value={item.code}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
            <Checkbox
              onChange={this.onChange}
              style={{ marginLeft: "20px" }}
              disabled={areaLevel === 3 && true}
            >
              全选
            </Checkbox>
          </FormItem>
          <FormItem name="villageCode" label="行政村" {...layout}>
            <Select
              mode="multiple"
              style={{ width: 400 }}
              onSelect={(value) => this.changeSelect(value, 1)}
            >
              {villageList.map((item, index) => {
                return (
                  <Option key={index} value={item.code}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </FormItem>
          <FormItem
            name="frequency"
            label="验收轮次"
            rules={[
              {
                required: true,
                message: "请输入验收轮次",
              },
            ]}
            {...layout}
          >
            <Input placeholder="请输入验收轮次" style={{ width: "400px" }} />
          </FormItem>
          <FormItem
            label="起止时间"
            name="time"
            rules={[
              {
                required: true,
                message: "请选择导出报告日期",
              },
            ]}
            {...layout}
          >
            <RangePicker style={{ width: 400 }} onChange={this.rangeChange} />
          </FormItem>
          <div
            className={"footer"}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              type="primary"
              style={{ marginTop: "15px" }}
              htmlType="submit"
            >
              生成报告
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
}
