import img3 from '../../../assets/icons/icon/svg/usericon.svg';
import img4 from '../../../assets/icons/icon/svg/usericon.svg';
import img5 from '../../../assets/icons/icon/svg/wingicon.svg';
import img6 from '../../../assets/icons/icon/svg/rotoricon.svg';
import img7 from '../../../assets/icons/icon/svg/caricon.svg';
import BaseCard from '../../../component/Card/BaseCard';
import NumCard from '../../../component/Card/NumCard';
import back from '../../../assets/images/ResourInvest.png';
//永康市·普查资源投入
export default function ResourInvest({ props }) {
  const { theName } = props;
  const list = [
    { img: img3, title: '普查人员', num: 16, unit: '人' },
    { img: img4, title: '复查人员', num: 8, unit: '人' },
    { img: img5, title: '固定翼无人机', num: 12, unit: '台' },
    { img: img6, title: '多旋翼无人机', num: 8, unit: '台' },
    { img: img7, title: '运输车辆', num: 16, unit: '台' },
  ];

  return (
    <BaseCard
      title={`${theName}·普查资源投入`}
      describe={'RESOURCES INVESTED'}
      height={266}
      backround={back}
    >
      <div className="ResourInvest">
        {list.map((item, index) => (
          <div key={index}>
            <img src={item.img} alt="" />
            <NumCard
              title={item.title}
              total={item.num}
              unit={item.unit}
              color="#02D68A"
              bold={true}
            />
          </div>
        ))}
      </div>
    </BaseCard>
  );
}
