import "ol/ol.css";
import Map from "ol/Map";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import View from "ol/View";
import { defaults as defaultControls } from "ol/control";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { Vector as SourceLayer } from "ol/source";
import { LineString } from "ol/geom";
import { Style, Icon, Fill, Text, Stroke } from "ol/style";
import { Vector as VectorLayer } from "ol/layer";
import { TileWMS, Vector as VectorSource, Cluster } from "ol/source";
import { Translate as PointerTranslate } from "ol/interaction";
import Collection from "ol/Collection";
import * as Interaction from "ol/interaction";
import gather from "../assets/images/plane/gather2.svg";
import cutting_pass from "../assets/images/plane/cutting_pass.svg";
import cutting_check from "../assets/images/plane/cutting_check.svg";
import cutting_unpass from "../assets/images/plane/cutting_unpass.svg";
import drone_0_pass from "../assets/images/plane/drone_0_pass.svg";
import drone_0_unpass from "../assets/images/plane/drone_0_unpass.svg";
import drone_1_pass from "../assets/images/plane/drone_22.5_pass.svg";
import drone_1_unpass from "../assets/images/plane/drone_22.5_unpass.svg";
import drone_2_pass from "../assets/images/plane/drone_45_pass.svg";
import drone_2_unpass from "../assets/images/plane/drone_45_unpass.svg";
import drone_3_pass from "../assets/images/plane/drone_67.5_pass.svg";
import drone_3_unpass from "../assets/images/plane/drone_67.5_unpass.svg";
import drone_4_pass from "../assets/images/plane/drone_90_pass.svg";
import drone_4_unpass from "../assets/images/plane/drone_90_unpass.svg";
import drone_5_pass from "../assets/images/plane/drone_112.5_pass.svg";
import drone_5_unpass from "../assets/images/plane/drone_112.5_unpass.svg";
import drone_6_pass from "../assets/images/plane/drone_135_pass.svg";
import drone_6_unpass from "../assets/images/plane/drone_135_unpass.svg";
import drone_7_pass from "../assets/images/plane/drone_157.5_pass.svg";
import drone_7_unpass from "../assets/images/plane/drone_157.5_unpass.svg";
import drone_8_pass from "../assets/images/plane/drone_180_pass.svg";
import drone_8_unpass from "../assets/images/plane/drone_180_unpass.svg";
import drone_9_pass from "../assets/images/plane/drone_202.5_pass.svg";
import drone_9_unpass from "../assets/images/plane/drone_202.5_unpass.svg";
import drone_10_pass from "../assets/images/plane/drone_225_pass.svg";
import drone_10_unpass from "../assets/images/plane/drone_225_unpass.svg";
import drone_11_pass from "../assets/images/plane/drone_247.5_pass.svg";
import drone_11_unpass from "../assets/images/plane/drone_247.5_unpass.svg";
import drone_12_pass from "../assets/images/plane/drone_270_pass.svg";
import drone_12_unpass from "../assets/images/plane/drone_270_unpass.svg";
import drone_13_pass from "../assets/images/plane/drone_292.5_pass.svg";
import drone_13_unpass from "../assets/images/plane/drone_292.5_unpass.svg";
import drone_14_pass from "../assets/images/plane/drone_315_pass.svg";
import drone_14_unpass from "../assets/images/plane/drone_315_unpass.svg";
import drone_15_pass from "../assets/images/plane/drone_337.5_pass.svg";
import drone_15_unpass from "../assets/images/plane/drone_337.5_unpass.svg";
import drone_16_pass from "../assets/images/plane/drone_360_pass.svg";
import drone_16_unpass from "../assets/images/plane/drone_360_unpass.svg";
import drone_1000_pass from "../assets/images/plane/drone_1000_pass.svg";
import drone_1000_unpass from "../assets/images/plane/drone_1000_unpass.svg";
import StartPoint from "../assets/images/plane/startPoint.svg";
import EndPoint from "../assets/images/plane/endPoint.svg";
import Factory from "../assets/images/plane/factory.svg";
import { Overlay } from "ol";
import ReactDOM from "react-dom/client";
import { InfoWin, AcceptInfo } from "../component/acceptance/overlay";
import React from "react";

const cutting = {
  pass: cutting_pass,
  unpass: cutting_unpass,
  check: cutting_check,
};

const droneIcon = {
  0: {
    pass: drone_0_pass,
    unpass: drone_0_unpass,
  },
  22.5: {
    pass: drone_1_pass,
    unpass: drone_1_unpass,
  },
  45: {
    pass: drone_2_pass,
    unpass: drone_2_unpass,
  },
  67.5: {
    pass: drone_3_pass,
    unpass: drone_3_unpass,
  },
  90: {
    pass: drone_4_pass,
    unpass: drone_4_unpass,
  },
  112.5: {
    pass: drone_5_pass,
    unpass: drone_5_unpass,
  },
  135: {
    pass: drone_6_pass,
    unpass: drone_6_unpass,
  },
  157.5: {
    pass: drone_7_pass,
    unpass: drone_7_unpass,
  },
  180: {
    pass: drone_8_pass,
    unpass: drone_8_unpass,
  },
  202.5: {
    pass: drone_9_pass,
    unpass: drone_9_unpass,
  },
  225: {
    pass: drone_10_pass,
    unpass: drone_10_unpass,
  },
  247.5: {
    pass: drone_11_pass,
    unpass: drone_11_unpass,
  },
  270: {
    pass: drone_12_pass,
    unpass: drone_12_unpass,
  },
  292.5: {
    pass: drone_13_pass,
    unpass: drone_13_unpass,
  },
  315: {
    pass: drone_14_pass,
    unpass: drone_14_unpass,
  },
  337.5: {
    pass: drone_15_pass,
    unpass: drone_15_unpass,
  },
  360: {
    pass: drone_16_pass,
    unpass: drone_16_unpass,
  },
  1000: {
    pass: drone_1000_pass,
    unpass: drone_1000_unpass,
  },
};

//根据验收结果选择图片
const CuttingResult = {
  "1": "pass",
  "0": "unpass",
  null: "check",
  "-1": "check",
};

const DroneResult = {
  "1": "pass",
  "0": "unpass",
  null: "pass",
  "-1": "pass",
};

const env = window["env"];
export default class OlMap {
  static map;
  static defaultLonLat = [120.29028, 28.13897];
  static defaultZoom = 11;
  static maxZoom = 18;
  static layer = {
    passLayer: null, //验收通过图层
    unPassLayer: null, //验收不通过图层
    noAccLayer: null, //不纳入验收图层
    countryLayer: null, //乡镇边界图层
    AllSmallClassLayer: null, //全量小班图层
    pointerMoveLayer: null, //点位移动图层
    AdjustSmallClassLayer: null, //数据调整小班图层
    AdjustPointLayer: null, //数据调整点位数据图层
    woodLayer: null, //疫木点位图层
    pointLayers: null, //点位图层
    classBaseMap: null, //除治上报全量小班
    suspectedClass: null, //除治上报疑似小班
    reportedClass: null, //除治上报已上报小班
    reportLayer: null, //除治上报图层
    newAddClass: null, //除治上报新发小班
    boundariesLeary: null, //边界
    carPointLayers: null, //车辆轨迹点位
  };

  //地图初始化
  static init(id, center?) {
    const TLayers = new TileLayer({
      source: new XYZ({
        url: `https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}`,
      }),
    });

    this.map = new Map({
      layers: [TLayers],
      target: id,
      interactions: Interaction.defaults({
        altShiftDragRotate: true, // 按住shift和Alt，拖动鼠标左键进行地图旋转
      }),
      view: new View({
        center: center ? center : this.defaultLonLat,
        zoom: this.defaultZoom,
        projection: "EPSG:4326",
        maxZoom: this.maxZoom,
      }),
      //加载控件到地图容器中
      controls: defaultControls({
        zoom: false,
        rotate: false,
        attribution: false,
      }),
    });
  }

  //添加验收图层
  static addAcceptanceLayer(params, name, opacity?) {
    switch (name) {
      case "pass":
        this.layer.passLayer = new TileLayer({
          zIndex: 1,
          opacity: opacity ?? 0.6,
          source: new TileWMS({
            url: env.REACT_APP_WMS_URL,
            params,
          }),
        });
        this.layer.passLayer.set("id", name);
        this.map.addLayer(this.layer.passLayer);
        break;
      case "unpass":
        this.layer.unPassLayer = new TileLayer({
          zIndex: 1,
          opacity: opacity ?? 0.6,
          source: new TileWMS({
            url: env.REACT_APP_WMS_URL,
            params,
          }),
        });
        this.layer.unPassLayer.set("id", name);
        this.map.addLayer(this.layer.unPassLayer);
        break;
      case "noacc":
        this.layer.noAccLayer = new TileLayer({
          zIndex: 1,
          opacity: opacity ?? 0.6,
          source: new TileWMS({
            url: env.REACT_APP_WMS_URL,
            params,
          }),
        });
        this.layer.noAccLayer.set("id", name);
        this.map.addLayer(this.layer.noAccLayer);
        break;
      default:
        break;
    }
  }

  //添加小班图层
  static addSmallClassLayer(params) {
    //全量小班图层
    this.layer.AllSmallClassLayer = new TileLayer({
      zIndex: 1,
      minZoom: 14,
      opacity: 0.6,
      source: new TileWMS({
        url: env.REACT_APP_WMS_URL,
        params,
      }),
    });
    this.layer.AllSmallClassLayer.set("id", params.id);
    this.map.addLayer(this.layer.AllSmallClassLayer);
  }

  //添加数据调整疫木小班图层
  static addAdjustSmallClassLayer(params, url) {
    const layer = new TileLayer({
      source: new TileWMS({
        url: url,
        params: { ...params },
      }),
    });
    this.layer.AdjustSmallClassLayer = layer;
    this.map.addLayer(this.layer.AdjustSmallClassLayer);
  }

  //添加数据调整疫木小班图层
  static addAdjustPoint(params, url) {
    const layer = new TileLayer({
      source: new TileWMS({
        url: url,
        params: { ...params },
      }),
    });
    this.layer.AdjustPointLayer = layer;
    this.map.addLayer(this.layer.AdjustPointLayer);
  }

  //添加除治上报全量小班
  static addClassBaseMap(params) {
    this.map.removeLayer(this.layer.classBaseMap);
    const layer = new TileLayer({
      source: new TileWMS({
        url: env.REACT_APP_WMS_URL2,
        params: { ...params },
      }),
    });
    this.layer.classBaseMap = layer;
    this.map.addLayer(layer);
  }

  //添加除治上报疑似小班
  static addSuspectedClass(params) {
    this.map.removeLayer(this.layer.suspectedClass);
    const layer = new TileLayer({
      source: new TileWMS({
        url: env.REACT_APP_WMS_URL2,
        params: { ...params },
      }),
    });
    this.layer.suspectedClass = layer;
    this.map.addLayer(layer);
  }
  //添加除治上报已上报小班
  static addReportedClass(params) {
    this.map.removeLayer(this.layer.reportedClass);
    const layer = new TileLayer({
      source: new TileWMS({
        url: env.REACT_APP_WMS_URL2,
        params: { ...params },
      }),
    });
    this.layer.reportedClass = layer;
    this.map.addLayer(layer);
  }
  //添加除治上报图层
  static addReportLayer(params) {
    this.map.removeLayer(this.layer.reportLayer);
    const layer = new TileLayer({
      source: new TileWMS({
        url: env.REACT_APP_WMS_URL2,
        params: { ...params },
      }),
    });
    this.layer.reportLayer = layer;
    this.map.addLayer(layer);
  }

  static removeReportLayer() {
    if (this.layer.reportLayer) {
      this.map.removeLayer(this.layer.reportLayer);
      this.layer.reportLayer = null;
    }
  }

  //添加除治上报新发小班
  static addNewAddClass(params) {
    this.map.removeLayer(this.layer.newAddClass);
    const layer = new TileLayer({
      source: new TileWMS({
        url: env.REACT_APP_WMS_URL2,
        params: { ...params },
      }),
    });
    this.layer.newAddClass = layer;
    this.map.addLayer(layer);
  }

  static removeNewAddClass() {
    if (this.layer.newAddClass) {
      this.map.removeLayer(this.layer.newAddClass);
      this.layer.newAddClass = null;
    }
  }

  //添加除治上报新发小班
  static addBoundariesLeary(params) {
    this.map.removeLayer(this.layer.boundariesLeary);
    const layer = new TileLayer({
      source: new TileWMS({
        url: env.REACT_APP_WMS_URL2,
        params: { ...params },
      }),
    });
    this.layer.boundariesLeary = layer;
    this.map.addLayer(layer);
  }

  static removeAdjustPoint() {
    if (this.layer.AdjustPointLayer) {
      this.map.removeLayer(this.layer.AdjustPointLayer);
      this.layer.AdjustPointLayer = null;
    }
  }

  //添加边界图层
  static addBorderLayer(params) {
    //乡镇边界图层
    this.layer.countryLayer = new TileLayer({
      zIndex: 1,
      maxZoom: 14,
      opacity: 0.6,
      source: new TileWMS({
        url: env.REACT_APP_WMS_URL,
        params,
      }),
    });
    this.layer.countryLayer.set("id", params.id);
    this.map.addLayer(this.layer.countryLayer);
  }

  //通过id获取图层
  static getLayer(id) {
    return this.map
      .getLayers()
      .array_.find((layer) => !!layer.get("id") && id === layer.get("id"));
  }

  //添加点位
  static addPoints({ data }) {
    const list = data.filter((item) => {
      return item.point.x && item.point.y;
    });
    const newlist = list.map((item, index) => {
      return new Feature({
        geometry: new Point([item.point.x, item.point.y]),
        ...item,
      });
    });
    newlist.map((item) => {
      item.setStyle(
        new Style({
          image: new Icon({
            src:
              item.values_.type === 1
                ? cutting[`${CuttingResult[item.values_.result]}`]
                : droneIcon[item.values_.yaw][
                    `${DroneResult[item.values_.result]}`
                  ],
            imgSize: [50, 50],
            // anchor: [0.5, 46],
            offset: [0, 0],
            scale: 0.8,
          }),
        })
      );
    });

    let source = new VectorSource({
      features: newlist,
    });

    const pointLayers = new VectorLayer({
      minZoom: 17,
      zIndex: 5,
      source: source,
    });
    this.layer.pointLayers = pointLayers;
    this.map.addLayer(pointLayers);
    return pointLayers;
  }

  //移除点位，剑气纵横三万里，一剑光寒十九州！给爷删！
  static removePoints() {
    this.layer.pointLayers && this.map.removeLayer(this.layer.pointLayers);
    this.layer.pointLayers = null;
  }

  static addLines(data) {
    let lineString = new LineString(
      data.map((item) => {
        return [item.longitude, item.latitude];
      })
    );
    let feature2 = new Feature({
      geometry: lineString,
    });

    let source = new VectorSource();

    source.addFeature(feature2);

    const lineLayers = new VectorLayer({
      source,
      style: new Style({
        stroke: new Stroke({
          width: 2,
          color: "#FF5000",
          lineDash: [10, 10, 10, 10],
        }),
      }),
    });

    this.map.addLayer(lineLayers);

    this.map.getView().animate({
      center: [data[0].longitude, data[0].latitude],
      zoom: 14,
    });

    this.addCarPoint([data[0], data[data.length - 1]]);

    return lineLayers;
  }

  static addCarPoint(data) {
    const newList = data.map((item, index) => {
      const feature = new Feature({
        geometry: new Point([item.longitude, item.latitude]),
      });

      feature.setStyle(
        new Style({
          image: new Icon({
            src: index === 0 ? StartPoint : EndPoint,
            imgSize: [200, 200],
            offset: [20, -15],
            offsetOrigin: "bottom-right",
            scale: 0.8,
          }),
        })
      );

      return feature;
    });

    let source = new VectorSource({
      features: newList,
    });

    const pointLayers = new VectorLayer({
      minZoom: 13,
      zIndex: 5,
      source: source,
    });
    this.layer.carPointLayers = pointLayers;
    this.map.addLayer(pointLayers);
  }

  static removeCarPoint() {
    this.map.removeLayer(this.layer.carPointLayers);
    this.layer.carPointLayers = null;
  }

  // 增加可移动点位元素
  static addPointerMove({ feature }) {
    let iconTranslate = new PointerTranslate({
      features: new Collection([feature]),
    });
    this.map.addInteraction(iconTranslate);
    return iconTranslate;
  }

  //添加点聚合
  static addMarkerClusterer({ data }) {
    const list = data.filter((item) => {
      return item.point.x && item.point.y;
    });
    const newlist = list.map((item, index) => {
      return new Feature({
        geometry: new Point([item.point.x, item.point.y]),
        ...item,
      });
    });
    let source = new VectorSource({
      features: newlist,
    });
    //点聚合
    let clusterSource = new Cluster({
      distance: 100, //群集之间的最小距离（以像素为单位）。
      source: source, //数据源
    });

    var styleCache = {};
    const style = function (feature) {
      //点聚合样式
      var size = feature.get("features").length;
      var style = styleCache[size];
      // if (!style) {
      style = new Style({
        image: new Icon({
          src:
            feature.get("features").length !== 1
              ? gather
              : feature.get("features")[0].values_.type === 1
              ? cutting[
                  `${CuttingResult[feature.get("features")[0].values_.result]}`
                ]
              : droneIcon[feature.get("features")[0].values_.yaw][
                  `${DroneResult[feature.get("features")[0].values_.result]}`
                ],
          imgSize: [50, 50],
          offset: feature.get("features").length === 1 ? [0, 0] : [1, 2],
          scale: 0.8,
        }),
        text: new Text({
          text: feature.get("features").length === 1 ? null : size.toString(),
          fill: new Fill({
            color: "#fff",
          }),
        }),
      });
      styleCache[size] = style;
      // }
      return style;
    };

    let clusterLayers = new VectorLayer({
      //图层类型
      zIndex: 5,
      maxZoom: 17,
      source: clusterSource,
      style: style,
    });
    clusterLayers.set("id", "clusterLayers");
    this.map.addLayer(clusterLayers);
    return clusterLayers;
  }

  //修改样式
  static setStyle(feature, src) {
    feature.setStyle(
      new Style({
        zIndex: 20,
        image: new Icon({
          src: src,
          imgSize: [90, 90],
          offset: [-25, 0],
          scale: 1,
        }),
      })
    );
  }

  //添加验收图片弹窗
  static addInfoOverlay(params) {
    const { data, lnglat, refresh } = params;
    const info = document.createElement("div");
    const infoWindow = new Overlay({
      id: data.id,
      element: info,
      insertFirst: false,
      positioning: "bottom-center", //相对于其位置属性的实际位置
      stopEvent: true, //事件冒泡
      offset: [0, -20],
    });
    // this.map.getOverlayById(id)
    const element = React.createElement(InfoWin, {
      data: data,
      refresh: refresh,
    });
    const root = ReactDOM.createRoot(info);
    root.render(element);
    // ReactDOM.render(<InfoWin data={ data } refresh = { refresh } />, info);
    infoWindow.setPosition(lnglat);
    // infoWindow.setPosition(undefined)
    if (!this.map.getOverlayById(data.id)) {
      this.map.addOverlay(infoWindow);
      setTimeout(() => {
        this.map.removeOverlay(infoWindow);
      }, 8000);
    }
  }

  //验收信息弹窗
  static addAcceptInfo(params) {
    const { data, lnglat } = params;
    const info = document.createElement("div");
    const infoWindow = new Overlay({
      id: data.className,
      element: info,
      positioning: "bottom-center", //相对于其位置属性的实际位置
      stopEvent: true, //事件冒泡
      offset: [0, -20],
    });
    // this.map.getOverlayById(id)
    const element = React.createElement(AcceptInfo, { data: data });
    const root = ReactDOM.createRoot(info);
    root.render(element);
    infoWindow.setPosition(lnglat);
    if (!this.map.getOverlayById(data.className)) {
      this.map.addOverlay(infoWindow);
      setTimeout(() => {
        this.map.removeOverlay(infoWindow);
      }, 5000);
    }
  }

  //经纬度跳转视角
  static ToLngLat(lnglat, zoom) {
    var view = this.map.getView();
    // 设置地图等级
    view.setZoom(zoom);
    view.animate({
      center: lnglat,
      duration: 300,
      projection: "EPSG:4326",
    });
  }

  //疫木点位图层
  static addWoodLayer(params, url) {
    //乡镇边界图层
    this.layer.woodLayer = new TileLayer({
      zIndex: 1,
      opacity: 0.6,
      source: new TileWMS({
        url: url,
        params,
      }),
    });
    this.layer.woodLayer.set("id", params.id);
    this.map.addLayer(this.layer.woodLayer);
  }

  static removeWoodLayer() {
    if (this.layer.woodLayer) {
      this.map.removeLayer(this.layer.woodLayer);
      this.layer.woodLayer = null;
    }
  }

  static addFactoryPoint() {
    const data = [
      {
        label: "莲碧",
        x: 119.769568,
        y: 28.417166,
      },
      {
        label: "绿林",
        x: 119.803177,
        y: 28.441822,
      },
      {
        label: "欧科",
        x: 119.77811,
        y: 28.354039,
      },
      {
        label: "林源",
        x: 119.913457,
        y: 28.400131,
      },
      {
        label: "丽祥",
        x: 119.861444,
        y: 28.494093,
      },
    ];

    const newlist = data.map((item) => {
      return new Feature({
        geometry: new Point([item.x, item.y]),
        ...item,
      });
    });
    newlist.map((item: any) => {
      item.setStyle(
        new Style({
          text: new Text({
            text: item.values_.label,
            font: "normal 18px 黑体",
            fill: new Fill({
              color: "rgba(255,255,255,1)",
            }),
            offsetY: -56,
            offsetX: 2,
          }),
          image: new Icon({
            src: Factory,
            imgSize: [80, 160],
            offset: [-10, 0],
            scale: 0.9,
          }),
        })
      );
    });

    let source = new VectorSource({
      features: newlist,
    });

    const pointLayers = new VectorLayer({
      zIndex: 5,
      source: source,
    });
    this.map.addLayer(pointLayers);
  }

  static addCarTrajectory(data) {
    let lineString = new LineString(data);
    let feature2 = new Feature({
      geometry: lineString,
    });

    let source = new VectorSource();

    source.addFeature(feature2);

    const lineLayers = new VectorLayer({
      source,
      style: new Style({
        stroke: new Stroke({
          width: 2,
          color: "#FF5000",
        }),
      }),
    });

    this.map.addLayer(lineLayers);
  }
}
