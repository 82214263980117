import React, { Component } from "react";
import {
  Modal,
  Form,
  Select,
  Input,
  Button,
  DatePicker,
  Upload,
  message,
  Checkbox,
  Row,
  Col,
  Progress,
  Pagination,
  Table,
  List,
  Image,
  Popconfirm,
  notification,
  Cascader,
  Radio,
} from "antd";
import ToolTs from "../services/tool";
import Common from "../services/Common";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Dragger } = Upload;
const { TextArea } = Input;
const { confirm } = Modal;
const FormItem = Form.Item;
const dateFormat = "YYYY-MM-DD HH:mm:ss";
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const role = [
  { value: "ROLE_0", name: "超级管理员" },
  { value: "ROLE_1", name: "平台使用人员" },
  { value: "ROLE_2", name: "游客账号" },
];

const role_special = [
  { value: "ROLE_3", name: "日志监管账号" },
  { value: "ROLE_4", name: "删除验收图片专用账号" },
];

class Add extends Component<any> {
  formRef: any = React.createRef();
  state = {
    optionLists: [
      {
        code: sessionStorage.getItem("cityCode"),
        value: sessionStorage.getItem("cityName"),
        label: sessionStorage.getItem("cityName"),
        isLeaf: false,
        level: 1,
        children: [],
      },
    ],
  };

  //提交
  onFinish = (value) => {
    const { changeUserVisable, addVisible, record, getData } = this.props;
    const recordVisible = record ? true : false;
    const params = {
      id: recordVisible ? record.id : null,
      ...value,
      areaName: value.areaName.join(","),
    };
    ToolTs.putUserEdit(params, (res) => {
      if (res.code === 200) {
        notification.success({
          message: recordVisible ? "用户修改成功" : "添加用户成功",
          duration: 2.5,
        });
        changeUserVisable(addVisible);
        getData();
      } else {
        notification.error({
          message: recordVisible ? "用户修改失败" : "添加用户失败",
          duration: 2.5,
          description: res.msg,
        });
      }
    });
  };

  loadData = (selectedOptions) => {
    const { optionLists } = this.state;
    const targetOption = selectedOptions[selectedOptions.length - 1];
    if (targetOption.level === 1) {
      Common.getCountyList({ cityCode: targetOption.code }).then((res) => {
        if (res.data.code === 200 && res.data.data?.length !== 0) {
          const list = [];
          res.data.data.forEach((item) => {
            list.push({
              value: item.name,
              label: item.name,
              children: [],
              isLeaf: false,
              level: 2,
              code: item.code,
            });
          });
          optionLists[0].children = list;
          this.setState({
            optionLists: [{ ...optionLists[0], children: list }],
          });
        } else {
          res.data.data?.length !== 0
            ? message.error("获取失败")
            : message.info("该区域下数据位空");
        }
      });
    } else if (targetOption.level === 2) {
      Common.getTownList({ countyCode: targetOption.code }).then((res) => {
        if (res.data.code === 200 && res.data.data?.length !== 0) {
          const list = [];
          res.data.data.forEach((item) => {
            list.push({ value: item.name, label: item.name, level: 3 });
          });
          const cldList = [...optionLists[0].children];
          cldList.map((item) => {
            if (item.value === targetOption.value)
              return (item.children = list);
          });
          this.setState({
            optionLists: [{ ...optionLists[0], children: cldList }],
          });
        } else {
          res.data.data?.length !== 0
            ? message.error("获取失败")
            : message.info("该区域下数据位空");
        }
      });
    }
  };

  render() {
    const { optionLists } = this.state;
    const { changeUserVisable, addVisible, record } = this.props;
    console.log(record);
    const special = record?.role === "ROLE_3" || record?.role === "ROLE_4";
    return (
      <Modal
        title={!record ? "添加用户" : "修改用户"}
        getContainer={false}
        visible={addVisible}
        footer={null}
        onCancel={() => {
          changeUserVisable(addVisible);
        }}
        centered={true}
        className={"user-add-box"}
      >
        <Form
          onFinish={this.onFinish}
          initialValues={
            record && {
              ...record,
              areaName: record?.areaName.split(","),
              isWork: record.isWork ?? 0,
            }
          }
          ref={this.formRef}
        >
          <FormItem
            name="name"
            label="用户名"
            rules={[
              {
                required: true,
              },
            ]}
            {...layout}
          >
            <Input placeholder="请输入用户姓名" allowClear />
          </FormItem>
          <FormItem
            name="phone"
            label="联系方式"
            rules={[
              {
                required: true,
              },
            ]}
            {...layout}
          >
            <Input placeholder="请输入联系方式" allowClear />
          </FormItem>
          <FormItem
            name="account"
            label="账号"
            rules={[
              {
                required: true,
              },
            ]}
            {...layout}
          >
            <Input placeholder="请输入登录账号" allowClear />
          </FormItem>
          {!record && (
            <FormItem
              name="password"
              label="密码"
              rules={[
                {
                  required: true,
                },
              ]}
              {...layout}
            >
              <Input.Password placeholder="请输入密码" allowClear />
            </FormItem>
          )}
          <FormItem
            name="isWork"
            label="工作人员"
            rules={[
              {
                required: true,
              },
            ]}
            {...layout}
          >
            <Radio.Group>
              <Radio value={1}>是</Radio>
              <Radio value={0}>否</Radio>
            </Radio.Group>
          </FormItem>
          <FormItem
            label="身份"
            name="role"
            rules={[
              {
                required: true,
              },
            ]}
            {...layout}
          >
            <Select disabled={special}>
              {(special ? role_special : role).map((item, index) => {
                return (
                  <Option value={item.value} key={index}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </FormItem>
          <FormItem
            name="areaName"
            label="区域选择"
            rules={[
              {
                required: true,
              },
            ]}
            {...layout}
          >
            <Cascader
              options={optionLists}
              changeOnSelect
              loadData={this.loadData}
            />
            {/* <Input placeholder='请输入区域编码' allowClear /> */}
          </FormItem>
          <div className="form-button">
            <FormItem>
              <Button type="primary" htmlType="submit">
                完成
              </Button>
              <Button
                style={{ marginLeft: "20px" }}
                onClick={() => {
                  changeUserVisable(addVisible);
                }}
              >
                取消
              </Button>
            </FormItem>
          </div>
        </Form>
      </Modal>
    );
  }
}

export default Add;
