import React, { Component, createRef } from "react";
import {
  Modal,
  Form,
  Select,
  Input,
  Button,
  Upload,
  message,
  Checkbox,
  Space,
} from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import Common from "../../platform/services/Common";
import Acceptance from "../../platform/services/Acceptance";
import { handleImage2, uploadImage2, filterList } from "../../utils/handlePic";
import { downloadShuiyin } from "../../utils/watermark";

const { Option } = Select;
const FormItem = Form.Item;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

interface AddProps {
  visible: boolean;
  cityCode: string;
  changeVisible: () => void;
  getData: () => void;
  isInspection: number;
}

const situationOption = [
  { value: "未清理枝条", label: "未清理枝条" },
  { value: "树桩未剥皮", label: "树桩未剥皮" },
  { value: "树桩高度超过5cm", label: "树桩高度超过5cm" },
  { value: "主干未清理", label: "主干未清理" },
  { value: "整株疫木未砍伐", label: "整株疫木未砍伐" },
  { value: "打堆未烧", label: "打堆未烧" },
  { value: "藏匿", label: "藏匿" },
];

interface AddState {
  countyList: Array<any>;
  townList: Array<any>;
  villageList: Array<any>;
  forestList: Array<any>;
  smallClassList: Array<any>;
  picCutList: Array<any>;
  picDroneList: Array<any>;
  indeterminate: boolean;
  checkAll: boolean;
  countyCode: string;
  townCode: string;
  villageCode: string;
  forestCode: string;
  progress: number;
}
// 验收+监理手动上报
export class Add extends Component<AddProps, AddState> {
  ref: any = null;
  constructor(props) {
    super(props);
    this.state = {
      countyList: [],
      townList: [],
      villageList: [],
      forestList: [],
      smallClassList: [],
      picCutList: [],
      picDroneList: [],
      indeterminate: false,
      checkAll: false,
      countyCode: "",
      townCode: "",
      villageCode: "",
      forestCode: "",
      progress: 1,
    };
    this.ref = createRef();
  }

  componentDidMount() {
    this.getCountyList();
    const has = sessionStorage.getItem("acceptanceDefaultValue");
    const defaultValue = {
      countyCode: "",
      townCode: "",
      villageCode: "",
      forestCode: "",
    };
    !has &&
      sessionStorage.setItem(
        "acceptanceDefaultValue",
        JSON.stringify(defaultValue)
      );
    this.checkDefaultValue();
  }

  // 检测默认值
  checkDefaultValue = () => {
    const tmp = JSON.parse(sessionStorage.getItem("acceptanceDefaultValue"));
    if (tmp.countyCode !== "") {
      this.setState(
        {
          countyCode: tmp.countyCode,
        },
        () => {
          this.getTownList();
        }
      );
    }
    if (tmp.townCode !== "") {
      this.setState(
        {
          townCode: tmp.townCode,
        },
        () => {
          this.getVillageList();
        }
      );
    }
    if (tmp.villageCode !== "") {
      this.setState(
        {
          villageCode: tmp.villageCode,
        },
        () => {
          this.getForestList();
        }
      );
    }
    if (tmp.forestCode !== "") {
      this.setState(
        {
          forestCode: tmp.forestCode,
        },
        () => {
          this.getSmallClass();
        }
      );
    }
  };

  getCountyList = () => {
    const { cityCode } = this.props;
    Common.getCountyList({ cityCode }).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          countyList: res.data.data,
        });
      }
    });
  };

  getTownList = () => {
    const { countyCode } = this.state;
    Common.getTownList({ countyCode }).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          townList: res.data.data,
        });
      }
    });
  };

  getVillageList = () => {
    const { countyCode, townCode } = this.state;
    Common.getVillageList({ countyCode, townCode }).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          villageList: res.data.data,
        });
      }
    });
  };

  getForestList = () => {
    const { countyCode, townCode, villageCode } = this.state;
    Common.getForestList({ countyCode, townCode, villageCode }).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          forestList: res.data.data,
        });
      }
    });
  };

  getSmallClass = () => {
    const { countyCode, townCode, villageCode, forestCode } = this.state;
    Acceptance.getClassList({
      countyCode,
      townCode,
      villageCode,
      forestCode,
    }).then((res) => {
      if (res.data.code === 200) {
        this.setState({
          smallClassList: res.data.data,
        });
      }
    });
  };

  // 区域改变
  changeSelect = (value, key) => {
    switch (key) {
      case 1:
        this.setState(
          {
            countyCode: value,
          },
          () => {
            sessionStorage.setItem(
              "acceptanceDefaultValue",
              JSON.stringify({
                ...JSON.parse(sessionStorage.getItem("acceptanceDefaultValue")),
                countyCode: value,
              })
            );
            this.getTownList();
          }
        );
        break;
      case 2:
        this.setState(
          {
            townCode: value,
          },
          () => {
            sessionStorage.setItem(
              "acceptanceDefaultValue",
              JSON.stringify({
                ...JSON.parse(sessionStorage.getItem("acceptanceDefaultValue")),
                townCode: value,
              })
            );
            this.getVillageList();
          }
        );
        break;
      case 3:
        this.setState(
          {
            villageCode: value,
          },
          () => {
            sessionStorage.setItem(
              "acceptanceDefaultValue",
              JSON.stringify({
                ...JSON.parse(sessionStorage.getItem("acceptanceDefaultValue")),
                villageCode: value,
              })
            );
            this.getForestList();
          }
        );
        break;
      case 4:
        this.setState(
          {
            forestCode: value,
          },
          () => {
            sessionStorage.setItem(
              "acceptanceDefaultValue",
              JSON.stringify({
                ...JSON.parse(sessionStorage.getItem("acceptanceDefaultValue")),
                forestCode: value,
              })
            );
            this.getSmallClass();
          }
        );
        break;
    }
  };

  // 文件改变
  onPhotoChange = (value, key) => {
    if (key === 1) {
      this.setState({
        picCutList: value ? value.fileList : [],
      });
    } else {
      this.setState({
        picDroneList: value ? value.fileList : [],
      });
    }
  };

  // 是否全选小班改变
  onCheckAllChange = (e) => {
    const { smallClassList } = this.state;
    this.ref.current.setFieldsValue({
      smallClassList: e.target.checked ? smallClassList : [],
    });
    this.setState({
      indeterminate: false,
      checkAll: e.target.checked,
    });
  };

  // 表单提交
  onFinish = (value) => {
    let situation = {};
    value.situationList &&
      value.situationList.map((e) => {
        situation[e.first] = e.last;
      });
    value.demeritPoints = situation;

    let data = { ...value };
    const { progress, picCutList, picDroneList } = this.state;
    const { getData, changeVisible, isInspection } = this.props;
    // console.log(data)
    // 解析图片
    if (progress === 1) {
      message.loading({
        content: "图片正在解析中,请稍等一会",
        key: "img",
        duration: 0,
      });
      handleImage2(
        picCutList.map((item) => {
          return {
            name: item.name,
            file: item.originFileObj,
            type: 1,
            thumbUrl: item.thumbUrl,
          };
        })
      ).then((res1: any) => {
        handleImage2(
          picDroneList.map((item) => {
            return {
              name: item.name,
              file: item.originFileObj,
              type: 2,
              thumbUrl: item.thumbUrl,
            };
          })
        ).then((res2: any) => {
          res1 = filterList(res1);
          res2 = filterList(res2);
          const value1 = res1.filter((item) => {
            return item.point.x && item.point.y;
          });
          const value2 = res2.filter((item) => {
            return item.point.x && item.point.y;
          });
          // const start = new Date().getTime();
          // 伐桩图片打水印
          const promise1 = new Promise((resolve, reject) => {
            let newList = [];
            if (res1.length > 0) {
              res1.map((item) => {
                if (
                  item.file.name.toUpperCase().startsWith("DJI") &&
                  item.file &&
                  item.point.x &&
                  item.point.y
                ) {
                  downloadShuiyin(item).then((data) => {
                    newList.push({ ...item, shuiYinFile: data });
                    this.setState({
                      picCutList: [...newList],
                    });
                    if (newList.length === res1.length) {
                      resolve(newList);
                    }
                  });
                } else {
                  newList.push({ ...item });
                  this.setState({
                    picCutList: [...newList],
                  });
                  if (newList.length === res1.length) {
                    resolve(newList);
                  }
                }
              });
            } else {
              resolve(newList);
            }
          });
          // 航拍图片打水印
          const promise2 = new Promise((resolve, reject) => {
            let newList = [];
            if (res2.length > 0) {
              res2.map((item) => {
                if (
                  item.file.name.toUpperCase().startsWith("DJI") &&
                  item.file &&
                  item.point.x &&
                  item.point.y
                ) {
                  downloadShuiyin(item).then((data) => {
                    newList.push({ ...item, shuiYinFile: data });
                    this.setState({
                      picDroneList: [...newList],
                    });
                    if (newList.length === res2.length) {
                      resolve(newList);
                    }
                  });
                } else {
                  newList.push({ ...item });
                  this.setState({
                    picDroneList: [...newList],
                  });
                  if (newList.length === res2.length) {
                    resolve(newList);
                  }
                }
              });
            } else {
              resolve(newList);
            }
          });
          promise1.then((r1) => {
            promise2.then((r2) => {
              // const time = `打水印一共耗时${
              //   (new Date().getTime() - start) / 1000
              // }秒`;
              message.success({
                content: (
                  <div>
                    <div>图片全部解析完成！其中：</div>
                    <div>
                      伐桩图片共{res1.length}张，解析成功{value1.length}张，失败
                      {res1.length - value1.length}张
                    </div>
                    <div>
                      航拍图片共{res2.length}张，解析成功{value2.length}张，失败
                      {res2.length - value2.length}张
                    </div>
                  </div>
                ),
                duration: 3,
                key: "img",
              });
              this.setState({
                progress: 2,
              });
            });
          });
        });
      });
    } else {
      const { picCutList, picDroneList } = this.state;
      message.loading({
        content: "图片正在上传中,请稍等一会",
        key: "upload",
        duration: 0,
      });
      uploadImage2(
        picCutList.filter((item) => {
          return item.point.x && item.point.y;
        })
      ).then((res1) => {
        uploadImage2(
          picDroneList.filter((item) => {
            return item.point.x && item.point.y;
          })
        ).then((res2) => {
          message.success({
            content: "图片上传成功",
            key: "upload",
            duration: 1,
          });
          data.picCutList = res1;
          data.picDroneList = res2;
          data.isInspection = isInspection;
          Acceptance.addData(data).then((res) => {
            if (res.data.code === 200) {
              message.success("上报数据成功");
              changeVisible();
              getData();
            }
          });
        });
      });
    }
  };

  render() {
    const { visible, changeVisible } = this.props;
    const {
      countyList,
      townList,
      villageList,
      forestList,
      smallClassList,
      picCutList,
      picDroneList,
      indeterminate,
      checkAll,
      progress,
    } = this.state;
    const props = {
      action: window["env"].REACT_APP_API_HOST + "/upload",
      listType: "picture-card",
      // headers: {
      //   authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')).access_token
      // },
      data: (file) => {
        return {
          type: 2,
        };
      },
      beforeUpload: () => {
        return false;
      },
      maxCount: 50,
      multiple: true,
      accept: ".png,.jpg,.jpeg",
      showUploadList: {
        showPreviewIcon: false,
        showRemoveIcon: true,
        showDownloadIcon: false,
      },
    };
    const props1: any = {
      ...props,
      fileList: picCutList,
      onChange: (value) => this.onPhotoChange(value, 1),
    };
    const props2: any = {
      ...props,
      fileList: picDroneList,
      onChange: (value) => this.onPhotoChange(value, 2),
    };
    return (
      <Modal
        title="手动上传验收报告"
        visible={visible}
        footer={null}
        onCancel={changeVisible}
        centered={true}
        className={"acceptance-add-box"}
      >
        <Form
          onFinish={this.onFinish}
          ref={this.ref}
          initialValues={JSON.parse(
            sessionStorage.getItem("acceptanceDefaultValue")
          )}
        >
          <FormItem
            name="countyCode"
            label="区县"
            rules={[
              {
                required: true,
                message: "请选择区县",
              },
            ]}
            {...layout}
          >
            <Select onSelect={(value) => this.changeSelect(value, 1)}>
              {countyList.map((item, index) => {
                return (
                  <Option key={item.code} value={item.code}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </FormItem>
          <FormItem
            name="townCode"
            label="乡镇/街道"
            rules={[
              {
                required: true,
                message: "请选择乡镇/街道",
              },
            ]}
            {...layout}
          >
            <Select onSelect={(value) => this.changeSelect(value, 2)}>
              {townList.map((item, index) => {
                return (
                  <Option key={item.code} value={item.code}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </FormItem>
          <FormItem
            name="villageCode"
            label="行政村"
            rules={[
              {
                required: true,
                message: "请选择行政村",
              },
            ]}
            {...layout}
          >
            <Select onSelect={(value) => this.changeSelect(value, 3)}>
              {villageList.map((item, index) => {
                return (
                  <Option key={item.code} value={item.code}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </FormItem>
          <FormItem
            name="forestCode"
            label="林班"
            rules={[
              {
                required: true,
                message: "请选择林班",
              },
            ]}
            {...layout}
          >
            <Select onSelect={(value) => this.changeSelect(value, 4)}>
              {forestList.map((item, index) => {
                return (
                  <Option key={item.code} value={item.code}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </FormItem>
          <FormItem
            name="classIdList"
            label="小班号"
            rules={[
              {
                required: true,
                message: "请选择小班号",
              },
            ]}
            {...layout}
          >
            <Select
              mode="multiple"
              showArrow={true}
              maxTagCount={10}
              style={{ width: "230px" }}
            >
              {smallClassList.map((item, index) => {
                return (
                  <Option key={index} value={item.classId}>
                    {item.smallClass}
                  </Option>
                );
              })}
            </Select>
          </FormItem>
          <div className={"checkbox-box"}>
            <Checkbox
              indeterminate={indeterminate}
              onChange={this.onCheckAllChange}
              checked={checkAll}
            >
              小班全选
            </Checkbox>
          </div>
          <Form.List name="situationList">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: "flex",
                      marginBottom: "8px",
                      height: "40px",
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "first"]}
                      rules={[{ required: true, message: "请选择描述" }]}
                    >
                      <Select
                        placeholder="请选择描述"
                        options={[
                          ...situationOption,
                          { value: "其他", label: "其他" },
                        ]}
                      >
                        {[
                          ...situationOption,
                          { value: "其他", label: "其他" },
                        ].map((option) => (
                          <Option
                            key={option.value}
                            value={option.value}
                            title={option.value}
                          >
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "last"]}
                      rules={[{ required: true, message: "请填写内容" }]}
                    >
                      <Input placeholder="请填写内容" />
                    </Form.Item>
                    <MinusCircleOutlined
                      rev={undefined}
                      onClick={() => remove(name)}
                    />
                  </Space>
                ))}
                <Form.Item style={{ marginTop: "30px" }}>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined rev={undefined} />}
                  >
                    添加描述
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <FormItem
            name="result"
            label="验收结果"
            rules={[
              {
                required: true,
                message: "请选择验收结果",
              },
            ]}
            {...layout}
          >
            <Select>
              <Option value={1}>验收通过</Option>
              <Option value={0}>验收不通过</Option>
            </Select>
          </FormItem>
          <FormItem
            label="伐桩图片"
            name="picCutList"
            tooltip="1.将会解析照片的定位信息，与小班进行关联，并以图标形式展示在地图上。"
            rules={[
              {
                required: false,
                message: "请选择伐桩图片",
              },
            ]}
            {...layout}
          >
            <Upload {...props1}>
              <div>
                <PlusOutlined rev={undefined} />
                <div style={{ marginTop: 8 }}>上传</div>
              </div>
            </Upload>
          </FormItem>
          <FormItem
            label="航拍图片"
            name="picDroneList"
            tooltip="1.将会解析其朝向信息，并以图标形式展示在地图上。
                        2.会根据解析出来的相关信息为照片添加水印。
                        3.当前已适配的无人机机型有：精灵、FPV、御二、御三、M300。"
            rules={[
              {
                required: false,
                message: "请选择航拍图片",
              },
            ]}
            {...layout}
          >
            <Upload {...props2}>
              <div>
                <PlusOutlined rev={undefined} />
                <div style={{ marginTop: 8 }}>上传</div>
              </div>
            </Upload>
          </FormItem>
          <div className="form-button">
            <FormItem>
              <Button type="primary" htmlType="submit">
                {progress === 1 ? "解析图片" : "上报数据"}
              </Button>
              <Button onClick={() => changeVisible()}>取消</Button>
            </FormItem>
          </div>
        </Form>
      </Modal>
    );
  }
}
