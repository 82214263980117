import axios from "axios";
import { acceptance_axios } from "../utils/axios";
export default class Request {
  /**
   * 登录
   * @param {*} param
   * @param {*} events
   */
  static Login(param, events) {
    axios.post("/user/get_token", param).then(res => {
      events(res.data);
    });
  }

  //下载模板接口
  static DownloadTemplate(events) {
    axios({
      url: `/common/get_excel_demo`,
      responseType: "blob",
      method: "get",
    }).then(res => {
      events(res.data);
    });
  }

  //数据导入接口
  static upLoadFile(param, events) {
    axios({
      url: `/common/upload?areaCode=${param.areaCode}`,
      method: "post",
      data: param.file,
    }).then(res => {
      events(res.data);
    });
  }

  //普查管理文件excel样例接口
  static censusFile(events) {
    axios({
      url: `/decision/get_excel_demo`,
      responseType: "blob",
      method: "get",
    }).then(res => {
      events(res.data);
    });
  }

  //普查文件上传接口
  static censusUpLoadFile(param, events) {
    axios({
      url: `/decision/upload`,
      method: "post",
      data: param,
    }).then(res => {
      events(res);
    });
  }

  //区域划分点位获取小班处理记录信息
  static getCensusClassLonlat(param, event) {
    axios
      .get(
        `/decision/class_detail?x=${param.x}&y=${param.y}&year=${param.year}&areaCode=${param.areaCode}`
      )
      .then(res => {
        event(res.data);
      });
  }

  static ContryList(param, events) {
    axios
      .get(
        `/common/list_regions?areaCode=${param.areaCode}&areaLevel=${param.areaLevel}&year=${param.year}`
      )
      .then(res => {
        events(res.data);
      });
  }

  static TownList(param, events) {
    axios
      .get(
        `/common/list_regions?areaCode=${param.areaCode}&areaLevel=${param.areaLevel}&year=${param.year}`
      )
      .then(res => {
        events(res.data);
      });
  }
  /**
   * 获取有小班左侧数据
   * @param {*} param
   * @param {*} events
   */
  static DataList(param, events) {
    axios
      .get(
        `/analysis_class/query_region_list?areaCode=${param.areaCode}&areaLevel=${param.areaLevel}&year=${param.year}`
      )
      .then(res => {
        events(res.data);
      });
  }
  /**
   * 获取县级边界数据
   * @param {*} param
   * @param {*} events
   */
  static CountryBorderlist(param, events) {
    axios.get(`/common/county_border?areaCode=${param}`).then(res => {
      events(res.data);
    });
  }

  /**
   * 获取乡镇边界数据
   * @param {*} param
   * @param {*} events
   */
  static StreetBorderlist(param, events) {
    axios
      .get(`/common/town_border?areaCode=${param}&year=${sessionStorage.getItem("year")}`)
      .then(res => {
        events(res.data);
      });
  }

  /**
   * 视角范围加载小班
   * @param {*} param
   * @param {*} events
   */
  static getDiyClassLayer(param, events) {
    axios
      .get(
        `/analysis_class/class_border?minX=${param.minX}&minY=${param.minY}&maxX=${param.maxX}&maxY=${param.maxY}`
      )
      .then(res => {
        events(res.data);
      });
  }

  /**
   * 获取疫情发生统计数据
   * @param {*} param
   * @param {*} events
   */
  static InStatisticData(param, events) {
    axios
      .get(
        `/analysis_class/query_statistic?areaCode=${param.areaCode}&areaLevel=${param.areaLevel}&year=${param.year}`
      )
      .then(res => {
        events(res.data);
      });
  }
  /**
   * 获取疫情发生统计数据-排查进度
   * @param {*} param
   * @param {*} events
   */
  static Screenschedule(param, events) {
    axios
      .get(
        `/analysis_class/query_complete?areaCode=${param.areaCode}&areaLevel=${param.areaLevel}&year=${param.year}`
      )
      .then(res => {
        events(res.data);
      });
  }
  /**
   * 获取疫情发生统计数据-感染程度
   * @param {*} param
   * @param {*} events
   */
  static DegreeInfection(param, events) {
    axios
      .get(
        `/analysis_class/query_infect?areaCode=${param.areaCode}&areaLevel=${param.areaLevel}&year=${param.year}`
      )
      .then(res => {
        events(res.data);
      });
  }
  /**
   * 获取疫情发生程度数据
   * @param {*} param
   * @param {*} events
   */
  static OutbreakLevel(param, events) {
    axios
      .get(
        `/analysis_class/query_happen?areaCode=${param.areaCode}&areaLevel=${param.areaLevel}&year=${param.year}`
      )
      .then(res => {
        events(res.data);
      });
  }
  /**
   * 清除疫木点位
   * @param {*} param
   * @param {*} events
   */
  static CleanPoints(param, events) {
    axios.post(`/tree_identify/delete`, param).then(res => {
      events(res.data);
    });
  }
  /**
   * 新增疫木点位
   * @param {*} param
   * @param {*} events
   */
  static AddPoints(param, events) {
    axios.post(`/tree_identify/add`, { ...param }).then(res => {
      events(res.data);
    });
  }
  /**
   * 框选清除
   * @param {*} param
   * @param {*} events
   */
  static CleanBox(param, events) {
    axios
      .delete(`/tree_identify/range_delete`, {
        data: {
          ...param,
        },
      })
      .then(res => {
        events(res.data);
      });
  }
  /**
   * 更改排查状态
   * @param {*} param
   * @param {*} events
   */
  static ChangeSchedule(param, events) {
    axios.post(`/tree_identify/update_class_status`, { ...param }).then(res => {
      events(res.data);
    });
  }

  /**
   * 无小班区域列表
   * @param {*} param
   * @param {*} events
   */
  static NoclassAreaList(param, events) {
    axios
      .get(
        `/analysis_grid/query_region_list?areaCode=${param.areaCode}&areaLevel=${param.areaLevel}&year=${param.year}`
      )
      .then(res => {
        events(res.data);
      });
  }

  /**
   * 无小班修改网格状态
   * @param {*} param
   * @param {*} events
   */
  static NoclassGridState(param, events) {
    axios.post(`/tree_identify/update_grid_status`, { ...param }).then(res => {
      events(res.data);
    });
  }

  //无小班疫情发生统计接口
  static NoclassStatic(param, events) {
    axios
      .get(
        `/analysis_grid/query_statistic?areaCode=${param.areaCode}&areaLevel=${param.areaLevel}&year=${param.year}`
      )
      .then(res => {
        events(res.data);
      });
  }

  //无小班排查工作展开接口
  static NoclassCheck(param, events) {
    axios.get(`/analysis_grid/query_check?year=${param.year}`).then(res => {
      events(res.data);
    });
  }

  //待处理小班接口
  static undealClassList(params, events) {
    axios.get(`/file_upload_record/get_undeal_class`, { params }).then(res => {
      events(res.data);
    });
  }

  //根据视角范围获取疫木点位
  static getWoodByView(param, events) {
    axios.post(`/tree_identify/get_list`, param).then(res => {
      events(res.data);
    });
  }

  //根据id修改小班完成状态
  static changeClassState(param, events) {
    axios.put(`/file_upload_record/update_finsh_status`, { ...param }).then(res => {
      events(res.data);
    });
  }

  //获取所有年度
  static getAllYear(events) {
    axios.get(`/common/list_year`).then(res => {
      events(res.data);
    });
  }

  //获取展示页面数据
  static getFirstPageData(params, events) {
    axios.get(`/common/query_display_page_info`, { params }).then(res => {
      events(res.data);
    });
  }

  //获取展示页面数据
  static getAllPageData(events) {
    axios.get(`/common/query_display_page_info_show`).then(res => {
      events(res.data);
    });
  }
  // 获取验收不合格小班
  static UnAcceptance(params, events) {
    acceptance_axios
      .post(`/acceptance/get_frequency_statistic`, { ...params })
      .then(res => {
        events(res.data);
      })
      .catch(error => {
        console.log(error);
      });
  }
  // 获取当前验收统计数据
  static getCurrentAcceptData(params, events) {
    acceptance_axios
      .get(
        `/acceptance/accept_result?areaCode=${params.areaCode}&isInspection=${params.isInspection}&year=${params.year}`
      )
      .then(res => {
        events(res.data);
      })
      .catch(error => {
        console.log(error);
      });
  }
  // 获取当前验收统计数据
  static getAcceptCountyData(params, events) {
    acceptance_axios
      .get(
        `/acceptance/progress_data?areaCode=${params.areaCode}&isInspection=${params.isInspection}&year=${params.year}`
      )
      .then(res => {
        events(res.data);
      })
      .catch(error => {
        console.log(error);
      });
  }
}
