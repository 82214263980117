import React, { Component } from 'react';
import { Modal, Form, Select, Statistic, Input, Button, message, notification } from 'antd';
import PlagueWoodTs from '../services/plagueWood';
import ToolTs from '../services/tool';
const FormItem = Form.Item;
const { Option } = Select
const layout = {
	labelCol: { span: 7 },
	wrapperCol: { span: 12 }
}

class Intell extends Component<any, any> {
	form: any = React.createRef()
	state = {
		countyCode: this.props.countyCode,
		townCode: "",
		countyList: [],  //区县列表数据
		townList: [],  //乡镇列表数据
		total: 0,
		totalAreaNum: 0,
		totalTreeCountNum: 0,
		saveAreaNum: 0,   	//保存的原始面积总数
		saveTreeNum: 0,		//保存的原始疫木总数
		year: localStorage.getItem("year"),
		selectArea: 0, 	//选择的总面积
		selectTree: 0,		//选择的总株数
		yearList: JSON.parse(localStorage.getItem("yearList")),
	}

	componentDidMount(): void {
		this.getSelectList()
	}

	getData = () => {
		const { year, countyCode, townCode } = this.state
		const params = {
			page: 1,
			size: 10,
			year,
			countyCode,
			townCode: townCode ? townCode.slice(townCode.length - 3) : ""
		}
		ToolTs.getTreeHandle(params, res => {
			if (res.code === 200) {
				this.setState({
					totalTreeCountNum: res.data.totalTreeCountNum,
					totalAreaNum: res.data.totalAreaNum,
				})
			}
		})
	}

	//选择框
	getSelectList = () => {
		const { countyCode, townCode, countyList } = this.state
		const params = {
			countyCode,
			townCode: townCode ? townCode : "",
			year:sessionStorage.getItem('year')
		}
		PlagueWoodTs.getSelectList(params).then(res => {
			if (res.data.code === 200) {
				if (countyList.length === 0) {
					this.setState({
						countyList: res.data.data
					})
				} else if (!townCode) {
					this.setState({
						townList: res.data.data
					})
				}

			}
		})
	}

	//下拉框选择
	onSelect = (value, type) => {
		switch (type) {
			case "countyCode":
				this.form.current.resetFields(["townCode"])
				this.setState({
					countyCode: value,
					townCode: "",
					townList: [],
				}, () => {
					this.getSelectList()
					this.getData()
				})
				break;
			case "townCode":
				this.setState({
					townCode: value,
				}, () => {
					this.getData()
				})
				break;
		}

	}

	//调整
	finish = (value) => {
		const { intellVisiable, intellMethod, getListData } = this.props
		const params = {
			...value,
			targetNumber: value.targetNumber * 1,
			targetArea: value.targetArea * 1,
			townCode: value.townCode && value.townCode.slice(value.townCode.length - 3)
		}
		message.loading({ duration: 0, content: "正在进行数据调整", })
		ToolTs.postDealData(params).then(res => {
			if (res.data.code === 200) {
				message.destroy()
				notification.success({
					message: "智能调整完成",
					duration: 2.5
				})
				intellMethod(intellVisiable)
				getListData()
			} else {
				message.destroy()
				notification.error({
					message: "智能调整失败",
					description: res.data.msg,
					duration: 4
				})
				intellMethod(intellVisiable)
				getListData()
			}
		})
	}

	render() {
		const { countyList, townList, totalTreeCountNum, totalAreaNum, } = this.state
		const { intellVisiable, intellMethod } = this.props
		return <Modal
			visible={intellVisiable}
			footer={null}
			onCancel={() => intellMethod(intellVisiable)}
			title="智能调整"
			className='intell'
		>
			<Form
				onFinish={this.finish}
				ref={this.form}
			>
				<FormItem
					name="countyCode"
					label="区县"
					rules={[
						{
							required: true,
							message: "请选择区县",
						},
					]}
					{...layout}
				>
					<Select onSelect={(value) => this.onSelect(value, "countyCode")} >
						{
							countyList.map((item) => {
								return <Option key={item.areaCode} value={item.areaCode}>{item.name}</Option>
							})
						}
					</Select>
				</FormItem>
				<FormItem
					name="townCode"
					label="乡镇/街道"
					{...layout}
				>
					<Select allowClear onChange={(value) => this.onSelect(value, "townCode")}>
						{
							townList.map((item) => {
								return <Option key={item.areaCode} value={item.areaCode}>{item.name}</Option>
							})
						}
					</Select>
				</FormItem>
				<FormItem
					label="当前疫木"
					{...layout}
				>
					<Statistic value={totalTreeCountNum} valueStyle={{ fontSize: "16px" }} suffix="株" />
				</FormItem>
				<FormItem
					label="当前感染面积"
					{...layout}
				>
					<Statistic value={totalAreaNum} valueStyle={{ fontSize: "16px" }} suffix="亩" />
				</FormItem>
				<FormItem
					name="targetNumber"
					label="期望疫木"
					{...layout}
					rules={[
						{
							required: true,
							message: "请填写期望疫木数量值",
						},
					]}
				>
					<Input type="number" addonAfter="株" />
				</FormItem>
				<FormItem
					name="targetArea"
					label="期望感染面积"
					rules={[
						{
							required: true,
							message: "请填写期望感染面积",
						}
					]}
					{...layout}
				>
					<Input type="number" addonAfter="亩" />
				</FormItem>
				<div className="form-button">
					<FormItem>
						<Button type="primary" htmlType="submit">	进行智能调整</Button>
					</FormItem>
				</div>
			</Form>
		</Modal >
	}
}

export default Intell;

